
import {of as observableOf,  Observable } from 'rxjs';

import {map, mergeMap} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Caption } from "../../static/models/caption.interface";
import { environment } from "../../../environments/environment";
import { HttpClientService } from "./httpclient";
import { ApplicationUser } from '../../pb-screen/models/application.user.interface';
import { select } from '@angular-redux/store';

@Injectable()
export class DynamicCaptionService {
    
    private applicationUser: ApplicationUser;
    @select("applicationUser") applicationUser$: Observable<ApplicationUser>;
    constructor(private readonly http : HttpClientService){
            this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
              if (applicationUser) {
                this.applicationUser = applicationUser;
              }
            });
    }

    public getDynamicCaptions() : Observable<Caption[]> {
        return this.http.getToken().pipe(mergeMap(() => {        
            this.getDynamicCaption(dynamicCaptions).subscribe((response: Caption[]) => {
                dynamicCaptions.forEach(el => {
                    el.caption = response.find(res => res.field == el.field && res.table == el.table)?.caption;
                });
            })
            return observableOf(dynamicCaptions);
        }))
    }

    public getDynamicCaption(captions: Caption[]) : Observable<Caption[]> {
        let lang = this.applicationUser.planningSetting.captionLang;
        lang = lang ? lang : "ENU";
        return this.http.post(environment.tmsApiEndpointAddress + 'caption?lang=' + lang, captions).pipe(
            map((response: any) => {
                return response.data;
            }));
    }

    public getCaptionByTableAndFieldName(table : string, field : string) : string {
        var caption = dynamicCaptions.filter((element) => {
            return element.table === table && element.field === field;
        });
        if(caption.length > 0) return caption[0].caption;
        else return '';
    }

    public getCaptionByFieldName(field : string) : string {
        var caption = dynamicCaptions.filter((element) => {
            return element.field === field;
        });
        if(caption.length > 0) return caption[0].caption;
        else return '';
    }
}

export const dynamicCaptions : Caption[] = [
    { table: 'TMS Order Cargo', field: 'Tracing No. 1', caption: '' },
    { table: 'TMS Order Cargo', field: 'Tracing No. 2', caption: '' },
    { table: 'TMS Order Cargo', field: 'Good Type Code', caption: '' },
    { table: 'TMS Order Cargo', field: 'Good Sub Type Code', caption: '' },
    { table: 'TMS Order Header', field: 'Shortcut Reference 1 Code', caption: '' },
    { table: 'TMS Order Header', field: 'Shortcut Reference 2 Code', caption: '' },
    { table: 'TMS Order Header', field: 'Shortcut Reference 3 Code', caption: '' }, 
    { table: 'TMS Order Line Extended', field: 'Custom Txt Field 1', caption: '' }, 
    { table: 'TMS Order Line Extended', field: 'Custom Txt Field 2', caption: '' }, 
    { table: 'TMS Order Line Extended', field: 'Custom DropDown Text1', caption: '' }, 
    { table: 'TMS Order Line Extended', field: 'Custom DropDown Text2', caption: '' }, 
    
];