import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'modal-okdialog',
  templateUrl: './okDialog.component.html'
})
export class OkDialogComponent {
  @Input() id: string;
  @Input() title: string;
  @Input() message: string;

  @Output() confirmClicked = new EventEmitter<void>();

  onConfirmClicked(): void {
    this.confirmClicked.emit();
  }
}