export interface PoolMove {
    timestamp: string;
    no: string;
    containerNo: string;
    active: string;
    type: string;
    dateTime: string;
    from: string;
    towards: string;
    fromLocation: string;
    toLocation: string;
    driverNo: string;
    driverName: string;
    haulierNo: string;
    haulierName: string;
    truckRegistrationNo: string;
    truckNo: string;
    eIRPrinted: string;
    comment: string;
    containerBookingNo: string;
    referenceCode: string;
    chargeStatus: string;
    codecoOrgMessage: string;
    codecoCorrMessage: string;
    announced: string;
    fromAddressNo: string;
    toAddressNo: string;
    containerSequence: string;
    noSeries: string;
    food: string;
    fromAddressName: string;
    fromAddressSearchName: string;
    fromAddressCity: string;
    toAddressName: string;
    toAddressSearchName: string;
    toAddressCity: string;
    sequence: string;
    containerType: string;
    containerSubType: string;
    createdBy: string;
    creationDateTime: string;
    lastModifiedBy: string;
    lastModificationDateTime: string;
    temperature: number;
    temperatureUOM: string;
    temperatureUOMDescription: string;
    humidity: string;
    drains: string;
    ventilation: string;
    carrierCode: string;
}
export enum MoveToFrom {
    EXTERN = "0",
    CLEANING = "1",
    CONTAINERSTOCK = "2",
    ORDER = "3",
    REPAIR = "4",
    HEATING = "5",
    CONTROLE = "6",
}

export function setMoveToFrom(code: string) {
    let stringName = "";
    switch (code) {
        case MoveToFrom.EXTERN:
            stringName = "Extern";
            break;
        case MoveToFrom.CLEANING:
            stringName = "Cleaning";
            break;
        case MoveToFrom.CONTAINERSTOCK:
            stringName = "Container Stock";
            break;
        case MoveToFrom.ORDER:
            stringName = "Order";
            break;
        case MoveToFrom.REPAIR:
            stringName = "Repair";
            break;
        case MoveToFrom.HEATING:
            stringName = "Heating";
            break;
        case MoveToFrom.CONTROLE:
            stringName = "Controle";
            break;
        default:
            stringName = "Unknown";
    }
    return stringName;
}



