export enum BookingModes {
    Incoming = 0,
    Outgoing = 1,
    All = 2,
}
export enum BookingModesStrings {
    Incoming = "Incoming",
    Outgoing = "Outgoing",
}
export enum BookingStatesStrings {
    Finished = "Finished",
    Open = "Open",
}
