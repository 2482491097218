
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Inject, Injectable } from '@angular/core';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from "@angular/http"
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../environments/environment';
import { PrintTicketData } from '../models/print-ticket-data.interface';
import { TransportOrderPlanUnit } from '../models/transport-order-plan-unit.interface';
import { DepotAddress } from '../models/depot-address.interface';
import { DepotGroup } from '../models/depot-group.interface';
import { OrderLine } from '../models/order-line/orderLine.interface';



export enum Actions {
  DEPOT_IN = 34,
  DEPOT_OUT = 33,
  PICK_UP = 7,
  DROP_OFF = 8,
}

@Injectable()
export class PrintTicketService {


  public baseUrl: string = environment.tmsApiEndpointAddress + "print";


    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }

    public printTicket(planUnit: TransportOrderPlanUnit, toNumber: string,
                       orderLines: OrderLine[], userName: string): any{

        const data: PrintTicketData ={
         
          depotInAddresses: [],
          depotOutAddresses: [],
          haulier: {
            driverName: "",
            haulier: "",
            printDate: "",
            truck: "",
            userName: "",
          },
          toNumber: "",
          type: {
            iD: 1,
          },
        };

        if (orderLines.length > 0) {
          data.haulier.driverName = orderLines[0].driverFullName;
          data.haulier.haulier = orderLines[0].supplierName;
          data.haulier.printDate = new Date().toString();
          data.haulier.truck = orderLines[0].truckDescription;
          data.haulier.userName = userName;
        }

        if (toNumber) {
          data.toNumber = toNumber;
        }

        const depotInAddressSearchNames = orderLines.reduce((searchnames, oL) => {
          if (searchnames.indexOf(oL.addressSearchName) === -1 && oL.actionType === Actions.DEPOT_IN) {
            if (planUnit.planningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
              searchnames.push(oL.addressSearchName);
            }
          }
          return searchnames;
        }, []);

        if (planUnit.planningBlocks.length > 0) {
          for (const address of depotInAddressSearchNames) {
            const newDepotAdres: DepotAddress = {
              addressSearchName: address,
              groups: [],
              noOfContainers: 0,
            };

            newDepotAdres.addressSearchName = orderLines.find((oL) => oL.addressSearchName === address).addressName;

            const addresGroups = orderLines.reduce((groups, oL) => {
              if (groups.indexOf(oL.groupingID) === -1 && oL.actionType === Actions.DEPOT_IN
                && oL.addressSearchName === address && oL.groupingID !== 0) {
                  groups.push(oL.groupingID);
              }
              return groups;
            }, []);

            for (const group of addresGroups) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInOrderLineRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };

              const groupedOrderlines =
              orderLines.filter((oL) => oL.groupingID === group && oL.actionType === Actions.DEPOT_IN);

              for (const orderline of groupedOrderlines) {
                const planningblock = planUnit.planningBlocks.find((pb) => pb.id === orderline.planningBlockId);
                newDepotGroup.planningBlocks.push(planningblock);
              }

              let addressblock = orderLines
              .find((oL) => oL.actionType === Actions.PICK_UP
              && oL.planningBlockId === groupedOrderlines[0].planningBlockId);

              if (!addressblock) {
                addressblock = orderLines
                .find((oL) => oL.actionType === Actions.DEPOT_OUT
                && oL.planningBlockId === groupedOrderlines[0].planningBlockId);
              }

              if (addressblock) {
                newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
              }


              newDepotGroup.tableInfo.depotInOrderLineRef2 = newDepotGroup.planningBlocks[0].depotInOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot =  this.createTimeslotString(groupedOrderlines[0]);

              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;
            }

            const addresGroupsZero = orderLines.reduce((groups, oL) => {
              if (oL.actionType === Actions.DEPOT_IN && oL.addressSearchName === address && oL.groupingID === 0) {
                  if (planUnit.planningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
                    groups.push(oL);
                  }
              }
              return groups;
            }, []);

            for (const orderline of addresGroupsZero) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInOrderLineRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };
              const planningblock = planUnit.planningBlocks.find((pb) => pb.id === orderline.planningBlockID);
              newDepotGroup.planningBlocks.push(planningblock);

              let addressblock =
              orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockID
              && oL.actionType === Actions.PICK_UP);

              if (!addressblock) {
                addressblock =
                orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockID
                && oL.actionType === Actions.DEPOT_OUT);
              }

              if (addressblock) {
                newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
              }


              newDepotGroup.tableInfo.depotInOrderLineRef2 = newDepotGroup.planningBlocks[0].depotInOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot = this.createTimeslotString(orderline);


              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;

            }

            data.depotInAddresses.push(newDepotAdres);

          }

        }
        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        headers.append('responseType' , 'blob');
        let options = new RequestOptions({
            headers : headers 
        })

        return this.http.post(this.baseUrl + "/printticket" , data, options ).pipe(
        map((res:any) =>
         window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))),
        catchError((err) => {
          return observableThrowError(err);
        }),);
    }

    private createTimeslotString(oL: OrderLine): string {
      let timeSlot: string = "";
      if (oL.bookedDateTimeFrom && oL.bookedDateTimeFrom.toString().length > 0) {
        timeSlot += oL.bookedDateTimeFrom.toString().split(" ")[0];
        timeSlot += " ";
        timeSlot += oL.bookedDateTimeFrom.toString().split(" ")[1].substring(0, 5);
        if (oL.bookedDateTimeUntil && oL.bookedDateTimeUntil.toString().length > 0) {
          timeSlot += " - ";
          timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[1].substring(0, 5);
        }
      } else if (oL.bookedDateTimeUntil && oL.bookedDateTimeUntil.toString().length > 0) {
        timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[0];
        timeSlot += " ";
        timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[1].substring(0, 5);
      }
      return timeSlot;
    }

}
