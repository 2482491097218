import { Component, Inject, Output, EventEmitter, OnInit, Input } from '@angular/core';
import {DOCUMENT} from '@angular/common';
import { ApplicationUser } from '../../../pb-screen/models/application.user.interface';
import { Observable } from 'rxjs';
import { select } from '@angular-redux/store';
import { ApplicationUserActions } from '../../../pb-screen/application.user.actions';
import { ComponentSource } from '../../models/component-source';

@Component({
    selector: 'app-font',
    templateUrl: 'font.component.html'
})
export class FontComponent implements OnInit{

  @Input() source: string;
  @Output() fontChangedEvent = new EventEmitter<string>();
  public user: ApplicationUser;
  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;

  private selectedFont: string = "sm";

  constructor(@Inject(DOCUMENT) private document: Document,
              public readonly userActions: ApplicationUserActions) {
  }

  ngOnInit(): void {
    if(this.selectedFont) {
      this.updateFont(this.selectedFont);
    }
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      this.user = applicationUser;
      if(!applicationUser)
        return;
      var fontSize;
      if(this.source === "to-screen") {
        fontSize = this.user.toPreferences.fontSize;
      }
      else if(this.source == ComponentSource.depotOut){
        fontSize = this.user.depotOutPreferences.fontSize;
      }
      else if(this.source == ComponentSource.depotIn){
        fontSize = this.user.depotInPreferences.fontSize;
      }
      else {
        fontSize = this.user.pbPreferences.fontSize;
      }
      if(!fontSize)
        return;
      if(this.selectedFont != fontSize) {
        this.selectedFont = fontSize;
        this.updateFont(this.selectedFont);
      }
    });
  }

  fontSetting = [
    {
      id: 'xs', isSelected: false, size: '10px'
    },
    {
      id: 'sm', isSelected: true, size: '12px'
    },
    {
      id: 'md', isSelected: false, size: '14px'
    },
    {
      id: 'lg', isSelected: false, size: '16px'
    }
  ];

  changeFontSize(val) {
    this.updateFont(val);
    this.fontChanged(val);
  }

  updateFont(val) {
    this.fontSetting.forEach(el => {
      el.isSelected = false;
      this.document.body.classList.remove(el.id);
    })

    this.fontSetting.find(el => el.id == val).isSelected = true;
    this.document.body.classList.add(val);
  }

  fontChanged(val){
    this.fontChangedEvent.emit(val);
  }
}
