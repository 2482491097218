import { DatePipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";
import * as moment from "moment";
@Pipe({
    name: 'utcDate'
})
export class UtcDatePipe extends DatePipe implements PipeTransform {
    transform(value: string, pattern?: string, dateFormat?: string): string {
        let DateTimeFormat = localStorage.getItem('defaultDateFormat').replace('"' , "" ).replace('"' , "" ) ; 
        let momentDate = dateFormat ? moment.utc(value, dateFormat) : moment.utc(value);
        if(!pattern)
            pattern = "DD/MM/yyyy HH:mm";
        if(DateTimeFormat)
            pattern = DateTimeFormat.replace('dd', 'DD') +" H:mm";
        let formattedDate = momentDate.utcOffset(moment().utcOffset());
        if(formattedDate.isValid())
        {
            if(formattedDate.year() == 1753)
                return '';
            return formattedDate.format(pattern);
        }
        return value;
    }

}
