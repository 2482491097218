
import {throwError as observableThrowError, of as observableOf,  Observable, of, throwError } from 'rxjs';
import { HttpClientService } from "./httpclient";
import { ToastrService } from "ngx-toastr";
import { Injectable } from "@angular/core";
import { PlanningBlock } from "../../pb-screen/models/planning-block/planning-block.interface";
import { catchError, flatMap, map } from "rxjs/operators";
import { TransportOrder } from "../../pb-screen/models/transport-order/transport-order.interface";
import { PRE_PLANNED, PLANNED, TO_BE_PLANNED } from "../../pb-screen/models/status-type.interface";
import { ToScreenService } from "../../pb-screen/services/to-screen.service";
import { environment } from "../../../environments/environment";
import { TranslateService } from "@ngx-translate/core";
import { PbActions } from "../../pb-screen/pb.actions";
import { ToActions } from "../../to-screen/to.actions";
import { OrderLine } from "../../pb-screen/models/order-line/orderLine.interface"
import * as  moment from "moment";
import { SharedActions } from "../shared.actions";
import { PlanningBoardActions } from "../../planningBoard/planningBoard.action";
import { select } from "@angular-redux/store";
import { ApplicationUser } from "../../pb-screen/models/application.user.interface";
import { switchMap } from 'rxjs-compat/operator/switchMap';
import { MsalService } from '../../msal';


@Injectable()
export class OrderlineService {
    @select("applicationUser") applicationUser$ : Observable<ApplicationUser>
    public user : ApplicationUser = null;

    public originalTo = {
        planningBlocks: [],
        orderlines: []
    }
    constructor(
        private readonly http: HttpClientService,
        private readonly toastr: ToastrService,
        private readonly toService: ToScreenService,
        private readonly translate: TranslateService,
        private readonly pbActions: PbActions,
        private readonly toActions: ToActions,
        private readonly authService: MsalService,
        private readonly sharedActions: SharedActions,
        public readonly planningBoardActions : PlanningBoardActions
    ) {

    }

    public cancelPb(planningBlocks: PlanningBlock[]): Observable<PlanningBlock[]> {
        return this.changePbStatus(planningBlocks, { 'statusType': PRE_PLANNED })
            .pipe(map((data) => {
                let updatedPbs = data;
                this.pbActions.updatePlanningBlocksDisplay(updatedPbs);
                this.toastr.success('The planning block has successfully been Canceled');
                return data;

            }), catchError(error => {
                return throwError(error);
            }));
    }


    public cancelTo(transportOrder: TransportOrder): Observable<any> {
        return this.toService.getPlanningBlocksForTO(transportOrder.id)
            .switchMap((to: TransportOrder) => {
                return this.changePbStatus(to.planningBlocks, { 'statusType': PRE_PLANNED })
                    .pipe(map((data) => {
                        this.pbActions.updatePlanningBlocksDisplay([...data]);
        
                        this.toService.getTransportOrders().subscribe((data) => {
                            this.toActions.updateTransportOrdersDisplay(data);
                        })
        
                        this.toService.getTransportOrderDetails(transportOrder.id).subscribe((data) => {
                            this.sharedActions.getOrderlines(data);
                        })
                        this.toActions.setSelectedTransportOrders([]);
        
                        this.toastr.success('The transport order has successfully been Canceled');
                        return data;
                    }),
                    catchError(error => {
                        return throwError(error)
                    }));
            })
    }

    public sendPb(planningBlocks): Observable<any> {
        let updatedPbs = [];
        return this.changePbStatus(planningBlocks, { 'statusType': PLANNED }).pipe(
            map((data) => {
                updatedPbs = data;
                this.pbActions.updatePlanningBlocksDisplay(updatedPbs);
                this.toastr.success('Send PB', 'The planning block has successfully been sent');
                return data;
            }),
            catchError(err => {
                return throwError(err);
            })
        );
    }

    public sendTo(transportOrder: TransportOrder) {
        this.toService.getPlanningBlocksForTO(transportOrder.id).subscribe((to: TransportOrder) => {
            this.changePbStatus(to.planningBlocks, { 'statusType': PLANNED }).subscribe((data) => {
                this.pbActions.updatePlanningBlocksDisplay([...data]);
                this.toService.getTransportOrders().subscribe((data) => {
                    this.toActions.updateTransportOrdersDisplay(data);
                })
                this.toastr.success('The transport order has successfully been sent');
                this.toService.getTransportOrderDetails(to.id).subscribe((data) => {
                    this.sharedActions.getOrderlines(data);
                })

            })
        })
    }

    public unplanTo(planningBlocks, transportOrder): Observable<any> {

        return this.changePbStatus(planningBlocks, { 'statusType': TO_BE_PLANNED })
            .switchMap((data) => {
                this.pbActions.updatePlanningBlocksDisplay([...data]);
                return this.deleteTo(transportOrder, '').pipe(map((result) => {
                    this.toService.getTransportOrders().subscribe((data) => {
                        this.toActions.getTransportOrders(data);
                    })
                    this.toastr.success("The Transport Order has successfully been unplanned");
                    this.planningBoardActions.removeTosFromCapacity([{tranportOrderNo: transportOrder.id, truckName: transportOrder.truck.name}]);
                    this.toActions.setSelectedTransportOrders([]);
                    this.planningBoardActions.setSelectionType('');
                    return data;
                }));
            })
    }

    changePbStatus(planningBlocks: PlanningBlock[], newStatus): Observable<any> {
        const account = this.authService.getAccount(); 
        let planningBlocksDelta = [];
        planningBlocks.forEach((element) => {
            planningBlocksDelta.push({ Id: element.id, Status: newStatus, TransportOrderNumber: element.transportOrderNumber })
        });
        return this.http.patch(environment.tmsApiEndpointAddress + 'planningblocks', { PlanningBlocks: planningBlocksDelta, userName: account.username }).pipe(
            map((res: any) => {
                return res.data;
            }),
            catchError((err) => {
                return observableThrowError(err)
            })
        )
    }

    changeToStatus(toId, newStatus, params) {
        params = params || "";
        return this.http.patch(environment.tmsApiEndpointAddress + 'planningblocks?transportOrderId=' + toId + params, {
            'Status': newStatus
        }).pipe(
            map((res: any) => {
                return res.data;
            }),
            catchError((error) => {
                return observableThrowError(error)
            })
        )
    }

    public sendNextPb(transportOrder: TransportOrder) {
        let originalTo = {
            planningBlocks: [],
            orderlines: []
        }

        return this.changeToStatus(transportOrder.id, { 'statusType': PLANNED }, '&filter=nextPb')
        .pipe(map((data) => {
            originalTo.planningBlocks = data;
            if(originalTo.planningBlocks.length >= 1 ) {
                this.pbActions.updatePlanningBlocksDisplay(originalTo.planningBlocks);
            }

            this.toService.getTransportOrderDetails(transportOrder.id).subscribe((data) => {
                this.sharedActions.getOrderlines(data);
            })
            this.translate.get('TOASTR_MESSAGES.SUCCESS.COLUMN_SELECTION_SUCCESS').subscribe((res: string) => {
                this.toastr.success('The next planning block has successfully been sent');
            });
            return data;
        }));
    }

    public resendTo(transportOrderId) : Observable<TransportOrder[]>{
        const account = this.authService.getAccount();
        return this.http.get(environment.tmsApiEndpointAddress + "/transportorders/ResendID?transportorderid=" + transportOrderId
        + "&userName=" + account.username)
        .pipe(
            map((res : any) => res.data) ,
            catchError((err  : any) => observableThrowError(err))
        );
    }

    deleteTo(transportOrder, capacityId) {
        return this.http.delete(environment.tmsApiEndpointAddress + 'transportorders/id?toId=' + transportOrder.id + "&capacityId=" + capacityId)
            .pipe((
                map((res: any) => {
                    return res.data;
                })
            ),
                catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }

    updateOrderline(planningBlockId, orderlineId, orderline, ignoreValidation) {
        return this.http.put(environment.tmsApiEndpointAddress + 'planningBlocks/' + planningBlockId +
            "/orderlines/" + orderlineId +
            '?ingoreValidation=' + ignoreValidation, orderline).pipe(
                map((res: any) => {
                    return res.data;
                })
                ,catchError((err  : any) => observableThrowError(err))
            )
    }

    public updateOrderlineReferences(params: any) {
        return this.http.post(environment.tmsApiEndpointAddress + 'transportorders/update-orderline-references?orderNumber', params)
            .pipe(
                map((res: any) => res.data),
                catchError((err:any) => observableThrowError(err))
            );
    };

    public deleteOrderline(orderlineId: number) {
        return this.http.delete(environment.tmsApiEndpointAddress + 'transportorders/delete-orderline?orderlineId=' + orderlineId )
            .pipe(
                map((res: any) => res.data),
                catchError((err:any) => observableThrowError(err))
            );
    };

    public addEquipmentToETAEquipment(params: any) {
        return this.http.post(environment.tmsApiEndpointAddress + 'transportorders/add-equipment-to-eta', params)
            .pipe(
                map((res: any) => res.data),
                catchError((err:any) => observableThrowError(err))
            );
    };

    public groupSelectedLines(transportOrder , transportOrderId, lineOrderIds) : Observable<any> {
        return this.http.put(environment.tmsApiEndpointAddress + "transportOrders/GroupSelectedLines?orderLineIds=" + lineOrderIds + "&transportOrderId=" + transportOrderId
        , transportOrder).pipe(
            map((res : any) => res.data) ,
            catchError((err  : any) => observableThrowError(err))
        );
    }

    public unGroupSelectedLines(transportOrder , transportOrderId , lineOrderIds) : Observable<any> {
        return this.http.put(environment.tmsApiEndpointAddress + "transportOrders/UngroupSelectedLines?orderLineIds=" + lineOrderIds + "&transportOrderId=" + transportOrderId
        , transportOrder).pipe(
            map((res : any) => res.data) ,
            catchError((err  : any) => observableThrowError(err))
        );
    }
}
