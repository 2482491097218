import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { ActionKeys } from "../pb-screen/action.keys";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { GetCmrActionsService } from "../pb-screen/services/getCmrActions.service";
import { PbScreenService } from "../pb-screen/services/pb-screen.service";
import { UserSettingsService } from "../pb-screen/services/usersettings.service";
import { IAppState } from "../store/IAppState";
import { NotificationService } from "./services/notification.service";
import { PlanningConflictsService } from "./services/planning-conflicts.service";
import { SharedService } from "./services/shared.service";

@Injectable()
export class AgendaActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
        private readonly sharedService: SharedService,
        private readonly notificationService: NotificationService,
        private readonly userSettingsService: UserSettingsService,
        private readonly getCmrActionsService: GetCmrActionsService,
        private readonly planningConflictsService: PlanningConflictsService,
        private pbScreenService: PbScreenService
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    setSelectedDriverFromAgenda(selectedDriverFromAgenda) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_DRIVER_FROM_AGENDA,
            selectedDriverFromAgenda : selectedDriverFromAgenda
        })
    }

    setSelectedTruckAssignement(selectedTruckAssignement) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_TRUCK_ASSIGNEMENT,
            selectedTruckAssignement : selectedTruckAssignement
        })
    }
    
    setSelectedDriverAbsence(selectedDriverAbsence) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_ABSENCE,
            selectedDriverAbsence : selectedDriverAbsence
        })
    }

    setDriverAgendaData(driverAgendaData) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_DRIVER_AGENDA_DATA,
            driverAgendaData : driverAgendaData
        })
    }



    setSelectedTruckFromAgenda(selectedTruckFromAgenda) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_TRUCK_FROM_AGENDA,
            selectedTruckFromAgenda : selectedTruckFromAgenda
        })
    }

    setSelectedAssignement(selectedAssignement) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_ASSIGNEMENT,
            selectedAssignement : selectedAssignement
        })
    }
    
    setSelectedUnavailability(selectedUnavailability) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_UNVAIILABILITY,
            selectedUnavailability : selectedUnavailability
        })
    }

    setTruckAgendaData(truckAgendaData) {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_TRUCK_AGENDA_DATA,
            truckAgendaData : truckAgendaData
        })
    }
}