import { Component, Input, Injectable } from '@angular/core';
import { PlanningBlock } from '../../../models/planning-block/planning-block.interface';
import { TransportOrder } from '../../../models/transport-order/transport-order.interface';
import { OrderLine } from '../../../models/order-line/orderLine.interface';
import { PbActions } from '../../../pb.actions';


@Component({
    moduleId: module.id,
    selector: 'selection-display',
    templateUrl: './selection-display.component.html'
})

export class SelectionDisplayComponent {
    constructor(public readonly pbActions: PbActions) { }
    
    @Input() selectedPlanningBlocks: PlanningBlock[];
    @Input() selectedTransportOrders: TransportOrder[];
    @Input() selectedOrderlines: OrderLine[];
    @Input() public source : string ;

    public popSelectedPb(planningBlock: PlanningBlock) {
        let filteredArray = this.selectedPlanningBlocks.filter((pb: PlanningBlock) => pb.id != planningBlock.id);
        let seq = 1;
        filteredArray.forEach(pb => {
            pb.selectionSequence = seq; 
            seq++;
        });
        this.pbActions.setSelectedPlanningBlocks([...filteredArray]);
        this.selectedPlanningBlocks = filteredArray;
    }

    public moveUp(planningBlock : PlanningBlock) {


        var newSeq = planningBlock.selectionSequence - 1 ; 
        var previousPb = this.selectedPlanningBlocks.filter((pb) => {
            return pb.selectionSequence == newSeq ; 
        })
        previousPb[0].selectionSequence = newSeq + 1; 
        planningBlock.selectionSequence = newSeq ;
        this.selectedPlanningBlocks[this.selectedPlanningBlocks.indexOf(planningBlock)] = planningBlock;
        this.selectedPlanningBlocks[this.selectedPlanningBlocks.indexOf(previousPb[0])] = previousPb[0];
        
        
        
        let sortedArray = this.selectedPlanningBlocks.sort((a,b) =>  a.selectionSequence - b.selectionSequence );
     
        this.pbActions.setSelectedPlanningBlocks([...sortedArray]);
    
    }

    public moveDown(planningBlock : PlanningBlock) {

        var newSeq = planningBlock.selectionSequence + 1 ; 
        var nextPb = this.selectedPlanningBlocks.filter((pb) => {
            return pb.selectionSequence == newSeq ; 
        })
        nextPb[0].selectionSequence = newSeq - 1; 
        planningBlock.selectionSequence = newSeq ;
        this.selectedPlanningBlocks[this.selectedPlanningBlocks.indexOf(planningBlock)] = planningBlock;
        this.selectedPlanningBlocks[this.selectedPlanningBlocks.indexOf(nextPb[0])] = nextPb[0];
        
        
        let sortedArray = this.selectedPlanningBlocks.sort((a,b) =>  a.selectionSequence - b.selectionSequence );
     
        this.pbActions.setSelectedPlanningBlocks([...sortedArray]);
    }

    public pbIsNotFirstOrLast(planningBlock : PlanningBlock)
    {
        return planningBlock.selectionSequence !== 1 && planningBlock.selectionSequence !== this.selectedPlanningBlocks.length ; 
    }
}