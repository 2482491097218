import { AnnouncementQuickFilter, PoolContainerStatusFilter, QuickFilter } from "../../depot-out/models/depot-out.interface";
import { PoolContainer } from "../../pool/models/pool-containers.interface";
import { PoolMove } from "../../pool/models/pool-move.interface";
import { getFilteredAnnouncedContainers, getFilteredContainersBySearchText } from "./announcement.text.filer";
import { isOfSelectedPoolContainerState } from "./pool-container-status.filter";

const columnNames = [
    { title: "Container number", field: "containerNo" }, { title: "Container Type", field: "containerType" },
    { title: "Carrier Name", field: "carrierName" }, { title: "Status", field: "status" },
    { title: "Location", field: "location" }, { title: "Pool adress Name", field: "poolAddressSearchName" },
    { title: "Comment", field: "comment" }
];

export function FilterPoolContainers(poolContainers: PoolContainer[], textFilter: string, quickFilter: AnnouncementQuickFilter): PoolContainer[] {
    let containers: PoolContainer[] = [...poolContainers];
    const matches: PoolContainer[] = [];

    if (textFilter.length > 0) {
        containers = getFilteredContainersBySearchText(
            containers,
            textFilter,
        );
    }

    for (const container of containers) {
        const isContainerPoolStatusMatch: boolean = isOfSelectedPoolContainerState(container,
            quickFilter.containerPoolStatus as PoolContainerStatusFilter);
        const isCarrierMatch: boolean = isCarrierSearchMatch(quickFilter, container);
        const isContainerTypeMatch: boolean = isContainerTypeSearchMatch(quickFilter, container);
        const isContainerNoMatch: boolean = isContainerNoSearchMatch(quickFilter, container);
        if (isContainerPoolStatusMatch &&
            isCarrierMatch &&
            isContainerTypeMatch &&
            isContainerNoMatch) {
            matches.push(container);
        }
    }

    return matches;
}

export function FilterAnnouncedContainers(announcedContainer: PoolContainer[], textFilter: string, quickFilter: AnnouncementQuickFilter): PoolContainer[] {
    const matches: PoolContainer[] = [];
    let containers: PoolContainer[] = [...announcedContainer];

    if (textFilter.length > 0) {
        containers = getFilteredAnnouncedContainers(
            containers,
            textFilter,
        );
    }

    for (const container of containers) {

        const isCarrierMatch: boolean = isCarrierSearchMatch(quickFilter, container);
        const isContainerTypeMatch: boolean = isContainerTypeSearchMatch(quickFilter, container);

        if (isCarrierMatch &&
            isContainerTypeMatch) {
            matches.push(container);
        }
    }
    return matches;
}

function isCarrierSearchMatch(quickFilter: AnnouncementQuickFilter, container: PoolContainer) {
    if (quickFilter.carrier) {
        return container.carrierCode.toLowerCase() === quickFilter.carrier.toLowerCase()
        && container.carrierCode.length > 0;
    }
    return true;
}

function isContainerTypeSearchMatch(quickFilter: AnnouncementQuickFilter, container: PoolContainer) {
    if (quickFilter.containerType) {
        return container.containerType.toLowerCase() === quickFilter.containerType.toLowerCase()
        && container.containerType.length > 0;
    }
    return true;
}

function isContainerNoSearchMatch(quickFilter: AnnouncementQuickFilter, container: PoolContainer) {
    if (quickFilter.containerNo) {
        return container.containerNo.toLowerCase().includes(quickFilter.containerNo.toLowerCase())
        && container.containerNo.length > 0;
    }
    return true;
}


/*Moves Filter*/

export function FilterPreAnnouncedContainerMoves(poolMoves: PoolMove[], quickFilter: AnnouncementQuickFilter): PoolMove[] {
    let moves: PoolMove[] = [...poolMoves];
    const matches: PoolMove[] = [];
    for (const move of moves) {
        const isCarrierMatch: boolean = isMoveCarrierSearchMatch(quickFilter, move);
        const isContainerTypeMatch: boolean = isMoveContainerTypeSearchMatch(quickFilter, move);
        const isContainerNoMatch: boolean = isMoveContainerNoSearchMatch(quickFilter, move);
        if (
            isCarrierMatch &&
            isContainerTypeMatch &&
            isContainerNoMatch) {
            matches.push(move);
        }
    }

    return matches;
}


function isMoveCarrierSearchMatch(quickFilter: AnnouncementQuickFilter, move: PoolMove) {
    
    if (quickFilter.carrier) {
        return move.carrierCode.toLowerCase() === quickFilter.carrier.toLowerCase()
        && move.carrierCode.length > 0;
    }
    return true;
}

function isMoveContainerTypeSearchMatch(quickFilter: AnnouncementQuickFilter, move: PoolMove) {
    if (quickFilter.containerType) {
        return move.containerType.toLowerCase() === quickFilter.containerType.toLowerCase()
        && move.containerType.length > 0;
    }
    return true;
}

function isMoveContainerNoSearchMatch(quickFilter: AnnouncementQuickFilter, move: PoolMove) {
    if (quickFilter.containerNo) {
        return move.announced.toLowerCase().includes(quickFilter.containerNo.toLowerCase())
        && move.announced.length > 0;
    }
    return true;
}