import { IdNameAsStringObject } from "./quick-view/id-name-as-string-object.interface";
import { HasConflicts } from "./planning-conflict-type.enum";

export interface QuickFilter {
    planningState: PlanningState;
    planningGroups?: string[];
    planningZone?: IdNameAsStringObject;
    startDate?: Date;
    endDate?: Date;
    firstAction?: string;
    lastAction?: string;
    loadingPlace?: string;
    unLoadingPlace?: string;
    customer?: string;
    containerStatus: string[];
    unloadDateFrom?: Date;
    unloadDateTo?: Date;
    pickupDateFrom?: Date;
    pickupDateTo?: Date;
    vgmClosing?: Date;
    specificVgmClosing: boolean;
    specificClosing: boolean;
    specificStartDate: boolean;
    specificEndDate: boolean;
    closing?: Date;
    containerType?: string;
    latestLocationCode?: string;
    transportTypes?: string[];
    tripTypes?: string[];
    carrier?: string;
    deliveryRegion?: string;
    collectionRegion?: string;
    processedOnly?: boolean;
    importantOnly?: boolean;
    deliveryMasterRegion?: string;
    collectionMasterRegion?: string;
    collectionDate?: Date;
    specificCollectionDate: boolean;
    deliveryDate?: Date;
    specificDeliveryDate: boolean;
    trailerType?: string;
    trailerSubType?: string;
    truckType?: string;
    truckSubType?: string;
    orderDate?: Date;
    specificOrderDate?: boolean;
    selectedOnly?: boolean;
    toFinalizeCombi: ToFinalizeCombi;
    truckDepartments?: string[];
    etaFrom?: Date;
    etaLocation?: string;
    actionIconFilter?: ActionIconFilter;
    colDelPlanning?: string;
    firstColAddressStreet?: string;
    firstDelAddressStreet?: string;
    pbFirstCollectionFrom?: Date
    specificPbFirstCollectionFrom? : boolean;
}

export enum ColDelPlanning {
  Unknown,
  Collection,
  Delivery,
  CollectionDelviery
}

export interface PlanningState {
    toBePlanned?: boolean;
    prePlanned?: boolean;
    planned?: boolean;
    inProgress?: boolean;
    onHold?: boolean;
    created?: boolean;
    closed?:boolean;
    reused?:boolean;
}

export interface ToFinalizeCombi {
    yes?: boolean;
    no?: boolean;
    all?: boolean;
}

export interface ContainerState {
    leegCrea?: boolean;
    leegAf?: boolean;
    geladen?: boolean;
    volKlaar?: boolean;
    volIn?: boolean;
    volCrea?: boolean;
    volAf?: boolean;
    gelost?: boolean;
    leegKlaar?: boolean;
    leegIn?: boolean;
}

export interface ListItem {
    id: number;
    value: string;
}

export interface ActionIconFilter {
  scanning: boolean;
  customs: boolean;
  weighing: boolean;
  ADR: boolean;
  T1: number;
  temperature: number;
  conflicts: HasConflicts;
  highValue: boolean;
  notifications: number;
  hasPbRemarks: boolean;
  preAnnouncementStatus: number;
  truckAppointmentStatus: number;
  containerReleaseStatus: number;
  documents: boolean;
}

export enum ContainerReleaseStatus{
    NotRequired,
    Required,
    Received,
    Deleted,
    Revoked,
    Blocked,
    Discharged
}
