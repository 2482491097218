export class PoolHaulier {
    timestamp: string;
    number: string;
    name: string;
    haulierCompany: string;
    createdBy: string;
    creationDateTime: string;
    lastModifiedBy: string;
    lastModificationDateTime: string;

    constructor() {
        this.timestamp = "";
        this.number = "";
        this.name = "";
        this.haulierCompany = "";
        this.createdBy = "";
        this.creationDateTime = "";
        this.lastModifiedBy = "";
        this.lastModificationDateTime = "";
    }
    
}