import { select } from '@angular-redux/store';
import { Inject, Injectable } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Observable ,  Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { SignalrWindow } from '../../pb-screen/services/channel.service';
import { ChannelEvent } from '../../pb-screen/services/channel.event.factory';
import { ApplicationUser } from '../../pb-screen/models/application.user.interface';
import * as moment from 'moment';



export function getWindow() { return window; }


export enum ConnectionState {
  Connecting = 1,
  Connected = 2,
  Reconnecting = 3,
  Disconnected = 4,
}

// tslint:disable-next-line:max-classes-per-file
class ChannelSubject {
  public channel: string;
  public subject: Subject<ChannelEvent>;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class PBoardChannelService {

  public starting$: Observable<any>;
  public connectionState$: Observable<ConnectionState>;
  public error$: Observable<string>;
  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;

  private connectionStateSubject = new Subject<ConnectionState>();
  private startingSubject = new Subject<any>();
  private errorSubject = new Subject<any>();
  public hubConnection: any;
  public hubProxy: any;
  private subjects: ChannelSubject[] = [];
  private events: ChannelEvent[] = [];
  private currentStatus: ConnectionState;
  private myChannels: string[] = [];
  private hubName: string = 'DataMonitorHub';
  private hubUrl: string = environment.hubEndpointAddress + '/signalr';
  private userId: string = '';

  constructor(
    @Inject(SignalrWindow) public window: SignalrWindow,
    private readonly logger: NGXLogger,
  ) {
    if (this.window.$ === undefined || this.window.$.hubConnection === undefined) {
      // tslint:disable-next-line:max-line-length
      throw new Error('The variable "$" or the .hubConnection() function are not defined...please check the SignalR scripts have been loaded properly');
    }

    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.userId = applicationUser.id;
      }
    });

    this.connectionState$ = this.connectionStateSubject.asObservable();
    this.error$ = this.errorSubject.asObservable();
    this.starting$ = this.startingSubject.asObservable();

    this.hubConnection = this.window.$.hubConnection();
    this.hubConnection.url = this.hubUrl;
    this.hubProxy = this.hubConnection.createHubProxy(this.hubName);

    this.connectionState$.subscribe((newState: ConnectionState) => {
      this.currentStatus = newState;
    });

    this.hubConnection.stateChanged((state: any) => {
      let newState = ConnectionState.Connecting;

      switch (state.newState) {
        case this.window.$.signalR.connectionState.connecting:
          newState = ConnectionState.Connecting;
          break;
        case this.window.$.signalR.connectionState.connected:
          newState = ConnectionState.Connected;
          break;
        case this.window.$.signalR.connectionState.reconnecting:
          newState = ConnectionState.Reconnecting;
          break;
        case this.window.$.signalR.connectionState.disconnected:
          newState = ConnectionState.Disconnected;
          break;
      }
      this.connectionStateSubject.next(newState);
      if (newState === ConnectionState.Connected) {
        setTimeout(() => { this.publishFromQueue(); }, 4000);
      }
    });

    this.hubConnection.error((error: any) => {
      // Push the error on our subject
      //
      this.errorSubject.next(error);
    });

    this.hubProxy.on('onEvent', (channel: string, ev: ChannelEvent) => {
      const channelSub = this.subjects.find((x: ChannelSubject) => {
        return x.channel === channel;
      }) as ChannelSubject;

      if (channelSub !== undefined && channelSub != null) {
        return channelSub.subject.next(ev);
      }
    });


    this.hubConnection.disconnected(() => {
      this.logger.error('SignalR disconnected for all channels on the client', this.userId,
        'disconnected', 'SIGNALR', '');

      const that = this;
      setTimeout(() => {
        that.hubConnection.start()
          .done(() => {
            that.logger.debug('Trying to restart the connection for all channels', this.userId,
              'starting', 'SIGNALR');
            that.startingSubject.next();
            that.myChannels.forEach((channel) => {
              const channelSub = new ChannelSubject();
              channelSub.channel = channel;
              channelSub.subject = new Subject<ChannelEvent>();
              that.subjects.push(channelSub);
              that.hubProxy.invoke('subscribe', channel)
                .done(() => {
                  that.logger.debug('Reconnected to channel: ${channel}', this.userId, 'subscribing', 'SIGNALR');
                })
                .fail((error: any) => {
                  that.logger.error(error, this.userId, 'subscribing', 'SIGNALR');
                  channelSub.subject.error(error);
                });
            });
          })
          .fail((error: any) => {
            that.logger.error(error, this.userId, 'subscribing', 'SIGNALR');
            that.startingSubject.error(error);
          });
      });

    }
      , 15000);
  }


  public start(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.hubConnection.start()
        .done(() => {
          this.logger.debug('SignalR started', this.userId, 'start', 'SIGNALR', '');
          this.startingSubject.next();

          resolve();
        })
        .fail((error: any) => {
          this.logger.error('Failed to start signalr', this.userId, 'starting', 'SIGNALR', '');
          this.startingSubject.error(error);

          reject();
        });
    });

  }

  public sub(channel: string): Observable<ChannelEvent> {
    if (this.myChannels.findIndex((c) => c === channel) < 0) {
      this.myChannels.push(channel);
    }
    let channelSub = this.subjects.find((x: ChannelSubject) => {
      return x.channel === channel;
    }) as ChannelSubject;

    if (channelSub !== undefined && channelSub != null) {
      this.logger.debug('Found existing observable for channel: ' + channel, this.userId, 'start', 'SIGNALR', '');
      return channelSub.subject.asObservable();
    }

    channelSub = new ChannelSubject();
    channelSub.channel = channel;
    channelSub.subject = new Subject<ChannelEvent>();
    this.subjects.push(channelSub);

    this.hubProxy.invoke('subscribe', channel)
      .done(() => {
        this.logger.debug('Successfully subscribed to channel: ' + channel, this.userId, 'start', 'SIGNALR', '');
      })
      .fail((error: any) => {
        channelSub.subject.error(error);
      });

    return channelSub.subject.asObservable();
  }

  public publish(ev: ChannelEvent): void {
    if (this.currentStatus === ConnectionState.Connected && this.events.length === 0) {
      this.hubProxy.invoke('publish', ev);
    } else {
      this.events.push(ev);
    }
  }

  public publishFromQueue(): void {
    while (this.events.length > 0 && this.currentStatus === ConnectionState.Connected) {
      this.hubProxy.invoke('publish', this.events[0]);
      this.events.shift();
    }
  }


  public startPlanningblocksMonitor(monitorSource, zone, group, truckDepartments, startDate, endDate, status, transportType) {
    this.hubProxy.invoke('startPlanningblocksMonitor', monitorSource, zone, group, truckDepartments,
      this.formatDate(startDate), this.formatEndDate(endDate), status, transportType);

    console.info(" startPlanningblocksMonitor for parameters :" + monitorSource, zone, group, truckDepartments,
      this.formatDate(startDate), this.formatEndDate(endDate), status, transportType);

  }

  public startWeekPlanningPbsMonitor(monitorSource, zone, group, truckDepartments, startDate, endDate, status, transportType) {
    this.hubProxy.invoke('startWeekPlanningPBsMonitor', monitorSource, zone, group, truckDepartments,
      this.formatDate(startDate), this.formatEndDate(endDate), status, transportType);

    console.info(" startWeekPlanningPBsMonitor for parameters :" + monitorSource, zone, group, truckDepartments,
      this.formatDate(startDate), this.formatEndDate(endDate), status, transportType);

  }

  public startWeekPlanningMonitor(group,truckDepartments,zone,startDate,endDate,withQuotes) {
    this.hubProxy.invoke('startWeekPlanningMonitor', group, truckDepartments, zone, this.formatDate(startDate), this.formatEndDate(endDate), withQuotes);
    console.info(" startWeekPlanningMonitor for parameters :" + { group, truckDepartments, zone, startDate, endDate, withQuotes });

  }



  private setDate(date) {
    const dateArray = date.split('/');
    const formattedDate = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
    return new Date(formattedDate);
  }

  private formatDate(date) {
    return moment(this.setDate(date), "DD/MM/YYYY").hour(0).minute(0).seconds(0).toISOString();

  }
  private formatEndDate(date) {
    return moment(this.setDate(date), "DD/MM/YYYY").hour(23).minute(59).seconds(0).toISOString();

  }
}
