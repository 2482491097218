import * as InitialState from './initial.state';
import { IAppState } from './IAppState';
import { ActionKeys } from '../pb-screen/action.keys';
import { TransportOrder } from '../pb-screen/models/transport-order/transport-order.interface';
import { getFilteredTransportOrdersBySearchText } from '../pb-screen/helpers/filter.by.text';
import { filterTransportOrders } from '../to-screen/containers/filter/to.quickfilter';
import * as moment from 'moment';

export function ToReducer(state: IAppState = InitialState.initialState, action) {
  switch (action.type) {
    case ActionKeys.REQUEST_TRANSPORTORDERS_SUCCESS:
      return storeTransportOrders(state, action);
    case ActionKeys.REQUEST_OPEN_TRANSPORTORDERS_SUCCESS:
      return storeOpenTransportOrders(state, action);
    case ActionKeys.APPLY_TO_GLOBAL_FILTER:
      return applyGlobalFilter(state, action);
    case ActionKeys.FILTER_OPEN_TRANSPORT_ORDERS:
      return filterOpenTransportOrders(state, action);
    case ActionKeys.RESET_OPEN_TRANSPORTORDERS_FILTER:
      return resetOpenTransportOrdersFilter(state, action);
    case ActionKeys.UPDATE_TO_SESSION_COLUMNS:
      return updateSessionToColumnSelection(state, action);
    case ActionKeys.SET_SELECTED_TRANSPORT_ORDERS:
      return setSelectedTransportOrders(state, action);
    case ActionKeys.UPDATE_ORDERLINE_SESSION_COLUMNS:
      return updateSessionOrderlineColumnSelection(state, action);
    case ActionKeys.SET_SELECTED_ORDER_LINES:
      return setSelectedOrderlines(state, action);
    case ActionKeys.APPLY_TO_QUICK_FILTER:
      return applyToQuickFilter(state, action);
    case ActionKeys.RESET_TO_QUICK_FILTER:
      return resetToQuickfilter(state, action);
    case ActionKeys.SET_TO_PROCESSED_DISPLAY_STATE:
      return setProcessedDisplayState(state, action);
    case ActionKeys.RESET_TRANSPORTORDERS:
      return resetTransportOrders(state, action);
    case ActionKeys.UPDATE_TOS_DISPLAY:
      return updateTransportOrdersDisplay(state, action);
    case ActionKeys.SET_TO_ACTION_BAR_STATE:
      return setActionBarStates(state, action);
    case ActionKeys.RESET_TO_ACTION_BAR_STATE:
      return resetActionBarStates(state, action);
    case ActionKeys.SET_FINALIZED_COMBI_STATE:
      return setFinalizedCombiState(state, action);
    case ActionKeys.SELECTED_TO_FOR_DETAILS: 
      return selectedToForDetails(state,action);
  }
}

function storeTransportOrders(state: IAppState, action): IAppState {

  return Object.assign({}, state, {
    transportOrders: action.transportOrders,
    filteredTransportOrders: action.transportOrders,
    quickFilteredTransportOrders: action.transportOrders
  })
}

function storeOpenTransportOrders(state: IAppState, action): IAppState {

  return Object.assign({}, state, {
    openTransportOrders: action.openTransportOrders,
    filteredOpenTransportOrders : action.openTransportOrders
  });
}


function setSelectedTransportOrders(state: IAppState, action): IAppState {

  if(action.selectedTransportOrders.length > 0 ) 
  {
  return Object.assign({}, state, {
    selectedTransportOrders: [...action.selectedTransportOrders] , 
    sharedSelectedPlanningBlocks: []
  }) 
  }
  else { 

    return Object.assign({}, state, {
      selectedTransportOrders: [...action.selectedTransportOrders] , 
      
      
    }); 
  }
}



function setSelectedOrderlines(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    selectedOrderlines: [...action.selectedOrderlines]
  })
}

function applyGlobalFilter(state: IAppState, action): IAppState {
  if (action.searchText.trim().length >= 1) {
    const tosToFilter = state.quickFilteredTransportOrdersNoText.length > 0 ?
      state.quickFilteredTransportOrdersNoText : state.transportOrders;
    const filtered = getFilteredTransportOrdersBySearchText(tosToFilter, action.searchText.trim(), state.sessionToColumnSelection);

    return Object.assign({}, state, {
      quickFilteredTransportOrdersNoText: tosToFilter,
      filteredTransportOrders: filtered,
      searchText: action.searchText
    })
  }
  const tos = state.quickFilteredTransportOrdersNoText.length > 0 ? state.quickFilteredTransportOrdersNoText : state.transportOrders;

  return Object.assign({}, state, {
    filteredTransportOrders: tos,
    searchText: action.searchText
  })
}

function filterOpenTransportOrders(state : IAppState, action) : IAppState {
  const columns: string[] = action.columnFields;
  const filter: string = action.filterValue;
  const openTransportOrders: TransportOrder[] = state.openTransportOrders;
  let filteredOpenTransportOrders: TransportOrder[] = [];

  if (filter !== "") {
      openTransportOrders.forEach((to: TransportOrder) => {
          columns.forEach((columnName: string) => {
              if (to[columnName].toString().toLowerCase().includes(filter.toLowerCase()) && !filteredOpenTransportOrders.includes(to)) {
                filteredOpenTransportOrders.push(to);
              }
          });
      });
  }

  return Object.assign({}, state, {
    filteredOpenTransportOrders: filter === "" ? openTransportOrders : filteredOpenTransportOrders,
    searchTextForOpenTOs : filter
  });
}

function resetOpenTransportOrdersFilter(state : IAppState, action) : IAppState {
  return Object.assign({}, state, {
    filteredOpenTransportOrders: state.openTransportOrders,
    searchTextForOpenTOs : ''
  });
}

function updateSessionToColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionToColumnSelection: action.columns });
}


function updateSessionOrderlineColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionOrderLineColumnSelection: action.columns });
}





function applyToQuickFilter(state: IAppState, action): IAppState {
  let matches: TransportOrder[] = [];
  let transportOrders: TransportOrder[] = [];
  if (action.ToQuickFilter.etaFrom) {
    transportOrders = state.transportOrders.filter(to => to.etaFrom.toString() !== "0001-01-01T00:00:00Z"
      && to.etaFrom.toString() !== "1753-01-01T00:00:00Z"
     && moment(to.etaFrom).format("YYYY Do MMM") === moment(action.ToQuickFilter.etaFrom).format("YYYY Do MMM"));
  } else {
    transportOrders = state.transportOrders;
  }
  if (!action.ToQuickFilter.operationalStatus.created &&
    !action.ToQuickFilter.operationalStatus.closed &&
    !action.ToQuickFilter.operationalStatus.inProgress &&
    !action.ToQuickFilter.toDate &&
    !action.ToQuickFilter.haulierName &&
    !action.ToQuickFilter.firstTruckDescription &&
    !action.ToQuickFilter.driver &&
    !action.ToQuickFilter.firstCoDriver &&
    !action.ToQuickFilter.planningZone &&
    !action.ToQuickFilter.planningGroups) {
    const ToSearchTextTrimed = state.searchText.trim();
    if (ToSearchTextTrimed.length >= 1) {
      matches = getFilteredTransportOrdersBySearchText(transportOrders, ToSearchTextTrimed, state.sessionToColumnSelection);
      return Object.assign(state, {
        quickFilteredTransportOrdersNoText: transportOrders,
        filteredTransportOrders: matches,
        quickFilteredTransportOrders: matches,
        toQuickFilter: null

      })
    }
    return Object.assign({},
      state,
      {
        quickFilteredTransportOrdersNoText: transportOrders,
        filteredTransportOrders: transportOrders,
        quickFilteredTransportOrders: transportOrders,
        toQuickFilter: null
      });
  }
  matches = filterTransportOrders(transportOrders,
    action.ToQuickFilter,
    '',
    state.sessionToColumnSelection, state.selectedTransportOrders);

  const ToSearchTextTrimed = state.searchText.trim();

  if (ToSearchTextTrimed.length >= 1) {
    const noTextMatches: TransportOrder[] = matches;
    matches = getFilteredTransportOrdersBySearchText(matches,
      ToSearchTextTrimed,
      state.sessionToColumnSelection);
    return Object.assign({},
      state,
      {
        quickFilteredTransportOrdersNoText: noTextMatches,
        filteredTransportOrders: matches,
        quickFilteredTransportOrders: matches,
        toQuickFilter: { ...action.ToQuickFilter }
      });
  }
  return Object.assign({},
    state,
    {
      quickFilteredTransportOrdersNoText: matches,
      filteredTransportOrders: matches,
      quickFilteredTransportOrders: matches,
      toQuickFilter: { ...action.ToQuickFilter }
    });
}

function resetToQuickfilter(state, action): IAppState {
  return Object.assign({},
    state,
    {
      toQuickFilter: null,
    });
}

function setProcessedDisplayState(state, action): IAppState {
  return Object.assign({}, state, {
    toProcessedDispalyState: action.toProcessedDispalyState
  })
}

function resetTransportOrders(state, action): IAppState {
  let transportOrders: TransportOrder[] = [];

  transportOrders = state.transportOrders;

  if (state.quickFilteredTransportOrdersNoText && state.quickFilteredTransportOrdersNoText.length > 0) {
    return Object.assign({}, state, {
      depotInSsearchTextearchText: '',
      filteredTransportOrders: state.quickFilteredTransportOrdersNoText,
      quickFilteredTransportOrders: state.quickFilteredTransportOrdersNoText,
    });
  }
  return Object.assign({}, state, {
    searchText: '',
    transportOrders: transportOrders,
    quickFilteredTransportOrders: transportOrders,
  });
}

function updateTransportOrdersDisplay(state: IAppState, action): IAppState {
  const transportOrders: TransportOrder[] = state.transportOrders;
  const quickFilteredTransportOrders: TransportOrder[] = state.quickFilteredTransportOrdersNoText;
  const filteredTransportOrders: TransportOrder[] = state.transportOrders;
  const returnedTransportOrders: TransportOrder[] = action.transportOrders;

  returnedTransportOrders.map((returnedTo: TransportOrder) => {
    const index = transportOrders.findIndex(d => d.id == returnedTo.id);
    transportOrders[index] = returnedTo;

    const filteredIndex = filteredTransportOrders.findIndex(d => d.id == returnedTo.id);
    filteredTransportOrders[filteredIndex] = returnedTo;

    const quickfilteredIndex = quickFilteredTransportOrders.findIndex(d => d.id == returnedTo.id);
    quickFilteredTransportOrders[quickfilteredIndex] = returnedTo;
  });

  return Object.assign({},
    state,
    {
      transportOrders: [...transportOrders],
      quickFilteredTransportOrdersNoText: [...quickFilteredTransportOrders],
      filteredTransportOrders: [...filteredTransportOrders],
    });
}
function setActionBarStates(state, action): IAppState {
  return Object.assign({}, state, {
    toPlanBtnState : action.toactionBarStates.toPlanBtnState,
    cancelPbBtnState: action.toactionBarStates.cancelPbBtnState,
    unplanPbBtnState: action.toactionBarStates.unplanPbBtnState,
    sendPbBtnState: action.toactionBarStates.sendPbBtnState,
    pbDetailsFromTo: action.toactionBarStates.pbDetailsFromTo
  });
}

function resetActionBarStates(state, action): IAppState {
  return Object.assign({}, state, {
    toPlanBtnState: false,
    cancelPbBtnState: false,
    unplanPbBtnState: false,
    sendPbBtnState: false,
    pbDetailsFromTo: false
  });
}

function setFinalizedCombiState(state, action): IAppState {
  return Object.assign({}, state, {
    finalizedCombiState: action.finalizedCombiState
  })
}


function selectedToForDetails(state , action) : IAppState {
  return Object.assign({}, state, {
    toSelectionForDetails: action.toSelectionForDetails
  }) 
}
