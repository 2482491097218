
import { PlanningState } from "../depot-in/models/depot-in.interface";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { IN_PROGRESS, ON_HOLD, PLANNED, PRE_PLANNED, TO_BE_PLANNED, PlanningStatus, RE_USED , CREATED } from "./models/status-type.interface";

export function isOfSelectedPlanningState(planningBlock: PlanningBlock, planningState: PlanningState): boolean {
        if (!planningState.toBePlanned &&
          !planningState.inProgress &&
          !planningState.planned &&
            !planningState.prePlanned &&
            !planningState.onHold && !planningState.created && !planningState.reused) {
          return true;
        }

    return planningState.toBePlanned && planningBlock.status.statusType === TO_BE_PLANNED ||
        planningState.inProgress && planningBlock.status.statusType === IN_PROGRESS ||
        planningState.planned && planningBlock.status.statusType === PLANNED ||
        planningState.prePlanned && planningBlock.status.statusType === PRE_PLANNED ||
        planningState.onHold && planningBlock.status.statusType === ON_HOLD ||
        planningState.onHold && planningBlock.status.statusType === CREATED || 
        planningState.reused && planningBlock.status.statusType === RE_USED; 
    }
