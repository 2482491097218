import { select } from "@angular-redux/store";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { PoolContainer } from "../../../pool/models/pool-containers.interface";
import { PoolActions } from "../../../pool/pool.actions";
import { PoolService } from "../../../pool/pool.service";
import { ModalType } from "../../../shared/models/component-source";
import { ModalManagerService } from "../../../shared/services/modal-manager.service";
import { SharedActions } from "../../../shared/shared.actions";
import { AnnouncementService } from "../../annoucement.service";
import { AnnouncementActions } from "../../announcement.actions";

@Component({
    selector: "undo-announce-container",
    templateUrl: "./undo-announce-container-modal.component.html",
})

export class UndoAnnounceContainer implements OnInit {
  
    @select("selectedAnnouncedContainers") public selectedAnnouncedContainers$: Observable<PoolContainer[]> ; 
    @select('loading') public loading$: Observable<boolean>; 

    @ViewChild("modal",{static: true}) public modal: ElementRef;
    public modalType = ModalType;
    public selectedContainers : PoolContainer[] = []; 
    public loading : boolean = false;


    constructor(
        private readonly poolService: PoolService,
        private readonly poolActions: PoolActions,
        private readonly modalManager: ModalManagerService,
        private readonly announcementService: AnnouncementService,
        private readonly toastr: ToastrService,
        private readonly sharedActions: SharedActions,
        private readonly announcementActions: AnnouncementActions,
      ) { }
      

  ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loading = data;
    })
        this.selectedAnnouncedContainers$.subscribe((data) => {
            if(data && data.length > 0) {
                this.selectedContainers = data;
            }
        })
    }

    public undoAnnounce() {
        this.loading = true;
        let containerNumbers = this.selectedContainers.map(x => x.containerNo);
        this.announcementService.unAnnounceContainer(containerNumbers).subscribe((data : PoolContainer[]) => {

            this.announcementActions.updateAnnouncementDisplay(data);
            this.announcementActions.setSelectedAnnouncedContainers([]);
            this.announcementActions.setSelectedPoolContainersForAnnouncement([]);
            this.toastr.success("Selected containers(s) is(are) successfully un-announced")
            this.loading = false; 
            this.closeModal();
        } , (err) => {
            this.loading = false; 
        });
    }

    public closeModal(): void {
        this.modalManager.closeModal(this.modalType.unAnnounceModal);
        this.sharedActions.setUiState(true);
    }
}
