import { select } from "@angular-redux/store";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Observable } from "rxjs";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { PoolContainer } from "../../../pool/models/pool-containers.interface";
import { ModalType } from "../../../shared/models/component-source";
import { ModalManagerService } from "../../../shared/services/modal-manager.service";
import { AnnouncementService } from "../../annoucement.service";
import { AnnouncementActions } from "../../announcement.actions";
import { AnnouncedContainersTable } from "./announced-containers-table/announced-containers-table.component";

@Component({
    selector: "announced-containers",
    templateUrl: "./announced-containers.component.html",
})

export class AnnouncedContainersComponent implements OnInit {

    @ViewChild("globalFilterAnnounced" , {static: true}) public globalFilterAnnounced: ElementRef;
    @ViewChild("AnnouncedContainersTable", {static: true}) public AnnouncedContainersTable: AnnouncedContainersTable;

    @select("selectedAnnouncedContainers") public selectedAnnouncedContainers$: Observable<PoolContainer[]>; 
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    
    public modalType = ModalType;
    public allowActions : boolean = false;
    public allowEditActions : boolean = false ;
    public selectedContainers : PoolContainer[] = [] ; 
    public enableAutocomplete: boolean = false;
    constructor(private readonly announcementActions: AnnouncementActions , 
        private readonly announcementService : AnnouncementService,
        private readonly modalManager: ModalManagerService,) {

    }
    
    ngOnInit(): void {

        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });
        
        this.announcementService.getAnnouncedPoolContainers().subscribe((data) => {
            this.announcementActions.setAnnouncedPoolContainers(data);
        })

        this.selectedAnnouncedContainers$.subscribe((data) => {
            if(data) { 
                this.selectedContainers = data ;
            }
                
        })
    }



    public filterAnnouncedContainers(selectedText: string): void {
        this.announcementActions.filterAnnouncedContainers(selectedText);
        this.refreshTableDisplay();
    }

    public clearGobalFilter(): void {
        this.globalFilterAnnounced.nativeElement.value = "";
        this.announcementActions.resetAnnouncedContainers();
    }


    public refreshTableDisplay() {
        this.AnnouncedContainersTable.refreshTableDisplay();
    }


    public openModal(modalId: string): void {
        if (modalId) {
          this.modalManager.openModal(modalId);
        }
    }

    public resetAnnounced() {
        this.announcementActions.resetAnnouncedContainers();
    }

}