import { Injectable } from '@angular/core';
import { Response, ResponseContentType, Http } from '@angular/http';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs';
import { PlanningBlock } from '../../../pb-screen/models/planning-block/planning-block.interface';
import { environment } from '../../../../environments/environment';
import { ContainerListData } from '../../models/container-list-data.interface';

@Injectable()
export class WareHouseListService {

  public baseUrl: string = environment.ContainerPlanningApiUri + "print";


    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }


    public printWareHouseList(pbs: PlanningBlock[], name: string): any{
        const data: ContainerListData = {
           planningBlocks: [],
           name: "",
            type: {
              iD: 1,
            },
          };

        data.planningBlocks =
          pbs.sort((a, b) => (a.fileId > b.fileId) ? 1 : ((b.fileId > a.fileId) ? -1 : 0));
        data.name = name;

        return this.http.post(this.baseUrl + "/warehouselist" , data, { responseType:ResponseContentType.Blob } )
        .map((res:any) =>
         window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
        .catch((err) => {
          return Observable.throw(err);
        });
    }

}
