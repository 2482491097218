import { QuickView } from './quick-view/quick-view.interface';
import { SelectedQuickFilters } from '../containers/filters/pb-quick-filter/pb-quick-filter.interface';
import { ToSelectedQuickFilter } from '../../to-screen/containers/filter/to-quick-filter/to-quick-filter.interface';
import { QuickFilter } from './pb-screen.interface';
import { GeneralSetting, PlanningSetting, WeekPlanningSetting } from '../../setting-modal/setting-modal.interface';
import { UserSetup } from '../userSetup/userSetup-modal.interface';
import { IdNameAsStringObject } from './quick-view/id-name-as-string-object.interface';
import {  QuickFilter as QuickFilterCp } from "../../depot-in/models/depot-in.interface";
import { AdvancedFilter } from '../../shared/components/filter-line/filter-line.interface';
export interface ApplicationUser {
  shipModalColumnsOrder: string[],
  railModalColumnsOrder: string[],
  depotQuickViews: QuickView[];
  id: string;
  username: string;
  fullName: string;
  settings: UserSettings[];
  pbPreferences : PbPreferences;
  toPreferences: ToPreferences;
  orderlinePreferences: OrderLinePreferences;
  defaultPlanningZone: string;
  defaultDateFormat: string  ;
  Screens: Selectedscreen;
  defaultGridIcon: GridIconSetting[];
  toIcons: GridIconSetting[];
  defaultPlanDate: string;
  defaultTruckDepartments: string[];
  defaultPlanGroups: string[];
  defaultPlanZone: string;
  defaultPlanDateRange : DefaultPlanDateRange;
  pbFixedPreferences: PbFixedPreferences;
  defaultAgendaDateRange: string;
  defaultAgendaPlanningGroup: IdNameAsStringObject;
  defaultAgendaPlanningZone: IdNameAsStringObject;
  defaultTruckAgendaDateRange: string;
  defaultTruckAgendaPlanningGroup: IdNameAsStringObject;
  defaultTruckAgendaPlanningZone: IdNameAsStringObject;
  pixelsPerPlanningBlock: number;
  weekPlanningSetting: WeekPlanningSetting;
  planningSetting: PlanningSetting;
  generalSetting: GeneralSetting;
  userSetup: UserSetup;
  defaultPlanSorting: string;
  depotOutSelectedColumns: Column[];
  depotInSelectedColumns: Column[];
  stackerSelectedColumns: Column[];
  depotOutSavedAdvancedFilters: AdvancedFilter[];
  depotInSavedAdvancedFilters: AdvancedFilter[];
  quickViews: QuickView[];
  planningBlocksPriority: PlanningBlocksPriority[];
  planningGroups: any[];
  siteNumber : string ; 
  depotInPreferences : DepotInPreferences, 
  depotOutPreferences : DepotOutPreferences, 
  depotOutGridIcons : GridIconSetting[]; 
  depotInGridIcons: GridIconSetting[];
  orderlinesDockSelectedColumns: Column[];
  conflictsDockSelectedColumns: Column[];
  cargoDockSelectedColumns: Column[];
  equipmentDockSelectedColumns: Column[];
  documentsDockSelectedColumns: Column[];
}

export interface DefaultPlanDateRange {
  defaultPlanDateFrom : string ,
  defaultPlanDateUntil : string
}

export interface UserSettings {
  id?: string;
  setting: Setting;
  value: string;
  scope: Scope;
  scopeValue: string;
  userId: string;
  view: View;
}

export interface View {
  name: string;
  id: string;
}

export interface PbPreferences {
  fontSize: string;
  selectedColumns: Column[];
  savedAdvancedFilters: AdvancedFilter[];
  quickViews: QuickView[];
  selectedQuickFilters?: SelectedQuickFilters;
  quickFilter?: QuickFilter;
}

export interface PbFixedPreferences {
  fixedQuickFilter?: QuickFilter;
  isFixedQuickFilter?: SelectedQuickFilters;
}

export interface ToPreferences {
  selectedToColumns: Column[];
  selectedToQuickFilters?: ToSelectedQuickFilter;
  fontSize: string;
}

export interface OrderLinePreferences {
  OrderLineColumnSelection: Column[];
  PbOrderLineColumnSelection: Column[];
}

export interface PlanningBoard {
  selectedColumns: Column[];
  savedAdvancedFilters: AdvancedFilter[];
  quickViews: QuickView[];
  selectedQuickFilters?: SelectedQuickFilters;
  selectedToColumns: Column[];
  OrderLineColumnSelection: Column[];
}

export interface Column {
  name: string;
  width: number;
}

export enum EnumGridIconSetting {
  Scanning, Customs, Weighing, ADR, T1, Temperature, HighValue, Conflicts , Notifications
}

export interface GridIconSetting {
  id: number; isVisible: boolean; name:string;
}

export interface Setting {
  id?: string;
  name: string;
  settingType: SettingType;
}

export enum SettingType {
  Single,
  Multiple
}

export enum Scope {
  None,
  Global,
  Module,
  User
}


export class Selectedscreen {
  depotIn: DocumentToBePrinted[];
  depotOut: DocumentToBePrinted[];
  pbscreen: DocumentToBePrinted[];
  pbdetails: DocumentToBePrinted[];
  toscreen: DocumentToBePrinted[];
  todetails: DocumentToBePrinted[];
  planningBoard: DocumentToBePrinted[];
}
export class DocumentToBePrinted {
  name: string;
}

export interface PlanningBlocksPriority{
  id: number,
  priority: boolean;
}

export interface DepotInPreferences
{
  selectedQuickFilters : SelectedDepotInQuickFilters, 
  quickFilter : QuickFilterCp,
  fontSize: string,
}

export interface DepotOutPreferences 
{
  selectedQuickFilters : SelectedDepotOutQuickFilters,
  quickFilter : QuickFilterCp,
  fontSize: string;
}


export class SelectedDepotInQuickFilters {
  isplanningStateActive: boolean;
  isfirstActionActive: boolean;
  islastActionActive: boolean;
  isloadingPlaceActive: boolean;
  isunLoadingPlaceActive: boolean;
  iscustomerActive: boolean;
  iscontainerStatusActive: boolean;
  isunloadDateFromActive: boolean;
  isunloadDateToActive: boolean;
  ispickupDateFromActive: boolean;
  ispickupDateToActive: boolean;
  ispbFirstCollectionFromActive: boolean;
  isspecificPbFirstCollectionFromActive: boolean;
  isspecificClosingActive: boolean;
  isclosingActive: boolean;
  iscontainerTypeActive: boolean;
  islatestLocationCodeActive: boolean;
  iscarrierActive: boolean;
  isplanningGroupsActive: boolean;
}


export class SelectedDepotOutQuickFilters {
  isplanningStateActive: boolean;
  isfirstActionActive: boolean;
  islastActionActive: boolean;
  isloadingPlaceActive: boolean;
  isunLoadingPlaceActive: boolean;
  iscustomerActive: boolean;
  iscontainerStatusActive: boolean;
  isunloadDateFromActive: boolean;
  isunloadDateToActive: boolean;
  ispbFirstCollectionFromActive: boolean;
  isclosingActive: boolean;
  isspecificPbFirstCollectionFromActive: boolean;
  isspecificClosingActive: boolean;
  ispickupDateFromActive: boolean;
  isStartDateActive: boolean;
  isEndDateActive: boolean;
  ispickupDateToActive: boolean;
  iscontainerTypeActive: boolean;
  islatestLocationCodeActive: boolean;
  iscarrierActive: boolean;
  isplanningGroupsActive: boolean;
}