import { PoolContainerStatusFilter } from "../../depot-out/models/depot-out.interface";

export function isOfSelectedPoolContainerState(container: any, containerState : PoolContainerStatusFilter): boolean {

     
    if (!containerState.notOnTerrain &&
          !containerState.reserved &&
          !containerState.notAvailable &&
            !containerState.available &&
            !containerState.all) {
          return true;
        }

    return containerState.notOnTerrain && container.status == "Not on terrain" ||
    containerState.notAvailable && container.status == "Not Available" ||
    containerState.all && container.status == "All" ||
    containerState.available && container.status == "Available" ||
    containerState.reserved && container.status == "Reserved" ; 

}