
import {catchError, mergeMap, first, map, takeUntil } from 'rxjs/operators';
 
import { OnInit, Component, ViewChild, Input, ElementRef } from "@angular/core";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { select } from "@angular-redux/store";

import { PbScreenService } from "../../../services/pb-screen.service";
import { PbActions } from "../../../pb.actions";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ApplicationUser } from "../../../models/application.user.interface";
import { ModalType } from "../../../../shared/models/source";
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from "../../../models/status-type.interface";
import { CapacitySlot } from "../../../models/capacity-slot.interface";
import { CapacitySelectorComponent } from "../../display/capacity-selector/capacity-selector.component";
import { TransportOrder } from "../../../models/transport-order/transport-order.interface";
import { environment } from "../../../../../environments/environment";
import { PlanningConflict } from "../../../models/planning-conflict.interface";
import { PlanningConflictType } from "../../../models/planning-conflict-type.enum";
import { Haulier } from "../../../models/haulier.interface";
import { SharedService } from "../../../../shared/services/shared.service";
import { Truck } from "../../../../static/models/truck.interface";
import { Driver } from "../../../../static/models/driver.interface";
import { ToScreenService } from "../../../services/to-screen.service";
import { SharedActions } from "../../../../shared/shared.actions";
import { ToActions } from "../../../../to-screen/to.actions";
import { validateConfig } from "@angular/router/src/config";
import { PlanningBoardActions } from "../../../../planningBoard/planningBoard.action";
import { Observable, of, Subject } from 'rxjs';
import { LocationSelectorComponent } from '../../../../shared/components/location-selector/location-selector.component';
import { TarTasManager } from '../../../../shared/services/tartas-manager.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { ActionBarService } from '../../../../shared/services/action-bar.service';
import { ContainerPlanningTypeEnum } from '../../../../shared/models/planning-groups-enum.interface';


@Component({
    moduleId: module.id,
    selector: 'planning-modal',
    templateUrl: './planning-modal.component.html'
})
export class PlanningModalComponent extends SimpleModalComponent<PlanningModalInput, void> implements OnInit {
    @ViewChild("modal") public modal: ElementRef;
    @ViewChild(LocationSelectorComponent) public locationSelector: LocationSelectorComponent;
    @ViewChild(CapacitySelectorComponent) public capacitySelectorComponent: CapacitySelectorComponent;
    // @Input() public selectedHaulier: Haulier;
    public source: string;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select("applicationUser") public applicationUser$: Observable<ApplicationUser>;
    @select("capacitySlots") public capacitySlots$: Observable<CapacitySlot[]>;
    @select("hauliers") public hauliers$: Observable<Haulier[]>;
    @select("selectedCapacityFromPlanBoard") public selectedCapacityFromPlanBoard$: Observable<any>;
    @select("selectedPbsToPlan") public selectedPbsToPlan$ : Observable<PlanningBlock[]>
    @select('loading') public loading$: Observable<boolean>;

    public finalizedCombi: boolean = false;

    public selectedPlanningBlocks: PlanningBlock[] = [];
    public selectedPlanningBlocksPlanningGroups: string[] = [];
    public selectedPlanningBlockForInBetweenPlanning: PlanningBlock = null;
    public planningFunctionCallback: Function = null;
    public loadingSpinner: boolean = false;
    public masterDetailView: boolean = false;
    public planningBlocksIncompatibleWarning: boolean = false;

    public capacitySlots: CapacitySlot[] = [];
    public planningHauliers: Haulier[] = [];
    public fallbackLanguage: string = "en";
    public languageToUse: string = "en";
    public userName: string = "";
    public userId: string = "";
    private reuseNotified: boolean = false;

    public modalType = ModalType;
    public assignedDriver: Driver;
    public assignedTruck: string = "";
    public selectedCapacity: CapacitySlot;
    public selectedHaulier: Haulier;
    public planDate: Date;
    public buttonState: boolean = false;
    public containerIsAvailable: boolean = false;

    public openTransportOrdersForCapacity: TransportOrder[] = [];
    public openTransportOrdersForHaulier: TransportOrder[] = [];
    public selectedTransportOrder: TransportOrder = null;
    public toRowIndex: number = null;
    public disableToSelectionModal = environment.disableToSelectionModal;
    public emptyCapacity: CapacitySlot;
    public emptyHaulier: Haulier;
    public selectedCs: any;
    public user: ApplicationUser;
    public linkToAppointmentEnabled: boolean = false;
    public showWarning: boolean = false;
    public warningMessage: string = '';
    destroy$ = new Subject();

    constructor(
        public readonly pbScreenService: PbScreenService,
        public readonly toScreenService: ToScreenService,
        public readonly sharedService: SharedService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly pbActions: PbActions,
        public readonly toActions: ToActions,
        private readonly actionBarService: ActionBarService,
        public readonly sharedActions: SharedActions,
        public readonly planningBoardAction: PlanningBoardActions,
        private readonly tarTasManager: TarTasManager) {
            super()
         }

  ngOnInit(): void {

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
            this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
                if (applicationUser) {
                    this.user = applicationUser;
                    this.userName = applicationUser.fullName;
                    this.userId = applicationUser.username;
                    this.disableToSelectionModal = this.user.generalSetting.disableTOSelectionModal ?? environment.disableToSelectionModal;
                }
            })

            
            this.selectedCapacityFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                if (data) {
                    this.selectedCapacity = data;
                    this.checkOpenTos(data);
                }
            })

            this.selectedPbsToPlan$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                if(data && data.length) {
                    this.selectedPlanningBlocks = data ; 
                }
            })
            this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((pbs: PlanningBlock[]) => {

                if (pbs && pbs.length) {
                    pbs.forEach((item) => {
                        item.selectionSequence = pbs.indexOf(item) + 1;
                    })
    
                    this.selectedPlanningBlocks = pbs;
                    this.selectedPlanningBlocksPlanningGroups = pbs.map(pb => pb.planningGroup).filter((v, i, s) => s.indexOf(v) === i);
                    const planChecker = (element, array, index) => {
                        return element.status.statusType === TO_BE_PLANNED;
                    }
                    this.buttonState = this.selectedPlanningBlocks.every(planChecker);
        
                    if (this.selectedPlanningBlocks.length === 1 && this.selectedPlanningBlocks[0].status.statusType === PRE_PLANNED) {
                        this.assignedDriver = this.selectedPlanningBlocks[0].driver;
                        this.assignedTruck = this.selectedPlanningBlocks[0].truckId;
        
                        let selectedCapacity = this.capacitySlots.filter((capacitySlot: CapacitySlot) => {
                            return capacitySlot.truck.id === this.assignedTruck;
                        })[0];
                        if (selectedCapacity) this.selectedCapacity = selectedCapacity;
                    }
        
        
                    this.hauliers$.pipe(takeUntil(this.destroy$)).subscribe((hauliers: Haulier[]) => {
                        this.planningHauliers = hauliers;
                    });
        
        
                    if (pbs.length > 0 && this.planningHauliers.length > 0) this.selectedHaulier = this.planningHauliers.filter(haulier => haulier.id == pbs[0].haulier.id.toString())[0];
        
                    this.isLinkToAppointmentEnabled();
                }
            });

            this.capacitySlots$.pipe(takeUntil(this.destroy$)).subscribe((capacitySlots: CapacitySlot[]) => {
                this.capacitySlots = capacitySlots;
            });

        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.user = applicationUser;
                this.userName = applicationUser.fullName;
                this.userId = applicationUser.username;
            }
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public translateString(text: string) {
        return this.translate.get(text).pipe(first()).toPromise();
    }

    public closeModal(): void {
        this.sharedActions.resetSharedSelectedPlanningBlocks();
        this.resetModalState();
        this.closeToPanel();
        this.close();
    }

    resetModalState() {
        this.selectedCapacity = this.emptyCapacity;
        this.selectedTransportOrder = null;
        this.capacitySelectorComponent.resetHaulierInput(false);
    }

    public getSelectedPlanDate(planDate) {
        this.planDate = new Date(planDate);
    }

    public getSelectedFinalizeCombi(finalizeCombi) {
        this.finalizedCombi = finalizeCombi;
    }

    public selectedPlanningBlocksArePrePlanned(): boolean {
        let result: boolean = true;
        this.selectedPlanningBlocks.forEach((pb) => {
            if (pb.status.statusType != 13) result = false;
        });
        return result;
    }

    public selectedPlanningBlocksAreToBePlanned(): boolean {
        let result: boolean = true;
        this.selectedPlanningBlocks.forEach((pb) => {
            if (pb.status.statusType != 12) result = false;
        });
        return result;
    }

    public planningGroupsCompatibleWithTo(): boolean {
        var planningGroup = "";
        if (this.selectedPlanningBlockForInBetweenPlanning) planningGroup = this.selectedPlanningBlockForInBetweenPlanning.planningGroup;
        else if (this.selectedTransportOrder) planningGroup = this.selectedTransportOrder.planningGroup;
        else if (this.selectedCapacity) planningGroup = this.selectedCapacity.truck.planningGroupID;
        return planningGroup === "" || this.selectedPlanningBlocksPlanningGroups.every(pg => pg === planningGroup);
    }

    public getPlanningBlocksPGIncompatible(): string {
        var planningGroup = "";
        if (this.selectedPlanningBlockForInBetweenPlanning) planningGroup = this.selectedPlanningBlockForInBetweenPlanning.planningGroup;
        else if (this.selectedTransportOrder) planningGroup = this.selectedTransportOrder.planningGroup;
        return this.selectedPlanningBlocks.filter(pb => pb.planningGroup != planningGroup).map(pb => pb.id).join();
    }

    public confirmPlanningGroupIncompatibility() {
        this.planningBlocksIncompatibleWarning = false;
        this.planningFunctionCallback(false);
    }

    public cancelPlanningGroupIncompatibility() {
        this.planningBlocksIncompatibleWarning = false;
        this.backToPreviousScreen();
        this.resetModalState();
        this.closeToPanel();
    }

    public validatePlanning(callback: Function): boolean {
        this.planningFunctionCallback = callback;
        if (this.planningGroupsCompatibleWithTo()) {
            return true;
        }
        else {
            this.planningBlocksIncompatibleWarning = true;
            return false;
        }
    }

    public planBeforeSelectedPB(validate: boolean = true) {
        if (validate) {
            let validatePlanning = this.validatePlanning(this.planBeforeSelectedPB);
            if (!validatePlanning) return;
        }
        //0 : Before
        this.planBeforeOrAfterSelectedPB(0).subscribe((response: any) => {
            console.log(response)
        });
    }

    public planAfterSelectedPB(validate: boolean = true) {
        if (validate) {
            let validatePlanning = this.validatePlanning(this.planAfterSelectedPB);
            if (!validatePlanning) return;
        }
        //1 : After
        this.planBeforeOrAfterSelectedPB(1).subscribe((response: any) => {
            console.log(response);
        });
    }

    public planBeforeOrAfterSelectedPB(beforeOrAfter: number): Observable<any> {
        this.loadingSpinner = true;
        return this.pbScreenService.planBeforeOrAfterPB(this.selectedPlanningBlockForInBetweenPlanning.transportOrderNumber,
                                                        this.selectedPlanningBlocks, 
                                                        this.selectedPlanningBlockForInBetweenPlanning.id,
                                                        beforeOrAfter).pipe(map((to: TransportOrder) => {
            this.loadingSpinner = false;
            this.calculateAndDisplayPlanningConflicts(to.id).subscribe();
            this.translateString('TOASTR_MESSAGES.SUCCESS.PLAN_SUCCEEDED').then((result) => {
                this.toastr.success(result.toString());
            });
            const pbs = to.planningBlocks.filter(pb => pb && pb != null);
            this.sharedActions.updatePlanningBlocksDisplay(pbs);
            let toNumbers = [] ; 
            toNumbers.push(to.id);
            this.pbScreenService.updateFinalizedCombi(toNumbers,this.finalizedCombi).subscribe((data) => {
                this.sharedActions.updateTransportOrdersDisplay(data);
            });
            this.loadingSpinner = false;
            if (this.source == 'planningBoard') {
                this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                    , this.user.defaultPlanDate
                    , this.user.defaultTruckDepartments.join(',')
                    , this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));
                this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                    this.user.defaultPlanDateRange.defaultPlanDateFrom,
                    this.user.defaultTruckDepartments.join(','),
                    this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));

                this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                    this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                this.resetSharedSelection();
                this.planningBoardAction.setSelectedPbsUnplanned([]);
            }

            this.closeModal();
        }),catchError((err) => {
                this.loadingSpinner = false;
                return of([]);
        }),);
    }

    public async prePlanAction(validate: boolean = true) {
        if(this.user.generalSetting.containerReuseEnabled)
        {
            let pbsDepotIn = this.selectedPlanningBlocks.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.IN);
            let pbsDepotOut = this.selectedPlanningBlocks.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.AF);
            const isReusable = await this.actionBarService.isReuseable(pbsDepotIn, pbsDepotOut, this.source);
            if(isReusable && !this.reuseNotified)
            {
                this.actionBarService.notifyReuse(this.selectedPlanningBlocks);
                this.reuseNotified = true;
                return;
            }
        }

        if (validate) {
            let validatePlanning = this.validatePlanning(this.prePlanAction);
            if (!validatePlanning) return;
        }

        if (this.selectedTransportOrder) {
            this.planExistingToAction().subscribe((result) => {
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));

                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }
            });

        }
        else if (this.selectedCapacity) {
            this.planToNewTOAction().subscribe((result) => {
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }
            });
        }
        else if (this.selectedHaulier) {
            this.assignHaulierToPBs().subscribe((result) => {
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }
            });
        }
    }

    public planAction(validate: boolean = true) {
        if (validate) {
            let validatePlanning = this.validatePlanning(this.planAction);
            if (!validatePlanning) return;
        }

        if (this.selectedTransportOrder) {
            this.planExistingToAction(false).subscribe((result: any) => {
                this.sendAction();
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }
            })
        }
        else if (this.selectedCapacity) {
            this.planToNewTOAction(false).subscribe((result: any) => {
                this.sendAction();
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }
            });
        }
        else if (this.selectedHaulier) {
            this.assignHaulierToPBs(false).subscribe((result: any) => {
                this.sendAction();
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");

                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);

                }
            });
        }
    }

    public sendAction() {
        this.loadingSpinner = true;
        this.pbScreenService.planAction(this.selectedPlanningBlocks).subscribe((plannedPbs: PlanningBlock[]) => {
            const transportOrderNumber = plannedPbs[0].transportOrderNumber;
            this.calculateAndDisplayPlanningConflicts(transportOrderNumber).subscribe();
            this.loadingSpinner = false;
            this.sharedActions.updatePlanningBlocksDisplay(plannedPbs);
            let toNumbers = [];
            toNumbers.push(transportOrderNumber);
            this.pbScreenService.updateFinalizedCombi(toNumbers, this.finalizedCombi).subscribe((data) => {
                this.toActions.updateTransportOrdersDisplay(data);
            });
            // this.pbActions.updatePlanningBlocksDisplay(plannedPbs);
            this.translateString('TOASTR_MESSAGES.SUCCESS.SEND_SUCCEEDED').then((result) => {
                this.toastr.success(result.toString());
            });
            this.closeModal();
        }, (err) => {
            this.loadingSpinner = false;
            this.translateString('TOASTR_MESSAGES.ERROR.SEND_FAILED').then((result) => {
                this.toastr.error(result);
            });
        });
    }

    public createTo() {
        this.loadingSpinner = true;
        const pbIds = this.selectedPlanningBlocks.map((pb: PlanningBlock) => pb.id);
        this.pbScreenService.planToNewTo(pbIds).subscribe((data: PlanningBlock[]) => {

            this.sharedActions.updatePlanningBlocksDisplay(data);
            let toNumbers = [];
            data.forEach((item) => {
                toNumbers.push(item.transportOrderNumber);
            })
            this.pbScreenService.updateFinalizedCombi(toNumbers, this.finalizedCombi).subscribe((data) => {
                this.loadingSpinner = false;
                this.toActions.updateTransportOrdersDisplay(data);
            });
            // this.pbActions.updatePlanningBlocksDisplay(data);
            this.translateString('TOASTR_MESSAGES.SUCCESS.CREATING_NEW_TO_SUCCEEDED').then((result) => {
                this.toastr.success(result.toString());
            });
            this.pbActions.setSelectedPlanningBlocks([]);

            if (this.source == 'planningBoard') {
                this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                    , this.user.defaultPlanDate
                    , this.user.defaultTruckDepartments.join(',')
                    , this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));
                this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                    this.user.defaultPlanDateRange.defaultPlanDateFrom,
                    this.user.defaultTruckDepartments.join(','),
                    this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));

                this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                    this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                this.resetSharedSelection();
                this.planningBoardAction.setSelectedPbsUnplanned([]);
            }

            this.closeModal();
        }, (err) => {
            this.loadingSpinner = false;
            this.translateString('TOASTR_MESSAGES.ERROR.CREATING_NEW_TO_FAILED').then((result) => {
                this.toastr.error(result)
            });
        });
    }

    public assignHaulierToPBs(closeModal: boolean = true): Observable<any> {
        this.loadingSpinner = true;
        if (this.selectedPlanningBlocks.every(pb => pb.status.statusType === TO_BE_PLANNED)) {
            const pbIds = this.selectedPlanningBlocks.map((pb: PlanningBlock) => pb.id);
            return this.pbScreenService.assignHaulierToPBs(this.selectedHaulier.id, pbIds).pipe(map((data: TransportOrder) => {
                this.loadingSpinner = false;
                this.sharedActions.updatePlanningBlocksDisplay(data.planningBlocks);
                let toNumbers = [];
                toNumbers.push(data.id);
                this.pbScreenService.updateFinalizedCombi(toNumbers, this.finalizedCombi).subscribe((data) => {
                    this.toActions.updateTransportOrdersDisplay(data);
                });
                this.translateString('TOASTR_MESSAGES.SUCCESS.HAULIER_ASSIGNED_SUCCEEDED').then((result) => {
                    this.toastr.success(result.toString() + data.id);
                });
                if (this.source == 'planningBoard') {
                    this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                        , this.user.defaultPlanDate
                        , this.user.defaultTruckDepartments.join(',')
                        , this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));
                    this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                        this.user.defaultPlanDateRange.defaultPlanDateFrom,
                        this.user.defaultTruckDepartments.join(','),
                        this.user.defaultPlanZone,
                        this.user.defaultPlanGroups.join(','));

                    this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                        this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                    this.resetSharedSelection();
                    this.planningBoardAction.setSelectedPbsUnplanned([]);
                }

                if(closeModal) this.closeModal();
            }),catchError(err => {
                this.loadingSpinner = false;
                this.translateString('TOASTR_MESSAGES.ERROR.HAULIER_ASSIGNED_ERROR').then((result) => {
                    this.toastr.error(result)
                });
                return of([]);
            }),);
        }
        else {
            var transportOrderNumber = this.selectedPlanningBlocks.filter(pb => pb.status.statusType === PRE_PLANNED).map(pb => pb.transportOrderNumber)[0];
            return this.toScreenService.getPlanningBlocksForTO(transportOrderNumber).pipe(mergeMap((transportOrder: TransportOrder) => {
                return this.toScreenService.assignHaulierToTO(this.selectedHaulier.id, transportOrder).pipe(map((data: TransportOrder) => {
                    this.loadingSpinner = false;
                    this.sharedActions.updatePlanningBlocksDisplay(data.planningBlocks);
                    //this.pbActions.updatePlanningBlocksDisplay(data.planningBlocks);
                    this.translateString('TOASTR_MESSAGES.SUCCESS.HAULIER_ASSIGNED_SUCCEEDED').then((result) => {
                        this.toastr.success(result.toString() + data.id);
                    });

                    if (this.source == 'planningBoard') {
                        this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                            , this.user.defaultPlanDate
                            , this.user.defaultTruckDepartments.join(',')
                            , this.user.defaultPlanZone,
                            this.user.defaultPlanGroups.join(','));
                        this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                            this.user.defaultPlanDateRange.defaultPlanDateFrom,
                            this.user.defaultTruckDepartments.join(','),
                            this.user.defaultPlanZone,
                            this.user.defaultPlanGroups.join(','));
    
                        this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                            this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                        this.resetSharedSelection();
                        this.planningBoardAction.setSelectedPbsUnplanned([]);
                    }

                    if(closeModal) this.closeModal();
                }),catchError(err => {
                    this.loadingSpinner = false;
                    this.translateString('TOASTR_MESSAGES.ERROR.HAULIER_ASSIGNED_ERROR').then((result) => {
                        this.toastr.error(result)
                    });
                    return of([]);
                }),);
            }));
        }
    }

    public toggleCapacity(state) {
        if (state) {
            this.capacitySelectorComponent.disableCapacityInput();
        } else {
            this.capacitySelectorComponent.enableCapacityInput();
        }
    }

    public getSelectedCapacity(capacity: CapacitySlot) {
        if (!capacity) {
            this.resetModalState();
        }
        if (!this.disableToSelectionModal && capacity) {
            this.selectedCapacity = capacity;
            const driverId = (capacity.driver && capacity.driver.id) ? capacity.driver.id.toString() : '';
            const truckId = (capacity.truck && capacity.truck.id) ? capacity.truck.id.toString() : '';
            var date = this.planDate;
            this.pbScreenService.getOpenTos(driverId, truckId, date.toISOString()).subscribe((data) => {
                this.openTransportOrdersForCapacity = data;
            })
        }
    }

    public getSelectedHaulier(haulier: Haulier) {
        if (!haulier) this.selectedHaulier = null;
        if (!this.disableToSelectionModal && haulier) {
            this.selectedHaulier = haulier;
            var date = this.planDate;
            this.pbScreenService.getOpenTosFromHaulier(this.selectedHaulier.id, date.toISOString()).subscribe((data) => {
                this.openTransportOrdersForHaulier = data;
            })
        }
    }

    public updateTransportOrderSelection(transportOrder: TransportOrder) {
        if (transportOrder) {
            this.selectedTransportOrder = transportOrder;
            this.selectedPlanningBlockForInBetweenPlanning = null;
        }
    }

    public updatePlanningBlockSelection(planningBlock: PlanningBlock) {
        if (planningBlock) {
            this.selectedPlanningBlockForInBetweenPlanning = planningBlock;
            this.selectedTransportOrder = null;
        }
    }

    public planExistingToAction(closeModal: boolean = true): Observable<any> {
        this.loadingSpinner = true;
        return this.pbScreenService.planToExistingTos(this.selectedTransportOrder.id, this.selectedPlanningBlocks).pipe(map((to: TransportOrder) => {
            this.calculateAndDisplayPlanningConflicts(this.selectedTransportOrder.id).subscribe();
            this.translateString('TOASTR_MESSAGES.SUCCESS.PLAN_SUCCEEDED').then((result) => {
                this.toastr.success(result.toString());
            });
            const pbs = to.planningBlocks.filter(pb => pb && pb != null);
            this.sharedActions.updatePlanningBlocksDisplay(pbs);
            let toNumbers = [];
            toNumbers.push(to.id);
            this.pbScreenService.updateFinalizedCombi(toNumbers, this.finalizedCombi).subscribe((data) => {
                this.toActions.updateTransportOrdersDisplay(data);
            });
            // this.pbActions.updatePlanningBlocksDisplay(pbs);
            if (this.source == 'planningBoard') {
                this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                    , this.user.defaultPlanDate
                    , this.user.defaultTruckDepartments.join(',')
                    , this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));
                this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                    this.user.defaultPlanDateRange.defaultPlanDateFrom,
                    this.user.defaultTruckDepartments.join(','),
                    this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));

                this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                    this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                this.resetSharedSelection();
                this.planningBoardAction.setSelectedPbsUnplanned([]);
            }

            this.loadingSpinner = false;
            if(closeModal) this.closeModal();
        }),catchError((err) => {
                this.loadingSpinner = false;
                return of([]);
            }),);
    }

    public planToNewTOAction(closeModal: boolean = true): Observable<any> {
        this.loadingSpinner = true;
        return this.pbScreenService.prePlanAction(this.selectedPlanningBlocks, this.selectedCapacity, this.planDate.toUTCString()).pipe(map((to: TransportOrder) => {

            this.loadingSpinner = false;
            this.calculateAndDisplayPlanningConflicts(to[0].id).subscribe();

            const pbs = to[0].planningBlocks.filter(pb => pb && pb != null);
            this.sharedActions.updatePlanningBlocksDisplay(pbs);

            let toNumbers = [];
            toNumbers.push(to[0].id);
            this.pbScreenService.updateFinalizedCombi(toNumbers, this.finalizedCombi).subscribe((data) => {
                this.toActions.updateTransportOrdersDisplay(data);
            });
            // this.pbActions.updatePlanningBlocksDisplay(pbs);
            this.translateString('TOASTR_MESSAGES.SUCCESS.PLAN_SUCCEEDED').then((result) => {
                this.toastr.success(result.toString());
            });

            if (this.source == 'planningBoard') {
                this.planningBoardAction.storeDataForPlanningGrid(this.user.defaultPlanDate
                    , this.user.defaultPlanDate
                    , this.user.defaultTruckDepartments.join(',')
                    , this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));
                this.planningBoardAction.storeDataForWeekPlanning(this.user.defaultPlanDateRange.defaultPlanDateUntil,
                    this.user.defaultPlanDateRange.defaultPlanDateFrom,
                    this.user.defaultTruckDepartments.join(','),
                    this.user.defaultPlanZone,
                    this.user.defaultPlanGroups.join(','));

                this.planningBoardAction.storeUnplannedPlanningBlocks(this.user.defaultPlanZone, this.user.defaultPlanGroups.join(","),
                    this.user.defaultPlanDate, this.user.defaultPlanDate, "");
                this.resetSharedSelection();
                this.planningBoardAction.setSelectedPbsUnplanned([]);
            }

            if(closeModal) this.closeModal();
        }),
            catchError(err => {
                this.loadingSpinner = false;
                this.translateString("TOASTR_MESSAGES.ERROR.PLAN_FAILED").then((result) => {
                    this.toastr.error(result)
                });
                return of([]);
            }),);
    }

    public planExistingTo() {
        this.backToPreviousScreen();
        this.setSelectedCapacityFromTO();
        this.closeToPanel();
    }

    public setSelectedCapacityFromTO() {
        const selectedTransportOrderTruckNo = this.selectedTransportOrder.truckNo;
        let filteredCapacities = this.capacitySlots.filter((capacity) => {
            return capacity.truck.id === selectedTransportOrderTruckNo;
        });
        if (filteredCapacities.length > 0) {
            this.selectedCapacity = filteredCapacities[0];
        }
    }

    public createNewTOFromModal() {
        this.selectedTransportOrder = null;
        this.closeToPanel();
    }

    public selectOpenTo(selectedTransportOrder, toRowIndex) {
        if (toRowIndex !== this.toRowIndex) {
            this.selectedTransportOrder = selectedTransportOrder;
            this.toRowIndex = toRowIndex;
        } else {
            this.selectedTransportOrder = null;
            this.toRowIndex = null;
        }
    }

    public closeToPanel() {
        this.openTransportOrdersForCapacity = [];
        this.openTransportOrdersForHaulier = [];
        this.toRowIndex = null;
    }

    public calculateAndDisplayPlanningConflicts(transportOrderNumber: string): Observable<any> {
        const conflictMessageConfig = {
            timeOut: 10000,
            extendedTimeOut: 10000,
            closeButton: true
        };

        return this.sharedService.calculatePlanningBlockConflicts(transportOrderNumber).pipe(
            map((conflicts: PlanningConflict[]) => {
                const uniqueConflicts = [...conflicts.reduce((accu, current) => {
                    accu.set(current.conflictMessage || current.conflictReason || current.ressourceNo, current);
                    return accu;
                }, new Map()).values()];

                uniqueConflicts.forEach((planningConflict: PlanningConflict) => {
                    const formattedConflictMessage = planningConflict.conflictMessage != "" ? planningConflict.conflictMessage : planningConflict.conflictReason;

                    switch (planningConflict.conflictType) {
                        case PlanningConflictType.WARNING:
                            this.toastr.warning(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                        case PlanningConflictType.ERROR:
                            this.toastr.error(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                        default:
                            this.toastr.info(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                    }
                });
            }),
            catchError((err) => {
                this.loadingSpinner = false;
                this.translateString("TOASTR_MESSAGES.ERROR.CALCULATING_CONFLICT_ERROR").then((result) => {
                    this.toastr.error(result)
                });
                return of([]);
            }),);
    }

    public onChange($event) {
        this.finalizedCombi = $event;
    }

    public switchToMasterDetailView() {
        if (!this.masterDetailView) {
            this.selectedTransportOrder = null;
            this.selectedPlanningBlockForInBetweenPlanning = null;
            this.masterDetailView = true;
        }
    }

    public backToPreviousScreen() {
        if (this.masterDetailView) {
            this.masterDetailView = false;
        }
    }

    public resetSharedSelection(): void {

        this.pbActions.setSharedSelectedPlanningBlocks([]);

        this.pbActions.setSelectedDisplayState(false);

    }


    public checkOpenTos(capacity) {
        const driverId = (capacity.driver && capacity.driver.id) ? capacity.driver.id.toString() : '';
        const truckId = (capacity.truck && capacity.truck.id) ? capacity.truck.id.toString() : '';
  
        this.pbScreenService.getOpenTos(driverId,truckId,this.setDate(this.user.defaultPlanDate)).subscribe((data) => {
            this.openTransportOrdersForCapacity = data;
        });
            
    }
  
    private setDate(date) {
      const dateArray = date.split('/');
      const formattedDate = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0];
      return new Date(formattedDate).toISOString();
    }

    isLinkToAppointmentEnabled() {
        if(!this.selectedPlanningBlocks)
            this.selectedPlanningBlocks = [];
        var result = this.tarTasManager.isLinkToAppointmentEnabled(this.selectedPlanningBlocks.filter(pb => pb.dInOrderLineID),
                                    this.selectedPlanningBlocks.filter(pb => pb.dOutOrderLineID));
        this.linkToAppointmentEnabled = result.linkToAppointmentEnabled;
        this.warningMessage = result.warningMessage;
        this.showWarning = result.showWarning;
    }

    link()
    {
        this.linkToAppointmentEnabled = false;
        this.tarTasManager.linkDepotOutToDepotIn(this.selectedPlanningBlocks.filter(pb => pb.dInOrderLineID),
                                    this.selectedPlanningBlocks.filter(pb => pb.dOutOrderLineID));
    }
}

export interface PlanningModalInput {
    source: string;
  }
