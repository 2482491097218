import { Injectable, Inject } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { Response, RequestOptions, ResponseContentType, Http } from '@angular/http';
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../../environments/environment';
import { ShuttleListData } from '../../models/shuttle-list-data.interface';
import { PlanningBlock } from '../../../pb-screen/models/planning-block/planning-block.interface';

@Injectable()
export class StuffingStaalListService {

  public baseUrl: string = environment.ContainerPlanningApiUri + "print";


  constructor(
    private readonly http: Http,
    private readonly toastr: ToastrService,
  ) { }


  public printStuffingStaalList(pbs: PlanningBlock[], name: string): any {
    const data: ShuttleListData = {
      planningBlocks: [],
      name: "",
      type: {
        iD: 1,
      },
    };

              // splitting dates in YYYYMMDD number for sorting, taking date part by plitting on " ",
          // taking first part, splitting on slash, reversing and joining
    data.planningBlocks = pbs.sort((a, b) => (a.vgmClosing.toString().split(" ")[0].split("/").reverse().join() <
          b.vgmClosing.toString().split(" ")[0].split("/").reverse().join()) ? -1 : 1);
    data.name = name;

    return this.http.post(this.baseUrl + "/stuffingstaallist", data, { responseType:ResponseContentType.Blob })
      .map((res:any) =>
        window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
      .catch((err) => {
        return Observable.throw(err);
      });
  }
}