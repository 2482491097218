import { PlanningBlock } from './../../../pb-screen/models/planning-block/planning-block.interface';
import { TMSSelectedCMRActions, xmlTMSSelectCMRActionsXML } from './../../../pb-screen/models/TMSSelectCMRActions.interface';
import { DocumentToBePrinted } from './../../../pb-screen/models/application.user.interface';
import { ToScreenService } from './../../../pb-screen/services/to-screen.service';
import { TransportOrder } from './../../../pb-screen/models/transport-order/transport-order.interface';
import { OnInit, Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable, Subject } from 'rxjs';
import { ApplicationUser } from '../../../pb-screen/models/application.user.interface';
import { ModalType } from '../../../shared/models/source';
import { SharedActions } from '../../../shared/shared.actions';
import { SharedService } from '../../../shared/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ModalManagerService } from '../../../shared/services/modal-manager.service';
import { CMRService } from '../../../pb-screen/services/print-CMR.service';
import { PLANNED, PRE_PLANNED } from '../../../pb-screen/models/status-type.interface';
import { PrintTicketService } from '../../../pb-screen/services/printticket.service';
import { OrderLine } from '../../../pb-screen/models/order-line/orderLine.interface';
import { GetCmrActionsService } from '../../../pb-screen/services/getCmrActions.service';
import { PrintService } from '../../../shared/services/print/print.service';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { takeUntil } from 'rxjs/operators';
@Component({
    moduleId: module.id,
    selector: 'print-list-modal',
    templateUrl: 'print-list-modal.component.html'
})
export class printlistComponent extends SimpleModalComponent<PrintListModel, void> implements PrintListModel, OnInit, OnDestroy{
    @ViewChild('modal') public modal: ElementRef;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select('processedDisplayState') public processedDisplayState$: Observable<boolean>;
    @select('filteredPlanningBlocks') public filteredTransportOrder$: Observable<TransportOrder[]>;
    //@Input() public selectedPlanningBlocks: TransportOrder[];
         public selectedCmrActions: TMSSelectedCMRActions[];
    public selectedTransportOrders: TransportOrder[] = [];
    

    //public CMR: string = 'Cmr';
    //public transportOrderDocument: string = 'Transport order Document';
    // public lists: string[] = ['Transport order Document', 'CMR'];
    public lists: string[] = [];
    public selectedList: string = '';
   
    public overMaximum: boolean = false;
    public sizeOfSelectionToBig: boolean = false;
    public applicationUser: ApplicationUser;
    public modalType = ModalType;
    public weekPlanStartDate: string;
    public weekPlanEndDate: string;
    public processedtransportOrder: TransportOrder[];
    public filteredTransportOrder: TransportOrder[];
    public processedDisplayState: boolean;
    public Id: string
    public planningBlockId: number
    public printButtonRestriction: boolean = false;
    destroy$ = new Subject();
    public enableAutocomplete: boolean = false;
   
    constructor(
        public readonly sharedActions: SharedActions,
        public readonly toScreenService: ToScreenService,
        public readonly sharedService: SharedService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly modalManager: ModalManagerService,
        public readonly cmrService: CMRService,
        private readonly getCmrActionsService: GetCmrActionsService, 
        private readonly printService: PrintService
    ) {
        super();
    }
    ngOnInit(): void {
        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.applicationUser = applicationUser;
                this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
        });
        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders: TransportOrder[]) => {
            this.selectedTransportOrders = transportOrders;
            if (this.selectedTransportOrders.length === 1) {
                this.toScreenService.getPlanningBlocksForTO(this.selectedTransportOrders[0].id).subscribe((to) => {
                    this.selectedTransportOrders[0].planningBlocks = to.planningBlocks;
                    this.sharedActions.getPbsForTO(to.planningBlocks);
                });
            }
        });

   
        this.processedDisplayState$.pipe(takeUntil(this.destroy$)).subscribe(function (processedDisplayState) {
            this.processedDisplayState = processedDisplayState;
        });
        this.filteredTransportOrder$.pipe(takeUntil(this.destroy$)).subscribe((filteredTransportOrder: TransportOrder[]) => {
            this.filteredTransportOrder = this.filteredTransportOrder;
        });

        if(this.selectedList == '') {
            this.printButtonRestriction = true ;
        }
    }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
    documentList() {
        if(this.applicationUser) {
        this.lists = []
        if (this.sharedService.ScreenName === 'to-screen') {
            for (var i = 0; i < this.applicationUser.Screens.toscreen.length; i++) {
                this.lists.push(this.applicationUser.Screens.toscreen[i].name);
            }
       
        } else {
            for (var i = 0; i < this.applicationUser.Screens.todetails.length; i++) {
                this.lists.push(this.applicationUser.Screens.todetails[i].name);
            }
        }
        return this.lists
        }
   }
    public onCancelClicked(): void {
        this.resetUi();
    }
    public resetUi(): void {
        this.sharedActions.setUiState(true);
        this.selectedCmrActions = [];
    }
    private closeModal(): void {
        this.close();
        this.resetUi();
    }
    public selectionChanged() {
        this.sizeOfSelectionToBig = false;
        this.overMaximum = false;
        this.printButtonRestriction = false;
        if (this.selectedList === 'Cmr') {
            this.sharedActions.getCmrActions(this.selectedTransportOrders[0].id);
        } else if (this.selectedList === 'Transport Order Document') {
            this.printButtonRestriction = !([...this.selectedTransportOrders].length > 0);
        }
    }
    public checkSize() {
        if (this.selectedList === 'Cmr') {
            this.print();
        } else if (this.selectedList === 'Transport Order Document') {
            this.print();
        } else {
            this.overMaximum = true
                ? this.processedtransportOrder.length > 750
                : this.processedtransportOrder.length > 750;
            this.sizeOfSelectionToBig = (true
                ? this.processedtransportOrder.length > 250
                : this.processedtransportOrder.length > 250) && !this.overMaximum;
            this.printButtonRestriction = this.sizeOfSelectionToBig || this.overMaximum;
            if (this.sizeOfSelectionToBig === false && this.overMaximum === false) {
                this.print();
            }
        }
    }
    public print() {
        if (this.selectedList === 'Transport Order Document') {
                this.selectedTransportOrders.forEach(to => {
                        this.printService.printtransportorder(to.id);
                });
            this.closeModal();
        } else if (this.selectedList === 'Cmr') {
            this.sharedService.myUpdatedObject.tmsSelectCMRActions.forEach(CmrAction => {
                if (CmrAction.select[0] !== '0') {
                    let xmlTMSCmrObject: xmlTMSSelectCMRActionsXML = {
                        tmsSelectCMRActions: [CmrAction],
                        Text: ''
                    };
                    CmrAction.select[0] = "true";
                    this.getCmrActionsService.printCmrDocument(xmlTMSCmrObject,
                        this.selectedTransportOrders[0].planningBlocks[0].id, this.selectedTransportOrders[0].id);
                }
            });
        }
    }
}

export interface PrintListModel {
  }
