export const CREATED = 6;
export const LINE_CREATED = 14; 
export const TO_BE_PLANNED = 12;
export const PLANNED = 11;
export const LINE_PLANNED = 18 ; 
export const IN_PROGRESS = 8;
export const LINE_IN_PROGRESS = 15;
export const PRE_PLANNED = 13;
export const ON_HOLD = 94;
export const CANCELED = 88;
export const LINE_CANCELED =89 ; 
export const RE_USED = 95;
export const CLOSED = 9;
export const LINE_CLOSED = 19 ;
export const ACCEPTED = 16 ; 
export const LINE_PREPLANNED = 93;


export enum PlanningStatus {
  CREATED = '6',
  TO_BE_PLANNED = '12',
  PLANNED = '11',
  IN_PROGRESS = '8',
  PRE_PLANNED = '13',
  ON_HOLD = '94',
  CANCELED = '88',
  RE_USED = '95',
  CLOSED = '9',
  LINE_CLOSED = '19' ,
  ACCEPTED = '16' , 
  LINE_PLANNED = '18'   ,
  LINE_CREATED = '14' , 
  LINE_IN_PROGRESS = '15' , 
  LINE_CANCELED = '89' 
}
