import { NgRedux, NgReduxModule } from '@angular-redux/store';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ApplicationRef, enableProdMode, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { BrowserModule, Title } from '@angular/platform-browser';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { SharedModule } from '@progress/kendo-angular-dropdowns';
import { LoadingModule } from 'ngx-loading';
import { ToastNoAnimation, ToastNoAnimationModule, ToastrModule } from 'ngx-toastr';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { APP_CONFIG } from './app.config';
import { routing } from './app.routing';
import { ApplicationUserActions } from './pb-screen/application.user.actions';
import { ApplicationUserService } from './pb-screen/services/application.user.service';
import { ChannelEventFactory } from './pb-screen/services/channel.event.factory';
import { ChannelService, SignalrWindow } from './pb-screen/services/channel.service';
import { AuthorizedUser, HomeService } from './pb-screen/services/home.service';
import { ToScreenService } from './pb-screen/services/to-screen.service';
import { HomeComponent } from './shared/components/home.component';
import { ModalManagerService } from './shared/services/modal-manager.service';
import { SharedService } from './shared/services/shared.service';
import { SmsService } from './shared/services/sms.service';
import { TokenInterceptor } from './shared/services/token.interceptor';
import { SharedActions } from './shared/shared.actions';
import { SharedModules } from './shared/shared.modules';
import { IAppState } from './store/IAppState';
import { store } from './store/store';
import { InitialGuard } from './shared/guards/Initial.guard';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { DynamicCaptionService } from './shared/services/dynamic-caption.service';
import { DynamicCaptionPipe } from './shared/pipes/dynamic-caption.pipe';
import { settingComponent } from './setting-modal/setting-modal.component';
import { settingAction } from './setting-modal/setting.action';
import { copySettingComponent } from './copySetting-modal/copySetting-modal.component';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';


import { InputsModule } from "@progress/kendo-angular-inputs";

import { userSetupComponent } from './pb-screen/userSetup/userSetup-modal.component';
import { PlanningBoardActions } from './planningBoard/planningBoard.action';
import { PlanningBoardService } from './planningBoard/services/planningBoard.service';
import { PlanningBoardSignalRListener } from './planningBoard/services/PlanningBoardSignalRListener';
import { PBoardChannelService } from './planningBoard/services/PBoardchannel.service';
import { DriverAgendaService } from './driverAgenda/services/driverAgenda.service';
import { MainPageComponent } from './mainPage/main-page.component';
import { AgendaActions } from './shared/agenda.actions';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { VersionComponent } from './shared/components/modals/version-modal/version-modal.component';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { DepotOutActions } from './depot-out/depot-out.actions';
import { PoolActions } from './pool/pool.actions';
import { AnnouncementActions } from './announcement/announcement.actions';
import { StackerActions } from './stacker-screen/stacker.actions';
import { ShuttleListService } from './services/shuttle.print.service';

import { MsalService, MSAL_INSTANCE, MsalGuard, MsalInterceptor, MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MSAL_GUARD_CONFIG, MSAL_INTERCEPTOR_CONFIG } from './msal/constants';
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { MsalInterceptorConfig } from './msal/msal.interceptor.config';

import {MsalComponent} from "./MsalComponents"
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SimpleModalModule } from 'ngx-simple-modal';
import { LoggerModule, NgxLoggerLevel } from 'ngx-logger';
import { PlanningDatesService } from './planningBoard/services/planningDates.service';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { ToActions } from './to-screen/to.actions';
import { HttpErrorInterceptor } from './shared/services/error.interceptor';

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './resources/', '.json');
  }
  
export function getWindow() { return window; }


function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      redirectUri:  environment.redirectUrl,
      postLogoutRedirectUri: environment.redirectUrl,
      
      //navigateToLoginRequestUrl: true
    },
    cache: {
      storeAuthStateInCookie: false,
    }
  });
}

function MSALInterceptorConfigFactory(): MsalInterceptorConfig {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    
    interactionType: InteractionType.Redirect,
    protectedResourceMap, 
  };
}

enableProdMode();
@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        HttpModule,
        HttpClientModule,
        routing,
        SharedModule,
        NgReduxModule,
        LoadingModule,
        TranslateModule,
        DropDownsModule,
        InputsModule,
        ToastNoAnimationModule,
        ToastrModule.forRoot({
            toastComponent: ToastNoAnimation,
            positionClass: 'toast-top-right',
            progressBar: true,
        }),
        TranslateModule.forRoot({
          loader: {
            provide: TranslateLoader,
            useFactory: (HttpLoaderFactory),
            deps: [HttpClient]
          },
        }),
        SharedModules,
        NguiAutoCompleteModule,
        SimpleModalModule.forRoot({container:document.body}),
        LoggerModule.forRoot({
          level: NgxLoggerLevel.DEBUG,
          serverLogLevel: NgxLoggerLevel.ERROR,
          // serverLoggingUrl: ClientSettings.clientSettings.containerPlanningApiUri + 'logs/log/',
          serverLoggingUrl: environment.hubEndpointAddress + '/mock',
        }),
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
    ],
    declarations: [AppComponent, 
        HomeComponent, 
        settingComponent, 
        copySettingComponent, 
        userSetupComponent, 
        MainPageComponent,
        MsalComponent,
        VersionComponent],
    providers: [
      {
        provide: HTTP_INTERCEPTORS,
        useClass: TokenInterceptor,
        multi: true
      },

      {
        provide: HTTP_INTERCEPTORS,
        useClass: HttpErrorInterceptor,
        multi: true
      },
  
      {
        provide: MSAL_INSTANCE,
        useFactory: MSALInstanceFactory
      },
      {
        provide: MSAL_GUARD_CONFIG,
        useValue: {
          interactionType: InteractionType.Redirect
        } as MsalGuardConfiguration
      },
      {
        provide: MSAL_INTERCEPTOR_CONFIG,
        useFactory: MSALInterceptorConfigFactory
      },
      
          MsalService,
          MsalGuard,
          MsalBroadcastService,
        { provide: APP_BASE_HREF, useValue: environment.app_base_href },
        ChannelEventFactory,
        ChannelService,
        HomeService,
        AuthorizedUser,
        DriverAgendaService,
        { provide: SignalrWindow, useFactory: getWindow },
        Title,
        PlanningDatesService,
        ApplicationUserService,
        ApplicationUserActions,
        settingAction,
        SharedActions,
        ModalManagerService,
        SharedService,
        SmsService,
        ToScreenService,
        RedirectGuard,
        InitialGuard,
        DynamicCaptionService,
        PlanningBoardActions,
        PlanningBoardService , PlanningBoardSignalRListener ,
        PBoardChannelService,
        AgendaActions,
        DepotOutActions,
        PoolActions,
        AnnouncementActions,
        StackerActions,
        HomeService,
        ShuttleListService
        
    ],
    entryComponents:[ VersionComponent , MsalComponent , AppComponent],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(private ngRedux: NgRedux<IAppState>) {
        ngRedux.provideStore(store);
    }

    ngDoBootstrap(ref: ApplicationRef) {
      if (window !== window.parent && !window.opener)
      {
        console.log("Bootstrap: MSAL");
        ref.bootstrap(MsalComponent);
      }
      else
      {
      //this.router.resetConfig(RouterModule);
        console.log("Bootstrap: App");
        ref.bootstrap(AppComponent);
      }
    }
}
