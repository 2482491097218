import { select } from "@angular-redux/store";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { MsalService } from "../../../msal/msal.service";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { PoolCarrier } from "../../../pool/models/pool-carrier.interface";
import { PoolContainer } from "../../../pool/models/pool-containers.interface";
import { PoolEquipmentType } from "../../../pool/models/pool-equipment-type.interface";
import { PoolService } from "../../../pool/pool.service";
import { LocationSelectorComponent } from "../../../shared/components/location-selector/location-selector.component";
import { AddressLocation } from "../../models/address-location";
import { ComponentSource, ModalType } from "../../models/component-source";
import { FileAddress } from "../../models/file/file-address.interface";
import { File } from "../../models/file/file.interface";
import { UpdateFile } from "../../models/file/update-file.interface";
import { HttpClientService } from "../../services/httpclient";
import { ModalManagerService } from "../../services/modal-manager.service";
import { SharedService } from "../../services/shared.service";
import { SharedActions } from "../../shared.actions";




@Component({
    selector: "dossier-modal",
    templateUrl: "dossier-modal.component.html",
})

export class DossierModalComponent implements OnInit {
    public loading: boolean = false;
    @Input() public source: ComponentSource;
    @ViewChild(LocationSelectorComponent) private locationSelector: LocationSelectorComponent;

    @select("depotOutSelection") private depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") private depotInSelection$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;

    public fileNumber: string;
    public file: File;
    public closingDate: string;
    public depotOutRef1: string;
    public depotInRef1: string;
    public selectedCarrier: PoolCarrier;
    public carriers: PoolCarrier[];
    public selectedContainerType: PoolEquipmentType;
    public selectedPlanningBlocks: PlanningBlock[];
    public containerTypes: PoolEquipmentType[] = [];
    public fileAddresses: FileAddress[] = [];
    public depotIn: FileAddress;
    public depotOut: FileAddress;
    public selectedLocation: AddressLocation = null;

    public closingDateCheck: boolean = true;
    public depotOutCheck: boolean = true;
    public depotInCheck: boolean = true;
    public selectedCarrierCheck: boolean = true;
    public selectedContainerTypeCheck: boolean = true;
    public updateButtonState: boolean = true;
    public locationChanged: boolean = false;
    public modalType = ModalType;

    constructor(
        private readonly sharedService: SharedService,
        private readonly toastr: ToastrService,
        private readonly sharedActions: SharedActions,
        private readonly translate: TranslateService,
        private readonly poolService: PoolService,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService,
        public readonly httpClientService: HttpClientService,
    ) {

    }

    public ngOnInit(): void {


      this.loading$.subscribe((data) => {
        if (!data)
          this.loading = data;
      })
        this.httpClientService.getToken().subscribe((token) => {
            this.sharedService.getCarriers().subscribe((carriers: PoolCarrier[]) => {
                this.carriers = carriers;
                this.sharedService.getFileAddresses().subscribe((fileAddresses: FileAddress[]) => {
                    this.fileAddresses = fileAddresses;
    
                    this.poolService.getEquipmentTypes().subscribe((poolEquipmentTypes: PoolEquipmentType[]) => {
                        this.containerTypes = poolEquipmentTypes;
                        this.loadDefaultDossier();
                    });
                });
            });
        })
    }

    public loadDefaultDossier(): void {
        if (this.source === ComponentSource.depotIn) {
            this.subscribeToDepotInSelection();
        } else if (this.source === ComponentSource.depotOut) {
            this.subscribeToDepotOutSelection();
        }
    }
    public getSelectedLocation(location: AddressLocation) {
        if (location) {
            if (!this.locationChanged) {
                this.locationChanged = true;
                this.toastr.warning("The location change will be applied to all orders in the file", "");
            }
            this.selectedLocation = location;
        }
    }
    public subscribeToDepotInSelection(): void {
        this.depotInSelection$.subscribe((selectedDepotInPlanningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = selectedDepotInPlanningBlocks;
            this.setDefaultValuesFromSelection(selectedDepotInPlanningBlocks);
        });
    }

    public subscribeToDepotOutSelection(): void {
        this.depotOutSelection$.subscribe((selectedDepotOutPlanningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = selectedDepotOutPlanningBlocks;
            this.setDefaultValuesFromSelection(selectedDepotOutPlanningBlocks);
        });
    }

    public setDefaultValuesFromSelection(selectedPlanningBlocks: PlanningBlock[]): void {
        if (selectedPlanningBlocks && selectedPlanningBlocks.length > 0) {
            // this.locationSelector.setSelectionFromParent(selectedPlanningBlocks[0].latestLocationCode);
            this.fileNumber = selectedPlanningBlocks[0].fileId;
            this.setFileFieldsByFileNumber();
        }
    }

    public setFileFieldsByFileNumber(): void {
        this.locationChanged = false;
        if (this.fileNumber) {
            this.sharedService.getFile(this.fileNumber).subscribe((file: File) => {
                this.file = file;
                this.closingDate = file.closingDateTime;
                this.depotOutRef1 = file.depotOutRef1;
                this.depotInRef1 = file.depotInRef1;

                const depotIn = this.fileAddresses.find((fileAddress: FileAddress) => {
                    return fileAddress.addressNo === file.depotIn;
                });
                this.depotIn = depotIn;

                const depotOut = this.fileAddresses.find((fileAddress: FileAddress) => {
                    return fileAddress.addressNo === file.depotOut;
                });
                this.depotOut = depotOut;

                const containerType = this.containerTypes.find((poolEquipmentType: PoolEquipmentType) => {
                    return poolEquipmentType.code === file.containerType;
                });
                this.selectedContainerType = containerType ? containerType : null;

                const carrier = this.carriers.find((poolCarrier: PoolCarrier) => {
                    return poolCarrier.no === file.carrierNo;
                });
                this.selectedCarrier = carrier ? carrier : null;
                // this.selectedLocation = this.locationSelector.setSelectionFromParent(file.locationCode);
                this.setFieldsChecks();
            });
        }
    }

    public setFieldsChecks(): void {
        this.closingDateCheck = this.closingDate != null && this.closingDate !== "" && this.closingDate !== undefined && this.closingDate.toString() !== "";
        this.depotOutCheck = this.depotOut != null && this.depotOut !== undefined && this.depotOut.toString() !== "";
        this.depotInCheck = this.depotIn != null && this.depotIn !== undefined && this.depotIn.toString() !== "";
        this.selectedCarrierCheck = this.selectedCarrier != null && this.selectedCarrier !== undefined && this.selectedCarrier.toString() !== "";
        this.selectedContainerTypeCheck = this.selectedContainerType != null && this.selectedContainerType !== undefined && this.selectedContainerType.toString() !== "";
        this.setUpdateButtonState();
    }

    public setUpdateButtonState(): void {
        this.updateButtonState = this.closingDateCheck && this.depotOutCheck && this.depotInCheck && this.selectedCarrierCheck && this.selectedContainerTypeCheck;
    }

    public updateDossier(): void {
        const account = this.authService.getAccount(); 
        
        this.loading = true;
        if(account.username) {
            this.updateDossierServiceCall(account.username);
        } else {
            this.setDefaultValuesFromSelection(this.selectedPlanningBlocks);
            this.loading = false;
            this.toastr.error("ERROR : " + "User Not Found");
        }

    }

    public updateDossierServiceCall(username: string): void {
        const updateDossierModel: UpdateFile = {
            depotOutRef1: this.depotOutRef1,
            depotInRef1: this.depotInRef1,
            closingDate: this.closingDate,
            selectedContainerType: this.selectedContainerType ? this.selectedContainerType.code : "",
            source: this.source,
            userId: username,
            selectedCarrier: this.selectedCarrier ? this.selectedCarrier.no : "",
            depotIn: this.depotIn ? this.depotIn.addressNo : "",
            depotOut: this.depotOut ? this.depotOut.addressNo : " ",
            addressId: (this.selectedLocation && this.selectedLocation.addressId !== this.file.addressNo && this.selectedLocation.locationCode !== this.file.locationCode)
                ? this.selectedLocation.addressId : "",
            locationCode: (this.selectedLocation && this.selectedLocation.addressId !== this.file.addressNo && this.selectedLocation.locationCode !== this.file.locationCode)
                ? this.selectedLocation.locationCode : "",
        };

        this.sharedService.updateFile(this.fileNumber, updateDossierModel)
            .subscribe((planningBlocks: PlanningBlock[]) => {
                this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
                this.loading = false;
                this.translate.get("TOASTR_MESSAGES.SUCCESS.DOSSIER_UPDATE_SUCCEEDED")
                    .subscribe((res: string) => {
                        this.toastr.success(res, "", {
                            closeButton: false
                        });
                        this.loading = false;
                        this.close();
                    });
            }, (error: any) => {
                this.loading = false;
            });
    }

    public close(): void {
        this.setFileFieldsByFileNumber();
        this.sharedActions.setUiState(true);
        this.toastr.clear();
        this.modalManager.closeModal(this.modalType.dossier);
    }

    public containerTypeFormatter(data: PoolEquipmentType): string {
        return `${data.code}`;
    }

    public carrierFormatter(data: PoolCarrier): string {
        return `${data.no}`;
    }

    public addressLocationsFormatter(data: AddressLocation): string {
        return `${data.searchName}`;
    }
}
