import { cpuUsage } from "process";
import { isTruthyComparison } from "../../pb-screen/filters/advanced.filter.comparison";
import { PoolContainer } from "../../pool/models/pool-containers.interface";
import { PoolMove } from "../../pool/models/pool-move.interface";
import { AdvancedFilterOperatorType, AdvancedFilterColumnType } from "../../shared/components/filter-line/filter-line.interface";
import { transformColumnNamesToProperties } from "../../static/columName.to.property.transformation";


const columnNames = [
    { title: "Container number", field: "containerNo" }, { title: "Container Type", field: "containerType" },
    { title: "Carrier Name", field: "carrierName" }, { title: "Status", field: "status" },
    { title: "Location", field: "location" }, { title: "Pool adress Name", field: "poolAddressSearchName" },
    { title: "Comment", field: "comment" }
];


const MovesColumnNames = [
    { title: "Container number", field: "containerNo" }, 
    { title: "Announced number", field: "announced" }, 
    { title: "Container Type", field: "containerType" },
    { title: "Haulier Name", field: "haulierName" }, 
    { title: "Truck Registration No", field: "truckRegistrationNo" },
    { title: "Driver Name", field: "driverName" }, 
    { title: "Carrier", field: "carrierCode" }, 
    { title: "To Location", field: "toLocation" }, 
    { title: "Comment", field: "comment" }];


export function getFilteredContainersBySearchText(containers: PoolContainer[],
    searchText: string): PoolContainer[] {
    const matches: PoolContainer[] = [];
    const columNames: string[] = transformColumnNamesToProperties(columnNames.map((x) => x.field));
    const splitSearchText: string[] = searchText.split(" ");
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);

    for (let container of containers) {
        let matchedAllStrings: boolean = true;
        for (let text of filteredSplitSearchText) {
            let isValidFilterOperation: boolean = false;
            for (let column of columNames) {
                if (isTruthyComparison(container[column],
                    AdvancedFilterOperatorType.Contains,
                    text,
                    AdvancedFilterColumnType.Text)) {
                    isValidFilterOperation = true;
                }
            }
            if (!isValidFilterOperation) {
                matchedAllStrings = false;
                break;
            }
        }

        if (matchedAllStrings) {
            matches.push(container);
        }
    }
    return matches;
}

export function getFilteredPreAnnouncedMovesBySearchText(moves : PoolMove[] , searchText : string) : PoolMove[]
{
    const matches : PoolMove[] = []; 
    const columNames : string[] = transformColumnNamesToProperties(MovesColumnNames.map((x) => x.field));   

    const splitSearchText: string[] = searchText.split(" ");
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);

    for (let move of moves) {
        let matchedAllStrings: boolean = true;
        for (let text of filteredSplitSearchText) {
            let isValidFilterOperation: boolean = false;
            for (let column of columNames) {
                if (isTruthyComparison(move[column],
                    AdvancedFilterOperatorType.Contains,
                    text,
                    AdvancedFilterColumnType.Text)) {
                    isValidFilterOperation = true;
                }
            }
            if (!isValidFilterOperation) {
                matchedAllStrings = false;
                break;
            }
        }

        if (matchedAllStrings) {
            matches.push(move);
        }
    }
    return matches;
}

export function getFilteredAnnouncedContainers(containers: PoolContainer[] , searchText: string) : PoolContainer[] {
    const matches: PoolContainer[] = [];
    const columNames: string[] = transformColumnNamesToProperties(columnNames.map((x) => x.field));
    const splitSearchText: string[] = searchText.split(" ");
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);

    for (let container of containers) {
        let matchedAllStrings: boolean = true;
        for (let text of filteredSplitSearchText) {
            let isValidFilterOperation: boolean = false;
            for (let column of columNames) {
                if (isTruthyComparison(container[column],
                    AdvancedFilterOperatorType.Contains,
                    text,
                    AdvancedFilterColumnType.Text)) {
                    isValidFilterOperation = true;
                }
            }
            if (!isValidFilterOperation) {
                matchedAllStrings = false;
                break;
            }
        }

        if (matchedAllStrings) {
            matches.push(container);
        }
    }
    return matches;
}