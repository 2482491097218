import { Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import { AdvancedFilterColumn, AdvancedFilterColumnType, AdvancedFilterGroup, AdvancedFilterGroupType, AdvancedFilterItem } from '../../../../shared/components/filter-line/filter-line.interface';

@Component({
  moduleId: module.id,
  selector: 'filter-group',
  templateUrl: './filter-group.component.html',
  styleUrls: ['./filter-group.component.css']
})

export class FilterGroupComponent implements OnInit {
  public GroupTypes = AdvancedFilterGroupType;
  @Output() public removeFilterGroup: EventEmitter<AdvancedFilterGroup> = new EventEmitter<AdvancedFilterGroup>();

  ngOnInit(): void {
  }

  @Input() filterGroup: AdvancedFilterGroup = {filterGroups: [], filterItems: [], operator: AdvancedFilterGroupType.And};
  @Input() public advancedFilterColumns: AdvancedFilterColumn[];
  @Input() isFirst: boolean = false;

  public removeFilterItem($event: AdvancedFilterItem, index: number): void {
    this.filterGroup.filterItems.splice(this.filterGroup.filterItems.indexOf($event),1);
  }

  addFilterLine(){
    this.filterGroup.filterItems.push({
      columnName: '',
      operator: null,
      type: AdvancedFilterColumnType.Text,
      value: '',
    });
  }

  addFilterGroup(){
    this.filterGroup.filterGroups.push({
      filterItems: [], 
    filterGroups: [],
    operator: AdvancedFilterGroupType.And});
  }

  onRemoveFilterGroup(){
    this.removeFilterGroup.emit(this.filterGroup);
  }

  removeFilterSubGroup($event, i){
    this.filterGroup.filterGroups.splice(this.filterGroup.filterGroups.indexOf($event),1);
  }
}
