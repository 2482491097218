
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { Http, ResponseContentType ,Response } from '@angular/http';
import { ToastrService } from 'ngx-toastr';
import { SharedService } from '../../shared/services/shared.service';
import { environment } from '../../../environments/environment';
import { PlanningBlock } from '../models/planning-block/planning-block.interface';
import { ExportCmrData } from '../models/CMR-data-raw.interface';
import { CMRdata } from '../models/CMR-data.interface';


@Injectable()
export class CMRService {

    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
        private readonly sharedService: SharedService
    ) { }

    private baseUrl: string = 'http://localhost:49211/v1/print/cmr';
  


    public printCMR(planningBlocks: PlanningBlock[]): any{
        for(let planningBlock of planningBlocks){
        this.getDataObject(planningBlock);
      }
    }


    public getDataObject(planningBlock: PlanningBlock): any {
        return this.sharedService.getCMRDataFromPlanningBlockById(planningBlock.id).subscribe((res: ExportCmrData) => {
          this.prepareObject(res);
        });

      }

      public prepareObject(res: ExportCmrData){
        let formattedData: CMRdata = {
          collection_Address: "   ",
          collection_Address_Simple:"  ",
          delivery_Address: " ",
          cargo_Good_Details: "  ",
          cargo_Quantity: "  ",
          cargo_Total_Gross_Weight: " ",
          cargo_Unit_Of_Measure_Details: " ",
          collection_Order_Line_Ref_1: " ",
          container_No: " ",
          container_Sub_Type: " ",
          container_Type: " ",
          country_Of_Destination: " ",
          delivery_Address_Simple: " ",
          delivery_Order_Line_Ref_1:" ",
          destination_Info:" ",
          documents: " ",
          drop_At_Address: " ",
          drop_At_Order_Line_Ref_1:" ",
          aDR:" ",
          haulier_Detail:" ",
          haulier_Details: " ",
          trailer_Description: " ",
          license_No:" ",
          license_Plate:" ",
          unique_Order_Line_ID: {Value: " "},
          order_No: " ",
          pickup_Order_Line_Ref_1: " ",
          pickup_Address: " ",
          seal:" ",
          vessel_Name: " ",
          type: {
            iD: 1,
          },
        };

        if(res.collectionAddressName){
          formattedData.collection_Address = "";
          formattedData.collection_Address += res.collectionAddressName + "\n";
        } else {
          formattedData.collection_Address += " \n";}
        if(res.collectionAddrNumber){
          formattedData.collection_Address += res.collectionAddrNumber + "\n";
        } else {
          formattedData.collection_Address += " \n";
        }
        if(res.collectionAddressPostalCode){
          formattedData.collection_Address += res.collectionAddressPostalCode + " ";
        }
        if(res.collectionAddressCity){
          formattedData.collection_Address += res.collectionAddressCity;
        }
        if(res.collectionAddrCountryCode){
          formattedData.collection_Address += ", " + res.collectionAddrCountryCode;
        }

        if(res.deliveryAddressName){
          formattedData.delivery_Address = "";
          formattedData.delivery_Address += res.deliveryAddressName + "\n";
        } else {
          formattedData.delivery_Address += " \n";}
        if(res.deliveryAddressStreet){
          formattedData.delivery_Address += res.deliveryAddressStreet + "\n";
        } else {
          formattedData.delivery_Address += " \n";
        }
        if(res.deliveryAddressPostalCode){
          formattedData.delivery_Address += res.deliveryAddressPostalCode + " ";
        }
        if(res.deliveryAddressCity){
          formattedData.delivery_Address += res.deliveryAddressCity;
        }
        if(res.deliveryAddressCountryCode){
          formattedData.delivery_Address += ", " + res.deliveryAddressCountryCode;
        }

        if(res.collectionAddressCity){
          formattedData.collection_Address_Simple = res.collectionAddressCity;
        }
        if(res.collectionAddrCountryCode){
          formattedData.collection_Address_Simple += ", " + res.collectionAddrCountryCode;
        }
        if(res.collectionTimeFrom){
          let date = this.convertDate(res.collectionTimeFrom);
          formattedData.collection_Address_Simple += " " + date;
        }

        if(res.deliveryAddressCity){
          formattedData.delivery_Address_Simple = res.deliveryAddressCity;
        }
        if(res.deliveryAddressCountryCode){
          formattedData.delivery_Address_Simple += ", " + res.deliveryAddressCountryCode;
        }
        if(res.deliveryTimeFrom){
          let date = this.convertDate(res.deliveryTimeFrom);
          formattedData.delivery_Address_Simple += " " + date;
        }

        if(res.licensePlate){
          formattedData.license_Plate = res.licensePlate;
        }

        if(res.licenseNo){
          formattedData.license_No = res.licenseNo;
        }

        if(res.orderNo){
          formattedData.order_No = res.orderNo;
        }

        if(res.uniqueOrderLineID){
          formattedData.unique_Order_Line_ID.Value = res.uniqueOrderLineID;
        }

        if(res.trailerDescription){
          formattedData.trailer_Description = res.trailerDescription;
        }

        if(res.pickupAddressName){
          formattedData.pickup_Address = "";
          formattedData.pickup_Address += res.pickupAddressName + "\n";
        } else {
          formattedData.pickup_Address += " \n";}
        if(res.pickupAddressStreet){
          formattedData.pickup_Address += res.pickupAddressStreet + "\n";
        } else {
          formattedData.pickup_Address += " \n";
        }
        if(res.pickupAddressPostalCode){
          formattedData.pickup_Address += res.pickupAddressPostalCode + " ";
        }
        if(res.pickupAddressCity){
          formattedData.pickup_Address += res.pickupAddressCity;
        }
        if(res.pickupAddressCountryCode){
          formattedData.pickup_Address += ", " + res.pickupAddressCountryCode;
        }

        if(res.pickupOrderLineRef1){
          formattedData.pickup_Order_Line_Ref_1 = res.pickupOrderLineRef1;
        }

        if(res.collectionOrderLineRef1){
          formattedData.collection_Order_Line_Ref_1 = res.collectionOrderLineRef1;
        }

        if(res.containerNo){
          formattedData.container_No = res.containerNo;
        }

        if(res.containerType){
          formattedData.container_Type = res.containerType;
        }

        if(res.containerSubType){
          formattedData.container_Sub_Type = res.containerSubType;
        }

        if(res.destinationInfo){
          formattedData.destination_Info = res.destinationInfo;
        }

        if(res.countryOfDestination){
          formattedData.country_Of_Destination = res.countryOfDestination;
        }

        if(res.dropAtAddressName){
          formattedData.drop_At_Address = "";
          formattedData.drop_At_Address += res.dropAtAddressName + "\n";
        } else {
          formattedData.drop_At_Address += " \n";}
        if(res.dropAtAddressStreet){
          formattedData.drop_At_Address += res.dropAtAddressStreet + "\n";
        } else {
          formattedData.drop_At_Address += " \n";
        }
        if(res.dropAtAddressPostalCode){
          formattedData.drop_At_Address += res.dropAtAddressPostalCode + " ";
        }
        if(res.dropAtAddressCity){
          formattedData.drop_At_Address += res.dropAtAddressCity;
        }
        if(res.dropAtAddressCountryCode){
          formattedData.drop_At_Address += ", " + res.dropAtAddressCountryCode;
        }

        if(res.dropAtOrderLineRef1){
          formattedData.drop_At_Order_Line_Ref_1 = res.dropAtOrderLineRef1;
        }

        if(res.deliveryOrderLineRef1){
          formattedData.delivery_Order_Line_Ref_1 = res.deliveryOrderLineRef1;
        }

        if(res.seal){
          formattedData.seal = res.seal;
        }
        
        if(res.vesselNameExport){
          formattedData.vessel_Name = res.vesselNameExport;
        }

        if(res.cmrDetails.goodDescription[0]){
          formattedData.cargo_Good_Details = res.cmrDetails.goodDescription[0];
        }

        if(res.cmrDetails.quantity[0]){
          formattedData.cargo_Quantity = res.cmrDetails.quantity[0];
        }
       
        if(res.cmrDetails.netWeight[0]){
          formattedData.cargo_Total_Gross_Weight = res.cmrDetails.netWeight[0];
        }

         if(res.cmrDetails.adr[0]){
            if(res.cmrDetails.adr[0] === "1"){
              formattedData.aDR = "Yes"
            } else { formattedData.aDR = "No" }
          } else { formattedData.aDR = "Unknown" }

        if(res.t1Document[0]){
          formattedData.documents = res.t1Document[0];
        }

        if(res.haulierName){
          formattedData.haulier_Details = "";
          formattedData.haulier_Details += res.haulierName + "\n";
        } else {
          formattedData.haulier_Details += " \n";}
        if(res.haulierAddress){
          formattedData.haulier_Details += res.haulierAddress + "\n";
        } else {
          formattedData.haulier_Details += " \n";
        }
        if(res.haulierPostCode){
          formattedData.haulier_Details += res.haulierPostCode + " ";
        }
        if(res.haulierCity){
          formattedData.haulier_Details += res.haulierCity;
        }
        if(res.haulierCountry){
          formattedData.haulier_Details += ", " + res.haulierCountry;
        }
        formattedData.haulier_Detail = formattedData.haulier_Details;

        formattedData.type.iD = 1;
        this.getPDF(formattedData).subscribe((result: any) => {});
      }

      public getPDF(formattedData: CMRdata){
        return this.http.post(this.baseUrl , formattedData, { responseType:ResponseContentType.Blob } ).pipe(
        map((res:any) =>
        window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))),
        catchError((err) => {
          return observableThrowError(err);
        }),);
      }

      public convertDate(dateString) {
        const date = new Date(dateString);
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      }

}