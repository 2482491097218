import { OnInit, Component, Input } from "@angular/core";
import { Observable } from "rxjs";
import { select } from "@angular-redux/store";
import { SharedActions } from "../../shared.actions";
import { PbActions } from "../../../pb-screen/pb.actions";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { Notification } from "../../models/notification/notification.interface";
import { SelectionEnum } from "../../models/selection.enum";


@Component({
    selector: 'notification-display',
    templateUrl: './notification-display.component.html'
})
export class NotificationDisplayComponent implements OnInit {

    @select('notifications') public notifications$ : Observable<Notification[]>;
    @select('planningBlockNotifications') public planningBlockNotifications$ : Observable<Notification[]>;
    @select('transportOrderNotifications') public transportOrderNotifications$ : Observable<Notification[]>;
    @select('filteredPlanningBlocks') public filteredPlanningBlocks$ : Observable<PlanningBlock[]>;

    @Input('selectionType') public selectionType : SelectionEnum;
    @Input('source') public source: string;

    private planningBlocks : PlanningBlock[] = [];
    private notifications : Notification[] = [];
    private planningBlockNotifications : Notification[] = [];
    private transportOrderNotifications : Notification[] = [];

    constructor(private readonly sharedActions : SharedActions,
                private readonly pbActions : PbActions) {
    }

    ngOnInit(): void {
        this.notifications$.subscribe((response : Notification[]) => {
            this.notifications = response;
        });

        this.filteredPlanningBlocks$.subscribe((response : PlanningBlock[]) => {
            this.planningBlocks = response;
        })

        this.planningBlockNotifications$.subscribe((response : Notification[]) => {
            this.planningBlockNotifications = response;
        });

        this.transportOrderNotifications$.subscribe((response : Notification[]) => {
            this.transportOrderNotifications = response;
        })
    }

    setSelectedPlanningBlock(notification : Notification){
        
    }

}