export const environment = {
    envName: 'McCann-TEST-AZURE',
    production: false,
    tenant: 'Go4Tms4McCannAzure.onmicrosoft.com',
    clientId: '627ea435-d5dd-4ca8-8d82-80a385ad2d17',

    authority: 'https://login.microsoftonline.com/Go4Tms4McCannAzure.onmicrosoft.com',
    redirectUrl: 'https://mccann-test-pt4-ext.go4tms.net/',

    extApplicationEndpointAddress: 'https://mccann-test-pt4-ext.go4tms.net/',
    tmsApiEndpointAddress: 'https://mccann-test-pt4-tms-api.go4tms.net/tms/v1/',
    hubEndpointAddress: 'https://mccann-test-pt4.go4tms.net',
    tmsUserApiEndPointAddress: 'https://mccann-test-pt4-user-api.go4tms.net/tms/v1/',
    pt4ClientEndPointAddress: 'https://mccann-test-pt4.go4tms.net/#/go4tms/',
    containerPlanningEndpoint: 'https://mccann-test-cp.go4tms.net/',
    defaultPlanningGroup : 'ALL',
    defaultPlanningZone : '',
    defaultPlanDaysAhead : 60,
    defaultPlanDaysBack : 60,
    disableToSelectionModal: false,
    depotInIcon : false , 
    depotOutIcon : false,
    iconType : "truck",
    maxDays : 3 ,
    collectionIcon: true,
    deliveryIcon: true,
    TODaysAhead : 60,
    TOdDaysBack : 60,
    shipping : true , 
    railway : false,
    tarTasEnabled: false,
    withQuotes: false,
    adminGroup : "PTADMINISTRATORS",
    containerReleaseEnabled: false,
    containerPoolEnabled: false,
    buisnessCentralUrl: 'https://mccann-bc.go4tms.net/TEST/', 
    showWaitingRoom : false ,
    ContainerPlanningApiUri : "", 
    ContainerPlanningClientUri : "" ,
    ContainerPlanningSignalRUri: "",
    MonitorAuthorizedUsers : "",
    checkOverlappingTos: false,
    app_base_href:'/' ,   
    
    buisnessCentralCompany : ''    

    
  };

