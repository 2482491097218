import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'orderlineFilter'
  })
  
export class OrderlineFilterPipe implements PipeTransform {
    transform(values: any[], filter : string) : any[] {
        
        let list =  values.filter((x) => {
            x.insertActionCode === filter;
        });
        return list ;
    }

}