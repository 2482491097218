import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClientService } from './../../shared/services/httpclient';
import { Injectable, OnInit } from '@angular/core';
import { TransportOrder } from '../models/transport-order/transport-order.interface';
import { select } from '@angular-redux/store';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { TMSSelectedCMRActions, xmlTMSSelectCMRActionsXML } from '../models/TMSSelectCMRActions.interface';

@Injectable()
export class GetCmrActionsService implements OnInit {
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    public selectedTransportOrders: TransportOrder[] = [];

    public baseUrl: string = environment.tmsApiEndpointAddress + 'print';
    public CmrActions: TMSSelectedCMRActions[];
    constructor(
        private http: HttpClientService,
        private readonly toastr: ToastrService,
    ) { }
    ngOnInit(): void {
        this.selectedTransportOrders$.subscribe((transportOrders: TransportOrder[]) => {
            this.selectedTransportOrders = transportOrders;
        });
    }

    public getCmrActionsForTo(id: string): Observable<xmlTMSSelectCMRActionsXML> {
        return this.http.get(this.baseUrl + '/getCmrActionsFromTo?id=' + id).pipe(map((response: any) => { return response.data }
        ));
    }
    public getCmrActionsForPb(id: number): Observable<xmlTMSSelectCMRActionsXML> {
        return this.http.get(this.baseUrl + '/getCmrActionsFromPb?id=' + id).pipe(map((response: any) => { return response.data }
        ));
    }
    public printCmrDocument(Obj: xmlTMSSelectCMRActionsXML, planningBlockId: number, transportOrderId: string) {

        return this.http
            .post(this.baseUrl + '/PrintCmr?planningBlockId=' + planningBlockId + '&transportOrderId=' + transportOrderId, Obj, "blob").subscribe((response: any) => {
                window.open(window.URL.createObjectURL(response));
            });

    }
}