// tslint:disable-next-line:max-line-length
import * as moment from 'moment';
import { AdvancedFilterColumnType, AdvancedFilterGroup, AdvancedFilterGroupType, AdvancedFilterOperatorType, AdvancedFilterTypeOperator } from '../../shared/components/filter-line/filter-line.interface';
import { PlanningBlock } from '../models/planning-block/planning-block.interface';

export const possibleOperators: AdvancedFilterTypeOperator[] =
  [
    {
      type: AdvancedFilterColumnType.Text,
      operators: [AdvancedFilterOperatorType.Equal, AdvancedFilterOperatorType.Contains,
        AdvancedFilterOperatorType.In , AdvancedFilterOperatorType.NotContains, AdvancedFilterOperatorType.NotIn , AdvancedFilterOperatorType.NotEqual],
    },
    {
      type: AdvancedFilterColumnType.Select,
      operators: [AdvancedFilterOperatorType.Equal, AdvancedFilterOperatorType.NotEqual, AdvancedFilterOperatorType.Empty],
    },
    {
      type: AdvancedFilterColumnType.Number,
      operators: [AdvancedFilterOperatorType.Equal, AdvancedFilterOperatorType.NotEqual,
      AdvancedFilterOperatorType.GreaterThan, AdvancedFilterOperatorType.GreaterThanOrEqual,
      AdvancedFilterOperatorType.LessThan, AdvancedFilterOperatorType.LessThanOrEqual],
    },
    {
      type: AdvancedFilterColumnType.DateTime,
      operators: [AdvancedFilterOperatorType.Equal,
      AdvancedFilterOperatorType.NotEqual, AdvancedFilterOperatorType.GreaterThan,
      AdvancedFilterOperatorType.GreaterThanOrEqual, AdvancedFilterOperatorType.LessThan,
      AdvancedFilterOperatorType.LessThanOrEqual],
    },
    {
      type: AdvancedFilterColumnType.Boolean,
      operators: [AdvancedFilterOperatorType.Equal,AdvancedFilterOperatorType.NotEqual],
    },
  ];

export function isTruthyComparison(initialValue: any,
  operator: AdvancedFilterOperatorType,
  expectedValue: any,
  advancedFilterColumnType: AdvancedFilterColumnType): boolean {
  if (!initialValue || !expectedValue) {
    return false;
  }

  if (!isValidTypeAndOperator(operator, advancedFilterColumnType)) {
    return false;
  }

  if (advancedFilterColumnType === AdvancedFilterColumnType.Text
    || advancedFilterColumnType === AdvancedFilterColumnType.Select) {
    if(initialValue.statusType) {//special case of pb status
      initialValue = initialValue.statusType.toString();
    }
    else {
      initialValue = initialValue.toString().toLowerCase();
      expectedValue = expectedValue.toString().toLowerCase();
    }
  }

  if (advancedFilterColumnType === AdvancedFilterColumnType.Number) {
    if (isNaN(expectedValue)) {
      expectedValue = Number(parseFloat(expectedValue.replace(',', '.')).toFixed(2));
    }

    if (isNaN(expectedValue)) {
      return false;
    }
  }

  if (advancedFilterColumnType === AdvancedFilterColumnType.Boolean) {
    initialValue = JSON.parse(initialValue.toString().toLowerCase());
    expectedValue = JSON.parse(expectedValue.toString().toLowerCase());
  }

  if (advancedFilterColumnType === AdvancedFilterColumnType.DateTime) {
    if(initialValue.includes('T')) //pb screen
    {
      initialValue = moment(initialValue).startOf('day').valueOf() ;
      expectedValue = moment(expectedValue).startOf('day').valueOf();
    }
    else{ //depots
      const initialValueBits = initialValue.split(/\D/);	
      initialValue = moment(initialValueBits[2] + initialValueBits[1] + initialValueBits[0], "YYYYMMDD").startOf('day').valueOf();
      expectedValue = moment(expectedValue).startOf('day').valueOf();
    }
  }

  switch (operator) {
    case AdvancedFilterOperatorType.Equal:
      return initialValue === expectedValue;
    case AdvancedFilterOperatorType.Contains:
      return initialValue.indexOf(expectedValue) !== -1 && initialValue.indexOf('1753-01-01') === -1;
    case AdvancedFilterOperatorType.NotEqual:
      return initialValue !== expectedValue;
    case AdvancedFilterOperatorType.Empty:
      return initialValue === '';
    case AdvancedFilterOperatorType.GreaterThan:

        return initialValue > expectedValue;
   case AdvancedFilterOperatorType.GreaterThanOrEqual:

          return initialValue >= expectedValue;
    case AdvancedFilterOperatorType.LessThan:
           return initialValue < expectedValue;
    case AdvancedFilterOperatorType.LessThanOrEqual:
      return initialValue <= expectedValue;
    case AdvancedFilterOperatorType.In:
      return isInOneOfTheSearchTerms(initialValue, expectedValue);
    case AdvancedFilterOperatorType.NotIn:
      return !isInOneOfTheSearchTerms(initialValue,expectedValue);
    case AdvancedFilterOperatorType.NotContains:
      return initialValue.indexOf(expectedValue) === -1;
  }
}

function isInOneOfTheSearchTerms(initialValue: string, expectedValue: string): boolean {
  const splitSearchText: string[] = expectedValue.split(';');
  const filteredSplitSearchText: string[] = splitSearchText.filter((t) => t.length > 0);
  for (const text of filteredSplitSearchText) {
    /* if (text ===  initialValue) {
      return true;
    } */
    if (initialValue.indexOf(text) !== -1) {
      return true;
    }
  }
  return false;
}

function isValidTypeAndOperator(operator: AdvancedFilterOperatorType,
  advancedFilterColumnType: AdvancedFilterColumnType) {
  const type: AdvancedFilterTypeOperator = possibleOperators.find((x) => x.type === advancedFilterColumnType);
  if (!type) { return false; }

  return type.operators.find((x) => x === operator);
}

export function advancedFilterPlanningBlocks(planningBlocks: PlanningBlock[],
  advancedFilterGroup: AdvancedFilterGroup): PlanningBlock[] {
  const matches: PlanningBlock[] = [];

  if(!advancedFilterGroup || 
      ((!advancedFilterGroup.filterGroups || !advancedFilterGroup.filterGroups.length) && 
        (!advancedFilterGroup.filterItems || !advancedFilterGroup.filterItems.length)))
        return planningBlocks;
  // tslint:disable-next-line:prefer-const
  for (let planningBlock of planningBlocks) {
    let isMatch: boolean = matchGroup(advancedFilterGroup, planningBlock);
    if (isMatch) {
      matches.push(planningBlock);
    }
  }

  return matches;
}

function matchFilterItems(filterGroup: AdvancedFilterGroup, planningBlock: PlanningBlock): boolean{
    let isMatch: boolean = undefined;
    let operator = filterGroup.operator;
    let advancedFilterItems = filterGroup.filterItems;
    for (let filterItem of advancedFilterItems) 
    {
      isMatch = isTruthyComparison(planningBlock[filterItem.columnName], filterItem.operator, filterItem.value, filterItem.type);
      if(operator == AdvancedFilterGroupType.And && !isMatch)
      {
        return false;
      }
      if(operator == AdvancedFilterGroupType.Or && isMatch)
      {
        return true;
      }
    }

    return isMatch;
}

function matchGroup(filterGroup: AdvancedFilterGroup, planningBlock: PlanningBlock): boolean{
    let isMatch: boolean = matchFilterItems(filterGroup, planningBlock);
    let operator = filterGroup.operator;
    if(filterGroup.filterGroups.length > 0 && (isMatch == undefined || (operator == AdvancedFilterGroupType.And && isMatch) 
      || operator == AdvancedFilterGroupType.Or && !isMatch))
    {
      let filterGroups = filterGroup.filterGroups;
      let operator = filterGroup.operator;
      let isFilterGroupMatch: boolean = undefined;
      for(let filterSubGroup of filterGroups)
      {
        isFilterGroupMatch = matchGroup(filterSubGroup, planningBlock);
        if(operator == AdvancedFilterGroupType.And && !isFilterGroupMatch)
        {
          return false;
        }
        if(operator == AdvancedFilterGroupType.Or && isFilterGroupMatch)
        {
          return true;
        }
      }
      return isFilterGroupMatch;
    }
    return isMatch;
}

