import { filterPlanningBlocks } from "../depot-out/filters/depot-out.quickfilter";
import { ActionKeys } from "../pb-screen/action.keys";
import { advancedFilterPlanningBlocks } from "../pb-screen/filters/advanced.filter.comparison";
import { ContainerStatusId } from "../pb-screen/models/planning-block/planning-block-container-status.interface";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { IdNameAsStringObject } from "../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { getFilteredPlanningBlocksBySearchText } from "../shared/filter.by.text";
import {
  TO_BE_PLANNED,
} from "../shared/models/status-type.interface";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';

/* NOTE: When adding a case be sure you add it to the fallthrough in the reducer.ts file */
export function depotOutReducer(state: IAppState = InitialState.initialState, action) {
  switch (action.type) {
    case ActionKeys.UPDATE_DEPOT_OUT_PLANNED_PLANNING_BLOCKS:
      return updateDepotOutPlannedPlanningBlocks(state, action);
    case ActionKeys.FILTER_DEPOT_OUT_PLANNINGBLOCKS:
      return filterDepotOutPlanningBlocks(state, action);
    case ActionKeys.REQUEST_DEPOT_OUT_PLANNINGBLOCKS_SUCCESS:
      return storeDepotOutPlanningBlocks(state, action);
    // quick filter
    case ActionKeys.QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
      return quickFilterDepotOutPlanningBlocks(state, action);
    case ActionKeys.RESET_QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
      return resetQuickFilterDepotOutPlanningBlocks(state, action);
    // advanced filter
    case ActionKeys.RESET_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
      return resetAdvancedFilterDepotOutPlanningBlocks(state, action);
    case ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
      return advancedFilterDepotOutPlanningBlocks(state, action);
    // column selection
    case ActionKeys.UPDATE_DEPOT_OUT_COLUMN_SELECTION:
      return updateDepotOutColumnSelection(state, action);
    case ActionKeys.SET_DEPOT_OUT_SELECTION:
      return setDepotOutSelection(state, action);
    case ActionKeys.SET_DEPOT_OUT_CANDIDATES:
      return setDepotOutCandidates(state, action);
    case ActionKeys.RESET_DEPOT_OUT_CANDIDATES:
      return resetDepotOutCandidates(state, action);
    case ActionKeys.SET_DEPOT_OUT_OVERBOOKING_CANDIDATES:
      return setDepotOutOverbookingCandidates(state, action);
    case ActionKeys.RESET_DEPOT_OUT_PLANNINGBLOCKS:
      return resetDepotOutPlanningBlocks(state, action);
    case ActionKeys.SET_DEPOT_OUT_PROCESSED_DISPLAY_STATE:
      return setDepotOutProcessedDisplayState(state, action);
    case ActionKeys.SET_DEPOT_OUT_IMPORTANT_ONLY_DISPLAY_STATE:
      return setDepotOutImportantOnlyDisplayState(state, action);
    default:
      return state;
  }
}

function setDepotOutSelection(state: IAppState, action): IAppState {
  if(!action.depotOutSelection)
    action.depotOutSelection = [];
  if(!state.depotInSelection)
    state.depotInSelection = [];
  let toPlan = state.depotInSelection.concat(action.depotOutSelection).filter(pb => pb.status.statusType == 12);
  return Object.assign({}, state, {
    selectedPbsToPlan: toPlan,
    depotOutSelection: action.depotOutSelection,
    selectedPbsFromPlanBoard: [],
    selectionType: "planningBlock"
  });
}

function updateDepotOutColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionDepotOutColumnSelection: action.columns });
}

function updateDepotOutPlannedPlanningBlocks(state: IAppState, action): IAppState {
  const planningBlocks = state.depotOutPlanningBlocks;
  const returnedPlanningBlocks = action.planningBlocks;
  const depotOutSelection = state.depotOutSelection;

  returnedPlanningBlocks.map((returnedPlanningBlock: PlanningBlock) => {
    let index = planningBlocks.findIndex(d => d.id === returnedPlanningBlock.id);
    if(index != -1)
      planningBlocks[index] = returnedPlanningBlock;
    index = depotOutSelection.findIndex(d => d.id === returnedPlanningBlock.id);
    if(index != -1)
      depotOutSelection[index] = returnedPlanningBlock;
  });
  
  let filteredPlanningBlocks = filterPlanningBlocks(
    planningBlocks,
    state.depotOutQuickFilter,
    "",
    state.sessionDepotOutColumnSelection,
  );

  return Object.assign({}, state, {
    depotOutFilteredPlanningBlocks: [...filteredPlanningBlocks],
    depotOutPlanningBlocks: [...planningBlocks],
    depotOutSelection: [...depotOutSelection]
  });
}

function filterDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  if (action.searchText.trim().length >= 1) {
    const pbsToFilter = state.depotOutQuickFilteredPlanningBlocksNoText.length > 0 ?
      state.depotOutQuickFilteredPlanningBlocksNoText : state.depotOutFilteredPlanningBlocks;
    const filtered = getFilteredPlanningBlocksBySearchText(pbsToFilter,
      action.searchText.trim(),
      state.sessionDepotOutColumnSelection,
    );

    return Object.assign({}, state, {
      depotOutQuickFilteredPlanningBlocksNoText: pbsToFilter,
      depotOutFilteredPlanningBlocks: filtered,
      depotOutSearchText: action.searchText,
    });
  }
  const pbs = state.depotOutQuickFilteredPlanningBlocksNoText.length > 0 ?
    state.depotOutQuickFilteredPlanningBlocksNoText : state.depotOutFilteredPlanningBlocks;
  return Object.assign({}, state, {
    depotOutFilteredPlanningBlocks: pbs,
    depotOutSearchText: action.searchText,
  });
}

function storeDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    depotOutPlanningBlocks: action.planningBlocks,
    depotOutQuickFilteredPlanningBlocks: action.planningBlocks,
    depotOutFilteredPlanningBlocks: depotOutDefaultQuickFilter(state,action.planningBlocks),
  });
}



function filterProcessedPlanningBlocks(planningBlocks: PlanningBlock[]): PlanningBlock[] {
  //return planningBlocks.filter((planningBlock: PlanningBlock) => !planningBlock.processed);
  return planningBlocks;
}

function depotOutDefaultQuickFilter(state : IAppState , planningBlocks: PlanningBlock[]) : PlanningBlock[] 
{
  return filterPlanningBlocks(planningBlocks,state.depotOutQuickFilter,"",state.sessionDepotOutColumnSelection) ; 
}

function quickFilterDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  const filter = action.quickFilter;
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotOutFilteredOnCandidates) {
    planningBlocks = state.depotOutCandidates;
  } else {
    planningBlocks = state.depotOutPlanningBlocks;
  }

  if (
    !action.quickFilter.customer &&
    !action.quickFilter.firstAction &&
    !action.quickFilter.lastAction &&
    !action.quickFilter.loadingPlace &&
    !action.quickFilter.unLoadingPlace &&
    !action.quickFilter.closing &&
    !action.quickFilter.pbFirstCollectionFrom &&
    !action.quickFilter.containerType &&
    !action.quickFilter.latestLocationCode &&
    !action.quickFilter.carrier &&
    !action.quickFilter.planningState.toBePlanned &&
    !action.quickFilter.planningState.inProgress &&
    !action.quickFilter.planningState.planned &&
    !action.quickFilter.planningState.prePlanned &&
    !action.quickFilter.planningState.onHold && 
    !action.quickFilter.planningState.reused && 
    !action.quickFilter.containerStatus.leegCrea &&
    !action.quickFilter.containerStatus.leegAf &&
    !action.quickFilter.containerStatus.geladen &&
    !action.quickFilter.containerStatus.volKlaar &&
    !action.quickFilter.containerStatus.volIn &&
    !action.quickFilter.containerStatus.volCrea &&
    !action.quickFilter.containerStatus.volAf &&
    !action.quickFilter.containerStatus.gelost &&
    !action.quickFilter.containerStatus.leegKlaar &&
    !action.quickFilter.containerStatus.leegIn &&
    !action.quickFilter.iconsFilter &&
    action.quickFilter.planningGroups.length === 0
  ) {
    const depotOutSearchTextTrimmed = state.depotOutSearchText.trim();

    if (depotOutSearchTextTrimmed.length >= 1) {
      matches = getFilteredPlanningBlocksBySearchText(
        planningBlocks,
        depotOutSearchTextTrimmed,
        state.sessionDepotOutColumnSelection,
      );
      return Object.assign({}, state, {
        depotOutFilteredPlanningBlocks: matches,
        depotOutQuickFilteredPlanningBlocks: matches,
        depotOutQuickFilteredPlanningBlocksNoText: planningBlocks,
        sessionDepotOutAdvancedFilters: null,
        depotOutQuickFilter: null,
      });
    }

    return Object.assign({}, state, {
      depotOutFilteredPlanningBlocks: planningBlocks,
      depotOutQuickFilteredPlanningBlocks: planningBlocks,
      depotOutQuickFilteredPlanningBlocksNoText: planningBlocks,
      sessionDepotOutAdvancedFilters: null
    });
  }

  matches = filterPlanningBlocks(
    planningBlocks,
    action.quickFilter,
    "",
    state.sessionDepotOutColumnSelection,
  );

  const depotOutSearchTextTrimmed = state.depotOutSearchText.trim();

  if (depotOutSearchTextTrimmed.length >= 1) {
    const noTextMatches: PlanningBlock[] = matches;
    matches = getFilteredPlanningBlocksBySearchText(
      matches,
      depotOutSearchTextTrimmed,
      state.sessionDepotOutColumnSelection,
    );
    return Object.assign({}, state, {
      depotOutQuickFilteredPlanningBlocksNoText: noTextMatches,
      depotOutFilteredPlanningBlocks: matches,
      depotOutQuickFilteredPlanningBlocks: matches,
      sessionDepotOutAdvancedFilters: null,
      depotOutQuickFilter: { ...action.quickFilter },
    });
  }

  return Object.assign({}, state, {
    depotOutQuickFilteredPlanningBlocksNoText: matches,
    depotOutFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocks: matches,
    sessionDepotOutAdvancedFilters: null,
    depotOutQuickFilter: { ...action.quickFilter },
  });
}

function resetQuickFilterDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    depotOutQuickFilter: null,
    sessionDepotOutAdvancedFilters: null
  });
}

function resetAdvancedFilterDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotOutFilteredOnCandidates) {
    planningBlocks = state.depotOutCandidates;
  } else {
    planningBlocks = filterProcessedPlanningBlocks(state.depotOutPlanningBlocks);
  }

  if (state.depotOutSearchText.length > 0) {
    matches = getFilteredPlanningBlocksBySearchText(planningBlocks, state.depotOutSearchText, state.sessionDepotOutColumnSelection)
  } else {
    matches = planningBlocks;
  }
  return Object.assign({}, state, {
    sessionDepotOutAdvancedFilters: null,
    depotOutFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocksNoText: planningBlocks,
    depotOutQuickFilter: null
  });
}

function advancedFilterDepotOutPlanningBlocks(state: IAppState, action) {
  let matches: PlanningBlock[] = [];
  let noText: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];
  const depotOutSearchTextTrimmed = state.depotOutSearchText.trim();

  if (state.depotOutFilteredOnCandidates) {
    planningBlocks = state.depotOutCandidates;
  } else {
    planningBlocks = state.depotOutPlanningBlocks;
  }

  matches = advancedFilterPlanningBlocks(planningBlocks, action.filterItems);

  if (depotOutSearchTextTrimmed.length > 0) {
    noText = matches;
    matches = getFilteredPlanningBlocksBySearchText(
      matches,
      depotOutSearchTextTrimmed,
      state.sessionDepotOutColumnSelection
    );
  } else {
    noText = matches;
  }

  return Object.assign({}, state, {
    depotOutQuickFilteredPlanningBlocksNoText: noText,
    sessionDepotOutAdvancedFilters: action.filterItems,
    depotOutFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocks: matches,
    depotOutQuickFilter: null,
  });
}

function setDepotOutCandidates(state: IAppState, action): IAppState {
  const matches: PlanningBlock[] = [];
  const depotInAdres = state.depotInSelection[0].lastPlanAddressSearchName;
  for (let planningBlock of state.depotOutPlanningBlocks) {
    if (
      planningBlock.firstPlanAddressSearchName === depotInAdres &&
      planningBlock.status.statusType === TO_BE_PLANNED
    ) {
      matches.push(planningBlock);
    }
  }
  return Object.assign({}, state, {
    sessionDepotOutAdvancedFilters: null,
    depotOutQuickFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocksNoText: matches,
    depotOutCandidates: matches,
    depotOutFilteredPlanningBlocks: matches,
    depotOutFilteredOnCandidates: true,
    depotOutQuickFilter: null
  });
}

function resetDepotOutCandidates(state: IAppState, action): IAppState {
  const planningBlocks = filterProcessedPlanningBlocks(state.depotOutPlanningBlocks);

  return Object.assign({}, state, {
    sessionDepotOutAdvancedFilters: null,
    depotOutQuickFilteredPlanningBlocks: planningBlocks,
    depotOutQuickFilteredPlanningBlocksNoText: planningBlocks,
    depotOutFilteredPlanningBlocks: planningBlocks,
    depotOutFilteredOnCandidates: false,
    depotOutQuickFilter: null,
    depotOutSearchText: ""
  });
}

function setDepotOutOverbookingCandidates(state: IAppState, action): IAppState {
  const depotInPlanningBlock = state.depotInSelection[0];
  const matches: PlanningBlock[] = getDepotOutCandidates(depotInPlanningBlock, state.depotOutPlanningBlocks, state.containerStatuses);

  return Object.assign({}, state, {
    depotOutFilteredOnCandidates: true,
    depotOutFilteredPlanningBlocks: matches,
    depotOutQuickFilter: null,
    depotOutQuickFilteredPlanningBlocks: matches,
    depotOutQuickFilteredPlanningBlocksNoText: matches,
    depotOutCandidates: matches,
    sessionDepotOutAdvancedFilters: null,
  });
}

export function getDepotOutCandidates(depotInPlanningBlock: PlanningBlock, depotOutPlanningBlocks: PlanningBlock[], containerStatuses: IdNameAsStringObject[]): PlanningBlock[]{
  const matches: PlanningBlock[] = [];
  // tslint:disable-next-line:prefer-const
  for (let planningBlock of depotOutPlanningBlocks) {
    let checkContainerTrailer = 
    (depotInPlanningBlock.containerOrTrailerType && depotInPlanningBlock.containerOrTrailerType === planningBlock.containerOrTrailerType)
    || (depotInPlanningBlock.containerType && depotInPlanningBlock.containerType === planningBlock.containerType)
    || (depotInPlanningBlock.trailerType && depotInPlanningBlock.trailerType === planningBlock.trailerType);
    if (
      checkContainerTrailer &&
      depotInPlanningBlock.orderNumber !== planningBlock.orderNumber &&
      depotInPlanningBlock.carrierId === planningBlock.carrierId &&
      isContainerStatusValidForDepotOutAndDepotInContainerReuseSelection(
        depotInPlanningBlock,
        planningBlock,
        containerStatuses
      )
      && planningBlock.status.statusType === TO_BE_PLANNED
    ) {
      matches.push(planningBlock);
    }
  }
  return matches;
}

function isContainerStatusValidForDepotOutAndDepotInContainerReuseSelection(
  depotInPB: PlanningBlock,
  depotOutPB: PlanningBlock,
  containerStatuses: IdNameAsStringObject[]
): boolean {
  let depotOutPBStatusId = containerStatuses.find(el => el.name == depotOutPB.containerStatus)?.id;
  let depotInPBStatusId = containerStatuses.find(el => el.name == depotInPB.containerStatus)?.id;
  return (
    ((depotOutPBStatusId === ContainerStatusId.volCrea ||
      depotOutPBStatusId === ContainerStatusId.volAf ||
      depotOutPBStatusId === ContainerStatusId.leegKlaar) &&
      depotInPBStatusId === ContainerStatusId.leegCrea) ||
    ((depotInPBStatusId === ContainerStatusId.volCrea ||
      depotInPBStatusId === ContainerStatusId.volAf ||
      depotInPBStatusId === ContainerStatusId.leegKlaar ||
      depotInPBStatusId === ContainerStatusId.gelost) &&
      depotOutPBStatusId === ContainerStatusId.leegCrea)
  );
}

function resetDepotOutPlanningBlocks(state: IAppState, action): IAppState {
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotOutFilteredOnCandidates) {
    planningBlocks = state.depotOutCandidates;
  } else {
    planningBlocks = state.depotOutPlanningBlocks;
  }

  if (state.depotOutQuickFilteredPlanningBlocksNoText && state.depotOutQuickFilteredPlanningBlocksNoText.length > 0) {
    return Object.assign({}, state, {
      depotOutSearchText: "",
      depotOutFilteredPlanningBlocks: state.depotOutQuickFilteredPlanningBlocksNoText,
      depotOutQuickFilteredPlanningBlocks: state.depotOutQuickFilteredPlanningBlocksNoText,
    });
  }
  return Object.assign({}, state, {
    depotOutSearchText: "",
    depotOutFilteredPlanningBlocks: planningBlocks,
    depotOutQuickFilteredPlanningBlocks: planningBlocks,
  });
}
function setDepotOutProcessedDisplayState(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    depotOutProcessedDisplayState: action.depotOutProcessedDisplayState
  });
}
function setDepotOutImportantOnlyDisplayState(state: IAppState, action): IAppState{
  return Object.assign({}, state, {
    depotOutImportantOnlyDisplayState: action.depotOutImportantOnlyDisplayState
  });
}
