import { IfObservable } from "rxjs/observable/IfObservable";
import { PreAnnouncedContainerMoves } from "../announcement/components/pre-announced-container-moves/pre-announced-container-moves/pre-announced-container-moves.component";
import { FilterAnnouncedContainers, FilterPoolContainers, FilterPreAnnouncedContainerMoves } from "../announcement/filters/announcement.quickfilter";
import { getFilteredAnnouncedContainers, getFilteredContainersBySearchText, getFilteredPreAnnouncedMovesBySearchText } from "../announcement/filters/announcement.text.filer";
import { AnnouncementQuickFilter } from "../depot-out/models/depot-out.interface";
import { ActionKeys } from "../pb-screen/action.keys";
import { PoolContainer } from "../pool/models/pool-containers.interface";
import { PoolMove } from "../pool/models/pool-move.interface";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';

export function announcementReducer(state: IAppState = InitialState.initialState, action) {
    switch (action.type) {
        case ActionKeys.SET_ALL_POOL_CONTAINERS:
            return setAllPoolContainers(state, action);
        case ActionKeys.FILTER_CONTAINER_POOL_ANNOUNCEMENT:
            return textFilterPoolContainers(state, action);
        case ActionKeys.RESET_POOL_CONTAINERS: 
            return resetPoolContainers(state,action);
        case ActionKeys.QUICK_FILTER_CONTAINER_POOL_ANNOUNCEMENT:
            return quickFilterPoolContainers(state,action); 
        case ActionKeys.SET_POOL_CONTAINER_FOR_ANNOUNCEMENT: 
            return setPoolContainersForAnnouncement(state , action);
        case ActionKeys.SET_ANNOUNCED_POOL_CONTAINERS: 
            return setAnnouncedPoolContainers(state,action);
        case ActionKeys.SET_SELECTED_ANNOUNCED_CONTAINER:
            return setSelectedAnnouncedContainers(state,action);
        case ActionKeys.FILTER_ANNOUNCED:
            return textFilterAnnouncedContainers(state,action);
        case ActionKeys.RESET_ANNOUNCED:
            return resetAnnouncedContainers(state,action)
        case ActionKeys.MONITOR_ANNOUNCE_CONTAINER: 
            return updateAnnouncementDisplay(state,action);
        case ActionKeys.SET_ANNOUNCED_CONTAINER_DAMAGE: 
            return setContainerDamageList(state,action)
        case ActionKeys.QUICK_FILTER_ANNOUNCED_CONTAINERS : 
            return quickfilterAnnouncedContainers(state,action);
        case ActionKeys.SET_PREANNOUNCED_CONTAINER_Moves: 
            return setPreAnnouncedContainerMoves(state,action);
        case ActionKeys.QUICK_FILTER_PREANNOUNCED_CONTAINER_MOVES: 
            return quickfilterFilterPreAnnouncedContainerMoves(state,action);
        case ActionKeys.RESET_PREANNOUNCED_CONTAINER_MOVES : 
            return resetPreAnnouncedContainerMoves(state,action);
        case ActionKeys.SET_SELECTED_PREANNOUNCED_POOL_MOVE: 
            return setSelectedPreAnnouncedPoolMove(state,action);
        case ActionKeys.RESET_ANNOUNCEMENT_FILTER: 
            return resetAnnouncementFilter(state,action);
        default:
            return state;
    }
}

function setAllPoolContainers(state, action) {
    return Object.assign({}, state, {
        allPoolContainers: action.allPoolContainers,
        filteredContainers : action.allPoolContainers
    });
}


function textFilterPoolContainers(state: IAppState, action): IAppState {
    if (action.searchText.trim().length >= 1) {
        const containersToFilter = state.allPoolContainers
        const filtered = getFilteredContainersBySearchText(containersToFilter,
            action.searchText.trim()
        );

        return Object.assign({}, state, {
            allPoolContainers : containersToFilter,
            filteredContainers: filtered,
            announcementSearchText: action.searchText,
        });
    }


    const containers = state.allPoolContainers;
    return Object.assign({}, state, {
        allPoolContainers : containers,
        filteredContainers: containers,
        announcementSearchText: action.searchText,
    });

}

function quickfilterAnnouncedContainers(state: IAppState , action): IAppState {
    let matches: PoolContainer[] = []; 
    let containers: PoolContainer[] = [] ; 

    if(state.announcedPoolContainers) {
        containers = state.announcedPoolContainers ;
    }

    if(!action.announcementQuickFilter) {
        const announcedSearchText = state.announcedSearchText.trim(); 
        if(announcedSearchText.length >= 1) {
            matches = getFilteredAnnouncedContainers(containers, announcedSearchText); 
            return Object.assign({} , state , {
                announcedPoolContainers : containers , 
                filteredAnnouncedPoolContainers : [...matches], 
                announcedSearchText : announcedSearchText, 
                announcementQuickFilter : null
            })
        }
    }

    matches = FilterAnnouncedContainers(containers,"",
    action.announcementQuickFilter);
    const announcedSearchText = state.announcedSearchText.trim(); 

    if (announcedSearchText.length >= 1) {
        matches =
        getFilteredAnnouncedContainers(matches,
            announcedSearchText,);
        return Object.assign({},
          state,
          {
            filteredAnnouncedPoolContainers: [...matches],
            announcedSearchText: announcedSearchText,
            announcementQuickFilter: { ...action.announcementQuickFilter }
          });
      }

      return Object.assign({},
        state,
        {
          filteredAnnouncedPoolContainers: [...matches],
          announcedSearchText: "",
          announcementQuickFilter: { ...action.announcementQuickFilter }
        });
}

function quickFilterPoolContainers(state: IAppState , action): IAppState {
    let matches:PoolContainer[] = [];
    let containers: PoolContainer[] = [];

    if(state.allPoolContainers) {
        containers = state.allPoolContainers;
    }

    if(!action.announcementQuickFilter) {
        const announcementSearchText = state.announcementSearchText.trim();
        if (announcementSearchText.length >= 1) {
            matches =
              getFilteredContainersBySearchText(containers,
                announcementSearchText,);
            return Object.assign({},
              state,
              {
                allPoolContainers: containers,
                filteredContainers: [...matches],
                announcementSearchText: announcementSearchText,
                announcementQuickFilter: null
              });
          }

          return Object.assign({},
            state,
            {
              allPoolContainers: containers,
              filteredContainers: containers,
              announcementSearchText: "",
              announcementQuickFilter: null
            });


    }


    matches = FilterPoolContainers(containers,"",
        action.announcementQuickFilter);
    const announcementSearchText = state.announcementSearchText.trim();

    if (announcementSearchText.length >= 1) {
        matches =
          getFilteredContainersBySearchText(matches,
            announcementSearchText,);
        return Object.assign({},
          state,
          {
            filteredContainers: [...matches],
            announcementSearchText: announcementSearchText,
            announcementQuickFilter: { ...action.announcementQuickFilter }
          });
      }

      return Object.assign({},
        state,
        {
           filteredContainers: [...matches],
          announcementSearchText: "",
          announcementQuickFilter: { ...action.announcementQuickFilter }
        });

        
}


function quickfilterFilterPreAnnouncedContainerMoves(state: IAppState , action) : IAppState {

    let matches:PoolMove[] = [];
    let poolMoves: PoolMove[] = [];

    if(state.allPoolContainers) {
        poolMoves = state.preAnnouncedContainerMoves;
    }

    if(!action.announcementQuickFilter) {
        const announcementSearchText = state.announcementSearchText.trim();
        if (announcementSearchText.length >= 1) {
            matches =
            getFilteredPreAnnouncedMovesBySearchText(poolMoves,
                announcementSearchText,);
            return Object.assign({},
              state,
              {
                preAnnouncedContainerMoves: poolMoves,
                filteredPreAnnouncedContainerMoves: [...matches],
                announcementSearchText: announcementSearchText,
                announcementQuickFilter: null
              });
          }

          return Object.assign({},
            state,
            {
                preAnnouncedContainerMoves: poolMoves,
                filteredPreAnnouncedContainerMoves: [...matches],
                announcementSearchText: "",
                announcementQuickFilter: null
            });
    }

    matches = FilterPreAnnouncedContainerMoves(poolMoves,
    action.announcementQuickFilter);

    const announcementSearchText = state.announcementSearchText.trim();

    if (announcementSearchText.length >= 1) {
        matches =
          getFilteredPreAnnouncedMovesBySearchText(matches,
            announcementSearchText,);
        return Object.assign({},
          state,
          {
            filteredPreAnnouncedContainerMoves: [...matches],
            announcementSearchText: announcementSearchText,
            announcementQuickFilter: { ...action.announcementQuickFilter }
          });
      }

    return Object.assign({},
        state,
        {
            filteredPreAnnouncedContainerMoves: [...matches],
            announcementSearchText: "",
            announcementQuickFilter: { ...action.announcementQuickFilter }
        });
}

function resetPoolContainers(state: IAppState, action): IAppState {
    let containers: PoolContainer[] = [];
  
     containers = state.allPoolContainers; 
  
    return Object.assign({}, state, {
        allPoolContainers: containers,
        filteredContainers: containers,
        announcementSearchText: "",
    });
  }

function resetAnnouncedContainers(state: IAppState , action): IAppState {
    let containers: PoolContainer[] = [];
  
     containers = state.announcedPoolContainers; 
  
    return Object.assign({}, state, {
        announcedPoolContainers : containers,
        filteredAnnouncedPoolContainers: containers,
        announcedSearchText: "",
    });
}

function  setPoolContainersForAnnouncement(state: IAppState,action) : IAppState {
    return Object.assign({},state, {
        poolContainersForAnnouncement : action.poolContainersForAnnouncement
    });
}

function setSelectedAnnouncedContainers(state : IAppState , action) : IAppState {
    return Object.assign({},state, {
        selectedAnnouncedContainers : action.selectedAnnouncedContainers
    });
}

function setAnnouncedPoolContainers(state: IAppState , action) : IAppState {
    return Object.assign({}, state , {
        filteredAnnouncedPoolContainers: action.announcedPoolContainers,
        announcedPoolContainers : action.announcedPoolContainers
    })
}



function textFilterAnnouncedContainers(state: IAppState, action): IAppState {
    if (action.searchText.trim().length >= 1) {
        const containersToFilter = state.filteredAnnouncedPoolContainers
        const filtered = getFilteredAnnouncedContainers(containersToFilter,
            action.searchText.trim()
        );

        return Object.assign({}, state, {
            filteredAnnouncedPoolContainers: filtered,
            announcedSearchText: action.searchText,
        });
    }


    const containers = state.filteredAnnouncedPoolContainers;
    return Object.assign({}, state, {
        announcedPoolContainers : containers,
        filteredAnnouncedPoolContainers: containers,
        announcedSearchText: action.searchText,
    });

}


function setContainerDamageList(state: IAppState , action) : IAppState {
    return Object.assign({}, state , {
        announcedContainerInspections: action.announcedContainerInspections
    })
}


function updateAnnouncementDisplay(state : IAppState , action) {
    let announcedContainer : PoolContainer[] = state.filteredAnnouncedPoolContainers; 
    let poolContainers : PoolContainer[] = state.filteredContainers; 
    let preAnnouncedContainerMoves : PoolMove[] = state.filteredPreAnnouncedContainerMoves;

  
    let newAnnouncedContainers : PoolContainer[] = [] ; 
    let removedAnnouncedContainers : PoolContainer[] = [];
    
    let newFiltredPoolContainer : PoolContainer[] = [] ; 
    let newFiltredAnnounced: PoolContainer[] = [];

    let newpreAnnouncedContainerMoves: PoolMove[] = [];
    
    if(poolContainers) {
        newFiltredPoolContainer = poolContainers ;  
    }
    if(announcedContainer) {
        newFiltredAnnounced = announcedContainer;
    }


    let selectionChanged = false; 
  
    if(action.updatedPoolContainer) {

     /* if(action.updatedPoolContainer.every((x) => {return x.status == "Announced"}))
      {
         action.updatedPoolContainer.forEach(element => {
            let index = newFiltredAnnounced.findIndex((x) => x.containerNo === element.containerNo); 
             newFiltredAnnounced[index] = element;
         });  
      }

      if(action.updatedPoolContainer.every((x) => {return x.status !== "Announced"})) {
          action.updatedPoolContainer.forEach(element => {
              let index = newFiltredPoolContainer.findIndex((x) => {return x.containerNo == element.containerNo}); 
              newFiltredPoolContainer[index] = element ;
          });
      }*/

      newAnnouncedContainers = action.updatedPoolContainer.filter((x) => { return x.status == "Announced" }); 
      removedAnnouncedContainers = action.updatedPoolContainer.filter((x) => { return x.status !== "Announced" });
      
      if(newAnnouncedContainers && newAnnouncedContainers.length > 0)
        {
          newAnnouncedContainers.forEach((item) => {
            let index = announcedContainer.findIndex((x) => x.containerNo === item.containerNo);
            if(index !== -1) {
               announcedContainer[index] = item;
               newFiltredAnnounced = announcedContainer;
               const indexInPool = poolContainers
               .findIndex((container: PoolContainer) => container.containerNo === item.containerNo);
                if(indexInPool > -1) {
                    poolContainers = poolContainers.splice(indexInPool, 1);
                    newFiltredPoolContainer = poolContainers;
                }
                
                
            } else {
              announcedContainer.push(item);
              newFiltredAnnounced = announcedContainer;
              const indexInPool = poolContainers.findIndex((container: PoolContainer) => container.containerNo === item.containerNo);
              if(indexInPool > -1) {
                 poolContainers.splice(indexInPool, 1);
                 newFiltredPoolContainer = poolContainers;
              }
                
            }
          })

          let announcedContainerNumbers = newAnnouncedContainers.map((x) => x.containerNo)
          announcedContainerNumbers.forEach((item) => {
              const preAnnouncedIndex = preAnnouncedContainerMoves.findIndex((x : PoolMove) => x.announced === item );
              if (preAnnouncedIndex > -1) {
                preAnnouncedContainerMoves.splice(preAnnouncedIndex, 1);
                newpreAnnouncedContainerMoves = preAnnouncedContainerMoves;
              } else {
                  newpreAnnouncedContainerMoves = preAnnouncedContainerMoves;
              }
          })
        }
  
        if(removedAnnouncedContainers && removedAnnouncedContainers.length > 0) {
          
            removedAnnouncedContainers.forEach((item) => {
              poolContainers.push(item);
              newFiltredPoolContainer = poolContainers;
              const index = announcedContainer.findIndex((container: PoolContainer) => container.containerNo === item.containerNo);
                    if(index > -1) {
                        announcedContainer.splice(index, 1);
                        newFiltredAnnounced = announcedContainer;
                    }
              
            })
            newpreAnnouncedContainerMoves = preAnnouncedContainerMoves;
        }

        if(state.announcementQuickFilter || state.announcementSearchText != "") {
            newFiltredPoolContainer = FilterPoolContainers(poolContainers,state.announcedSearchText,state.announcementQuickFilter) ;
            newpreAnnouncedContainerMoves = FilterPreAnnouncedContainerMoves(newpreAnnouncedContainerMoves,state.announcementQuickFilter)
        }

        if(state.announcedSearchText != "") {
            newFiltredAnnounced = getFilteredAnnouncedContainers(announcedContainer,state.announcedSearchText); 
            
        }


        return Object.assign({}, state, {
            filteredContainers : [...newFiltredPoolContainer],
            filteredAnnouncedPoolContainers: [...newFiltredAnnounced],
            filteredPreAnnouncedContainerMoves : [...newpreAnnouncedContainerMoves]
        });
    }
  }


  function setPreAnnouncedContainerMoves(state, action) 
  {
    return Object.assign({}, state, {
        preAnnouncedContainerMoves : action.preAnnouncedContainerMoves,
        filteredPreAnnouncedContainerMoves : action.preAnnouncedContainerMoves
    });
}

function resetPreAnnouncedContainerMoves(state: IAppState, action): IAppState {
    
    let moves: PoolMove[] = [];
  
     moves = state.preAnnouncedContainerMoves; 
  
    return Object.assign({}, state, {
        preAnnouncedContainerMoves: moves,
        filteredPreAnnouncedContainerMoves: moves,
        announcementSearchText: "",
    });
  }

  function setSelectedPreAnnouncedPoolMove(state: IAppState , action) : IAppState {
      return Object.assign({},state ,  
        {
            selectedPreAnnouncedPoolMove : action.selectedPreAnnouncedPoolMove
        });
  }

  function resetAnnouncementFilter(state : IAppState , action) : IAppState {
      return Object.assign({} , state , {
          announcementQuickFilter : action.announcementQuickFilter
      })
  }
