import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { ActionKeys } from "../../../pb-screen/action.keys";
import { SignalrBroadcaster } from "../../../pb-screen/helpers/signalr.broadcaster";
import { ChannelEventFactory } from "../../../pb-screen/services/channel.event.factory";
import { ChannelService } from "../../../pb-screen/services/channel.service";
import { AdvancedFilterGroup, AdvancedFilterItem } from "../../../shared/components/filter-line/filter-line.interface";
import { IAppState } from "../../../store/IAppState";

@Injectable()
export class DepotInAdvancedFilterActions extends SignalrBroadcaster {
    constructor(ngRedux: NgRedux<IAppState>,
                channelEventFactory: ChannelEventFactory,
                channelService: ChannelService) {
        super(ngRedux, channelEventFactory, channelService);
    }

    public executeAdvancedFilter(filterItems: AdvancedFilterGroup): void {
       super.dispatchAction({
           type: ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS,
           filterItems,
      });
    }

    public resetAdvancedFilter(filterItems: AdvancedFilterGroup) {
        super.dispatchAction({
            type: ActionKeys.RESET_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS,
            filterItems,
        });

    }
}
