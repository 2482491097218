import { TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";
import { ToQuickFilter, OperationalStatus, FinalizedCombi, ToIconFilter } from "../../models/to-screen.interface";
import { Column } from "../../../pb-screen/models/application.user.interface";
import { getFilteredTransportOrdersBySearchText } from "../../../pb-screen/helpers/filter.by.text";
import { isOfSelectedOperationalStatus, isOfSelectedFinalizedCombi } from "./to.checkOpStatus";
import { hasConflicts } from "../../../pb-screen/models/planning-conflict-type.enum";

export function filterTransportOrders(tos: TransportOrder[],
    toQuickFilter: ToQuickFilter, textFilter: string, sessionToColumnSelection: Column[],
    selectedTOs: TransportOrder[]): TransportOrder[] {
    let transportOrders: TransportOrder[] = [...tos];
    const matches: TransportOrder[] = [];

    if (textFilter.length > 0) {
        transportOrders = getFilteredTransportOrdersBySearchText(transportOrders, textFilter, sessionToColumnSelection);
    }

    if(toQuickFilter.selectedOnly == true ) {
      transportOrders = selectedTOs;
      return selectedTOs;
    }

    for (let transportOrder of transportOrders) {
        let isOperationalStatusMatch: boolean = true;
        let isToDateMatch: boolean = true;
        let isHaulierNameMatch: boolean = true;
        let isFirstTruckDescMatch: boolean = true;
        let isDriverMatch: boolean = true;
        let isFirstCoDriverMatch: boolean = true;
        let isPlanningGroupMatch: boolean = true;
        let isPlanningZoneMatch: boolean = true;
        let isFinalizedCombiMatch: boolean = true;
        let isTruckDepartmentMatch: boolean = true;
        let isIconFilterMatch: boolean = true;
        let isToSentToHaulierMatch: boolean = true;

        isOperationalStatusMatch = isOfSelectedOperationalStatus(transportOrder, toQuickFilter.operationalStatus as OperationalStatus);
        if(!isOperationalStatusMatch)
          continue;

        isFinalizedCombiMatch = isOfSelectedFinalizedCombi(transportOrder, toQuickFilter.finalizedCombi as FinalizedCombi);
        if(!isFinalizedCombiMatch)
          continue;

        isToDateMatch = isDateMatch(toQuickFilter.toDate, transportOrder.date, toQuickFilter.specificToDate);

        if(!isToDateMatch)
          continue;

        isToSentToHaulierMatch = isDateMatch(toQuickFilter.toSentToHaulierDate, transportOrder.toSentToHaulierDate, toQuickFilter.specificToSentToHaulierDate);

        if(!isToSentToHaulierMatch)
          continue;

        if (toQuickFilter.haulierName &&
            transportOrder.haulierName &&
            toQuickFilter.haulierName.length > 0 &&
            transportOrder.haulierName.length > 0) {
            isHaulierNameMatch = transportOrder.haulierName.toLowerCase()
                .indexOf(toQuickFilter.haulierName.toLowerCase()) > -1;
        } else if (!transportOrder.haulierName &&
            toQuickFilter.haulierName &&
            toQuickFilter.haulierName.length) {
            isHaulierNameMatch = false;
        }

        if(!isHaulierNameMatch)
          continue;

        if (toQuickFilter.firstTruckDescription &&
            transportOrder.firstTruckDescription &&
            toQuickFilter.firstTruckDescription.length > 0) {
            isFirstTruckDescMatch = transportOrder.firstTruckDescription.toLowerCase()
                .indexOf(toQuickFilter.firstTruckDescription.toLowerCase()) > -1;
        } else if (!transportOrder.firstTruckDescription &&
            toQuickFilter.firstTruckDescription &&
            toQuickFilter.firstTruckDescription.length) {
            isFirstTruckDescMatch = false;
        }

        if(!isFirstTruckDescMatch)
          continue;

        if (toQuickFilter.driver &&
            transportOrder.driver &&
            transportOrder.driver.length > 0 &&
            toQuickFilter.driver.length > 0) {
            isDriverMatch = transportOrder.driver.toLowerCase()
                .indexOf(toQuickFilter.driver.toLowerCase()) > -1;
        } else if (!transportOrder.driver &&
            toQuickFilter.driver &&
            toQuickFilter.driver.length) {
            isDriverMatch = false;
        }

        if(!isDriverMatch)
          continue;

        if (toQuickFilter.firstCoDriver &&
            transportOrder.firstCoDriver &&
            transportOrder.firstCoDriver.length > 0 &&
            toQuickFilter.firstCoDriver.length > 0) {
            isFirstCoDriverMatch = transportOrder.firstCoDriver.toLowerCase()
                .indexOf(toQuickFilter.firstCoDriver.toLowerCase()) > -1;
        } else if (!transportOrder.firstCoDriver &&
            toQuickFilter.firstCoDriver &&
            toQuickFilter.firstCoDriver.length) {
            isFirstCoDriverMatch = false;
        }

        if(!isFirstCoDriverMatch)
          continue;

        if(toQuickFilter.planningGroups &&
            toQuickFilter.planningGroups.length > 0 &&
            transportOrder.planningGroup &&
            transportOrder.planningGroup.length > 0 ) {
                isPlanningGroupMatch = toQuickFilter.planningGroups.includes(transportOrder.planningGroup);
            }

        if(!isPlanningGroupMatch)
          continue;

        if(toQuickFilter.truckDepartments &&
            toQuickFilter.truckDepartments.length > 0 &&
            transportOrder.truckDepartmentCode &&
            transportOrder.truckDepartmentCode.length > 0 ) {
             isTruckDepartmentMatch = toQuickFilter.truckDepartments.includes(transportOrder.truckDepartmentCode);

            }
            else if (transportOrder.truckDepartmentCode.length === 0 && toQuickFilter.truckDepartments.length > 0) isTruckDepartmentMatch = false;

        if(!isTruckDepartmentMatch)
          continue;

        if(toQuickFilter.planningZone &&
            toQuickFilter.planningZone.id &&
            toQuickFilter.planningZone.id.length > 0 &&
            ((transportOrder.firstPlanningZone &&
            transportOrder.firstPlanningZone.length > 0) || (transportOrder.lastPlanningZone && transportOrder.lastPlanningZone.length) )) {
                isPlanningZoneMatch = toQuickFilter.planningZone.id === transportOrder.firstPlanningZone || toQuickFilter.planningZone.id === transportOrder.lastPlanningZone ;
            }

        if(!isPlanningZoneMatch)
          continue;

       isIconFilterMatch = checkIfIsIconFilterMatch(toQuickFilter.iconFilter, transportOrder);

       if(!isIconFilterMatch)
        continue;

      matches.push(transportOrder);
    }

    if (matches === []) {
        return transportOrders;
    }
    return matches;

}

export function isDateMatch(originalFilterDate: Date, toDate: Date, isSpecificDate: boolean) : boolean{
  let isMatch: boolean = true;
  if (originalFilterDate &&
      toDate &&
      originalFilterDate.toString().length > 1 &&
      toDate.toString().length > 1) {

      const dateToCompare = new Date(Date.parse(toDate.toString())).setHours(0, 0, 0, 0);
      const filterDate = new Date(Date.parse(originalFilterDate.toString())).setHours(0, 0, 0, 0);
      if (isSpecificDate) {
          isMatch = filterDate === dateToCompare;
      }
      else {
          isMatch = filterDate >= dateToCompare;
      }
  } else if (!toDate && originalFilterDate) {
      isMatch = false;
  }
  return isMatch;
}

export function checkIfIsIconFilterMatch(filter: ToIconFilter, to: TransportOrder): boolean {
  if(!filter)
    return true;

  if(filter.margin && filter.margin != getMarginColor(to, filter.marginDangerRangeFrom , filter.marginDangerRangeUntil))
    return false;

  if(filter.temperature && !to.hasPbsTemperatureControlled)
    return false;

  if(filter.conflicts && filter.conflicts != hasConflicts(to.planningConflicts))
    return false;

  if(filter.pbRemarks && !to.hasPBRemarks)
    return false;

  if(filter.ADR && !to.dangerousGoods)
    return false;

    if(filter.highValue && to.highValue == "0")
      return false;

  if(filter.notifications && filter.notifications !== to.hasNotifications)
    return false;

  if(filter.orderlineToSend && filter.orderlineToSend !== to.orderlineToSend)
    return false;

  if(filter.error && filter.error !== to.errorOnBc)
    return false;

  return true;
}

export function getMarginColor(to: TransportOrder, marginDangerRangeFrom, marginDangerRangeUntil) {
  var marginColor = '';
  if (to.marginPercentage === 0 && to.revenue === 0 && to.cost === 0) { marginColor = '' }
  else if (to.marginPercentage > marginDangerRangeUntil) { marginColor = '#10A310' }
  else if (to.marginPercentage < marginDangerRangeFrom) { marginColor = 'red' }
  else if (to.marginPercentage <= marginDangerRangeUntil && to.marginPercentage >= marginDangerRangeFrom) { marginColor = 'orange' }
  else { marginColor = '' }
  return marginColor;
}

