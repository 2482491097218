import { StackerQuickFilter } from "../../depot-out/models/depot-out.interface";

export function FilterStackerPlanningBlocks(stackerPlanningBlocks : any[] , stackerQuickFilter : StackerQuickFilter ): any[] {
    let matches : any[] = [] ; 
    
    for (const pb of stackerPlanningBlocks) {
        const isTruckMatch: boolean = isTruckSearchMatch(stackerQuickFilter, pb);
        const isFileNoMatch: boolean = isFileNoSearchMatch(stackerQuickFilter, pb);
        const isContainerNoMatch: boolean = isContainerNoSearchMatch(stackerQuickFilter, pb);
        const isOrderNoMatch: boolean = isOrderNoSearchMatch(stackerQuickFilter, pb);
        const isContainerStatusMatch : boolean = isStatusSearchMatch(stackerQuickFilter,pb); 
        const isContainerPlanningTypeMatch : boolean = isPlanningTypeMatch(stackerQuickFilter,pb);
        const isKaaiMatch : boolean = isKaaiSearchMatch(stackerQuickFilter,pb);
        if (isContainerStatusMatch && 
            isContainerPlanningTypeMatch && 
            isTruckMatch &&
            isFileNoMatch &&
            isContainerNoMatch && 
            isOrderNoMatch && 
            isKaaiMatch) {
            matches.push(pb);
        }
    }

    return matches;
}



function isContainerNoSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.containerNo) {
        return pb.containerNo.toLowerCase().includes(quickFilter.containerNo.toLowerCase())
        && pb.containerNo.length > 0;
    }
    return true;
}


function isFileNoSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.fileNumber) {
        return pb.fileId.toLowerCase().includes(quickFilter.fileNumber.toLowerCase())
        && pb.fileId.length > 0;
    }
    return true;
}

function isOrderNoSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.orderNo) {
        return pb.orderNumber.toLowerCase().includes(quickFilter.orderNo.toLowerCase())
        && pb.orderNumber.length > 0;
    }
    return true;
}

function isTruckSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.truck) {
        return pb.truckId.toLowerCase().includes(quickFilter.truck.toLowerCase())
        && pb.truckId.length > 0;
    }
    return true;
}

function isKaaiSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.selectedKaai) {
        return pb.quay.toLowerCase().includes(quickFilter.selectedKaai.toLowerCase())
        && pb.quay.length > 0;
    }
    return true;
}

function isStatusSearchMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.selectedStatuses && quickFilter.selectedStatuses.length > 0) {
        return quickFilter.selectedStatuses.includes(pb.containerStatus);
    }
    return true;
}

function isPlanningTypeMatch(quickFilter: StackerQuickFilter, pb) {
    if (quickFilter.selectedPlanningTypes && quickFilter.selectedPlanningTypes.length > 0) {
        return quickFilter.selectedPlanningTypes.includes(formatContainerPlanningType(pb.containerPlanningType));
    }
    return true;
}


function formatContainerPlanningType(type) : string{
    if(type == "1") return "AF"; 
    if(type == "2") return "IN"
}




