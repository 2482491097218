import { ComponentSource } from './../../../models/component-source';
import { select } from "@angular-redux/store";
import {
    Component, OnInit
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";
import { ModalType } from "../../../models/component-source";
import { SharedActions } from '../../../shared.actions';
import { SharedService } from '../../../services/shared.service';
import { ModalManagerService } from '../../../services/modal-manager.service';
import { PlanningGroups } from '../../../models/planning-groups-enum.interface';
import { PlanningBlock } from '../../../../pb-screen/models/planning-block/planning-block.interface';
import { HttpClientService } from '../../../services/httpclient';
import { QuickFilter } from '../../../../depot-in/models/depot-in.interface';

@Component({
    selector: "planning-groups-update",
    templateUrl: "planning-groups-update.component.html",
})

export class PlanningGroupModalComponent implements OnInit {

    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInQuickFilter") private depotInQuickFilter$: Observable<QuickFilter>;
    @select("depotOutQuickFilter") private depotOutQuickFilter$: Observable<QuickFilter>;


    public selectedPlanningBlocks: PlanningBlock[] = [];
    public depotInSelection: PlanningBlock[] = [];
    public depotOutSelection: PlanningBlock[] = [];
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public planningGroups: string[] = [];
    public selectedPlanningGroup: string = '';
    public selectedPlanningGroups: string[] = [];


    constructor(
        private readonly sharedService: SharedService,
        private readonly sharedActions: SharedActions,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        public readonly httpClientService: HttpClientService,
        private readonly modalManager: ModalManagerService,
    ) { }

    public ngOnInit(): void {
        this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotOutSelection = selectedPlanningBlocks;
        });

        this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotInSelection = selectedPlanningBlocks;
        });

        this.depotInQuickFilter$.subscribe((depotInQuickFilter: QuickFilter) => {
            if(depotInQuickFilter)
                this.selectedPlanningGroups = depotInQuickFilter.planningGroups;
        });

        this.depotOutQuickFilter$.subscribe((depotOutQuickFilter: QuickFilter) => {
            if(depotOutQuickFilter)
                this.selectedPlanningGroups = depotOutQuickFilter.planningGroups;
        });

        this.httpClientService.getToken().subscribe((token) => {
            this.sharedService.getCPPlanningGroups().subscribe((planningGroups) => {
                this.planningGroups = planningGroups.map((data : any) => data.code);
            });
          })
    };

    public apply(): void {
        const pbIds = [...this.depotOutSelection, ...this.depotInSelection].map(pb => pb.id);
        this.sharedService.updatePlanningGroup(pbIds, this.selectedPlanningGroup)
            .subscribe((pbs: PlanningBlock[]) => {
                /*pbs.forEach((pb: PlanningBlock) => {
                    if (this.selectedPlanningGroups.includes(pb.planningGroup)) {
                        this.sharedActions.updatePlanningBlocksDisplay([pb]);
                    } else {
                        this.sharedActions.removePlanningBlocksDisplay([pb]);
                    }
                });*/
                this.sharedActions.updatePlanningBlocksDisplayCpl([...pbs]);
                this.translate.get("TOASTR_MESSAGES.SUCCESS.STATUS_UPDATE_SUCCEEDED")
                    .subscribe((res) => {
                        this.toastr.success(res, "", {
                            closeButton: false
                        });
                    });
                this.closeModal();
            });
    };

    public closeModal(): void {
        this.selectedPlanningGroup = "";
        //this.sharedActions.resetModalDomains();
        this.sharedActions.setUiState(true)
        this.modalManager.closeModal(this.modalType.updatePlanningGroups);
    };

}