import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name : 'temperature' })
export class TemperaturePipe implements PipeTransform {

    constructor() {
    }

    transform(value: string, setTemperature : string) { 
        if(value) 
        {
        var temperature = value.replace(",", ".");
        temperature = setTemperature === '1' ? Number(temperature).toFixed(1).replace(".", ",") + " °C" : "";
        return temperature; 
        } 
        else 
           return "" ; 
    }
}