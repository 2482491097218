import { SelectedQuickFilters } from './containers/filters/pb-quick-filter/pb-quick-filter.interface';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../store/IAppState';
import { ApplicationUser, UserSettings, Selectedscreen, GridIconSetting } from './models/application.user.interface';
import { ActionKeys } from './action.keys';
import { ApplicationUserService } from './services/application.user.service';
import { ToastrService } from 'ngx-toastr';
import { settings } from 'cluster';
import { HttpClientService } from '../shared/services/httpclient';
import { UserSettingsService } from './services/usersettings.service';
import { Observable ,  of } from 'rxjs';
import { SharedActions } from '../shared/shared.actions';
import { environment } from '../../environments/environment';
import { GeneralSetting, PlanningSetting, WeekPlanningSetting } from '../setting-modal/setting-modal.interface';
import { UserSetup } from './userSetup/userSetup-modal.interface';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import { PlanningDatesService } from '../planningBoard/services/planningDates.service';
import { Jsonp } from '@angular/http';
import { CargoDockColumnDefiniton, ConflictsDockColumnDefiniton, DocumentsDockColumnDefiniton, EquipmentDockColumnDefiniton, OrderLineDockColumnDefiniton } from './static/columns/OrderLineColumnDefinition';
import { QuickView } from './models/quick-view/quick-view.interface';

@Injectable()
export class ApplicationUserActions {
  assignDefaultGridIcon = [
    {"id":1,"isVisible":true,"name":"Scanning"},
    {"id":2,"isVisible":true,"name":"Customs"},
    {"id":3,"isVisible":true,"name":"Weighing"},
    {"id":4,"isVisible":true,"name":"ADR"},
    {"id":5,"isVisible":true,"name":"T1"},
    {"id":6,"isVisible":false,"name":"Temperature"},
    {"id":7,"isVisible":true,"name":"High Value"},
    {"id":8,"isVisible":true,"name":"Conflicts"}
  ];
  constructor(
    private readonly ngRedux: NgRedux<IAppState>,
    private readonly applicationUserService: ApplicationUserService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly httpClientService: HttpClientService,
    private readonly userSettingService: UserSettingsService,
    private readonly sharedActions: SharedActions,
    private readonly planningDatesService: PlanningDatesService
  ) {}

  /* getApplicationUser(username: string, fullName: string) {
         this.applicationUserService.getApplicationUser().subscribe(
             (data) => {
                console.log(`data found: \n${data.id}`);
                 this.httpClientService.getToken().subscribe((token) => {
                     let userId = localStorage.getItem('userId') ;
                     this.applicationUserService.getUserSettings(userId).subscribe((settings) =>{
                         console.log(settings)
                         this.ngRedux.dispatch({
                             type: ActionKeys.SET_SESSION_USERSETTINGS_DATA,
                             sessionColumnSelection: JSON.parse(settings.value).selectedColumns,
                             sessionToColumnSelection: JSON.parse(settings.value).selectedToColumns,
                             sessionOrderLineColumnSelection: JSON.parse(settings.value).OrderLineColumnSelection

                         });
                     })

                 })

                 this.ngRedux.dispatch({
                     type: ActionKeys.GET_APPLICATION_USER_SUCCESS,
                     applicationUser: data
                 });
             },
             (error: any) => {
                 console.log(`error: \n${error}`);
                 const data = this.applicationUserService.getDefaultApplicationUser(username, fullName);
                 if (error.status === 404) {
                     console.log('404: get default application user');
                     this.ngRedux.dispatch({
                         type: ActionKeys.GET_APPLICATION_USER_NOTFOUND,
                         applicationUser: data
                     });
                 } else {
                     console.log('error: get default application user');
                     this.ngRedux.dispatch({
                         type: ActionKeys.GET_APPLICATION_USER_FAILED,
                         applicationUser: data
                     });
                 }
                 this.ngRedux.dispatch({
                     type: ActionKeys.SET_SESSION_USERSETTINGS_DATA,
                     userSettings: data.settings
                 });
             });
     }*/

  getDefaultPreferenceValues(setting: string) {
    switch (setting) {
      case "depotQuickViews":
        return JSON.stringify([]);
      case "defaultPbUserSettings":
        return JSON.stringify({
          selectedColumns: [],
          savedAdvancedFilters: [],
          quickViews: [],
          selectedQuickFilters: {}
        });
      case "defaultToUserSettings":
        return JSON.stringify({
          selectedToColumns: [],
          selectedToQuickFilters: {
            isOperationalStatusActive: false,
            isToDateActive: false,
            isDriverActive: false,
            isHaulierNameActive: false,
            isFirstTruckDescActive: false,
            isFirstCoDriverActive: false,
            isETADateActive: false
          }
        });
      case "defaultOrderLineUserSettings":
        return JSON.stringify({
          OrderLineColumnSelection: []
        });
      case "defaultGridIcon":
        return JSON.stringify(
          this.assignDefaultGridIcon
        );
      case "defaultPlanZone":
        return "ALL";
      case "defaultDateFormat":
        return "dd/MM/yyyy";

      case "defaultPlanGroups":
        return JSON.stringify([]);
      case "defaultTruckDepartments":
         return JSON.stringify([]); 
      case "defaultPlanSorting": 
         return "truck";

      case "defaultAgendaDateRange" : return "01/01/2020-31/01/2020";
      case "defaultTruckAgendaDateRange" : return "01/01/2020-31/01/2020";
      case "defaultAgendaPlanningGroup" : return '{"id":"ALL","name":"Alle"}';
      case "defaultAgendaPlanningZone" : return '{"id":"W-EUR","name":"West-Europa"}';
      case "defaultTruckAgendaPlanningGroup" : return '{"id":"ALL","name":"Alle"}';
      case "defaultTruckAgendaPlanningZone"  : return '{"id":"W-EUR","name":"West-Europa"}'; 
      
      
      case "depotOutSelectedColumns": return JSON.stringify([]); 
      case "depotInSelectedColumns": return JSON.stringify([]);
      case "stackerSelectedColumns": return JSON.stringify([]);
      case "depotOutSavedAdvancedFilters": return JSON.stringify([]);
      case "depotInSavedAdvancedFilters": return JSON.stringify([]);
      case "quickViews": return JSON.stringify([]);
      case "planningBlocksPriority": return JSON.stringify([]);
      case "planningGroups": return JSON.stringify([]);
      case "siteNumber" : return "" ;
      case "depotInPreferences" :  return JSON.stringify({
        selectedQuickFilters : {}, 
        quickFilter : {},
        fontSize: "",
      });
      case "depotOutPreferences" :return JSON.stringify({
        selectedQuickFilters : {}, 
        quickFilter : {},
        fontSize: "",
      });
      case "depotOutGridIcons" : return JSON.stringify([]);
      case "depotInGridIcons": return JSON.stringify([]);
      default:
        return null;
    }
  }


  getDefaultPreference(settingName, userId): Observable<UserSettings> {
    let defaultValue = this.getDefaultPreferenceValues(settingName);

        this.userSettingService.getUserSettingByName(settingName, userId).subscribe((userSettings: UserSettings[]) => {

            if (userSettings.length === 0) {

                this.userSettingService.getSettingByName(settingName).subscribe((setting) => {

                    this.userSettingService.createUserSettings(userId, setting, defaultValue, 'module', 'planning').subscribe((userSetting) => {


                        return of(userSetting);

                    })
                })
            } else {

                return of(userSettings[0]);
            }
        })

    return of();
  }

  getAppUserAndDefaultPreferences(settingNames: any[], userId): Observable<ApplicationUser> {

        return this.userSettingService.getUserSettings(userId)
          .pipe(map(userSettings => {
            
            settingNames.forEach(settingName => {
              let setting = userSettings.find((item: UserSettings) => {return item.setting.name === settingName;});
              if (!setting) {
                  this.userSettingService.getSettingByName(settingName).subscribe((setting) => {
                      let defaultValue = this.getDefaultPreferenceValues(settingName);
                      this.userSettingService.createUserSettings(userId, setting, defaultValue, 'module', 'planning').subscribe((userSetting) => {
                          return of(userSetting);
                      })
                  })
              } else {
                  return of(setting);
              }
            });

            return this.setApplicationUserSettings(userSettings, userId);
          }));
  }

  setApplicationUser(userId): Observable<ApplicationUser> {
    return this.userSettingService.getUserSettings(userId).pipe(map(userSettings => {
          return this.setApplicationUserSettings(userSettings, userId);
        }))
    }

  setApplicationUserSettings(userSettings: UserSettings[], userId): ApplicationUser {
    
    let pbPreferences = null;
      let shipModalColumnsOrder = null;
      let railModalColumnsOrder = null;
      let toPreferences = null;
      let orderlinePreferences = null;
      let screens: Selectedscreen = null;
      let defaultPlanningZone = "ALL";
      let defaultDateFormat = "dd/MM/yyyy";
      let defaultGridIcon = null;
      let toIcons = [];
      let defaultPlanZone = "ALL";
      let defaultTruckDepartments = null;
      let defaultPlanGroups = null;
      let pbFixedPreferences = null;
      let defaultAgendaDateRange = null;
      let defaultTruckAgendaDateRange = null;
      let defaultAgendaPlanningGroup = null;
      let defaultAgendaPlanningZone = null;
      let defaultTruckAgendaPlanningGroup = null;
      let defaultTruckAgendaPlanningZone = null;
      let userSetup = new UserSetup();
      let pixelsPerPlanningBlock = 0;
      let depotQuickViews: QuickView[] = [];
      let weekPlanningSetting = new WeekPlanningSetting();
      let planningSetting = new PlanningSetting();
      let generalSetting = new GeneralSetting();
      let orderlinesDockSelectedColumns = [];
      let conflictsDockSelectedColumns = [];
      let cargoDockSelectedColumns = [];
      let equipmentDockSelectedColumns = [];
      let documentsDockSelectedColumns = [];
      let defaultPlanSorting = "truck";

      let defaultdepotOutSelectedColumns = null ;
      let defaultdepotInSelectedColumns = null ;
      let defaultstackerSelectedColumns = null ;
      let defaultdepotOutSavedAdvancedFilters = null ;
      let defaultdepotInSavedAdvancedFilters = null ;
      let defaultquickViews = null ;
      let defaultplanningBlocksPriority = null ;
      let defaultplanningGroups = null ;
      let defaultsiteNumber = null ;
      let defaultdepotInPreferences = null ;
      let defaultdepotOutPreferences = null ;
      let defaultdepotOutGridIcons = null ;
      let defaultdepotInGridIcons = null ;


     
      if (userSettings.length === 0) {
        pbPreferences = JSON.parse(
          this.getDefaultPreferenceValues("defaultPbUserSettings")
        );
        toPreferences = JSON.parse(
          this.getDefaultPreferenceValues("defaultToUserSettings")
        );
        screens = JSON.parse(
          this.getDefaultPreferenceValues("globalPrintSetting")
        );
        orderlinePreferences = JSON.parse(
          this.getDefaultPreferenceValues("defaultOrderLineUserSettings")
        );
        defaultPlanZone = this.getDefaultPreferenceValues(
          "defaultPlanZone"
        );
        defaultDateFormat = this.getDefaultPreferenceValues(
          "defaultDateFormat"
        );
        defaultGridIcon= JSON.parse(this.getDefaultPreferenceValues("defaultGridIcon"));
        defaultPlanGroups = JSON.parse(this.getDefaultPreferenceValues("defaultPlanGroups"));
        defaultTruckDepartments = JSON.parse(this.getDefaultPreferenceValues("defaultTruckDepartments"));
        defaultPlanSorting = JSON.parse(this.getDefaultPreferenceValues("defaultPlanSorting"));
        defaultAgendaDateRange = JSON.parse(this.getDefaultPreferenceValues("defaultAgendaDateRange"));
        defaultTruckAgendaDateRange = JSON.parse(this.getDefaultPreferenceValues("defaultTruckAgendaDateRange"));
        defaultAgendaPlanningGroup = JSON.parse(this.getDefaultPreferenceValues("defaultAgendaPlanningGroup"));
        defaultAgendaPlanningZone = JSON.parse(this.getDefaultPreferenceValues("defaultAgendaPlanningZone"));
        defaultTruckAgendaPlanningGroup = JSON.parse(this.getDefaultPreferenceValues("defaultTruckAgendaPlanningGroup"));
        defaultTruckAgendaPlanningZone = JSON.parse(this.getDefaultPreferenceValues("defaultTruckAgendaPlanningZone"));

         defaultdepotOutSelectedColumns = JSON.parse(this.getDefaultPreferenceValues("depotOutSelectedColumns"));
         defaultdepotInSelectedColumns = JSON.parse(this.getDefaultPreferenceValues("depotInSelectedColumns"));
         defaultstackerSelectedColumns = JSON.parse(this.getDefaultPreferenceValues("stackerSelectedColumns"));
         defaultdepotOutSavedAdvancedFilters = JSON.parse(this.getDefaultPreferenceValues("depotOutSavedAdvancedFilters"));
         defaultdepotInSavedAdvancedFilters = JSON.parse(this.getDefaultPreferenceValues("depotInSavedAdvancedFilters"));
         defaultquickViews = JSON.parse(this.getDefaultPreferenceValues("quickViews"));
         defaultplanningBlocksPriority = JSON.parse(this.getDefaultPreferenceValues("planningBlocksPriority"));
         defaultplanningGroups = JSON.parse(this.getDefaultPreferenceValues("planningGroups"));
         defaultsiteNumber = JSON.parse(this.getDefaultPreferenceValues("siteNumber"));
         defaultdepotInPreferences = JSON.parse(this.getDefaultPreferenceValues("depotInPreferences"));
         defaultdepotOutPreferences = JSON.parse(this.getDefaultPreferenceValues("depotOutPreferences"));
         defaultdepotOutGridIcons = JSON.parse(this.getDefaultPreferenceValues("depotOutGridIcons"));
         defaultdepotInGridIcons = JSON.parse(this.getDefaultPreferenceValues("depotInGridIcons"));

      } else {
        let pbPreferencesJson = userSettings.filter(item => {
          return item.setting.name === "defaultPbUserSettings";
        });
        let shipModalColumnsOrderJson = userSettings.filter(item => {
          return item.setting.name === "shipModalColumnsOrder";
        });
        let railModalColumnsOrderJson = userSettings.filter(item => {
          return item.setting.name === "railModalColumnsOrder";
        });
        let orderlinesSelectedColumnsJson = userSettings.find(item => {
          return item.setting.name === "orderlinesDockSelectedColumns";
        });
        let conflictsSelectedColumnsJson = userSettings.find(item => {
          return item.setting.name === 'conflictsDockSelectedColumns';
        });
        let cargoDockSelectedColumnsJson = userSettings.find(item => {
          return item.setting.name === 'cargoDockSelectedColumns';
        });
        let equipmentDockSelectedColumnsJson = userSettings.find(item => {
          return item.setting.name === 'equipmentDockSelectedColumns';
        });
        let documentsDockSelectedColumnsJson = userSettings.find(item => {
          return item.setting.name === 'documentsDockSelectedColumns';
        });
        let toPreferencesJson = userSettings.filter(item => {
          return item.setting.name === "defaultToUserSettings";
        });
        let orderlinePreferencesJson = userSettings.filter(item => {
          return item.setting.name === "defaultOrderLineUserSettings";
        });
        let pbFixedPreferencesJson = userSettings.filter(item => {
          return item.setting.name === "pbFixedPreferences";
        });
        let userSetupJson = userSettings.filter(item => {
          return item.setting.name === "userSetup";
        });

        
     

        let driverAgendaDateRangeJson = userSettings.filter(item => {
          return item.setting.name === "defaultAgendaDateRange";
        });

        let driverTruckAgendaDateRangeJson = userSettings.filter(item => {
          return item.setting.name === 'defaultTruckAgendaDateRange';
        });

        let defaultPlanningZoneJson = userSettings.filter(item => {
          return item.setting.name === "defaultPlanZone";
        });
        let defaultPlanGroupsJson = userSettings.filter(item => {
          return item.setting.name === "defaultPlanGroups";
        })

        let defaultTruckDepartmentsJson = userSettings.filter(item => {
          return item.setting.name === "defaultTruckDepartments"
        })
        let defaultGridIconJson = userSettings.filter(item => {
          return item.setting.name === "defaultGridIcon";
        });
        let toIconsJson = userSettings.filter(item => {
          return item.setting.name === "toIcons";
        });

        let defaultDateTimeFormatJson = userSettings.filter(item => {
          return item.setting.name === "defaultDateFormat";
        });
        let screensJson = userSettings.filter(item => {
          return item.setting.name === "globalPrintSetting";
        });

        let defaultAgendaPlanningZoneJson = userSettings.filter(item => {
          return item.setting.name === "defaultAgendaPlanningZone";
        });

        let defaultAgendaPlanningGroupJson = userSettings.filter(item => {
          return item.setting.name === "defaultAgendaPlanningGroup";
        });

        let defaultTruckAgendaPlanningZoneJson = userSettings.filter(item => {
          return item.setting.name === "defaultTruckAgendaPlanningZone";
        });

        let defaultTruckAgendaPlanningGroupJson = userSettings.filter(item => {
          return item.setting.name === "defaultTruckAgendaPlanningGroup";
        });

        let pixelsPerPlanningBlockJson = userSettings.find(item => {
          return item.setting.name === "pixelsPerPlanningBlock";
        })

        let weekPlanningSettingJson = userSettings.find(item => {
          return item.setting.name === "weekPlanning";
        })

        let depotQuickViewsJson = userSettings.find(item => {
          return item.setting.name === "depotQuickViews";
        })

        let planningSettingJson = userSettings.find(item => {
          return item.setting.name === "planning";
        })

        let generalSettingJson = userSettings.find(item => {
          return item.setting.name === "generalSettings";
        })

        let defaultPlanSortingJson = userSettings.filter(item => {
          return item.setting.name === "defaultPlanSorting";
        })

        let defaultdepotOutSelectedColumnsJson = userSettings.filter((item) => {
          return item.setting.name == "depotOutSelectedColumns"
        })
        let defaultdepotInSelectedColumnsJson = userSettings.filter((item) => {
          return item.setting.name == "depotInSelectedColumns"
        })
        let defaultstackerSelectedColumnsJson = userSettings.filter((item) => {
          return item.setting.name == "stackerSelectedColumns"
        })
        let defaultdepotOutSavedAdvancedFiltersJson = userSettings.filter((item) => {
          return item.setting.name == "depotOutSavedAdvancedFilters"
        })
        let defaultdepotInSavedAdvancedFiltersJson = userSettings.filter((item) => {
          return item.setting.name == "depotInSavedAdvancedFilters"
        })
        let defaultquickViewsJson = userSettings.filter((item) => {
          return item.setting.name == "quickViews"
        })
        let defaultplanningBlocksPriorityJson = userSettings.filter((item) => {
          return item.setting.name == "planningBlocksPriority"
        })
        let defaultplanningGroupsJson = userSettings.filter((item) => {
          return item.setting.name == "planningGroups"
        })
        let defaultsiteNumberJson = userSettings.filter((item) => {
          return item.setting.name == "siteNumber"
        })
        let defaultdepotInPreferencesJson = userSettings.filter((item) => {
          return item.setting.name == "depotInPreferences"
        })
        let defaultdepotOutPreferencesJson = userSettings.filter((item) => {
          return item.setting.name == "depotOutPreferences"
        })
        let defaultdepotOutGridIconsJson = userSettings.filter((item) => {
          return item.setting.name == "depotOutGridIcons"
        })
        let defaultdepotInGridIconsJson = userSettings.filter((item) => {
          return item.setting.name == "depotInGridIcons"
        })

       if(depotQuickViewsJson){
        depotQuickViews = JSON.parse(depotQuickViewsJson.value);
       }
       else{
        depotQuickViews = [];
       }
        if(pixelsPerPlanningBlockJson)
        {
          pixelsPerPlanningBlock = parseInt(pixelsPerPlanningBlockJson.value) > 0 ? parseInt(pixelsPerPlanningBlockJson.value) : 10 ;
        } else {
          pixelsPerPlanningBlock = 10
        }
        if(weekPlanningSettingJson)
        {
          weekPlanningSetting = JSON.parse(weekPlanningSettingJson.value);
        }
        if(planningSettingJson)
        {
          planningSetting = JSON.parse(planningSettingJson.value);
        }
        if(generalSettingJson)
        {
          generalSetting = JSON.parse(generalSettingJson.value);
        }
        if(orderlinesSelectedColumnsJson)
        {
          orderlinesDockSelectedColumns = JSON.parse(orderlinesSelectedColumnsJson.value);
        }  else {
          orderlinesDockSelectedColumns = OrderLineDockColumnDefiniton;
        }
        if(conflictsSelectedColumnsJson)
        {
          conflictsDockSelectedColumns = JSON.parse(conflictsSelectedColumnsJson.value);
        } else {
          conflictsDockSelectedColumns = ConflictsDockColumnDefiniton;
        }
        if(cargoDockSelectedColumnsJson)
        {
          cargoDockSelectedColumns = JSON.parse(cargoDockSelectedColumnsJson.value);
        }  else {
          cargoDockSelectedColumns = CargoDockColumnDefiniton;
        }
        if(documentsDockSelectedColumnsJson)
        {
          documentsDockSelectedColumns = JSON.parse(documentsDockSelectedColumnsJson.value);
        }  else {
          documentsDockSelectedColumns = DocumentsDockColumnDefiniton;
        }
        if(equipmentDockSelectedColumnsJson)
        {
          equipmentDockSelectedColumns = JSON.parse(equipmentDockSelectedColumnsJson.value);
        }  else {
          equipmentDockSelectedColumns = EquipmentDockColumnDefiniton;
        }

        if(pbPreferencesJson.length > 0) {
          pbPreferences = JSON.parse(pbPreferencesJson[0].value, this.parseDate);
        } else {
          pbPreferences = {
            selectedColumns: [],
            savedAdvancedFilters: [],
            quickViews: [],
            selectedQuickFilters : {}
          }
        }

        if(screensJson.length > 0)
        {
          screens = JSON.parse(screensJson[0].value);
        }
        defaultPlanningZone = "";
        /*Rework Filter*/
         if(defaultPlanningZoneJson[0] )
          {
            defaultPlanZone = defaultPlanningZoneJson[0].value;
          } 

          if(defaultTruckDepartmentsJson.length > 0 ) {
            defaultTruckDepartments = JSON.parse(defaultTruckDepartmentsJson[0].value) ;
          } else {
            defaultTruckDepartments = [] ;
          }

          if(defaultPlanGroupsJson.length > 0 )
          {
            defaultPlanGroups = JSON.parse(defaultPlanGroupsJson[0].value);
          }
          else {
            defaultPlanGroups = [] ;
          }


        if(toIconsJson.length > 0)
             toIcons=JSON.parse(toIconsJson[0].value);
        
        if(defaultGridIconJson.length > 0)
             defaultGridIcon=JSON.parse(defaultGridIconJson[0].value);
        else {
              defaultGridIcon = this.assignDefaultGridIcon;
              this.userSettingService.getSettingByName('defaultGridIcon').subscribe((setting) => {
            });
        }

        if (defaultDateTimeFormatJson.length > 0)
          defaultDateFormat = defaultDateTimeFormatJson[0].value;

        if(toPreferencesJson.length > 0) {
          if (
            JSON.parse(toPreferencesJson[0].value).selectedToColumns &&
            JSON.parse(toPreferencesJson[0].value).selectedToQuickFilters
          ) {
            toPreferences = JSON.parse(toPreferencesJson[0].value);
          } else if (
            JSON.parse(toPreferencesJson[0].value).selectedToQuickFilters ===
            undefined
          ) {
            toPreferences = {
              selectedToColumns: JSON.parse(toPreferencesJson[0].value)
                .selectedToColumns,
              selectedToQuickFilters: {}
            };
          }
        } else {
          toPreferences = {
            selectedToColumns: [] ,
            selectedToQuickFilters : {}
          }
        }

        if(orderlinePreferencesJson.length > 0) {
          orderlinePreferences = JSON.parse(orderlinePreferencesJson[0].value);
        } else {
          orderlinePreferences = {
            OrderLineColumnSelection: [],
            PbOrderLineColumnSelection: []
          }
        }

        if(defaultPlanSortingJson.length > 0) {
          if(defaultPlanSortingJson[0].value != "") {
            defaultPlanSorting = defaultPlanSortingJson[0].value;
          }
        }

        if(pbFixedPreferencesJson.length > 0) {
          pbFixedPreferences = JSON.parse(pbFixedPreferencesJson[0].value, this.parseDate);
        }
        if(userSetupJson.length > 0) {
          userSetup = JSON.parse(userSetupJson[0].value);
        }
        if(driverAgendaDateRangeJson.length > 0)
          {
            if(driverAgendaDateRangeJson[0].value) {
              defaultAgendaDateRange = driverAgendaDateRangeJson[0].value;
            }
          }

        if(driverTruckAgendaDateRangeJson.length > 0) {
          if(driverTruckAgendaDateRangeJson[0].value) {
            defaultTruckAgendaDateRange = driverTruckAgendaDateRangeJson[0].value;
          }
        }
          

        if(defaultAgendaPlanningGroupJson.length > 0)
        {
          if(defaultAgendaPlanningGroupJson[0].value) {
            defaultAgendaPlanningGroup = JSON.parse(defaultAgendaPlanningGroupJson[0].value);
          }
        }
          

        if(defaultAgendaPlanningZoneJson.length > 0) {
          if(defaultAgendaPlanningZoneJson[0].value) {
            defaultAgendaPlanningZone = JSON.parse(defaultAgendaPlanningZoneJson[0].value);
          }
        }
          

        if(defaultTruckAgendaPlanningGroupJson.length > 0)
         {
           if(defaultTruckAgendaPlanningGroupJson[0].value) {
            defaultTruckAgendaPlanningGroup = JSON.parse(defaultTruckAgendaPlanningGroupJson[0].value);
           }
         }
         

        if(defaultTruckAgendaPlanningZoneJson.length > 0)
         {
           if(defaultTruckAgendaPlanningZoneJson[0].value) {
            defaultTruckAgendaPlanningZone = JSON.parse(defaultTruckAgendaPlanningZoneJson[0].value);
           }
         }

         if(defaultdepotInSelectedColumnsJson.length > 0)
         {
           if(defaultdepotInSelectedColumnsJson[0].value) {
            defaultdepotInSelectedColumns = JSON.parse(defaultdepotInSelectedColumnsJson[0].value);
           }
         }

         if(defaultdepotOutSelectedColumnsJson.length > 0)
         {
           if(defaultdepotOutSelectedColumnsJson[0].value) {
            defaultdepotOutSelectedColumns = JSON.parse(defaultdepotOutSelectedColumnsJson[0].value);
           }
         }
         

        if(defaultstackerSelectedColumnsJson.length >0) {
          if(defaultstackerSelectedColumnsJson[0].value) {
            defaultstackerSelectedColumns = JSON.parse(defaultstackerSelectedColumnsJson[0].value);
           }
        }

        if(shipModalColumnsOrderJson.length >0) {
          if(shipModalColumnsOrderJson[0].value) {
            shipModalColumnsOrder = JSON.parse(shipModalColumnsOrderJson[0].value);
           }
        }

        if(railModalColumnsOrderJson.length >0) {
          if(railModalColumnsOrderJson[0].value) {
            railModalColumnsOrder = JSON.parse(railModalColumnsOrderJson[0].value);
           }
        }

        if(defaultdepotOutSavedAdvancedFiltersJson.length >0) {
           if(defaultdepotOutSavedAdvancedFiltersJson[0].value) {
            defaultdepotOutSavedAdvancedFilters = JSON.parse(defaultdepotOutSavedAdvancedFiltersJson[0].value);
           }
        }
        if(defaultdepotInSavedAdvancedFiltersJson.length >0) {
          if(defaultdepotInSavedAdvancedFiltersJson[0].value) {
            defaultdepotInSavedAdvancedFilters = JSON.parse(defaultdepotInSavedAdvancedFiltersJson[0].value);
           }
        }
        if(defaultquickViewsJson.length >0) {
          if(defaultquickViewsJson[0].value) {
            defaultquickViews = JSON.parse(defaultquickViewsJson[0].value);
           }
        }
        if(defaultplanningBlocksPriorityJson.length >0) {
          if(defaultplanningBlocksPriorityJson[0].value) {
            defaultplanningBlocksPriority = JSON.parse(defaultplanningBlocksPriorityJson[0].value);
           }
        }
        if(defaultplanningGroupsJson.length >0) {
          if(defaultplanningGroupsJson[0].value) {
            defaultplanningGroups = JSON.parse(defaultplanningGroupsJson[0].value);
           }
        }
        if(defaultsiteNumberJson.length >0) {
          if(defaultsiteNumberJson[0].value) {
            defaultsiteNumber = JSON.parse(defaultsiteNumberJson[0].value);
           }
        }
        if(defaultdepotInPreferencesJson.length >0) {
          if(defaultdepotInPreferencesJson[0].value) {
            defaultdepotInPreferences = JSON.parse(defaultdepotInPreferencesJson[0].value);
           }
        }
        if(defaultdepotOutPreferencesJson.length >0) {
          if(defaultdepotOutPreferencesJson[0].value) {
            defaultdepotOutPreferences = JSON.parse(defaultdepotOutPreferencesJson[0].value);
           }
        }
        if(defaultdepotOutGridIconsJson.length >0) {
          if(defaultdepotOutGridIconsJson[0].value) {
            defaultdepotOutGridIcons = JSON.parse(defaultdepotOutGridIconsJson[0].value);
           }
        }
        if(defaultdepotInGridIconsJson.length >0){

          if(defaultdepotInGridIconsJson[0].value) {
            defaultdepotInGridIcons = JSON.parse(defaultdepotInGridIconsJson[0].value);
           }
        }


      }

      let defaultPlanDateRange = this.getDefaultWeekPlanningDateRange(weekPlanningSetting, userSetup);

      let defaultPlanDate: string = this.getDefaultPlanningDateRange();

      let applicationUser = {
        id: userId,
        depotQuickViews: depotQuickViews,
        settings: userSettings,
        pbPreferences: pbPreferences,
        shipModalColumnsOrder,
        railModalColumnsOrder,
        orderlinePreferences: orderlinePreferences,
        toPreferences: toPreferences,
        defaultPlanningZone: defaultPlanningZone,
        defaultDateFormat: defaultDateFormat,
        defaultGridIcon: defaultGridIcon,
        toIcons : toIcons,
        Screens: screens,
        defaultPlanDate : defaultPlanDate,
        defaultPlanZone : defaultPlanZone,
        defaultPlanGroups : defaultPlanGroups,
        defaultTruckDepartments : defaultTruckDepartments,
        defaultPlanDateRange : defaultPlanDateRange,
        pbFixedPreferences : pbFixedPreferences,
        pixelsPerPlanningBlock : pixelsPerPlanningBlock,
        weekPlanningSetting : weekPlanningSetting,
        planningSetting: planningSetting,
        generalSetting: generalSetting,
        orderlinesDockSelectedColumns,
        conflictsDockSelectedColumns,
        cargoDockSelectedColumns,
        equipmentDockSelectedColumns,
        documentsDockSelectedColumns,
        userSetup : userSetup,
        defaultPlanSorting : defaultPlanSorting,

        defaultAgendaDateRange : defaultAgendaDateRange, 
        defaultAgendaPlanningGroup: defaultAgendaPlanningGroup,
        defaultAgendaPlanningZone: defaultAgendaPlanningZone,
        defaultTruckAgendaDateRange: defaultTruckAgendaDateRange, 
        defaultTruckAgendaPlanningGroup: defaultTruckAgendaPlanningGroup,
        defaultTruckAgendaPlanningZone: defaultTruckAgendaPlanningZone, 

        depotOutSelectedColumns : defaultdepotOutSelectedColumns,
        depotInSelectedColumns : defaultdepotInSelectedColumns,
        stackerSelectedColumns : defaultstackerSelectedColumns,
        depotOutSavedAdvancedFilters : defaultdepotOutSavedAdvancedFilters,
        depotInSavedAdvancedFilters : defaultdepotInSavedAdvancedFilters,
        quickViews : defaultquickViews,
        planningBlocksPriority : defaultplanningBlocksPriority,
        planningGroups : defaultplanningGroups,
        siteNumber : defaultsiteNumber,
        depotInPreferences : defaultdepotInPreferences,
        depotOutPreferences : defaultdepotOutPreferences,
        depotOutGridIcons : defaultdepotOutGridIcons,
        depotInGridIcons : defaultdepotInGridIcons

      } as ApplicationUser;

      this.ngRedux.dispatch({
        type: ActionKeys.GET_APPLICATION_USER_SUCCESS,
        applicationUser: applicationUser
      });

            // this.sharedActions.setApplicationUser(applicationUser);
            this.ngRedux.dispatch({
                type: ActionKeys.SET_SESSION_USERSETTINGS_DATA,
                sessionColumnSelection: pbPreferences.selectedColumns,
                sessionToColumnSelection: toPreferences.selectedToColumns,
                sessionOrderLineColumnSelection: orderlinePreferences.OrderLineColumnSelection,
                sessionDocumentSelection: screens.depotOut,
                sessionPbOrderLineColumnSelection: orderlinePreferences.PbOrderLineColumnSelection,
                depotOutSelectedColumns: defaultdepotOutSelectedColumns,
                depotInSelectedColumns: defaultdepotInSelectedColumns,
                stackerSelectedColumns: defaultstackerSelectedColumns
            });

            localStorage.setItem('defaultDateFormat', applicationUser.defaultDateFormat);
            return applicationUser;
    
  }

  getDefaultPlanningDateRange(): string {
    let date = this.planningDatesService.getPlanningDate();
    if(date)
      return date;
    return moment().format("DD/MM/YYYY");
  }

  getDefaultWeekPlanningDateRange(weekPlanningSetting: WeekPlanningSetting, userSetup: UserSetup) {
    let fromDate, untilDate;
    let sessionDates = this.planningDatesService.getWeekPlanningDates();
    if(sessionDates)
      return sessionDates;
    let range = weekPlanningSetting.defaultDateFromRange ?? 0;
    fromDate = moment().add(range,'days');
    let maxDays = this.planningDatesService.getPlanningFromUntilRange(userSetup.weekplanningDefaultDateRange, weekPlanningSetting.defaultDateRange);      
    untilDate = moment(fromDate).add(maxDays,'days');
    return {
      defaultPlanDateFrom : fromDate.format("DD/MM/YYYY"),
      defaultPlanDateUntil : untilDate.format("DD/MM/YYYY")
     };
  }

  updateApplicationUser(applicationUser: ApplicationUser, settingName , showTostr: boolean) {
    this.updateApplicationUserObservable(applicationUser, settingName, showTostr).subscribe(() => {});
    }

    updateApplicationUserObservable(applicationUser: ApplicationUser, settingName , showTostr: boolean):Observable<any> {
      return this.userSettingService
        .getUserSettingByName(settingName, applicationUser.id)
        .switchMap((userSetting: UserSettings[]) => {
          let settingValue = applicationUser[settingName] ? JSON.stringify(applicationUser[settingName]) : null;
          if (settingName === "defaultOrderLineUserSettings") {
            settingValue = JSON.stringify(
              applicationUser.orderlinePreferences
            );
          }
          else if (settingName === "defaultToUserSettings") {
            settingValue = JSON.stringify(applicationUser.toPreferences);
          }
          else if (settingName === "defaultPbUserSettings") {
              settingValue = JSON.stringify(applicationUser.pbPreferences);
          }
          else if (settingName === "defaultDateFormat") {
              settingValue = applicationUser.defaultDateFormat
          }
          else if (settingName === "defaultGridIcon") {
            settingValue = JSON.stringify(
              applicationUser.defaultGridIcon
            );
          }
          else if (settingName === "toIcons") {
            settingValue = JSON.stringify(
              applicationUser.toIcons
            );
          }
          else if(settingName === "defaultPlanZone") {
            settingValue = applicationUser.defaultPlanZone;
          }
          else if(settingName === "defaultPlanGroups") {
            settingValue = JSON.stringify(
              applicationUser.defaultPlanGroups
            )
          }
  
          else if(settingName === "defaultTruckDepartments")
          {
            settingValue = JSON.stringify(
              applicationUser.defaultTruckDepartments
            )
          }
  
          else if(settingName === "pbFixedPreferences")
          {
            settingValue = JSON.stringify(applicationUser.pbFixedPreferences);
          }
          
          if(settingName === "userSetup")
          {
            settingValue = JSON.stringify(applicationUser.userSetup);
          }
          
  
          else if(settingName === "defaultAgendaDateRange")
          {
              settingValue = JSON.stringify(applicationUser.defaultAgendaDateRange);
          }
  
          else if(settingName === "defaultTruckAgendaDateRange")
          {
              settingValue = JSON.stringify(applicationUser.defaultTruckAgendaDateRange);
          }
  
          else if(settingName === "defaultAgendaPlanningZone")
          {
              settingValue = JSON.stringify(applicationUser.defaultAgendaPlanningZone);
          }
  
          else if(settingName === "defaultAgendaPlanningGroup")
          {
              settingValue = JSON.stringify(applicationUser.defaultAgendaPlanningGroup);
          }
  
          else if(settingName === "defaultTruckAgendaPlanningZone")
          {
              settingValue = JSON.stringify(applicationUser.defaultTruckAgendaPlanningZone);
          }
  
          else if(settingName === "defaultTruckAgendaPlanningGroup")
          {
              settingValue = JSON.stringify(applicationUser.defaultTruckAgendaPlanningGroup);
          }
  
          if(settingName === "defaultPlanSorting")
          {
            settingValue = applicationUser.defaultPlanSorting
          }
  
          
          if(settingName === "depotOutSelectedColumns")
          {
            settingValue = JSON.stringify(applicationUser.depotOutSelectedColumns)
          }
          
          if(settingName === "depotInSelectedColumns")
          {
            settingValue = JSON.stringify(applicationUser.depotInSelectedColumns)
          }
          
          if(settingName === "stackerSelectedColumns")
          {
            settingValue = JSON.stringify(applicationUser.stackerSelectedColumns)
          }
          
          if(settingName === "depotOutSavedAdvancedFilters")
          {
            settingValue = JSON.stringify(applicationUser.depotOutSavedAdvancedFilters)
          }
          
          if(settingName === "depotInSavedAdvancedFilters")
          {
            settingValue = JSON.stringify(applicationUser.depotInSavedAdvancedFilters)
          }
          
          if(settingName === "quickViews")
          {
            settingValue = JSON.stringify(applicationUser.quickViews)
          }
          
          if(settingName === "planningBlocksPriority")
          {
            settingValue = JSON.stringify(applicationUser.planningBlocksPriority)
          }
          
          if(settingName === "planningGroups")
          {
            settingValue = JSON.stringify(applicationUser.planningGroups)
          }
          
          if(settingName === "siteNumber")
          {
            settingValue = JSON.stringify(applicationUser.siteNumber)
          }
          
          if(settingName === "depotInPreferences")
          {
            settingValue = JSON.stringify(applicationUser.depotInPreferences)
          }
          
          if(settingName === "depotOutPreferences")
          {
            settingValue = JSON.stringify(applicationUser.depotOutPreferences)
          }
          
          if(settingName === "depotOutGridIcons")
          {
            settingValue = JSON.stringify(applicationUser.depotOutGridIcons)
          }
          
          if(settingName === "depotInGridIcons")
          {
            settingValue = JSON.stringify(applicationUser.depotInGridIcons)
          }
  
  
  
  
          if(!userSetting || !userSetting.length) {
            return this.createUserSettingsObservable(applicationUser, settingValue, settingName, showTostr);
          }
          else{
            userSetting[0].value = settingValue;
            return this.userSettingService.createOrUpdateUserSettings(userSetting[0], applicationUser.id).map(
              () => {
                this.updateApplicationUserSucceded(applicationUser, showTostr);
              }
            );
          }
  
        });
      }

      createUserSettingsObservable(applicationUser: ApplicationUser, value, settingName: any, showTostr: boolean):Observable<any> {
        return this.userSettingService.getSettingByName(settingName).switchMap(setting => {
          if(!setting)
            {
              this.toastr.error("ERROR setting: " +settingName + "does not exist.");
              return;
            }
          return this.userSettingService.createUserSettings(applicationUser.id, setting, value, null, null).map(
            () => {
              this.updateApplicationUserSucceded(applicationUser, showTostr);
            }
          );
        });
      }

    updateApplicationUserWithDefaultSetting(applicationUser: ApplicationUser, settingName, showTostr: boolean) {
      this.userSettingService
        .getUserSettingByName(settingName, applicationUser.id)
        .subscribe((userSetting: UserSettings[]) => {
          let value = null;

          if(settingName === "pbFixedPreferences")
          {
            value = JSON.stringify(applicationUser.pbFixedPreferences);
          }

          if(!userSetting.length)
          {
            this.userSettingService.getSettingByName(settingName).subscribe(setting => {
              this.userSettingService.createUserSettings(null, setting, value, null, null).subscribe(
                () => {
                  this.updateApplicationUserSucceded(applicationUser, showTostr);
                }
              );
            });
          }
          else {
            userSetting[0].value = value;
            this.userSettingService.createOrUpdateDefaultUserSettings(userSetting[0]).subscribe(
              () => {
                this.updateApplicationUserSucceded(applicationUser, showTostr);
              }
            );
          }
        });
      }

  public updateApplicationUserSucceded(applicationUser: ApplicationUser, showTostr: boolean) {
    this.ngRedux.dispatch({
      type: ActionKeys.UPDATE_APPLICATION_USER,
      applicationUser: applicationUser
    });
    if(showTostr) {
      this.translate
      .get("TOASTR_MESSAGES.SUCCESS.COLUMN_SELECTION_SUCCESS")
      .subscribe((res: string) => {
        this.toastr.success(res);
      });
    }
  }

    setAzureUsers() {
        this.httpClientService.getToken().subscribe((data) => {
            this.applicationUserService.GetAzureUser().subscribe((res : any) => {
                if (res) {
                    this.ngRedux.dispatch({
                        type: ActionKeys.REQUEST_AZURE_USERS_SUCCESS,
                        azureUsers: res.data
                    });
                }
            })
        })

    }

    parseDate(key, value) {
      let dateFormat = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*))(?:Z|(\+|-)([\d|:]*))?$/;
      if (typeof value === "string" && dateFormat.test(value)) {
        if(value)
          return new Date(value);
        return null;
      }

      return value;
    }
}
