import { ClientSettings } from "./static.interface";

export let webSettings: ClientSettings;

const tempApiUri = '';
const tempToken = '';

// export const clientSettings = {
//     containerPlanningApiUri: webSettings.containerPlanningApiUri,
//     authorizationToken: webSettings.authorizationToken,
// } as ClientSettings;

export const clientSettings = {
    containerPlanningApiUri: tempApiUri,
    authorizationToken: tempToken,
} as ClientSettings;


