import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { ComponentSource, ModalType } from '../../models/component-source';
import { PlanningBlock } from '../../models/planning-block/planning-block.interface';

@Component({
    selector: 'depot-selection-display',
    templateUrl: './selection-display.component.html'
})

export class DepotSelectionDisplayComponent implements OnChanges{

    @Input() selectedPlanningBlocks: PlanningBlock[];
    @Input() source: string;
    @Input() parentModalType: string;

    public componentSource = ComponentSource;
    public modalType = ModalType;

    ngOnChanges(changes: SimpleChanges): void {
        if(changes.selectedPlanningBlocks)
        {
            this.selectedPlanningBlocks.forEach(pb => {
                if(!pb.containerOrTrailerType)
                    pb.containerOrTrailerType = pb.containerType;
            });
        }
    }
}