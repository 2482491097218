import { select } from "@angular-redux/store";
// tslint:disable-next-line:max-line-length
import { Component, EventEmitter, OnDestroy, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DepotInActions } from "../../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../../depot-out/depot-out.actions";
import { PoolHaulier } from "../../../../pool/models/pool-haulier.interface";
import { PoolTruck } from "../../../../pool/models/pool-truck.interface";
import { PoolService } from "../../../../pool/pool.service";
import { ComponentSource, ModalType } from "../../../models/component-source";
import { ContainerReuseInfo } from "../../../models/container-reuse/container.reuse.interface";
import { RE_USED, TO_BE_PLANNED } from "../../../models/status-type.interface";
import { ModalManagerService } from "../../../services/modal-manager.service";
import { SharedService } from "../../../services/shared.service";
import { SharedActions } from "../../../shared.actions";
import { ContainerReuseStatus } from "./container-reuse.state.enum";
import { Observable, Subject } from "rxjs";
import { combineLatest } from 'rxjs';
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { HttpClientService } from "../../../services/httpclient";
import { MsalService } from "../../../../msal/msal.service";
import { SimpleModalComponent } from "ngx-simple-modal";
import { map, takeUntil } from "rxjs/operators";
import { TableStateService } from "../../../../services/table.state.service";
import * as _ from "lodash";
import { ContainerPlanningTypeEnum } from "../../../models/planning-groups-enum.interface";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";
@Component({
  selector: "container-reuse-modal",
  templateUrl: "container-reuse-modal.component.html",
})

export class ContainerReuseComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
  @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
  @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
  @select("reuseCandidates") public reuseCandidates$: Observable<PlanningBlock[]>;
  @select('loading') public loading$: Observable<boolean>;
  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
  public enableAutocomplete: boolean = false;
  public containerReuseInfo: ContainerReuseInfo;
  public containerReuseInfoStatus: number = -1;
  public containerReuseInfoStatusText: string = "";
  public selectedContainerReuseInfoStatus: number = 0;
  public loadingSpinner: boolean = false;
  public isReused: boolean = false;
  public selectedPbs: PlanningBlock[];
  public componentSource = ComponentSource;
  public modalType = ModalType;

  public hauliers: PoolHaulier[] = [];
  public selectedHaulier: PoolHaulier = null;
  public customHaulierValue: boolean = false;
  public commentHaulierSelection: PoolHaulier = null;

  public trucks: PoolTruck[] = [];
  public selectedTruck: PoolTruck = null;
  public customTruckValue: boolean = false;
  public commentTruckSelection: PoolTruck = null;
  public labelVisibility: boolean = false;
  public onlyOnePbSelected: boolean = false;
  public selectionIndex: number = 0;
  public selectionMaxIndex: number = 0;
  public currentDepotInReusePb: PlanningBlock[] = [];
  public currentDepotOutReusePb: PlanningBlock[] = [];
  public depotInPlanningBlocks: PlanningBlock[];
  public depotOutPlanningBlocks: PlanningBlock[];
  public reuseNumberDisplay: string;
  public onlyReuseApproval: boolean = false;
  destroy$ = new Subject();

  constructor(
    private readonly sharedActions: SharedActions,
    private readonly sharedService: SharedService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly depotInActions: DepotInActions,
    private readonly depotOutActions: DepotOutActions,
    private readonly poolService: PoolService,
    private readonly modalManager: ModalManagerService,
    private readonly authService: MsalService,
    public readonly httpClientService: HttpClientService,
    private readonly tableStateService: TableStateService
  ) { 
    super();
  }

  public ngOnInit(): void {

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })

    this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
      }
    });

    this.httpClientService.getToken().subscribe((token) => {
      this.poolService.getHauliers().subscribe((hauliers: PoolHaulier[]) => {
        this.hauliers = hauliers;
      });
    })



    combineLatest([this.depotInSelection$, this.depotOutSelection$])
      .pipe(map(([depotInSelection, depotOutSelection]) => {
          return {depotInSelection, depotOutSelection};
        }), takeUntil(this.destroy$))
      .subscribe((result => {
          this.depotInPlanningBlocks = result.depotInSelection;
          this.depotOutPlanningBlocks = result.depotOutSelection;
          this.onlyOnePbSelected = [...  this.depotInPlanningBlocks, ... this.depotOutPlanningBlocks].length === 1 ? true : false;
          this.selectedPbs = [...  this.depotInPlanningBlocks, ... this.depotOutPlanningBlocks];
          this.renderPlanningBlocksSelection();
      }));

      this.reuseCandidates$.pipe(takeUntil(this.destroy$))
        .subscribe((candidates: PlanningBlock[]) => {
            if(candidates.length)
            {
              this.depotInPlanningBlocks = candidates.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.IN);
              this.depotOutPlanningBlocks = candidates.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.AF);
              this.onlyOnePbSelected = [...  this.depotInPlanningBlocks, ... this.depotOutPlanningBlocks].length === 1 ? true : false;
              this.selectedPbs = [...  this.depotInPlanningBlocks, ... this.depotOutPlanningBlocks];
              this.renderPlanningBlocksSelection();
            }
        })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public renderPlanningBlocksSelection() {
    
    if(this.selectedPbs.length > 1 && this.selectedPbs.map(x => x.status.statusType == 94).length === this.selectedPbs.length )
    {
      this.setMultipleSelectedPb();
    } 
    else {
      if (this.reuseOperationCheck()) {
        if (this.onlyOnePbSelected) {
          this.setSingleSelectedPb();
        } 
        else {
          this.setDoubleSelectedPbs();
        }
      }
    }
    
  }



  public setSingleSelectedPb() {
    const canceledStatusNumber: number = 3;
    const selectedPlanningBlock = this.depotInPlanningBlocks.length > 0
      ? this.depotInPlanningBlocks[0]
      : this.depotOutPlanningBlocks[0];
    this.selectionMaxIndex = 1;
    this.selectionIndex = 0;
    this.reuseNumberDisplay = selectedPlanningBlock.containerReuseNo;

    this.currentDepotInReusePb = this.depotInPlanningBlocks.length === 1 ? this.depotInPlanningBlocks : [];
    this.currentDepotOutReusePb = this.depotOutPlanningBlocks.length === 1 ? this.depotOutPlanningBlocks : [];

    this.sharedService.getContainerReuseInfo(selectedPlanningBlock.containerReuseNo)
      .subscribe((containerReuseInfo: ContainerReuseInfo) => {
        if (containerReuseInfo) {
          this.sharedService.getPlanningBlockByReuseNo(containerReuseInfo.containerReuseNo).subscribe(
            (planningBlocks: PlanningBlock[]) => {
              if (selectedPlanningBlock.statusType === RE_USED) {
                this.isReused = true;
              }
              this.currentDepotInReusePb = planningBlocks.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.IN);
              this.currentDepotOutReusePb = planningBlocks.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.AF);

              this.containerReuseInfo = containerReuseInfo;
              this.containerReuseInfoStatus = containerReuseInfo.requestStatus;
              this.translateContainerReuseInfoStatusToText();

              if (this.containerReuseInfoStatus < canceledStatusNumber) {
                if (!this.isReused) {
                  this.selectedContainerReuseInfoStatus = this.containerReuseInfoStatus + 1;
                } else {
                  this.selectedContainerReuseInfoStatus = 3;
                }
              }
            });
        } else {
          this.containerReuseInfo = null;
          this.containerReuseInfoStatusText = "";
          this.selectedContainerReuseInfoStatus = 0;
          this.isReused = false;
        }
      });
  }

  public setDoubleSelectedPbs() {
    const canceledStatusNumber: number = 3;
    this.selectionMaxIndex = this.depotInPlanningBlocks.length <= this.depotOutPlanningBlocks.length ? this.depotInPlanningBlocks.length : this.depotOutPlanningBlocks.length;

    this.currentDepotInReusePb = [this.depotInPlanningBlocks[this.selectionIndex]];
    this.currentDepotOutReusePb = [this.depotOutPlanningBlocks[this.selectionIndex]];
    this.reuseNumberDisplay = this.depotInPlanningBlocks[this.selectionIndex].containerReuseNo;

    this.sharedService.getContainerReuseInfo(this.currentDepotInReusePb[0].containerReuseNo)
      .subscribe((containerReuseInfo: ContainerReuseInfo) => {
        if (containerReuseInfo) {
          this.sharedService.getPlanningBlockByReuseNo(containerReuseInfo.containerReuseNo).subscribe(
            (planningBlocks: PlanningBlock[]) => {
              this.isReused = this.currentDepotInReusePb[0].statusType === RE_USED;

              this.containerReuseInfo = containerReuseInfo;
              this.containerReuseInfoStatus = containerReuseInfo.requestStatus;
              this.translateContainerReuseInfoStatusToText();

              if (this.containerReuseInfoStatus < canceledStatusNumber) {
                if (!this.isReused) {
                  this.selectedContainerReuseInfoStatus = this.containerReuseInfoStatus + 1;
                } else {
                  this.selectedContainerReuseInfoStatus = 3;
                }
              }
            });
        } else {
          this.containerReuseInfo = null;
          this.containerReuseInfoStatusText = "";
          this.selectedContainerReuseInfoStatus = 0;
          this.isReused = false;
        }
      });
  }


  public setMultipleSelectedPb() 
  {
    this.onlyReuseApproval = true;
    const canceledStatusNumber: number = 3;
    this.selectionMaxIndex = this.selectedPbs.length;
    this.reuseNumberDisplay = "";
    this.currentDepotInReusePb = this.depotInPlanningBlocks.length === 1 ? this.depotInPlanningBlocks : [];
    this.currentDepotOutReusePb = this.depotOutPlanningBlocks.length === 1 ? this.depotOutPlanningBlocks : [];
    this.sharedService.getContainerReuseInfo(this.selectedPbs[0].containerReuseNo)
      .subscribe((containerReuseInfo: ContainerReuseInfo) => {
        if (containerReuseInfo) {
          this.sharedService.getPlanningBlockByReuseNo(containerReuseInfo.containerReuseNo).subscribe(
            (planningBlocks: PlanningBlock[]) => {
              this.isReused = this.selectedPbs[0].statusType === RE_USED;

              this.containerReuseInfo = containerReuseInfo;
              this.containerReuseInfoStatus = containerReuseInfo.requestStatus;
              this.currentDepotInReusePb = this.depotInPlanningBlocks.length === 1 ? this.depotInPlanningBlocks : [];
              this.currentDepotOutReusePb = this.depotOutPlanningBlocks.length === 1 ? this.depotOutPlanningBlocks : [];
              this.translateContainerReuseInfoStatusToText();

              if (this.containerReuseInfoStatus < canceledStatusNumber) {
                if (!this.isReused) {
                  this.selectedContainerReuseInfoStatus = this.containerReuseInfoStatus + 1;
                } else {
                  this.selectedContainerReuseInfoStatus = 3;
                }
              }
            });
        } else {
          this.containerReuseInfo = null;
          this.containerReuseInfoStatusText = "";
          this.selectedContainerReuseInfoStatus = 0;
          this.isReused = false;
        }
      });
  }

  public reuseOperationCheck(): boolean {
    return this.depotInPlanningBlocks && this.depotOutPlanningBlocks && [...this.depotInPlanningBlocks, ...this.depotOutPlanningBlocks].length > 0
      && (this.depotInPlanningBlocks.length === this.depotOutPlanningBlocks.length
        || (this.depotInPlanningBlocks.length === 0 && this.depotOutPlanningBlocks.length === 1)
        || (this.depotInPlanningBlocks.length === 1 && this.depotOutPlanningBlocks.length === 0));
  }

  public incrementIndex() {
    this.selectionIndex++;
    this.currentDepotInReusePb = [this.depotInPlanningBlocks[this.selectionIndex]];
    this.currentDepotOutReusePb = [this.depotOutPlanningBlocks[this.selectionIndex]];
    this.reuseNumberDisplay = this.depotInPlanningBlocks[this.selectionIndex].containerReuseNo;
  }

  public decrementIndex() {
    this.selectionIndex--;
    this.currentDepotInReusePb = [this.depotInPlanningBlocks[this.selectionIndex]];
    this.currentDepotOutReusePb = [this.depotOutPlanningBlocks[this.selectionIndex]];
    this.reuseNumberDisplay = this.depotInPlanningBlocks[this.selectionIndex].containerReuseNo;
  }

    public onConfirmClicked(): void {
     
    if (!this.containerReuseInfo) {
        this.requestContainerReuse();
        
    }
      
    let reuseNo: string = "";
    let reuseNumbers = [];
    let planningBlocksToReuse: PlanningBlock[] = null;
      
    if(this.selectedPbs.length === 1)
    {
      reuseNo = this.currentDepotInReusePb[0] ? this.currentDepotInReusePb[0].containerReuseNo : this.currentDepotOutReusePb[0].containerReuseNo ;
      reuseNumbers.push(reuseNo);
      planningBlocksToReuse = [this.currentDepotInReusePb[0], this.currentDepotOutReusePb[0]];
    } 
    else {
      reuseNumbers = this.selectedPbs.map((pb) => pb.containerReuseNo);
      planningBlocksToReuse = [this.selectedPbs[0],this.selectedPbs[1]]
    }
    
    reuseNumbers = _.uniq(reuseNumbers);
    reuseNumbers.forEach((reuseNo) => {
      if (reuseNo) {
        switch (this.selectedContainerReuseInfoStatus) {
          case ContainerReuseStatus.APPROVED:
                this.updateContainerReuseStateToApproved(reuseNo);
            break;
          case ContainerReuseStatus.REJECTED:
                this.updateContainerReuseStateToRejected(reuseNo, planningBlocksToReuse);
            break;
          case ContainerReuseStatus.CANCELED:
                this.updateContainerReuseStateToCanceled(reuseNo);
            break;
          }
          this.close();
                  
          }
    })  
        
  }

  public translateContainerReuseInfoStatusToText(): void {
    const translateString: string = this.getContainerStatusTranslatedString();

    if (translateString) {
      this.translate.get(translateString).subscribe((res: string) => {
        this.containerReuseInfoStatusText = res;
      });
    }
  }

  public getContainerStatusTranslatedString(): string {
    switch (this.containerReuseInfoStatus) {
      case ContainerReuseStatus.APPROVED:
        return "CONTAINER_REUSE.APPROVE";
      case ContainerReuseStatus.REQUESTED:
        return "CONTAINER_REUSE.REQUEST";
      case ContainerReuseStatus.CANCELED:
        return "CONTAINER_REUSE.CANCEL";
      case ContainerReuseStatus.REJECTED:
        return "CONTAINER_REUSE.REJECT";
      default:
        return "";
    }
  }

  public updateContainerReuseStateToCanceled(reuseNo: string): void {
    this.loadingSpinner = true;
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.updateContainerReuseStateToCanceledServiceCall(reuseNo, account.username);
      } else {
        this.onErrorEvents();
      }
    
  }

  public updateContainerReuseStateToCanceledServiceCall(reuseNo: string, username: string): void {
    this.sharedService.cancelContainerReuse(reuseNo, username).subscribe((planningBlocks: PlanningBlock[]) => {
      this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
      this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
      this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_CANCELED_COMPLETED_SUCCESFULLY")
        .subscribe((res: string) => {
          this.onSuccessEvents(res);
          this.loadingSpinner = false;
        });
    },
      (error) => {
        this.onErrorEvents();
      });
  }

  public updateContainerReuseStateToRejected(reuseNo: string, planningBlocks: PlanningBlock[]): void {
    this.loadingSpinner = true;
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.updateContainerReuseStateToRejectedServiceCall(reuseNo, planningBlocks, account.username);
      } else {
        this.onErrorEvents();
      }
    
  }

  public updateContainerReuseStateToRejectedServiceCall(reuseNo: string,
                                                        planningBlocks: PlanningBlock[], username: string): void {
    if(this.onlyReuseApproval) 
    {
      this.rejectReuse(reuseNo, username);
    } 
    else 
    {
      if (planningBlocks) {
      
        if (planningBlocks[0].planningBlockRemarks) {
          this.rejectReuseWithExternalHaulierRemark(reuseNo, planningBlocks[0], username);
        }
        if (planningBlocks[1]?.planningBlockRemarks) {
          this.rejectReuseWithExternalHaulierRemark(reuseNo, planningBlocks[1], username);
        } else {
          this.rejectReuse(reuseNo, username);
        }
      }
    }
    
  }

  public rejectReuse(reuseNo: string, username: string): void {
    this.sharedService.rejectContainerReuse(reuseNo, username).subscribe((planningBlocks: PlanningBlock[]) => {
      this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
      this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
      this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_REJECTED_COMPLETED_SUCCESFULLY")
        .subscribe((res: string) => {
          this.onSuccessEvents(res);
          this.loadingSpinner = false;
        });
    },
      (error) => {
        this.onErrorEvents();
      });
  }

  public rejectReuseWithExternalHaulierRemark(reuseNo: string, planningBlock: PlanningBlock, username: string): void {
    const remark = planningBlock.planningBlockRemarks.replace(/--(.*?)--/, ""); // --(.*?)--
    const account = this.authService.getAccount(); 
      if (account.username) {

        this.sharedService.postPbRemark(planningBlock.id, remark, account.username).subscribe(
          () => {
            this.translate.get("TOASTR_MESSAGES.SUCCESS.REMARK_SUCCEEDED").subscribe((res: string) => {
                   this.toastr.success(res, "", {
                     closeButton: false
                 });
            });

            this.sharedService.rejectContainerReuse(reuseNo, username).subscribe((planningBlocks: PlanningBlock[]) => {
              this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
              this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
              this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_REJECTED_COMPLETED_SUCCESFULLY")
                .subscribe((res: string) => {
                  this.onSuccessEvents(res);
                  this.loadingSpinner = false;
                });
            },
              (error) => {
                this.onErrorEvents();
              });
          }
          , (err) => {
            this.translate.get("TOASTR_MESSAGES.ERROR.REMARK_ERROR").subscribe((res: string) => {
              this.toastr.error(res);
            });
          });
      }
  
  }

  public updateContainerReuseStateToApproved(reuseNo: string): void {
    this.loadingSpinner = true;
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.updateContainerReuseStateToApprovedServiceCall(reuseNo, account.username);
      } else {
        this.onErrorEvents();
      }
  }
  
  public updateContainerReuseStateToApprovedServiceCall(reuseNo: string, username: string): void {
    this.sharedService.approveContainerReuse(reuseNo, username).subscribe((planningBlocks: PlanningBlock[]) => {
      this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
      this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
      this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_ACCEPTED_COMPLETED_SUCCESFULLY")
        .subscribe((res: string) => {
          this.onSuccessEvents(res);
            this.loadingSpinner = false;
        });
    },
      (error) => {
        this.onErrorEvents();
      });
  }

  public requestContainerReuse(): void {
    this.loadingSpinner = true;
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.registerContainerReuseServiceCall(account.username);
      } else {
        this.onErrorEvents();
      }
    
  }

  public registerContainerReuseServiceCall(username: string): void {
    let depotInPbId: number;
    let depotOutPbId: number;
    let remarkPbId: number;
    let currentRemark: string = "";
    if (this.onlyOnePbSelected) {
      if (this.depotInPlanningBlocks.length > 0) {
        depotInPbId = this.depotInPlanningBlocks[0].id;
        depotOutPbId = 0;
        remarkPbId = depotInPbId;
        currentRemark = this.depotInPlanningBlocks[0].planningBlockRemarks;
      } else if (this.depotOutPlanningBlocks.length > 0) {
        depotInPbId = 0;
        depotOutPbId = this.depotOutPlanningBlocks[0].id;
        remarkPbId = depotOutPbId;
        currentRemark = this.depotOutPlanningBlocks[0].planningBlockRemarks;
      }

      let remark: string = currentRemark;

      if (this.commentHaulierSelection
        && this.commentTruckSelection
        && this.commentHaulierSelection.name
        && this.commentTruckSelection.registrationNumber) {
        // tslint:disable-next-line:max-line-length
        remark = "--Re-use from " + this.commentHaulierSelection.name + "( " + this.commentTruckSelection.registrationNumber + " )--" + currentRemark;
      }

      this.sharedService.postPbRemark(remarkPbId, remark, username).subscribe(
        () => {
          this.translate.get("TOASTR_MESSAGES.SUCCESS.REMARK_SUCCEEDED").subscribe((res: string) => {
              this.toastr.success(res, "", {
                  closeButton: false
              });
          });
          this.sharedService.registerContainerReuse(depotInPbId, depotOutPbId, username)
            .subscribe((planningBlocks: PlanningBlock[]) => {
              this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
              this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
              this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_REGISTRATION_COMPLETED_SUCCESFULLY")
                .subscribe((res: string) => {
                  this.onSuccessEvents(res);
                    this.loadingSpinner = false;
                    this.hideLabel();

                });
            },
              (error) => {
                this.onErrorEvents();
              });
        }, (err) => {
          this.translate.get("TOASTR_MESSAGES.ERROR.REMARK_ERROR").subscribe((res: string) => {
            this.toastr.error(res);
          });

        });

    } else {
      depotInPbId = this.currentDepotInReusePb[0].id;
      depotOutPbId = this.currentDepotOutReusePb[0].id;
      this.sharedService.registerContainerReuse(depotInPbId, depotOutPbId, username)
        .subscribe((planningBlocks: PlanningBlock[]) => {
          this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
          this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
          this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_REUSE_REGISTRATION_COMPLETED_SUCCESFULLY")
            .subscribe((res: string) => {
              this.onSuccessEvents(res);
              this.loadingSpinner = false;
            });
        },
          (error) => {
            this.onErrorEvents();
          });
    }
  }

  public onSuccessEvents(successMessage: string): void {
      this.toastr.success(successMessage, "", {
          closeButton: false
      });
    this.tableStateService.resetTableSelection(true);
    this.sharedActions.setUiState(true);
    this.containerReuseInfo = null;
    this.containerReuseInfoStatusText = "";
    this.selectedContainerReuseInfoStatus = 0;
    this.isReused = false;
    this.selectedTruck = null;
    this.selectedHaulier = null;
    this.customTruckValue = false;
    this.customHaulierValue = false;
    this.hideLabel();
    this.selectionIndex = 0;
    this.selectionMaxIndex = 1;
    this.onlyReuseApproval = false;
    this.switchCurrentData();
    if (this.onlyOnePbSelected) {
      this.close();
    }
  }

  public onErrorEvents(): void {
    this.loadingSpinner = false;
    this.sharedActions.setUiState(true);
    this.selectionIndex = 0;
    this.selectionMaxIndex = 1;
    this.onlyReuseApproval = false;
    this.hideLabel();
  }

  public switchCurrentData() {
    const depotIn = this.depotInPlanningBlocks.filter((pb) => pb.id !== this.selectedPbs[0].id);
    const depotOut = this.depotOutPlanningBlocks.filter((pb) => pb.id !== this.selectedPbs[0].id);
    this.depotInActions.setDepotInSelection(depotIn);
    this.depotOutActions.setDepotOutSelection(depotOut);
  }

  public enableUi() {
    this.sharedActions.setUiState(true);
    this.hideLabel();
    this.selectionIndex = 0;
    this.selectionMaxIndex = 1;
    this.onlyReuseApproval = false;
    this.close();
  }

  public haulierValueFormatter(data: PoolHaulier): string {
    return `${data.name}`;
  }

  public setSelectedHaulier() {
    if (typeof this.selectedHaulier === "string") {
      const customHaulier = new PoolHaulier();
      customHaulier.name = this.selectedHaulier;

      this.commentHaulierSelection = customHaulier;
      this.customHaulierValue = true;
      this.customTruckValue = true;
      this.trucks = [];

    } else {
      this.commentHaulierSelection = this.selectedHaulier;
      this.customHaulierValue = false;

      this.poolService.getTrucks(this.selectedHaulier.number).subscribe((trucks: PoolTruck[]) => {
        this.trucks = trucks;
      });

      this.customTruckValue = (typeof this.selectedTruck === "string") ? true : false;
    }
  }

  public truckValueFormatter(data: PoolTruck): string {
    return `${data.number} ${data.name}`;
  }

  public setSelectedTruck(): void {
    if (typeof this.selectedTruck === "string") {
      const customTruck = new PoolTruck();
      customTruck.registrationNumber = this.selectedTruck;

      this.commentTruckSelection = customTruck;
      this.customTruckValue = true;
    } else {
      this.customTruckValue = false;
      this.commentTruckSelection = this.selectedTruck;
    }
  }

  public isHaulierAndTruckVisible() {
    if (this.currentDepotInReusePb && this.currentDepotOutReusePb) {
      const pbsMerged: PlanningBlock[] = [...this.currentDepotInReusePb, ...this.currentDepotOutReusePb];
      if (pbsMerged.length === 1 && pbsMerged[0].statusType === TO_BE_PLANNED) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  public hideLabel() {
    const reuseModalLabel: HTMLElement = document.getElementById("reuseModalLabel") as HTMLElement;
    if(reuseModalLabel)
      reuseModalLabel.style.display = "none";
    }

}
