import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name : 'unavailability' })
export class UnavailabilityPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string) {
        return moment(value).format('DD-MM-YY HH:mm');
    }
}
