import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, ActivatedRoute } from '@angular/router';
import { Http } from '@angular/http';
import { HttpClientService } from '../services/httpclient';
import { ApplicationUserService } from '../../pb-screen/services/application.user.service';
import { ChannelService } from '../../pb-screen/services/channel.service';
import { HomeService } from '../../pb-screen/services/home.service';
import { ApplicationUserActions } from '../../pb-screen/application.user.actions';
import { TranslateService } from '@ngx-translate/core';
import { settingAction } from '../../setting-modal/setting.action';

@Injectable()
export class InitialGuard implements CanActivate {

  constructor(private router: Router,
    private readonly httpClientService: HttpClientService,
    private readonly applicationUserActions: ApplicationUserActions,
    private readonly settingAction: settingAction) { }

  canActivate(route: ActivatedRouteSnapshot, ): boolean {

    const loginParam = route.paramMap.get('login');
    const destinationParam = route.paramMap.get('destination');
    if (loginParam) {
      localStorage.setItem('userId', loginParam);
      this.httpClientService.getToken().subscribe((token) => {
        this.applicationUserActions.setApplicationUser(loginParam).subscribe();
        this.applicationUserActions.getDefaultPreference("defaultPbUserSettings", loginParam);
        this.applicationUserActions.getDefaultPreference("defaultToUserSettings", loginParam);
        this.applicationUserActions.getDefaultPreference("defaultOrderLineUserSettings", loginParam);
        this.settingAction.getDefaultGlobalPreference(["globalPrintSetting"]);
      })
    }
    this.router.navigateByUrl(
      this.router.createUrlTree(
        [destinationParam], { queryParams: route.queryParams}
      )
    );
    return false;
  }
}
