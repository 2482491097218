import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UserSettingsService } from "../pb-screen/services/usersettings.service";
import { environment } from "../../environments/environment";
import { Title } from "@angular/platform-browser";
import { VersionSettings } from "../pb-screen/static/client/versionSettings";
import { TranslateService } from "@ngx-translate/core";
import { MSAL_INSTANCE, MsalService , MsalBroadcastService } from '../msal';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'; 
import { MsalGuardConfiguration } from '../msal/msal.guard.config';
import { EventMessage, EventType, InteractionType, AuthenticationResult } from '@azure/msal-browser';
import { Subject } from "rxjs";
import { MSAL_GUARD_CONFIG } from "../msal/constants";
import { filter, takeUntil } from "rxjs/operators";

@Component({
  selector: 'app-main-page',
  templateUrl: './main-page.component.html'
})
export class MainPageComponent implements OnInit , OnDestroy {

  public buisnessCentralUrl: string = environment.buisnessCentralUrl;
  public landingPageUrl: string;
  public iconType: string;
  public showDepotInIcon: boolean = true;
  public showDepotOutIcon: boolean = true;
  public showDeliveryIcon: boolean = true;
  public showCollectionIcon: boolean = true;
  private readonly _destroying$ = new Subject<void>();


  public isIframe = false;
  public loggedIn = false;

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly userSettingsService: UserSettingsService,
    public readonly titleService: Title,
    private translate: TranslateService,
    private msalBroadcastService: MsalBroadcastService , 
    private authService: MsalService,
  ) {}

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;

    this.checkoutAccount();
    this.msalBroadcastService.msalSubject$
    .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
    ).subscribe((result) => {
        this.checkAccount();
        if(result.eventType == "msal:loginSuccess" ) {
        this.SetApplicationDefaults() ; 
        }
    });

    // this.checkoutAccount();

    // this.broadcastService.subscribe('msal:loginSuccess', () => {
    //   this.checkoutAccount();
    // });

    // this.authService.handleRedirectCallback((authError, response) => {
    //   if (authError) {
    //     console.error('Redirect Error: ', authError.errorMessage);
    //     return;
    //   }

    //   console.log('Redirect Success: ', response.accessToken);
    // });

    // this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    //   console.log('MSAL Logging: ', message);
    // }, {
    //   correlationId: CryptoUtils.createNewGuid(),
    //   piiLoggingEnabled: false
    // }));
   // this.login() ; 
   this.SetApplicationDefaults();
  }
 SetApplicationDefaults() { 

  const account = this.authService.instance.getAllAccounts()[0];

  if (!this.authService.instance.getActiveAccount()) {
      this.authService.instance.setActiveAccount(account);
  }


  let title = 'Go4Planning ' + VersionSettings.versionNumber + ' | Go4Tms Web Portal';
  this.titleService.setTitle(title);
  this.iconType = environment.iconType;
  this.showDepotInIcon =  environment.depotInIcon != null ? environment.depotInIcon : true ;
  this.showDepotOutIcon = environment.depotOutIcon != null ? environment.depotOutIcon : true;
  this.showDeliveryIcon =  environment.deliveryIcon != null ? environment.deliveryIcon : true ;
  this.showCollectionIcon = environment.collectionIcon != null ? environment.collectionIcon : true;
  this.userSettingsService.getGolbalSettingByName('landingPage').subscribe((result: any[]) => {
    if(result.length) {
      this.landingPageUrl = environment.hubEndpointAddress + "/App/assets/images/backgrounds/"+ result[0].value +".jpg";
    }
  });
 }

 checkoutAccount() {
  this.loggedIn = !!this.authService.getAccount();
}

login() {
  this.checkAccount() ; 
  if (!this.loggedIn) {
    this.authService.loginPopup().subscribe ((() => { 
      this.checkAccount();
      if (this.loggedIn) {
          this.SetApplicationDefaults();      }
      } )) ;
  }

else {
    this.SetApplicationDefaults();
  }
}

logout() {
  this.authService.logout();
}

  
  onRightClick(page: string, queryParams: string = '') {
    window.open(page + queryParams);
    return false;
  }

  accessCp(page:string, isRightClick: boolean){
    let url = environment.containerPlanningEndpoint + page;
    if(isRightClick)
      window.open(url, "_blank");
    else
      window.location.href = url;
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
