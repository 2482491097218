import { Component, OnInit } from "@angular/core";
import { SharedService } from "../../../services/shared.service";
import { SharedActions } from "../../../shared.actions";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { WindowsService } from "../../../models/windows-service";
import { PlanningConflict } from "../../../../pb-screen/models/planning-conflict.interface";
import { ChannelEvent } from "../../../../pb-screen/services/channel.event.factory";



@Component({
    selector: "automatic-updates",
    templateUrl: './automatic-updates.component.html'
})
export class AutomaticUpdatesComonent implements OnInit {

    @select("monitorService") public monitorService$ : Observable<WindowsService>;
    @select('channelEvents') channelEvents$: Observable<ChannelEvent[]>;

    monitorService : WindowsService;
    channelEvents: ChannelEvent[];

    constructor(private readonly sharedService: SharedService,
                private readonly sharedActions: SharedActions) { }

    ngOnInit() : void {
        this.monitorService$.subscribe((monitorService : WindowsService) => {
            console.log(monitorService);
            this.monitorService = monitorService;
        })

        this.channelEvents$.subscribe((response: ChannelEvent[]) => {
            this.channelEvents = response.reverse();
        })
    }

    public checkForService() {
        this.sharedActions.getMonitorServiceStatus();
    }

    public itemIdsFromList(list : any) {
        return list.map((to) => to.id).join(", ");
    }
}
