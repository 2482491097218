
import {map} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class HttpClientService {

  constructor(private readonly httpClient : HttpClient) {
    this.getToken();
  }

  public getToken(): Observable<any> {
    return this.httpClient.get(environment.tmsApiEndpointAddress + "token").pipe(map((response : any) => {
      let token = response;
      localStorage.removeItem('basicToken');
      localStorage.setItem('basicToken', token);
    }));
  }

  public getApiVersion(): Observable<string> {
    return this.httpClient.get(environment.tmsApiEndpointAddress + "version").pipe(map((response : any) => {
      return response;
    }));
  }

  get(url) {
    return this.httpClient.get(url);
  }


  getWithConfig(url , config) {
    return this.httpClient.get(url , config);
  }



  post(url, data, responseType?) {
    if (responseType)
      return this.httpClient.post(url, data, {responseType:responseType});
    else
      return this.httpClient.post(url, data);
  }

  put(url, data) {
    return this.httpClient.put(url, data);
  }

  delete(url) {
    return this.httpClient.delete(url);
  }

  patch(url, data) {
    return this.httpClient.patch(url,data);
  }
}