import { FileAddress } from './../../../pb-screen/models/file/file-address.interface';
import { OnInit, Component, ViewChild, ElementRef, Output } from "@angular/core";
import { select } from "@angular-redux/store";
import { ToOperationalStatusType, TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";
import { Observable } from "rxjs";
import { OrderlineService } from "../../../shared/services/orderline.service";
import { PRE_PLANNED } from "../../../pb-screen/models/status-type.interface";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { ToScreenService } from "../../../pb-screen/services/to-screen.service";
import { element } from "protractor";
import { OrderLine } from "../../../pb-screen/models/order-line/orderLine.interface";
import { ToActions } from "../../to.actions";
import { ToastrService } from "ngx-toastr";
import { ToActionBarService } from "../../../pb-screen/services/to-action-bar.service";
import { PbActions } from "../../../pb-screen/pb.actions";
import { PbScreenService } from "../../../pb-screen/services/pb-screen.service";
import { SharedActions } from "../../../shared/shared.actions";
import { PlanningConflict } from "../../../pb-screen/models/planning-conflict.interface";
import { SharedService } from "../../../shared/services/shared.service"
import * as moment from 'moment';
import { ConfirmDialogComponent } from '../../../shared/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogService } from '../../../shared/components/confirm-dialog/confirm-dialog.service';
import { printlistComponent } from '../../modals/print-list-modal/print-list-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { ToPlanningModalComponent } from '../../modals/planning-modal/to-planning-modal.component';
import { PbDetailsComponent } from '../../../pb-screen/containers/display/pb-details/pb-details.component';
import { PlanningBoardActions } from '../../../planningBoard/planningBoard.action';

export enum Actions {
    General = 0, Collection = 1, Unload = 2, Load = 3, Delivery = 4, Embark = 5, Debark = 6,
    Pickup = 7, Drop_at = 8, Couple = 9, Uncouple = 10, Shipping = 11, Train_On = 12, Train_Off = 13,
    Stock_In = 14, Stock_Out = 15, Via = 16, StartTO = 17, StopTO = 18, Driver_On = 19, DriverOff = 20, Break = 21,
    Embark_SD = 22, Debark_SD = 23, Railway = 24, Airway = 25, FA = 26, FD = 27, StartO = 28, StopO = 29, Rest = 30,
    Storage = 31, Handling = 32, DOut = 33, DIn = 34, Scan = 35, Customs = 36, Weighing = 37

}


@Component({
    moduleId: module.id,
    selector: "to-action-bar",
    templateUrl: "./to-action-bar.component.html"
})

export class ToActionBarComponent implements OnInit {
    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select('planningBlocksForTO') public planningBlocksForTO$: Observable<PlanningBlock[]>;
    @select('selectedOrderlines') public selectedOrderlines$: Observable<OrderLine[]>;
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('orderLines') public orderLines$: Observable<OrderLine[]>;
    @select('loading') public loading$: Observable<boolean>;

    @select('cancelPbBtnState') public cancelPbBtnState$: Observable<boolean>;
    @select('sendPbBtnState') public sendPbBtnState$: Observable<boolean>;
    @select('unplanPbBtnState') public unplanPbBtnState$: Observable<boolean>;
    @select('pbDetailsFromTo') public pbDetailsFromTo$: Observable<boolean>;
    @select("toPlanBtnState") public toPlanBtnState$: Observable<boolean>;

    public selectedTransportOrder: TransportOrder;
    public planningBlocksForTo: PlanningBlock[] = [];
    public selectedOrderlines: OrderLine[] = [];
    public selectedPlanningBlocks: PlanningBlock[] = [];
    public resetButtonHovered: boolean = false;
    public toPlanBtnState: boolean = false;
    private notificationButton: boolean = false;
    private cancelPbBtnState: boolean = false;
    private sendPbBtnState: boolean = false;
    private unplanPbBtnState: boolean = false;
    private unplanToBtnState: boolean = false;
    private unplanState: boolean = false;
    private tooltip: string = 'Unplan TO';
    private readyToUnplan: boolean = false;
    private sendBtnState: boolean = false;
    private pbDetailsFromTo: boolean = false;
    public orderLines: OrderLine[] = [];
    public displayConflicts: boolean = false;
    public ConflictsList: PlanningConflict[];
    private LastCallBack: Function;
    private orderlineModalState: boolean = false;
    public sendToState: boolean = false;
    public loadingSpinner : boolean = false ; 

    public assignCapacityState : boolean = false;

    constructor(private readonly orderlineService: OrderlineService,
        private readonly pbScreenService: PbScreenService,
        private readonly toService: ToScreenService,
        private readonly toActions: ToActions,
        private readonly sharedActions: SharedActions,
        private readonly toastr: ToastrService,
        private readonly toActionBarService: ToActionBarService,
        private readonly sharedService: SharedService,
        private readonly pbActions: PbActions,
        private readonly  simpleModalService: SimpleModalService,
        private readonly confirmDialogService: ConfirmDialogService,
        private readonly planningBoardActions: PlanningBoardActions) { }

  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.selectedTransportOrders$.subscribe((transpordOders: TransportOrder[]) => {
            this.sendToState = false;
            
            this.selectedTransportOrder = transpordOders[0];
            if (this.selectedTransportOrder) {
                this.planningBlocksForTO$.subscribe((data) => {
                    this.planningBlocksForTo = data;

                    if (this.selectedTransportOrder && this.planningBlocksForTo) {
                        this.readyToUnplan = this.planningBlocksForTo.every((element, index, array) => {

                            return element.status.statusType === PRE_PLANNED;
                        });
                    }
                    
                })

                if (this.selectedTransportOrder.haulierName !== "") {
                    this.sendToState = true;
                }
            }


            if(this.selectedTransportOrder)
            {
                if(this.selectedTransportOrder.toOperationalStatusType !== ToOperationalStatusType.InProgress && this.selectedTransportOrder.toOperationalStatusType !== ToOperationalStatusType.Closed ) 
                {
                    this.assignCapacityState = true;
                } else {
                    this.assignCapacityState = false ;
                }
            } else {
                this.assignCapacityState = false;
            }


        })



        this.orderLines$.subscribe((orderlines) => {
            
            if (orderlines) {
                this.sendBtnState = orderlines.every((element, index, array) => {
                    return element.sentToHaulier === 1;
                });
                
                /*this.sendNextPbBtnState = orderlines.every((element,index,array)=> {
                     return element.sentToHaulier === 1;
                 })*/
            }
        })


        this.toPlanBtnState$.subscribe((toPlanBtnState: boolean) => {
            this.toPlanBtnState = toPlanBtnState;
        });

        this.selectedPlanningBlocks$.subscribe((planningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = planningBlocks;
        })

        this.cancelPbBtnState$.subscribe((res) => {
            this.cancelPbBtnState = res;
        })

        this.sendPbBtnState$.subscribe((res) => {
            this.sendPbBtnState = res;
        })

        this.pbDetailsFromTo$.subscribe((res) => {
            this.pbDetailsFromTo = res;
        })

        this.unplanPbBtnState$.subscribe((res) => {
            this.unplanPbBtnState = res;
        })


        this.selectedOrderlines$.subscribe((orderlines: OrderLine[]) => {
            if (orderlines) {
                this.selectedOrderlines = orderlines;
                this.setBtnStates();
                this.tooltip = 'Unplan Selected Planning Blocks';

                this.setOrderlineSelectionButtonStates();
            }
        })

    }


    private setOrderlineSelectionButtonStates() {
        this.unplanState = this.selectedOrderlines.length > 0 ? true : false;
        this.notificationButton = this.selectedOrderlines.length === 1 ? true : false;
        this.orderlineModalState = this.selectedOrderlines.length === 1 ? true : false;
    };

    IsNoPlanningRequiredEnabled() {
        if (this.selectedOrderlines.length > 0) {
            for (var i in this.selectedOrderlines) {
                let orderLine = this.selectedOrderlines[i];
                if (!(orderLine.actionType == Actions.Drop_at || orderLine.actionType == Actions.Pickup || Actions.DOut == orderLine.actionType ||
                    Actions.DIn == orderLine.actionType ||
                    Actions.Uncouple == orderLine.actionType || Actions.Couple == orderLine.actionType)) {
                    return false;
                }
                if (orderLine.orderNumber.toString() == "") {
                    return false;
                }
            }
            return true;
        }
        else
            return false;
    }

    CancelOperation() {
        this.displayConflicts = false;
        this.ConflictsList = [];
    }

    ConfirmOperation() {
        this.displayConflicts = false;
        this.ConflictsList = [];
        this.LastCallBack.call(null);


    }
    NoPlanningRequired() {
        this.toService.NoPlanningRequired(this.selectedTransportOrder, this.selectedOrderlines).subscribe((data) => {
            if (data == "") {
                this.toastr.info("Operation Success");
            }
            else {
                this.toastr.error("Error remove lines :" + data);
            }


            if (this.selectedTransportOrder) {
               this.toService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines: any[]) => {
                    this.sharedActions.getOrderlines(orderlines);
                })
                this.planningBlocksForTO$.subscribe((data) => {
                    this.planningBlocksForTo = data;
                    this.pbActions.updatePlanningBlocksDisplay(data);
                })

                this.resetSelection();

            }
        })
    }

    sendNextPb() {
        let planningBlockIds = [];
        this.toService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines) => {

            this.sharedActions.getOrderlines(orderlines);
            orderlines.forEach((value, index) => {
                if (!value.sentToHaulier) {
                    if (planningBlockIds.indexOf(value.planningBlockId) === -1)
                        planningBlockIds.push({ id: value.planningBlockId });
                }

            });
            this.CheckConflicts(() => { 
                this.orderlineService.sendNextPb(this.selectedTransportOrder).subscribe();
            }, planningBlockIds);
        });

    }

    sendTo() {
        if (this.selectedTransportOrder.sentToBc === 1) {
            let date = new Date(this.selectedTransportOrder.toSentDateTime);
            this.confirmDialogService.confirmThis("The selected To has been already sent to BC on : "
                + moment(date).format("L LT") +
                " . Do you want to send it again ?", () => {
                    this.CheckConflicts(() => {
                        this.sendToHaulier([this.selectedTransportOrder.id]);
                    }, null);
                }, () => {

                })
        }
        else {
            this.CheckConflicts(() => {
                this.sendToHaulier([this.selectedTransportOrder.id]);
            }, null);
        }
    }

    sendToHaulier(toNumbers:string[]) {
        this.toService.sendToToHaulier(toNumbers).subscribe((res: TransportOrder[]) => {
            this.toActions.updateTransportOrdersDisplay(res);
            this.toastr.success('The transport order has successfully been sent');
        })
    }


    CheckConflicts(callback, pbArray: any) {
        this.sharedService.calculatePlanningBlockConflicts(this.selectedTransportOrder.id).subscribe((conflictData) => {
            if (pbArray == null && conflictData.length > 0) {
                this.displayConflicts = true;
                this.ConflictsList = conflictData;
                this.LastCallBack = callback;
            }
            else if (conflictData.length == 0) {
                this.displayConflicts = false;
                this.ConflictsList = null;
                this.LastCallBack = null;
                callback.call(null);
            }
            else if (pbArray != null) {
                let pbConficts = [];

                for (const pbIndex in pbArray) {
                    for (const conflictIndex in conflictData) {
                        if (conflictData[conflictIndex].pbId == pbArray[pbIndex].id.toString()) {
                            pbConficts.push(conflictData[conflictIndex]);
                        }
                    }
                }

                if (pbConficts.length > 0) {
                    this.displayConflicts = true;
                    this.ConflictsList = conflictData;
                    this.LastCallBack = callback;
                }
                else {
                    this.displayConflicts = false;
                    this.ConflictsList = null;
                    this.LastCallBack = null;
                    callback.call(null);
                }
            }
        });
    }

    cancelTo() {
        this.orderlineService.cancelTo(this.selectedTransportOrder).subscribe((res) => {
            this.pbActions.updatePlanningBlocksDisplay(res.planningBlocks);
            this.readyToUnplan = true;
        });
    }

    unplanForSelectedOrderlines() {
        let planningBlockIds = this.selectedOrderlines.map(ol => ol.planningBlockId);
        let selectedPlanningBlocks = this.planningBlocksForTo.filter((pb) => planningBlockIds.includes(pb.id) && pb.status.statusType === PRE_PLANNED);
        this.pbScreenService.unplanPlanningBlocks(selectedPlanningBlocks, false).subscribe((planningBlocks: PlanningBlock[]) => {
            this.pbActions.updatePlanningBlocksDisplay(planningBlocks);
            this.toActions.setSelectedTransportOrders([]);
            this.toastr.success("The planning block has successfully been unplanned");
            this.resetSelection();
            this.closeModal();
        });
    }

    cancelPbs() {
        if (this.planningBlocksForTo && this.selectedOrderlines) {
            this.orderlineService.cancelPb(this.selectedPlanningBlocks).subscribe();
        }
    }

    unplanPbs() {
        if (this.planningBlocksForTo && this.selectedOrderlines) {
            this.pbScreenService.unplanPlanningBlocks(this.selectedPlanningBlocks, false).subscribe((planningBlocks: PlanningBlock[]) => {
                if (this.planningBlocksForTo.length === planningBlocks.length) {
                    this.orderlineService.deleteTo(this.selectedTransportOrder, '');
                    this.closeModal();
                }
            })
            this.closeModal();
        }

    }

    sendPbs() {
        if (this.planningBlocksForTo && this.selectedOrderlines) {
            this.CheckConflicts(() => {
                this.orderlineService.sendPb(this.selectedPlanningBlocks).subscribe((data) => {
                    this.toService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines) => {
                        this.sharedActions.getOrderlines(orderlines);
                    })
                });
            }, this.selectedPlanningBlocks);
        }
    }

    setBtnStates() {
        if (this.planningBlocksForTo && this.selectedOrderlines) {
            let planningBlockIds = this.selectedOrderlines.map(ol => ol.planningBlockId);
            let selectedPlanningBlocks = this.planningBlocksForTo.filter((pb) => planningBlockIds.includes(pb.id));
            this.unplanToBtnState = selectedPlanningBlocks.every((element, index, array) => {
                return element.status.statusType === PRE_PLANNED;
            });
        }
    }

    setPbBtnStatus() {
        this.planningBlocksForTo.forEach((element) => {
            this.selectedOrderlines.forEach((orderline) => {
                if (element.id === orderline.planningBlockId && !this.selectedPlanningBlocks.includes(element)) {

                    this.selectedPlanningBlocks.push(element);
                }
            })
        })
    }

    public unplanTO() {
        this.orderlineService.unplanTo(this.planningBlocksForTo, this.selectedTransportOrder).subscribe((res) => {
            this.resetSelection();
            this.toActions.setSelectedTransportOrders([]);
        });
    }

    resetSelection() {
        this.toActions.setSelectedOrderlines([]);
        this.unplanToBtnState = false;
        this.toActionBarService.resetActionBar();
    }

    private closeModal(): void {
        this.modal.nativeElement.click();
        this.resetSelection();

    }
    public ScreenName() {
        this.sharedService.ScreenName = 'TO-Screen-Details';
    }

    public openPrintModal(){
        this.ScreenName();
        this.simpleModalService.addModal(printlistComponent, {})
          .subscribe(()=>{ });
    }

    public setSelectedPb() {
        let pbId = [this.selectedOrderlines[0].planningBlockId];
        this.pbScreenService.getPlanningBlocksByIds(pbId).subscribe((data: PlanningBlock[]) => {
            this.pbActions.setSelectedPlanningBlocks([...data]);
        })
        this.simpleModalService.addModal(PbDetailsComponent)
          .subscribe(()=>{ });
    }


    public groupSelectedLines() {
        this.loadingSpinner = true ; 
        var lineOrderIds = this.selectedOrderlines.map(x => x.orderLineId).join("%7C");

        if (this.selectedOrderlines.length > 1) {
            this.orderlineService.groupSelectedLines(this.selectedTransportOrder, this.selectedTransportOrder.id, lineOrderIds).subscribe((data) => {


                this.toService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines: any[]) => {

                    this.sharedActions.getOrderlines(orderlines);
                });
                this.toActions.setSelectedOrderlines([]);
                this.toastr.success('Group Lines', 'The selected lines are successfully grouped');

                this.loadingSpinner = false ; 
            }, (err) => {
                this.loadingSpinner = false ; 
            })
        } else {
            this.toastr.warning('Group Lines', 'Please select minimum 2 lines to group')
            this.loadingSpinner = false ; 
        }
    }


    public ungroupSelectedLines() {
        this.loadingSpinner = true ; 
        var lineOrderIds = this.selectedOrderlines.map(x => x.orderLineId).join("%7C");

        if (this.selectedOrderlines.length > 1) {
            this.orderlineService.unGroupSelectedLines(this.selectedTransportOrder, this.selectedTransportOrder.id, lineOrderIds).subscribe((data) => {
                this.toService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines: any[]) => {
                    this.sharedActions.getOrderlines(orderlines);

                });

                this.toActions.setSelectedOrderlines([]);
                this.selectedPlanningBlocks = [];
                this.resetSelection();
                this.toastr.success('Ungroup Lines', 'The selected lines are successfully ungrouped')
                this.loadingSpinner = false ; 

            }, (err) => {
                this.loadingSpinner = false ; 
            })
        } else {
            this.toastr.warning('Ungroup Lines', 'Please select minimum 2 lines to ungroup')
            this.loadingSpinner = false ; 
        }
    }

    
    public setSelectedTOForCapacityModal(){
        this.toActions.setSelectedTransportOrders([this.selectedTransportOrder]);
        
        this.simpleModalService.addModal(ToPlanningModalComponent, {})
          .subscribe(()=>{ });
    }

    public recalculateTo(){
        this.loadingSpinner = true;
        this.toService.recalculateTO(this.selectedTransportOrder.id).subscribe((result) => {
            this.loadingSpinner = false;
            this.planningBoardActions.UpdatePlanningDisplay([result]);
            this.toastr.success("TO has been recalculated");

        });
    }


}
