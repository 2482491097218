import { select } from "@angular-redux/store";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { ComponentSource, ModalType } from "../../models/component-source";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { SimpleModalComponent } from "ngx-simple-modal";
import { TarTasService } from "../../services/tartas.service";
import { ToastrService } from "ngx-toastr";
import * as moment from "moment";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { SharedService } from "../../services/shared.service";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs-compat";
import { TruckAppointmentBookingTabs, TruckAppointmentLine } from "../../models/tar-tas/tar-tas.interface";
import { PbActions } from "../../../pb-screen/pb.actions";
import { PbScreenService } from "../../../pb-screen/services/pb-screen.service";

@Component({
    selector: "truck-appointment-modal",
    templateUrl: "./truck-appointment-modal.component.html",
})
export class TruckAppointmentModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public depotOutSelection: PlanningBlock[] = [];
    public depotInSelection: PlanningBlock[] = [];
    public selectedTimeSlot: string = "";
    public planDate: string;
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public fallbackLanguage: string = "en";
    public languageToUse: string = "en";
    public TruckAppointmentBookingTabs = TruckAppointmentBookingTabs;
    public selectedTab: TruckAppointmentBookingTabs = TruckAppointmentBookingTabs.Main;
    selectedPlanningBlocks: PlanningBlock[] = [];
    sharedSelectedPlanningBlocks: PlanningBlock[] = [];
    destroy$ = new Subject();
    depotInLines: TruckAppointmentLine[];
    depotOutLines: TruckAppointmentLine[];

    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select("sharedSelectedPlanningBlocks") public sharedSelectedPlanningBlocks$: Observable<PlanningBlock[]>;

    constructor(
        private readonly translate: TranslateService,
        private readonly tarTasService: TarTasService,
        private readonly toastrService: ToastrService,
        private readonly sharedService: SharedService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        public pbActions: PbActions,
        private readonly pbService: PbScreenService
    ) {
        super()
        translate.setDefaultLang(this.fallbackLanguage);
        translate.use(this.languageToUse);
    }

    public ngOnInit() {
        this.depotOutSelection$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotOutSelection = selectedPlanningBlocks;
        });
        this.depotInSelection$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotInSelection = selectedPlanningBlocks;
        });
        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = selectedPlanningBlocks;
            this.setPlanningBlocks();
        });
        this.sharedSelectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((planningBlocks: PlanningBlock[]) => {
            this.sharedSelectedPlanningBlocks = planningBlocks;
            this.setPlanningBlocks();
        });
    }

    setPlanningBlocks() {
        let planningBlocks = this.selectedPlanningBlocks.concat(this.sharedSelectedPlanningBlocks);
        if(planningBlocks.length)
        {
            this.depotOutSelection = planningBlocks.filter(pb => pb.dOutOrderLineID);
            this.depotInSelection = planningBlocks.filter(pb => pb.dInOrderLineID);
        }
    }
    
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public closeModal() {
        this.close();
    }

    public getSelectedTimeSlot(val) {
        this.selectedTimeSlot = val;
    }

    public getSelectedPlanDate(planDate) {
        this.planDate = planDate;
    }

    public translateString(text: string) {
        return this.translate.get(text).first().toPromise();
    }

    createAppointments(grouped = false)
    {
        let depotInOrderlineIds = this.depotInSelection.map(el => el.dInOrderLineID);
        let depotOutOrderlineIds = this.depotOutSelection.map(el => el.dOutOrderLineID);
        let splitedDates = this.selectedTimeSlot.split("-");
        if(splitedDates.length != 2)
        {
            this.toastrService.warning("Please select time slot");
            return;
        }
        var from = moment(this.planDate+ " " + splitedDates[0], "YYYY-MM-DD HH:mm");
        var until = moment(this.planDate+ " " + splitedDates[1], "YYYY-MM-DD HH:mm");

        this.tarTasService.createTruckAppointment(depotInOrderlineIds.concat(depotOutOrderlineIds),
            from.toISOString(), until.toISOString(), grouped)
            .subscribe(
                result => {
                    this.toastrService.success("Truck Appointment created");
                    let inPbIds = this.depotInSelection.map(el => el.id);
                    let outPbIds = this.depotOutSelection.map(el => el.id);
                    this.sharedService.getPlanningBlocksByIds(inPbIds.concat(outPbIds)).subscribe((planningBlocks: PlanningBlock[]) => {
                        let partsPBs = _.partition(planningBlocks, (pb: PlanningBlock) => {
                            var index = inPbIds.findIndex(el => el == pb.id);
                            return index != -1;
                        });

                        if(partsPBs[0].length)
                            this.depotInActions.updatePlanningBlocks(partsPBs[0]);
                        if(partsPBs[1].length)
                            this.depotOutActions.updatePlanningBlocks(partsPBs[1]);
                      });

                    this.pbService.getPlanningBlocksByIds(inPbIds.concat(outPbIds))
                        .subscribe((pbs)=> {
                            this.pbActions.updatePlanningBlocksDisplay(pbs);
                        })
                    this.closeModal();
                });
    }

    createGroupedAppointment(){
        this.createAppointments(true);
    }

    linkToAppointment()
    {
        this.depotInLines = [];
        this.depotInSelection.forEach(el => {
            let line = new TruckAppointmentLine();
            line.truckAppointmentNo = '';
            line.truckAppointmentAddressId = el.dInTruckAppointmentAddressID;
            line.truckAppointmentSystem = el.dInTruckAppointmentSystem;
            line.orderlineId = el.dInOrderLineID;
            line.pbEntryNo = el.id;
            this.depotInLines.push(line);
        });
        this.depotOutLines = [];
        this.depotOutSelection.forEach(el => {
            let line = new TruckAppointmentLine();
            line.truckAppointmentNo = '';
            line.truckAppointmentAddressId = el.dOutTruckAppointmentAddressID;
            line.truckAppointmentSystem = el.dOutTruckAppointmentSystem;
            line.orderlineId = el.dOutOrderLineID;
            line.pbEntryNo = el.id;
            this.depotOutLines.push(line);
        })
        this.selectedTab = TruckAppointmentBookingTabs.Link;
    }

    goToMain(refresh: boolean){
        this.selectedTab = TruckAppointmentBookingTabs.Main;
    }

}
