import { Component, OnInit, ViewChild, ElementRef, Input } from "@angular/core";
import { select } from "@angular-redux/store";

import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { SharedActions } from "../../../shared.actions";
import { SharedService } from "../../../services/shared.service";
import { Observable } from "rxjs";
import { ModalType, ComponentSource } from "../../../models/component-source";
import { ModalManagerService } from "../../../services/modal-manager.service";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { MsalService } from "../../../../msal/msal.service";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";


@Component({
    selector: "weighing-modal",
    templateUrl: "weighing-modal.component.html",
})

export class WeighingModalComponent implements OnInit {
    public selectedPb: PlanningBlock[];
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public weight: number;
    public loadingSpinner: boolean = false;

    @Input() public source: string;
    @ViewChild("modal",{static: true}) private modal: ElementRef;
    @select("depotInSelection") private depotInSelection$: Observable<PlanningBlock[]>;
    @select("depotOutSelection") private depotOutSelection$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;

    constructor(private readonly sharedService: SharedService,
        private readonly sharedActions: SharedActions,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService) { }

  public ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })

    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });
      
        this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            if ((selectedPlanningBlocks[0]) && this.source === this.componentSource.depotIn) {
                this.selectedPb = selectedPlanningBlocks;
                this.weight = selectedPlanningBlocks[0].actualWeight !== 0 ? selectedPlanningBlocks[0].actualWeight : null;
            }
        });
        this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            if ((selectedPlanningBlocks[0]) && this.source === this.componentSource.depotOut) {
                this.selectedPb = selectedPlanningBlocks;
                this.weight = selectedPlanningBlocks[0].actualWeight !== 0 ? selectedPlanningBlocks[0].actualWeight : null;
            }
        });
    }
    public setWeigh() {
        const account = this.authService.getAccount(); 
        this.loadingSpinner = true;
        this.sharedService.setWeight(this.selectedPb[0].id, 
            this.weight, account.username).subscribe((planningBlock: PlanningBlock) => {
            this.loadingSpinner = false;
            this.sharedActions.updatePlanningBlocksDisplayCpl([planningBlock]);
            this.translate.get('TOASTR_MESSAGES.SUCCESS.WEIGHING_SUCCEEDED').subscribe((res: string) => {
                this.toastr.success(res, "", {
                    closeButton: false
                });
                this.closeModal();
            });
        }, (err) => {
            this.loadingSpinner = false;
        })
        
    }

    public closeModal() {
        this.sharedActions.resetModalDomains();
        this.weight = 0;
        this.sharedActions.setUiState(true);
        this.modalManager.closeModal(this.modalType.weigh);
    }
}
