
import {throwError as observableThrowError,  Observable, Subject, VirtualTimeScheduler } from 'rxjs';
import { HttpClientService } from "../../shared/services/httpclient";
import { Injectable } from "@angular/core";
import { UserSettings, Setting, View } from "../models/application.user.interface";
import { environment } from '../../../environments/environment';
import { Response } from '@angular/http';
import { map, catchError, filter, publishReplay } from 'rxjs/operators';
import { ActivatedRoute } from "@angular/router";
@Injectable()
export class UserSettingsService {

    private userSettings = [];
    private settings = [];
    private subject = new Subject<any>();
    private userSettingReturn = new Observable<UserSettings[]>();
    private settingReturn = new Subject<any>();
    private subSetting: Setting;
    private view: string;

    constructor(private route: ActivatedRoute,
        private readonly http: HttpClientService) {
      this.subscribeToViewParam();
    }

    private subscribeToViewParam() {
      this.route.queryParamMap.subscribe(queryParams => {
        this.view = queryParams.get("view");
      });
    }

    private getViewParam(){
      if(this.view)
        return '&view=' + this.view;
      return '';
    }

    public getView() {
      return this.view;
    }

    getSettings(): Observable<Setting[]> {
        return this.http.get(environment.tmsUserApiEndPointAddress + 'settings').pipe(
            map((res: any) => {
                console.log(res.data);
                return res.data;
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        )
    }

    getUserSettings(userId): Observable<UserSettings[]> {
        return this.http
            .get(environment.tmsUserApiEndPointAddress + 'usersettings?scope=module&scopeValue=planning&userId=' + userId + this.getViewParam())
            .pipe(
                map((response: any) => {
                    return response.data;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }

    getUserSettingByName(name, userId): Observable<UserSettings[]> {
        return this.http
            .get(environment.tmsUserApiEndPointAddress + 'usersettings?scope=module&scopeValue=planning&userId=' + userId + this.getViewParam())
            .pipe(
                map((response: any) => {
                    let result = response.data.filter((item: UserSettings) => {
                        return item.setting.name === name;
                    });
                    return result;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }
    getGolbalSettingByName(name): Observable<UserSettings[]> {
        return this.http
            .get(environment.tmsUserApiEndPointAddress + 'usersettings?scope=module&scopeValue=planning' + this.getViewParam())
            .pipe(
                map((response: any) => {
                    let result = response.data.filter((item: UserSettings) => {
                        return item.setting.name === name;
                    });
                    return result;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }
    getGolbalSettings(): Observable<UserSettings[]> {
        return this.http
            .get(environment.tmsUserApiEndPointAddress + 'usersettings?scope=module&scopeValue=planning' + this.getViewParam())
            .pipe(
                map((response: any) => {
                    return response.data;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }
    getSettingByName(settingName): Observable<Setting> {
        return this.http.get(environment.tmsUserApiEndPointAddress + 'settings').pipe(
            map((res: any) => {
                return res.data.filter((item: Setting) => {
                    return item.name === settingName;
                })[0];
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        )
    }

    createOrUpdateUserSettings(usersettings: UserSettings, currentAppUserId): Observable<any> {
      let settingViewName = usersettings.view ? usersettings.view.name : null;
      if(!usersettings.userId || settingViewName != this.view)
        return this.createUserSettings(currentAppUserId, usersettings.setting, usersettings.value, null, null);
      return this.updateUserSettings(usersettings);
    }

    createOrUpdateDefaultUserSettings(usersettings: UserSettings): Observable<any> {
      let settingViewName = usersettings.view ? usersettings.view.name : null;
      if( settingViewName != this.view)
        return this.createUserSettings(null, usersettings.setting, usersettings.value, null, null);
      return this.updateUserSettings(usersettings);
    }

    createUserSettings(userId, setting: Setting, value, scope, scopeValue): Observable<UserSettings> {
        scope = scope || "Module";
        scopeValue = scopeValue || "Planning";
        return this.http.post(environment.tmsUserApiEndPointAddress + 'usersettings', {
            "setting": setting,
            "value": value,
            "scope": scope,
            "scopeValue": scopeValue,
            "userId": userId,
            "view": this.view ? {name: this.view} : null
        }).pipe(
            map((response: any) => {
                return response.data
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        )

    }
    createGlobalSettings(setting: Setting, value, scope, scopeValue): Observable<UserSettings> {
        scope = scope || "Module";
        scopeValue = scopeValue || "Planning";
        return this.http.post(environment.tmsUserApiEndPointAddress + 'usersettings', {
            "setting": setting,
            "value": value,
            "scope": scope,
            "viewName": this.view,
            "scopeValue": scopeValue,
        }).pipe(
            map((response: any) => {
                return response.data
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        )
    }
    updateUserSettings(userSetting: UserSettings): Observable<any> {
        return this.http.put(environment.tmsUserApiEndPointAddress + 'usersettings', userSetting).pipe(
            map(
                (response:any) => {
                    return response;
                }
            ), catchError((error) => {
                return observableThrowError(error);
            })
        )
    }
    CopyUserSetting(DestinationId: string, sourceId: string): Observable<UserSettings> {
        return this.http.get(environment.tmsUserApiEndPointAddress + 'usersettings/copy?DestinationId='+ DestinationId + '&SourceId=' + sourceId)
        .pipe(
            map((response: any) => {
                return response
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        )
    }


    getViews(): Observable<View[]> {
      return this.http.get(environment.tmsUserApiEndPointAddress + 'views').pipe(
          map((res: any) => {
              return res.data;
          }),
          catchError((error) => {
              return observableThrowError(error);
          })
      )
  }
}
