import { select } from "@angular-redux/store";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { ComponentSource, ModalType } from "../../models/component-source";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { SimpleModalComponent } from "ngx-simple-modal";
import { TarTasService } from "../../services/tartas.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { TarTasStatus, TruckAppointmentBookingTabs, TruckAppointmentLine } from "../../models/tar-tas/tar-tas.interface";
import { Actions } from "../../../services/printticket.service";
import { SharedService } from "../../services/shared.service";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";
import * as moment from "moment";
import { PbActions } from "../../../pb-screen/pb.actions";
import { PbScreenService } from "../../../pb-screen/services/pb-screen.service";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

@Component({
    selector: "truck-appointment-booking-modal",
    templateUrl: "./truck-appointment-booking-modal.component.html",
})
export class TruckAppointmentBookingModalComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {
    public depotOutSelection: PlanningBlock[] = [];
    public depotInSelection: PlanningBlock[] = [];
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public fallbackLanguage: string = "en";
    public languageToUse: string = "en";
    public TruckAppointmentBookingTabs = TruckAppointmentBookingTabs;
    public selectedTab: TruckAppointmentBookingTabs = TruckAppointmentBookingTabs.Main;

    public status: string;
    public error: string;
    public code: string;
    public address: string;
    public bookedDateTimeUntil: string;
    public bookedDateTimeFrom: string;
    public loading: boolean = false;

    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;
    @select("sharedSelectedPlanningBlocks") public sharedSelectedPlanningBlocks$: Observable<PlanningBlock[]>;
    destroy$ = new Subject();
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    
    public depotInTruckAppointmentLines: TruckAppointmentLine[] = [];
    public depotOutTruckAppointmentLines: TruckAppointmentLine[] = [];
    public checkedDepotInLines: TruckAppointmentLine[] = [];
    public checkedDepotOutLines: TruckAppointmentLine[] = [];
    selectedPlanningBlocks: PlanningBlock[] = [];
    sharedSelectedPlanningBlocks: PlanningBlock[] = [];

    constructor(
        private readonly translate: TranslateService,
        private readonly tarTasService: TarTasService,
        private readonly toastrService: ToastrService,
        private readonly sharedService: SharedService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        public pbActions: PbActions,
        private readonly pbService: PbScreenService
    ) {
        super()
        translate.setDefaultLang(this.fallbackLanguage);
        translate.use(this.languageToUse);
    }

  public ngOnInit() {
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loading = data;
    })

    this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });
        this.depotOutSelection$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotOutSelection = selectedPlanningBlocks;
            this.setDepotOutSelection();
        });
        this.depotInSelection$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotInSelection = selectedPlanningBlocks;
            this.setDepotInSelection();
        });
        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = selectedPlanningBlocks;
            if(this.selectedPlanningBlocks.length)
                this.setPlanningBlocks();
        });
        this.sharedSelectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((planningBlocks: PlanningBlock[]) => {
            this.sharedSelectedPlanningBlocks = planningBlocks;
            if(this.sharedSelectedPlanningBlocks.length)
                this.setPlanningBlocks();
        });
    }

    setDepotInSelection() 
    {
        if(this.depotInSelection.length)
        {
            this.status = TarTasStatus[this.depotInSelection[0].dInTruckAppointmentStatus];
            this.error = this.depotInSelection[0].dInTruckAppointmentError;
            this.code = this.depotInSelection[0].dInTruckAppointmentNo;
            this.getTruckAppointmentLines(this.depotInSelection[0].dInOrderLineID);
        }
    }

    setDepotOutSelection() 
    {
        if(this.depotOutSelection.length)
        {
            this.status = TarTasStatus[this.depotOutSelection[0].dOutTruckAppointmentStatus];
            this.error = this.depotOutSelection[0].dOutTruckAppointmentError;
            this.code = this.depotOutSelection[0].dOutTruckAppointmentNo;
            this.getTruckAppointmentLines(this.depotOutSelection[0].dOutOrderLineID);
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    setPlanningBlocks() {
        let planningBlocks = this.selectedPlanningBlocks.concat(this.sharedSelectedPlanningBlocks);
        if(planningBlocks.length)
        {
            this.depotOutSelection = planningBlocks.filter(pb => pb.dOutOrderLineID);
            this.depotInSelection = planningBlocks.filter(pb => pb.dInOrderLineID);
        }
        if(this.depotInSelection.length)
            this.setDepotInSelection();
        else if(this.depotOutSelection.length)
            this.setDepotOutSelection();
    }

    getTruckAppointmentLines(dOrderLineID: number) {
        this.loading = true;
        this.tarTasService.getTruckAppointmentLines(dOrderLineID)
            .subscribe(
                result => {
                    if(!result.length)
                    {
                        this.loading = false;
                        this.depotInTruckAppointmentLines = [];
                        this.depotOutTruckAppointmentLines = [];
                        this.toastrService.error("No lines for Truck appointment no.: " + this.code + ". The appointment will be automatically deleted after 15 minutes.");
                        return;
                    }
                    var partiotioned = _.partition(result, (line: TruckAppointmentLine) => line.actionType == Actions.DEPOT_IN);
                    this.depotInTruckAppointmentLines = partiotioned[0];
                    this.depotOutTruckAppointmentLines = partiotioned[1];
                    this.address = result[0].truckAppointmentAddressId;
                    this.bookedDateTimeFrom =  moment.utc(result[0].bookedDateTimeFrom).local().format("yyyy-MM-DDTHH:mm");
                    this.bookedDateTimeUntil =  moment.utc(result[0].bookedDateTimeUntil).local().format("yyyy-MM-DDTHH:mm");
                    this.loading = false;
                });
    }

    cancelAppointment(){
        var orderlineId = this.depotInSelection.length ? 
                this.depotInSelection[0].dInOrderLineID : this.depotOutSelection[0].dOutOrderLineID;
        this.tarTasService.cancelTruckAppointment(orderlineId)
            .subscribe(
                result => {
                    this.toastrService.success("Truck Appointment cancelled");
                    let inPbIds = this.depotInSelection.map(el => el.id);
                    let outPbIds = this.depotOutSelection.map(el => el.id);
                    let pbIds = inPbIds.concat(outPbIds);
                    this.sharedService.getPlanningBlocksByIds(pbIds).subscribe((planningBlocks: PlanningBlock[]) => {
                        let partsPBs = _.partition(planningBlocks, (pb: PlanningBlock) => {
                            var index = inPbIds.findIndex(el => el == pb.id);
                            return index != -1;
                        });
                        if(partsPBs[0].length)
                            this.depotInActions.updatePlanningBlocks(partsPBs[0]);
                        if(partsPBs[1].length)
                            this.depotOutActions.updatePlanningBlocks(partsPBs[1]);
                      });

                    this.pbService.getPlanningBlocksByIds(pbIds)
                        .subscribe((pbs)=> {
                            this.pbActions.updatePlanningBlocksDisplay(pbs);
                        });
                });
    }

    swapAppointment(){
        this.selectedTab = TruckAppointmentBookingTabs.Swap;
    }

    goToMain(refresh: boolean){
        if(refresh)
            this.refreshTruckAppointmentLines();
        this.selectedTab = TruckAppointmentBookingTabs.Main;
    }

    public closeModal() {
        this.close();
    }

    disconnect(){
        let lines = this.checkedDepotInLines.concat(this.checkedDepotOutLines);
        if(!lines.length)
        {
            this.toastrService.error("Truck Appointment Lines not selected")
            return;
        }
        let orderlineIds = lines.map(el => el.orderlineId);
        this.tarTasService.disconnectContainers(orderlineIds, lines[0].truckAppointmentNo)
            .subscribe(
                result => {
                    let pbIds = lines.map(el => el.pbEntryNo);
                    this.sharedService.getPlanningBlocksByIds(pbIds)
                        .subscribe((planningBlocks) => {
                            this.depotInActions.updatePlanningBlocks(planningBlocks);
                            this.depotOutActions.updatePlanningBlocks(planningBlocks);
                        });

                    this.pbService.getPlanningBlocksByIds(pbIds)
                        .subscribe((pbs)=> {
                            this.pbActions.updatePlanningBlocksDisplay(pbs);
                        });
                    this.refreshTruckAppointmentLines();
                    this.toastrService.success("Containers disconnected.");
                });
    }

    refreshTruckAppointmentLines() {
        let orderlineId;
        if(this.depotInSelection.length)
            orderlineId = this.depotInSelection[0].dInOrderLineID;
        else if(this.depotOutSelection.length)
            orderlineId = this.depotOutSelection[0].dOutOrderLineID;
        if(orderlineId)
            this.getTruckAppointmentLines(orderlineId);
        else
        {
            this.depotInTruckAppointmentLines = [];
            this.depotOutTruckAppointmentLines = [];
            this.toastrService.error("No lines for Truck appointment no.: " + this.code + ". The appointment will be automatically deleted after 15 minutes.");
        }
    }

    move(){
        this.selectedTab = TruckAppointmentBookingTabs.Move;
    }

}
