import { NgRedux, select } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";

import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { IAppState } from "../../../store/IAppState";
import { ComponentSource, ModalType } from "../../models/component-source";
import { ActionBarService } from "../../services/action-bar.service";
import { SharedActions } from "../../shared.actions";
import { ModalManagerService } from "../../services/modal-manager.service";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { environment } from "../../../../environments/environment";
import { TarTasService } from "../../services/tartas.service";
import { TarTasManager } from "../../services/tartas-manager.service";
import { ContainerReuseComponent } from "../modals/container-reuse-modal/container-reuse-modal.component";
import { SimpleModalService } from "ngx-simple-modal";


@Component({
  selector: "depot-action-bar",
  templateUrl: "./action-bar.component.html",
})
export class ActionBarComponent implements OnInit {

  @Output() public disableUi = new EventEmitter();
  @Input() public source: string;

  @select("planState") private planState$: Observable<boolean>;
  @select("depotOutPlanState") private depotOutPlanState$: Observable<boolean>;
  @select("depotInPlanState") private depotInPlanState$: Observable<boolean>;
  @select("uiState") private uiState$: Observable<boolean[]>;
  @select("depotOutStatusState") private depotOutStatusState$: Observable<boolean>;
  @select("depotInStatusState") private depotInStatusState$: Observable<boolean>;
  @select("updateInitialsState") private updateInitialsState$ : Observable<boolean>;
  @select("depotOutSelection") private depotOutSelection$: Observable<PlanningBlock[]>;
  @select("depotInSelection") private depotInSelection$: Observable<PlanningBlock[]>;
  @select("depotOutRerouteState") private depotOutRerouteState$: Observable<boolean>;
  @select("depotInRerouteState") private depotInRerouteState$: Observable<boolean>;
  @select("depotOutCandidateState") private depotOutCandidateState$: Observable<boolean>;
  @select("depotInCandidateState") private depotInCandidateState$: Observable<boolean>;
  @select("depotOutContainerReuseState") private depotOutContainerReuseState$: Observable<boolean>;
  @select("depotInContainerReuseState") private depotInContainerReuseState$: Observable<boolean>;
  @select("containerReuseState") private containerReuseState$: Observable<boolean>;
  @select("depotInRailState") private depotInRailState$: Observable<boolean>;
  @select("depotOutRailState") private depotOutRailState$: Observable<boolean>;
  @select("cmrState") private cmrState$: Observable<boolean>;
  @select("depotInReuseCandidatesState") private depotInReuseCandidatesState$: Observable<boolean>;
  @select("depotOutReuseCandidatesState") private depotOutReuseCandidatesState$: Observable<boolean>;
  @select("depotOutUnplanState") private depotOutUnplanState$: Observable<boolean>;
  @select("depotInUnplanState") private depotInUnplanState$: Observable<boolean>;
  @select("containerInPoolDepotOutState") private containerInPoolDepotOutState$: Observable<boolean>;
  @select("containerInPoolDepotInState") private containerInPoolDepotInState$: Observable<boolean>;
  @select("printListDepotOutState") private printListDepotOutState$: Observable<boolean>;
  @select("printListDepotInState") private printListDepotInState$: Observable<boolean>;
  @select("depotInWeighState") private depotInWeighState$: Observable<boolean>;
  @select("depotOutWeighState") private depotOutWeighState$: Observable<boolean>;
  @select("dossierDepotInState") private dossierDepotInState$: Observable<boolean>;
  @select("dossierDepotOutState") private dossierDepotOutState$: Observable<boolean>;
  @select("depotOutFilteredPlanningBlocks") private depotOutFilteredPlanningBlocks$: Observable<PlanningBlock[]>;
  @select("depotInFilteredPlanningBlocks") private depotInFilteredPlanningBlocks$: Observable<PlanningBlock[]>;
  @select("depotInReservationState") private depotInReservationState$: Observable<boolean>;
  @select("groupUpdateState") private groupUpdateState$: Observable<boolean>;
  @select("applicationUser") applicationUser$ : Observable<ApplicationUser>;
  @select("planningGroupString") planningGroupString$ : Observable<string>


  public originalContainerReuseState: boolean = false;
  public planState: boolean = false;
  public statusState: boolean = false;
  public cmrState: boolean = false;
  public rerouteState: boolean = false;
  public planCandidatesState: boolean = false;
  public containerReuseState: boolean = false;
  public reuseCandidatesState: boolean = false;
  public containerInPoolDepotOutState: boolean = false;
  public containerInPoolDepotInState: boolean = false;
  public unplanState: boolean = false;
  public printListDepotOutState: boolean = false;
  public printListDepotInState: boolean = false;
  public railState: boolean = false;
  public weighState: boolean = false;
  public dossierDepotInState: boolean = false;
  public dossierDepotOutState: boolean = false;
  public depotOutSelection: PlanningBlock[] = [];
  public depotInSelection: PlanningBlock[] = [];
  public componentSource = ComponentSource;
  public selectionCount: number;
  public resetButtonHovered: boolean = false;
  public resetStyle: string = "text-info";
  public depotInPlanningBlocks: PlanningBlock[] = [];
  public depotOutPlanningBlocks: PlanningBlock[] = [];
  public reservationState: boolean = false;
  public groupUpdateState: boolean = false;
  public planDate: string;
  public apiFormattedDate: string;
  public modalType: ModalType;
  public updateInitialsState : boolean = false ;
  public user : ApplicationUser = null ; 
  public planningGroupString : string = "";
  public tarTasEnabled = environment.tarTasEnabled;
  public containerPoolEnabled = environment.containerPoolEnabled;
  public truckAppointmentEnabled: boolean = false;
  public railWayEnabled: boolean = environment.railway;
  public containerReuseEnabled: boolean = false;
  public manageFileReservation: boolean = false;

  constructor(private readonly ngRedux: NgRedux<IAppState>,
    private depotInActions: DepotInActions,
    private depotOutActions: DepotOutActions,
    private sharedActions: SharedActions,
    private readonly toastr: ToastrService,
    private readonly translate: TranslateService,
    private readonly actionBarService: ActionBarService,
    private readonly modalManager: ModalManagerService,
    private readonly tarTasManager: TarTasManager,
    private readonly  simpleModalService: SimpleModalService,
  ) { }

  public ngOnInit(): void {
    this.planningGroupString$.subscribe((data) => {
      this.planningGroupString = data;
    })
    this.depotInFilteredPlanningBlocks$.subscribe((pbs: PlanningBlock[]) => {
      this.depotInPlanningBlocks = pbs;
    });
    this.depotOutFilteredPlanningBlocks$.subscribe((pbs: PlanningBlock[]) => {
      this.depotOutPlanningBlocks = pbs;
    });

    this.planState$.subscribe((planState: boolean) => {
      this.planState = planState;
    });
    this.depotOutUnplanState$.subscribe((depotOutUnplanState: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.unplanState = depotOutUnplanState;
      }
    });
    this.depotInUnplanState$.subscribe((depotInUnplanState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.unplanState = depotInUnplanState;
      }
    });
    this.depotOutStatusState$.subscribe((depotOutStatusState: boolean) => {
      if (this.source === this.componentSource.depotOut) { this.statusState = depotOutStatusState; }
    });

    this.depotInStatusState$.subscribe((depotInStatusState: boolean) => {
      if (this.source === this.componentSource.depotIn) { this.statusState = depotInStatusState; }
    });


    this.updateInitialsState$.subscribe((updateInitialsState : boolean) => {
        this.updateInitialsState = updateInitialsState;
    })

    this.cmrState$.subscribe((cmrState: boolean) => {
      this.cmrState = cmrState;
    });

    this.depotInRailState$.subscribe((depotInRailState: boolean) => {
      if (this.source === this.componentSource.depotIn) { this.railState = depotInRailState; }
    });

    this.depotOutRailState$.subscribe((depotOutRailState: boolean) => {
      if (this.source === this.componentSource.depotOut) { this.railState = depotOutRailState; }
    });

    this.depotOutReuseCandidatesState$.subscribe((reuseCandidatesState: boolean) => {
      if (this.source === this.componentSource.depotOut) { this.reuseCandidatesState = reuseCandidatesState; }
    });

    this.depotInReuseCandidatesState$.subscribe((reuseCandidatesState: boolean) => {
      if (this.source === this.componentSource.depotIn) { this.reuseCandidatesState = reuseCandidatesState; }
    });

    this.containerReuseState$.subscribe((containerReuseState: boolean) => {
      this.originalContainerReuseState = containerReuseState;
      this.setContainerReuseState();
    });

    this.depotOutRerouteState$.subscribe((depotOutReroute: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.rerouteState = depotOutReroute;
      }
    });

    this.depotInRerouteState$.subscribe((depotInReroute: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.rerouteState = depotInReroute;
      }
    });

    this.depotInCandidateState$.subscribe((depotInCandidate: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.planCandidatesState = depotInCandidate;
      }
    });

    this.depotOutCandidateState$.subscribe((depotOutCandidate: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.planCandidatesState = depotOutCandidate;
      }
    });

    this.containerInPoolDepotInState$.subscribe((containerInPoolDepotInState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.containerInPoolDepotInState = containerInPoolDepotInState;
      }
    });

    this.containerInPoolDepotOutState$.subscribe((containerInPoolDepotOutState: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.containerInPoolDepotOutState = containerInPoolDepotOutState;
      }
    });

    this.depotInWeighState$.subscribe((depotInWeighState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.weighState = depotInWeighState;
      }
    });

    this.depotOutWeighState$.subscribe((depotOutWeighState: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.weighState = depotOutWeighState;
      }
    });

    this.printListDepotOutState$.subscribe((printListDepotOutState: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.printListDepotOutState = printListDepotOutState;
      }
    });

    this.printListDepotInState$.subscribe((printListDepotInState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.printListDepotInState = printListDepotInState;
      }
    });

    this.dossierDepotInState$.subscribe((dossierDepotInState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.dossierDepotInState = dossierDepotInState;
      }
    });

    this.dossierDepotOutState$.subscribe((dossierDepotOutState: boolean) => {
      if (this.source === this.componentSource.depotOut) {
        this.dossierDepotOutState = dossierDepotOutState;
      }
    });

    this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      if (this.depotOutSelection.length === 0 && this.depotInSelection.length === 0) {
        this.actionBarService.setActionBar(this.depotInSelection, this.depotOutSelection, this.source);
      }
      this.depotInSelection = selectedPlanningBlocks;
      if (this.source === this.componentSource.depotIn) {
        this.selectionCount = selectedPlanningBlocks.length;
      }
      this.checkIfTruckSelectionEnabled();
      this.setContainerReuseState();
    });

    this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      if (this.depotOutSelection.length === 0 && this.depotInSelection.length === 0) {
        this.actionBarService.setActionBar(this.depotInSelection, this.depotOutSelection, this.source);
      }
      this.depotOutSelection = selectedPlanningBlocks;
      if (this.source === this.componentSource.depotOut) {
        this.selectionCount = selectedPlanningBlocks.length;
      }
      this.checkIfTruckSelectionEnabled();
      this.setContainerReuseState();
    });

    this.depotInReservationState$.subscribe((depotInReservationState: boolean) => {
      if (this.source === this.componentSource.depotIn) {
        this.reservationState = depotInReservationState;
      }
    });

    this.groupUpdateState$.subscribe((groupUpdateState: boolean) => {
      this.groupUpdateState = groupUpdateState;
    });

    this.applicationUser$.subscribe((data) => {
      if(data) {
        this.user = data;
        this.manageFileReservation = this.user.generalSetting.manageFileReservation;
        this.containerReuseEnabled = this.user.generalSetting.containerReuseEnabled;
      }
    })

  }

  setContainerReuseState() {
    this.containerReuseState = this.originalContainerReuseState;
    let pbs = [...this.depotInSelection, ...this.depotOutSelection]
    if(this.containerReuseState && pbs.length == 1 && !pbs[0].containerReuseNo)
    {
      this.containerReuseState = false;
    }
  }

  checkIfTruckSelectionEnabled() {
    this.truckAppointmentEnabled = this.tarTasManager.checkIfTruckSelectionEnabled(this.depotInSelection, this.depotOutSelection);
  }

  public resetSelection(): void {
    if (this.source === this.componentSource.depotIn) {
      this.depotInActions.setDepotInSelection([]);
      this.actionBarService.setActionBar(this.depotInSelection, this.depotOutSelection, this.source);
    } else {
      this.depotOutActions.setDepotOutSelection([]);
      this.actionBarService.setActionBar(this.depotInSelection, this.depotOutSelection, this.source);
    }
  }

  public searchCandidates(): void {
    if (this.source === this.componentSource.depotIn && this.planCandidatesState) {
      this.depotOutActions.setDepotOutCandidates();
      this.translate
        .get("TOASTR_MESSAGES.WARNING.CANDIDATES_DEPOT_OUT")
        .subscribe((res: string) => {
          this.toastr.warning(res);
        });
    } else if (this.source === this.componentSource.depotOut && this.planCandidatesState) {
      this.depotInActions.setDepotInCandidates();
      this.translate.get("TOASTR_MESSAGES.WARNING.CANDIDATES_DEPOT_IN").subscribe((res: string) => {
        this.toastr.warning(res);
      });
    }
  }

  public searchOverbookingCandidates(): void {
    if (this.source === this.componentSource.depotIn) {
      this.depotOutActions.setDepotOutOverbookingCandidates();
      this.translate
        .get("TOASTR_MESSAGES.SUCCESS.OVERBOOKING_CANDIDATES_DEPOT_OUT")
        .subscribe((res: string) => {
          this.toastr.success(res, "", {
            closeButton: false
        });
        });
    } else if (this.source === this.componentSource.depotOut) {
      this.depotInActions.setDepotInOverbookingCandidates();
      this.translate
        .get("TOASTR_MESSAGES.SUCCESS.OVERBOOKING_CANDIDATES_DEPOT_IN")
        .subscribe((res: string) => {
          this.toastr.success(res, "", {
            closeButton: false
          });
        });
    }
  }

  public blockUi(): void {
    this.disableUi.emit();
  }

  public openModal(modalId: string): void {
    if (modalId) {
      this.modalManager.openModal(modalId);
    }
  }

  public copyContainerNumbers(): void {
    const pbs = this.source === this.componentSource.depotIn ? this.depotInPlanningBlocks : this.depotOutPlanningBlocks;
    let copyString: string = "";

    pbs.forEach(element => {
      if (element.containerNo != "") {
        copyString += element.containerNo + '\n';
      }
    });

    let selBox = document.createElement('textarea');
    selBox.style.opacity = '0';
    selBox.value = copyString;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);

    this.toastr.success("Copied container numbers", "", {
      closeButton: false
    });
  }

  public setInitialPlanDate() {
    const currentDate = new Date();
    const currentDateHours = currentDate.getHours();

    if (this.isHourBetweenMidnightAndFiveAm(currentDateHours)) {
      this.setPlanDateToPreviousDay(currentDate);
    } else {
      this.planDate = currentDate.toISOString().slice(0, 10);
    }
  }

  public setPlanDateToPreviousDay(currentDate) {
    const subtractXDays = 1;
    const newDate = new Date();

    newDate.setDate(currentDate.getDate() - subtractXDays);
    this.planDate = newDate.toLocaleString().slice(0, 9);
    //this.planDate = newDate.toLocaleDateString();
    
    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    this.planDate = (new Date(newDate as any - tzoffset)).toISOString().slice(0, 10);

  }

  public isHourBetweenMidnightAndFiveAm(currentDateHours) {
    return currentDateHours >= 0 && currentDateHours < 5;
  }

  public formatDateForApi() {
    const dateArray = this.planDate.split("-");
    this.apiFormattedDate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
  }

  public storeCapacitySlots() {
    this.setInitialPlanDate();
    this.formatDateForApi();
    if(this.user)
    {
      if(this.planningGroupString !== "" && this.planningGroupString !== null)
      {
        this.sharedActions.getCapacitySlotsCpl(this.apiFormattedDate , this.planningGroupString);
      }
      else {
        this.sharedActions.getCapacitySlotsCpl(this.apiFormattedDate , "ALL");
      }
    }
    
  }

  truckAppointmentAction(){
    this.tarTasManager.truckAppointmentAction(this.depotInSelection, this.depotOutSelection);
  }

  openContainerReuseModal(){
    this.simpleModalService.addModal(ContainerReuseComponent).subscribe(() => {
    });
  }

}
