import { select } from "@angular-redux/store";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { DamageList } from "../../../pool/models/damageList.interface";
import { PoolContainerInspectionItem } from "../../../pool/models/pool-container-inspection-item.interface";
import { PoolContainer, PoolContainerStatus } from "../../../pool/models/pool-containers.interface";
import { PoolActions } from "../../../pool/pool.actions";
import { PoolService } from "../../../pool/pool.service";
import { ModalType } from "../../../shared/models/component-source";
import { ModalManagerService } from "../../../shared/services/modal-manager.service";
import { SharedActions } from "../../../shared/shared.actions";
import { AnnouncementService } from "../../annoucement.service";
import { AnnouncementActions } from "../../announcement.actions";


@Component({
    selector: "edit-container-inspections",
    templateUrl: "./edit-container-inspections.component.html",
})



export class EditContainerInspectionsModal implements OnInit {

    @select("selectedAnnouncedContainers") public selectedAnnouncedContainers$: Observable<PoolContainer[]> ; 
    @select("checklistItems") public checklistItems$: Observable<PoolContainerInspectionItem[]>;
    @select('loading') public loading$: Observable<boolean>; 
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;

    public modalType = ModalType;
    @ViewChild("editContainerInspection",{static: true}) public editContainerInspection: ElementRef;

    public announcedContInspc : DamageList[] = []; 
    public enableAutocomplete: boolean = false;
    public selectedContainer : PoolContainer ; 

    public poolContainerInspectionItems: PoolContainerInspectionItem[];
    public frontInspectionItems: PoolContainerInspectionItem[];
    public backInspectionItems: PoolContainerInspectionItem[];
    public bottomInspectionItems: PoolContainerInspectionItem[];
    public insideInspectionItems: PoolContainerInspectionItem[];
    public containerCoolingInspectionItems: PoolContainerInspectionItem[];
    public leftInspectionItems: PoolContainerInspectionItem[];
    public rightInspectionItems: PoolContainerInspectionItem[];
    public roofInspectionItems: PoolContainerInspectionItem[];
    public tankBulkInspectionItems: PoolContainerInspectionItem[];
    public generalInspectionItems: PoolContainerInspectionItem[];


    public checkedInspectionItems: PoolContainerInspectionItem[] = [];

    public poolRemark1: string = "";
    public poolRemark2: string = "";
    public poolRemark3: string = "";
    public poolRemark4: string = "";
    public poolRemark5: string = "";
    public poolRemark6: string = "";

    public checklistItemsCodes : string = "";

    public loading: boolean = false ;

    constructor(
        private readonly poolService: PoolService,
        private readonly poolActions: PoolActions,
        private readonly modalManager: ModalManagerService,
        private readonly announcementService: AnnouncementService,
        private readonly toastr : ToastrService,
        private readonly sharedActions : SharedActions,
        private readonly announcementActions: AnnouncementActions
      ) { }
    
  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loading = data;
    })
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
      }
    });

      this.poolService.getPoolInspectionChecklist().subscribe((checklistItems: PoolContainerInspectionItem[]) => {
        this.poolContainerInspectionItems = checklistItems;
      });
        this.selectedAnnouncedContainers$.subscribe((data) => {
            if(data && data.length > 0) {
                this.selectedContainer = data[0];
                this.announcementService.getAnnouncedContainerInspection(this.selectedContainer.containerNo).subscribe((data) => {
                  
                  this.announcedContInspc = data;
                  this.announcementActions.setContainerDamageList(data);
      
                  this.renderInputs();
                  
                })
            }
        })
        

        

    }


    public renderInputs(): void {



        if (this.poolContainerInspectionItems.length !== 0) {
          
          this.poolContainerInspectionItems.forEach((item) => {
            this.checkInspection(item)
          });

          let remarkslist = this.announcedContInspc.filter((x) => x.inspectionCode.toString() == ""); 
          remarkslist.forEach((item) => {
          }); 

          this.poolRemark1 = remarkslist[0] ? remarkslist[0].description : "";
          this.poolRemark2 = remarkslist[1] ? remarkslist[1].description : "";
          this.poolRemark3 = remarkslist[2] ? remarkslist[2].description : "";
          this.poolRemark4 = remarkslist[3] ? remarkslist[3].description : "";
          this.poolRemark5 = remarkslist[4] ? remarkslist[4].description : "";
          this.poolRemark6 = remarkslist[5] ? remarkslist[5].description : "";
         
          this.frontInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "0");
          this.backInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "2");
          this.bottomInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "4");
          this.insideInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "6");
          this.containerCoolingInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "8");
          this.leftInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "1");
          this.rightInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "3");
          this.roofInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "5");
          this.tankBulkInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "7");
          this.generalInspectionItems = this.poolContainerInspectionItems.filter((x) => x.checklistArea === "9");
        
        }
      }
    
      public resetChecklist() {
        this.checkedInspectionItems = [];
        this.poolRemark1 = "";
        this.poolRemark2 = "";
        this.poolRemark3 = "";
        this.poolRemark4 = "";
        this.poolRemark5 = "";
        this.poolRemark6 = "";
        this.setContainerRemarks();
      }
    
  

      public checkInspection(inspectionItem) {
        
          if(this.announcedContInspc.filter((a) => { return a.inspectionCode == inspectionItem.sequenceCode}).length > 0) 
          {
            inspectionItem.checked = true;
             
          } else {
              inspectionItem.checked = false; 
          }
         
      }
    
      public setSelectedChecklistItems($event, inspectionItem: PoolContainerInspectionItem): void {
        const poolContainerCheckedInsectionItems = this.poolContainerInspectionItems.filter((x) => x.checked);
        this.checkedInspectionItems = poolContainerCheckedInsectionItems;
      }
    
      public setContainerRemarks(): void {
        /*this.poolActions.setContainerRemarks([this.poolRemark1, this.poolRemark2, this.poolRemark3,
        this.poolRemark4, this.poolRemark5, this.poolRemark6]);*/

        console.log(this.poolRemark1);
      }

     

      public apply(): any {
        this.loading = true;
        this.checkedInspectionItems.map((item: PoolContainerInspectionItem) => {
          this.checklistItemsCodes += item.sequenceCode + "|";
        });

        let remarks = this.poolRemark1 + "|" +this.poolRemark2 + "|" +this.poolRemark3 + "|" +this.poolRemark4 + "|" +this.poolRemark5 + "|" +this.poolRemark6 + "|" ;

        const data = {
            containerNumber: this.selectedContainer ? this.selectedContainer.containerNo : "",
            containerInspectionItems: this.checklistItemsCodes,
            containerInspectionRemarks: remarks,
        };
        
        this.announcementService.editAnnnouncementInspections(data).subscribe((data) => {
          this.announcementActions.updateAnnouncementDisplay(data);
          this.announcementActions.setSelectedAnnouncedContainers([]);
          this.announcementActions.setSelectedPoolContainersForAnnouncement([]);
          this.toastr.success("Container : " + data[0].containerNo + " , Inspections List updated ")
          this.loading = false; 
          this.closeModal();
        } , (err) => {
          this.loading = false; 
          this.closeModal();
        })
    }

    public setContainerStatus(status: string) {
        switch(status.toLowerCase()) {
            case "available" : 
            return PoolContainerStatus.AVAILABLE; 
            case "not on terrain": 
            return PoolContainerStatus.NOT_ON_TERRAIN;
            case "not available": 
            return PoolContainerStatus.NOT_AVAILABLE;
            case "all" :
            return PoolContainerStatus.ALL;
            case "reserved":
            return PoolContainerStatus.RESERVED;
            default: 
            return 0;
        }
    }

      public closeModal(): void {
        this.modalManager.closeModal(this.modalType.editContainerInspection)
    }

}
