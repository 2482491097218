import { Column } from '../models/application.user.interface';
import { ContainerState, PlanningState, QuickFilter, ToFinalizeCombi, ActionIconFilter } from '../models/pb-screen.interface';
import { PlanningBlock } from '../models/planning-block/planning-block.interface';
import { getFilteredPlanningBlocksBySearchText } from '../helpers/filter.by.text';
import { isOfSelectedPlanningState, isOfSelectedToFinalizeCombi } from '../helpers/check.planning.state';
import { isOfSelectedContainerState } from '../helpers/check.container.state';
import * as moment from 'moment';
import { hasActionIcon, ActionIcons } from '../models/action.icons.enum';
import { hasConflicts } from '../models/planning-conflict-type.enum';

const nullString = "0001-01-01T00:00:00Z";
const nullNavisionString = "1753-01-01T00:00:00Z";

export function isDateValid(dateAsString: string) {
    var isNull = dateAsString === nullString || dateAsString === nullNavisionString;
    return !isNull;
}


export function filterPlanningBlocks(pbs: PlanningBlock[],
    quickFilter: QuickFilter, textFilter: string, sessionDepotOutColumnSelection: Column[],
    selectedPlanningBlocks: PlanningBlock[]): PlanningBlock[] {
    let planningBlocks: PlanningBlock[] = [...pbs];
    const matches: PlanningBlock[] = [];

    if (textFilter.length > 0) {
        planningBlocks = getFilteredPlanningBlocksBySearchText(planningBlocks, textFilter, sessionDepotOutColumnSelection);
    }

    if (quickFilter == null || quickFilter == undefined) {
        return planningBlocks;
    }

    if (quickFilter.selectedOnly == true) {
        planningBlocks = selectedPlanningBlocks;
        return selectedPlanningBlocks;
    }

    for (let planningblock of planningBlocks) {
        let isACustomerMatch: boolean = true;
        let isFirstActionMatch: boolean = true;
        let isLastActionMatch: boolean = true;
        let isLastPlanAddressSearchNameMatch: boolean = true;
        let isFirstPlanAddressSearchNameMatch: boolean = true;
        let isPlanningStateMatch: boolean = true;
        let isContainerStatusMatch: boolean = true;
        let isClosingMatch: boolean = true;
        let isVGMClosingMatch: boolean = true;
        let isStartDateMatch: boolean = true;
        let isEndDateMatch: boolean = true;
        let isContainerTypeMatch: boolean = true;
        let isLocationMatch: boolean = true;
        let isTransportType: boolean = true;
        let isTripType: Boolean = true;
        let isCarrierMatch: boolean = true;
        let isPlanningGroupMatch: boolean = true;
        let isPlanningZoneMatch: boolean = true;
        let isCollectionRegionMatch: boolean = true;
        let isDeliveryRegionMatch: boolean = true;
        let isActionFilterMatch: boolean = true;
        let isCollectionMasterRegionMatch: boolean = true;
        let isDeliveryMasterRegionMatch: boolean = true;
        let isCollectionDateMatch: boolean = true;
        let isDeliveryDateMatch: boolean = true;
        let isTripTypeMatch: Boolean = true;
        let isTransportTypeMatch: boolean = true;
        let isOrderDateMatch: boolean = true;
        let isToFinalizeComboMatch: boolean = true;
        let isTruckDepartmentMatch: boolean = true;
        let isTruckTypeMatch: boolean = true;
        let isTrailerTypeMatch: boolean = true;
        let isTruckSubTypeMatch: boolean = true;
        let isTrailerSubTypeMatch: boolean = true;


        if (quickFilter.selectedOnly && selectedPlanningBlocks.length == 0) {
            continue;
        }

        if (quickFilter.planningGroups &&
            quickFilter.planningGroups.length > 0 &&
            planningblock.planningGroup &&
            planningblock.planningGroup.length > 0) {
            isPlanningGroupMatch = quickFilter.planningGroups.includes(planningblock.planningGroup);

            if (!isPlanningGroupMatch) continue;
        }


        if (quickFilter.truckDepartments &&
            quickFilter.truckDepartments.length > 0 &&
            planningblock.truckDepartmentCode &&
            planningblock.truckDepartmentCode.length > 0) {
            isTruckDepartmentMatch = quickFilter.truckDepartments.includes(planningblock.truckDepartmentCode)
        } else if (quickFilter.truckDepartments && quickFilter.truckDepartments.length > 0 && planningblock.truckDepartmentCode == "") {
            isTruckDepartmentMatch = false;
        }
        if (!isTruckDepartmentMatch) continue;



        if (quickFilter.planningZone &&
            quickFilter.planningZone.id &&
            quickFilter.planningZone.id.length > 0 &&
            ((planningblock.firstAddressPlanningZone && planningblock.firstAddressPlanningZone.length > 0) ||
                (planningblock.firstPlanningAddressPlanningZone && planningblock.firstPlanningAddressPlanningZone.length > 0))) {
            isPlanningZoneMatch = ((planningblock.firstAddressPlanningZone === quickFilter.planningZone.id) ||
                (planningblock.firstPlanningAddressPlanningZone === quickFilter.planningZone.id));
            if (!isPlanningZoneMatch) continue;
        }
        if (quickFilter.transportTypes &&
            planningblock.transportType &&
            quickFilter.transportTypes.length > 0 &&
            planningblock.transportType.length > 0
        ) {
            isTransportTypeMatch = quickFilter.transportTypes.includes(planningblock.transportType);
        } else if (!planningblock.transportType
            && quickFilter.transportTypes
            && quickFilter.transportTypes.length) {
            isTransportTypeMatch = false;
        }

        if (!isTransportTypeMatch) continue;

        if (quickFilter.tripTypes &&
            planningblock.tripType &&
            quickFilter.tripTypes.length > 0 &&
            planningblock.tripType.length > 0
        ) {
            isTripTypeMatch = quickFilter.tripTypes.includes(planningblock.tripType);
        } else if (!planningblock.tripType
            && quickFilter.tripTypes
            && quickFilter.tripTypes.length) {
            isTripTypeMatch = false;
        }
        if (!isTripTypeMatch) continue;

        if (quickFilter.customer &&
            planningblock.customerName &&
            quickFilter.customer.length > 0 &&
            planningblock.customerName.length > 0) {
            isACustomerMatch = planningblock.customerName.toLowerCase().indexOf(quickFilter.customer.toLowerCase()) >
                -1;
        }

        if (!isACustomerMatch) continue;

        if (quickFilter.containerType &&
            planningblock.containerType &&
            quickFilter.containerType.length > 0 &&
            planningblock.containerType.length > 0) {
            if (/\s/.test(quickFilter.containerType)) {
                isContainerTypeMatch = planningblock.containerType.toLowerCase()
                    === quickFilter.containerType.toLowerCase();
            } else {
                isContainerTypeMatch = planningblock.containerType.toLowerCase()
                    .indexOf(quickFilter.containerType.toLowerCase()) > -1;
            }
        }

        if (!isContainerTypeMatch) continue;

        if (quickFilter.truckType &&
            planningblock.truckType &&
            quickFilter.truckType.length > 0 &&
            planningblock.truckType.length > 0) {
            if (/\s/.test(quickFilter.truckType)) {
                isTruckTypeMatch = planningblock.truckType.toLowerCase()
                    === quickFilter.truckType.toLowerCase();
            } else {
                isTruckTypeMatch = planningblock.truckType.toLowerCase()
                    .indexOf(quickFilter.truckType.toLowerCase()) > -1;
            }
            if (!isTruckTypeMatch) continue;
        } else if (quickFilter.truckType && quickFilter.truckType.length > 0 && planningblock.truckType === "") {
            isTrailerSubTypeMatch = false;
        }

        if (quickFilter.trailerType &&
            planningblock.trailerType &&
            quickFilter.trailerType.length > 0 &&
            planningblock.trailerType.length > 0) {
            if (/\s/.test(quickFilter.truckType)) {
                isTrailerTypeMatch = planningblock.trailerType.toLowerCase()
                    === quickFilter.trailerType.toLowerCase();
            } else {
                isTrailerTypeMatch = planningblock.trailerType.toLowerCase()
                    .indexOf(quickFilter.trailerType.toLowerCase()) > -1;
            }
        } else if (quickFilter.trailerType && quickFilter.trailerType.length > 0 && planningblock.trailerType === "") {
            isTrailerTypeMatch = false;
        }
        if (!isTrailerTypeMatch) continue;

        if (quickFilter.truckSubType &&
            planningblock.truckSubType &&
            quickFilter.truckSubType.length > 0 &&
            planningblock.truckSubType.length > 0) {
            if (/\s/.test(quickFilter.truckSubType)) {
                isTruckSubTypeMatch = planningblock.truckSubType.toLowerCase()
                    === quickFilter.truckSubType.toLowerCase();
            } else {
                isTruckSubTypeMatch = planningblock.truckSubType.toLowerCase()
                    .indexOf(quickFilter.truckSubType.toLowerCase()) > -1;
            }
        } else if (quickFilter.truckSubType && quickFilter.truckSubType.length > 0 && planningblock.truckSubType === "") {
            isTruckSubTypeMatch = false;
        }

        if (!isTruckSubTypeMatch) continue;


        if (quickFilter.trailerSubType &&
            planningblock.trailerSubType &&
            quickFilter.trailerSubType.length > 0 &&
            planningblock.trailerSubType.length > 0) {
            if (/\s/.test(quickFilter.trailerSubType)) {
                isTrailerSubTypeMatch = planningblock.trailerSubType.toLowerCase()
                    === quickFilter.trailerSubType.toLowerCase();
            } else {
                isTrailerSubTypeMatch = planningblock.trailerSubType.toLowerCase()
                    .indexOf(quickFilter.trailerSubType.toLowerCase()) > -1;
            }
        } else if (quickFilter.trailerSubType && quickFilter.trailerSubType.length > 0 && planningblock.trailerSubType === "") {
            isTrailerSubTypeMatch = false;
        }
        if (!isTrailerSubTypeMatch) continue;

        if (quickFilter.firstAction &&
            planningblock.firstPlanAddressSearchName &&
            quickFilter.firstAction.length > 0 &&
            planningblock.firstPlanAddressSearchName.length > 0) {

            isFirstActionMatch = (
                planningblock.firstPlanAddressSearchName
                    .toLowerCase().indexOf(quickFilter.firstAction.toLowerCase()) > -1);
        }

        if (!isFirstActionMatch) continue;

        if (quickFilter.lastAction &&
            planningblock.lastPlanAction &&
            planningblock.lastPlanAddressSearchName &&
            quickFilter.lastAction.length > 0 &&
            planningblock.lastPlanAddressSearchName.length > 0 &&
            planningblock.lastPlanAction.length > 0) {
            // todo KC: Comment in word document -> Firstly hardcoded on DEPOT IN later enum
            const lastPlanAction: string = 'DEPOT IN';

            isLastActionMatch = (planningblock.lastPlanAction === lastPlanAction &&
                planningblock.lastPlanAddressSearchName
                    .toLowerCase().indexOf(quickFilter.lastAction.toLowerCase()) > -1);
        }

        if (!isLastActionMatch) continue;

        if (quickFilter.loadingPlace &&
            planningblock.firstCollectionAddressSearchName &&
            quickFilter.loadingPlace.length > 0 &&
            planningblock.firstCollectionAddressSearchName.length > 0) {
            isLastPlanAddressSearchNameMatch = planningblock.firstCollectionAddressSearchName.toLowerCase()
                .indexOf(quickFilter.loadingPlace.toLowerCase()) >
                -1;
        } else if (!planningblock.firstCollectionAddressSearchName
            && quickFilter.loadingPlace
            && quickFilter.loadingPlace.length) {
            isLastPlanAddressSearchNameMatch = false;
        }

        if (!isLastPlanAddressSearchNameMatch) continue;

        if (quickFilter.unLoadingPlace &&
            planningblock.firstDeliveryAddressSearchName &&
            quickFilter.unLoadingPlace.length > 0 &&
            planningblock.firstDeliveryAddressSearchName.length > 0) {
            isFirstPlanAddressSearchNameMatch = planningblock.firstDeliveryAddressSearchName.toLowerCase()
                .indexOf(quickFilter.unLoadingPlace.toLowerCase()) >
                -1;
        } else if (!planningblock.firstDeliveryAddressSearchName
            && quickFilter.unLoadingPlace
            && quickFilter.unLoadingPlace.length) {
            isFirstPlanAddressSearchNameMatch = false;
        }

        if (!isFirstPlanAddressSearchNameMatch) continue;

        if (quickFilter.closing &&
            planningblock.closingDate &&
            quickFilter.closing.toString().length > 0 &&
            planningblock.closingDate.toString().length > 0) {

            const dateToCompare = new Date(Date.parse(planningblock.closingDate.toString())).setHours(0, 0, 0, 0);
            const filterDate = new Date(Date.parse(quickFilter.closing.toString())).setHours(0, 0, 0, 0);

            if (quickFilter.specificClosing) {
                isClosingMatch = filterDate === dateToCompare;
            }
            else {
                isClosingMatch = filterDate >= dateToCompare;
            }
        } else if (!planningblock.closingDate && quickFilter.closing) {
            isClosingMatch = false;
        }

        if (!isClosingMatch) continue;

        if (quickFilter.vgmClosing &&
            planningblock.vgmClosing &&
            quickFilter.vgmClosing.toString().length > 0 &&
            planningblock.vgmClosing.toString().length > 0) {

            const dateToCompare = new Date(Date.parse(planningblock.vgmClosing.toString())).setHours(0, 0, 0, 0);
            const filterDate = new Date(Date.parse(quickFilter.vgmClosing.toString())).setHours(0, 0, 0, 0);

            if (quickFilter.specificVgmClosing) {
                isVGMClosingMatch = filterDate === dateToCompare;
            }
            else {
                isVGMClosingMatch = filterDate >= dateToCompare;
            }
        } else if (!planningblock.vgmClosing && quickFilter.vgmClosing) {
            isVGMClosingMatch = false;
        }

        if (!isVGMClosingMatch) continue;


        if (quickFilter.startDate &&
            quickFilter.startDate.toString().length > 0) {

            if (
                isDateValid(planningblock.startFrom.toString())) {
                const filterDate = moment(Date.parse(quickFilter.startDate.toString())).hours(0).minutes(0).seconds(0).utc();

                if (quickFilter.specificStartDate) {
                    isStartDateMatch = (isDateValid(planningblock.startFrom.toString()) && moment(planningblock.startFrom.toString()).isSame(filterDate, 'day'));

                }
                else {
                    isStartDateMatch = (isDateValid(planningblock.startFrom.toString()) && moment(planningblock.startFrom.toString()).isSameOrAfter(filterDate))

                }
            }
            else isStartDateMatch = false;
        }

        if (quickFilter.endDate &&
            quickFilter.endDate.toString().length > 0) {
            if (isDateValid(planningblock.endUntil.toString())) {

                const filterDate = moment(Date.parse(quickFilter.endDate.toString())).hours(23).minutes(59).seconds(59).utc();

                if (quickFilter.specificEndDate) {
                    isEndDateMatch = (isDateValid(planningblock.endUntil.toString()) && moment(planningblock.endUntil.toString()).isSame(filterDate, 'day'));
                }
                else {
                    isEndDateMatch = (isDateValid(planningblock.endUntil.toString()) && moment(planningblock.endUntil.toString()).isSameOrBefore(filterDate));
                }
            }
            else isEndDateMatch = false;
        }

        if (!isStartDateMatch || !isEndDateMatch) continue;

        isPlanningStateMatch = isOfSelectedPlanningState(planningblock, quickFilter.planningState as PlanningState);
        if (!isPlanningStateMatch) continue;

        isContainerStatusMatch = isOfSelectedContainerState(planningblock, quickFilter.containerStatus);
        if (!isContainerStatusMatch) continue;

        isToFinalizeComboMatch = isOfSelectedToFinalizeCombi(planningblock, quickFilter.toFinalizeCombi as ToFinalizeCombi);
        if (!isToFinalizeComboMatch) continue;

        if (quickFilter.latestLocationCode) {
            isLocationMatch =
                (planningblock.latestLocationCode.toLowerCase() === quickFilter.latestLocationCode.toLowerCase())
                && planningblock.latestLocationCode.length > 0;
            if (!isLocationMatch) continue;
        }

        if (quickFilter.carrier) {
            isCarrierMatch = (planningblock.carrierId.toLowerCase().indexOf(quickFilter.carrier.toLowerCase()) >
                -1) && planningblock.carrierId.length > 0;
            if (!isCarrierMatch) continue;
        }

        if (quickFilter.deliveryRegion &&
            quickFilter.deliveryRegion.length > 0 &&
            planningblock.firstDeliveryRegion &&
            planningblock.firstDeliveryRegion.length > 0) {
            isDeliveryRegionMatch = planningblock.firstDeliveryRegion.toLowerCase().indexOf(quickFilter.deliveryRegion.toLowerCase()) > -1
        } else if (!planningblock.firstDeliveryRegion &&
            quickFilter.deliveryRegion &&
            quickFilter.deliveryRegion.length) {
            isDeliveryRegionMatch = false;
        }
        if (!isDeliveryRegionMatch) continue;

        if (quickFilter.collectionRegion &&
            quickFilter.collectionRegion.length > 0 &&
            planningblock.firstCollectionRegion &&
            planningblock.firstCollectionRegion.length > 0) {
            isCollectionRegionMatch = planningblock.firstCollectionRegion.toLowerCase().indexOf(quickFilter.collectionRegion.toLowerCase()) > -1
        } else if (!planningblock.firstCollectionRegion &&
            quickFilter.collectionRegion &&
            quickFilter.collectionRegion.length) {
            isCollectionRegionMatch = false;
        }

        if (!isCollectionRegionMatch) continue;

        if (quickFilter.processedOnly && !planningblock.processed) {
            continue;
        }

        if (quickFilter.importantOnly && !planningblock.important) {
            continue;
        }

        isActionFilterMatch = checkIfIsActionFilterMatch(planningblock, quickFilter.actionIconFilter);
        if (!isActionFilterMatch) continue;

        if (quickFilter.collectionMasterRegion &&
            quickFilter.collectionMasterRegion.length > 0 &&
            planningblock.firstCollectionMasterRegion &&
            planningblock.firstCollectionMasterRegion.length > 0) {
            isCollectionMasterRegionMatch = planningblock.firstCollectionMasterRegion.toLowerCase().indexOf(quickFilter.collectionMasterRegion.toLowerCase()) > -1
        } else if (!planningblock.firstCollectionMasterRegion &&
            quickFilter.collectionMasterRegion &&
            quickFilter.collectionMasterRegion.length) {
            isCollectionMasterRegionMatch = false;
        }
        if (!isCollectionMasterRegionMatch) continue;

        if (quickFilter.deliveryMasterRegion &&
            quickFilter.deliveryMasterRegion.length > 0 &&
            planningblock.firstDeliveryMasterRegion &&
            planningblock.firstDeliveryMasterRegion.length > 0) {
            isDeliveryMasterRegionMatch = planningblock.firstDeliveryMasterRegion.toLowerCase().indexOf(quickFilter.deliveryMasterRegion.toLowerCase()) > -1
        } else if (!planningblock.firstDeliveryMasterRegion &&
            quickFilter.deliveryMasterRegion &&
            quickFilter.deliveryMasterRegion.length) {
            isDeliveryMasterRegionMatch = false;
        }
        if (!isDeliveryMasterRegionMatch) continue;

        if (quickFilter.collectionDate &&
            quickFilter.collectionDate.toString().length > 0
        ) {
            var pbDate = isDateValid(planningblock.firstCollectionFrom.toString()) ? new Date(planningblock.firstCollectionFrom.toString()) : null;
            if (pbDate !== null) {
                const dateToCompare = pbDate.setHours(0, 0, 0, 0);
                const filterDate = new Date(Date.parse(quickFilter.collectionDate.toString())).setHours(0, 0, 0, 0);

                if (quickFilter.specificCollectionDate) {
                    isCollectionDateMatch = dateToCompare === filterDate;
                }
                else {
                    isCollectionDateMatch = dateToCompare <= filterDate;
                }
            }
            else {
                isCollectionDateMatch = false;
            }
            if (!isCollectionDateMatch) continue;
        }


        if (quickFilter.deliveryDate &&
            quickFilter.deliveryDate.toString().length > 0
        ) {
            var pbDate = isDateValid(planningblock.firstDeliveryFrom.toString()) ? new Date(planningblock.firstDeliveryFrom.toString()) : null;

            if (pbDate !== null) {
                const dateToCompare = pbDate.setHours(0, 0, 0, 0);
                const filterDate = new Date(Date.parse(quickFilter.deliveryDate.toString())).setHours(0, 0, 0, 0);
                if (quickFilter.specificDeliveryDate) {
                    isDeliveryDateMatch = dateToCompare === filterDate;
                }
                else {
                    isDeliveryDateMatch = dateToCompare <= filterDate;
                }
            } else {
                isDeliveryDateMatch = false;
            }
            if (!isDeliveryDateMatch) continue;
        }

        if (quickFilter.orderDate &&
            quickFilter.orderDate.toString().length > 0 &&
            (isDateValid(planningblock.orderDate.toString()))
        ) {
            var pbDate = isDateValid(planningblock.orderDate.toString()) ? new Date(planningblock.orderDate.toString()) : null;
            const dateToCompare = pbDate.setHours(0, 0, 0, 0);
            const filterDate = new Date(Date.parse(quickFilter.orderDate.toString())).setHours(0, 0, 0, 0);

            if (quickFilter.specificOrderDate) {
                isOrderDateMatch = dateToCompare === filterDate;
            }
            else {
                isOrderDateMatch = dateToCompare >= filterDate;
            }
            if (!isOrderDateMatch) continue;
        }

        if (quickFilter.colDelPlanning) {
            let isMatch = quickFilter.colDelPlanning.includes(planningblock.colDelPlanning.toString());
            if (!isMatch) continue;
        }

        if (quickFilter.firstColAddressStreet) {
            let pbValue = planningblock.firstColAddressStreet ? planningblock.firstColAddressStreet : '';
            let isMatch = pbValue.toLowerCase().includes(quickFilter.firstColAddressStreet.toLowerCase());
            if (!isMatch) continue;
        }

        if (quickFilter.firstDelAddressStreet) {
            let pbValue = planningblock.firstDelAddressStreet ? planningblock.firstDelAddressStreet : '';
            let isMatch = pbValue.toLowerCase().includes(quickFilter.firstDelAddressStreet.toLowerCase());
            if (!isMatch) continue;
        }

        matches.push(planningblock);
    }

    return matches;

}

export function checkIfIsActionFilterMatch(pb: PlanningBlock, filter: ActionIconFilter): boolean {
    if (!filter)
        return true;
    if (filter.notifications && filter.notifications != pb.notifications)
        return false;
    if(filter.documents && !pb.documents)
        return false;
    if (filter.conflicts && filter.conflicts != hasConflicts(pb.planningConflicts))
        return false;
    if (filter.highValue && pb.highValue == "0")
        return false;
    if (filter.temperature && parseFloat(pb.setTemperature) !== filter.temperature)
        return false;
    if (filter.T1 && pb.customDocumentStatus != filter.T1)
        return false;
    if (filter.ADR && !pb.dangerousGoods)
        return false;
    if (filter.hasPbRemarks && !pb.remarks)
        return false;
    if(filter.preAnnouncementStatus && pb.dOutPreAnnouncementStatus != filter.preAnnouncementStatus && pb.dInPreAnnouncementStatus != filter.preAnnouncementStatus)
      return false;
    if(filter.truckAppointmentStatus && pb.dOutTruckAppointmentStatus != filter.truckAppointmentStatus && pb.dInTruckAppointmentStatus != filter.truckAppointmentStatus)
      return false;
  if(filter.weighing && !hasActionIcon(pb.specificContainerActions, ActionIcons.WEIGHING))
    return false;
  if(filter.customs && !hasActionIcon(pb.specificContainerActions, ActionIcons.CUSTOMS))
    return false;
  if(filter.scanning && !hasActionIcon(pb.specificContainerActions, ActionIcons.SCANNING))
      return false;
    if (filter.containerReleaseStatus) {
        if (filter.containerReleaseStatus == 1 && pb.containerReleaseStatus != 1 && pb.containerReleaseStatus != 2)
            return false;
        if (filter.containerReleaseStatus == 2 && pb.containerReleaseStatus != 3 && pb.containerReleaseStatus != 4 && pb.containerReleaseStatus != 5)
            return false;
        if (filter.containerReleaseStatus == 3 && pb.containerReleaseStatus != 6)
            return false;
    }
  return true;
}
