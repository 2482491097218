import { OperationalStatus, FinalizedCombi } from "../../models/to-screen.interface";
import { ToOperationalStatusType, TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";

export function isOfSelectedOperationalStatus(transportOrder: TransportOrder, operationalStatus: OperationalStatus): boolean{
    if(!operationalStatus.closed && 
        !operationalStatus.created && 
        !operationalStatus.inProgress) {
        return true ;
    }
    return operationalStatus.closed && transportOrder.toOperationalStatusType == ToOperationalStatusType.Closed  ||
    operationalStatus.inProgress && transportOrder.toOperationalStatusType === ToOperationalStatusType.InProgress || 
    operationalStatus.created && transportOrder.toOperationalStatusType === ToOperationalStatusType.Created
}

export function isOfSelectedFinalizedCombi(transportOrder: TransportOrder , finalizedCombi: FinalizedCombi) : boolean {
    if(!finalizedCombi.yes && 
        !finalizedCombi.no && 
        !finalizedCombi.all ) {
            return true;
        }
    return finalizedCombi.yes && transportOrder.finalizedCombi === 1 || 
           finalizedCombi.no && transportOrder.finalizedCombi === 0 || 
           finalizedCombi.all && (transportOrder.finalizedCombi === 1 || transportOrder.finalizedCombi === 0)
}