import { SharedService } from './../../../../shared/services/shared.service';
import { OnInit, Component, ViewChild, ElementRef, Output, Input, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subject } from "rxjs";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { ActionWizardService } from "../../../../shared/services/action-wizard.service";
import { WizardDefinition } from "../../../../shared/models/wizard-definition";
import { InsertActionTemplateType } from "../../../../shared/models/insert-action-template-type";
import { HttpClientService } from "../../../../shared/services/httpclient";
import { SharedActions } from "../../../../shared/shared.actions";
import { ToastrService } from "ngx-toastr";
import { OrderLine } from "../../../models/order-line/orderLine.interface";
import { ApplicationUser } from "../../../models/application.user.interface";
import { PbActions } from "../../../pb.actions";
import { ToActions } from "../../../../to-screen/to.actions";
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { takeUntil } from 'rxjs/operators';
import { PbInsertActionWizardComponent } from '../../modals/pb-insert-action-wizard-modal/pb-insert-action-wizard-modal.component';
import { UpdatePBModalComponent } from '../../modals/update-pb-modal/update-pb-modal.component';
import { PbScreenService } from '../../../services/pb-screen.service';
import { PrintListComponent } from '../../modals/print-list-modal/print-list-modal.component';
import { environment } from '../../../../../environments/environment';
import { QuickFilter } from '../../../models/pb-screen.interface';

@Component({
    moduleId: module.id,
    selector: 'pb-details-modal',
    templateUrl: './pb-details.component.html',
})
export class PbDetailsComponent extends SimpleModalComponent<PbDetailsInput, void> implements OnInit, OnDestroy {
    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('pbOrderlines') public pbOrderlines$: Observable<OrderLine[]>;
    @select('pbWizardDefinitions') pbWizardDefinitions$: Observable<WizardDefinition[]>;
    @select('defaultPlanningZone') defaultPlanningZone$: Observable<string>;
    @select('selectedPbsFromPlanBoard') selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>
    @select('quickFilter') public quickFilter$: Observable<QuickFilter>;

    public selectedPlanningBlocks: PlanningBlock[] = [];
    public selectedPb: PlanningBlock;
    public orderlineForPb: OrderLine = null;
    public plannigBlockOrderlines: OrderLine[] = [];

    public actionWizardsList: WizardDefinition[] = [];
    public selectedActionWizard: WizardDefinition = null;

    public filter: string = InsertActionTemplateType.pbCard;
    public isProccessingWizard: boolean = false;
    public manuallySelectOrderlines: boolean = false;

    public shippingOrRailwayEnabled = environment.shipping || environment.railway;
    
    public user: ApplicationUser;
    public defaultPlanningZone: string;

    public loadingSpinner = false;
    private pbActionWizards: WizardDefinition[] = [];

    public wizard = {
        code: "",
        manuallySelectOrderline: false,
        wizardDefinition: null,
        targetType: 0,
        orderlines: []
    }

    source: string = '';
    
    destroy$ = new Subject();

    constructor(private readonly actionWizardService: ActionWizardService,
        private readonly httpClientService: HttpClientService,
        private readonly sharedActions: SharedActions,
        private readonly toastr: ToastrService,
        private readonly pbActions: PbActions,
        private readonly toActions: ToActions,
        private readonly simpleModalService: SimpleModalService,
        private readonly sharedService: SharedService,
        public readonly pbService: PbScreenService) {
            super();
    }
    
    ngOnInit(): void {

        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((planningBlocks: PlanningBlock[]) => {
            if(planningBlocks && planningBlocks.length) {
                this.selectedPlanningBlocks = planningBlocks;
                this.selectedPb = this.selectedPlanningBlocks[0];
            }
        })

        this.selectedPbsFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            if(data && data.length) {
                this.selectedPlanningBlocks = data ; 
                this.selectedPb = this.selectedPlanningBlocks[0];
            }
           
        })

        this.pbOrderlines$.pipe(takeUntil(this.destroy$)).subscribe((orderlines: OrderLine[]) => {
            this.plannigBlockOrderlines = orderlines;

        })

        this.pbWizardDefinitions$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            if(this.source !== "pb-screen")
            {
                this.actionWizardsList = data;
            }
            this.pbActionWizards = data;
        })

        this.quickFilter$.pipe(takeUntil(this.destroy$)).subscribe((filter: QuickFilter) => {
            if(this.source == "pb-screen")
            {
                if(filter.planningZone && filter.planningZone.id)
                    this.actionWizardService.getActionWizardDefinitons(InsertActionTemplateType.pbCard, filter.planningZone.id)
                        .subscribe((data) => {
                            this.actionWizardsList = data;
                        })
                else{
                    this.actionWizardsList = this.pbActionWizards;
                }
            }
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public actionizardValueFormatter(wizard: WizardDefinition) {
        return `${wizard.description}`;
    }

    public autocompleListFormatterForWizard(data: WizardDefinition): string {
        return `${data.description}`;
    }

    selectChange(event) {
        this.selectedActionWizard = event;
        this.wizard.code = this.selectedActionWizard.code;
        this.wizard.wizardDefinition = this.selectedActionWizard;
        this.sharedActions.setSelectedActionWizard(this.selectedActionWizard);
    }

    startWizard() {
        if (!this.selectedActionWizard) {
            this.toastr.warning('Please Select a wizard');

        } 

        if (this.selectedActionWizard.insertLines.indexOf('Action Code') > -1) {
                this.executeWizardWithoutModal();
        }
        else {
            this.simpleModalService.addModal(PbInsertActionWizardComponent).subscribe((result) => {
                if(result)
                    this.refreshPbOrderlines();
             });
        }
        
    }

    automaticallySelectOrderlineForWizard(wizard) {}

    executeWizardWithoutModal() {
        let insertLineContainsActionCode = this.wizard.wizardDefinition.insertLines.indexOf("Action Code");
        if (insertLineContainsActionCode > -1 || this.wizard.code === "START TO" || this.wizard.code === "STOP TO") {
            this.actionWizardService.getOrderlineForPlanningBlock(this.selectedPb.id, this.wizard.code).subscribe((data: OrderLine) => {
                this.wizard.orderlines.push(data);
                this.wizard.orderlines.forEach((element) => {
                    this.actionWizardService.getWizardLines(this.wizard.code, element.orderLineId).subscribe((data) => {
                        data.forEach((wizardLine,index) => {
                            if (data.length - 1 === index) {
                                this.actionWizardService.executeWizard({
                                    "code": this.wizard.code,
                                    "orderLineId": element.orderLineId,
                                    "calledFrom": this.wizard.targetType,
                                    "addressList": data[0].addressNo
                                }).subscribe((data) => {
                                    this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                                    this.refreshPbOrderlines();
                                })
                            }
                        });
                    })
                })

            })
        }
    }

    refreshPbOrderlines() {
        this.pbService.getPlanningBlockDetails(this.selectedPb).subscribe((data) => {
            this.pbActions.setOrderlinesForPlanningBlock(data);
          })
    }


    private closeModal(): void {
        this.close();
    }

    public ScreenName() {
        this.sharedService.ScreenName = 'PB-Screen-Details';
    }

    public openPrintModal(){
        this.ScreenName();
        this.simpleModalService.addModal(PrintListComponent, null)
          .subscribe(()=>{ });
    }

    openEditPbModal(){
        this.simpleModalService.addModal(UpdatePBModalComponent)
              .subscribe(()=>{ });
      }
}

export interface PbDetailsInput {
    source: string;
  }