import { OnInit, Component, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subject } from "rxjs";
import { WizardDefinition } from "../../../shared/models/wizard-definition";
import { TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";
import { OrderLine } from "../../../pb-screen/models/order-line/orderLine.interface";
import { SelectableSettings, GridDataResult, GridComponent } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { ActionWizardService } from "../../../shared/services/action-wizard.service";
import { ToScreenService } from "../../../pb-screen/services/to-screen.service";
import { ToastrService } from "ngx-toastr";
import { ToActions } from "../../to.actions";
import { OrderlineService } from "../../../shared/services/orderline.service";
import { PbActions } from "../../../pb-screen/pb.actions";
import { SharedActions } from "../../../shared/shared.actions";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { PlanningBoardActions } from "../../../planningBoard/planningBoard.action";
import { takeUntil } from "rxjs/operators";
import { SimpleModalComponent } from "ngx-simple-modal";
import { environment } from "../../../../environments/environment";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'to-insert-action-wizard-modal',
    templateUrl: './to-insert-action-wizard-modal.component.html'
})

export class ToInsertActionWizardComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {

    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    private _shipRailGrid: GridComponent;
    @ViewChild('shipRailGrid') set shipRailGrid(shipRailGrid: GridComponent)
    {
        if(shipRailGrid)
            this._shipRailGrid = shipRailGrid;
    }
    @select('selectedActionWizard') selectedActionWizard$: Observable<WizardDefinition>;
    @select('selectedTransportOrders') selectedTransportOrders$: Observable<TransportOrder[]>;
    @select('selectedPlanningBlocks') selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('orderLines') public orderLines$: Observable<OrderLine[]>;
    @select('loading') public loading$: Observable<boolean>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    public selectedActionWizard: WizardDefinition;
    public selectedTransportOrder: TransportOrder;
    public selectedOrderlineId: number;
    private currentStep: number;
    private navigationEnabled: boolean = true;
    public gridData: GridDataResult;
    public shipOrRailGridData: GridDataResult;
    public wizardLines: any;
    public searchFilter: string = '';
    public selectedWizardLineAddresses = [];
    public orderlines: OrderLine[] = [];
    public shouldAskAdress = false;
    public addressAsked = false;
    public wizardManuallySelectOrderline = true;
    public wizardSelectDateTime = false;

    public wizardResult: any;

    public selectedRow: number;

    public requestedDateTimeUntil: Date;
    public requestedDateTimeFrom: Date;
    public bookedDateTimeFrom: Date;
    public bookedDateTimeUntil: Date;

    public orderNumber: number;
    public planningBlockId: number;


    public selectedWizard = {
        index: 0,
        addressList: []
    }


    public state: State = {
        skip: 0,
        take: 25,
    };

    public shipOrRailState: State = {
        skip: 0,
        take: 25,
    };

    public selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: 'single',
    };
    public mySelection: OrderLine[] = [];
    public shipOrRailSelection: ShippingLine[] = [];

    public loadingSpinner: boolean = false;
    public selectedPbs: PlanningBlock[] = [];
    destroy$ = new Subject();
    public showShippingOrRailwayLines: boolean = false;
    shipOrRailLines: ShippingLine[] = [];
    selectedShipOrRailLineNo: string;
    railwayEnabled: boolean = environment.railway;
    shippingEnabled: boolean = environment.shipping;

    constructor(private readonly actionWizardService: ActionWizardService,
        private readonly toScreenService: ToScreenService,
        private readonly toastr: ToastrService,
        private readonly toActions: ToActions,
        private readonly orderlineService: OrderlineService,
        private readonly pbActions: PbActions,
        private readonly sharedAction: SharedActions,
        public readonly planboardActions: PlanningBoardActions) {
            super();
    }

    ngOnInit(): void {
        this.currentStep = 1;


      this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if (!data)
          this.loadingSpinner = data;
      })
      
        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
            this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
        });

        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((planningBlocks) => {
            if(planningBlocks && planningBlocks.length > 0)
            {
                this.selectedPbs = planningBlocks;
            }
            
        })

        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders: TransportOrder[]) => {
            if (transportOrders && transportOrders.length > 0) {
                this.selectedTransportOrder = transportOrders[0];
                
                /*this.toScreenService.getTransportOrderDetails(transportOrders[0].id).subscribe((data) => {
                    this.sharedAction.getOrderlines(data);
                })*/
            }
        })

        this.selectedActionWizard$.pipe(takeUntil(this.destroy$)).subscribe((wizard: WizardDefinition) => {
            if (wizard) {
                this.selectedActionWizard = wizard;
            }
        })

        this.orderLines$.pipe(takeUntil(this.destroy$)).subscribe((orderlines: OrderLine[]) => {
            if (orderlines && orderlines.length > 0) {
                this.orderlines = orderlines;
                if(orderlines.length >0) 
                {
                this.planningBlockId = this.orderlines[0].planningBlockId;
                this.orderNumber = this.orderlines[0].orderNumber;
                
                }

                this.setGridContent(); 
            }
        })
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    getWizardLines() {
        if (this.selectedOrderlineId && this.selectedActionWizard) {
            this.actionWizardService.getWizardLines(this.selectedActionWizard.code, this.selectedOrderlineId).subscribe((res) => {
                this.wizardLines = res;
            })
        }
    }

    getAddressesForWizard(wizardLine) {
        if (this.searchFilter.length >= 3) {
            this.actionWizardService.getAddresses(wizardLine.wizardCode, wizardLine.lineNo, this.searchFilter).subscribe((data) => {
                this.selectedWizard.addressList = data;
            })
        } else {
            this.toastr.warning('Please type at least 3 characters to search for an address.');
        }

    }

    executeWizard(wizardData) {
        this.loadingSpinner = true;
        this.actionWizardService.executeWizard(wizardData).subscribe((data) => {
            //Test if inserting dateTime for pbs is required 
            let timerequiredLine = this.wizardLines.find((x) => x.timeRequired === true);
            if (this.wizardLines.length > 0 && timerequiredLine) {
                this.wizardManuallySelectOrderline = false;
                this.shouldAskAdress = false;
                this.wizardResult = data.result;
                this.wizardSelectDateTime = true;
                this.currentStep++;

                this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');

                this.actionWizardService.getNewSplittedPBsCreatedByIAW(wizardData.orderLineId , wizardData.code).subscribe((data) => {
                    this.sharedAction.updatePlanningBlocksDisplay(data);
                    //this.pbActions.setSelectedPlanningBlocks([...this.selectedPbs, ...data]);
                }); 

            } else {
                this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                this.actionWizardService.getNewSplittedPBsCreatedByIAW(wizardData.orderLineId , wizardData.code).subscribe((data) => {
                    this.sharedAction.updatePlanningBlocksDisplay(data);
                    //this.pbActions.setSelectedPlanningBlocks([...this.selectedPbs, ...data]);
                }, (err) => {
                    console.log(err.error.feedback[0].description);
                }); 
                
            }
            this.loadingSpinner = false;
            if(this.selectedTransportOrder) {
                this.toScreenService.getTransportOrdersByIds(this.selectedTransportOrder.id).subscribe((data) => {
                    this.sharedAction.getOrderlines(data[0].planningOrderlines);
                    this.planboardActions.UpdatePlanningDisplay(data); 
                })

            }

            this.closeModal();

        }, (err) => {
            this.loadingSpinner = false;
            this.closeModal();
        })
    }

    private next(): void {
        if (!this.selectedOrderlineId && this.wizardManuallySelectOrderline) {
            this.toastr.error('Please select an orderline before proceeding');
        } else {
            if (this.wizardLines.length > 0) {
                if(this.selectedActionWizard.useShippingLines || this.selectedActionWizard.useRailWayLines)
                {
                    this.shouldAskAdress = false;
                    this.actionWizardService.getFilteredShippingLineList(this.selectedActionWizard.code).subscribe(data => {
                        this.showShippingOrRailwayLines = true;
                        this.shipOrRailLines = data;
                        this.setShipOrRailGridContent();
                    }, err => {
                        this.currentStep--;
                    });
                }
                else if (this.selectedActionWizard.useSameAddress) {
                    if (this.wizardLines[0].addressNo) {
                        this.selectedWizardLineAddresses.push({
                            lineNo: this.wizardLines[0].lineNo,
                            addressNo: this.wizardLines[0].addressNo
                        })
                        this.shouldAskAdress = false;
                        this.currentStep--;
                        let dataToSave = {
                            addressList: this.selectedWizardLineAddresses.map((x) => x.addressNo).join(";"),
                            calledFrom: 1,
                            code: this.selectedActionWizard.code,
                            orderLineId: this.selectedOrderlineId
                        }
                        this.executeWizard(dataToSave);

                    } else {
                        this.shouldAskAdress = true;
                    }
                } else {
                    this.wizardLines.forEach(element => {
                        if (element.addressNo) {
                            var alreadyPushedAdrs = this.selectedWizardLineAddresses.map((x) => x.lineNo === element.lineNo);
                            if (alreadyPushedAdrs.length === 0) {
                                this.selectedWizardLineAddresses.push({
                                    lineNo: element.lineNo,
                                    addressNo: element.addressNo
                                });
                                this.shouldAskAdress = false;
                            }
                        } else {
                            this.shouldAskAdress = true;
                        }
                    });
                }
            }
            this.currentStep++;
        }

    }

    saveDateTimeForPbs() {
        this.loadingSpinner = true;
        let olsInProgress = {};
        let hasErrorOnSave = false;
        for (const key in this.wizardResult) {
            let tempPb = this.wizardResult[key];

            for (const p in tempPb.planningOrderlines) {
                let tempOl = tempPb.planningOrderlines[p];
                olsInProgress[tempOl.orderLineId] = true;
            }
        }

        this.wizardResult.forEach(element => {
            let newCreatedPbs = [];
            element.planningOrderlines.forEach(ol => {
                ol.bookedDateTimeFrom = new Date(this.bookedDateTimeFrom);
                ol.bookedDateTimeUntil = new Date(this.bookedDateTimeUntil);
                ol.requestedDateTimeFrom = new Date(this.requestedDateTimeFrom);
                ol.requestedDateTimeUntil = new Date(this.requestedDateTimeUntil);
                this.orderlineService.updateOrderline(ol.planningBlockId, ol.orderLineId, ol, false).subscribe((res) => {
                    newCreatedPbs.push(res);
                    this.pbActions.updatePlanningBlocksDisplay(newCreatedPbs);
                    this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                    this.loadingSpinner = false;
                    this.closeModal();
                }, (error) => {
                    this.closeModal();
                })
            });
        });

    }

    private saveData(): void {
        if(this.showShippingOrRailwayLines)
        {
            this.saveShipOrRailData();
            return;
        }
        if (this.shouldAskAdress && this.selectedWizardLineAddresses.length === 0) {
            this.toastr.error('Please select an address before proceeding');
            return;
        }
        if (!this.selectedActionWizard.useSameAddress && this.shouldAskAdress && this.selectedWizardLineAddresses.length !== this.wizardLines.length) {
            this.toastr.error('Please select an address for each wizard line before proceeding');
            return;
        }
        else if (this.shouldAskAdress && !this.addressAsked) {
            if (this.selectedOrderlineId && this.wizardManuallySelectOrderline) {
                this.wizardManuallySelectOrderline = false;
            }
        }

        let dataToSave = {
            addressList: this.selectedWizardLineAddresses.map((x) => x.addressNo).join(";"),
            calledFrom: 1,
            code: this.selectedActionWizard.code,
            orderLineId: this.selectedOrderlineId
        }

        this.executeWizard(dataToSave);

    }

    saveShipOrRailData() {
        if(!this.selectedShipOrRailLineNo)
        {
            this.toastr.error('Please select a line before proceeding');
            return;
        }

        this.executeShipOrRailWizard();
    }

    executeShipOrRailWizard() {
        let dataToSave = {
            shippingLineNo: this.selectedShipOrRailLineNo,
            calledFrom: 1,
            code: this.selectedActionWizard.code,
            orderLineId: this.selectedOrderlineId
        };
        this.executeWizard(dataToSave);
    }

    private previous(): void {
        this.currentStep--;
        this.showShippingOrRailwayLines = false;
    }

    private setStep(step): void {
        if (this.navigationEnabled) {
            this.currentStep = step;
        }
    }

    public setGridContent(): void {
        this.gridData = {
            data: [...this.orderlines.slice(this.state.skip, this.state.skip + this.state.take)],
            total: this.orderlines.length,
        };
    }

    public setShipOrRailGridContent(): void {
        this.shipOrRailGridData = {
            data: [...this.shipOrRailLines.slice(this.shipOrRailState.skip, this.shipOrRailState.skip + this.shipOrRailState.take)],
            total: this.shipOrRailLines.length,
        };
        setTimeout(() => {
            if(this._shipRailGrid)
                this._shipRailGrid.autoFitColumns();
        });
    }

    public rowClicked(gridUser, selection): void {
        this.selectedOrderlineId = selection[0];
        this.mySelection = [...selection];
        this.getWizardLines();
    }

    public shipOrRailRowClicked(gridUser, selection): void {
        this.selectedShipOrRailLineNo = selection[0];
        this.shipOrRailSelection = [...selection];
    }

    public selectAddress(lineNo, address, index) {
        let selectedAddress = this.selectedWizardLineAddresses.find((x) => x.lineNo === lineNo);
        if (selectedAddress) {
            selectedAddress.addressNo = address.no;
        } else {
            this.selectedWizardLineAddresses.push({
                lineNo: lineNo,
                addressNo: address.no
            })
        }
        this.selectedRow = index;
        this.shouldAskAdress = true;
    }

    public setAddressContent() {
        this.selectedWizard.addressList = [];
        this.searchFilter = '';

        this.bookedDateTimeFrom = null;
        this.bookedDateTimeUntil = null;
        this.requestedDateTimeFrom = null;
        this.requestedDateTimeUntil = null;
    }

    public closeModal(): void {
        this.toActions.resetActionBarStates();
        this.toActions.updateTransportOrdersDisplay([]);
        this.close();
    }


}
