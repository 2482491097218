import { ApplicationUser } from './../pb-screen/models/application.user.interface';
import { IAppState } from './IAppState';
import * as InitialState from './initial.state';
import { ActionKeys } from '../pb-screen/action.keys';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { filterPlanningBlocks } from '../pb-screen/filters/pb.quickfilter';
import { advancedFilterPlanningBlocks } from '../pb-screen/filters/advanced.filter.comparison';
import { TransportOrder } from '../pb-screen/models/transport-order/transport-order.interface';
import { filterTransportOrders } from '../to-screen/containers/filter/to.quickfilter';
import { getFilteredPlanningBlocksBySearchText, getFilteredTransportOrdersBySearchText } from '../pb-screen/helpers/filter.by.text';
import { relativeTimeRounding } from 'moment';
import { filterPlanningBlocks as depotInQuickFilter } from "../depot-in/filters/depot-in.quickfilter";
import { filterPlanningBlocks as depotOutQuickFilter } from "../depot-out/filters/depot-out.quickfilter";


import { CapacitySlot } from '../pb-screen/models/capacity-slot.interface';
import { PlanningConflict } from '../pb-screen/models/planning-conflict.interface';
import * as moment from 'moment';
import { formatDateForApi, setVisibleWithingRange } from '../shared/functions/orderlines.functions';
import { OrderLine } from '../pb-screen/models/order-line/orderLine.interface';
import { TO_BE_PLANNED } from '../pb-screen/models/status-type.interface';
import { getFilteredPlanningBlocksBySearchText as  getFilteredPlanningBlocksBySearchTextCpl } from "../shared/filter.by.text";

/* NOTE: When adding a case be sure you add it to the fallthrough in the reducer.ts file */
export function sharedReducer(state: IAppState = InitialState.initialState, action): IAppState {
  switch (action.type) {
    case ActionKeys.SET_GLOBAL_SETTINGS:
      return setGlobalSettings(state,action);
    case ActionKeys.HTTP_REQUEST_PENDING:
      return httpRequestPending(state,action);
    case ActionKeys.RESET_STATE:
      return resetState(state,action);
    case ActionKeys.GET_APPLICATION_USER_SUCCESS:
      return setApplicationUser(state,action);
    case ActionKeys.GET_APPLICATION_USER_NOTFOUND:
    case ActionKeys.GET_APPLICATION_USER_FAILED:
      return initializeApplicationUser(state, action);
    case ActionKeys.SET_SESSION_USERSETTINGS_DATA:
      return setSessionUserSettingsData(state, action);
    case ActionKeys.UPDATE_APPLICATION_USER:
      return updateApplicationUser(state, action);
    case ActionKeys.RESET_MODAL_DOMAINS:
      return resetModalDomains(state, action);
    case ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS:
      return storeCapacitySlots(state, action);
    case ActionKeys.REQUEST_HAULIERS_SUCCESS:
      return storeHauliers(state, action);
    case ActionKeys.REQUEST_ORDERLINES_SUCCESS:
      return storeOrderlines(state, action);
    case ActionKeys.SET_QUOTELINES:
      return storeQuoteLines(state, action);
    case ActionKeys.REQUEST_DRIVERS_SUCCESS:
      return storeDrivers(state, action);
    case ActionKeys.REQUEST_PLANNINGGROUPS_SUCCESS:
      return storePlanningGroups(state, action);
    case ActionKeys.SET_CONTAINER_STAUSES:
      return storeContainerStatuses(state, action);
    case ActionKeys.REQUEST_COMMUNICATION_PARTNERS_SUCCESS:
      return storeCommunicationPartners(state, action);
    case ActionKeys.REQUEST_STANDARD_NOTIFICATIONS_SUCCESS:
      return storeStandardNotifications(state, action);
    case ActionKeys.SET_PLANNINGBLOCKS_FOR_TO:
      return setPlanningBlockForTo(state, action);
    case ActionKeys.SET_TMS_PLANNING_SETUP:
      return setTmsPlanningSetup(state, action);
    case ActionKeys.SET_SELECTED_ACTION_WIZARD:
      return setSelectedActionWizard(state, action);
    case ActionKeys.SET_DEFAULT_PLANNINGZONE: ""
      return setDefaultPlanningZone(state,action);
    case ActionKeys.UPDATE_SESSION_Document:
      return updateSessionDepotInDocumentSelection(state, action);
    case ActionKeys.SHARED_UPDATE_PLANNINGBLOCKS_DISPLAY:
      return updatePlanningBlocksDisplay(state, action);
    case ActionKeys.SHARED_UPDATE_TRANSPORTORDERS_DISPLAY:
      return updateTransportOrdersDisplay(state, action);
    case ActionKeys.GET_CMR_ACTIONS:
      return getCmrActions(state, action);
    case ActionKeys.GET_ACTIVE_PLANNING_CONFLICTS:
      return setActivePlanningConflicts(state,action);
    case ActionKeys.SET_PLANNING_CONFLICTS:
      return setPlanningConflicts(state,action);
    case ActionKeys.GET_MONITOR_SERVICE_STATUS:
      return setMonitorServiceStatus(state, action);
    case ActionKeys.Add_CHANNEL_EVENT_TO_INVENTORY:
      return addChannelEventToInventory(state, action);
    case ActionKeys.REQUEST_ALL_NOTIFICATIONS_SUCCESS:
      return setAllNotifications(state, action);
      case ActionKeys.REQUEST_Waiting_Room_Details_SUCCESS:
        return setWaitingRoomDetails(state, action);
    case ActionKeys.REQUEST_ALL_PLANNING_BLOCK_NOTIFICATIONS_SUCCESS:
      return setAllPlanningBlockNotifications(state, action);
    case ActionKeys.REQUEST_ALL_TRANSPORT_ORDER_NOTIFICATIONS_SUCCESS:
      return setAllTransportOrderNotifications(state, action);
    case ActionKeys.REQUEST_TRUCKDEPARTMENTS_SUCCESS:
      return storeTruckDepartments(state,action);
    case ActionKeys.REQUEST_AZURE_USERS_SUCCESS:
      return setAzureUsers(state,action);
    case ActionKeys.STORE_USER_AZURE_GROUPS:
      return storeUserAzureGroups(state,action);
      case ActionKeys.STORE_PLANNING_ZONE:
      return storePlanningZone(state,action);
    case ActionKeys.STORE_IGNORE_CONFLICT_REASONS:
      return storeIgnoreConflictReasons(state,action);
    case ActionKeys.SET_SELECTED_TRANSPORT_ORDERS_FOR_INTERWINDOW_PLANNING:
      return setSelectedTransportOrdersForInterWindowPlanning(state, action);
    case ActionKeys.STORE_CHASSIS_FOR_PB:
      return storeChassisForPb(state,action);
    case ActionKeys.STORE_TRAILERS_FOR_PB:
      return storeTrailersForPb(state,action);
    case ActionKeys.PLAN_BOARD_MONITOR_STATUS:
      return setPlanBoardMonitorStatust(state , action); 
    case ActionKeys.REQUEST_ORDERLINES_SUCCESS2:
      return setOrderlinesForTO(state,action);
    case ActionKeys.STORE_PB_WIZARD_DEFINITIONS:
      return storePbWizardDefs(state,action);
    case ActionKeys.STORE_TO_WIZARD_DEFINITIONS:
      return storeToWizardDefs(state,action);
    case ActionKeys.SET_SESSION_USERSETTINGS_DATA:
      return setSessionUserSettingsData(state, action);
    case ActionKeys.SET_UI_STATE:
      return setUiState(state, action);
    case ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS:
      return storeCapacitySlots(state, action);
    case ActionKeys.UPDATE_CPL_PLANNINGBLOCKS_DISPLAY:
    case ActionKeys.UPDATE_DEPOTS_DISPLAY:
    case ActionKeys.ADD_PLANNINGBLOCKS_DISPLAY:
    case ActionKeys.DELETE_PLANNINGBLOCKS_DISPLAY:
      return updatePlanningBlocksDisplayCpl(state, action);
  
    case ActionKeys.RESET_CPL_ACTION_BAR_STATES:
      return resetActionBarStatesCpl(state, action);
    case ActionKeys.SET_CPL_ACTION_BAR_STATES:
      return setActionBarStatesCpl(state, action);
    case ActionKeys.SET_PLANNING_GROUPS:
      return setPlanningGroups(state, action);
    case ActionKeys.GET_MONITOR_SERVICE_STATUS:
      return setMonitoringServiceStatus(state,action);
    case ActionKeys.Add_CHANNEL_EVENT_TO_INVENTORY:
      return addChannelEventToInventory(state,action);
    case ActionKeys.SET_GROUP_STRING: 
      return setGroupsString(state,action);
    case ActionKeys.STORE_PLANNING_CONFLICTS:
      return storePlanningConflicts(state,action);
    case ActionKeys.SELECT_CONFLICT: 
      return selectConflict(state,action);
    default:
      return state;
  }
}

function updateTransportOrdersDisplay(state: IAppState, action) {
  let planningData = state.planningData; 
  let weekPlanningData = state.weekPlanningData;
  let openTos = state.openTosForDate;
  let filteredTransportOrders: TransportOrder[] = state.filteredTransportOrders;

  const transportOrders: TransportOrder[] = state.transportOrders;
  const selectedTransportOrders : TransportOrder[] = state.selectedTransportOrders;

  function getTime(date: string): string {
    const time = date.split("T")[1];
    const newDate = "01/01/2018 " + time;
    return newDate;
  }

  action.updatedTransportOrders.map((updatedTransportOrder: TransportOrder) => {
    const updatedDate = new Date(getTime(updatedTransportOrder.timestamp.toString()));
    updateTo(updatedTransportOrder, planningData, state, checkPlanBoardFiltersForTo);
    updateTo(updatedTransportOrder, weekPlanningData, state, checkWeekPlanningFiltersForTo);

    let toIndex = transportOrders.findIndex((to: TransportOrder) => to.id === updatedTransportOrder.id);
    if (toIndex > -1 && transportOrders[toIndex].timestamp) {
      const oldDate = new Date(getTime(transportOrders[toIndex].timestamp.toString()));
      if (updatedDate.getTime() > oldDate.getTime()) {
        transportOrders[toIndex] = updatedTransportOrder;

        if(state.selectedTransportOrders.length > 0) {
          toIndex = selectedTransportOrders.findIndex((to : TransportOrder) => to.id === updatedTransportOrder.id);
          if(toIndex > -1){
            selectedTransportOrders[toIndex] = updatedTransportOrder;
          }
        }
      }
    }
    else if (toIndex === -1) {
      transportOrders.push(updatedTransportOrder);
    }
  });

  if (action.deletedTransportOrders) {
    action.deletedTransportOrders.map((deletedTransportOrder: TransportOrder) => {
      // remove from plan board
      removeTo(deletedTransportOrder, planningData, state);
      removeTo(deletedTransportOrder, weekPlanningData, state);

      //remove from to screen
      let toIndex = transportOrders
        .findIndex((to: TransportOrder) => to.id === deletedTransportOrder.id);

      if (toIndex > -1) {
        transportOrders.splice(toIndex, 1);
      }

      if(state.filteredTransportOrders) {
        toIndex = filteredTransportOrders.findIndex((to : TransportOrder) => to.id === deletedTransportOrder.id);
        if(toIndex > -1) {
          filteredTransportOrders.splice(toIndex, 1);
        }
      }

      if (state.selectedTransportOrders.length > 0) {
        toIndex = selectedTransportOrders
          .findIndex((to: TransportOrder) => to.id === deletedTransportOrder.id);
        if (toIndex > -1) {
          selectedTransportOrders.splice(toIndex, 1);
        }
      }
    });
  }

  let transportOrdersToFilter: TransportOrder[] = [...transportOrders];
  let quickFilteredTransportOrders = [...transportOrders];

  if (state.toQuickFilter) {
    transportOrdersToFilter = filterTransportOrders(transportOrdersToFilter, state.toQuickFilter, state.searchText, state.sessionToColumnSelection, state.selectedTransportOrders);
    quickFilteredTransportOrders = [...transportOrders];
  }
  if(state.searchText.length > 0){
    transportOrdersToFilter = getFilteredTransportOrdersBySearchText(transportOrdersToFilter, state.searchText, state.sessionToColumnSelection);
  }
  // else if (state.sessionAdvancedFilters.length !== 0 && state.sessionAdvancedFilters !== null) {
  //   filteredPlanningBlocks = advancedFilterPlanningBlocks(planningBlocksToFilter,
  //     state.sessionAdvancedFilters);
  // }
  let conflicts: PlanningConflict[] = state.planningConflictsForDock;
  if(planningData && planningData.length > 0) {
    conflicts = getUpdatedConflictsListForDock(planningData);
  }

    return Object.assign({},
      state,
      {
        transportOrders: [...transportOrders],
        quickFilteredTransportOrdersNoText : [...quickFilteredTransportOrders],
        updatedTransportOrders: action.updatedTransportOrders,
        filteredTransportOrders: [...transportOrdersToFilter],
        selectedTransportOrders: [...selectedTransportOrders],
        planningData: planningData,
        weekPlanningData: [...weekPlanningData],
        planningConflictsForDock: conflicts
      });
}

function removeTo(deletedTransportOrder: TransportOrder, data: CapacitySlot[], state: IAppState){
  if(!data || !data.length) {
    return;
  }
    let openTos = state.openTosForDate;
    let selectedTransportOrders = state.selectedTransportOrders;
    let capacitySlotToUpdate = data.filter((x) => {
      return x.truck.name == deletedTransportOrder.truck.name; 
    })[0];

    if(capacitySlotToUpdate) {
      removeItem(capacitySlotToUpdate.transportOrders, deletedTransportOrder);
    }

    removeItem(openTos, deletedTransportOrder);
    removeItem(selectedTransportOrders, deletedTransportOrder);
}

function updateTo(updatedTransportOrder: TransportOrder, data: CapacitySlot[], state: IAppState, checkPlanBoardFiltersForTo: (object: any, state: IAppState, checkTruckDepartments: boolean, checkPlanningZone: boolean) => boolean){
  if(!data || !data.length) {
    return;
  }
  let openTos = state.openTosForDate;
  const updatedDate = new Date(getTime(updatedTransportOrder.timestamp.toString()));
  const selectedTransportOrders : TransportOrder[] = state.selectedTransportOrders;
  if(updatedTransportOrder.truck.name){
    let capacitySlotToUpdate = data.filter((x) => {
      return x.truck.name == updatedTransportOrder.truck.name ; 
    })[0];

    if(capacitySlotToUpdate) {
      //check if updated on board
      let oldCapacitySlot = data.find((x: CapacitySlot) => {
        return x.transportOrders.find(to => to.id == updatedTransportOrder.id); 
      });
      if(oldCapacitySlot && oldCapacitySlot.truck.name != capacitySlotToUpdate.truck.name)
        removeItem(oldCapacitySlot.transportOrders, updatedTransportOrder);

      //here remove in opentos if there
      update(openTos, capacitySlotToUpdate.transportOrders, selectedTransportOrders, selectedTransportOrders, updatedTransportOrder, updatedDate, state, true, true, checkPlanBoardFiltersForTo);
    }
  }
  else {
    let capacitySlotToUpdate: any = data.find((x: CapacitySlot) => {
      return x.transportOrders.find(to => to.id == updatedTransportOrder.id); 
    }) ?? {};
    if(!updatedTransportOrder.haulier.name) 
      update(capacitySlotToUpdate.transportOrders, openTos, selectedTransportOrders, selectedTransportOrders, updatedTransportOrder, updatedDate, state, false, false, checkPlanBoardFiltersForTo);
    else //unplaned to is not deleted but updated -> haulier exists, truck is null e.g. louwyck env
      {
        removeItem(capacitySlotToUpdate.transportOrders, updatedTransportOrder);
        removeItem(selectedTransportOrders, updatedTransportOrder);
      }
  }
}

function getTime(date: string): string {
  const time = date.split("T")[1];
  const newDate = "01/01/2018 " + time;
  return newDate;
}
function updatePlanningBlocksDisplay(state: IAppState, action) {
  let filteredPlanningBlocks: PlanningBlock[] = state.filteredPlanningBlocks;
  let planningData = state.planningData;
  let weekPlanningData = state.weekPlanningData;
  let selectedBlocksChanged = false;

  const planningBlocks: PlanningBlock[] = state.planningBlocks;
  const planninbBoardPBs: PlanningBlock[] = state.unplannedPbs;
  let selectedPbsFromPlanBoard: PlanningBlock[] = state.selectedPbsFromPlanBoard;
  const selectedPbsToPlan: PlanningBlock[] = state.selectedPbsToPlan;
  const selectedPlanningBlocks: PlanningBlock[] = state.selectedPlanningBlocks;
  let unplannedPbs = state.unplannedPbs;

  if(action.updatedPlanningBlocks) {
  action.updatedPlanningBlocks.map((updatedPlanningBlock: PlanningBlock) => {
    const updatedDate = new Date(getTime(updatedPlanningBlock.timestamp.toString()));
    if(planningData && planningData.length > 0) {
      if(updatedPlanningBlock.truck){
        let capacitySlotToUpdate: CapacitySlot = planningData.find((x) => {
          return x.truck.name == updatedPlanningBlock.truck; 
        });

        if(capacitySlotToUpdate) {
          let to: any = capacitySlotToUpdate.transportOrders.find((x) => {
            return x.id == updatedPlanningBlock.transportOrderNumber; 
          });
          if(!to){
            to = {};
            to.id = updatedPlanningBlock.transportOrderNumber;
            to.isEmpty = true;
            to.planningBlocks = [];
            to.planningConflicts = [];
            to.truck = {id: capacitySlotToUpdate.truck.id, name: capacitySlotToUpdate.truck.name};
            to.haulier = {};
            to.haulierRemarks = [];
            to.planningRemarks = [];
          }
          let oldTo: TransportOrder, oldPb: PlanningBlock;
            planningData.find((x: CapacitySlot) => {
              oldTo = x.transportOrders.find(to => {
                  oldPb = to.planningBlocks.find(pb => updatedPlanningBlock.id == pb.id);
                  return oldPb;
              });
              return oldTo;
            });
          if(oldTo && oldTo.id != to.id)
          {
            removeItem(oldTo.planningBlocks, updatedPlanningBlock);
          }
          let updated = update(unplannedPbs, to.planningBlocks, selectedPbsToPlan, selectedPbsFromPlanBoard, updatedPlanningBlock, updatedDate, state, true, true, checkPlanBoardFiltersForPb, true);
          to.planningBlocks.sort((a:PlanningBlock, b: PlanningBlock) =>{
            return a.planningBlockSequence - b.planningBlockSequence;
          });
          if(to.id && to.isEmpty && updated) //make sure new pbs match board filters and then push created/empty TO
          {
            let checkIfExistsTo = capacitySlotToUpdate.transportOrders.find(el => el.id == to.id);
            if(!checkIfExistsTo)
              capacitySlotToUpdate.transportOrders.push(to);
          }
        }
      }
      else {
        let oldTo: TransportOrder, oldPb: PlanningBlock;
        planningData.find((x: CapacitySlot) => {
          oldTo = x.transportOrders.find(to => {
              oldPb = to.planningBlocks.find(pb => updatedPlanningBlock.id == pb.id);
              return oldPb;
          });
          return oldTo;
        });
        if(updatedPlanningBlock.status.statusType == TO_BE_PLANNED)
          update(oldTo?.planningBlocks ?? [], unplannedPbs, selectedPbsFromPlanBoard, selectedPbsToPlan, updatedPlanningBlock, updatedDate, state, false, false, checkPlanBoardFiltersForPb);
        else
          update(oldTo?.planningBlocks ?? [], [], selectedPbsFromPlanBoard, [], updatedPlanningBlock, updatedDate, state, false, false, checkPlanBoardFiltersForPb);
      }
    }
    if(weekPlanningData && weekPlanningData.length > 0) {
      if(updatedPlanningBlock.truck){
        let capacitySlotToUpdate: CapacitySlot = weekPlanningData.find((x) => {
          return x.truck.name == updatedPlanningBlock.truck; 
        });

        if(capacitySlotToUpdate) {
          let to: any = capacitySlotToUpdate.transportOrders.find((x) => {
            return x.id == updatedPlanningBlock.transportOrderNumber; 
          });
          if(!to){
            to = {};
            to.id = updatedPlanningBlock.transportOrderNumber;
            to.isEmpty = true;
            to.planningBlocks = [];
            to.planningConflicts = [];
            to.truck = {id: capacitySlotToUpdate.truck.id, name: capacitySlotToUpdate.truck.name};
            to.haulier = {};
            to.haulierRemarks = [];
            to.planningRemarks = [];
            to.orderlines = [];
          }
          let oldTo: TransportOrder, oldPb: PlanningBlock;
          weekPlanningData.find((x: CapacitySlot) => {
            oldTo = x.transportOrders.find(to => {
                oldPb = to.orderlines.find((ol: OrderLine) => updatedPlanningBlock.id == ol.planningBlockId);
                return oldPb;
            });
            return oldTo;
          });
          if(oldTo && oldTo.id == to.id)
            oldTo = null;
          let updated = update(state.unplannedPbsForWeekPlanning, [], selectedPbsToPlan, [], updatedPlanningBlock, updatedDate, state, true, true, checkWeekPlanningFiltersForPb);
          if(updated) {
            if(to.id && to.isEmpty) //make sure new pbs match board filters and then push created/empty TO
            {
              let checkIfExistsTo = capacitySlotToUpdate.transportOrders.find(el => el.id == to.id);
              if(!checkIfExistsTo)
                capacitySlotToUpdate.transportOrders.push(to);
            }
            //update orderlines -> if pb matches filters
            updatedPlanningBlock.orderlines.forEach(orderline => {
              update(oldTo?.orderlines, to.orderlines, state.selectedOrderlinesFromBoard, state.selectedOrderlinesFromBoard, orderline, updatedDate, state, true, true, () => true)
            });
            to.orderlines.sort((a, b) =>{
                let aValue = moment(a.plannedDateTimeFrom).valueOf();
                let bValue = moment(b.plannedDateTimeFrom).valueOf();
                return aValue - bValue;
            });
            setVisibleWithingRange(to,
              formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom),
              formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil));
            if(oldTo){
              oldTo.orderlines.sort((a, b) =>{
                  let aValue = moment(a.plannedDateTimeFrom).valueOf();
                  let bValue = moment(b.plannedDateTimeFrom).valueOf();
                  return aValue - bValue;
              });
              setVisibleWithingRange(oldTo,
                formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom),
                formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil));
            }
          }
        }
      }
      else {
        let oldTo: TransportOrder, oldPb: PlanningBlock;
        weekPlanningData.find((x: CapacitySlot) => {
          oldTo = x.transportOrders.find(to => {
              oldPb = to.orderlines.find((ol: OrderLine) => updatedPlanningBlock.id == ol.planningBlockId);
              return oldPb;
          });
          return oldTo;
        });
        if(oldTo)
        {
          oldTo.orderlines = oldTo.orderlines.filter(el => updatedPlanningBlock.id != el.planningBlockId);
          setVisibleWithingRange(oldTo,
            formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom),
            formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil));
        }

        if(state.selectedOrderlinesFromBoard)
          state.selectedOrderlinesFromBoard = state.selectedOrderlinesFromBoard.filter(el => updatedPlanningBlock.id != el.planningBlockId);
        
        if(updatedPlanningBlock.status.statusType == TO_BE_PLANNED)
          update([], state.unplannedPbsForWeekPlanning, selectedPbsToPlan, selectedPbsToPlan, updatedPlanningBlock, updatedDate, state, false, false, checkWeekPlanningFiltersForPb);
      }
    }
    let planningBlockIndex = planningBlocks.findIndex((planningBlock: PlanningBlock) => planningBlock.id === updatedPlanningBlock.id);
    if (planningBlockIndex === -1) {//add
      planningBlocks.push(updatedPlanningBlock);
    }//update
    else if (planningBlocks[planningBlockIndex].timestamp) {
      const oldDate = new Date(getTime(planningBlocks[planningBlockIndex].timestamp.toString()));
      if (updatedDate.getTime() > oldDate.getTime()) {
        planningBlocks[planningBlockIndex] = updatedPlanningBlock;

        if(state.selectedPlanningBlocks.length > 0) {
          planningBlockIndex = selectedPlanningBlocks.findIndex((pb : PlanningBlock) => pb.id === updatedPlanningBlock.id);
          if(planningBlockIndex > -1){
            selectedPlanningBlocks[planningBlockIndex] = updatedPlanningBlock;
            selectedBlocksChanged = true;
          }
        }
        updateBoardPlanningBlocksDisplay(updatedPlanningBlock ,planninbBoardPBs , state) ;
      }
    }
  });
}

  if (action.deletedPlanningBlocks && action.deletedPlanningBlocks.length > 0) {
    action.deletedPlanningBlocks.map((deletedPlanningBlock: PlanningBlock) => {

      if(planningData && planningData.length > 0) {
        if(deletedPlanningBlock.truck){
          let capacitySlotToUpdate: CapacitySlot = planningData.find((x) => {
            return x.truck.name == deletedPlanningBlock.truck; 
          });
    
          if(capacitySlotToUpdate) {
            let to: any = capacitySlotToUpdate.transportOrders.find((x) => {
              return x.id == deletedPlanningBlock.transportOrderNumber; 
            });
            if(to) {
              removeItem(to.planningBlocks, deletedPlanningBlock);
              removeItem(selectedPbsFromPlanBoard, deletedPlanningBlock);
            }
          }

        }
        else {
          removeItem(unplannedPbs, deletedPlanningBlock);
          removeItem(selectedPbsToPlan, deletedPlanningBlock);
        }
      }

      if(weekPlanningData && weekPlanningData.length > 0) {
        if(deletedPlanningBlock.truck){
          let oldTo: TransportOrder, oldPb: PlanningBlock;
          weekPlanningData.find((x: CapacitySlot) => {
            oldTo = x.transportOrders.find(to => {
                oldPb = to.orderlines.find((ol: OrderLine) => deletedPlanningBlock.id == ol.planningBlockId);
                return oldPb;
            });
            return oldTo;
          });
          if(oldTo)
          {
            oldTo.orderlines = oldTo.orderlines.filter(el => deletedPlanningBlock.id != el.planningBlockId);
            setVisibleWithingRange(oldTo,
              formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom),
              formatDateForApi(state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil));
          }

          if(state.selectedOrderlinesFromBoard)
            state.selectedOrderlinesFromBoard = state.selectedOrderlinesFromBoard.filter(el => deletedPlanningBlock.id != el.planningBlockId);
        }
        else {
          removeItem(state.unplannedPbsForWeekPlanning, deletedPlanningBlock);
          removeItem(selectedPbsToPlan, deletedPlanningBlock);
        }
      }

      let pbIndex = planningBlocks
        .findIndex((pb: PlanningBlock) => pb.id === deletedPlanningBlock.id);

      if (pbIndex > -1) {
        planningBlocks.splice(pbIndex, 1);
      }

      if(state.filteredPlanningBlocks.length > 0) {
        pbIndex = filteredPlanningBlocks.findIndex((pb : PlanningBlock) => pb.id === deletedPlanningBlock.id);
        if(pbIndex > -1) {
          filteredPlanningBlocks.splice(pbIndex, 1);
        }
      }

      if (state.selectedPlanningBlocks.length > 0) {
        pbIndex = selectedPlanningBlocks
          .findIndex((pb: PlanningBlock) => pb.id === deletedPlanningBlock.id);
        if (pbIndex > -1) {
          selectedPlanningBlocks.splice(pbIndex, 1);
          selectedBlocksChanged = true;
        }
      }
    });
  }

  let planningBlocksToFilter: PlanningBlock[] = [...planningBlocks];
  let quickFilteredPlanningBlocks : PlanningBlock[] = [...planningBlocks];
  let quickFilteredPlanningBlocksNoText : PlanningBlock[] = [...planningBlocks];


  if (state.quickFilter) {
    planningBlocksToFilter = filterPlanningBlocks(planningBlocksToFilter, state.quickFilter, '', state.sessionColumnSelection, state.selectedPlanningBlocks);
    quickFilteredPlanningBlocksNoText = [...planningBlocksToFilter];
    planningBlocksToFilter = getFilteredPlanningBlocksBySearchText(planningBlocksToFilter, state.searchText, state.sessionColumnSelection);
    quickFilteredPlanningBlocks = [...planningBlocksToFilter];
  }
  else if(state.searchText.length > 0){
    planningBlocksToFilter = getFilteredPlanningBlocksBySearchText(planningBlocksToFilter, state.searchText, state.sessionColumnSelection);
    quickFilteredPlanningBlocks = [...planningBlocksToFilter];
  }
  if (state.sessionAdvancedFilters !== null && (state.sessionAdvancedFilters.filterGroups.length > 0 || state.sessionAdvancedFilters.filterItems.length > 0)) {
    planningBlocksToFilter = advancedFilterPlanningBlocks(planningBlocksToFilter, state.sessionAdvancedFilters);
  }
  // if(state.searchText.length > 0){
  //   planningBlocksToFilter = getFilteredPlanningBlocksBySearchText(planningBlocksToFilter, state.searchText, state.sessionColumnSelection);
  // }
  if(!selectedPbsFromPlanBoard)
    selectedPbsFromPlanBoard = [];

  if (selectedBlocksChanged) {
    return Object.assign({},
      state,
      {
        planningBlocks : [...planningBlocks],
        quickFilteredPlanningBlocksNoText : [...quickFilteredPlanningBlocksNoText],
        quickFilteredPlanningBlocks: [...quickFilteredPlanningBlocks],
        updatedPlanningBlocks: action.updatedPlanningBlocks,
        filteredPlanningBlocks: [...planningBlocksToFilter],
        selectedPlanningBlocks: [...selectedPlanningBlocks] , 
        //selectedTransportOrders: [] , 
        setSelectedTransportOrdersForInterWindowPlanning: [],
        weekPlanningData: [...weekPlanningData],
        selectedPbsToPlan: [...selectedPbsToPlan],
        selectedPbsFromPlanBoard: [...selectedPbsFromPlanBoard]
      });
  }

  return Object.assign({},
    state,
    {
      quickFilteredPlanningBlocksNoText : [...quickFilteredPlanningBlocksNoText],
      quickFilteredPlanningBlocks: [...quickFilteredPlanningBlocks],
      updatedPlanningBlocks: action.updatedPlanningBlocks,
      filteredPlanningBlocks: [...planningBlocksToFilter],
      weekPlanningData: [...weekPlanningData],
      // selectedPbsToPlan: [...selectedPbsToPlan],
      // selectedPbsFromPlanBoard: [...selectedPbsFromPlanBoard]
    });
}


function updateBoardPlanningBlocksDisplay(updatedPlanningBlock:PlanningBlock, planningBoardPbs:PlanningBlock[] , state:IAppState) {
  let planningBlockIndex = planningBoardPbs.findIndex((planningBlock: PlanningBlock) => planningBlock.id === updatedPlanningBlock.id);
    const updatedDate = new Date(getTime(updatedPlanningBlock.timestamp.toString()));
    if (planningBlockIndex > -1 && planningBoardPbs[planningBlockIndex].timestamp) {
      const oldDate = new Date(getTime(planningBoardPbs[planningBlockIndex].timestamp.toString()));
      if (updatedDate.getTime() > oldDate.getTime()) {
        planningBoardPbs[planningBlockIndex] = updatedPlanningBlock;

        if(state.selectedPbsFromPlanBoard.length > 0) {
          planningBlockIndex = state.selectedPbsFromPlanBoard.findIndex((pb : PlanningBlock) => pb.id === updatedPlanningBlock.id);
          if(planningBlockIndex > -1){
            state.selectedPbsFromPlanBoard[planningBlockIndex] = updatedPlanningBlock;

          }
        }
      }
    }

    //state.capacitySlots[0].transportOrders[0].planningBlocks
  }


function resetModalDomains(state: IAppState, action) {
  return Object.assign({},
    state,
    {
      selectedPlanningBlocks: [],
      uiState: true,
    })
}


function initializeApplicationUser(state: IAppState, action): IAppState {

  return Object.assign({},
    state,
    {
      applicationUser: action.applicationUser,
    });
}

function setSessionUserSettingsData(state: IAppState, action): IAppState {

  return Object.assign({},
    state,
    {
      sessionColumnSelection: action.sessionColumnSelection,
      sessionToColumnSelection: action.sessionToColumnSelection,
      sessionOrderLineColumnSelection: action.sessionOrderLineColumnSelection,
      sessionPbOrderLineColumnSelection : action.sessionPbOrderLineColumnSelection,
      sessionDepotOutColumnSelection: action.depotOutSelectedColumns,
      sessionDepotInColumnSelection: action.depotInSelectedColumns,
      sessionStackerColumnSelection : action.stackerSelectedColumns
    });
}


function updateApplicationUser(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      applicationUser: { ...action.applicationUser },
      sessionColumnSelection: action.applicationUser.pbPreferences.selectedColumns,
      sessionToColumnSelection: action.applicationUser.toPreferences.selectedToColumns,
      sessionOrderLineColumnSelection: action.applicationUser.orderlinePreferences.OrderLineColumnSelection,
      sessionPbOrderLineColumnSelection: action.applicationUser.orderlinePreferences.PbOrderLineColumnSelection,
      sessionDepotOutColumnSelection: action.applicationUser.depotOutSelectedColumns,
      sessionDepotInColumnSelection: action.applicationUser.depotInSelectedColumns,
      sessionStackerColumnSelection : action.applicationUser.stackerSelectedColumns

    });
}

function storeCapacitySlots(state: IAppState, action): IAppState {

  return Object.assign({}, state, {
    capacitySlots: action.capacitySlots,
  })
}

function storeHauliers(state: IAppState, action): IAppState {

  return Object.assign({}, state, {
    hauliers: action.hauliers,
  })
}

function storeOrderlines(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    orderLines: action.orderLines,
  })
}

function storeQuoteLines(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    quoteLines: action.quoteLines,
  })
}

function storeDrivers(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    drivers: action.drivers,
  })
}

function storePlanningGroups(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    planningGroups: action.planningGroups,
  })
}

function storeTruckDepartments(state: IAppState, action): IAppState {
  return Object.assign({}, state , {
    truckDepartments : action.truckDepartments
  })
}

function storeCommunicationPartners(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    communicationPartners: action.communicationPartners,
  })
}

function storeStandardNotifications(state: IAppState, action) : IAppState {
  return Object.assign({}, state, {
    standardNotifications : action.standardNotifications,
  })
}

function setPlanningBlockForTo(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    planningBlocksForTO: [...action.planningBlocksForTO],
  })
}

function setTmsPlanningSetup(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    tmsPlanningSetup: action.tmsPlanningSetup
  })
}

function setSelectedActionWizard(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    selectedActionWizard: action.selectedActionWizard
  })
}

function setDefaultPlanningZone(state: IAppState , action): IAppState {
  return Object.assign({},state, {
    defaultPlanningZone : action.defaultPlanningZone
  })
}

function httpRequestPending(state: IAppState, action): IAppState
{
  return Object.assign({},state, {
    httpRequestPending: action.pending
  })
}

function resetState(state: IAppState, action): IAppState
{
  return Object.assign({},state, {
    selectedTransportOrders: [],
    selectedPlanningBlocks: [],
    selectedPbsToPlan: [],
    selectedPbsFromPlanBoard: [],
    selectedOrderlinesFromBoard: [],
    selectionType: ''
  })
}

function setGlobalSettings(state: IAppState, action): IAppState
{
  return Object.assign({},state, {
    globalSettings: action.settings
  })
}

function setApplicationUser(state: IAppState , action): IAppState {
  return Object.assign({},state, {
    applicationUser: action.applicationUser
  })
}

function setActivePlanningConflicts(state: IAppState , action): IAppState {
  return Object.assign({},state, {
    activePlanningConflicts: action.activePlanningConflicts
  })
}

function setPlanningConflicts(state: IAppState, action): IAppState {
  return Object.assign({},state, {
    planningConflicts: action.planningConflicts
  });
}

function getCmrActions(state: IAppState, action): IAppState {
  return Object.assign({},
    state, {
      cmrActions: action.cmrActions
    });
}
function updateSessionDepotInDocumentSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionDocumentSelection: action.sessionDocumentSelection });
}

function setMonitorServiceStatus(state: IAppState, action) : IAppState {
  return Object.assign({}, state, { monitorService: action.monitorService});
}

function setPlanBoardMonitorStatust(state , action) : IAppState {
  return Object.assign({}, state, { planBoardMonitor: action.planBoardMonitor}); 
}

function addChannelEventToInventory(state: IAppState, action) : IAppState {
  var channelEvents = state.channelEvents;
  if(channelEvents.length >= 10) channelEvents.shift();
  channelEvents.push(action.channelEvent);

  return Object.assign({}, state, { channelEvents : [...channelEvents] });
}

function setAllNotifications(state : IAppState, action) : IAppState {
  return Object.assign({}, state, { notifications : [...action.notifications]});
}

function setWaitingRoomDetails(state : IAppState, action) : IAppState {
  return Object.assign({}, state, { waitingRoomDetails : [...action.waitingRoomDetails]});
}

function setAllPlanningBlockNotifications(state : IAppState, action) : IAppState {
  return Object.assign({}, state, { planningBlockNotifications : [...action.planningBlockNotifications] });
}

function setAllTransportOrderNotifications(state : IAppState, action) : IAppState {
  return Object.assign({}, state, { transportOrderNotifications : [...action.transportOrderNotifications] });
}


function setAzureUsers(state: IAppState , action) : IAppState {
  return Object.assign({},state , {azureUsers : action.azureUsers});
}

function storeUserAzureGroups(state: IAppState , action) : IAppState {
  return Object.assign({},state , {azureGroups : action.azureGroups})
}

function storePlanningZone(state: IAppState , action) : IAppState {
  return Object.assign({},state , {planningZone : action.planningZone})
}

function storeIgnoreConflictReasons(state: IAppState , action) : IAppState {
  return Object.assign({},state,{ignoreConflictReasons: action.ignoreConflictReasons})
}

function storeTrailersForPb(state: IAppState , action) : IAppState {
  return Object.assign({},state , {trailers : action.trailers})
}

function storeChassisForPb(state: IAppState , action) : IAppState {
  return Object.assign({},state,{chassis : action.chassis})
}

function setSelectedTransportOrdersForInterWindowPlanning(state: IAppState , action) : IAppState {
  return Object.assign({},state , {selectedTOForInterWindowPlanning : action.selectedTOForInterWindowPlanning})
}

function setOrderlinesForTO(state: IAppState , action) : IAppState {
  return Object.assign({}, state , {
    orderlinesForTO : action.orderlinesForTO
  }) 
}

function storePbWizardDefs(state: IAppState, action): IAppState {
  return Object.assign({}, state , {
    pbWizardDefinitions: action.wizardDefinitions
  }) 
}

function storeToWizardDefs(state: IAppState, action): IAppState {
  return Object.assign({}, state , {
    toWizardDefinitions: action.wizardDefinitions
  }) 
}

//Container Planning 


function updatePlanningBlocksDisplayCpl(state: IAppState, action): IAppState {
  let depotInFilteredPlanningBlocks: PlanningBlock[] = state.depotInFilteredPlanningBlocks;
  let depotOutFilteredPlanningBlocks: PlanningBlock[] = state.depotOutFilteredPlanningBlocks;

  let depotInFilteredPlanningBlocksNoText: PlanningBlock[] = state.depotInFilteredPlanningBlocks;
  let depotOutFilteredPlanningBlocksNoText: PlanningBlock[] = state.depotOutFilteredPlanningBlocks;

  let selectedBlocksChanged = false;

  const depotInPlanningBlocks: PlanningBlock[] = state.depotInPlanningBlocks;
  const depotOutPlanningBlocks: PlanningBlock[] = state.depotOutPlanningBlocks;
  const depotInCandidates: PlanningBlock[] = state.depotInCandidates;
  const depotOutCandidates: PlanningBlock[] = state.depotOutCandidates;
  const depotInSelection: PlanningBlock[] = state.depotInSelection;
  const depotOutSelection: PlanningBlock[] = state.depotOutSelection;

  function getTime(date: string): string {
    return date;
  }

  if( action.updatedPlanningBlocks ) 
  {

  action.updatedPlanningBlocks?.map((updatedPlanningBlock: PlanningBlock) => {
    let depotInIndex = depotInPlanningBlocks
      .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === updatedPlanningBlock.id);
    const updatedDate = new Date(getTime(updatedPlanningBlock.timestamp.toString()));
    if (depotInIndex > -1 && depotInPlanningBlocks[depotInIndex].timestamp) {
      const oldDate = new Date(getTime(depotInPlanningBlocks[depotInIndex].timestamp.toString()));
      if (updatedDate.getTime() > oldDate.getTime()) {
        depotInPlanningBlocks[depotInIndex] = updatedPlanningBlock;
        if (state.depotInFilteredOnCandidates) {
          depotInIndex = depotInCandidates
            .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === updatedPlanningBlock.id);
          if (depotInIndex > -1) {
            depotInCandidates.splice(depotInIndex, 1);
          }
        }
        if (state.depotInSelection) {
          depotInIndex = depotInSelection
            .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === updatedPlanningBlock.id);
          if (depotInIndex > -1) {
            depotInSelection[depotInIndex] = updatedPlanningBlock;
            selectedBlocksChanged = true;
          }
        }
      }
    }
    let depotOutIndex = depotOutPlanningBlocks
      .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === updatedPlanningBlock.id);
    if (depotOutIndex > -1 && depotOutPlanningBlocks[depotOutIndex].timestamp) {
      const oldDate = new Date(getTime(depotOutPlanningBlocks[depotOutIndex].timestamp.toString()));
      if (updatedDate.getTime() >= oldDate.getTime()) {
        depotOutPlanningBlocks[depotOutIndex] = updatedPlanningBlock;
        if (state.depotOutFilteredOnCandidates) {
          depotOutIndex = depotOutCandidates
            .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === updatedPlanningBlock.id);
          if (depotOutIndex > -1) {
            depotOutCandidates.splice(depotOutIndex, 1);
          }
        }
        if (state.depotOutSelection) {
          depotOutIndex = depotOutSelection
            .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === updatedPlanningBlock.id);
          if (depotOutIndex > -1) {
            depotOutSelection[depotOutIndex] = updatedPlanningBlock;
            selectedBlocksChanged = true;
          }
        }
      }
    }
  });

   }
  if (action.newPlanningBlocks) {
    if (action.newPlanningBlocks.depotInPlanningBlocks && action.newPlanningBlocks.depotInPlanningBlocks.length > 0) {
      action.newPlanningBlocks.depotInPlanningBlocks.map((newPlanningBlock: PlanningBlock) => {
        const depotInIndex = depotInPlanningBlocks
          .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === newPlanningBlock.id);
        if (depotInIndex === -1) {
          depotInPlanningBlocks.push(newPlanningBlock);
          if (state.depotInFilteredOnCandidates) {
            depotInCandidates.push(newPlanningBlock);
          }
        }
      });
    }

    if (action.newPlanningBlocks.depotOutPlanningBlocks && action.newPlanningBlocks.depotOutPlanningBlocks.length > 0) {
      action.newPlanningBlocks.depotOutPlanningBlocks.map((newPlanningBlock: PlanningBlock) => {
        const depotOutIndex = depotOutPlanningBlocks
          .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === newPlanningBlock.id);
        if (depotOutIndex === -1) {
          depotOutPlanningBlocks.push(newPlanningBlock);
          
          if (state.depotOutFilteredOnCandidates) {
            depotOutCandidates.push(newPlanningBlock); 
          }
        }
      });
    }
  }

  if (action.deletedPlanningBlocks) {
    action.deletedPlanningBlocks.map((deletedPlanningBlock: PlanningBlock) => {
      let depotInIndex = depotInPlanningBlocks
        .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === deletedPlanningBlock.id);

      if (depotInIndex > -1) {
        depotInPlanningBlocks.splice(depotInIndex, 1);
      }

      if (state.depotInFilteredOnCandidates) {
        depotInIndex = depotInCandidates
          .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === deletedPlanningBlock.id);
        if (depotInIndex > -1) {
          depotInCandidates.splice(depotInIndex, 1);
        }
      }

      if (state.depotInSelection) {
        depotInIndex = depotInSelection
          .findIndex((depotInPlanningBlock: PlanningBlock) => depotInPlanningBlock.id === deletedPlanningBlock.id);
        if (depotInIndex > -1) {
          depotInSelection.splice(depotInIndex, 1);
        }
      }

      let depotOutIndex = depotOutPlanningBlocks
        .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === deletedPlanningBlock.id);

      if (depotOutIndex > -1) {
        depotOutPlanningBlocks.splice(depotOutIndex, 1);
      }

      if (state.depotOutFilteredOnCandidates) {
        depotOutIndex = depotOutCandidates
          .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === deletedPlanningBlock.id);
        if (depotOutIndex > -1) {
          depotOutCandidates.splice(depotOutIndex, 1);
        }
      }

      if (state.depotOutSelection) {
        depotOutIndex = depotOutSelection
          .findIndex((depotOutPlanningBlock: PlanningBlock) => depotOutPlanningBlock.id === deletedPlanningBlock.id);
        if (depotOutIndex > -1) {
          depotOutSelection.splice(depotOutIndex, 1);
        }
      }
    });
  }

  let depotInPlanningBlocksToFilter: PlanningBlock[] = [];
  if (state.depotInFilteredOnCandidates) {
    depotInPlanningBlocksToFilter = [...depotInCandidates];
  } else {
    depotInPlanningBlocksToFilter = [...depotInPlanningBlocks];
  }
  let depotOutPlanningBlocksToFilter: PlanningBlock[] = [];
  if (state.depotOutFilteredOnCandidates) {
    depotOutPlanningBlocksToFilter = [...depotOutCandidates];
  } else {
    depotOutPlanningBlocksToFilter = [...depotOutPlanningBlocks];
  }

  if (state.depotInQuickFilter !== null && state.depotInQuickFilter !== undefined) {
    depotInFilteredPlanningBlocks =
      depotInQuickFilter(depotInPlanningBlocksToFilter, state.depotInQuickFilter,
        "", state.sessionDepotInColumnSelection);
  } else if (state.sessionDepotInAdvancedFilters && (state.sessionDepotInAdvancedFilters.filterGroups.length > 0 || state.sessionDepotInAdvancedFilters.filterItems.length > 0)) {
    depotInFilteredPlanningBlocks = advancedFilterPlanningBlocks(depotInPlanningBlocksToFilter,
      state.sessionDepotInAdvancedFilters);
  } else {
    depotInFilteredPlanningBlocks = depotInPlanningBlocksToFilter;
  }

  if (state.depotOutQuickFilter !== null && state.depotOutQuickFilter !== undefined) {
    let planningGroups = [];
    if(action.newPlanningBlocks) {
      if(action.newPlanningBlocks.depotInPlanningBlocks) {
        planningGroups =  action.newPlanningBlocks.depotInPlanningBlocks.map(pb => pb.planningGroup) ;
      } else if (action.newPlanningBlocks.depotOutPlanningBlocks) {
        planningGroups =  action.newPlanningBlocks.depotOutPlanningBlocks.map(pb => pb.planningGroup) ;
      }
       
    } else if (action.deletedPlanningBlocks) {
      planningGroups =  action.deletedPlanningBlocks.map(pb => pb.planningGroup); 
    } else {
      if(action.updatedPlanningBlocks)
        planningGroups = action.updatedPlanningBlocks.map(pb => pb.planningGroup);
      else if(action.updatedplanningBlocksCPL)
        planningGroups = action.updatedplanningBlocksCPL.map(pb => pb.planningGroup);
    }
    planningGroups.forEach(element => {
      if(state.depotOutQuickFilter.planningGroups.indexOf(element) == -1)
      {
        state.depotOutQuickFilter.planningGroups.push(element);
      }
    });
    depotOutFilteredPlanningBlocks =
      depotOutQuickFilter(depotOutPlanningBlocksToFilter, state.depotOutQuickFilter,"",
        state.sessionDepotOutColumnSelection);
  } else if (state.sessionDepotOutAdvancedFilters && (state.sessionDepotOutAdvancedFilters.filterGroups.length > 0 || state.sessionDepotOutAdvancedFilters.filterItems.length > 0)) {
    depotOutFilteredPlanningBlocks = advancedFilterPlanningBlocks(depotOutPlanningBlocksToFilter,
      state.sessionDepotOutAdvancedFilters);
  } else {
    depotOutFilteredPlanningBlocks = depotOutPlanningBlocksToFilter;
  }

  if (state.depotInSearchText.trim().length > 0) {
    depotInFilteredPlanningBlocksNoText = depotInFilteredPlanningBlocks;
    depotInFilteredPlanningBlocks = getFilteredPlanningBlocksBySearchTextCpl(depotInFilteredPlanningBlocks, state.depotInSearchText.trim(), state.sessionDepotInColumnSelection);
  } else {
    depotInFilteredPlanningBlocksNoText = depotInFilteredPlanningBlocks;
  }

  if (state.depotOutSearchText.trim().length > 0) {
    depotOutFilteredPlanningBlocksNoText = depotOutFilteredPlanningBlocks;
    depotOutFilteredPlanningBlocks = getFilteredPlanningBlocksBySearchText(depotOutFilteredPlanningBlocks, state.depotOutSearchText.trim(), state.sessionDepotOutColumnSelection);
  } else {
    depotOutFilteredPlanningBlocksNoText = depotOutFilteredPlanningBlocks;
  }

  if (selectedBlocksChanged) {
    return Object.assign({},
      state,
      {
        updatedPlanningBlocks: action.updatedPlanningBlocks,
        depotInFilteredPlanningBlocks : [...depotInFilteredPlanningBlocks],
        depotOutFilteredPlanningBlocks : [...depotOutFilteredPlanningBlocks] ,
        depotInSelection: [...depotInSelection],
        depotOutSelection: [...depotOutSelection],
        depotInCandidates: [...depotInFilteredPlanningBlocks],
        depotOutCandidates: [...depotOutFilteredPlanningBlocks],
        depotInPlanningBlocks: [...depotInPlanningBlocks],
        depotOutPlanningBlocks: [...depotOutPlanningBlocks],
        depotInQuickFilteredPlanningBlocksNoText: [...depotInFilteredPlanningBlocksNoText],
        depotOutQuickFilteredPlanningBlocksNoText: [...depotOutFilteredPlanningBlocksNoText],
        depotInQuickFilteredPlanningBlocks: [...depotInFilteredPlanningBlocks],
        depotOutQuickFilteredPlanningBlocks: [...depotOutFilteredPlanningBlocks],
      });
  }

  return Object.assign({},
    state,
    {
      updatedPlanningBlocks: action.updatedPlanningBlocks,
      depotInFilteredPlanningBlocks : [...depotInFilteredPlanningBlocks],
      depotOutFilteredPlanningBlocks : [...depotOutFilteredPlanningBlocks] ,
      depotInCandidates: [...depotInFilteredPlanningBlocks],
      depotOutCandidates: [...depotOutFilteredPlanningBlocks],
      depotInPlanningBlocks: [...depotInPlanningBlocks],
      depotOutPlanningBlocks: [...depotOutPlanningBlocks],
      depotInQuickFilteredPlanningBlocksNoText: [...depotInFilteredPlanningBlocksNoText],
      depotOutQuickFilteredPlanningBlocksNoText: [...depotOutFilteredPlanningBlocksNoText],
      depotInQuickFilteredPlanningBlocks: [...depotInFilteredPlanningBlocks],
      depotOutQuickFilteredPlanningBlocks: [...depotOutFilteredPlanningBlocks],
    });
}




function setUiState(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      uiState: action.uiState,
    });
}






function resetActionBarStatesCpl(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      planState: action.state,
      depotOutUnplanState: action.state,
      depotInUnplanState: action.state,
      depotInStatusState: action.state,
      depotOutStatusState: action.state,
      depotInRerouteState: action.state,
      depotOutRerouteState: action.state,
      depotInCandidateState: action.state,
      depotOutCandidateState: action.state,
      depotInRailState: action.state,
      depotOutRailState: action.state,
      cmrState: action.state,
      containerReuseState: action.state,
      depotInReuseCandidatesState: action.state,
      depotOutReuseCandidatesState: action.state,
      containerInPoolDepotOutState: action.state,
      containerInPoolDepotInState: action.state,
      depotInWeighState: action.state,
      depotOutWeighState: action.state,
      dossierDepotInState: action.state,
      dossierDepotOutState: action.state,
      depotInReservationState: action.state,
      groupUpdateState: action.state, 
      updateInitialsState: action.state
      
    });
}

function setActionBarStatesCpl(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      planState: action.actionBarStates.planState,
      depotOutUnplanState: action.actionBarStates.depotOutUnplanState,
      depotInUnplanState: action.actionBarStates.depotInUnplanState,
      depotInStatusState: action.actionBarStates.depotInStatusState,
      depotOutStatusState: action.actionBarStates.depotOutStatusState,
      depotInRerouteState: action.actionBarStates.depotInRerouteState,
      depotOutRerouteState: action.actionBarStates.depotOutRerouteState,
      depotInCandidateState: action.actionBarStates.depotInCandidateState,
      depotOutCandidateState: action.actionBarStates.depotOutCandidateState,
      depotInRailState: action.actionBarStates.depotInRailState,
      depotOutRailState: action.actionBarStates.depotOutRailState,
      cmrState: action.actionBarStates.cmrState,
      containerReuseState: action.actionBarStates.containerReuseState,
      depotInReuseCandidatesState: action.actionBarStates.depotInReuseCandidatesState,
      depotOutReuseCandidatesState: action.actionBarStates.depotOutReuseCandidatesState,
      containerInPoolDepotOutState: action.actionBarStates.containerInPoolDepotOutState,
      containerInPoolDepotInState: action.actionBarStates.containerInPoolDepotInState,
      depotInWeighState: action.actionBarStates.depotInWeighState,
      depotOutWeighState: action.actionBarStates.depotOutWeighState,
      dossierDepotInState: action.actionBarStates.dossierDepotInState,
      dossierDepotOutState: action.actionBarStates.dossierDepotOutState,
      depotInReservationState: action.actionBarStates.depotInReservationState,
      groupUpdateState: action.actionBarStates.groupUpdateState,
      updateInitialsState : action.actionBarStates.updateInitialsState
    });
}

function setPlanningGroups(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      selectedPlanningGroups: [...action.selectedPlanningGroups]
    });
}



function setMonitoringServiceStatus(state: IAppState, action) : IAppState {
  return Object.assign({}, state, { monitorService: action.monitorService});
}



function setGroupsString(state: IAppState , action) : IAppState {
  return Object.assign({},state , {planningGroupString : action.planningGroupString})
}

function storePlanningConflicts(state: IAppState , action) : IAppState {
  return Object.assign({},state , {activePlanningConflicts : action.activePlanningConflicts}) 
}

function selectConflict(state: IAppState , action) : IAppState {
  
  return Object.assign({},state , {selectedConflict : action.selectedConflict}) 
}

function getUpdatedConflictsListForDock(planningData: CapacitySlot[]) {
    let conflicts = [];
    planningData.forEach(cp => {
        if (cp && cp.transportOrders && cp.transportOrders.length) {
            cp.transportOrders.forEach(to => {
                if (to.planningConflicts && to.planningConflicts.length > 0) {
                    conflicts.push(...to.planningConflicts);
                }
            });
        }
    });
    return conflicts;
}

function checkPlanBoardFiltersForTo(to: TransportOrder, state: IAppState, checkTruckDepartments: boolean = true, checkPlanningZone: boolean = true): boolean {
  var checkDates = checkToDates(to, state.applicationUser.defaultPlanDate,state.applicationUser.defaultPlanDate);
  return checkDates
    && (!state.applicationUser.defaultPlanGroups.length || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == 'all') || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == to.planningGroup.toLowerCase()))
    && (!checkTruckDepartments || !state.applicationUser.defaultTruckDepartments.length || !!state.applicationUser.defaultTruckDepartments.find(el => el.toLowerCase() == to.truckDepartmentCode.toLowerCase()))
    && (!checkPlanningZone || !state.applicationUser.defaultPlanningZone || state.applicationUser.defaultPlanningZone.toLowerCase() == to.firstPlanningZone.toLowerCase() || state.applicationUser.defaultPlanningZone.toLowerCase() == to.lastPlanningZone.toLowerCase());
}

function checkWeekPlanningFiltersForTo(to: TransportOrder, state: IAppState, checkTruckDepartments: boolean = true, checkPlanningZone: boolean = true): boolean {
  let checkDate =  checkToDates(to, state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom, state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil) ;//moment(to.date).isBetween(moment(state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom, "DD/MM/YYYY"), moment(state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil, "DD/MM/YYYY"), 'day', '[]');
  return checkDate && checkWeekPlanningFilters(to.planningGroup, to.firstPlanningZone, to.lastPlanningZone, to.truckDepartmentCode, state, checkTruckDepartments, checkPlanningZone);
}

function checkWeekPlanningFiltersForPb(pb: PlanningBlock, state: IAppState, checkTruckDepartments: boolean = true, checkPlanningZone: boolean = true): boolean {
  let checkDate = checkPbWithinRange(pb, state.applicationUser.defaultPlanDateRange.defaultPlanDateFrom, state.applicationUser.defaultPlanDateRange.defaultPlanDateUntil) ;
  return checkDate && checkWeekPlanningFilters(pb.planningGroup, pb.firstPlanningAddressPlanningZone ?? '', pb.firstPlanningAddressPlanningZone ?? '', pb.truckDepartmentCode ?? '', state, checkTruckDepartments, checkPlanningZone);
}

function checkWeekPlanningFilters(planningGroup, firstPlanningZone, lastPlanningZone, truckDepartmentCode, state: IAppState, checkTruckDepartments: boolean, checkPlanningZone: boolean): boolean {
  return (!state.applicationUser.defaultPlanGroups.length || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == 'all') || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == planningGroup.toLowerCase()))
    && (!checkTruckDepartments || !state.applicationUser.defaultTruckDepartments.length || !!state.applicationUser.defaultTruckDepartments.find(el => el.toLowerCase() == truckDepartmentCode.toLowerCase()))
    && (!checkPlanningZone || !state.applicationUser.defaultPlanZone || state.applicationUser.defaultPlanZone.toLowerCase() == firstPlanningZone.toLowerCase() || state.applicationUser.defaultPlanZone.toLowerCase() == lastPlanningZone.toLowerCase());
}

function checkPlanBoardFiltersForPb(pb: PlanningBlock, state: IAppState, checkTruckDepartments: boolean = true, checkPlanningZone: boolean = true): boolean {
  var checkDate = checkPbWithinRange(pb, state.applicationUser.defaultPlanDate, state.applicationUser.defaultPlanDate);
  return checkDate 
    && (!state.applicationUser.defaultPlanGroups.length || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == 'all') || !!state.applicationUser.defaultPlanGroups.find(el => el.toLowerCase() == pb.planningGroup.toLowerCase()))
    && (!checkTruckDepartments || !state.applicationUser.defaultTruckDepartments.length || !!state.applicationUser.defaultTruckDepartments.find(el => el.toLowerCase() == pb.truckDepartmentCode.toLowerCase()))
    && (!checkPlanningZone || !state.applicationUser.defaultPlanningZone || state.applicationUser.defaultPlanningZone.toLowerCase() == pb.firstPlanningAddressPlanningZone.toLowerCase());
}

function removeItem(tos: any[], deletedTransportOrder: any) {
  if(!tos || !tos.length)
    return;
  let toIndex = tos.findIndex((to: any) => to.id === deletedTransportOrder.id);
  if (toIndex > -1) {
    tos.splice(toIndex, 1);
  }
}

function update(fromTos: any[], toTos: any[], selectedItemsFrom: any[], selectedItemsTo: any[],updatedTransportOrder: any, updatedDate, state: IAppState, checkTruckDepartments: boolean, checkPlanningZone: boolean, checkPlanBoardFilters: (object: any, state: IAppState, checkTruckDepartments: boolean, checkPlanningZone: boolean) => boolean, removeToArrayDisabled: boolean = false): boolean {
  
  if(fromTos && fromTos.length)
  {
    removeItem(fromTos, updatedTransportOrder);
    removeItem(selectedItemsFrom, updatedTransportOrder);
  }
  
  let toIndex = toTos.findIndex((to: TransportOrder) => to.id === updatedTransportOrder.id);
  if (toIndex == -1) {
    if(checkPlanBoardFilters(updatedTransportOrder, state, checkTruckDepartments, checkPlanningZone))
    {
      toTos.push(updatedTransportOrder);
      return true;
    }
  }
  else {
    if(checkPlanBoardFilters(updatedTransportOrder, state, checkTruckDepartments, checkPlanningZone)){
      let oldTo = toTos[toIndex];
      let oldDate = new Date();
      if(oldTo.timestamp)
        oldDate = new Date(getTime(oldTo.timestamp.toString()));
      if (oldTo.isEmpty || updatedDate.getTime() > oldDate.getTime()) {
        if(oldTo.isEmpty && fromTos)
        {
          removeItem(fromTos, updatedTransportOrder);
          removeItem(selectedItemsFrom, updatedTransportOrder);
        }
        delete oldTo.isEmpty;
        toTos[toIndex] = {...updatedTransportOrder, planningBlocks: oldTo.planningBlocks, orderlines: oldTo.orderlines};
        if(selectedItemsTo && selectedItemsTo.length){
          let selectedToIndex = selectedItemsTo.findIndex((to: TransportOrder) => to.id === updatedTransportOrder.id);
          selectedItemsTo[selectedToIndex] = toTos[toIndex];
        }
      }
      return true;
    } else {
      if(!removeToArrayDisabled)
        removeItem(toTos, updatedTransportOrder);
      removeItem(selectedItemsTo, updatedTransportOrder);
    }
  }
  return false;
}

function checkPbWithinRange(pb: PlanningBlock, startRangeDate, endRangeDate) {
  let isWithinVisibleRange = false;
  let startDate = moment(startRangeDate, "DD/MM/YYYY").set({hour:0, minute:0});
  let endDate = moment(endRangeDate, "DD/MM/YYYY").set({hour:23, minute:59});

  if ((pb.info.indexOf("Start") == 0 || pb.info.indexOf("Stop") == 0)) { // Start PB
      // SEE IF IT IS A ZERO LENGTH PB (ORNOT)
      if(moment(pb.firstPlanningTimeFromPlanning).isSame(moment(pb.lastPlanningTimeUntilPlanning))){
          // WE HAVE A ZERO LENGTH
          if (moment(pb.firstPlanningTimeFromPlanning).isSame(startDate) || moment(pb.firstPlanningTimeFromPlanning).isAfter(startDate)) {
              isWithinVisibleRange = true;
          }
      } else {
          // OLD CASE FOR NON ZERO INTERVALS
          if (moment(pb.firstPlanningTimeFromPlanning).isBefore(endDate) && moment(pb.lastPlanningTimeUntilPlanning).isAfter(startDate)) {
              isWithinVisibleRange = true;
          }

      }
  }

  // SEE IF IT IS A ZERO LENGTH PB (ORNOT)
  if(moment(pb.startFrom).isSame(moment(pb.endUntil))){
      // WE HAVE A ZERO LENGTH
      if (moment(pb.startFrom).isSame(startDate) || moment(pb.startFrom).isAfter(startDate)) {
          isWithinVisibleRange = true;
      }

  } else {
      // assuming pb.startFrom is ALWAYS before pb.endUntil
      if (moment(pb.startFrom).isBefore(endDate) && moment(pb.endUntil).isAfter(startDate)) {
          isWithinVisibleRange = true;
      }
  }
  return isWithinVisibleRange;
}

function checkToDates(to: TransportOrder, startDateRange, endDateRange): boolean
{ 
  let startDate = moment(startDateRange, "DD/MM/YYYY").set({hour:0, minute:0});
  let endDate = moment(endDateRange, "DD/MM/YYYY").set({hour:23, minute:59});
  let startToDate = moment(to.firstPlanningTimeFrom);
  let endToDate = moment(to.lastPlanningTimeUntil);
  return startToDate.isBefore(endDate) && endToDate.isAfter(startDate)
}

function storeContainerStatuses(state: IAppState, action: any): IAppState {
  return Object.assign({}, state, {
    containerStatuses: action.containerStatuses,
  })
}