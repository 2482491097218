import * as moment from "moment";
import { Column } from "../../pb-screen/models/application.user.interface";
import { PlanningBlock } from "../../pb-screen/models/planning-block/planning-block.interface";
import { ActionIcons } from "../../shared/action.icons.enum";
import { isOfSelectedContainerState } from "../../shared/check.container.state";
import { isOfSelectedPlanningState } from "../../shared/check.planning.state";
import { getFilteredPlanningBlocksBySearchText } from "../../shared/filter.by.text";
import { IN_PROGRESS, PLANNED, PRE_PLANNED, TO_BE_PLANNED } from "../../shared/models/status-type.interface";
import { ContainerState, PlanningState, QuickFilter } from "../models/depot-in.interface";
const nullString = "0001-01-01T00:00:00Z";
const nullNavisionString = "1753-01-01T00:00:00Z";

export function isDateValid(dateAsString: string) {
  var isNull = dateAsString === nullString || dateAsString === nullNavisionString;
  return !isNull;
}

export function filterPlanningBlocks(depotInPlanningBlocks: PlanningBlock[],
                                     quickFilter: QuickFilter, textFilter: string,
                                     sessionDepotOutColumnSelection: Column[]): PlanningBlock[] {
    let planningBlocks: PlanningBlock[] = [...depotInPlanningBlocks];
    const matches: PlanningBlock[] = [];

    if (textFilter.length > 0) {
        planningBlocks = getFilteredPlanningBlocksBySearchText(
            planningBlocks,
            textFilter,
            sessionDepotOutColumnSelection,
            );
    }
    
    if(!quickFilter)
        return planningBlocks;

    for (const planningblock of planningBlocks) {
        let isStartDateMatch: boolean = true, isEndDateMatch: boolean = true;
        if (quickFilter.startDate &&
            quickFilter.startDate.toString().length > 0) {
                if (
                    isDateValid(planningblock.startFrom.toString())) {
                    const filterDate = moment(Date.parse(quickFilter.startDate.toString())).hours(0).minutes(0).seconds(0).utc();
    
                    if (quickFilter.specificStartDate) {
                        isStartDateMatch = (isDateValid(planningblock.startFrom.toString()) && moment(planningblock.startFrom.toString()).isSame(filterDate, 'day'));
    
                    }
                    else {
                        isStartDateMatch = (isDateValid(planningblock.startFrom.toString()) && moment(planningblock.startFrom.toString()).isSameOrAfter(filterDate))
    
                    }
                }
                else isStartDateMatch = false;
        }

        if (quickFilter.endDate &&
            quickFilter.endDate.toString().length > 0) {
                if (isDateValid(planningblock.endUntil.toString())) {

                    const filterDate = moment(Date.parse(quickFilter.endDate.toString())).hours(23).minutes(59).seconds(59).utc();
    
                    if (quickFilter.specificEndDate) {
                        isEndDateMatch = (isDateValid(planningblock.endUntil.toString()) && moment(planningblock.endUntil.toString()).isSame(filterDate, 'day'));
                    }
                    else {
                        isEndDateMatch = (isDateValid(planningblock.endUntil.toString()) && moment(planningblock.endUntil.toString()).isSameOrBefore(filterDate));
                    }
                }
                else isEndDateMatch = false;
        }

        if (!isStartDateMatch || !isEndDateMatch) continue;
        const isACustomerMatch: boolean = isCustomerSearchMatch(quickFilter, planningblock);
        const isContainerTypeMatch: boolean = isContainerTypeSearchMatch(quickFilter, planningblock);
        const isFirstActionMatch: boolean = isFirstActionSearchMatch(quickFilter, planningblock);
        const isLastActionMatch: boolean = isLastActionSearchMatch(quickFilter, planningblock);
        const isLastPlanAddressSearchNameMatch: boolean =
        isLastPlanAddressSearchNameSearchMatch(quickFilter, planningblock);
        const isFirstPlanAddressSearchNameMatch: boolean =
        isFirstPlanAddressSearchNameSearchMatch(quickFilter, planningblock);
        const isClosingMatch: boolean = isClosingSearchMatch(quickFilter, planningblock);
        const isPbFirstCollectionFromMatch: boolean = isPbFirstCollectionFromSearchMatch(quickFilter, planningblock);
        const isPlanningStateMatch: boolean = isOfSelectedPlanningState(planningblock,
            quickFilter.planningState as PlanningState);
        const isContainerStatusMatch: boolean = isOfSelectedContainerState(planningblock,
            quickFilter.containerStatus);
        const isLocationMatch: boolean = isLocationSearchMatch(quickFilter, planningblock);
        const isCarrierMatch: boolean = isCarrierSearchMatch(quickFilter, planningblock);
        const isPlanningGroupMatch: boolean = isPlanningGroupSearchMatch(quickFilter, planningblock);
        const isActionIconFilterMatch : boolean = checkIfIsActionFilterMatch(planningblock,quickFilter);
        if (isACustomerMatch &&
            isFirstActionMatch &&
            isContainerTypeMatch &&
            isLastActionMatch &&
            isLastPlanAddressSearchNameMatch &&
            isFirstPlanAddressSearchNameMatch &&
            isPlanningStateMatch &&
            isContainerStatusMatch &&
            isClosingMatch &&
            isPbFirstCollectionFromMatch &&
            isLocationMatch &&
            isCarrierMatch &&
            isPlanningGroupMatch && 
            isActionIconFilterMatch) {
            matches.push(planningblock);
        }
    }

    return matches;
}
function isCarrierSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.carrier) {
        return planningblock.carrierId.toLowerCase() === quickFilter.carrier.toLowerCase()
        && planningblock.carrierId.length > 0;
    }
    return true;
}

function isPlanningGroupSearchMatch(quickFilter: QuickFilter, planningBlock : PlanningBlock) : boolean {
    if(quickFilter.planningGroups && quickFilter.planningGroups.length > 0){
        return quickFilter.planningGroups.indexOf(planningBlock.planningGroup) > -1;
    }
    return true;
}

function isLocationSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.latestLocationCode) {
        return (planningblock.latestLocationCode.toLowerCase() === quickFilter.latestLocationCode.toLowerCase())
            && planningblock.latestLocationCode.length > 0;
    }
    return true;
}

function isPbFirstCollectionFromSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.pbFirstCollectionFrom &&
        planningblock.pbFirstCollectionFrom &&
        quickFilter.pbFirstCollectionFrom.toString().length > 0 &&
        planningblock.pbFirstCollectionFrom.toString().length > 0) {
        const quickfilterDate = Date.parse(quickFilter.pbFirstCollectionFrom.toString());
        const pbDateElems = planningblock.pbFirstCollectionFrom.toString().split(/\D+/g);
        const pbDate = Date.parse(pbDateElems[2] + "-" + pbDateElems[1] + "-" + pbDateElems[0]);
        if (quickFilter.specificPbFirstCollectionFrom) {
            return quickfilterDate === pbDate;
        } else {
            return quickfilterDate >= pbDate;
        }
    } else if (!planningblock.pbFirstCollectionFrom && quickFilter.pbFirstCollectionFrom) {
        return false;
    }
    return true;
}

function isClosingSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.closing &&
        planningblock.closingDate &&
        quickFilter.closing.toString().length > 0 &&
        planningblock.closingDate.toString().length > 0) {
        const quickfilterDate = Date.parse(quickFilter.closing.toString());
        const pbDateElems = planningblock.closingDate.toString().split(/\D+/g);
        const pbDate = Date.parse(pbDateElems[2] + "-" + pbDateElems[1] + "-" + pbDateElems[0]);
        if (quickFilter.specificClosing) {
            return quickfilterDate === pbDate;
        } else {
            return quickfilterDate >= pbDate;
        }
    } else if (!planningblock.closingDate && quickFilter.closing) {
        return false;
    }
    return true;
}

function isFirstPlanAddressSearchNameSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.unLoadingPlace &&
        planningblock.firstDeliveryAddressSearchName &&
        quickFilter.unLoadingPlace.length > 0 &&
        planningblock.firstDeliveryAddressSearchName.length > 0) {
        return planningblock.firstDeliveryAddressSearchName.toLowerCase() === quickFilter.unLoadingPlace.toLowerCase();
    } else if (!planningblock.firstDeliveryAddressSearchName
        && quickFilter.unLoadingPlace
        && quickFilter.unLoadingPlace.length) {
        return false;
    }
    return true;
}

function isLastPlanAddressSearchNameSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.loadingPlace &&
        planningblock.firstCollectionAddressSearchName &&
        quickFilter.loadingPlace.length > 0 &&
        planningblock.firstCollectionAddressSearchName.length > 0) {
        return planningblock.firstCollectionAddressSearchName.toLowerCase() === quickFilter.loadingPlace.toLowerCase();
    } else if (!planningblock.firstCollectionAddressSearchName
        && quickFilter.loadingPlace
        && quickFilter.loadingPlace.length) {
        return false;
    }
    return true;
}

function isLastActionSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.lastAction &&
        planningblock.lastPlanAction &&
        planningblock.lastPlanAddressSearchName &&
        quickFilter.lastAction.length > 0 
        ) {
        const lastPlanAction: string = "DEPOT IN";
        return (planningblock.lastPlanAction === lastPlanAction &&
            planningblock.lastPlanAddressSearchName
                .toLowerCase() === quickFilter.lastAction.toLowerCase());
    }
    return true;
}

function isFirstActionSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.firstAction &&
        planningblock.firstPlanAddressSearchName &&
        quickFilter.firstAction.length > 0 &&
        planningblock.firstPlanAddressSearchName.length > 0) {
        return (planningblock.firstPlanAddressSearchName
            .toLowerCase() === quickFilter.firstAction.toLowerCase());
    }
    return true;
}

function isContainerTypeSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.containerType &&
        quickFilter.containerType.length > 0) {
        if (/\s/.test(quickFilter.containerType)) {
            return planningblock.containerOrTrailerType.toLowerCase()
                === quickFilter.containerType.toLowerCase();
        } else {
            return planningblock.containerOrTrailerType.toLowerCase() === quickFilter.containerType.toLowerCase();
        }
    }
    return true;
}

function isCustomerSearchMatch(quickFilter: QuickFilter, planningblock: PlanningBlock) {
    if (quickFilter.customer &&
        planningblock.customerName &&
        quickFilter.customer.length > 0 &&
        planningblock.customerName.length > 0) {
        return planningblock.customerName.toLowerCase() === quickFilter.customer.toLowerCase();
    }
    return true;
}

function hasActionIcon(specificContainerActions: string, actionIcon: ActionIcons): boolean {
    const containerActions = specificContainerActions.split("|");
    return containerActions.indexOf(actionIcon) !== -1;
}

function hasTarTasCode(depotInRef2)
{
    let hasTarTas: boolean = false;
    if(depotInRef2 != null && depotInRef2 != ""){
      hasTarTas = true;
    }
    return hasTarTas;
}


function checkIfIsActionFilterMatch(pb: PlanningBlock, quickFilter: QuickFilter): boolean{
    let filter = quickFilter.iconsFilter;
    if(!filter)
      return true;
    if(filter.important && !pb.important)
      return false;
    if(filter.hasT1Action && pb.t1Document != filter.hasT1Action)
      return false;
    if(filter.hasADRAction && !pb.dangerousGoods)
      return false;
    if(filter.hasContainerAvailable !=-1 && pb.containerPoolStatus != filter.hasContainerAvailable)
      return false;
    if(filter.preAnnouncementStatus && pb.dInPreAnnouncementStatus != filter.preAnnouncementStatus)
      return false;
    if(filter.truckAppointmentStatus && pb.dInTruckAppointmentStatus != filter.truckAppointmentStatus)
      return false;
    if(filter.hasWeighingAction && !hasActionIcon(pb.specificContainerActions, ActionIcons.WEIGHING))
      return false;
    if(filter.hasCustomsAction && !hasActionIcon(pb.specificContainerActions, ActionIcons.CUSTOMS))
      return false;
    if(filter.hasScanningAction && !hasActionIcon(pb.specificContainerActions, ActionIcons.SCANNING))
      return false;
      if(filter.hasTarTasCode && !hasTarTasCode(pb.depotInOrderLineRef2))
      return false;
    if (filter.containerReleaseStatus) {
        if (filter.containerReleaseStatus == 1 && pb.containerReleaseStatus != 1 && pb.containerReleaseStatus != 2)
            return false;
        if (filter.containerReleaseStatus == 2 && pb.containerReleaseStatus != 3 && pb.containerReleaseStatus != 4 && pb.containerReleaseStatus != 5)
            return false;
        if (filter.containerReleaseStatus == 3 && pb.containerReleaseStatus != 6)
            return false;
    }
    if (filter.highValue && pb.highValue == "0")
        return false;
    if (filter.temperature && parseFloat(pb.setTemperature) !== filter.temperature)
        return false;
    return true;
  }
