import { select } from "@angular-redux/store";
import { Component, EventEmitter, OnInit, Output, Input } from "@angular/core";
import { Observable, Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { AddressLocation } from "../../models/address-location";
import { HttpClientService } from "../../services/httpclient";
import { SharedService } from "../../services/shared.service";


@Component({
    moduleId: module.id,
    selector: "location-selector",
    templateUrl: "./location-selector.component.html",
})

export class LocationSelectorComponent implements OnInit {

    @Output() public locationSelection: EventEmitter<AddressLocation> = new EventEmitter();
    @Input() public hasError: boolean;
    @Input() public horizontalLabel: boolean;
    @Input() public isDisabled: boolean;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    public locations: AddressLocation[];
    public selectedLocation: AddressLocation;
    public emptyLocation: AddressLocation;
    public locationchanged: boolean = false;
    public disableInput: boolean = false;
    private locationFilled: boolean = true;
    destroy$ = new Subject();
    
    constructor(public readonly sharedService: SharedService, 
                public readonly httpClientService: HttpClientService) {
    }

    public ngOnInit() {
        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });
        this.httpClientService.getToken().subscribe((token: any) => {
            this.sharedService.getAddressLocations().subscribe((locations: AddressLocation[]) => {
                this.locations = locations;
            });
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
      }

    public updateLocationSelection(selectedLocation: AddressLocation) {
        if (selectedLocation) {
            this.locationchanged = true;
            this.locationSelection.emit(selectedLocation);
        } 
    }

    public clearSelection() {
        this.selectedLocation = this.emptyLocation;
        this.locationSelection.emit(this.selectedLocation);
    }

    public autocompleListFormatter(data: AddressLocation): string {
        return `${data.searchName}`;
    }

    public valueListFormatter(data: AddressLocation): string {
        if (data.searchName) {
            return `${data.searchName}`;
        }
    }

    public setSelectionFromParent(location: string): AddressLocation {
    if(location == null || this.locations == null ) 
    { 
        this.selectedLocation = this.emptyLocation; 
    }
    else {
        const foundLocation = this.locations.find((x) => x.locationCode === location);
        if (foundLocation) {
            this.selectedLocation = foundLocation;
        } else {
            this.selectedLocation = this.emptyLocation;
        }
    }

        return this.selectedLocation;
    }

    public disableLocationInput(): void {
        this.disableInput = true;
    }

    public enableLocationInput(): void {
        this.disableInput = false;
    }
}
