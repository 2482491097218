
import { throwError as observableThrowError, Observable } from 'rxjs';
import { TO_BE_PLANNED, PLANNED, PRE_PLANNED } from './../models/status-type.interface';
import { TransportOrder } from './../models/transport-order/transport-order.interface';
import { Injectable } from '@angular/core';

import { HttpClientService } from '../../shared/services/httpclient';
import { PlanningBlock } from '../models/planning-block/planning-block.interface';
import { environment } from '../../../environments/environment';
import { Response, RequestOptions } from "@angular/http";
import { map, catchError } from 'rxjs/operators';
import { CapacitySlot } from '../models/capacity-slot.interface';
import { IdNameAsStringObject } from '../models/quick-view/id-name-as-string-object.interface';
import { Haulier } from '../models/haulier.interface';
import { Equipment } from '../../shared/models/equipment.interface';
import { Chassis } from '../../shared/models/chassis.interface';
import { Trailer } from '../../shared/models/trailer.interface';
import { select } from '@angular-redux/store';
import { ApplicationUser } from '../models/application.user.interface';
import { OrderRegisteredCost } from '../models/order-registered-cost.interface';
import { MsalService } from '../../msal';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class PbScreenService {
  @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
  applicationUser: ApplicationUser;

  constructor(private http: HttpClientService,
    private readonly authService: MsalService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService) {
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.applicationUser = applicationUser;
      }
    });
   }

  public getPlanningGroups(): Observable<IdNameAsStringObject[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planning/groups').pipe(
        map((response: any) => {
          return response.data as IdNameAsStringObject[];
        }));
  }
  public getTransportTypes(): Observable<IdNameAsStringObject[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planning/transportTypes').pipe(
        map((response: any) => {
          return response.data as IdNameAsStringObject[];
        }));
  }
  public getTripType(): Observable<IdNameAsStringObject[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planning/tripTypes').pipe(
        map((response: any) => {
          return response.data as IdNameAsStringObject[];
        }));
  }

  public getPlanningZones(): Observable<IdNameAsStringObject[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planning/zones').pipe(
        map((response: any) => {
          return response.data as IdNameAsStringObject[];
        }));
  }

  public getPlanningBlocks(dateFrom: string, dateUntil: string): Observable<any[]> {
    let params = '';
    const zone = '';
    const group = 'ALL';

    params += '?startDate=' + dateFrom;
    params += '&endDate=' + dateUntil;
    params += '&zone=' + zone;
    params += '&group=' + group;
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planningBlocks' + params).pipe(
        map((response: any) => response.data));
  }

  public getPlanningBlocksRange(zone, group): Observable<any[]> {
    let params = '';

    params += '?daysAhead=' + environment.defaultPlanDaysAhead;
    params += '&daysBack=' + environment.defaultPlanDaysBack;
    params += '&zone=' + zone;
    params += '&group=' + group;
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planningBlocks/range' + params).pipe(
        map((response: any) => response.data));
  }

  public getPlanningBlocksByIds(pbIds: number[]): Observable<PlanningBlock[]> {
    let params = pbIds.join(",");
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planningBlocks?planningBlockIds=' + params).pipe(
        map((response: any) => response.data));
  }

  public planAction(pbs: PlanningBlock[]): Observable<PlanningBlock[]> {
    const account = this.authService.getAccount(); 
    let pbDelta = {
      PlanningBlocks: [],
      userName: account.username
    };
    pbs.forEach((pb: PlanningBlock) => {
      pbDelta.PlanningBlocks.push(
        {
          Id: pb.id,
          Status: {
            statusType: PLANNED
          }
        }
      )
    });

    return this.http
      .patch(environment.tmsApiEndpointAddress + "planningBlocks", pbDelta).pipe(
        map((res: any) => {
          return res.data as PlanningBlock[]
        }),
        catchError((err) => observableThrowError(err)));
  }

  public planBeforeOrAfterPB(transportOrderId, planningBlocks: PlanningBlock[], refPlanningBlockId: number, beforeOrAfter: number, orderlineId: number = 0): Observable<TransportOrder> {
    const account = this.authService.getAccount();
    return this.http.post(environment.tmsApiEndpointAddress + "transportorders/id/planningblocks-before-or-after" +
      "?toId=" + transportOrderId +
      "&refPlanningBlockId=" + refPlanningBlockId +
      "&beforeOrAfter=" + beforeOrAfter +
      "&orderlineId=" + orderlineId+
      "&userName=" + account.username,
      planningBlocks).pipe(
        map((response: any) => response.data as TransportOrder),
        catchError((err) => observableThrowError(err)));
  }

  public getPlanningBlockDetails(planningBlock: PlanningBlock): Observable<any[]> {
    return this.getPlanningBlockDetailsByID(planningBlock.id);
  }

  public getPlanningBlockDetailsByID(planningBlockId: number): Observable<any[]> {
    return this.getPlanningBlockDetailsByIDs(planningBlockId.toString());
  }

  public getPlanningBlockDetailsByIDs(planningBlockIdsCommaSepareted: string): Observable<any[]> {
    if(!planningBlockIdsCommaSepareted)
    {
      this.translate.get("TOASTR_MESSAGES.ERROR.PB_NOT_SELECTED").subscribe((translated) => {
        this.toastr.error(translated);
      });
      return;
    }
    return this.http.get(environment.tmsApiEndpointAddress + "planningBlocks/" + planningBlockIdsCommaSepareted + "/orderlines").pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public planToNewTo(pbIds: number[]): Observable<PlanningBlock[]> {
    const planningBlockIds = pbIds.join(",");
    const account = this.authService.getAccount();
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/add-to-new-to?planningBlockIds=" + planningBlockIds
    +"&userName=" + account.username).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public updateFinalizedCombi(toNumbersArray: string[], newFinalizedCombi: boolean): Observable<TransportOrder[]> {
    const toNumbers = toNumbersArray.join(",");
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/update-finalizedCombi?toNumbers=" + toNumbers + "&newFinalizedCombi=" + newFinalizedCombi).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }
  public updateETADate(toNumbersArray: string[], etaDate: Date): Observable<TransportOrder[]> {
    const toNumbers = toNumbersArray.join(",");
    const newData = etaDate.getFullYear() + "-" + etaDate.getMonth() + "-" + etaDate.getDate();
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/update-toetadate?toNumbers=" + toNumbers + "&etaDate=" + newData).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public getOpenTos(driverNo: string, truckNo: string, planningDate: string) {
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/open?truckNo=" + truckNo + "&driverNo=" + driverNo + "&planningDate=" + planningDate).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public getOpenTosFromHaulier(haulierNo: string, planningDate: string) {
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/openFromHaulier?haulierNo=" + haulierNo + "&planningDate=" + planningDate).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public assignHaulierToPBs(haulierNo: string, planningBlockIds: number[]): Observable<TransportOrder> {
    const account = this.authService.getAccount();
    return this.http.post(environment.tmsApiEndpointAddress + "transportorders/assign-haulier-to-pbs?haulierId=" + haulierNo 
    +"&userName=" + account.username, planningBlockIds).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public planToExistingTos(toId: string, pbs: PlanningBlock[]): Observable<TransportOrder> {
    const account = this.authService.getAccount();
    return this.http.post(environment.tmsApiEndpointAddress + "transportorders/id/PlanningBlocks?toId=" + toId +
      "&scopeStartDate=1753-01-01&scopeEndDate=1753-01-01&userName=" + account.username, pbs).pipe(
        map((response: any) => response.data),
        catchError((err) => observableThrowError(err)));
  }

  public unplanPlanningBlocks(pbs: PlanningBlock[], cancelSendToHaulier: boolean): Observable<PlanningBlock[]> {
    // const expectedStatus = cancelSendToHaulier ? TO_BE_PLANNED : PRE_PLANNED;
    // const expectedStatus = this.selectedPlanningBlocks ? TO_BE_PLANNED : PRE_PLANNED;

    const account = this.authService.getAccount(); 
    let pbDelta = {
      PlanningBlocks: [],
      userName: account.username
    };
    pbs.forEach((pb: PlanningBlock) => {
      pbDelta.PlanningBlocks.push(
        {
          Id: pb.id,
          Status: {
            statusType: pb.statusType === PLANNED && !cancelSendToHaulier ? PRE_PLANNED : TO_BE_PLANNED
          },
          TransportOrderNumber: pb.transportOrderNumber
        }
      )
    });

    return this.http
      .patch(environment.tmsApiEndpointAddress + "planningBlocks", pbDelta).pipe(
        map((res: any) => {
          return res.data as PlanningBlock[]
        }),
        catchError((err) => observableThrowError(err)));
  }

  public prePlanAction(planningBlocks: PlanningBlock[], capacitySlot: CapacitySlot, planDate: string): Observable<any> {
    const account = this.authService.getAccount(); 
    return this.http
      .patch(environment.tmsApiEndpointAddress + "planningblocks/movements?scopeStartDate=" + planDate + "&scopeEndDate=" + planDate + "&userName=" + account.username, {
        'PlanningBlocks': planningBlocks,
        'Target': '',
        'Source': '',
        'CapacitySlot': capacitySlot
      }).pipe(
        map((res: any) => {
          return res.data
        }),
        catchError((err) => observableThrowError(err)));
  }

  public setImportantStatus(pbId: number, importantState: boolean, userId: string): Observable<any> {
    return this.http
      .put(environment.tmsApiEndpointAddress + "planningblocks/important", { PbId: pbId, ImportantState: importantState, UserId: userId }).pipe(
        map((res: any) => {
          return res.data
        }),
        catchError((err) => observableThrowError(err)));
  }

  /*public getDynamicCaption(table, field, lang): Observable<any> {
    return this.http.get(environment.tmsApiEndpointAddress + 'caption?table=' + table + '&field=' + field + '&lang=' + lang).pipe(
      map((res: any) => {
        return res.data
      }), catchError((error) => {
        return Observable.throw(error);
      })
    )
  }*/

  public assingPbToOtherPlanningGroup(planningBlocks: PlanningBlock[], planningGroup: String): Observable<PlanningBlock[]> {
    
    const account = this.authService.getAccount(); 
    let pbDelta = {
      PlanningBlocks: [],
      userName: account.username
    }

    planningBlocks.forEach((pb: PlanningBlock) => {
      pbDelta.PlanningBlocks.push(
        {
          Id: pb.id,
          PlanningGroup: planningGroup
        }
      )
    });

    return this.http
      .patch(environment.tmsApiEndpointAddress + "planningBlocks", pbDelta).pipe(
        map((res: any) => res.data as PlanningBlock[]),
        catchError((err) => observableThrowError(err)));
  };

  public getChassisForPbId(pbId: number): Observable<Chassis[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planningBlocks/' + pbId + '/chassis').pipe(
        map((response: any) => response.data as Chassis[]),
        catchError((err) => observableThrowError(err)));

  };

  public getTrailersForPbId(pbId: number): Observable<Trailer[]> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'planningBlocks/' + pbId + '/trailers').pipe(
        map((response: any) => response.data as Trailer[]),
        catchError((err) => observableThrowError(err)));

  };


  public movePlanningblocksToTO(planningBlocks, transportOrderTarget, scopeStartDate, scopeEndDate, capacitySlot): Observable<any> {
    const account = this.authService.getAccount(); 
    return this.http.patch(environment.tmsApiEndpointAddress  + "planningblocks/movements?scopeStartDate=" + scopeStartDate + "&scopeEndDate=" + scopeEndDate+ "&userName=" + account.username, {
        'PlanningBlocks': planningBlocks,
        'Target': transportOrderTarget,
        'CapacitySlot' : capacitySlot
    }).pipe(map((res : any) => {
      return res.data ;
    }),catchError((err) => observableThrowError(err)),);
}

  public updatePlanningBlockDates(planningblockIds, datetime): Observable<PlanningBlock[]> {
    return this.http.put(environment.tmsApiEndpointAddress + "planningblocks/datetime?planningBlockIds=" + planningblockIds + "&dateTime=" + datetime, datetime).pipe(
      map((res: any) => { return res.data }), catchError((err) => observableThrowError(err)))

  }


  public addPlanningblocksToTO(planningBlocks, transportOrderTarget, scopeStartDate, scopeEndDate, capacitySlot): Observable<any> {
    const account = this.authService.getAccount();
    return this.http.post(environment.tmsApiEndpointAddress + "transportOrders/id/planningblocks?toId="
      + encodeURI(transportOrderTarget.id) + "&scopeStartDate=" + scopeStartDate + "&scopeEndDate=" + scopeEndDate + "&userName=" + account.username, planningBlocks).pipe(
        map((res: any) => {
            return    res.data;
        }
        ), catchError((err) => observableThrowError(err)));
  }

  public registerCost(pbId: number, supplierNo: string, unitPrice: number, sendTO: boolean): Observable<PlanningBlock> {

    return this.http.post(environment.tmsApiEndpointAddress + "planningblocks/register-cost?pbId="+pbId+"&supplierNo="+supplierNo+"&unitPrice="+unitPrice+"&sendTO="+sendTO, null).pipe(
        map((res: any) => {
            return    res.data;
        }
        ), catchError((err) => observableThrowError(err)));
  }

  public getShippingRailDiaryByLineNumbers(lineNumbers): Observable<any> {

    return this.http
      .get(environment.tmsApiEndpointAddress + "planningblocks/shipping-rail-diary?lineNumbers=" + lineNumbers).pipe(
        map((response: any) => response.data));
  }

  public getShippingLines(pbIds) : Observable<any[]> {
    return this.http
    .get(environment.tmsApiEndpointAddress + "planningblocks/shipping-lines?pbIds=" + pbIds).pipe(
      map((response: any) => response.data));
  }

  public getRailwayLines() : Observable<any[]> {
    return this.http
    .get(environment.tmsApiEndpointAddress + "planningblocks/rail-lines").pipe(
      map((response: any) => response.data));
  }

  public getOrderRegisteredCost(orderNo: string) : Observable<OrderRegisteredCost[]> {
    return this.http
    .get(environment.tmsApiEndpointAddress + "planningblocks/order-registered-cost?orderNo=" + orderNo).pipe(
      map((response: any) => response.data));
  }

  public changeDeparture(departureData) : Observable<PlanningBlock[]> {
    return this.http.post(environment.tmsApiEndpointAddress + "planningblocks/change-departure" , departureData).pipe(
      map((response: any) => response.data), catchError((err) => observableThrowError(err)));
  }

  public changeRailwayOrShipping(changeRailwayOrShippingData) : Observable<PlanningBlock[]> {
    return this.http.post(environment.tmsApiEndpointAddress + "planningblocks/change-shipping-rail-line" , changeRailwayOrShippingData).pipe(
      map((response: any) => response.data), catchError((err) => observableThrowError(err)));
  }

  public getValidContainerStatuses(orderNumber: string, currentStatus: string) {
    return this.http
        .get(environment.tmsApiEndpointAddress + "planningblocks/" + orderNumber + "/statuses?currentStatus=" + currentStatus)
        .pipe(map((response:any) => {
            return response.data;
        }),
            catchError((err) => {
                return  Observable.throw(err);
            }));
  }
};


