import { OnInit, Component, Input, SimpleChanges, OnChanges, TemplateRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { TransportOrder } from "../../../../models/transport-order/transport-order.interface";
import { PlanningBlock } from "../../../../models/planning-block/planning-block.interface";
import { ToScreenService } from "../../../../services/to-screen.service";
import { Column } from "../../../../models/application.user.interface";
import { TranslateService } from "@ngx-translate/core";
import { SelectableMode, SelectableSettings, GridComponent } from "@progress/kendo-angular-grid";
import { SharedActions } from "../../../../../shared/shared.actions";


const columnDefinition = [
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.id', width : 100 },
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.orderNumber', width : 100 },
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.info', width : 100 },
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.containerNo', width : 100 },
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.startFrom', width : 100 },
    { name : 'COLUMN_SELECTION.COLUMN_NAMES.endUntil', width : 100 }
  ];


@Component({
    moduleId: module.id,
    selector: 'to-details',
    templateUrl: './to-details.component.html'
})
export class TODetailsComponent implements OnInit, OnChanges{
    
    @Input() public transportOrder: TransportOrder;
    @Output() public planningBlockSelection: EventEmitter<PlanningBlock> = new EventEmitter();
    @ViewChild('dateTemplate') public dateTemplate: TemplateRef<any>;
    @ViewChild(GridComponent) grid: GridComponent;

    public loading : boolean = true;
    public columns = [];
    public planningBlocks : PlanningBlock[];
    public selectedPlanningBlock : PlanningBlock = null;
    public mySelection: number[] = [];

    public checkboxOnly = false;
    public mode : SelectableMode = "single";
    public selectableSettings: SelectableSettings;

    constructor(private readonly toScreenService : ToScreenService,
                private readonly translate: TranslateService,private readonly sharedActions : SharedActions) { 
                    this.setSelectableSettings();
                }

    ngOnInit(): void {
        this.columns = this.createDataTablesColumns(columnDefinition);
    }
    
    public ngOnChanges(simpleChange: SimpleChanges): void {
        if (simpleChange && simpleChange.transportOrder) {
            if(simpleChange.transportOrder.currentValue != simpleChange.transportOrder.previousValue){
                this.toScreenService.getPlanningBlocksForTO(this.transportOrder.id).subscribe((data : TransportOrder) => {
                    this.planningBlocks = data.planningBlocks;
                    this.sharedActions.getOrderlines(data.planningOrderlines);
                    this.loading = false;
                })
            }
        }
    }

    public createDataTablesColumns(selectedColumns: Column[]): string[] {
        const result = [];
        for (const column of selectedColumns) {
    
          this.translate.get(column.name).subscribe((translated) => {
    
            result.push({
              cellTemplate: this.dateTemplate,
              name: translated,
              prop: column.name.substring(column.name.lastIndexOf('.') + 1),
              width: column.width,
            });
          });
        }
        return result;
    }

    
    public setSelectableSettings(): void {
        this.selectableSettings = {
            checkboxOnly: this.checkboxOnly,
            mode: this.mode
        };
    }

    public rowClicked(gridUser, selection): void {
        this.selectedPlanningBlock = selection.selectedRows[0].dataItem;
        if(this.selectedPlanningBlock) this.planningBlockSelection.emit(this.selectedPlanningBlock);
    }

    public clearSelection(){
        this.mySelection = [];
    }
}