
import {first} from 'rxjs/operators';
import { OnInit, Component, ElementRef, ViewChild } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { TransportOrder } from "../../../../pb-screen/models/transport-order/transport-order.interface";
import { OrderLine } from "../../../../pb-screen/models/order-line/orderLine.interface";
import { SelectionEnum } from "../../../models/selection.enum";
import { CommunicationPartner } from "../../../models/notification/communication-partner.interface";
import { SharedActions } from "../../../shared.actions";
import { HttpClientService } from "../../../services/httpclient";
import { StandardNotification } from "../../../models/notification/standard-notification.interface";
import { NotificationService } from "../../../services/notification.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ToActions } from "../../../../to-screen/to.actions";
import { Notification } from "../../../models/notification/notification.interface";
import { ApplicationUser } from '../../../../pb-screen/models/application.user.interface';

@Component({
    moduleId: module.id,
    selector: 'notification-modal',
    templateUrl: 'notification-modal.component.html'
})
export class NotificationModalComponent implements OnInit{
    @ViewChild('modal') public modal: ElementRef;

    @select('selectedPlanningBlocks') private readonly selectedPlanningBlocks$ : Observable<PlanningBlock[]>;
    @select('selectedTransportOrders') private readonly selectedTransportOrders$ : Observable<TransportOrder[]>;
    @select('selectedOrderlines') private readonly selectedOrderlines$ : Observable<OrderLine[]>;
    @select('communicationPartners') private readonly communicationPartners$: Observable<CommunicationPartner[]>;
    @select('standardNotifications') private readonly standardNotifications$: Observable<StandardNotification[]>;
    @select('planningBlockNotifications') public planningBlockNotifications$ : Observable<Notification[]>;
    @select('transportOrderNotifications') public transportOrderNotifications$ : Observable<Notification[]>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;

    private selectedPlanningBlocks : PlanningBlock[] = [];
    private selectedTransportOrders : TransportOrder[] = [];
    private selectedOrderlines : OrderLine[] = [];
    private selectedItemNotifications : Notification[] = [];
    private selectedRecipient : CommunicationPartner;
    private selectedStandardNotification : StandardNotification;
    private subject : string = "";
    private body : string = "";

    private planningBlockNotifications : Notification[] = [];
    private transportOrderNotifications : Notification[] = [];
    private notifications : Notification[] = [];

    private selectionType : SelectionEnum = SelectionEnum.None;
    private communicationPartners: CommunicationPartner[];
    private standardNotifications: StandardNotification[];
    private firstNotification: Notification;

    
    constructor(private readonly httpClientService: HttpClientService,
                private readonly notificationService: NotificationService,
                public readonly translate: TranslateService,
                public readonly toastr: ToastrService,
                private readonly sharedActions : SharedActions,
                private readonly toActions : ToActions) {
    }

    private resetModalSelection() {
        this.selectedRecipient = undefined;
        this.selectedStandardNotification = undefined;
        this.subject = "";
        this.body = "";
    }
    
    ngOnInit(): void {
        this.httpClientService.getToken().subscribe(() => {
            this.sharedActions.getCommunicationPartners();
            this.sharedActions.getStandardNotifications();

            this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
                if (applicationUser) {
                  this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                }
              });
            
            this.communicationPartners$.subscribe((communicationPartners : CommunicationPartner[]) => {
                this.communicationPartners = communicationPartners;
            });

            this.standardNotifications$.subscribe((standardNotifications : StandardNotification[]) => {
                this.standardNotifications = standardNotifications;
            })
        })

        this.planningBlockNotifications$.subscribe((data) => {
            if(data)
            {
                this.planningBlockNotifications = data;
            }
            
        })

        this.transportOrderNotifications$.subscribe((data) => {
            if(data)
            {
                this.transportOrderNotifications = data;
            }
        })


        this.selectedPlanningBlocks$.subscribe((selection: PlanningBlock[]) => {
            if(selection && selection.length > 0 && this.selectionType !== SelectionEnum.Orderline) { 
                this.selectionType = SelectionEnum.PlanningBlock;
                this.selectedPlanningBlocks = selection;
            }
            else if(!selection){
                this.selectionType = SelectionEnum.None;
            }

            this.setSelectedItemNotifications();
        });

        
        this.selectedTransportOrders$.subscribe((selection: TransportOrder[]) => {
            if(selection && selection.length > 0 && this.selectionType !== SelectionEnum.PlanningBlock  ) {
                this.selectionType = SelectionEnum.TransportOrder;
                this.selectedTransportOrders = selection;
            }
            else if(!selection){
                this.selectionType = SelectionEnum.None;
            }

            this.setSelectedItemNotifications();
        });

        this.selectedOrderlines$.subscribe((selection: OrderLine[]) => {
            if(selection && selection.length > 0 ) {
                this.selectionType = SelectionEnum.Orderline;
                this.selectedOrderlines = selection;
            }
            else {
                this.selectionType = SelectionEnum.None;
            }

            this.setSelectedItemNotifications();
        });

    }

    public setSelectedItemNotifications(){
        if(this.selectionType === SelectionEnum.PlanningBlock)
        {
            if(this.selectedPlanningBlocks.length > 0 ) 
            {
            this.selectedItemNotifications = this.planningBlockNotifications.filter(x => x.planningBlockId === this.selectedPlanningBlocks[0].id);
            this.firstNotification = this.selectedItemNotifications[0];
            }
        }

        if(this.selectionType === SelectionEnum.TransportOrder)
        {
            if(this.selectedTransportOrders.length > 0) 
            {
            this.selectedItemNotifications = this.transportOrderNotifications.filter(x => x.transportOrderNumber === this.selectedTransportOrders[0].id);
            this.firstNotification = this.selectedItemNotifications[0];
            }
        }
        
    }

    public updateRecipient(recipient : CommunicationPartner){
    }

    public updateStandardNotification(standard : StandardNotification){
        this.subject = standard.subject;
    }

    public send() : void {
        let itemId = "";
        let orderId = "";
        if(this.selectionType === SelectionEnum.Orderline){
            itemId = this.selectedOrderlines[0].lineNumber.toString();
            orderId = this.selectedOrderlines[0].orderNumber.toString();
        }
        if(this.selectionType === SelectionEnum.PlanningBlock){
            itemId = this.selectedPlanningBlocks[0].id.toString();
        }
        if(this.selectionType === SelectionEnum.TransportOrder){
            itemId = this.selectedTransportOrders[0].id;
        }

        this.notificationService.createNotification(this.selectionType, itemId, orderId, this.body, this.subject, this.selectedRecipient.code, this.selectedStandardNotification.code).subscribe(() => {
            this.translateString('TOASTR_MESSAGES.SUCCESS.NOTIFICATION_REGISTERED_SUCCESS').then((result) => {
                if(this.selectionType === SelectionEnum.PlanningBlock)
                {
                    this.sharedActions.getAllPlanningBlockNotifications();
                }
                if(this.selectionType === SelectionEnum.TransportOrder)
                {
                    this.sharedActions.getAllTransportOrderNotifications();
                }
                this.toastr.success(result.toString());
            });
            this.closeModal();
        }, (err) => {
            this.translateString('TOASTR_MESSAGES.ERROR.NOTIFICATION_REGISTERED_ERROR').then((result) => {
                this.toastr.error(result);
            });
        });
    }
    
    public translateString(text: string) {
        return this.translate.get(text).pipe(first()).toPromise();
    }

    public autocompleListFormatterForRecipients(data: CommunicationPartner) : string {
        return data.code;
    }
    
    public valueListFormatterForRecipients(data: CommunicationPartner): string {
        return data.code;
    }

    public autocompleListFormatterForStandardNotifications(data: StandardNotification) : string {
        return data.code;
    }
    
    public valueListFormatterForStandardNotification(data: StandardNotification): string {
        return data.description;
    }

    public closeModal() : void {
        this.resetModalSelection();
        this.modal.nativeElement.click();
    }
}