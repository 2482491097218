import { IAppState } from './IAppState';

import { initialState } from './initial.state';
import { sharedReducer } from './shared.reducer';
import { ActionKeys } from '../pb-screen/action.keys';
import { PbReducer } from './pb.reducer';
import { Reducer } from 'redux';
import { ToReducer } from './to.reducer';
import { PlanningBoardReducer } from './planningBoard.reducer';
import { agendaReducer } from './agenda.reducer';
import { announcementReducer } from './announcement.reducer';
import { stackerReducer } from './stacker.reducer';
import { poolReducer } from './pool.reducer';
import { depotInReducer } from './depot-in.reducer';
import { depotOutReducer } from './depot-out.reducer';


export function reducer(state: IAppState = initialState, action: any): IAppState {
    switch (action.type) {
        case ActionKeys.GET_APPLICATION_USER_SUCCESS:
        case ActionKeys.GET_APPLICATION_USER_NOTFOUND:
        case ActionKeys.GET_APPLICATION_USER_FAILED:
        case ActionKeys.UPDATE_APPLICATION_USER:
        case ActionKeys.SET_SESSION_USERSETTINGS_DATA:
        case ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS:
        case ActionKeys.REQUEST_ORDERLINES_SUCCESS:
        case ActionKeys.SET_QUOTELINES:
        case ActionKeys.SET_PLANNINGBLOCKS_FOR_TO:
        case ActionKeys.REQUEST_HAULIERS_SUCCESS:
        case ActionKeys.SET_TMS_PLANNING_SETUP:
        case ActionKeys.SET_SELECTED_ACTION_WIZARD:
        case ActionKeys.SET_DEFAULT_PLANNINGZONE:
        case ActionKeys.REQUEST_DRIVERS_SUCCESS:
        case ActionKeys.REQUEST_PLANNINGGROUPS_SUCCESS:
        case ActionKeys.SET_CONTAINER_STAUSES:
        case ActionKeys.REQUEST_COMMUNICATION_PARTNERS_SUCCESS:
        case ActionKeys.REQUEST_STANDARD_NOTIFICATIONS_SUCCESS:
        case ActionKeys.UPDATE_SESSION_Document:
        case ActionKeys.GET_ACTIVE_PLANNING_CONFLICTS:
        case ActionKeys.SHARED_UPDATE_PLANNINGBLOCKS_DISPLAY:
        case ActionKeys.UPDATE_DEPOTS_DISPLAY:
        case ActionKeys.SHARED_UPDATE_TRANSPORTORDERS_DISPLAY:
        case ActionKeys.GET_CMR_ACTIONS:
        case ActionKeys.GET_MONITOR_SERVICE_STATUS:
        case ActionKeys.Add_CHANNEL_EVENT_TO_INVENTORY:
        case ActionKeys.REQUEST_ALL_NOTIFICATIONS_SUCCESS:
       case ActionKeys.REQUEST_Waiting_Room_Details_SUCCESS:

        case ActionKeys.REQUEST_ALL_PLANNING_BLOCK_NOTIFICATIONS_SUCCESS:
        case ActionKeys.REQUEST_ALL_TRANSPORT_ORDER_NOTIFICATIONS_SUCCESS:
        case ActionKeys.SET_PLANNING_CONFLICTS:
        case ActionKeys.REQUEST_TRUCKDEPARTMENTS_SUCCESS:
        case ActionKeys.REQUEST_AZURE_USERS_SUCCESS:
        case ActionKeys.STORE_USER_AZURE_GROUPS:
        case ActionKeys.STORE_IGNORE_CONFLICT_REASONS:
        case ActionKeys.STORE_PLANNING_ZONE:
        case ActionKeys.SET_SELECTED_TRANSPORT_ORDERS_FOR_INTERWINDOW_PLANNING:
        case ActionKeys.STORE_TRAILERS_FOR_PB:
        case ActionKeys.STORE_CHASSIS_FOR_PB:
        case ActionKeys.PLAN_BOARD_MONITOR_STATUS:
        case ActionKeys.REQUEST_ORDERLINES_SUCCESS2:
        case ActionKeys.GET_APPLICATION_USER_SUCCESS:
        case ActionKeys.GET_APPLICATION_USER_NOTFOUND:
        case ActionKeys.GET_APPLICATION_USER_FAILED:
        case ActionKeys.UPDATE_APPLICATION_USER:
        case ActionKeys.SET_SESSION_USERSETTINGS_DATA:
        //shared
        case ActionKeys.SET_CPL_ACTION_BAR_STATES:
        case ActionKeys.RESET_CPL_ACTION_BAR_STATES:
        case ActionKeys.UPDATE_CPL_PLANNINGBLOCKS_DISPLAY:
        case ActionKeys.ADD_PLANNINGBLOCKS_DISPLAY:
        case ActionKeys.DELETE_PLANNINGBLOCKS_DISPLAY:
        case ActionKeys.SET_UI_STATE:
        case ActionKeys.RESET_MODAL_DOMAINS:
        case ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS:
        case ActionKeys.SET_PLANNING_GROUPS:
        case ActionKeys.GET_MONITOR_SERVICE_STATUS:
        case ActionKeys.Add_CHANNEL_EVENT_TO_INVENTORY:
        case ActionKeys.SET_GROUP_STRING:
        case ActionKeys.STORE_PLANNING_CONFLICTS:
        case ActionKeys.SELECT_CONFLICT:
        case ActionKeys.STORE_PB_WIZARD_DEFINITIONS:
        case ActionKeys.STORE_TO_WIZARD_DEFINITIONS:
        case ActionKeys.RESET_STATE:
        case ActionKeys.SET_GLOBAL_SETTINGS:
            return sharedReducer(state, action);

        case ActionKeys.REQUEST_PLANNINGBLOCKS_SUCCESS:
        case ActionKeys.APPLY_GLOBAL_FILTER:
        case ActionKeys.APPLY_QUICK_FILTER:
        case ActionKeys.RESET_QUICK_FILTER:
        case ActionKeys.UPDATE_SESSION_COLUMNS:
        case ActionKeys.APPLY_ADVANCED_FILTER:
        case ActionKeys.RESET_ADVANCED_FILTER:
        case ActionKeys.RESET_PLANNINGBLOCKS:
        case ActionKeys.SET_PROCESSED_DISPLAY_STATE:
        case ActionKeys.SET_IMPORTANT_DISPLAY_STATE:
        case ActionKeys.SET_SELECTED_PLANNING_BLOCKS:
        case ActionKeys.SET_REUSE_CANDIDATES:
        case ActionKeys.SET_Shared_SELECTED_PLANNING_BLOCKS:
        case ActionKeys.UPDATE_PLANNINGBLOCKS_DISPLAY:
        case ActionKeys.SET_ACTION_BAR_STATES:
        case ActionKeys.RESET_ACTION_BAR_STATES:
        case ActionKeys.SET_DATE_INTERVAL:
        case ActionKeys.SET_ORDERLINES_FOR_PLANNINGBLOCK:
        case ActionKeys.SET_SELECTED_DISPLAY_STATE:
        case ActionKeys.UPDATE_PBORDERLINE_SESSION_COLUMNS:
        case ActionKeys.SET_SHIPPING_RAIL_DATA:
            return PbReducer(state, action);
        case ActionKeys.REQUEST_TRANSPORTORDERS_SUCCESS:
        case ActionKeys.REQUEST_OPEN_TRANSPORTORDERS_SUCCESS:
        case ActionKeys.APPLY_TO_GLOBAL_FILTER:
        case ActionKeys.FILTER_OPEN_TRANSPORT_ORDERS:
        case ActionKeys.RESET_OPEN_TRANSPORTORDERS_FILTER:
        case ActionKeys.UPDATE_TO_SESSION_COLUMNS:
        case ActionKeys.SET_SELECTED_TRANSPORT_ORDERS:
        case ActionKeys.UPDATE_ORDERLINE_SESSION_COLUMNS:
        case ActionKeys.SET_SELECTED_ORDER_LINES:
        case ActionKeys.APPLY_TO_QUICK_FILTER:
        case ActionKeys.RESET_TO_QUICK_FILTER:
        case ActionKeys.UPDATE_TOS_DISPLAY:
        case ActionKeys.SET_TO_ACTION_BAR_STATE:
        case ActionKeys.RESET_TO_ACTION_BAR_STATE:
        case ActionKeys.SET_TO_PROCESSED_DISPLAY_STATE:
        case ActionKeys.SET_FINALIZED_COMBI_STATE:
        case ActionKeys.SELECTED_TO_FOR_DETAILS:
            return ToReducer(state, action);
        case ActionKeys.STORE_PLANNING_FOR_DATE_SUCCESS:
        case ActionKeys.PLANNING_CONFLICTS_FOR_DOCK:
        case ActionKeys.STORE_UNPLANNED_PBS_SUCCESS:
        case ActionKeys.STORE_SELECTION_TYPE:
        case ActionKeys.UPDATE_UNPLANNED_DISPLAY:
        case ActionKeys.UPDATE_ORDERLINES_DISPLAY:
        case ActionKeys.SET_SELECTED_PBS_UNPLANNED:
        case ActionKeys.STORE_WEEK_PLANNING_DATA_SUCCESS:
        case ActionKeys.STORE_WEEK_UNPLANNED_PBS_SUCCESS:
        case ActionKeys.SET_SELECTED_ORDERLINES_FROM_PLANBOARD:
        case ActionKeys.SET_SELECTED_QUOTELINES_FROM_BOARD:
        case ActionKeys.SELECT_PBS_FROM_PLAN_BOARD:
        case ActionKeys.SET_SELECTED_CAPACITY_FROM_PLANBOARD:
        case ActionKeys.STORE_OPEN_TOS_FO_DATE_SUCCESS:
        case ActionKeys.UPDATE_PLANNING_DISPLAY:
        case ActionKeys.REMOVE_TOS_FROM_CAPACITY:
        case ActionKeys.SET_LOADING:
        case ActionKeys.SET_ORDERLINES_DOCK_LOADING:
            return PlanningBoardReducer(state, action);
        case ActionKeys.STORE_SELECTED_QUOTES:
            return PlanningBoardReducer(state,action);
        case ActionKeys.SET_SELECTED_ABSENCE:
        case ActionKeys.SET_SELECTED_DRIVER_FROM_AGENDA:
        case ActionKeys.SET_SELECTED_TRUCK_ASSIGNEMENT:
        case ActionKeys.SET_DRIVER_AGENDA_DATA:
        case ActionKeys.SET_TRUCK_AGENDA_DATA:
        case ActionKeys.SET_SELECTED_ASSIGNEMENT:
        case ActionKeys.SET_SELECTED_UNVAIILABILITY:
        case ActionKeys.SET_SELECTED_TRUCK_FROM_AGENDA:
            return agendaReducer(state, action);
         //APPLICATION
         case ActionKeys.GET_APPLICATION_USER_SUCCESS:
            case ActionKeys.GET_APPLICATION_USER_NOTFOUND:
            case ActionKeys.GET_APPLICATION_USER_FAILED:
            case ActionKeys.UPDATE_APPLICATION_USER:
            case ActionKeys.SET_SESSION_USERSETTINGS_DATA:
            case ActionKeys.SET_UI_STATE:
            case ActionKeys.RESET_MODAL_DOMAINS:
            case ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS:
            case ActionKeys.SET_PLANNING_GROUPS:
            case ActionKeys.HTTP_REQUEST_PENDING:
            case ActionKeys.SET_GROUP_STRING:
                return sharedReducer(state, action);
            //DEPOT OUT
            case ActionKeys.UPDATE_DEPOT_OUT_PLANNED_PLANNING_BLOCKS:
            case ActionKeys.FILTER_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.REQUEST_DEPOT_OUT_PLANNINGBLOCKS_SUCCESS:
            case ActionKeys.QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.RESET_QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.UPDATE_DEPOT_OUT_COLUMN_SELECTION:
            case ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.RESET_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.SET_DEPOT_OUT_SELECTION:
            case ActionKeys.SET_DEPOT_OUT_CANDIDATES:
            case ActionKeys.RESET_DEPOT_OUT_CANDIDATES:
            case ActionKeys.SET_DEPOT_OUT_OVERBOOKING_CANDIDATES:
            case ActionKeys.RESET_DEPOT_OUT_PLANNINGBLOCKS:
            case ActionKeys.SET_DEPOT_OUT_PROCESSED_DISPLAY_STATE:
                return depotOutReducer(state, action);
            case ActionKeys.SET_DEPOT_OUT_IMPORTANT_ONLY_DISPLAY_STATE:
                return depotOutReducer(state, action);
            //DEPOT_IN
            case ActionKeys.UPDATE_DEPOT_IN_PLANNED_PLANNING_BLOCKS:
            case ActionKeys.FILTER_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.REQUEST_DEPOT_IN_PLANNINGBLOCKS_SUCCESS:
            case ActionKeys.QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.RESET_QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.UPDATE_DEPOT_IN_COLUMN_SELECTION:
            case ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.RESET_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.SET_DEPOT_IN_SELECTION:
            case ActionKeys.SET_DEPOT_IN_CANDIDATES:
            case ActionKeys.RESET_DEPOT_IN_CANDIDATES:
            case ActionKeys.SET_DEPOT_IN_OVERBOOKING_CANDIDATES:
            case ActionKeys.RESET_DEPOT_IN_PLANNINGBLOCKS:
            case ActionKeys.SET_DEPOT_IN_PROCESSED_DISPLAY_STATE:
                return depotInReducer(state, action);
            case ActionKeys.SET_DEPOT_IN_IMPORTANT_ONLY_DISPLAY_STATE:
                return depotInReducer(state, action);
            // pool: 
            case ActionKeys.SET_SELECTED_POOL_BOOKING:
            case ActionKeys.SET_POOL_BOOKINGS:
            case ActionKeys.MONITOR_POOL_BOOKINGS:
            case ActionKeys.FILTER_EXISTING_POOL_BOOKINGS:
            case ActionKeys.FILTER_NEW_POOL_BOOKINGS:
            case ActionKeys.GET_POOL_MOVES:
            case ActionKeys.GET_ACTIVE_POOL_MOVES:
            case ActionKeys.UPDATE_ACTIVE_POOL_MOVES:
            case ActionKeys.FILTER_POOL_MOVES:
            case ActionKeys.SET_SELECTED_POOL_MOVE:
            case ActionKeys.SET_SELECTED_ACTIVE_POOL_MOVE:
            case ActionKeys.SET_POOL_CONTAINERS:
            case ActionKeys.FILTER_POOL_CONTAINERS:
            case ActionKeys.SET_SELECTED_POOL_CONTAINER:
            case ActionKeys.GET_CARRIERS:
            case ActionKeys.GET_EQUIPMENT_TYPES:
            case ActionKeys.GET_HAULIERS:
            case ActionKeys.SET_SELECTED_HAULIER:
            case ActionKeys.GET_DRIVERS:
            case ActionKeys.SET_SELECTED_DRIVER:
            case ActionKeys.GET_TRUCKS:
            case ActionKeys.SET_SELECTED_TRUCKS:
            case ActionKeys.SET_WIZARD_COMMENT:
            case ActionKeys.SET_CONTAINER_REMARKS:
            case ActionKeys.GET_CHECKLIST_ITEMS:
            case ActionKeys.SET_SELECTED_CHECKLIST_ITEMS:
            case ActionKeys.SET_SELECTED_TO_LOCATION:
            case ActionKeys.SET_ASSIGNMENT_DIRECTION:
            case ActionKeys.FILTER_ACTIVE_POOL_MOVES:
            case ActionKeys.FILTER_ACTIVE_POOL_MOVES:
                return poolReducer(state, action);
           
            case ActionKeys.SET_ALL_POOL_CONTAINERS:
            case ActionKeys.FILTER_CONTAINER_POOL_ANNOUNCEMENT:
            case ActionKeys.RESET_POOL_CONTAINERS:
            case ActionKeys.QUICK_FILTER_CONTAINER_POOL_ANNOUNCEMENT:
            case ActionKeys.SET_POOL_CONTAINER_FOR_ANNOUNCEMENT:
            case ActionKeys.SET_ANNOUNCED_POOL_CONTAINERS:
            case ActionKeys.SET_SELECTED_ANNOUNCED_CONTAINER:
            case ActionKeys.FILTER_ANNOUNCED:
            case ActionKeys.RESET_ANNOUNCED:
            case ActionKeys.MONITOR_ANNOUNCE_CONTAINER:
            case ActionKeys.SET_ANNOUNCED_CONTAINER_DAMAGE:
            case ActionKeys.QUICK_FILTER_ANNOUNCED_CONTAINERS:
            case ActionKeys.SET_PREANNOUNCED_CONTAINER_Moves:
            case ActionKeys.QUICK_FILTER_PREANNOUNCED_CONTAINER_MOVES:
            case ActionKeys.RESET_PREANNOUNCED_CONTAINER_MOVES:
            case ActionKeys.SET_SELECTED_PREANNOUNCED_POOL_MOVE:
            case ActionKeys.RESET_ANNOUNCEMENT_FILTER:
            case ActionKeys.UPDATE_ANNOUNCED_SELECTION:
                return announcementReducer(state,action);
            case ActionKeys.STORE_PLANNING_BLOCKS_FOR_STACKER:
            case ActionKeys.UPDATE_STACKER_COLUMN_SELECTION:
            case ActionKeys.QUICK_FILTER_STACKER_PLANNING_BLOCKS:
            case ActionKeys.SET_FILTER_STACKER_VALUE:
            case ActionKeys.SET_SELECTED_STACKER_PBS:
            case ActionKeys.STORE_STACKER_LOCATIONS:
            case ActionKeys.TXT_FILTER_STACKER_PBS:
            case ActionKeys.TXT_FILTER_VALUE:
            case ActionKeys.UPDATE_STACKER_DISPLAY:
            case ActionKeys.REGISTER_STACKER_ACTIONS_UNDO:
            case ActionKeys.REGISTER_STACKER_ACTIONS_REDO:
            case ActionKeys.STORE_STIES:
                return stackerReducer(state,action);

        default:
            return state;
    }
}
