export class PoolCarrier {
    timestamp: string;
    no: string;
    description: string;
    customerNo: string;
    
    constructor() {
        this.timestamp = "";
        this.no = "";
        this.description = "";
        this.customerNo = "";
    }
}
