
import {catchError, map} from 'rxjs/operators';
import { ToActions } from '../../to.actions';
import { ToScreenService } from '../../../pb-screen/services/to-screen.service';
import { TransportOrder } from '../../../pb-screen/models/transport-order/transport-order.interface';
import { select } from "@angular-redux/store";
import { Component, ElementRef, Input, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable ,  of, Subject } from "rxjs";
import { HomeService } from '../../../pb-screen/services/home.service';
import { CapacitySlot } from '../../../pb-screen/models/capacity-slot.interface';
import { PlanningConflict } from '../../../pb-screen/models/planning-conflict.interface';
import { PlanningConflictType } from '../../../pb-screen/models/planning-conflict-type.enum';
import { SharedService } from '../../../shared/services/shared.service';
import { Haulier } from '../../../pb-screen/models/haulier.interface';
import { PRE_PLANNED } from '../../../pb-screen/models/status-type.interface';
import { Driver } from '../../../static/models/driver.interface';
import { SharedActions } from '../../../shared/shared.actions';
import { IdNameAsStringObject } from '../../../pb-screen/models/quick-view/id-name-as-string-object.interface';
import { ToCapacitySelectorComponent } from '../to-capacity-selector/to-capacity-selector.component';
import { SimpleModalComponent } from 'ngx-simple-modal';
import { takeUntil } from 'rxjs/operators';
import { PlanningBoardActions } from '../../../planningBoard/planningBoard.action';

@Component({
    moduleId: module.id,
    selector: "to-planning-modal",
    templateUrl: "./to-planning-modal.component.html",
})

export class ToPlanningModalComponent extends SimpleModalComponent<null, null>  implements OnInit, OnDestroy {
    
    @select("selectedTransportOrders") public selectedTransportOrders$: Observable<TransportOrder[]>;
    @ViewChild(ToCapacitySelectorComponent) public capacitySelectorComponent: ToCapacitySelectorComponent;
    @select("capacitySlots") public capacitySlots$: Observable<CapacitySlot[]>;
    @select("drivers") public drivers$: Observable<Driver[]>; 
    @select('loading') public loading$: Observable<boolean>;

    
    public capacitySlots: CapacitySlot[] = [];
    public selectedTransportOrders: TransportOrder[] = [];
    public drivers: Driver[] = [];
    public selectedPlanningGroup: IdNameAsStringObject;
    public selectedHaulier: Haulier;
    public selectedDriver: Driver;
    public selectedCoDriver: Driver;
    public loadingSpinner: boolean = false;
    public selectedCapacity: CapacitySlot;
    public selectedTransportOrder: TransportOrder = null;
    public assignedDriver : string = "";
    public assignedTruck : string = "";
    destroy$ = new Subject();

    constructor(
        public readonly toScreenService: ToScreenService,
        public readonly toActions: ToActions,
        public readonly toastr: ToastrService,
        public readonly homeService: HomeService,
        private readonly translate: TranslateService,
        public readonly sharedService: SharedService,
        public readonly sharedActions: SharedActions,
        public readonly planningBoardActions: PlanningBoardActions
    ) {
        super();
     }

  public ngOnInit(): void {

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    });
        
        this.drivers$.pipe(takeUntil(this.destroy$)).subscribe((drivers: Driver[]) => {
            if(drivers) this.drivers = drivers;
            if(this.drivers.length > 0){

                if(this.assignedDriver.length > 0){
                    let toDriverList = this.drivers.filter(d => d.id === this.assignedDriver);
                    if(toDriverList.length > 0)
                    this.selectedDriver = toDriverList[0];
                }
                else this.selectedDriver = null;

                if(this.selectedTransportOrder){
                    let toCoDriverList = this.drivers.filter(d => d.id === this.selectedTransportOrder.firstCoDriver);
                    if(toCoDriverList.length > 0)
                    this.selectedCoDriver = toCoDriverList[0];
                }
                else this.selectedCoDriver = null;
            }
        })

        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((tos: TransportOrder[]) => {
            this.clearModalSelection();
            this.selectedTransportOrders = tos;
            this.selectedTransportOrder = tos[0];
            
            if(this.selectedTransportOrders.length === 1) {
                    this.assignedDriver = this.selectedTransportOrder.firstDriver;
                    this.assignedTruck = this.selectedTransportOrder.truckNo;

                    let selectedCapacity = this.capacitySlots.filter((capacitySlot : CapacitySlot) => {
                        return capacitySlot.truck.id === this.assignedTruck;
                    })[0];

                    if(selectedCapacity) {
                        this.selectedCapacity = selectedCapacity;
                    }
                    else{
                        this.selectedCapacity = null;
                    }
                    
                    if(this.drivers && this.drivers.length > 0){
                        let toDriverList = this.drivers.filter(d => this.assignedDriver && d.id === this.assignedDriver);
                        if(toDriverList.length > 0)
                        this.selectedDriver = toDriverList[0];

                        let toCoDriverList = this.drivers.filter(d => this.selectedTransportOrder && d.id === this.selectedTransportOrder.firstCoDriver);
                        if(toCoDriverList.length > 0)
                        this.selectedCoDriver = toCoDriverList[0];
                    }
                    else{
                        this.selectedDriver = null;
                        this.selectedCoDriver = null;
                    }
                }
        });

        
        this.capacitySlots$.pipe(takeUntil(this.destroy$)).subscribe((capacitySlots: CapacitySlot[]) => {
            this.capacitySlots = capacitySlots;
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public getSelectedPlanningGroup(planningGroup: IdNameAsStringObject) {
        this.selectedPlanningGroup = planningGroup;
    }

    public getSelectedCapacity(capacity: CapacitySlot) {
        this.selectedCapacity = capacity;
    }

    
    public getSelectedHaulier(haulier: Haulier) {
            this.selectedHaulier = haulier;
    }

    public getSelectedDriver(driver: Driver) {
        this.selectedDriver = driver;
    }

    public getSelectedCoDriver(driver: Driver) {
        this.selectedCoDriver = driver;
    }

    capacityChangedForSelectedTransportOrder() : boolean {
        return this.assignedDriver != this.selectedTransportOrders[0].firstDriver 
                || this.assignedTruck != this.selectedTransportOrders[0].truckNo;
    }

    haulierChangedForSelectedTransportOrder() : boolean{
        return this.selectedHaulier.id != this.selectedTransportOrders[0].haulierNo;
    }

    public assignCapacity(): void {
        this.toScreenService.assignCapacityToTO(this.selectedTransportOrders[0], 
                                                this.selectedCapacity, 
                                                this.selectedPlanningGroup? this.selectedPlanningGroup.id : "",
                                                this.selectedHaulier? this.selectedHaulier.id : "", 
                                                this.selectedDriver? this.selectedDriver.id : " ", 
                                                this.selectedCoDriver? this.selectedCoDriver.id : " ")
                    .subscribe((to : TransportOrder) => {
                        this.sharedActions.updateTransportOrdersDisplay([to]);
                        this.toActions.setSelectedTransportOrders([to]);
                        this.planningBoardActions.UpdatePlanningDisplay([to]);
                        this.toastr.success("Capacity assigned successfully");
                        this.calculateAndDisplayPlanningConflicts(to.id).subscribe();
                        this.closeModal();
                    }, (err) => {
                        this.loadingSpinner = false;
                });
    }

    public calculateAndDisplayPlanningConflicts(transportOrderNumber: string): Observable<any> {
        const conflictMessageConfig = {
            timeOut: 10000,
            extendedTimeOut: 10000,
            closeButton: true
        };

        return this.sharedService.calculatePlanningBlockConflicts(transportOrderNumber).pipe(
            map((conflicts: PlanningConflict[]) => {
                const uniqueConflicts = [...conflicts.reduce((accu, current) => {
                    accu.set(current.conflictMessage || current.conflictReason || current.ressourceNo, current);
                    return accu;
                }, new Map()).values()];

                uniqueConflicts.forEach((planningConflict: PlanningConflict) => {
                    const formattedConflictMessage = planningConflict.conflictMessage != "" ? planningConflict.conflictMessage : planningConflict.conflictReason;

                    switch (planningConflict.conflictType) {
                        case PlanningConflictType.WARNING:
                            this.toastr.warning(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                        case PlanningConflictType.ERROR:
                            this.toastr.error(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                        default:
                            this.toastr.info(formattedConflictMessage, "Planning Conflict: ", conflictMessageConfig);
                            break;
                    }
                });
            }),
            catchError((err) => {
                this.loadingSpinner = false;
                this.translate.get("TOASTR_MESSAGES.ERROR.CALCULATING_CONFLICT_ERROR").subscribe((res: string) => {
                    this.toastr.success(res);
                });
                return of([]);
            }),);
    }

    public closeModal(): void {
        this.close();
    }


    clearModalSelection() {
        this.selectedCapacity = null;
        if(this.capacitySelectorComponent) this.capacitySelectorComponent.selectedCapacity = null;

        this.selectedHaulier = null;
        if(this.capacitySelectorComponent) this.capacitySelectorComponent.selectedHaulier = null;

        this.selectedDriver = null;
        if(this.capacitySelectorComponent) this.capacitySelectorComponent.selectedDriver = null;

        this.selectedCoDriver = null;
        if(this.capacitySelectorComponent) this.capacitySelectorComponent.selectedCoDriver = null;
    }

}   
