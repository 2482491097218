import { SharedService } from './../services/shared.service';
import { Component, OnInit, Inject } from "@angular/core";
import { APP_CONFIG, AppConfig } from "../../app.config";
import { Title } from "@angular/platform-browser";



@Component({
    selector: 'app-home',
    templateUrl: './home.component.html'
})
export class HomeComponent implements OnInit {
    public isIframe = false;
    public loggedIn = false;

    constructor(public readonly titleService: Title,
        // private broadcastService: BroadcastService, private authService: MsalService,
        private readonly sharedService: SharedService) { }

    ngOnInit() {
        this.titleService.setTitle('Home');
        this.isIframe = window !== window.parent && !window.opener;

        // this.checkoutAccount();

    //     this.broadcastService.subscribe('msal:loginSuccess', () => {
    //         this.checkoutAccount();
    //     });

    //     this.authService.handleRedirectCallback((authError, response) => {
    //         if (authError) {
    //             console.error('Redirect Error: ', authError.errorMessage);
    //             return;
    //         }

    //         console.log('Redirect Success: ', response.accessToken);
    //     });

    //     this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
    //         console.log('MSAL Logging: ', message);
    //     }, {
    //         correlationId: CryptoUtils.createNewGuid(),
    //         piiLoggingEnabled: false
    //     }));
    // }
    }
    // checkoutAccount() {
    //     this.loggedIn = !!this.authService.getAccount();
    // }

    // login() {
    //     const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

    //     if (isIE) {
    //         this.authService.loginRedirect();
    //     } else {
    //         this.authService.loginPopup();
    //     }
    // }

    // test() {
    //     this.sharedService.getTMSPlanningSetup().subscribe((data) => {
    //         alert('ok')
    //         console.log(data)
    //     })
    // }

    // logout() {
    //     this.authService.logout();
    //     localStorage.clear();
    // }

}