import { OnInit, Component, ViewChild, ElementRef, Input, EventEmitter, Output } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { WizardDefinition } from "../../../shared/models/wizard-definition";
import { ToScreenService } from "../../../pb-screen/services/to-screen.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { HttpClientService } from "../../../shared/services/httpclient";
import {PlanningConflict} from "../../../pb-screen/models/planning-conflict.interface" ; 
import { IgnoreConflictReason } from "../../../pb-screen/models/ingore-conflict-reason.interface";
import { IdNameAsStringObject } from "../../../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { ListItem } from "../../../pb-screen/models/pb-screen.interface";
import { SendToWithUserParams } from "../../../pb-screen/models/send-to-params.interface";
import { error } from "protractor";

@Component({  
    selector : "planning-conflicts" , 
    templateUrl: './conflicts-list-modal.component.html'  , 
    styleUrls : ['./conflicts-list-modal-style.css']
}) 

export class PlanningConflictsComponent implements OnInit {   
    @Input() public conflicts : Observable<PlanningConflict[]> ;

    @Output() CancelOperation = new EventEmitter<void>() ;
    
    @Output() ConfirmOperation = new EventEmitter<Function>(); 

    @select('selectedTransportOrders') selectedTransportOrders$: Observable<TransportOrder[]>;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    @select('ignoreConflictReasons') ignoreConflictReasons$: Observable<IgnoreConflictReason[]>;
    @select('azureUsers') public azureUsers$: Observable<any[]>;
    @ViewChild("modal") public modal: ElementRef;  

    public IsConfirmed : boolean ; 
    public igonreConflictReasons: ListItem[] = [];
    public selectedReason: any;
    public message: any;
    public transportOrder: TransportOrder;
    public user: any;

    constructor( public readonly toScreenService: ToScreenService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly httpClientService: HttpClientService) 
    {

    }
    
    ngOnInit() {  

        this.ignoreConflictReasons$.subscribe((reasons: IgnoreConflictReason[]) => {
            if(reasons)
            {
                reasons.forEach((item) => {
                    let obj : ListItem = {
                        id : item.ignoreConflictReasonNo, 
                        value : item.description
                    }
                    this.igonreConflictReasons.push(obj);
                })
            }
        })

        this.selectedTransportOrders$.subscribe((data : TransportOrder[]) => {
            if(data){
                this.transportOrder = data[0];
            }
        })

        this.azureUsers$.subscribe((data) => {
            if(data) {
                this.user = data.filter(x => x.objectId == localStorage.getItem('userId'));
            }
        })
    }

    onCancel() { 
        this.CancelOperation.emit() ; 

    }
    onConfirm() { 
        
        let sendToParams : SendToWithUserParams =  {
            toId  : this.transportOrder.id, 
            userName : this.user[0].displayName, 
            smsInteraction : true,
            isConflictIgnored : true, 
            reasonId : this.selectedReason.id, 
            ignoreText : this.message
            
        }
        this.toScreenService.sendToToHaulierWithUser(sendToParams).subscribe((data) => {
            this.toastr.success('The transport order has successfully been sent');
        });

        this.ConfirmOperation.emit() ; 
    }

   
 }
