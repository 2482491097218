import { select } from "@angular-redux/store";
import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../../../../environments/environment";
import { ApplicationUser } from "../../../../models/application.user.interface";
import { PlanningBlock } from "../../../../models/planning-block/planning-block.interface";
import { PbScreenService } from "../../../../services/pb-screen.service";


@Component({
    moduleId: module.id,
    selector: 'pb-next-transport-info',
    templateUrl: './next-transport-info.component.html',
})

export class NextTransportInfoComponent implements OnInit {
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('selectedPbsFromPlanBoard') selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public selectedPb: any = null; 
    public enableAutocomplete: boolean = false;
    public showRailInfo : boolean = false ; 
    public showShippingInfo : boolean = false ;
    constructor(private readonly pbScreenService: PbScreenService){

    }

    ngOnInit(): void {
        this.showRailInfo = environment.railway; 
        this.showShippingInfo = environment.shipping;
        this.selectedPlanningBlocks$.subscribe((planningBlocks : PlanningBlock[]) => {
            if(planningBlocks && planningBlocks.length > 0 )
            {
                this.selectedPb = planningBlocks[0];
            }
           
        })
        
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });

        this.selectedPbsFromPlanBoard$.subscribe((data) => {
            if(data && data.length > 0) {
                this.selectedPb = data[0];
            }
           
        })
    }

}