
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { CapacitySlot } from './../models/capacity-slot.interface';
import { Injectable } from "@angular/core";
import { HttpClientService } from "../../shared/services/httpclient";
import { environment } from "../../../environments/environment";
import { TransportOrder } from "../models/transport-order/transport-order.interface";
import { Http } from "@angular/http";
import { PlanningBlock } from "../models/planning-block/planning-block.interface";
import { map, catchError } from 'rxjs/operators';
import { OrderLine } from "../../pb-screen/models/order-line/orderLine.interface" 

import { Remark } from '../models/remark.interface';
import { SendToWithUserParams } from '../models/send-to-params.interface';
import { ErrorHttpParams } from '../../shared/services/errror.httpparams';
import { MsalService } from '../../msal';

@Injectable()
export class ToScreenService {
  constructor(private http: HttpClientService,
    private readonly authService: MsalService) { }

  public getTransportOrders() {
    return this.http.get(environment.tmsApiEndpointAddress + 'transportorders/all?daysAhead=' + environment.TODaysAhead + '&daysBack=' + environment.TOdDaysBack).pipe(
      map((response: any) => response.data.sort()));
  }

  public getAllOpenTransportOrders() {
    return this.http.get(environment.tmsApiEndpointAddress + 'transportorders/open-all').pipe(
      map((response: any) => response.data.sort()));
  }

  public getTransportOrderDetails(toId: string): Observable<any> {
    return this.http.get(environment.tmsApiEndpointAddress + 'transportorders/orderlines?id=' + toId).pipe(
      map((response: any) => response.data));
  }

  public getTransportOrderById(transportOrderId : string, withPlanningBlocks : boolean): Observable<TransportOrder>{
    return this.http.get(environment.tmsApiEndpointAddress + 'transportorders?id=' + transportOrderId + '&WithPlanningBlocks=' + withPlanningBlocks).pipe(
      map((response : any) => response.data));
  }

  getPlanningBlocksForTO(id): Observable<TransportOrder> {
    return this.http
      .get(environment.tmsApiEndpointAddress + 'transportorders/GetID?transportorderid=' + id + '&withPlanningBlocks=true').pipe(map((response: any) =>
        response.data));
  }

  public addCapacityToTo(transportorder: TransportOrder, capacity: CapacitySlot) {
    return this.http
      .patch(environment.tmsApiEndpointAddress + 'transportorders/assign-capacity-for-to', {
        "TransportOrder": transportorder,
        "CapacitySlot": capacity
      }).pipe(map((response: any) =>
        response.data));
  }


  public assignCapacityToTO(transportOrder: TransportOrder, selectedCapacity: CapacitySlot, planningGroupId: string, haulierId: string, driverId: string, coDriverId: string): Observable<TransportOrder> {
    var capacityAssignmentObject = {
      movement: {
        transportOrder: transportOrder,
        capacitySlot: selectedCapacity
      },
      planningGroupId: planningGroupId,
      haulierId: haulierId,
      driverId: driverId,
      coDriverId: coDriverId
    }

    return this.http.put(environment.tmsApiEndpointAddress + 'transportorders/to-assign-capacity?toNumber=' + transportOrder.id, capacityAssignmentObject).pipe(
      map((response: any) => response.data),
      catchError((err) => observableThrowError(err)));
  }

  public NoPlanningRequired(transportorder: TransportOrder, orderLines: OrderLine[]) {
    let orderLinesids = [];
    for (const orderLine in orderLines) {

      orderLinesids.push(orderLines[orderLine].orderLineId);
    }

    return this.http
      .post(environment.tmsApiEndpointAddress + 'transportorders/removeLines?toNumber=' + transportorder.id,
        orderLinesids
      ).pipe(
        map((response: any) => {
          return response.data;
        }),
        catchError((err) => observableThrowError(err)));
  }

  public changePlanningSequence(toId, isMovedUp, targetSequenceNo, orderLine) {
    return this.http
      .post(environment.tmsApiEndpointAddress + "transportorders/id?toId=" + toId + "&direction=" + (isMovedUp ? "1" : "0") + "&referencePlanningSequence=" + targetSequenceNo
        , orderLine
      ).pipe(
        map((response: any) => {
          return response;
        }),
        catchError((err) => observableThrowError(err)));

  }


  public cancelSentTo(transportorder: TransportOrder): Observable<TransportOrder> {
    return this.http
      .patch(environment.tmsApiEndpointAddress + 'transportorders/cancel-sent-to', transportorder).pipe(
      map((res: any) => res.data));
  }

  public removeCapacityFromTO(transportorder: TransportOrder): Observable<any> {
    return this.http
      .patch(environment.tmsApiEndpointAddress + 'transportorders/remove-capacity-from-to', transportorder).pipe(
      map((response: any) => response.data));
  }

  public removeTruckFromTO(transportorder: TransportOrder): Observable<any> {
    return this.http
      .patch(environment.tmsApiEndpointAddress + 'transportorders/remove-truck-from-to', transportorder).pipe(
      map((response: any) => response.data));
  }

  public assignHaulierToTO(haulierNo: string, transportOrder: TransportOrder): Observable<TransportOrder> {
    return this.http.put(environment.tmsApiEndpointAddress + "transportorders/assign-haulier?haulierId=" + haulierNo, transportOrder).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((err) => observableThrowError(err)));
  }

  public changeDriverAndCoDriverForTO(to: TransportOrder, driverId: string, coDriverId: string): Observable<TransportOrder> {
    return this.http.put(environment.tmsApiEndpointAddress + `transportorders/driver-codriver?driverId=${driverId}&coDriverId=${coDriverId}`, to).pipe(
      map((response: any) => {
        return response.data;
      }),
      catchError((err) => observableThrowError(err)));
  }

  public updateToRemark(toId: string, remark: string, toRemarks: Remark[], remarkType: number): Observable<any> {

    const lineNumber = (toRemarks && toRemarks.length > 0) ? toRemarks[0].lineNumber : 0;

    return this.http.post(environment.tmsApiEndpointAddress + `transportorders/create-update-remarks`, {
      RemarkType: remarkType,
      TransportOrderId: toId,
      Value: remark,
      LineNumber: lineNumber,
    }).pipe(catchError((err) => observableThrowError(err)));
  }

  
  public updateBulkToRemark( toRemarks : Remark[] ): Observable<TransportOrder[]> {
    return this.http.post(environment.tmsApiEndpointAddress + `transportorders/create-update-bulk-remarks`, { 
       Remarks : toRemarks 
    }).pipe(
      map((response : any) => {
         return response.data;
      }),
      catchError((err) => observableThrowError(err)));
  }

  public getTransportOrdersByIds(toIds: string): Observable<TransportOrder[]> {
    return this.http.getWithConfig(environment.tmsApiEndpointAddress + `transportorders/get-transport-orders-by-ids?ids=` + toIds, 
        {
          params: new ErrorHttpParams(true)
        })
      .pipe(
        map((response: any) => response.data),
        catchError((err) => observableThrowError(err)));
  }

  public sendToToHaulier(toId: string[]) : Observable<TransportOrder[]> {
    const account = this.authService.getAccount();
    return this.http.get(environment.tmsApiEndpointAddress + `transportorders/send-to-haulier?toId=` + toId.join(',')
    + "&userName=" + account.username)
      .pipe(
        map((response : any) => response.data), 
        catchError((err) => observableThrowError(err)) 
      )
  }

  public sendToToHaulierWithUser(sendToParams: SendToWithUserParams)
  {
    return this.http.post(environment.tmsApiEndpointAddress + `transportorders/send-to-haulier-with-user` , sendToParams)
        .pipe(
          map((response : any) => response.data) , 
          catchError((err) => observableThrowError(err))
        )
  }

  
  recalculateTO(toId: string) {
    return this.http.post(environment.tmsApiEndpointAddress + `transportorders/recalculateto?toId=` + toId, null)
        .pipe(
          map((response : any) => response.data)
        )
  }


}