import { Inject, Injectable } from '@angular/core';
import { Http, RequestOptions, Response, ResponseContentType } from '@angular/http';
import { ToastrService } from "ngx-toastr";
import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import { getToday } from '@progress/kendo-angular-dateinputs/dist/es2015/util';
import { forEach } from '@angular/router/src/utils/collection';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { TransportOrderPlanUnit } from '../../models/transport-order/transport-order-plan-unit.interface';
import { PrintTicketData } from '../../models/print-ticket-data.interface';
import { DepotAddress } from '../../models/print-ticket/depot-address.interface';
import { DepotGroup } from '../../models/print-ticket/depot-group.interface';
import { OrderLine } from '../../../pb-screen/models/order-line/orderLine.interface';


export enum Actions {
  DEPOT_IN = 34,
  DEPOT_OUT = 33,
  PICK_UP = 7,
  DROP_OFF = 8,
}

@Injectable()
export class PrintTicketService {


  public baseUrl: string = environment.ContainerPlanningApiUri + "print";


    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }

    public printTicket(planUnit: TransportOrderPlanUnit, toNumber: string,
                       orderLines: OrderLine[], userName: string): any{

        const data: PrintTicketData ={
          depotInAddresses: [],
          depotOutAddresses: [],
          haulier: {
            driverName: "",
            haulier: "",
            printDate: "",
            truck: "",
            userName: "",
          },
          toNumber: "",
          type: {
            iD: 1,
          },
        };

        if (orderLines.length > 0) {
          data.haulier.driverName = orderLines[0].driverFullName;
          data.haulier.haulier = orderLines[0].supplierName;
          data.haulier.printDate = new Date().toString();
          data.haulier.truck = orderLines[0].truckDescription;
          data.haulier.userName = userName;
        }

        if (toNumber) {
          data.toNumber = toNumber;
        }

        const depotInAddressSearchNames = orderLines.reduce((searchnames, oL) => {
          if (searchnames.indexOf(oL.addressSearchName) === -1 && oL.actionType === Actions.DEPOT_IN) {
            if (planUnit.depotInPlanningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
              searchnames.push(oL.addressSearchName);
            }
          }
          return searchnames;
        }, []);

        if (planUnit.depotInPlanningBlocks.length > 0) {
          for (const address of depotInAddressSearchNames) {
            const newDepotAdres: DepotAddress = {
              addressSearchName: address,
              groups: [],
              noOfContainers: 0,
            };

            newDepotAdres.addressSearchName = orderLines.find((oL) => oL.addressSearchName === address).addressName;

            const addresGroups = orderLines.reduce((groups, oL) => {
              if (groups.indexOf(oL.groupingID) === -1 && oL.actionType === Actions.DEPOT_IN
                && oL.addressSearchName === address && oL.groupingID !== 0) {
                  groups.push(oL.groupingID);
              }
              return groups;
            }, []);

            for (const group of addresGroups) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };

              const groupedOrderlines =
              orderLines.filter((oL) => oL.groupingID === group && oL.actionType === Actions.DEPOT_IN);

              for (const orderline of groupedOrderlines) {
                const planningblock = planUnit.depotInPlanningBlocks.find((pb) => pb.id === orderline.planningBlockId);
                newDepotGroup.planningBlocks.push(planningblock);
              }

              let addressblock = orderLines
              .find((oL) => oL.actionType === Actions.PICK_UP
              && oL.planningBlockId === groupedOrderlines[0].planningBlockId);

              if (!addressblock) {
                addressblock = orderLines
                .find((oL) => oL.actionType === Actions.DEPOT_OUT
                && oL.planningBlockId === groupedOrderlines[0].planningBlockId);
              }

              if (addressblock) {
                newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
              }


              newDepotGroup.tableInfo.depotInRef2 = newDepotGroup.planningBlocks[0].depotInOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot =  this.createTimeslotString(groupedOrderlines[0]);

              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;
            }

            const addresGroupsZero = orderLines.reduce((groups, oL) => {
              if (oL.actionType === Actions.DEPOT_IN && oL.addressSearchName === address && oL.groupingID === 0) {
                  if (planUnit.depotInPlanningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
                    groups.push(oL);
                  }
              }
              return groups;
            }, []);

            for (const orderline of addresGroupsZero) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };
              const planningblock = planUnit.depotInPlanningBlocks.find((pb) => pb.id === orderline.planningBlockId);
              newDepotGroup.planningBlocks.push(planningblock);

              let addressblock =
              orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockId
              && oL.actionType === Actions.PICK_UP);

              if (!addressblock) {
                addressblock =
                orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockId
                && oL.actionType === Actions.DEPOT_OUT);
              }

              if (addressblock) {
                newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
              }


              newDepotGroup.tableInfo.depotInRef2 = newDepotGroup.planningBlocks[0].depotOutOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot = this.createTimeslotString(orderline);


              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;

            }

            data.depotInAddresses.push(newDepotAdres);

          }

        }

        const depotOutAddressSearchNames = orderLines.reduce((searchnames, oL) => {
          if (searchnames.indexOf(oL.addressSearchName) === -1 && oL.actionType === Actions.DEPOT_OUT) {
            if (planUnit.depotOutPlanningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
              searchnames.push(oL.addressSearchName);
            }
          }
          return searchnames;
        }, []);

        if (planUnit.depotOutPlanningBlocks.length > 0) {
          for (const address of depotOutAddressSearchNames) {
            const newDepotAdres: DepotAddress = {
              addressSearchName: address,
              groups: [],
              noOfContainers: 0,
            };

            newDepotAdres.addressSearchName = orderLines.find((oL) => oL.addressSearchName === address).addressName;

            const addresGroups = orderLines.reduce((groups, oL) => {
              if (groups.indexOf(oL.groupingID) === -1 && oL.actionType === Actions.DEPOT_OUT
                && oL.addressSearchName === address && oL.groupingID !== 0) {
                  groups.push(oL.groupingID);
              }
              return groups;
            }, []);

            for (const group of addresGroups) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };

              const groupedOrderlines =
              orderLines.filter((oL) => oL.groupingID === group && oL.actionType === Actions.DEPOT_OUT);

              for (const orderline of groupedOrderlines) {
                const planningblock = planUnit.depotOutPlanningBlocks.find((pb) => pb.id === orderline.planningBlockId);
                newDepotGroup.planningBlocks.push(planningblock);
              }

              let addressblock = orderLines
              .find((oL) => oL.actionType === Actions.DROP_OFF
              && oL.planningBlockId === groupedOrderlines[0].planningBlockId);

              if (!addressblock) {
                addressblock = orderLines
                .find((oL) => oL.actionType === Actions.DEPOT_IN
                && oL.planningBlockId === groupedOrderlines[0].planningBlockId);
              }

              if (addressblock) {
                newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
              }

              newDepotGroup.tableInfo.depotInRef2 = newDepotGroup.planningBlocks[0].depotOutOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot = this.createTimeslotString(groupedOrderlines[0]);

              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;
            }

            const addresGroupsZero = orderLines.reduce((groups, oL) => {
              if (oL.actionType === Actions.DEPOT_OUT && oL.addressSearchName === address && oL.groupingID === 0) {
                if (planUnit.depotOutPlanningBlocks.find((pb) => pb.id === oL.planningBlockId)) {
                  groups.push(oL);
                }
              }
              return groups;
            }, []);

            for (const orderline of addresGroupsZero) {
              const newDepotGroup: DepotGroup = {
                planningBlocks: [],
                tableInfo: {
                  addressSearchName: "",
                  depotInRef2: "",
                  location: "",
                  timeSlot: "",
                },
              };
              const planningblock = planUnit.depotOutPlanningBlocks.find((pb) => pb.id === orderline.planningBlockId);
              newDepotGroup.planningBlocks.push(planningblock);

              let addressblock =
                orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockId
                && oL.actionType === Actions.DROP_OFF);

              if (!addressblock) {
                addressblock =
                orderLines.find((oL) => oL.planningBlockId === orderline.planningBlockId
                && oL.actionType === Actions.DEPOT_IN);
              }

              if (addressblock) {
                  newDepotGroup.tableInfo.addressSearchName = addressblock.addressSearchName;
                }
              newDepotGroup.tableInfo.depotInRef2 = newDepotGroup.planningBlocks[0].depotOutOrderLineRef2;
              newDepotGroup.tableInfo.location = newDepotGroup.planningBlocks[0].latestLocationCode;
              newDepotGroup.tableInfo.timeSlot = this.createTimeslotString(orderline);


              newDepotAdres.groups.push(newDepotGroup);
              newDepotAdres.noOfContainers += newDepotGroup.planningBlocks.length;
            }
            data.depotOutAddresses.push(newDepotAdres);

          }
        }

        return this.http.post(this.baseUrl + "/printticket" , data, { responseType:ResponseContentType.Blob } ).pipe(
          map((res:any) =>
          window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
          ,catchError((err) => {
           return  Observable.throw(err);
         })
        )
        
    }

    private createTimeslotString(oL: OrderLine): string {
      let timeSlot: string = "";
      if (oL.bookedDateTimeFrom && oL.bookedDateTimeFrom.toString().length > 0) {
        timeSlot += oL.bookedDateTimeFrom.toString().split(" ")[0];
        timeSlot += " ";
        timeSlot += oL.bookedDateTimeFrom.toString().split(" ")[1].substring(0, 5);
        if (oL.bookedDateTimeUntil && oL.bookedDateTimeUntil.toString().length > 0) {
          timeSlot += " - ";
          timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[1].substring(0, 5);
        }
      } else if (oL.bookedDateTimeUntil && oL.bookedDateTimeUntil.toString().length > 0) {
        timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[0];
        timeSlot += " ";
        timeSlot += oL.bookedDateTimeUntil.toString().split(" ")[1].substring(0, 5);
      }
      return timeSlot;
    }

}
