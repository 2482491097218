
import {of as observableOf,  Observable ,  forkJoin, Subject } from 'rxjs';

import {first,  catchError, map, takeUntil } from 'rxjs/operators';
import { SharedService } from '../../../../shared/services/shared.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, AfterViewInit } from "@angular/core";
import { select } from "@angular-redux/store";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { PbScreenService } from "../../../services/pb-screen.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { PbActions } from "../../../pb.actions";
import { SharedActions } from '../../../../shared/shared.actions';
import { TransportOrder } from '../../../models/transport-order/transport-order.interface';
import { ToActions } from '../../../../to-screen/to.actions';
import { ToScreenService } from '../../../services/to-screen.service';
import { Chassis } from '../../../../shared/models/chassis.interface';
import { Trailer } from '../../../../shared/models/trailer.interface';
import { IdNameAsStringObject } from '../../../models/quick-view/id-name-as-string-object.interface';
import { LocationSelectorComponent } from '../../../../shared/components/location-selector/location-selector.component';
import { AddressLocation } from '../../../../shared/models/address-location';
import { SimpleModalComponent, SimpleModalService } from 'ngx-simple-modal';
import { relativeTimeRounding } from 'moment';
import { ApplicationUser } from '../../../models/application.user.interface';


@Component({
    moduleId: module.id,
    selector: 'update-pb-modal',
    templateUrl: './update-pb-modal.component.html'
})
export class UpdatePBModalComponent extends SimpleModalComponent<void,void> implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild("modal") public modal: ElementRef;
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select("chassis") public chassis$ : Observable<Chassis[]>;
    @select("trailers") public trailers$ : Observable<Trailer[]>;
    @select('selectedPbsFromPlanBoard') selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    @select("containerStatuses") public containerStatuses$: Observable<IdNameAsStringObject[]>;
    @select('loading') public loading$: Observable<boolean>;
    public containerStatuses: IdNameAsStringObject[] = [];
    @ViewChild(LocationSelectorComponent) private locationSelector: LocationSelectorComponent;
    public enableAutocomplete: boolean = false;
    private fallbackLanguage: string = 'en';
    private languageToUse: string = 'en';
    private selectedPlanningBlocks: PlanningBlock[] = [];
    private selectedPlanningBlockIds: number[] = [];
    private selectedTransportOrders: TransportOrder[] = [];
    private commaSeperatedPlanningBlockIds: string = '';
    private selectedLocation: AddressLocation;
    private emptyLocation: AddressLocation;
    private loadingSpinner: boolean = false;
    private modalHasWarning: boolean = false;
    private modalHasError: boolean = false;

    private containerNumber: string = '';
    private containerNumberIsValid: boolean = false;
    private containerNumberErrorDescription: string = "";

    private planningRemark: string = '';
    private haulierRemark: string = '';
    private retourRemark: string = '';
    private selectedStatus: IdNameAsStringObject;

    private trailers : Trailer[] = [];
    private chassis: Chassis[] = []; 

    public selectedChassis: Chassis;
    public selectedTrailer: Trailer;
    
    destroy$ = new Subject();

    applicationUser: ApplicationUser;

    public statusOptions: IdNameAsStringObject[] = [];

    constructor(
        public readonly pbScreenService: PbScreenService,
        public readonly sharedService: SharedService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly pbActions: PbActions, 
        public readonly sharedActions: SharedActions,
        public readonly toActions: ToActions,
        public readonly toScreenSerice: ToScreenService) { 
            super();
        }

  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })

        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.applicationUser = applicationUser;
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });
        this.containerStatuses$.subscribe((statuses: IdNameAsStringObject[]) => {
            this.containerStatuses = statuses;
        });
        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((tos : TransportOrder[]) => {
            if(tos && tos.length > 0) {
                this.selectedTransportOrders = tos ; 
            }
        })

       
           this.chassis$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                if(data){
                    this.chassis = data ; 
                }else {
                    this.chassis = [];
                }
           })
    
            this.trailers$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
                if(data) {
                    this.trailers = data;
                } else {
                    this.trailers = [];
                }
            })
        
    };

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    ngAfterViewInit(){
        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((pbs: PlanningBlock[]) => {
            if (pbs && pbs.length > 0) {
                this.modalHasWarning = pbs.length > 1;
                this.selectedPlanningBlockIds = [];
                pbs.forEach(pb => this.selectedPlanningBlockIds.push(pb.id));
                this.selectedPlanningBlocks = pbs;
                this.checkContainerNumber();
                this.renderFields(pbs[0]);
                this.setStatuses();
            }
        });
        this.selectedPbsFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((pbs) => {
            if (pbs && pbs.length > 0) {
                this.modalHasWarning = pbs.length > 1;
                this.selectedPlanningBlockIds = [];
                pbs.forEach(pb => this.selectedPlanningBlockIds.push(pb.id));
                this.selectedPlanningBlocks = pbs;
                this.checkContainerNumber();
                this.renderFields(pbs[0]);
                this.setStatuses();
            }
           
        })
    }

    setStatuses() {
        if(!this.selectedPlanningBlocks.length)
            return;
        let status = this.selectedPlanningBlocks[0].containerStatus;
        if(status)
        {
            this.pbScreenService.getValidContainerStatuses(this.selectedPlanningBlocks[0].orderNumber, status)
                .subscribe((data: string[]) => {
                    if (status) {
                        data.push(status);
                    }
                    let array = this.containerStatuses.filter(status => data.find(el => el == status.name));
                    this.statusOptions = array;
                });
        }
        else
        {
            this.statusOptions = this.containerStatuses;
        }
    }

    private renderFields(pb: PlanningBlock) {
        this.locationSelector.clearSelection();
        const locationData = this.hasMultiplePbs() ?
            (this.haveSameAttributeValue("latestLocationCode") ? this.selectedPlanningBlocks[0].latestLocationCode : "") : pb.latestLocationCode;
        this.selectedLocation = this.locationSelector.setSelectionFromParent(locationData);

        let statusName = this.hasMultiplePbs() ?
            this.haveSameAttributeValue("containerStatus") ? this.selectedPlanningBlocks[0] : "" : pb.containerStatus;
        this.selectedStatus = this.containerStatuses.find(el => el.name == statusName);
        this.planningRemark = this.hasMultiplePbs() ?
            this.haveSameAttributeValue("planningRemark") ? this.selectedPlanningBlocks[0].planningRemark : "" : pb.planningRemark;

        this.haulierRemark = this.hasMultiplePbs() ?
            this.haveSameAttributeValue("haulierRemark") ? this.selectedPlanningBlocks[0].haulierRemark : "" : pb.haulierRemark;

        this.retourRemark = this.hasMultiplePbs() ?
            this.haveSameAttributeValue("retourRemark") ? this.selectedPlanningBlocks[0].planningBlockRetour : "" : pb.planningBlockRetour;

        this.containerNumber = this.hasMultiplePbs() ?
            this.haveSameAttributeValue("containerNo") ? this.selectedPlanningBlocks[0].containerNo : "" : pb.containerNo;
        this.containerNumberIsValid = this.hasMultiplePbs() ? false : true;
    };

    private getSelectedLocation(location: AddressLocation) {
        this.selectedLocation = location;
    };

    private applyUpdate() {
        this.loadingSpinner = true;
        if (this.hasMultiplePbs()) {
            let observableArray: any[] = [];
            observableArray = this.selectedPlanningBlocks.map((pb: PlanningBlock) => {
                const dataLoad = {
                    "PbId": pb.id,
                    "Status": pb.containerStatus ? pb.containerStatus : "",
                    "Address": this.selectedLocation ? this.selectedLocation.addressId : "",
                    "Location": pb.latestLocationCode ? pb.latestLocationCode: "",
                    "ContainerNumber": pb.containerNo ? pb.containerNo : "",
                    "PlanningRemark": this.planningRemark !== "" ? this.planningRemark : "",
                    "HaulierRemark": this.haulierRemark !== "" ? this.haulierRemark : "",
                    "RetourRemark": this.retourRemark !== "" ? this.retourRemark : "",
                    "OrderNumber": pb.orderNumber !== "" ? pb.orderNumber : "",
                    "TrailerNo" : pb.trailerNo ? pb.trailerNo : "",
                    "ChassisNo" : pb.chassisNo ? pb.chassisNo : "",
                };
                return this.sharedService.updatePlanningBlock(dataLoad);
                
            });

            forkJoin(observableArray.map((obs: Observable<PlanningBlock>) => {
                return obs.pipe(
                    map((data: PlanningBlock) => {
                        this.translateString('TOASTR_MESSAGES.SUCCESS.STATUS_UPDATE_SUCCEEDED').then((result) => {
                            this.toastr.success(result.toString() + data.id + ' successfully');
                        });
                        let remainingPbs: PlanningBlock[] = this.selectedPlanningBlocks.filter(pb => pb.id !== data.id);
                        
                        //this.pbActions.setSelectedPlanningBlocks(remainingPbs.length > 0 ? remainingPbs : []);
                        if(data.transportOrderNumber !== "")
                        {
                            this.toScreenSerice.getPlanningBlocksForTO(data.transportOrderNumber).subscribe((data) => {
                                this.toActions.setSelectedTransportOrders([data]);
                                this.sharedActions.updateTransportOrdersDisplay([data]);
                            })
                        }
                       
                        remainingPbs.push(data);
                        this.pbActions.setSelectedPlanningBlocks(remainingPbs);
                        this.sharedActions.updatePlanningBlocksDisplay([data]);
                    }),
                    catchError(err => {
                        this.loadingSpinner = false;
                        return observableOf(undefined)
                    })
                );
            })).subscribe((res) => {
                console.log(res);
                this.loadingSpinner = false;
                // this.pbActions.setSelectedPlanningBlocks([]);
                this.closeModal();
            }, (err => {
                this.loadingSpinner = false;
                observableOf(undefined)
            }));

        } else {
            const lineNumber = Math.max(...this.selectedPlanningBlocks[0].haulierRemarks.map(d => d.lineNumber));
            const dataLoad = {
                "PbId": this.selectedPlanningBlocks[0].id,
                "Status": this.selectedStatus?.name ?? "",
                "Address": this.selectedLocation ? this.selectedLocation.addressId : "",
                "Location": this.selectedLocation ? this.selectedLocation.locationCode : "",
                "ContainerNumber": this.containerNumber ? this.containerNumber : "",
                "PlanningRemark": this.planningRemark ? this.planningRemark : "",
                "HaulierRemark": this.haulierRemark ? this.haulierRemark : "",
                "RetourRemark": this.retourRemark ? this.retourRemark : "",
                "OrderNumber": this.selectedPlanningBlocks[0].orderNumber ? this.selectedPlanningBlocks[0].orderNumber : ' ',
                "LineNumber": (lineNumber && isFinite(lineNumber)) ? lineNumber : 0, 
                "TrailerNo" : this.selectedTrailer ? this.selectedTrailer.id : "",
                "ChassisNo" : this.selectedChassis ? this.selectedChassis.id : "",
            };

            this.sharedService.updatePlanningBlock(dataLoad).subscribe(
                (data: PlanningBlock) => {
                    if(data.transportOrderNumber !== "")
                        {
                            this.toScreenSerice.getPlanningBlocksForTO(data.transportOrderNumber).subscribe((data) => {
                                this.toActions.setSelectedTransportOrders([data]);
                                this.sharedActions.updateTransportOrdersDisplay([data]);
                            })
                        }
                    this.pbActions.setSelectedPlanningBlocks([data]);
                    this.sharedActions.updatePlanningBlocksDisplay([data]);
                    this.loadingSpinner = false;
                    this.translateString('TOASTR_MESSAGES.SUCCESS.STATUS_UPDATE_SUCCEEDED').then((result) => {
                        this.toastr.success(result.toString() + data.id + ' successfully');
                    });
                    this.closeModal();
                },
                (err) => {
                    this.loadingSpinner = false;
                });
        }
    };

    private checkContainerNumber() {
        if (this.containerNumber && this.containerNumber.length > 0) {
            this.sharedService.checkContainerNumber(this.containerNumber).subscribe((res) => {
                if (res.statusCode === 202) {
                    this.containerNumberIsValid = true;
                    this.modalHasError = false;
                }
            }, (err) => {
                this.containerNumberErrorDescription = err.feedback[0].description;
                this.containerNumberIsValid = false;
                this.modalHasError = true;
            });
        } else {
            this.containerNumber = '';
            this.containerNumberIsValid = true;
            this.modalHasError = false;
        };
    };

    private closeModal(): void {
        this.clearFields();
        this.close();
    };

    private clearFields(): void {
        if(this.hasMultiplePbs() || !this.selectedPlanningBlocks.length) 
        {
            this.planningRemark = "";
            this.haulierRemark = "";
            this.retourRemark = "";
        } 
        else 
        {
            if(this.selectedPlanningBlocks[0].planningRemark == "" || this.selectedPlanningBlocks[0].planningRemark == null)
            {
                this.planningRemark = "";
                this.haulierRemark = ""; 
                this.retourRemark = "";
                
            } 
            else 
            {
                this.haulierRemark = this.selectedPlanningBlocks[0].haulierRemark;
                this.retourRemark = this.selectedPlanningBlocks[0].planningBlockRetour;
                this.planningRemark = this.selectedPlanningBlocks[0].planningRemark;
              
            }
        }

        this.locationSelector.clearSelection();
        this.selectedStatus = {id: "", name: ""}; 
        this.containerNumber = "";
        this.selectedChassis = null; 
        this.selectedTrailer = null;
    }

    private hasMultiplePbs(): boolean {
        return this.selectedPlanningBlocks.length > 1;
    };

    private translateString(text: string) {
        return this.translate.get(text).pipe(first()).toPromise();
    };

    private haveSameAttributeValue(attribute: string): boolean {
        return this.selectedPlanningBlocks.every((pb, index, arr) => {
            return pb[attribute] === arr[0][attribute];
        });
    };

    public autocompleListFormatterForTrailer(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public valueListFormatterForTrailer(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public autocompleListFormatterForChassis(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public valueListFormatterForChassis(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }
};
