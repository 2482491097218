import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { StackerQuickFilter } from "../depot-out/models/depot-out.interface";
import { ActionKeys } from "../pb-screen/action.keys";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { AddressLocation } from "../shared/models/address-location";
import { StackerAction } from "../shared/models/stacker-actions";

import { IAppState } from "../store/IAppState";

@Injectable()
export class StackerActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }


    public storePbsForStacker(planningBlocks: any[]) {
        super.dispatchAction({
            type: ActionKeys.STORE_PLANNING_BLOCKS_FOR_STACKER,
            stackerPlanningBlocks: planningBlocks
        });
    }

    public quickFilterStackerPlanningBlocks(stackerQuickFilter: StackerQuickFilter) {
        super.dispatchAction({
          type: ActionKeys.QUICK_FILTER_STACKER_PLANNING_BLOCKS,
          stackerQuickFilter,
        });
    } 

    public setQuickFilterValue(stackerQuickFilter : StackerQuickFilter) {
        super.dispatchAction({
            type : ActionKeys.SET_FILTER_STACKER_VALUE,
            stackerQuickFilter
        })
    }

    public setTxtFilterValue(stackerTxtSearch) {
        super.dispatchAction({
            type : ActionKeys.TXT_FILTER_VALUE,
            stackerTxtSearch
        })
    }

    public setSelctedStackerPbs(selectedStackerPlanningBlocks: any[])
    {
        super.dispatchAction({
            type : ActionKeys.SET_SELECTED_STACKER_PBS,
            selectedStackerPlanningBlocks

        })
    }

    public setAddressLocations(stackerAddressLocations : AddressLocation[])
    {
        super.dispatchAction({
            type : ActionKeys.STORE_STACKER_LOCATIONS,
            stackerAddressLocations

        })
    }

    public stackerTxtFilter(stackerTxtSearch: string) {
        super.dispatchAction({
          type: ActionKeys.TXT_FILTER_STACKER_PBS,
          stackerTxtSearch
        });
    }

    public updateStackerDisplay(updatdItem : any)
    {
        super.dispatchAndTransmitAction({
            type : ActionKeys.UPDATE_STACKER_DISPLAY, 
            updatdItem
        })
    }

    public UpdateStackerActionsUndo(undoActions : StackerAction[]) {
        super.dispatchAction({
            type : ActionKeys.REGISTER_STACKER_ACTIONS_UNDO,
            undoActions
        })
    }

    public UpdateStackerActionsRedo(redoActions : StackerAction[]) {
        super.dispatchAction({
            type : ActionKeys.REGISTER_STACKER_ACTIONS_REDO,
            redoActions
        })
    }

    public storeSites(sites : any[])
    {
        super.dispatchAction({
            type : ActionKeys.STORE_STIES, 
            sites
        })
    }

}