import { ActionKeys } from "../pb-screen/action.keys";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';

export function agendaReducer(state: IAppState = InitialState.initialState, action): IAppState {
    switch (action.type) {
        case ActionKeys.SET_DRIVER_AGENDA_DATA:
            return setDriverAgendaData(state,action);
        case ActionKeys.SET_SELECTED_DRIVER_FROM_AGENDA: 
            return setSelectedDriverFromAgenda(state,action);
        case ActionKeys.SET_SELECTED_TRUCK_ASSIGNEMENT:
            return setSelectedAssignement(state,action);
        case ActionKeys.SET_SELECTED_ABSENCE:
            return setSelectedAbsence(state,action);
        case ActionKeys.SET_SELECTED_TRUCK_FROM_AGENDA: 
            return setSelectedTruckFromAgenda(state,action); 
        case ActionKeys.SET_SELECTED_UNVAIILABILITY : 
            return setSelectedUnavailability(state,action); 
        case ActionKeys.SET_SELECTED_ASSIGNEMENT: 
            return setSelectedTruckAssignement(state,action); 
        case ActionKeys.SET_TRUCK_AGENDA_DATA: 
            return setTruckAgendaData(state,action);
        default:
          return state;
      }
}

function setSelectedDriverFromAgenda(state , action) {
    return Object.assign({},
        state,
        {
            selectedDriverFromAgenda: action.selectedDriverFromAgenda,
        });
}

function setSelectedAssignement(state,action) {
    return Object.assign({},
        state,
        {
            selectedTruckAssignement: action.selectedTruckAssignement,
        });
}

function setSelectedAbsence(state,action) {
    return Object.assign({},
        state,
        {
            selectedDriverAbsence: action.selectedDriverAbsence,
        });
}

function setDriverAgendaData(state,action) {
    return Object.assign({}, state, {
        driverAgendaData: action.driverAgendaData,
      })
}




function setSelectedTruckFromAgenda(state , action) {
    return Object.assign({},
        state,
        {
            selectedTruckFromAgenda: action.selectedTruckFromAgenda,
        });
}

function setSelectedTruckAssignement(state,action) {
    return Object.assign({},
        state,
        {
            selectedAssignement: action.selectedAssignement,
        });
}

function setSelectedUnavailability(state,action) {
    return Object.assign({},
        state,
        {
            selectedUnavailability: action.selectedUnavailability,
        });
}

function setTruckAgendaData(state,action) {
    return Object.assign({}, state, {
        truckAgendaData: action.truckAgendaData,
      })
}