
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { ToastrService } from "ngx-toastr";
import { HttpClientService } from "./httpclient";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { PlanningConflict } from "../../pb-screen/models/planning-conflict.interface";
import { Injectable } from "@angular/core";
import { IgnoreConflictReason } from "../../pb-screen/models/ingore-conflict-reason.interface";

@Injectable()
export class PlanningConflictsService {
    constructor(
        private readonly http: HttpClientService,
        private readonly toastr: ToastrService) {

    }

    public getPlanningConflictsForPbIds(pbIds: string) : Observable<PlanningConflict[]> {
        

        return this.http.get(environment.tmsApiEndpointAddress + 'planningConflicts/for-pbIds?pbIds=' + pbIds)
            .pipe(map((response: any) => response.data)
             , catchError((error) => {
                 return observableThrowError(error);
             }))
    }   

    public getPlanningConflictsForToNumbers(toNumbers: string) : Observable<PlanningConflict[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'planningConflicts/for-toNumbers?toNumbers=' + toNumbers)
            .pipe(map((response: any) => response.data)
             , catchError((error) => {
                return observableThrowError(error);
            }))

    }

    public getActivePlanningConflicts(): Observable<PlanningConflict[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'planningConflicts/get-active-planning-conflicts')
            .pipe(
                map((response: any) => response.data) , catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }

    public getIgnoreConflictReasons(): Observable<IgnoreConflictReason[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'planningConflicts/igonre-reasons')
            .pipe(
                map((response: any) => response.data) 
                ,catchError((error) => {
                    return observableThrowError(error);
                })
            )
    }
}