import { OnInit, Component, Input, ViewChild, ElementRef } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { PlanningConflict } from "../../../../pb-screen/models/planning-conflict.interface";
import { ToActions } from "../../../../to-screen/to.actions";
import { PbActions } from "../../../../pb-screen/pb.actions";
import { PbScreenService } from "../../../../pb-screen/services/pb-screen.service";
import { ToScreenService } from "../../../../pb-screen/services/to-screen.service";

@Component({
    selector: "active-planning-conflicts",
    templateUrl: './active-planning-conflicts.component.html'
})
export class ActivePlanningConflictsComponent implements OnInit {
    @select('activePlanningConflicts') actvicePlanningConflicts$: Observable<PlanningConflict[]>
    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    @Input() public planningConflicts: PlanningConflict[];
    @Input() public source: string;

    constructor(
        private readonly toActions: ToActions, 
        private readonly pbActions: PbActions,
        private readonly pbService: PbScreenService,
        private readonly toService: ToScreenService) {
    }

    ngOnInit(): void {

    }

    public setSelectedConflict(conflict: PlanningConflict,$event) {
        if(this.source === 'pb-screen') {
            let pbIds = []; 
            pbIds.push(conflict.pbId)
            this.pbService.getPlanningBlocksByIds(pbIds).subscribe((data) => {
                this.pbActions.setSelectedPlanningBlocks(data);
            })
        } else if(this.source === 'to-screen') {
            let toNumber = conflict.transportOrderId;
            this.toService.getTransportOrdersByIds(toNumber).subscribe((data) => {
                this.toActions.setSelectedTransportOrders(data);
            })
        }
        
    }
}