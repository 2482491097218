import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '../../../environments/environment';
import { DynamicCaptionService } from '../services/dynamic-caption.service';

@Pipe({ name: 'shippingRailway', pure: false })
export class ShippingRailway implements PipeTransform {

  transform(field: string) {
    if (field.includes("Supplier")) {
      const searchRegExp = /Supplier/gi;
      let replaceWith = "Supplier"; 

      if(environment.railway && environment.shipping)
      {
        return field;
      }
      else if(environment.railway) 
      {
        replaceWith = 'Railway';
      } else if(environment.shipping) {
         replaceWith = 'Shipping';
      }
      field = field.replace(searchRegExp, replaceWith);
    }
    return field;
  }
}