import { select } from "@angular-redux/store";
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";
import { MsalService } from "../../../../msal";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { PoolService } from "../../../../pool/pool.service";
import { DepotCapacitySelectorComponent } from "../../../containers/capacity-selector/capacity-selector.component";
import { AddressLocation } from "../../../models/address-location";
import { CapacitySlot } from "../../../models/capacity-slot.interface";
import { ComponentSource, ModalType } from "../../../models/component-source";
import { ContainerPoolRequestInfo } from "../../../models/container-pool-request/container.pool.request";
import { IN_PROGRESS, PLANNED, TO_BE_PLANNED } from "../../../models/status-type.interface";
import { ModalManagerService } from "../../../services/modal-manager.service";
import { SharedService } from "../../../services/shared.service";
import { SharedActions } from "../../../shared.actions";
import { LocationSelectorComponent } from "../../location-selector/location-selector.component";
import { ContainerReuseStatus } from "../container-reuse-modal/container-reuse.state.enum";


@Component({
  selector: "move-to-pool-modal",
  templateUrl: "move-to-pool-modal.component.html",
})
export class MoveToPoolComponent implements OnInit {
  @Input()
  public source: string;
  @Output()
  public onMoveToPoolSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();
  public containerPoolRequestInfo: ContainerPoolRequestInfo;
  public selectedContainerPoolRequestInfoStatus: number = -1;
  
  @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
  @select("depotOutSelection")
  public depotOutSelection$: Observable<PlanningBlock[]>;
  @select("depotInSelection")
  public depotInSelection$: Observable<PlanningBlock[]>;
  @ViewChild(LocationSelectorComponent)
  public locationSelector: LocationSelectorComponent;
  @ViewChild(DepotCapacitySelectorComponent)
  public capacitySelector: DepotCapacitySelectorComponent;
  public enableAutocomplete: boolean = false;
  public selectedPlanningBlocks: PlanningBlock[] = [];
  public depotInSelection: PlanningBlock[] = [];
  public depotOutSelection: PlanningBlock[] = [];

  public componentSource = ComponentSource;
  public modalType = ModalType;
  public selectedLocation: AddressLocation;
  public buttonState: boolean = true;
  public locationFilled: boolean = true;
  public selectedCapacity: CapacitySlot;
  public planDate: string;
  public fallbackLanguage: string = "en";
  public languageToUse: string = "en";
  public selectedReference: string = "";
  public isDepotInReference: boolean = false;
  public disableReferenceInput: boolean = false;
  locationRequired: any;

  constructor(
    private readonly sharedActions: SharedActions,
    private readonly sharedService: SharedService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly poolService: PoolService,
    private readonly modalManager: ModalManagerService, 
    private readonly authService: MsalService) {
    translate.setDefaultLang(this.fallbackLanguage);
    translate.use(this.languageToUse);
  }

  public ngOnInit(): void {
    this.applicationUser$.subscribe((user) => {
      if (user !== null) {
          this.locationRequired = user.generalSetting.locationRequired;
          this.enableAutocomplete = user.generalSetting.enableAutocomplete;
      }
    });
    if (this.capacitySelector) {
      this.capacitySelector.disableDateInput();
    }
    this.subscribeToDepotInSelection();
    this.subscribeToDepotOutSelection();
  }

  public subscribeToDepotOutSelection() {
    this.depotOutSelection$.subscribe((depotOutSelection: PlanningBlock[]) => {
      this.enableButtonState();
      if (this.source === this.componentSource.depotOut && depotOutSelection) {
        this.depotOutSelection = depotOutSelection;
        this.selectedPlanningBlocks = depotOutSelection;

        this.setSelectedReferenceFromSelection(depotOutSelection, false);
        this.setSelectedLocationFromPlanningBlockValue();
        this.setSelectedCapacityFromPlanningBlockValue();
        this.setInputFieldsState();

        if (
          depotOutSelection.length > 0 &&
          depotOutSelection[0].containerPoolRequestNo
        ) {
          this.getContainerPoolRequestInfo(
            depotOutSelection[0].containerPoolRequestNo,
          );
        } else {
          this.containerPoolRequestInfo = null;
          this.selectedContainerPoolRequestInfoStatus = -1;
        }
      }
    });
  }

  public setInputFieldsState() {
    if (this.selectedPlanningBlocks.length === 1) {
      if (this.capacitySelector) {
        this.capacitySelector.enableCapacityInput();
      }

      if (this.locationSelector) {
        this.locationSelector.enableLocationInput();
      }

      this.disableReferenceInput = false;
    } else {
      if (this.capacitySelector) {
        this.capacitySelector.disableCapacityInput();
      }

      if (this.locationSelector) {
        this.locationSelector.disableLocationInput();
      }

      this.disableReferenceInput = true;
      this.enableButtonState();
    }
  }

  public subscribeToDepotInSelection() {
    this.depotInSelection$.subscribe((depotInSelection: PlanningBlock[]) => {
      this.enableButtonState();
      if (this.source === this.componentSource.depotIn && depotInSelection) {
        this.depotInSelection = depotInSelection;
        this.selectedPlanningBlocks = depotInSelection;

        this.setSelectedReferenceFromSelection(depotInSelection, true);
        this.setSelectedLocationFromPlanningBlockValue();
        this.setSelectedCapacityFromPlanningBlockValue();
        this.setInputFieldsState();

        if (depotInSelection.length > 0 && depotInSelection[0].containerPoolRequestNo) {
          this.getContainerPoolRequestInfo(depotInSelection[0].containerPoolRequestNo);
        } else {
          this.containerPoolRequestInfo = null;
          this.selectedContainerPoolRequestInfoStatus = -1;
        }
      }
    });
  }

  public setSelectedReferenceFromSelection(selection: PlanningBlock[], isDepotInReference: boolean): void {
    if (selection.length > 0) {
      this.selectedReference = isDepotInReference ? selection[0].depotInOrderLineRef1 : selection[0].depotOutOrderLineRef1;
      this.isDepotInReference = isDepotInReference;
    }
  }

  public setSelectedLocationFromPlanningBlockValue() {
    if (this.selectedPlanningBlocks.length === 1) {
      if (this.selectedPlanningBlocks[0].latestLocationCode && this.locationSelector) {
        this.selectedLocation = this.locationSelector.setSelectionFromParent(
          this.selectedPlanningBlocks[0].latestLocationCode,
        );
      } else {
        this.disableButtonState();
      }
    } else {
      this.disableButtonState();
    }
  }

  public setSelectedCapacityFromPlanningBlockValue() {
    if (this.selectedPlanningBlocks.length === 1) {
      const truckName = this.selectedPlanningBlocks[0].truck;
      if (truckName && this.capacitySelector) {
        this.selectedCapacity = this.capacitySelector.setSelectionFromParent(truckName);
      } else {
        this.disableButtonState();
      }
    } else {
      this.disableButtonState();
    }
  }

  public getContainerPoolRequestInfo(containerPoolRequestNo: string): void {
    this.sharedService
      .getContainerPoolRequestInfo(containerPoolRequestNo)
      .subscribe((containerPoolRequestInfo: ContainerPoolRequestInfo) => {
        if (containerPoolRequestInfo) {
          this.fillInContainerPoolRequestInfoFields(containerPoolRequestInfo);
        } else {
          this.resetContainerPoolRequestInfo();
        }
      });
  }

  public fillInContainerPoolRequestInfoFields(containerPoolRequestInfo: ContainerPoolRequestInfo): void {
    this.containerPoolRequestInfo = containerPoolRequestInfo;
    this.selectedContainerPoolRequestInfoStatus =
      Number(containerPoolRequestInfo.requestStatus) + 1;
  }

  public resetContainerPoolRequestInfo() {
    this.containerPoolRequestInfo = null;
    this.selectedContainerPoolRequestInfoStatus = -1;
  }

  public updateContainerPoolRequestStateToRejected(
    containerPoolRequestNumbers: string[],
  ): void {
      const account = this.authService.getAccount(); 
        if (account.username) {
          this.updateContainerPoolRequestStateToRejectedServiceCall(
            containerPoolRequestNumbers,
            account.username,
          );
        } else {
          this.resetUi();
        }
      
  }

  public updateContainerPoolRequestStateToRejectedServiceCall(
    containerPoolRequestNumbers: string[],
    username: string,
  ): void {
    this.sharedService
      .rejectContainerToPoolRequest(containerPoolRequestNumbers, username)
      .subscribe(
        (planningBlocks: PlanningBlock[]) => {
          this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
          this.translate
            .get(
              "TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_REJECTED_COMPLETED_SUCCESFULLY",
            )
            .subscribe((res: string) => {
              this.onSuccessEvents(res);
            });
        },
        () => {
          this.resetUi();
        },
      );
  }

  public updateContainerPoolRequestStateToCanceled(
    containerPoolRequestNumbers: string[],
  ): void {
    const account = this.authService.getAccount(); 
        if (account.username) {
          this.updateContainerPoolRequestStateToCanceledServiceCall(
            containerPoolRequestNumbers,
            account.username,
          );
        } else {
          this.resetUi();
        }
      
  }

  public updateContainerPoolRequestStateToCanceledServiceCall(
    containerPoolRequestNumbers: string[],
    username: string,
  ): void {
    this.sharedService
      .cancelContainerToPoolRequest(containerPoolRequestNumbers, username)
      .subscribe(
        (planningBlocks: PlanningBlock[]) => {
          this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
          this.translate
            .get(
              "TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_CANCELED_COMPLETED_SUCCESFULLY",
            )
            .subscribe((res: string) => {
              this.onSuccessEvents(res);
            });
        },
        () => {
          this.resetUi();
        },
      );
  }

  public updateContainerPoolRequestStateToApprovedServiceCall(
    poolContainerRequestNumbers: string[],
    username: string,
  ): void {
    this.sharedService
      .approveContainerToPoolRequest(poolContainerRequestNumbers, username)
      .subscribe(
        (planningBlocks: PlanningBlock[]) => {
          this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
          this.translate
            .get(
              "TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_ACCEPTED_COMPLETED_SUCCESFULLY",
            )
            .subscribe((res: string) => {
                this.toastr.success(res, "", {
                  closeButton: false
              });
            });
        },
        () => {
          this.resetUi();
        },
      );
  }

  public isContainerPoolRequest(): boolean {
    return (
      !this.containerPoolRequestInfo && this.selectedPlanningBlocks.length >= 1
    );
  }

  public requestAndApproveContainerPoolRequest(): void {
    const account = this.authService.getAccount(); 
        if (account.username) {
          this.registerAndApproveContainerPoolRequestServiceCall(account.username);
        } else {
          this.resetUi();
        }
      
  }

  public registerAndApproveContainerPoolRequestServiceCall(username: string): void {
    if (this.isOneToBePlannedPlanningBlockSelected(this.selectedPlanningBlocks)
          || this.areAllPlanningBlocksPlannedOrInProgress(this.selectedPlanningBlocks)) {
      this.requestAndApproveForToBePlannedPlanningBlock(username);
    } else {
          this.poolService.registerApprovePoolContReqChecks(this.selectedPlanningBlocks.map((x) => x.id), username)
            .subscribe(
              () => {
                this.requestAndApproveForMultiplePlanningBlocks(username);
              },
              (err) => {
                this.resetUi();
              },
            );
    }
  }

  public isOneToBePlannedPlanningBlockSelected(planningBlocks: PlanningBlock[]) {
    return planningBlocks.length === 1
       && planningBlocks[0].status.statusType === TO_BE_PLANNED;
  }

  public areAllPlanningBlocksPlannedOrInProgress(planningBlocks: PlanningBlock[]) {
    return planningBlocks.every((x) => x.status.statusType === PLANNED
            || x.status.statusType === IN_PROGRESS);
  }

  public requestAndApproveForToBePlannedPlanningBlock(username: string) {
    let depotInRef1 = "";
    let depotOutRef1 = "";

    if (this.isDepotInReference) {
      depotInRef1 = this.selectedReference;
    } else {
      depotOutRef1 = this.selectedReference;
    }

    this.sharedService.containerInOutPool(this.selectedPlanningBlocks.map((x) => x.id),
      this.selectedCapacity.id, '',
      this.selectedLocation, username, depotInRef1, depotOutRef1)
      .subscribe((planningBlocks: PlanningBlock[]) => {
        this.sharedActions.updatePlanningBlocksDisplay(planningBlocks);
        this.translate
          .get(
            "TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_REGISTRATION_COMPLETED_SUCCESFULLY",
          ).subscribe((res: string) => {
            this.onSuccessEvents(res);
          });
      }, (error) => {
        this.resetUi();
      });
  }

  public requestAndApproveForMultiplePlanningBlocks(username: string): void {
    this.sharedService
      .registerContainerToPoolRequest(
        this.selectedPlanningBlocks.map((planningBlock: PlanningBlock) => planningBlock.id),
        username,
      )
      .subscribe(
        (planningBlocks: PlanningBlock[]) => {
          this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
          this.translate
            .get(
              "TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_REGISTRATION_COMPLETED_SUCCESFULLY",
            )
            .subscribe((res: string) => {
              this.onSuccessEvents(res);
            });

          const containerPoolRequestNumbers: string[] = planningBlocks.map((x) => x.containerPoolRequestNo);
          this.updateContainerPoolRequestStateToApprovedServiceCall(containerPoolRequestNumbers, username);
        },
        () => {
          this.resetUi();
        },
      );
  }

  public onSuccessEvents(successMessage: string): void {
    this.disableButtonState();
    this.containerPoolRequestInfo = null;
    this.selectedContainerPoolRequestInfoStatus = -1;
    this.onMoveToPoolSucceeded.emit(true);
    this.sharedActions.resetModalDomains();
    this.selectedLocation = null;
    this.selectedCapacity = null;
    if (this.locationSelector) {
      this.selectedLocation = this.locationSelector.setSelectionFromParent("empty");
    }
      this.toastr.success(successMessage, "", {
          closeButton: false
      });
    this.selectedReference = "";
    this.isDepotInReference = false;
    this.disableReferenceInput = false;
    this.modalManager.closeModal(this.modalType.moveToPool);
  }

  public resetUi(): void {
    this.selectedLocation = null;
    this.selectedCapacity = null;
    if (this.locationSelector) {
      this.selectedLocation = this.locationSelector.setSelectionFromParent("empty");
    }
    this.selectedReference = "";
    this.disableButtonState();
    this.sharedActions.setUiState(true);
    this.disableReferenceInput = false;
    this.sharedActions.resetModalDomains();
    this.modalManager.closeModal(this.modalType.moveToPool);
    }

    public enableButtonState() {
    this.buttonState = true;
  }

  public disableButtonState() {
    this.buttonState = false;
  }

  public moveToPool(): void {
    const account = this.authService.getAccount(); 

    if (this.isContainerPoolRequest()) {
      switch (this.selectedContainerPoolRequestInfoStatus) {
        case ContainerReuseStatus.REQUEST_AND_APPROVE:
          this.requestAndApproveContainerPoolRequest();
          break;
        case ContainerReuseStatus.REQUESTED:
          this.requestContainerPoolRequest();
          break;
      }
    } else {
      if (this.locationSelector) {
        this.locationSelector.disableLocationInput();
      }

      if (this.capacitySelector) {
        this.capacitySelector.disableCapacityInput();
      }
      this.selectedPlanningBlocks.forEach((planningBlock: PlanningBlock) => {
        const containerPoolRequestNo: string = planningBlock.containerPoolRequestNo;
      });

      const containerNumbers: string[] = this.selectedPlanningBlocks.map((x) => x.containerPoolRequestNo);

      if (containerNumbers.length > 0) {
        switch (this.selectedContainerPoolRequestInfoStatus) {
          case ContainerReuseStatus.APPROVED:
            this.updateContainerPoolRequestStateToApproved(containerNumbers);
            break;
          case ContainerReuseStatus.REJECTED:
            if (this.containerPoolRequestInfo
              && this.selectedPlanningBlocks.length === 1
              && this.selectedPlanningBlocks[0].status.statusType === TO_BE_PLANNED
              // tslint:disable-next-line:triple-equals
              && this.containerPoolRequestInfo.requestStatus == 1) {
                 this.requestAndApproveForToBePlannedPlanningBlock(account.username);
            } else {
              this.updateContainerPoolRequestStateToRejected(containerNumbers);
            }
            break;
          case ContainerReuseStatus.CANCELED:
            this.updateContainerPoolRequestStateToCanceled(containerNumbers);
            break;
        }
      } else {
        this.toastr.error("Move in/out of pool: none of the selected planningsblocks have a container pool request number.");
      }
    }
  }

  public requestContainerPoolRequest(): void {
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.poolService.registerApprovePoolContReqChecks(this.selectedPlanningBlocks.map((x) => x.id),account.username)
          .subscribe(
            () => {
              this.registerContainerPoolRequestServiceCall(account.username);
            },
            (err) => {
              this.resetUi();
            });
      } else {
        this.resetUi();
      }
  }

  public registerContainerPoolRequestServiceCall(username: string): void {
    this.sharedService.registerContainerToPoolRequest(this.selectedPlanningBlocks.map((x) => x.id),
      username,
    )
      .subscribe((planningBlocks: PlanningBlock[]) => {
        this.sharedActions.updatePlanningBlocksDisplayCpl(planningBlocks);
        this.translate.get("TOASTR_MESSAGES.SUCCESS.CONTAINER_POOL_REQUEST_REGISTRATION_COMPLETED_SUCCESFULLY")
          .subscribe((res: string) => {
            this.onSuccessEvents(res);
          });
      },
        (error) => {
          this.resetUi();
        });
  }

  public updateContainerPoolRequestStateToApproved(poolContainerRequestNumbers: string[]): void {
    const account = this.authService.getAccount(); 
      if (account.username) {
        this.updateContainerPoolRequestStateToApprovedServiceCall(poolContainerRequestNumbers, account.username);
      } else {
        this.resetUi();
      }
    
  }

  public getSelectedLocation(location: AddressLocation) {
    if (location) {
      this.selectedLocation = location;
      if (
        this.locationSelector &&
        this.locationSelector.selectedLocation &&
        this.locationSelector.selectedLocation.searchName.length > 0
      ) {
        this.enableButtonState();
      } else {
        this.disableButtonState();
      }
    } else {
      if (this.locationSelector) {
        this.selectedLocation = this.locationSelector.setSelectionFromParent("empty");
      }
      this.disableButtonState();
    }
  }

  public getSelectedCapacity(capacity) {
    if (capacity) {
      this.selectedCapacity = capacity;
    }
  }

  public getSelectedPlanDate(planDate) {
    this.planDate = planDate;
  }

  public translateString(text: string) {
    return this.translate.get(text).first().toPromise();
  }

  public showElement(): boolean {
    return (!this.containerPoolRequestInfo
      && this.selectedPlanningBlocks.length === 1
      && this.selectedPlanningBlocks[0].status.statusType === TO_BE_PLANNED
      && this.selectedContainerPoolRequestInfoStatus === -1) ||
      (this.containerPoolRequestInfo
        && this.selectedPlanningBlocks.length === 1
        && this.selectedPlanningBlocks[0].status.statusType === TO_BE_PLANNED
        // tslint:disable-next-line:triple-equals
        && this.containerPoolRequestInfo.requestStatus == 1);
  }

  public disableButton(): boolean {
    return !this.containerPoolRequestInfo
      && this.selectedPlanningBlocks.length === 1
      && this.selectedPlanningBlocks[0].status.statusType === TO_BE_PLANNED
      && this.selectedContainerPoolRequestInfoStatus === -1
      && (!this.selectedCapacity || (!this.selectedLocation && this.locationRequired));
  }

  public validateLocation(): boolean {
    return !this.selectedLocation && this.locationRequired;
  }

  public validateCapacity(): boolean {
    return !this.selectedCapacity;
  }

}
