
export const TO_BE_PLANNED = 12;
export const PLANNED = 11;
export const IN_PROGRESS = 8;
export const PRE_PLANNED = 13;
export const ON_HOLD = 94;
export const CANCELED = 88;
export const RE_USED = 95;
export const CLOSED = 9;
export const CREATED = 6;

export enum PlanningStatus {
  TO_BE_PLANNED = "12",
  PLANNED = "11",
  IN_PROGRESS = "8",
  PRE_PLANNED = "13",
  ON_HOLD = "94",
  CANCELED = "88",
  RE_USED = "95",
  CLOSED = "9",
  CREATED = "6"
}

export const PlanningStatusDisplay = {
  toBePlanned: { name: "To be planned", value: TO_BE_PLANNED },
    onHold: { name: "On hold", value: ON_HOLD },
    
}