import { BookingStatesStrings, BookingModes } from "../pool/models/booking-enums.interface";
import { PoolContainersFilters } from "../pool/models/pool-containers-filters.interface";
import { PoolContainer } from "../pool/models/pool-containers.interface";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';
import { PoolBooking } from "../pool/models/pool-booking.interface";
import { PoolBookingFilter } from "../pool/models/pool-booking-filters.interface";
import { PoolMove } from "../pool/models/pool-move.interface";
import { ActionKeys } from "../pb-screen/action.keys";


export function poolReducer(state: IAppState = InitialState.initialState, action) {

    switch (action.type) {
        case ActionKeys.SET_POOL_BOOKINGS:
            return setPoolBookings(state, action);
        case ActionKeys.MONITOR_POOL_BOOKINGS:
            return monitorPoolBookings(state, action);
        case ActionKeys.GET_POOL_MOVES:
            return getPoolMoves(state, action);
        case ActionKeys.FILTER_POOL_MOVES:
            return filterPoolMoves(state, action);
        case ActionKeys.SET_SELECTED_POOL_BOOKING:
            return setSelectedPoolBooking(state, action);
        case ActionKeys.FILTER_EXISTING_POOL_BOOKINGS:
            return filterExistingPoolBookings(state, action);
        case ActionKeys.FILTER_NEW_POOL_BOOKINGS:
            return filterNewPoolBookings(state, action);
        case ActionKeys.SET_SELECTED_POOL_MOVE:
            return setSelectedPoolMove(state, action);
        case ActionKeys.SET_SELECTED_ACTIVE_POOL_MOVE:
            return setSelectedActivePoolMove(state, action);
        case ActionKeys.SET_POOL_CONTAINERS:
            return setPoolContainers(state, action);
        case ActionKeys.FILTER_POOL_CONTAINERS:
            return filterPoolContainers(state, action);
        case ActionKeys.SET_SELECTED_POOL_CONTAINER:
            return setSelectedPoolContainer(state, action);
        case ActionKeys.GET_CARRIERS:
            return getCarriers(state, action);
        case ActionKeys.GET_EQUIPMENT_TYPES:
            return getEquipmentTypes(state, action);
        case ActionKeys.GET_HAULIERS:
            return getHauliers(state, action);
        case ActionKeys.SET_SELECTED_HAULIER:
            return setSelectedHaulier(state, action);
        case ActionKeys.GET_DRIVERS:
            return getDrivers(state, action);
        case ActionKeys.SET_SELECTED_DRIVER:
            return setSelectedDriver(state, action);
        case ActionKeys.GET_TRUCKS:
            return getTrucks(state, action);
        case ActionKeys.SET_SELECTED_TRUCKS:
            return setSelectedTruck(state, action);
        case ActionKeys.SET_WIZARD_COMMENT:
            return setWizardComment(state, action);
        case ActionKeys.SET_CONTAINER_REMARKS:
            return setContainerRemarks(state, action)
        case ActionKeys.GET_CHECKLIST_ITEMS:
            return getChecklistItems(state, action)
        case ActionKeys.SET_SELECTED_CHECKLIST_ITEMS:
            return setSelectedChecklistItems(state, action)
        case ActionKeys.SET_SELECTED_TO_LOCATION:
            return setSelectedToLocation(state, action)
        case ActionKeys.SET_ASSIGNMENT_DIRECTION:
            return setAssignmentDirection(state, action)
        case ActionKeys.GET_ACTIVE_POOL_MOVES:
            return getActivePoolMoves(state, action)
        case ActionKeys.UPDATE_ACTIVE_POOL_MOVES:
            return updateActivePoolMoves(state, action)
        case ActionKeys.FILTER_ACTIVE_POOL_MOVES:
            return filterActivePoolMoves(state, action)
        default:
            return state;
    }

    function setPoolBookings(state, action) {
        return Object.assign({}, state, {
            poolBookings: action.poolBookings,
            filteredPoolBookings: action.poolBookings
        });
    }

    function monitorPoolBookings(state, action) {
        const bookingDirection: BookingModes = state.poolAssignmentDirection;
        const monitorPayload = bookingDirection === BookingModes.Incoming ? action.incoming : action.outgoing;

        let updatedPoolBooking: PoolBooking[] = [...state.poolBookings];
        let updatedFilteredPoolBooking: PoolBooking[] = [...state.filteredPoolBookings];

        if (monitorPayload.added.length > 0) {
            monitorPayload.added.forEach((addedBooking: PoolBooking) => {
                if (updatedPoolBooking.find((elem) => elem.no === addedBooking.no)) {
                    updatedPoolBooking.map((currentBooking: PoolBooking, index) => {
                        if (currentBooking.no === addedBooking.no && currentBooking.timestamp < addedBooking.timestamp) {
                            updatedPoolBooking[index] = addedBooking;
                        }
                    })
                }
                else { updatedPoolBooking.push(addedBooking) }
                if (updatedFilteredPoolBooking.find((elem) => elem.no === addedBooking.no)) {
                    updatedFilteredPoolBooking.map((currentBooking: PoolBooking, index) => {
                        if (currentBooking.no === addedBooking.no && currentBooking.timestamp < addedBooking.timestamp) {
                            updatedFilteredPoolBooking[index] = addedBooking;
                        }
                    });
                }
                else { updatedFilteredPoolBooking.push(addedBooking) }
            });
        }

        if (monitorPayload.updated.length > 0) {
            monitorPayload.updated.forEach((updatedBooking: PoolBooking) => {
                updatedPoolBooking.map((currentBooking: PoolBooking, index) => {
                    if (currentBooking.no === updatedBooking.no && currentBooking.timestamp < updatedBooking.timestamp) {
                        updatedPoolBooking[index] = updatedBooking;
                    }
                });
                updatedFilteredPoolBooking.map((currentBooking: PoolBooking, index) => {
                    if (currentBooking.no === updatedBooking.no && currentBooking.timestamp < updatedBooking.timestamp) {
                        updatedFilteredPoolBooking[index] = updatedBooking;
                    }
                });
            });
        }

        if (monitorPayload.deleted.length > 0) {
            monitorPayload.deleted.forEach((deletedBooking: PoolBooking) => {
                updatedPoolBooking = updatedPoolBooking.filter((poolBooking: PoolBooking) => {
                    return !(poolBooking.no === deletedBooking.no && poolBooking.timestamp < deletedBooking.timestamp)
                });

                updatedFilteredPoolBooking = updatedFilteredPoolBooking.filter((poolBooking: PoolBooking) => {
                    return !(poolBooking.no === deletedBooking.no && poolBooking.timestamp < deletedBooking.timestamp)
                });
            });
        }
        return Object.assign({}, state, {
            filteredPoolBookings: updatedFilteredPoolBooking,
            poolBookings: updatedPoolBooking
        });
    }

    function setSelectedPoolBooking(state, action) {
        return Object.assign({}, state, {
            selectedPoolBooking: action.selectedPoolBooking
        });
    }

    function filterer(bookings: PoolBooking[], filterReq: PoolBookingFilter) {
        const poolBookings: PoolBooking[] = bookings;
        const filterRequirements: PoolBookingFilter = filterReq;
        let filteredPoolBookings: PoolBooking[] = [];

        if (filterRequirements.reference.toString() === ""
            && filterRequirements.carrierNo === ""
            && filterRequirements.onlyOpen === false) {
            filteredPoolBookings = poolBookings;
        } else {
            poolBookings.forEach((booking: PoolBooking) => {
                if (filterRequirements.onlyOpen === true) {
                    if (booking.reference.toLowerCase().includes(filterRequirements.reference.toLowerCase())
                        && booking.carrierCode.toLowerCase().includes(filterRequirements.carrierNo.toLowerCase())
                        && booking.finished === BookingStatesStrings.Open) {
                        filteredPoolBookings.push(booking);
                    }
                } else {
                    if (booking.reference.toLowerCase().includes(filterRequirements.reference.toLowerCase())
                        && booking.carrierCode.toLowerCase().includes(filterRequirements.carrierNo.toLowerCase())) {
                        filteredPoolBookings.push(booking);
                    }
                }
            });
        }
        return filteredPoolBookings;
    };

    function filterNewPoolBookings(state, action) {
        return Object.assign({}, state, {
            filteredPoolBookings: filterer(action.poolBookings, action.poolBookingsFilter),
            poolBookings: action.poolBookings,
            poolBookingsFilter: action.poolBookingsFilter
        });
    }

    function filterExistingPoolBookings(state, action) {
        return Object.assign({}, state, {
            filteredPoolBookings: filterer(state.poolBookings, action.poolBookingsFilter),
            poolBookingsFilter: action.poolBookingsFilter
        });
    }

    function getPoolMoves(state, action) {
        return Object.assign({}, state, {
            poolMoves: action.poolMoves,
            filteredPoolMoves: action.poolMoves,
        });
    }
    function filterPoolMoves(state, action) {
        const columns: string[] = action.columns;
        const filter: string = action.filter;
        const poolMoves: PoolMove[] = state.poolMoves;
        let filteredPoolMoves: PoolMove[] = [];

        if (filter !== "") {
            poolMoves.forEach((move: PoolMove) => {
                columns.forEach((columnName: string) => {
                    if (move[columnName].toString().toLowerCase().includes(filter.toLowerCase()) && !filteredPoolMoves.includes(move)) {
                        filteredPoolMoves.push(move);
                    }
                });
            });
        }

        return Object.assign({}, state, {
            filteredPoolMoves: filter === "" ? poolMoves : filteredPoolMoves
        });
    }

    function filterActivePoolMoves(state, action) {
        const columns: string[] = action.columns;
        const filter: string = action.filter;
        const poolMoves: PoolMove[] = state.activePoolMoves;
        let filteredPoolMoves: PoolMove[] = [];

        if (filter !== "") {
            poolMoves.forEach((move: PoolMove) => {
                columns.forEach((columnName: string) => {
                    if (move[columnName].toString().toLowerCase().includes(filter.toLowerCase()) && !filteredPoolMoves.includes(move)) {
                        filteredPoolMoves.push(move);
                    }
                });
            });
        }

        return Object.assign({}, state, {
            filteredActivePoolMoves: filter === "" ? poolMoves : filteredPoolMoves
        });
    }

    function setSelectedPoolMove(state, action) {
        return Object.assign({}, state, {
            selectedPoolMove: action.selectedPoolMove
        });
    }

    function setSelectedActivePoolMove(state, action) {
        return Object.assign({}, state, {
            selectedActivePoolMove: action.selectedActivePoolMove,
        });
    }

    function setPoolContainers(state, action) {
        return Object.assign({}, state, {
            poolContainers: action.poolContainers,
            filteredPoolContainers: action.poolContainers
        });
    }

    function filterPoolContainers(state, action) {
        const filterRequirements: PoolContainersFilters = action.filterRequirements;
        const poolContainers: PoolContainer[] = state.poolContainers;
        let filteredPoolContainers: PoolContainer[] = [];

        if (filterRequirements.containerNo.toString() === ""
            && filterRequirements.containerType.toString() === ""
            && filterRequirements.carrierCode.toString() === "") {
            filteredPoolContainers = poolContainers;
        } else {
            poolContainers.forEach((poolContainer: PoolContainer) => {
                if (poolContainer.containerNo.toLowerCase().includes(filterRequirements.containerNo.toString().toLowerCase())
                    && poolContainer.containerType.toString().toLowerCase().includes(filterRequirements.containerType.toString().toLowerCase())
                    && poolContainer.carrierCode.toString().toLowerCase().includes(filterRequirements.carrierCode.toString().toLowerCase())) {
                    filteredPoolContainers.push(poolContainer);
                }
            });
        }

        return Object.assign({}, state, {
            filteredPoolContainers: filteredPoolContainers
        })
    }
    function setSelectedPoolContainer(state, action) {
        return Object.assign({}, state, {
            selectedPoolContainer: action.selectedPoolContainer
        })
    }
    function getCarriers(state, action) {
        return Object.assign({}, state, {
            carriers: action.carriers
        })
    }
    function getEquipmentTypes(state, action) {
        return Object.assign({}, state, {
            equipmentTypes: action.equipmentTypes
        })
    }
    function getHauliers(state, action) {
        return Object.assign({}, state, {
            hauliers: action.hauliers
        })
    }
    function setSelectedHaulier(state, action) {
        return Object.assign({}, state, {
            selectedHaulier: action.selectedHaulier
        })
    }
    function getDrivers(state, action) {
        return Object.assign({}, state, {
            drivers: action.drivers
        })
    }
    function setSelectedDriver(state, action) {
        return Object.assign({}, state, {
            selectedDriver: action.selectedDriver
        })
    }
    function getTrucks(state, action) {
        return Object.assign({}, state, {
            trucks: action.trucks
        })
    }
    function setSelectedTruck(state, action) {
        return Object.assign({}, state, {
            selectedTruck: action.selectedTruck
        });
    }
    function setWizardComment(state, action) {
        return Object.assign({}, state, {
            wizardComment: action.wizardComment
        });
    }
    function setContainerRemarks(state, action) {
        const newArrayReference = action.containerRemarks.slice();
        return Object.assign({}, state, {
            containerRemarks: action.containerRemarks,
        });
    }
    function getChecklistItems(state, action) {
        return Object.assign({}, state, {
            checklistItems: action.checklistItems,
        });
    }
    function setSelectedChecklistItems(state, action) {
        const newArrayReference = action.selectedChecklistItems.length !== 0 ? action.selectedChecklistItems.slice() : [];
        return Object.assign({}, state, {
            selectedChecklistItems: newArrayReference,
        });
    }
    function setSelectedToLocation(state, action) {
        return Object.assign({}, state, {
            selectedToLocation: action.selectedToLocation,
        });
    }
    function setAssignmentDirection(state, action) {
        return Object.assign({}, state, {
            poolAssignmentDirection: action.poolAssignmentDirection,
        });
    }
    function getActivePoolMoves(state, action) {
        return Object.assign({}, state, {
            activePoolMoves: action.activePoolMoves,
            filteredActivePoolMoves: action.activePoolMoves
        })
    }

    function updateActivePoolMoves(state, action) {
        return Object.assign({}, state, {
            activePoolMoves: state.activePoolMoves.filter(move => move.no !== action.moveNo),
            filteredActivePoolMoves: state.filteredActivePoolMoves.filter(move => move.no !== action.moveNo)
        })
    }
}
