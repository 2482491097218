import { OnInit, ViewChild, ElementRef, Component } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { ToastrService } from "ngx-toastr";
import { PbScreenService } from "../../../services/pb-screen.service";
import { TranslateService } from "@ngx-translate/core";
import { PbActions } from "../../../pb.actions";
import { ActionWizardService } from "../../../../shared/services/action-wizard.service";
import { InsertActionTemplateType } from "../../../../shared/models/insert-action-template-type";
import { HttpClientService } from "../../../../shared/services/httpclient";
import { WizardDefinitionInsertModel, WizardDefinition } from "../../../../shared/models/wizard-definition";
import { ListItem } from "@progress/kendo-angular-dateinputs/dist/es2015/timepicker/models/list-item.interface";
import { ActionWizardAddress } from "../../../../shared/models/action-wizard-address";
import { ApplicationUser } from "../../../models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'insert-action-wizard-modal',
    templateUrl: './insert-action-wizard-modal.component.html'
})

export class InsertActionWizardComponent implements OnInit {
    @select('selectedPlanningBlocks') selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    @select('loading') public loading$: Observable<boolean>;
    @ViewChild("modal") public modal: ElementRef;
    public enableAutocomplete: boolean = false;

    public selectedPlanningBlocks: PlanningBlock[] = [];
    public loadingSpinner: boolean = false;
    public loadingAdrs: boolean = true;

    public filter = InsertActionTemplateType.pbList;
    public actionTemplateAddressesListItems: any[] = [];
    public actionTemplates: WizardDefinition[] = [];
    public addressList: ActionWizardAddress[];
    public selectedAction: WizardDefinition;
    public selectedAdrsNumber: ActionWizardAddress;

    public planningZone: string = '';

    constructor(
        public readonly pbScreenService: PbScreenService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly pbActions: PbActions,
        public readonly actionWizardService: ActionWizardService,
        public readonly httpClientService: HttpClientService) { }

  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.selectedPlanningBlocks$.subscribe((data) => {
            this.selectedPlanningBlocks = data;
        })

        this.applicationUser$.subscribe((data) => {
            if (data) {
                this.planningZone = data.defaultPlanZone;
                this.enableAutocomplete = data.generalSetting.enableAutocomplete;
                this.getWizardDefinitions();
            }

        })

    }

    public getWizardDefinitions() {
        this.httpClientService.getToken().subscribe((token) => {
            this.actionWizardService.getActionWizardDefinitons(this.filter, this.planningZone).subscribe((data) => {
                if(data)
                  this.actionTemplates = data.filter((x) => x.insertLines == "After Last Action Code" || x.insertLines == "Before First Action Code");
            })
        })
    }

    public onWizardActionSelected() {
        this.loadingAdrs = true;
        if (this.selectedAction && this.selectedAction.code) {
            this.actionWizardService.getActionWizardAddreses(this.selectedAction.code).subscribe((data) => {
                this.addressList = data;
                this.selectedAdrsNumber = data.find(el => el.no == this.selectedAction.defaultAddressNo);
                if(!this.selectedAdrsNumber)
                    this.loadingAdrs = false;
                
            })
        } else {
            this.selectedAdrsNumber = null;
        }


    }

    public applyActionWizard() {
        if (this.selectedAction) {
            this.loadingSpinner = true;
            let pbIds = this.selectedPlanningBlocks.map(x => x.id);
            let actionWizardModel: WizardDefinitionInsertModel = {
                planningBlockIds: pbIds,
                actionTemplateCode: this.selectedAction.code,
                addressNo: '',
                userId: localStorage.getItem('userId')
            }
            if (this.selectedAction.useSameAddress) {
                actionWizardModel.addressNo = this.selectedAdrsNumber.no;
            }
            else {
                actionWizardModel.addressNo = '';
            }
            this.actionWizardService.inserActionWizard(actionWizardModel).subscribe((data) => {
                this.toastr.success('Action Wizard Inserted Successfully');
                this.pbActions.updatePlanningBlocksDisplay(data);
                this.pbActions.setSelectedPlanningBlocks([]);
                this.loadingSpinner = false;
                this.closeModal();
            }, (err) => {
                this.loadingSpinner = false;
                this.closeModal();
            })
        } else {
            this.toastr.warning('Please select a wizard');
        }



    }

    public actionizardValueFormatter(wizard: WizardDefinition) {
        return `${wizard.description}`;
    }

    public addressListValueFormatter(address: ActionWizardAddress) {
        return `${address.name}`;
    }

    public autocompleListFormatterForWizard(data: WizardDefinition): string {
        return `${data.description}`;
    }
    public autocompleListFormatterForAddress(data: ActionWizardAddress): string {
        return `${data.name}`;
    }

    public closeModal(): void {
        this.selectedAction = null;
        this.selectedAdrsNumber = null;
        this.modal.nativeElement.click();
    }

}
