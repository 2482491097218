import { OnInit, Component, Input } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { TransportOrder } from "../../../models/transport-order/transport-order.interface";
import { ToActions } from "../../../../to-screen/to.actions";
import { ApplicationUser } from "../../../models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'transport-orders-master-detail-filter',
    templateUrl: './transport-orders-master-detail-filter.component.html'
})
export class TransportOrdersMasterDetailFilterComponent implements OnInit{

    @select("openTransportOrders") public openTransportOrders$ : Observable<TransportOrder[]>;
    @select("filteredOpenTransportOrders") public filteredOpenTransportOrders$ : Observable<TransportOrder[]>;
    @select("searchTextForOpenTOs") public searchTextForOpenTOs$ : Observable<string>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    
    @Input() public columnFields: string[];

    public enableAutocomplete: boolean = false;
    public openTransportOrders : TransportOrder[];
    public filteredTransportOrders : TransportOrder[];
    public filterValue : string = "";

    constructor(private readonly toActions: ToActions) { }

    ngOnInit(): void { 
        
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });

        this.openTransportOrders$.subscribe((transportOrders: TransportOrder[]) => {
            this.openTransportOrders = transportOrders;
            if (this.filterValue !== "") {
                this.filterOpenTransportOrders();
            }
        });
        
        this.filteredOpenTransportOrders$.subscribe((filteredTransportOrders: TransportOrder[]) => {
            this.filteredTransportOrders = filteredTransportOrders;
        });

        this.searchTextForOpenTOs$.subscribe((searchText : string) => {
            this.filterValue = searchText;
        })
    }

    public filterOpenTransportOrders() : void{
        this.toActions.filterOpenTransportOrders(this.filterValue, this.columnFields);
    }

    public resetFilter(){
        this.toActions.resetOpenTransportOrdersFilter();
        this.filterValue = "";
    }
}