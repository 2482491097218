import { Injectable } from "@angular/core";
import * as moment from "moment";
import { environment } from "../../../environments/environment";

@Injectable()
export class PlanningDatesService {
    constructor() { }
    
    getPlanningFromUntilRange(userSetupWeekplanningDefaultDateRange: number, weekPlanningSettingDefaultDateRange:number): number {
      let userDateRange = userSetupWeekplanningDefaultDateRange;
      let defaultRange = weekPlanningSettingDefaultDateRange ? weekPlanningSettingDefaultDateRange - 1 : environment.maxDays - 1;
      if(!userDateRange)
          return defaultRange;
      userDateRange = userDateRange - 1;
      return Math.min(userDateRange, defaultRange);
    }

    saveWeekPlanningDates(defaultPlanDateRange){
      this.saveJsonToSessionStorage(SessionStorage.WeekPlanningDates, defaultPlanDateRange);
    }

    getWeekPlanningDates(): any{
      return this.getJsonFromSessionStorage(SessionStorage.WeekPlanningDates);
    }

    savePlanningDate(date){
      this.saveJsonToSessionStorage(SessionStorage.PlanningDate, date);
    }

    getPlanningDate(): any{
      return this.getJsonFromSessionStorage(SessionStorage.PlanningDate);
    }

    private saveJsonToSessionStorage(key, value){
      let jsonString = JSON.stringify(value);
      sessionStorage.setItem(key, jsonString);
    }

    private getJsonFromSessionStorage(key){
      let json = sessionStorage.getItem(key);
      return JSON.parse(json);
    }
}

enum SessionStorage{
  WeekPlanningDates = "WeekPlanningDates",
  PlanningDate = "PlanningDate"
}