
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {map,  catchError } from 'rxjs/operators';
import { TMSSelectedCMRActions, xmlTMSSelectCMRActionsXML } from './../../pb-screen/models/TMSSelectCMRActions.interface';
import { HttpClientService } from "./httpclient";
import { ToastrService } from "ngx-toastr";
import { clientSettings } from "../../pb-screen/static/client/clientSettings";
import { Http, Response, Headers, RequestOptions, ResponseContentType } from "@angular/http"
import { PlanningBlock } from "../../pb-screen/models/planning-block/planning-block.interface";
import { Injectable } from "@angular/core";
import { CapacitySlot } from "../../pb-screen/models/capacity-slot.interface";
import { CapacitySlot as CapacitySlot2 } from "../../../app/shared/models/capacity-slot.interface"
import { environment } from "../../../environments/environment";
import { ExportCmrData } from "../../pb-screen/models/CMR-data-raw.interface";

import { ContainerListData } from "../../pb-screen/models/container-list-data.interface";
import { element, error } from "protractor";
import { PlanningBlockPrintModel } from "../../pb-screen/models/planning-block/planning-block-view-model";
import { OrderLine } from "../../pb-screen/models/order-line/orderLine.interface";
import { Haulier } from "../../pb-screen/models/haulier.interface";
import { TMSPlanningSetup } from "../models/tms-planning-setup";
import { PlanningConflict } from "../../pb-screen/models/planning-conflict.interface";
import { Driver } from "../../static/models/driver.interface";
import * as moment from 'moment';
import { Container } from '../../pb-screen/models/planning-block/container.interface';
import { WindowsService } from '../models/windows-service';
import { IdNameAsStringObject } from '../../pb-screen/models/quick-view/id-name-as-string-object.interface';
import { TransportOrder } from '../../pb-screen/models/transport-order/transport-order.interface';
import { ActionTemplateScreen } from '../action.template.screen.enum';
import { FileReservation } from '../models/file-reservation-interface';
import { UpdatePlanningBlock } from '../models/planning-block/update-planning-block.interface';
import { PoolCarrier } from '../../pool/models/pool-carrier.interface';
import { FileAddress } from '../models/file/file-address.interface';
import { UpdateFile } from '../models/file/update-file.interface';
import { RailwayLine } from '../models/RailwayLine';
import { RailwayDeparture } from '../models/RailwayDeparture';
import { ContainerPoolRequestInfo } from '../models/container-pool-request/container.pool.request';
import { ContainerReuseInfo } from '../models/container-reuse/container.reuse.interface';

import { ActionTemplateAddress, ActionTemplateInsertLineViewModel } from '../components/modals/reroute-modal/reroute-modal.interface';
import { TransportOrderWithPlanUnit } from '../models/transport-order/transport-order-with-plan-unit.interface';
import { TransportOrderPlanUnit } from '../models/transport-order/transport-order-plan-unit.interface';
import { File } from '../models/file/file.interface';
import { AddressLocation } from '../models/address-location';
import { ErrorHttpParams } from './errror.httpparams';
import { TranslateService } from '@ngx-translate/core';


@Injectable()
export class SharedService {
    private planningBlocks = [];
    public myUpdatedObject: xmlTMSSelectCMRActionsXML
    public ScreenName: string = '';
    constructor(
        private readonly http: HttpClientService,
        private readonly toastr: ToastrService,
        private readonly translate: TranslateService,
        private http1: Http) {

    }

    public getValidcontainerStatuses(orderNumber: string, currentStatus: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "orders/" + orderNumber + "/statuses?currentStatus=" + currentStatus)
            .pipe(
                map((response: any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public checkContainerAvailability(pbId: number, containerNumber: string, userId: string) {
        const apiData = {
            "planningBlockId": pbId,
            "containerNumber": containerNumber,
            "userId": userId
        }
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/check-container-availability/", apiData)
            .pipe(map((response: any) => response.data)
                , catchError((error) => {
                    return observableThrowError(error);
                }))

    }

    public updateDepotInDate(planningBlockId: number, depotInDate: string, userId: string) {
        const apiWrapper = {
            depotInDate: {
                Date: depotInDate,
            },
            userId,
        };

        return this.http
            .put(environment.ContainerPlanningApiUri + "planningBlocks/" + planningBlockId + "/depot-in-date", apiWrapper)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    public updateContainerNumber(planningBlockId: number, containerNumber: string, userId: string) {
        const apiWrapper = {
            value: containerNumber,
            userId,
        };
        return this.http
            .put(environment.ContainerPlanningApiUri + "planningBlocks/" + planningBlockId + "/container-number", apiWrapper)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }

    public updatePlanningBlockStatus(planningBlock: PlanningBlock, newStatus: string, newLocation: AddressLocation, userId: string):
        Observable<PlanningBlock> {
        let addressId = "";
        let location = "";
        if (newLocation && newLocation.addressId) {
            addressId = newLocation.addressId;
        }
        if (newLocation && newLocation.locationCode) {
            location = newLocation.locationCode;
        }

        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/" + planningBlock.id + "/container-status",
                {
                    addresslocation: {
                        addressId,
                        locationCode: location,
                    },
                    status: newStatus,
                    userId,
                })
            .pipe(
                map((res: any) => {
                    return res.data;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                }),
            );
    }

    public takeContainerFromPool(pbIds: number[], userId: string, containerNo: string) {
        const apiData = {
            pbIds: pbIds,
            userId: userId,
            containerNo: containerNo,
        }
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/take-container-from-pool/", apiData)
            .pipe(map((response: any) => response)
                , catchError((err) => {
                    return observableThrowError(err);
                }));
    }

    public getCapacitySlots(planDate): Observable<[CapacitySlot]> {
        const group = "ALL";
        const zone = "";
        const startDate = moment(planDate).hours(0).minutes(0).seconds(0).toISOString();
        const endDate = moment(planDate).hours(23).minutes(59).seconds(59).toISOString();
        return this.http
            .get(environment.tmsApiEndpointAddress + "planning?startDate=" + startDate
                + "&endDate=" + endDate + "&zone=" + zone + "&group=" + group).pipe(
                    map((response: any) => response.data),
                    catchError((err) => {
                        return observableThrowError(err);
                    })
                )
    }

    public getCapacitySlotsCpl(planDate , planningGroups): Observable<[CapacitySlot]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "capacityslots/?planDate=" + planDate + "&planningGroups=" + planningGroups)
            .pipe(
                map((response:any) => response.data),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }


    public getCapacitySlotsLight(planDate) {
        const group = "ALL";
        const zone = "";
        const startDate = moment(planDate).hours(0).minutes(0).seconds(0).toISOString();
        const endDate = moment(planDate).hours(23).minutes(59).seconds(59).toISOString();
        return this.http
            .get(environment.tmsApiEndpointAddress + "planning/light?startDate=" + startDate
                + "&endDate=" + endDate + "&zone=" + zone + "&group=" + group).pipe(
                    map((response: any) => response.data),
                    catchError((err) => {
                        return observableThrowError(err);
                    })
                )
    }

    public getCapacitySlotsAll() {
        const group = "ALL";
        const zone = "";
        return this.http
            .get(environment.tmsApiEndpointAddress + "planning/all?zone=" + zone + "&group=" + group).pipe(
                map((response: any) => response.data),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }


    public getDrivers(): Observable<Driver[]> {
        const daysAhead = environment.defaultPlanDaysAhead;
        const daysBack = environment.defaultPlanDaysBack;
        return this.http.
            get(environment.tmsApiEndpointAddress + "planning/drivers?daysAhead=" + daysAhead + "&daysBack=" + daysBack).pipe(
                map((response: any) => response.data as Driver[]),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public getPlanningGroups(): Observable<IdNameAsStringObject[]> {
        return this.http
          .get(environment.tmsApiEndpointAddress + 'planning/groups').pipe(
          map((response: any) => {
            return response.data as IdNameAsStringObject[];
          }));
    }

    public getCPPlanningGroups(): Observable<IdNameAsStringObject[]> {
        return this.http
          .get(environment.ContainerPlanningApiUri + 'planningblocks/get-planning-groups').pipe(
          map((response: any) => {
            return response.data as IdNameAsStringObject[];
          }));
    }

    public getContainerStatuses() : Observable<IdNameAsStringObject[]> {
        return this.http
            .get(environment.tmsApiEndpointAddress + 'planning/container-statuses').pipe(
            map((response : any) => {
                return response.data as IdNameAsStringObject[];
            }),
            catchError((err) => {
                return observableThrowError(err);
            }))
    }

    public getTruckDepartments() : Observable<IdNameAsStringObject[]> {
        return this.http
            .get(environment.tmsApiEndpointAddress + 'planning/truck-departments').pipe(
            map((response : any) => {
                return response.data as IdNameAsStringObject[];
            }))
    }

    public getPlanningHauliers(): Observable<[Haulier]> {
        return this.http
            .get(environment.tmsApiEndpointAddress + 'planning/hauliers').pipe(
                map((response: any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            );
    }

    public getCMRDataFromPlanningBlockById(planningBlockId: number): Observable<ExportCmrData> {
        return this.http
            .get(environment.tmsApiEndpointAddress + "planningBlocks/" + planningBlockId + "/cmr")
            .pipe(map((response: any) => response.data));
    }

    public getOrderLines(ids: string): Observable<OrderLine[]> {
        if(!ids)
        {
            this.translate.get("TOASTR_MESSAGES.ERROR.PB_NOT_SELECTED").subscribe((translated) => {
                this.toastr.error(translated);
            });
            return;
        }
        return this.http
            .get(environment.tmsApiEndpointAddress + "planningBlocks/" + ids + "/orderlines")
            .pipe(map((response: any) => response.data));
    }

    public getTMSPlanningSetup(): Observable<TMSPlanningSetup> {
        return this.http
            .get(environment.tmsApiEndpointAddress + "planning-setup")
            .pipe(
                map((response: any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public calculatePlanningBlockConflicts(transportOrderIdsCommaSeparated: string): Observable<PlanningConflict[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "transportorders/calculateConflicts?transportOrderId=" + transportOrderIdsCommaSeparated).pipe(
            map(
                (res: any) => {
                    return res.data.flatMap(to => to.planningConflicts) as PlanningConflict[];
                }),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    public calculatePlanningBlockConflictsReturnTo(transportOrderId: string): Observable<TransportOrder[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "transportorders/calculateConflicts?transportOrderId=" + transportOrderId).pipe(
            map(
                (res: any) => {
                    return res.data as TransportOrder[];
                }),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    public hideConflictForTo(conflictEntryNo: string, transportOrderId: string): Observable<TransportOrder> {
        return this.http.post(environment.tmsApiEndpointAddress + "transportorders/hideConflict?conflictEntryNo=" + conflictEntryNo + "&transportOrderId=" + transportOrderId, null).pipe(
            map(
                (res: any) => {
                    return res.data as TransportOrder;
                }),
            catchError((err) => {
                return observableThrowError(err);
            })
        );
    }

    public hideConflictForPb(conflictEntryNo: string, pbId: number): Observable<PlanningBlock> {
        return this.http.post(environment.tmsApiEndpointAddress + "planningblocks/hideConflict?conflictEntryNo=" + conflictEntryNo + "&pbId=" + pbId, null).pipe(
            map(
                (res: any) => {
                    return res.data as PlanningBlock;
                }),
            catchError((err) => {
                return observableThrowError(err);
            })
        );
    }

    public getAddressLocations(): Observable<AddressLocation[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "planning/address-locations").pipe(
            map((res: any) => res.data),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    public checkContainerNumber(containerNumber: string) {
        return this.http.get(environment.tmsApiEndpointAddress + "planning/check-container-number?containerNumber=" + containerNumber).pipe(
            map((res: any) => res),
            catchError((err) => observableThrowError(err.error)),

        );
    };

    public updatePlanningBlock(dataLoad: any): Observable<PlanningBlock> {
        return this.http.put(environment.tmsApiEndpointAddress + "planningblocks/update-pb", dataLoad).pipe(
            map((res: any) => res.data),
            catchError((err) => observableThrowError(err.error)),
        );
    };

    public getContainersForPlanningBlock(pbId: number): Observable<Container[]> {
        return this.http.getWithConfig(environment.tmsApiEndpointAddress + "planningblocks/" + pbId + "/containers",
        {
            params: new ErrorHttpParams(true)
        }).pipe(
            map((res: any) => res.data as Container[]),
            catchError((err) => observableThrowError(err.error)),
        );
    };

    public getMonitoringServiceStatus() : Observable<WindowsService> {
        return this.http.get(environment.tmsApiEndpointAddress + "services").pipe(
            map((res: any) => res.data as WindowsService),
            catchError((err) => observableThrowError(err.error)),
        );
    }

    public getMonitorServiceStatusPlanBoard() : Observable<WindowsService> {
        return this.http.get(environment.tmsApiEndpointAddress + "services/plan-board").pipe(
            map((res: any) => res.data as WindowsService),
            catchError((err) => observableThrowError(err.error)),
        );
    }

    public getUserAzureGroups(userId) : Observable<any[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "users/" + userId ).pipe(
            map((res: any) => res.data.groups) , 
            catchError((err) => observableThrowError(err.error)),
        )
    }

    public getChassisForPbId(pbId) : Observable<any[]> {
        return this.http.get(environment.tmsApiEndpointAddress + '/planningblocks/' + pbId + '/chassis').pipe(
            map((res : any) => res.data) , 
            catchError((err) => observableThrowError(err))
        )
    }

    public getTrailersForPbId(pbId) : Observable<any[]> {
        return this.http.get(environment.tmsApiEndpointAddress + '/planningblocks/' + pbId + '/trailers').pipe(
            map((res : any) => res.data) , 
            catchError((err) => observableThrowError(err))
        )
    }
    public getPlanningBlocksForOrderId(orderId) : Observable<PlanningBlock[]> {
        return this.http.get(environment.tmsApiEndpointAddress + '/planningblocks/pbs-for-order?orderId=' + orderId)
                .pipe(map((res : any) => res.data) , 
                catchError((err) => observableThrowError(err))
                );
    }

    public checkDriverAbsence(planDate, employeeId) : Observable<any[]> {
        return this.http.get(environment.tmsApiEndpointAddress + '/driveragenda/check-driver-absence?planDate=' + planDate + '&employeeNo=' + employeeId)
            .pipe(map((res : any) => res.data) , 
            catchError((err) => observableThrowError(err))
            ); 
    }

    public postSupportMail() {
        return this.http
            .post(environment.ContainerPlanningApiUri + "logs/send", {});
    }

    public addComment(orderNumber: string, comment: string, userId: string) {
        const apiWrapper = {
            remark: {
                orderId: orderNumber,
                value: comment,
            },
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/add-haulier-remark", apiWrapper)
            .pipe(
                map((res : any) => res),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public postPbRemark(pbId: number, txtRemark: string, userId: string) {
        const apiWrapper = {
            remark: {
                value: txtRemark,
            },
            userId,
        };
       
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/" + pbId + "/pb-remark", apiWrapper)
            .pipe(
                map((response:any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public postBookedDates(pbId: number, fromDate: string, untilDate: string, userId: string) {
        const apiWrapper = {
            fromDate,
            untilDate,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/" + pbId + "/postBookedDates", apiWrapper)
            .pipe(
                map((response:any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public postDepotInRef(pbId: number, ref: string, type: number, userId: string) {
        const apiWrapper = {
            type,
            value: ref,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/" + pbId + "/updateDepotInRef", apiWrapper)
            .pipe(
                map((response:any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public postDepotOutRef(pbId: number, ref: string, type: number, userId: string) {
        const apiWrapper = {
            type,
            value: ref,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/" + pbId + "/updateDepotOutRef", apiWrapper)
            .pipe(
                map((response:any) => {
                    return response.data;
                }),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public checkContainerNumberCpl(containerNumber: string, userId: string, selectedPlanningBlock?: PlanningBlock) {
        const apiWrapper = {
            value: containerNumber,
            userId,
        };

        return this.http
            .put(environment.ContainerPlanningApiUri + "planningblocks/check-container-number", apiWrapper)
            .pipe(
                map((res : any) => res),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public checkContainerNumbersInFile(fileNumber: string, containerNumber: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri +
                "planningblocks/get-pbs-by-file-no/" +
                encodeURIComponent(fileNumber))
            .pipe(
                map((res : any) => {
                    const pbs: PlanningBlock[] = res.data;
                    return pbs.every((planningBlock: PlanningBlock) => planningBlock.containerNo !== containerNumber);
                }),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public updateDepotInDateCpl(planningBlockId: number, depotInDate: string, userId: string) {
        const apiWrapper = {
            depotInDate: {
                Date: depotInDate,
            },
            userId,
        };
        return this.http
            .put(environment.ContainerPlanningApiUri + "planningBlocks/" + planningBlockId + "/depot-in-date", apiWrapper)
            .pipe(map((response:any) => {
                return response.data;
            }));
    }


    public updateInitials(formBody) 
    {
        

        return this.http.post(environment.ContainerPlanningApiUri  + "planningBlocks/update-initial-datetime",formBody)
                .pipe(
                    map((res : any) => {
                        return res.data;
                    }),
                    catchError((err) => {
                        return  Observable.throw(err);
                    }),
                );
    }

    public updateContainerNumberCpl(planningBlockId: number, containerNumber: string, userId: string) {
        const apiWrapper = {
            value: containerNumber,
            userId,
        };
        return this.http
            .put(environment.ContainerPlanningApiUri + "planningBlocks/" + planningBlockId + "/container-number", apiWrapper)
            .pipe(map((response:any) => {
                return response.data;
            }));
    }

    public getValidContainerStatuses(orderNumber: string, currentStatus: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "orders/" + orderNumber + "/statuses?currentStatus=" + currentStatus)
            .pipe(map((response:any) => {
                return response.data;
            }),
                catchError((err) => {
                    return  Observable.throw(err);
                }));
    }

    public getPlanningBlockById(id: number) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningBlocks/" + id)
            .pipe(map((response:any) => response.data));
    }


    public getPlanningBlocksByIds(pbIds: number[]): Observable<PlanningBlock[]> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningBlocks/fetch", pbIds)
            .pipe(
                map((res:any) => res.data as PlanningBlock[])
                , catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public getPlanningBlockByReuseNo(reuseNo: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningBlocks/reuseNo/" + encodeURIComponent(reuseNo))
            .pipe(map((response:any) => response.data));
    }

    public getPlanningBlockByContainerPoolRequestNo(containerPoolRequestNo: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningBlocks/containerPoolRequestNo/" + encodeURIComponent(containerPoolRequestNo))
            .pipe(map((response:any) => response.data));
    }

    public getCMRDataFromPlanningBlockByIdCpl(id: number): Observable<ExportCmrData> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningBlocks/" + id + "/cmr")
            .pipe(map((response:any) => response.data));
    }

    public containerInOutPool(pbIds: number[], capacityDiaryId: number,
        containerStatus: string, location: AddressLocation,
        userId: string, depotInRef1: string, depotOutRef1: string): Observable<PlanningBlock[]> {
        return this.http.post(environment.ContainerPlanningApiUri + "pool/container-in-out-pool", {
            pbIds,
            capacityDiaryId,
            containerStatus,
            tmsAddress: location.addressId,
            location: location.locationCode,
            userId,
            depotInRef1,
            depotOutRef1,
        }).pipe(
            map((res:any) => {
                return res.data;
            }),
            catchError((err) => {
                return  Observable.throw(err);
            }));
    }

    public prePlanAction(capacity: CapacitySlot2, planUnit: TransportOrderPlanUnit, planDate: string, userId: string): Observable<TransportOrderWithPlanUnit> {
        const newToData = {
            planDate,
            capacitySlot: capacity,
            planUnit,
            truckNo: capacity.truck.id.toString(),
            haulierNo: capacity.haulierNo,
            driverNo: capacity.driver.id,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "transportorders", newToData)
            .pipe(
                map((res:any) => res.data as TransportOrderWithPlanUnit),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public group(planUnit: TransportOrderPlanUnit, userId: string): Observable<TransportOrderPlanUnit> {
        const apiWrapper = {

            transportOrderPlanUnit: planUnit,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/group", apiWrapper)
            .pipe(
                map((res:any) => res.data as TransportOrderPlanUnit),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public send(planUnit: TransportOrderPlanUnit, userId: string): Observable<TransportOrderPlanUnit> {
        const apiWrapper = {

            transportOrderPlanUnit: planUnit,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/plan", apiWrapper)
            .pipe(
                map((res:any) => res.data as TransportOrderPlanUnit),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public undoSend(planUnit: TransportOrderPlanUnit, userId: string): Observable<TransportOrderPlanUnit> {
        const apiWrapper = {

            transportOrderPlanUnit: planUnit,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningBlocks/undo-plan", apiWrapper)
            .pipe(
                map((res:any) => res.data as TransportOrderPlanUnit),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }

    public getActionTemplates(actionTemplateScreen: ActionTemplateScreen): Observable<string[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "action-template/" + actionTemplateScreen + "/all")
            .pipe(
                map((response:any) => response.data),
                catchError((error: any) =>  Observable.throw(error)),
            );
    }

    public getActionTemplateAddresses(actionTemplateCode: string): Observable<ActionTemplateAddress[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "action-template/" + actionTemplateCode + "/addresses")
            .pipe(
                map((response:any) => response.data),
                catchError((error: any) =>  Observable.throw(error)),
            );
    }

    public getFirstOrderLineIdsForPlanningBlockIds(commaSeparatedPlanningBlockIds: string): Observable<number[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningBlocks/orderlines/first/" + commaSeparatedPlanningBlockIds)
            .pipe(
                map((response:any) => response.data),
                catchError((error: any) =>  Observable.throw(error)),
            );
    }

    public insertActionTemplateLines(actionTemplateInsertLineViewModel: ActionTemplateInsertLineViewModel) {
        return this.http
            .post(environment.ContainerPlanningApiUri + "action-template/", actionTemplateInsertLineViewModel)
            .pipe(
                map((response:any) => response.data),
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }
    public undoPreplan(planUnit: TransportOrderPlanUnit, userId: string): Observable<any> {
        const apiWrapper = {

            transportOrderPlanUnit: planUnit,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningBlocks/undo-preplan", planUnit)
            .pipe(
                catchError((err) => {
                    return  Observable.throw(err);
                }),
            );
    }
    public unplan(pbIds: number[], userId: string, cancelSendToHaulier: boolean): Observable<PlanningBlock[]> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningBlocks/unplan", { pbIds, userId, cancelSendToHaulier })
            .pipe
            (
            map((res:any) => res.data as PlanningBlock[]),
            catchError((err) => {
                return  Observable.throw(err);
            }),
            );
    }

    public getMultipleContainersReuseInfo(reuseNos: string[]): Observable<ContainerReuseInfo[]> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "container/get-multiple-reuse-info", { reuseNos })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    return  Observable.throw(error);
                }),
            );
    }

    public getContainerReuseInfo(reuseNo: string): Observable<ContainerReuseInfo> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "container/get-reuse-info/" + encodeURIComponent(reuseNo))
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    return  Observable.throw(error);
                }),
            );
    }

    public getContainerPoolRequestInfo(containerPoolRequestNo: string): Observable<ContainerPoolRequestInfo> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/request-info/" + encodeURIComponent(containerPoolRequestNo))
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    return  Observable.throw(error);
                }),
            );
    }

    public registerContainerReuse(depotInPlanningBlockIds: number,
        depotOutPlanningBlockIds: number,
        userId: string): Observable<PlanningBlock[]> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "container/register", {
                depotInPlanningBlockId: depotInPlanningBlockIds,
                depotOutPlanningBlockId: depotOutPlanningBlockIds,
                userId,
            })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public approveContainerReuse(reuseNo: string, userId: string): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri + "container/approve", { reuseNo, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public rejectContainerReuse(reuseNo: string, userId: string): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri + "container/reject", { reuseNo, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public cancelContainerReuse(reuseNo: string, userId: string): Observable<PlanningBlock[]> {
        return this.http.put(environment.ContainerPlanningApiUri + "container/cancel", { reuseNo, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public registerContainerToPoolRequest(planningBlockIds: number[], userId: string): Observable<PlanningBlock[]> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/register-container-request", { planningBlockIds, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public approveContainerToPoolRequest(poolContainerRequestNumbers: string[], userId: string): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri + "pool/approve-container-request", { containerPoolRequestNumbers: poolContainerRequestNumbers, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public rejectContainerToPoolRequest(poolContainerRequestNumbers: string[], userId: string): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri + "pool/reject-container-request", { containerPoolRequestNumbers: poolContainerRequestNumbers, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public cancelContainerToPoolRequest(poolContainerRequestNumbers: string[], userId: string): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri + "pool/cancel-container-request", { containerPoolRequestNumbers: poolContainerRequestNumbers, userId })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public planPbsToTrain(PlanningBlocks: PlanningBlock[], railwayLineNo: string,
        departureId: number, userId: string): Observable<PlanningBlock[]> {

        const pbIds = PlanningBlocks.map((pb) => pb.id);
        const apiWrapper = {
            departureId,
            pbIds,
            railwayLineNo,
            userId,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "railway/plan", apiWrapper)
            .pipe(
                map((response:any) => response.data),
                catchError((error) =>  Observable.throw(error)),
            );
    }

    public getAvailableRailwayLines(): Observable<RailwayLine[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "railway/lines/")
            .pipe(map((response:any) => response.data));
    }

    public getRailwayDepartures(railwayNo: string, planDate: string, userId: string): Observable<RailwayDeparture[]> {
        const apiWrapper = {
            planDate,
            railwayLineNo: railwayNo,
        };
        return this.http
            .post(environment.ContainerPlanningApiUri + "railway/departures", apiWrapper)
            .pipe(map((response:any) => response.data));
    }

    public moveContainerToPool(actionScreen: ActionTemplateScreen, containerPlanningBlockId: number)
        : Observable<PlanningBlock> {
            
        return this.http.put(environment.ContainerPlanningApiUri + "container/move-to-pool", {
            actionScreen,
            containerPlanningBlockId,
        })
            .pipe(
                map((response:any) => response.data),
                catchError((error) => {
                    
                    return  Observable.throw(error);
                }),
            );
    }

    public getOrderLinesCpl(ids: string): Observable<OrderLine[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "orderlines/orderlines-by-pb-ids/" + ids)
            .pipe(map((response:any) => response.data));
    }

    public setWeight(pbId: number, weight: number, userId: string) {
        const apiWrapper = {
            pbId,
            weight,
            userId,
        };
        
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/weigh", apiWrapper)
            .pipe(
                map((response:any) => response.data),
                catchError((err) => {
                    return  Observable.throw(err.error);
                }),
            );
    }

    public getFile(fileNumber: string): Observable<File> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "files?fileNumber=" + fileNumber)
            .pipe(map((response:any) => response.data));
    }

    public updateFile(fileNumber: string, fileViewModel: UpdateFile): Observable<PlanningBlock[]> {
        return this.http
            .put(environment.ContainerPlanningApiUri+ "files?fileNumber=" + fileNumber, fileViewModel)
            .pipe(
                map((response:any) => response.data),
                catchError((err) => {
                    
                    return  Observable.throw(err);
                }),
            );
    }

    public getFileAddresses(): Observable<FileAddress[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "files/addresses")
            .pipe(map((response:any) => response.data));
    }

    public getCarriers(): Observable<PoolCarrier[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "files/carriers")
            .pipe(
                map((response:any) => {
                    const data = response.data;
                    return data;
                }),
                catchError((err) => {
                    return  Observable.throw(err.error);
                }),
            );
    }

    public updatePlanningState(planningBlock: PlanningBlock, status: number, userId: string) {
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/update-pb-plan-state", { pbId: planningBlock.id, status })
            .pipe(catchError((err) => {
                return  Observable.throw(err);
            }));
    }

    public updatePlanningBlockCpl(updatePlanningBlock: UpdatePlanningBlock): Observable<PlanningBlock> {
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/update-planning-block", updatePlanningBlock).pipe(
                map((response:any) => {
                    const data = response.data;
                    return data;
                }, catchError((err) => {
                    
                    return  Observable.throw(err);
                }))
            );
            
    }

    public getPlanningBlocksByFileNumber(fileNumber: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "planningblocks/get-pbs-by-file-no/" + fileNumber)
            .pipe(map((response:any) => response.data));
    }

    public getFileRerservation(): Observable<FileReservation> {
        return this.http.
            get(environment.ContainerPlanningApiUri + "fileReservation/get-file-reservation")
            .pipe(map((response:any) => {
                const data = response.data;
                return data;
            } , catchError((err) => {
                
                return  Observable.throw(err);
            })));

    }



    public updatePlanningGroup(pbIds: number[], planningGroupCode: string) {
        return this.http
            .post(environment.ContainerPlanningApiUri + "planningblocks/update-planning-groups",
                {
                    pbIds,
                    planningGroupCode
                })
            .pipe(map((response:any) => response.data) , catchError((err) => {
                
                return  Observable.throw(err);
            }));
    }

   

  

    //Monitoring Service Status 
  

    public startMonitoringService() : Observable<WindowsService> 
    {
       /* return this.http.getMonitor(environment.ContainerPlanningApiUri +"services/start").pipe(
            map((res :any) => res.data),
            catchError((err) =>  Observable.throw(err))
        )*/
        return null;
    }

    public getActivePlanningConflicts() : Observable<PlanningConflict[]> 
    {
        return   this.http.get(environment.ContainerPlanningApiUri + "planningconflicts/get-active-conflicts").pipe(
            map((res: any) => res.data),
            catchError((err) =>  Observable.throw(err))
        )     
    }

}