export enum ActionKeys {
    SET_SELECTED_PLANNING_BLOCKS = 'pbs/SET_SELECTED_PLANNING_BLOCKS',
    SET_Shared_SELECTED_PLANNING_BLOCKS = 'pbs/SET_Shared_SELECTED_PLANNING_BLOCKS',
    UPDATE_PLANNINGBLOCKS_DISPLAY = 'pbs/UPDATE_PLANNINGBLOCKS_DISPLAY',
    REQUEST_PLANNINGBLOCKS_SUCCESS = 'pbs/REQUEST_PLANNINGBLOCKS_SUCCESS',
    SET_REUSE_CANDIDATES = 'pbs/SET_REUSE_CANDIDATES',
    RESET_PLANNINGBLOCKS = 'pbs/RESET_PLANNINGBLOCKS',
    SET_DATE_INTERVAL = 'pbs/SET_DATE_INTERVAL',
    SET_ORDERLINES_FOR_PLANNINGBLOCK = 'pbs/SET_ORDERLINES_FOR_PLANNINGBLOCK',
    RESET_ACTION_BAR_STATES = 'action_bar/RESET_ACTION_BAR_STATES',
    SET_ACTION_BAR_STATES = 'action_bar/SET_ACTION_BAR_STATES',
    APPLY_GLOBAL_FILTER = 'filters/APPLY_GLOBAL_FILTER',
    APPLY_QUICK_FILTER = 'filters/APPLY_QUICK_FILTER',
    RESET_QUICK_FILTER = 'filters/RESET_QUICK_FILTER',
    APPLY_ADVANCED_FILTER = 'filters/APPLY_ADVANCED_FILTER',
    RESET_ADVANCED_FILTER = 'filters/RESET_ADVANCED_FILTER',
    SET_PROCESSED_DISPLAY_STATE = 'filters/SET_PROCESSED_DISPLAY_STATE',
    SET_IMPORTANT_DISPLAY_STATE = 'filters/SET_IMPORTANT_DISPLAY_STATE',
    SET_SELECTED_DISPLAY_STATE = "filters/SET_SELECTED_DISPLAY_STATE",
    UPDATE_SESSION_COLUMNS = 'columns/UPDATE_SESSION_COLUMNS',
    UPDATE_TO_SESSION_COLUMNS = 'columns/UPDATE_TO_SESSION_COLUMNS',
    UPDATE_ORDERLINE_SESSION_COLUMNS = 'columns/UPDATE_ORDERLINE_SESSION_COLUMNS',
    UPDATE_PBORDERLINE_SESSION_COLUMNS = 'columns/UPDATE_PBORDERLINE_SESSION_COLUMNS',
    GET_APPLICATION_USER_SUCCESS = 'applicationuser/GET_APPLICATION_USER_SUCCESS',
    GET_APPLICATION_USER_NOTFOUND = 'applicationuser/GET_APPLICATION_USER_NOTFOUND',
    GET_APPLICATION_USER_FAILED = 'applicationuser/GET_APPLICATION_USER_FAILED',
    SET_SESSION_USERSETTINGS_DATA = 'applicationuser/SET_SESSION_USERSETTINGS_DATA',
    UPDATE_APPLICATION_USER = 'applicationuser/UPDATE_APPLICATION_USER',
    SET_GLOBAL_SETTINGS = 'applicationuser/SET_GLOBAL_SETTINGS',
    SET_DEFAULT_PLANNINGZONE = 'applicationuser/SET_DEFAULT_PLANNINGZONE',
    SET_SHIPPING_RAIL_DATA = "pbs/SET_SHIPPING_RAIL_DATA",
    RESET_STATE="shared/RESET_STATE",

    // signal r key
    TRANSMIT_ACTION = 'signalR/TRANSMIT_ACTION',
    // default key (aka something went wrong key)
    // OTHER_ACTION                                     = '__ANY_OTHER_ACTION_TYPE__',
    // Shared Actions
    REQUEST_CAPACITY_SLOTS_SUCCESS = "shared/REQUEST_CAPACITY_SLOTS_SUCCESS",
    REQUEST_HAULIERS_SUCCESS = "shared/REQUEST_HAULIERS_SUCCESS",
    REQUEST_DRIVERS_SUCCESS = "shared/REQUEST_DRIVERS_SUCCESS",
    REQUEST_PLANNINGGROUPS_SUCCESS = "shared/REQUEST_PLANNINGGROUPS_SUCCESS",
    SET_CONTAINER_STAUSES="shared/SET_CONTAINER_STAUSES,",
    RESET_MODAL_DOMAINS = "shared/RESET_MODAL_DOMAINS ",
    SET_UI_STATE = "shared/SET_UI_STATE",
    REQUEST_ORDERLINES_SUCCESS = "shared/REQUEST_ORDERLINES_SUCCESS",
    SET_PLANNINGBLOCKS_FOR_TO = "shared/SET_PLANNINGBLOCKS_FOR_TO",
    SET_TMS_PLANNING_SETUP = "shared/SET_TMS_PLANNING_SETUP",
    SET_SELECTED_ACTION_WIZARD = "shared/SET_SELECTED_ACTION_WIZARD",
    UPDATE_SESSION_Document = "shared/UPDATE_SESSION_Document",
    SHARED_UPDATE_PLANNINGBLOCKS_DISPLAY = "shared/UPDATE_PLANNINGBLOCKS_DISPLAY",
    UPDATE_DEPOTS_DISPLAY = "shared/UPDATE_DEPOTS_DISPLAY",
    SHARED_UPDATE_TRANSPORTORDERS_DISPLAY = "shared/UPDATE_TRANSPORTORDERS_DISPLAY",
    GET_CMR_ACTIONS = "shared/GET_CMR_ACTIONS",
    GET_ACTIVE_PLANNING_CONFLICTS = "shared/GET_ACTIVE_PLANNING_CONFLICTS",
    GET_MONITOR_SERVICE_STATUS = "shared/GET_MONITOR_SERVICE_STATUS",
    Add_CHANNEL_EVENT_TO_INVENTORY = "shared/Add_CHANNEL_EVENT_TO_INVENTORY",
    REQUEST_ALL_NOTIFICATIONS_SUCCESS = "shared/REQUEST_ALL_NOTIFICATIONS_SUCCESS",
    REQUEST_Waiting_Room_Details_SUCCESS = "shared/REQUEST_Waiting_Room_Details_SUCCESS",

    REQUEST_ALL_PLANNING_BLOCK_NOTIFICATIONS_SUCCESS = "shared/REQUEST_ALL_PLANNING_BLOCK_NOTIFICATIONS_SUCCESS",
    REQUEST_ALL_TRANSPORT_ORDER_NOTIFICATIONS_SUCCESS = "shared/REQUEST_ALL_TRANSPORT_ORDER_NOTIFICATIONS_SUCCESS",
    REQUEST_TRUCKDEPARTMENTS_SUCCESS = "shared/REQUEST_TRUCKDEPARTMENTS_SUCCESS",
    SET_PLANNING_CONFLICTS = "shared/SET_PLANNING_CONFLICTS",
    SET_SELECTED_TRANSPORT_ORDERS_FOR_INTERWINDOW_PLANNING = 'shared/SET_SELECTED_TRANSPORT_ORDERS_FOR_INTERWINDOW_PLANNING',
    REQUEST_ORDERLINES_SUCCESS2 = "shared/REQUEST_ORDERLINES_SUCCESS2",
    STORE_TO_WIZARD_DEFINITIONS = "shared/STORE_TO_WIZARD_DEFINITIONS",
    STORE_PB_WIZARD_DEFINITIONS = "shared/STORE_PB_WIZARD_DEFINITIONS",

    SET_SHARED_SELECTED_TRANSPORT_ORDERS = "PlanBoard/SET_SHARED_SELECTED_TRANSPORT_ORDERS",
    //To Screen Action keys
    RESET_TRANSPORTORDERS = 'tos/RESET_TRANSPORTORDERS',
    SET_SELECTED_TRANSPORT_ORDERS = 'tos/SET_SELECTED_TRANSPORT_ORDERS',
    SELECTED_TO_FOR_DETAILS = "tos/SELECTED_TO_FOR_DETAILS",
    REQUEST_TRANSPORTORDERS_SUCCESS = 'tos/REQUEST_TRANSPORTORDERS_SUCCESS',
    REQUEST_OPEN_TRANSPORTORDERS_SUCCESS = 'tos/REQUEST_OPEN_TRANSPORTORDERS_SUCCESS',
    APPLY_TO_GLOBAL_FILTER = 'tos/APPLY_TO_GLOBAL_FILTER',
    FILTER_OPEN_TRANSPORT_ORDERS = 'tos/FILTER_OPEN_TRANSPORT_ORDERS',
    RESET_OPEN_TRANSPORTORDERS_FILTER = 'tos/RESET_OPEN_TRANSPORTORDERS_FILTER',
    SET_SELECTED_ORDER_LINES = 'tos/SET_SELECTED_ORDER_LINES',
    APPLY_TO_QUICK_FILTER = 'filters/APPLY_TO_QUICK_FILTER',
    RESET_TO_QUICK_FILTER = 'filters/RESET_TO_QUICK_FILTER',
    SET_TO_PROCESSED_DISPLAY_STATE = 'filters/SET_TO_PROCESSED_DISPLAY_STATE',
    SET_TO_ACTION_BAR_STATE = "tos/SET_TO_ACTION_BAR_STATE",
    RESET_TO_ACTION_BAR_STATE = "tos/RESET_TO_ACTION_BAR_STATE",
    UPDATE_TOS_DISPLAY = 'tos/UPDATE_TOS_DISPLAY',
    REQUEST_COMMUNICATION_PARTNERS_SUCCESS = "REQUEST_COMMUNICATION_PARTNERS_SUCCESS",
    REQUEST_STANDARD_NOTIFICATIONS_SUCCESS = "REQUEST_STANDARD_NOTIFICATIONS_SUCCESS",
    SET_FINALIZED_COMBI_STATE = "SET_FINALIZED_COMBI_STATE",
    REQUEST_AZURE_USERS_SUCCESS = "REQUEST_AZURE_USERS_SUCCESS",
    STORE_USER_AZURE_GROUPS = "STORE_USER_AZURE_GROUPS",
    STORE_IGNORE_CONFLICT_REASONS = "STORE_IGNORE_CONFLICT_REASONS",
    STORE_PLANNING_ZONE = "STORE_PLANNING_ZONE",
    STORE_CHASSIS_FOR_PB = "STORE_CHASSIS_FOR_PB",
    STORE_TRAILERS_FOR_PB = "STORE_TRAILERS_FOR_PB",

    //Planning Board Action Keys
    STORE_PLANNING_FOR_DATE_SUCCESS = "PlanBoard/STORE_PLANNING_FOR_DATE_SUCCESS",
    STORE_UNPLANNED_PBS_SUCCESS = "PlanBoard/STORE_UNPLANNED_PBS_SUCCESS",
    UPDATE_UNPLANNED_DISPLAY = "PlanBoard/UPDATE_UNPLANNED_DISPLAY",
    UPDATE_ORDERLINES_DISPLAY = "PlanBoard/UPDATE_ORDERLINES_DISPLAY",
    SET_SELECTED_PBS_UNPLANNED = "PlanBoard/SET_SELECTED_PBS_UNPLANNED",
    SET_SELECTED_ORDERLINES_FROM_PLANBOARD = "PlanBoard/SET_SELECTED_ORDERLINE_FROM_PLANBOARD",

    STORE_PLANNING_FILTER_SAVE = 'PlanBoard/STORE_PLANNING_FILTER_SAVE',
    SELECT_PBS_FROM_PLAN_BOARD = 'PlanBoard/SELECT_PB_FROM_PLAN_BOARD',
    STORE_SELECTION_TYPE = 'PlanBoard/STORE_SELECTION_TYPE',

    STORE_WEEK_PLANNING_DATA_SUCCESS = "PlanBoard/STORE_WEEK_PLANNING_DATA_SUCCESS",
    STORE_WEEK_UNPLANNED_PBS_SUCCESS = "PlanBoard/STORE_WEEK_UNPLANNED_PBS_SUCCESS",

    UPDATE_MULTIDAYS_PLANNING_DISPLAY = "PlanBoard/UPDATE_MULTIDAYS_PLANNING_DISPLAY",
    SET_SELECTED_CAPACITY_FROM_PLANBOARD = "PlanBoard/SET_SELECTED_CAPACITY_FROM_PLANBOARD",
    STORE_OPEN_TOS_FO_DATE_SUCCESS = "PlanBoard/STORE_OPEN_TOS_FO_DATE_SUCCESS",
    PLAN_BOARD_MONITOR_STATUS = "PlanBoard/PLAN_BOARD_MONITOR_STATUS",
    UPDATE_PLANNING_DISPLAY = "PlanBoard/UPDATE_PLANNING_DISPLAY",
    REMOVE_TOS_FROM_CAPACITY = "PlanBoard/REMOVE_TOS_FROM_CAPACITY",
    SET_LOADING = "PlanBoard/SET_LOADING",
    SET_ORDERLINES_DOCK_LOADING = "PlanBoard/SET_ORDERLINES_DOCK_LOADING",
    HTTP_REQUEST_PENDING = "shared/HTTP_REQUEST_PENDING",

    //Driver Agenda 
    SET_DRIVER_AGENDA_DATA = "DriverAgenda/SET_DRIVER_AGENDA_DATA",
    SET_SELECTED_DRIVER_FROM_AGENDA = "DriverAgenda/SET_SELECTED_DRIVER_FROM_AGENDA",
    SET_SELECTED_TRUCK_ASSIGNEMENT = "DriverAgenda/SET_SELECTED_TRUCK_ASSIGNEMENT",
    SET_SELECTED_ABSENCE = "DriverAgenda/SET_SELECTED_ABSENCE",

    //Truck Agenda 
    SET_TRUCK_AGENDA_DATA = "TruckAgenda/SET_TRUCK_AGENDA_DATA",
    SET_SELECTED_TRUCK_FROM_AGENDA = "TruckAgenda/SET_SELECTED_TRUCK_FROM_AGENDA",
    SET_SELECTED_ASSIGNEMENT = "TruckAgenda/SET_SELECTED_ASSIGNEMENT",
    SET_SELECTED_UNVAIILABILITY = "TruckAgenda/SET_SELECTED_UNVAIILABILITY",

    REQUEST_DEPOT_OUT_PLANNINGBLOCKS_SUCCESS = "depot-out/REQUEST_DEPOT_OUT_PLANNINGBLOCKS_SUCCESS",
    UPDATE_DEPOT_OUT_PLANNED_PLANNING_BLOCKS = "depot-out/UPDATE_DEPOT_OUT_PLANNED_PLANNING_BLOCKS",
    SET_DEPOT_OUT_SELECTION = "depot-out/SET_DEPOT_OUT_SELECTION",
    SET_DEPOT_OUT_CANDIDATES = "depot-out/SET_DEPOT_OUT_CANDIDATES",
    RESET_DEPOT_OUT_CANDIDATES = "depot-out/RESET_DEPOT_OUT_CANDIDATES",
    SET_DEPOT_OUT_OVERBOOKING_CANDIDATES = "depot-out/SET_DEPOT_OUT_OVERBOOKING_CANDIDATES",
    RESET_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/RESET_PLANNINGBLOCKS",
    FILTER_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/filter/FILTER_DEPOT_OUT_PLANNINGBLOCKS",
    QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/filter/QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS",
    RESET_QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/filter/RESET_QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS",
    EXECUTE_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/filter/EXECUTE_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS",
    RESET_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS = "depot-out/filter/RESET_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS",
    SET_DEPOT_OUT_PROCESSED_DISPLAY_STATE = "depot-out/SET_DEPOT_OUT_PROCESSED_DISPLAY_STATE",
    SET_DEPOT_OUT_IMPORTANT_ONLY_DISPLAY_STATE = "depot-out/SET_DEPOT_OUT_IMPORTANT_ONLY_DISPLAY_STATE",

    UPDATE_DEPOT_OUT_COLUMN_SELECTION = "depot-out/columns/UPDATE_DEPOT_OUT_COLUMN_SELECTION",

    UPDATE_DEPOT_IN_PLANNED_PLANNING_BLOCKS = "depot-in/UPDATE_DEPOT_IN_PLANNED_PLANNING_BLOCKS",
    UPDATE_DEPOT_IN_ROW_SELECTION = "depot-in/UPDATE_DEPOT_IN_ROW_SELECTION",
    SET_DEPOT_IN_SELECTION = "depot-in/SET_DEPOT_IN_SELECTION",
    SET_DEPOT_IN_CANDIDATES = "depot-in/SET_DEPOT_IN_CANDIDATES",
    RESET_DEPOT_IN_CANDIDATES = "depot-in/RESET_DEPOT_IN_CANDIDATES",
    SET_DEPOT_IN_OVERBOOKING_CANDIDATES = "depot-in/SET_DEPOT_IN_OVERBOOKING_CANDIDATES",
    RESET_DEPOT_IN_PLANNINGBLOCKS = "depot-in/RESET_PLANNINGBLOCKS",

    FILTER_DEPOT_IN_PLANNINGBLOCKS = "depot-in/filter/FILTER_DEPOT_IN_PLANNINGBLOCKS",
    REQUEST_DEPOT_IN_PLANNINGBLOCKS_SUCCESS = "depot-in/filter/REQUEST_DEPOT_IN_PLANNINGBLOCKS_SUCCESS",
    QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS = "depot-in/filter/QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS",
    RESET_QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS = "depot-in/filter/RESET_QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS",
    EXECUTE_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS = "depot-in/filter/EXECUTE_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS",
    RESET_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS = "depot-in/filter/RESET_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS",
    SET_DEPOT_IN_PROCESSED_DISPLAY_STATE = "depot-in/SET_DEPOT_IN_PROCESSED_DISPLAY_STATE",
    SET_DEPOT_IN_IMPORTANT_ONLY_DISPLAY_STATE = "depot-in/SET_DEPOT_IN_IMPORTANT_ONLY_DISPLAY_STATE",

    UPDATE_DEPOT_IN_COLUMN_SELECTION = "depot-in/columns/UPDATE_DEPOT_IN_COLUMN_SELECTION",


    UPDATE_CPL_PLANNINGBLOCKS_DISPLAY = "shared/UPDATE_CPL_PLANNINGBLOCKS_DISPLAY",
    RESET_CPL_ACTION_BAR_STATES = "shared/RESET_CPL_ACTION_BAR_STATES",
    SET_CPL_ACTION_BAR_STATES = "shared/SET_CPL_ACTION_BAR_STATES",
    ADD_PLANNINGBLOCKS_DISPLAY = "shared/ADD_PLANNINGBLOCKS_DISPLAY",
    DELETE_PLANNINGBLOCKS_DISPLAY = "shared/DELETE_PLANNINGBLOCKS_DISPLAY",
    SET_PLANNING_GROUPS = "shared/SET_PLANNING_GROUPS",
    STORE_PLANNING_CONFLICTS = "shared/STORE_PLANNING_CONFLICTS",
    SELECT_CONFLICT = "shared/SELECT_CONFLICT",

    SET_ASSIGNMENT_DIRECTION = "pool/SET_ASSIGNMENT_DIRECTION",
    SET_POOL_BOOKINGS = "pool/SET_POOL_BOOKINGS",
    GET_POOL_MOVES = "pool/GET_POOL_MOVES",
    GET_ACTIVE_POOL_MOVES = "pool/GET_ACTIVE_POOL_MOVES",
    UPDATE_ACTIVE_POOL_MOVES = "pool/UPDATE_ACTIVE_POOL_MOVES",
    FILTER_POOL_MOVES = "pool/FILTER_POOL_MOVES",
    FILTER_ACTIVE_POOL_MOVES = "pool/FILTER_ACTIVE_POOL_MOVES",
    SET_POOL_CONTAINERS = "pool/SET_POOL_CONTAINERS",
    GET_EQUIPMENT_TYPES = "pool/GET_EQUIPMENT_TYPES",
    GET_CARRIERS = "pool/GET_CARRIERS",
    GET_HAULIERS = "pool/GET_HAULIERS",
    GET_DRIVERS = "pool/GET_DRIVERS",
    GET_TRUCKS = "pool/GET_TRUCKS",

    SET_SELECTED_POOL_BOOKING = "pool/SET_SELECTED_POOL_BOOKING",
    SET_SELECTED_POOL_MOVE = "pool/SET_SELECTED_POOL_MOVE",
    SET_SELECTED_ACTIVE_POOL_MOVE = "pool/SET_SELECTED_ACTIVE_POOL_MOVE",
    SET_SELECTED_POOL_CONTAINER = "pool/SET_SELECTED_POOL_CONTAINER",
    SET_SELECTED_HAULIER = "pool/SET_SELECTED_HAULIER",
    SET_SELECTED_DRIVER = "pool/SET_SELECTED_DRIVER",
    SET_SELECTED_TRUCKS = "pool/SET_SELECTED_TRUCKS",
    SET_SELECTED_TO_LOCATION = "pool/SET_SELECTED_TO_LOCATION",

    SET_WIZARD_COMMENT = "pool/SET_WIZARD_COMMENT",
    SET_CONTAINER_REMARKS = "pool/SET_CONTAINER_REMARKS",
    GET_CHECKLIST_ITEMS = "pool/GET_CHECKLIST_ITEMS",
    SET_SELECTED_CHECKLIST_ITEMS = "pool/SET_SELECTED_CHECKLIST_ITEMS",

    FILTER_EXISTING_POOL_BOOKINGS = "pool/FILTER_EXISTING_POOL_BOOKINGS",
    FILTER_NEW_POOL_BOOKINGS = "pool/FILTER_NEW_POOL_BOOKINGS",
    FILTER_POOL_CONTAINERS = "pool/FILTER_POOL_CONTAINERS",

    MONITOR_POOL_BOOKINGS = "pool/MONITOR_POOL_BOOKINGS",


    // default key (aka something went wrong key)
    OTHER_ACTION = "__ANY_OTHER_ACTION_TYPE__",

    SET_LAST_ACTION_DATETIME = "shared/SET_LAST_ACTION_DATETIME",
    SET_GROUP_STRING = "shared/SET_GROUP_STRING",

    //Announcement 
    SET_ALL_POOL_CONTAINERS = "Announcement/SET_ALL_POOL_CONTAINERS",
    FILTER_CONTAINER_POOL_ANNOUNCEMENT = "Announcement/FILTER_CONTAINER_POOL_ANNOUNCEMENT",
    QUICK_FILTER_CONTAINER_POOL_ANNOUNCEMENT = "Announcement/QUICK_FILTER_CONTAINER_POOL_ANNOUNCEMENT",
    RESET_POOL_CONTAINERS = "Announcement/RESET_POOL_CONTAINERS",
    SET_POOL_CONTAINER_FOR_ANNOUNCEMENT = "Announcement/SET_POOL_CONTAINER_FOR_ANNOUNCEMENT",
    SET_ANNOUNCED_POOL_CONTAINERS = "Announcement/SET_ANNOUNCED_POOL_CONTAINERS",
    SET_SELECTED_ANNOUNCED_CONTAINER = "Announcement/SET_SELECTED_ANNOUNCED_CONTAINER",
    FILTER_ANNOUNCED = "Announcement/FILTER_ANNOUNCED",
    RESET_ANNOUNCED = "Announcement/RESET_ANNOUNCED",
    MONITOR_ANNOUNCE_CONTAINER = "announced/MONITOR_ANNOUNCE_CONTAINER",
    SET_ANNOUNCED_CONTAINER_DAMAGE = "Annoouncement/SET_ANNOUNCED_CONTAINER_DAMAGE",
    QUICK_FILTER_ANNOUNCED_CONTAINERS = "Annoouncement/QUICK_FILTER_ANNOUNCED_CONTAINERS",
    SET_PREANNOUNCED_CONTAINER_Moves = "Announcement/SET_PREANNOUNCED_CONTAINER_Moves",
    QUICK_FILTER_PREANNOUNCED_CONTAINER_MOVES = "Announcement/QUICK_FILTER_PREANNOUNCED_CONTAINER_MOVES",
    RESET_PREANNOUNCED_CONTAINER_MOVES = "Announcement/RESET_PREANNOUNCED_CONTAINER_MOVES",
    SET_SELECTED_PREANNOUNCED_POOL_MOVE = "Announcement/SET_SELECTED_PREANNOUNCED_POOL_MOVE",
    UPDATE_ANNOUNCED_SELECTION                        = "Announcement/UPDATE_ANNOUNCED_SELECTION",
    RESET_ANNOUNCEMENT_FILTER                         = "Announcement/RESET_ANNOUNCEMENT_FILTER",
    //Stacker Screen 
    STORE_PLANNING_BLOCKS_FOR_STACKER                 = "Stacker/STORE_PLANNING_BLOCKS_FOR_STACKER",
    UPDATE_STACKER_COLUMN_SELECTION                   = "Stacker/UPDATE_STACKER_COLUMN_SELECTION",
    QUICK_FILTER_STACKER_PLANNING_BLOCKS              = "Stacker/QUICK_FILTER_STACKER_PLANNING_BLOCKS",
    SET_FILTER_STACKER_VALUE                          = "Stacker/SET_FILTER_STACKER_VALUE",
    SET_SELECTED_STACKER_PBS                          = "Stacker/SET_SELECTED_STACKER_PBS",
    STORE_STACKER_LOCATIONS                           = "Stacker/STORE_STACKER_LOCATIONS",
    TXT_FILTER_STACKER_PBS                            = "Stacker/TXT_FILTER_STACKER_PBS",
    TXT_FILTER_VALUE                                  = "Stacker/TXT_FILTER_VALUE",
    UPDATE_STACKER_DISPLAY                            = "Stacker/UPDATE_STACKER_DISPLAY",
    REGISTER_STACKER_ACTIONS_REDO                     = "Stacker/REGISTER_STACKER_ACTIONS_REDO",
    REGISTER_STACKER_ACTIONS_UNDO                     = "Stacker/REGISTER_STACKER_ACTIONS_UNDO",
    STORE_STIES                                       = "Stacker/STORE_STIES" , 

    //Quotes
    STORE_SELECTED_QUOTES = 'PlanBoard/STORE_SELECTED_QUOTES',
    SET_QUOTELINES = "PlanBoard/SET_QUOTELINES",
    SET_SELECTED_QUOTELINES_FROM_BOARD = "PlanBoard/SET_SELECTED_QUOTELINES_FROM_BOARD",
    PLANNING_CONFLICTS_FOR_DOCK = "PlanBoard/PLANNING_CONFLICTS_FOR_DOCK"
}
