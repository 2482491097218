import { Component, Input, Output, EventEmitter } from '@angular/core';

import { SelectableItemDefinition, OnItemSelectedEvent, OnItemDeselectedEvent } from './selectable-item.interface';

@Component({
    moduleId: module.id,
    selector: 'selectable-item',
    templateUrl: './selectable-item.component.html'
})

export class SelectableItemComponent  {
    @Input() selectableItem: SelectableItemDefinition;
    @Output() itemSelected = new EventEmitter<OnItemSelectedEvent>();
    @Output() itemDeselected = new EventEmitter<OnItemDeselectedEvent>();

    onSelectableItemClick(columnName: string, checked: boolean) {
        if (checked) {
            const selected: OnItemSelectedEvent = {
                name: columnName
            };
            this.itemSelected.emit(selected);
        } else {
            const deselected: OnItemDeselectedEvent = {
                name: columnName
            };
            this.itemDeselected.emit(deselected);
        }
    }


 
}