import { select } from '@angular-redux/store';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationUser } from '../../../models/application.user.interface';
import {
    CREATED, CANCELED, IN_PROGRESS, ON_HOLD, PLANNED, PRE_PLANNED, RE_USED, TO_BE_PLANNED, CLOSED, ACCEPTED, LINE_PLANNED, LINE_CREATED,
    LINE_IN_PROGRESS, LINE_CLOSED, LINE_CANCELED, LINE_PREPLANNED
} from '../../../models/status-type.interface';
// tslint:disable-next-line:max-line-length

@Component({
    moduleId: module.id,
    selector: 'status-badge',
    templateUrl: './status-badge.component.html',
})
export class StatusBadgeComponent implements OnChanges, OnInit {
    
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;

    @Input() public statusType;
    @Input() public isOrderline: boolean = false;

    public labelName: string;
    public tagStyle: string;
    private orderlineReuseStatus: number = RE_USED;

    ngOnInit()
    {
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.orderlineReuseStatus = applicationUser.generalSetting.orderlineReuseStatus;
              this.getStatusString();
            }
          });
    }

    public ngOnChanges(): void {
        this.getStatusString();
    }

    public getStatusString(): void {
        if(this.setOrderlinesReusedState())
            return;
        switch (this.statusType) {
            case CREATED:
            case LINE_CREATED:
                this.labelName = 'created'; this.tagStyle = 'badge badge-created';
                break;
            case TO_BE_PLANNED:
                this.labelName = 'to be planned'; this.tagStyle = 'badge badge-to-be-planned';
                break;
            case PLANNED:
            case LINE_PLANNED:
                this.labelName = 'planned'; this.tagStyle = 'badge badge-planned';
                break;

            case IN_PROGRESS:
            case LINE_IN_PROGRESS:
                this.labelName = 'in progress'; this.tagStyle = 'badge badge-in-progress';
                break;
            case PRE_PLANNED:
                this.labelName = 'pre planned'; this.tagStyle = 'badge badge-preplanned';
                break;
            case ON_HOLD:
                this.labelName = 'on hold'; this.tagStyle = 'badge badge-danger';
                break;
            case CANCELED:
            case LINE_CANCELED:
                this.labelName = 'canceled'; this.tagStyle = 'badge badge-danger';
                break;

            case RE_USED:
                this.setReused();
                break;
            case CLOSED:
            case LINE_CLOSED:
                this.labelName = 'closed'; this.tagStyle = 'badge badge-done';
                break;
            case ACCEPTED:
                this.labelName = 'accepted'; this.tagStyle = 'badge badge-to-be-planned';
                break;
            case LINE_PREPLANNED:
                this.labelName = 'pre planned'; this.tagStyle = 'badge badge-preplanned';
                break;

            default:
                this.labelName = 'undefined status'; this.tagStyle = 'badge badge-danger';
        }
    }
    setOrderlinesReusedState(): boolean{
        if(this.isOrderline && this.statusType == this.orderlineReuseStatus)
        {
            this.setReused();
            return true;
        }
        return false;
    }
    setReused() {
        this.labelName = 'reused'; this.tagStyle = 'badge badge-reused';
    }
}
