import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";

import { IAppState } from "../store/IAppState";
import { PoolBookingFilter } from "./models/pool-booking-filters.interface";
import { PoolBooking } from "./models/pool-booking.interface";
import { PoolCarrier } from "./models/pool-carrier.interface";
import { PoolContainerInspectionItem } from "./models/pool-container-inspection-item.interface";
import { PoolContainersFilters } from "./models/pool-containers-filters.interface";
import { PoolContainer } from "./models/pool-containers.interface";
import { PoolDriver } from "./models/pool-driver.interface";
import { PoolEquipmentType } from "./models/pool-equipment-type.interface";
import { PoolHaulier } from "./models/pool-haulier.interface";
import { PoolMove } from "./models/pool-move.interface";
import { PoolTruck } from "./models/pool-truck.interface";
import { BookingModes } from "./models/booking-enums.interface";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { ActionKeys } from "../pb-screen/action.keys";
import { AddressLocation } from "../shared/models/address-location";

@Injectable()
export class PoolActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    public setSelectedPoolContainer(selectedPoolContainer: PoolContainer) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_POOL_CONTAINER,
            selectedPoolContainer: selectedPoolContainer
        });
    }

    public setPoolContainers(poolContainers: PoolContainer[]) {
        super.dispatchAction({
            type: ActionKeys.SET_POOL_CONTAINERS,
            poolContainers: poolContainers
        });
    }

    public setPoolBookings(poolBookings: PoolBooking[]) {
        super.dispatchAction({
            type: ActionKeys.SET_POOL_BOOKINGS,
            poolBookings: poolBookings
        });
    }

    public setSelectedPoolBooking(selectedPoolBooking: PoolBooking) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_POOL_BOOKING,
            selectedPoolBooking: selectedPoolBooking
        });
    }

    public filterPoolContainers(filterRequirements: PoolContainersFilters) {
        super.dispatchAction({
            type: ActionKeys.FILTER_POOL_CONTAINERS,
            filterRequirements: filterRequirements
        });
    }

    public filterNewPoolBookings(poolBookingsFilter: PoolBookingFilter, poolBookings: PoolBooking[], callback?) {
        super.dispatchAction({
            type: ActionKeys.FILTER_NEW_POOL_BOOKINGS,
            poolBookings: poolBookings,
            poolBookingsFilter: poolBookingsFilter,
        });
        if (callback) {
            callback();
        }
    }

    public filterExistingPoolBookings(poolBookingsFilter: PoolBookingFilter) {
        super.dispatchAction({
            type: ActionKeys.FILTER_EXISTING_POOL_BOOKINGS,
            poolBookingsFilter: poolBookingsFilter
        });
    }

    public getPoolMovesByBookingNo(poolMoves: PoolMove[]) {
        super.dispatchAction({
            type: ActionKeys.GET_POOL_MOVES,
            poolMoves: poolMoves
        });
    }

    public setPoolMoves(poolMoves: PoolMove[]) {
        super.dispatchAction({
            type: ActionKeys.GET_POOL_MOVES,
            poolMoves: poolMoves
        });
    }
    public filterPoolMoves(filter: string, columns: string[]) {
        super.dispatchAction({
            type: ActionKeys.FILTER_POOL_MOVES,
            filter: filter,
            columns: columns
        });
    }
    public setSelectedPoolMove(selectedPoolMove: PoolMove) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_POOL_MOVE,
            selectedPoolMove: selectedPoolMove
        });
    }

    public setSelectedActivePoolMove(selectedActivePoolMove: PoolMove) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_ACTIVE_POOL_MOVE,
            selectedActivePoolMove: selectedActivePoolMove
        });
    }

    public getCarriers(carriers: PoolCarrier[]) {
        super.dispatchAction({
            type: ActionKeys.GET_CARRIERS,
            carriers,
        });
    }

    public getEquipmentTypes(equipmentTypes: PoolEquipmentType[]) {
        super.dispatchAction({
            type: ActionKeys.GET_EQUIPMENT_TYPES,
            equipmentTypes,
        });
    }

    public getDrivers(drivers: PoolDriver[]) {
        super.dispatchAction({
            type: ActionKeys.GET_DRIVERS,
            drivers: drivers,
        });
    }

    public getHauliers(hauliers: PoolHaulier[]) {
        super.dispatchAction({
            type: ActionKeys.GET_HAULIERS,
            hauliers: hauliers,
        });
    }

    public getTrucks(trucks: PoolTruck[]) {
        super.dispatchAction({
            type: ActionKeys.GET_TRUCKS,
            trucks: trucks
        });
    }

    public setSelectedDriver(selectedDriver: PoolDriver) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_DRIVER,
            selectedDriver: selectedDriver
        });
    }

    public setSelectedHaulier(selectedHaulier: PoolHaulier) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_HAULIER,
            selectedHaulier: selectedHaulier
        });
    }

    public setSelectedTruck(selectedTruck: PoolTruck) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_TRUCKS,
            selectedTruck: selectedTruck
        });
    }

    public setWizardComment(wizardComment: string) {
        super.dispatchAction({
            type: ActionKeys.SET_WIZARD_COMMENT,
            wizardComment: wizardComment
        });
    }

    public setContainerRemarks(containerRemarks: string[]) {
        super.dispatchAction({
            type: ActionKeys.SET_CONTAINER_REMARKS,
            containerRemarks: containerRemarks
        });
    }
    public getChecklistItems(checklistItems: PoolContainerInspectionItem[]) {
        super.dispatchAction({
            type: ActionKeys.GET_CHECKLIST_ITEMS,
            checklistItems: checklistItems
        });
    }
    public setSelectedChecklistItems(selectedChecklistItems: PoolContainerInspectionItem[]) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_CHECKLIST_ITEMS,
            selectedChecklistItems: selectedChecklistItems
        });
    }
    public setSelectedToLocation(selectedToLocation: AddressLocation) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_TO_LOCATION,
            selectedToLocation: selectedToLocation
        })
    }
    public setAssignmentDirection(poolAssignmentDirection: BookingModes) {
        super.dispatchAction({
            type: ActionKeys.SET_ASSIGNMENT_DIRECTION,
            poolAssignmentDirection: poolAssignmentDirection
        })
    }
    public getActivePoolMoves(activePoolMoves: PoolMove[]) {
        super.dispatchAction({
            type: ActionKeys.GET_ACTIVE_POOL_MOVES,
            activePoolMoves
        })
    }
    public setActivePoolMoves(activePoolMoves: PoolMove[]) {
        super.dispatchAction({
            type: ActionKeys.GET_ACTIVE_POOL_MOVES,
            activePoolMoves: activePoolMoves
        });
    }
    public filterActivePoolMoves(filter: string, columns: string[]) {
        super.dispatchAction({
            type: ActionKeys.FILTER_ACTIVE_POOL_MOVES,
            filter: filter,
            columns: columns
        });
    }
    public updateActivePoolMoves(moveNo:string) {
        super.dispatchAction({
            type: ActionKeys.UPDATE_ACTIVE_POOL_MOVES,
            moveNo
        });
    }
    
}
