import { isTruthyComparison } from "../../pb-screen/filters/advanced.filter.comparison";
import { Column } from "../../pb-screen/models/application.user.interface";
import { AdvancedFilterColumnType, AdvancedFilterOperatorType } from "../../shared/components/filter-line/filter-line.interface";
import { transformColumnNamesToProperties } from "../../static/columName.to.property.transformation";
import { defaultStackerPlanningBlockColumnDefinition } from "../../static/stackerPlanningBlocks.columns.definition";

export function getFilterStackerPbsByTxt(planningBlocks: any[],
    searchText: string,
    stackerColumns: Column[]): any[] {
    const matches: any[] = [];
    const columNames: string[] = transformColumnNamesToProperties([...stackerColumns , ...defaultStackerPlanningBlockColumnDefinition].map((x) => x.name));
    const splitSearchText: string[] = searchText.split(" ");
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);

    for (let planningBlock of planningBlocks) {
        let matchedAllStrings: boolean = true;
        for (let text of filteredSplitSearchText) {
            let isValidFilterOperation: boolean = false;
            for (let column of columNames) {
                if (isTruthyComparison(planningBlock[column],
                    AdvancedFilterOperatorType.Contains,
                    text,
                    AdvancedFilterColumnType.Text)) {
                    isValidFilterOperation = true;
                }
            }
            if (!isValidFilterOperation) {
                matchedAllStrings = false;
                break;
            }
        }

        if (matchedAllStrings) {
            matches.push(planningBlock);
        }
    }
    return matches;
}
