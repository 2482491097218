import { select } from "@angular-redux/store";
import { Component, ElementRef, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";

import { ComponentSource, ModalType } from "../../models/component-source";
import { SharedActions } from "../../shared.actions";
import { TransportOrderPlanUnit } from "../../models/transport-order/transport-order-plan-unit.interface";
import { SharedService } from "../../services/shared.service";
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from "../../models/status-type.interface";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { ModalManagerService } from "../../services/modal-manager.service";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { MsalService } from "../../../msal";



@Component({
    selector: "unplan-modal-cpl",
    templateUrl: "./unplan-modal-CPL.component.html",
})

export class UnplanModalComponentCPL implements OnInit {
    @Input() public source: string;
    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;

    public modalType = ModalType;
    public selectedPlanningBlocks: PlanningBlock[] = [];
    public componentSource = ComponentSource;
    public loadingSpinner: boolean = false;
    isAllPlanned: boolean = false;
    isAllPrePlanned: boolean = false;

    constructor(private readonly sharedService: SharedService,
        private readonly sharedActions: SharedActions,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService
    ) { }

  public ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.depotInSelection$.subscribe((depotInSelection: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotIn) {
                this.selectedPlanningBlocks = depotInSelection;
                this.setButtonStatuses();
            }
        });
        this.depotOutSelection$.subscribe((depotOutSelection: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotOut) {
                this.selectedPlanningBlocks = depotOutSelection;
                this.setButtonStatuses();
            }
        });
        
    }
    setButtonStatuses() {
        this.isAllPlanned = this.selectedPlanningBlocks.every((x) => x.status.statusType == PLANNED);
        this.isAllPrePlanned = this.selectedPlanningBlocks.every((x) => x.status.statusType == PRE_PLANNED);
    }
    public unplanAction(cancelSendToHaulier: boolean): void {
        const pbIds: number[] = [];
        this.loadingSpinner = true;
        const account = this.authService.getAccount(); 
        this.selectedPlanningBlocks.map((pb: PlanningBlock) => pbIds.push(pb.id));
        
            this.sharedService.unplan(pbIds, account.username, cancelSendToHaulier).subscribe((unplannedPbs: PlanningBlock[]) => {
                // CASE : everything is successfull
                this.translate.get("TOASTR_MESSAGES.SUCCESS.UNPLAN_SUCCEEDED").subscribe((res) => {
                    this.toastr.success(res, "", {
                        closeButton: false,
                    });
                });
                this.sharedActions.updatePlanningBlocksDisplayCpl(unplannedPbs);
                this.loadingSpinner = false;
                this.sharedActions.resetModalDomains();
                this.closeModal();
                this.depotInActions.setDepotInSelection([]);
                this.depotOutActions.setDepotOutSelection([]);
            }, (error) => {
                // CASE : error but still, some pbs may be successfully unplanned
                this.loadingSpinner = false;
                this.sharedActions.setUiState(true);
                this.checkPbList(pbIds);
            });
       
    }
    public checkPbList(pbIds: number[]): void {
        let failedPbs: PlanningBlock[] = [];
        this.sharedService.getPlanningBlocksByIds(pbIds).toPromise().then((res: PlanningBlock[]) => {
            res.forEach((pb: PlanningBlock) => {
                if (pb.status.statusType !== TO_BE_PLANNED) {
                    failedPbs.push(pb);
                }
            })
        }).then(() => {
            if (this.source === this.componentSource.depotIn) {
                this.depotInActions.setDepotInSelection(failedPbs)
            }
            else if (this.source === this.componentSource.depotOut) {
                this.depotOutActions.setDepotOutSelection(failedPbs)
            }
        })
    };

    public closeModal(): void {
        this.modalManager.closeModal(this.modalType.unplan);
        this.sharedActions.setUiState(true);
    }
}
