import { PlanningGroups } from './../shared/models/planning-groups-enum.interface';
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import 'rxjs/add/observable/of';

import { map } from "rxjs/operators";
import { clientSettings } from "../static/clientSettings";
import { environment } from '../../environments/environment';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { HttpClientService } from '../shared/services/httpclient';

@Injectable()
export class DepotInService {
  constructor(
    private http: HttpClientService) { }

  public getPlanningBlocks(zone: string): Observable<PlanningBlock[]> {
    // const planningGroups = selectedPlanningGroups.join(',');
    return this.http
      .get(environment.ContainerPlanningApiUri+ "planningBlocks/depot-in?zone="+zone)
      .pipe(map((response:any) => {
        return response.data
      }))
  }

}
