import { SharedActions } from './../shared/shared.actions';
import { Component, OnInit, ElementRef, ViewChild, Output, AfterViewInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { select } from "@angular-redux/store";
import { ApplicationUser } from "../pb-screen/models/application.user.interface";
import { Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { HttpClientService } from '../shared/services/httpclient';
import { ApplicationUserService } from '../pb-screen/services/application.user.service';
import { MultiSelectComponent } from "@progress/kendo-angular-dropdowns";
import { UserSettingsService } from '../pb-screen/services/usersettings.service';
import { ToastrService } from 'ngx-toastr';
@Component({
    moduleId: module.id,
    selector: 'copySetting-modal',
    templateUrl: 'copySetting-modal.component.html'
})
export class copySettingComponent implements OnInit {
    @select('azureUsers') public azureUsers$: Observable<any[]>;
    @ViewChild('modal') public modal: ElementRef
    @Output('companyName') companyName = environment.envName;
    // @ViewChild('AzurUsersSelector') AzurUsersSelector: MultiSelectComponent;
    private applicationUser: ApplicationUser;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    public AzureADUsers: any[] = [];
    public ListItem: any[] = [];
    public elRef: ElementRef;
    public UserId: any;
    public userSetting: any[];
    public copyToAzureUser: any;

    constructor(private translate: TranslateService,
        private actions: SharedActions,
        public readonly httpClientService: HttpClientService,
        public readonly applicationUserService: ApplicationUserService,
        public readonly userSettingsService: UserSettingsService,
        private readonly toastr: ToastrService, ) { }

    ngOnInit() {

        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.applicationUser = applicationUser;
            }
        });

        this.azureUsers$.subscribe((res) => {
            this.AzureADUsers = res;
            this.AzureADUsers.forEach(element => {
                this.ListItem.push({ name: element.displayName, id: element.objectId });
            });
        })
    }
    sourceUser() {
        this.httpClientService.getToken().subscribe((token) => {
            this.userSettingsService.getUserSettings(this.UserId.id).subscribe((settings) => {
                this.userSetting = settings;
            })
        })
    }
    destanationUser() {
        this.copyToAzureUser.forEach(element => {
            console.log(element.id);
        });
    }

    private closeModal(): void {
        this.modal.nativeElement.click();
    }
    copysetting() {
        this.copyToAzureUser.forEach(copyTo => {
            this.userSettingsService.CopyUserSetting(copyTo.id, this.UserId.id).subscribe(
                res => {
                    this.toastr.success("User settings are copied");
                }
            );
        });
    }

}