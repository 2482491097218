import { Pipe, PipeTransform } from '@angular/core';
import { DynamicCaptionService } from '../services/dynamic-caption.service';

@Pipe({ name : 'dynamicCaption' , pure : false })
export class DynamicCaptionPipe implements PipeTransform {

    constructor(private readonly dynamicCaptionService : DynamicCaptionService) {
    }

    transform(field: string) {
        let caption = this.dynamicCaptionService.getCaptionByFieldName(field);
        if(caption === '') return field;
        else return caption;
    }
}