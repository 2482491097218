import { select } from "@angular-redux/store";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { AuthorizedUser, HomeService } from "../../../services/home.service";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { TO_BE_PLANNED, PRE_PLANNED, PLANNED } from "../../../models/status-type.interface";
import { PbActions } from "../../../pb.actions";
import { PbScreenService } from "../../../services/pb-screen.service";
import { SharedActions } from "../../../../shared/shared.actions";


@Component({
    moduleId: module.id,
    selector: "unplan-modal",
    templateUrl: "./unplan-modal.component.html",
})

export class UnplanModalComponent implements OnInit {
    @Input() public source: string;
    @ViewChild("modal") public modal: ElementRef;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;

    public selectedPlanningBlocks: PlanningBlock[] = [];
    public loadingSpinner: boolean = false;

    constructor(
        public readonly pbScreenService: PbScreenService,
        public readonly pbActions: PbActions,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly homeService: HomeService,
        public readonly sharedActions: SharedActions,
    ) { }

  public ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.selectedPlanningBlocks$.subscribe((pbs: PlanningBlock[]) => {
            this.selectedPlanningBlocks = pbs;
        });
    }
    public unplanAction(cancelSendToHaulier: boolean): void {
        this.loadingSpinner = true;

        this.pbScreenService.unplanPlanningBlocks(this.selectedPlanningBlocks, cancelSendToHaulier).subscribe((unplannedPbs: PlanningBlock[]) => {
            this.loadingSpinner = false;
            //this.pbActions.updatePlanningBlocksDisplay(unplannedPbs);
            this.sharedActions.updatePlanningBlocksDisplay(unplannedPbs);
            this.translate.get("TOASTR_MESSAGES.SUCCESS.UNPLAN_SUCCEEDED").subscribe((res: string) => {
                this.toastr.success(res)
                this.closeModal();
            });
        }, (err) => {
            this.loadingSpinner = false;
        })
    }

    public closeModal(): void {
        this.modal.nativeElement.click();
    }

}
