import { NgRedux, select } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

import { SharedActions } from "../../../shared/shared.actions";
import { IAppState } from "../../../store/IAppState";
import { CapacitySlot } from "../../models/capacity-slot.interface";

@Component({
  selector: "time-slot-selector",
  templateUrl: "./time-slot-selector.component.html",
})
export class TimeSlotSelectorComponent implements OnInit {

  @Output() public timeSlotSelection: EventEmitter<string> = new EventEmitter();
  @Output() public planDateSelection: EventEmitter<string> = new EventEmitter();
  public selectedTimeSlot: string;

  public planDate: string;

  public disabledDateInput: boolean = false;
  public emptyCapacity: CapacitySlot;
  public slotList: string[] = ["0:00 - 2:00", "2:00 - 4:00", "4:00 - 6:00", "6:00 - 8:00", "8:00 - 10:00"
  , "10:00 - 12:00", "12:00 - 14:00", "14:00 - 16:00", "16:00 - 18:00", "18:00 - 20:00"
  , "20:00 - 22:00", "22:00 - 24:00"];

  constructor() {
  }

  public ngOnInit() {
    this.setInitialPlanDate();
    this.planDateSelection.emit(this.planDate);
  }

  public enableDateInput() {
    this.disabledDateInput = false;
  }

  public disableDateInput() {
    this.disabledDateInput = true;
  }

  public setInitialPlanDate() {
    const currentDate = new Date();
    const currentDateHours = currentDate.getHours();

    if (this.isHourBetweenMidnightAndFiveAm(currentDateHours)) {
      this.setPlanDateToPreviousDay(currentDate);
    } else {
      this.planDate = currentDate.toISOString().slice(0, 10);
    }
  }
  public updatePlanDate() {
    this.planDateSelection.emit(this.planDate);
  }

  public updateTimeSlot() {
    this.timeSlotSelection.emit(this.selectedTimeSlot);
  }

  public setPlanDateToPreviousDay(currentDate) {
    const subtractXDays = 1;
    const newDate = new Date();

    newDate.setDate(currentDate.getDate() - subtractXDays);
    this.planDate = newDate.toLocaleString().slice(0, 9);
    //this.planDate = newDate.toLocaleDateString();

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    this.planDate = (new Date(newDate as any - tzoffset)).toISOString().slice(0, 10);

  }

  public isHourBetweenMidnightAndFiveAm(currentDateHours) {
    return currentDateHours >= 0 && currentDateHours < 5;
  }
}
