import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DepotInActions } from "../../../../depot-in/depot-in.actions";
import { DepotInService } from "../../../../depot-in/depot-in.service";
import { DepotOutActions } from "../../../../depot-out/depot-out.actions";
import { DepotOutService } from "../../../../depot-out/depot-out.service";
import { ActionTemplateScreen } from "../../../action.template.screen.enum";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { SharedService } from "../../../services/shared.service";
import { SharedActions } from "../../../shared.actions";
// tslint:disable-next-line:max-line-length
import { ActionTemplateAddress, ActionTemplateInsertLineResultViewModel, ActionTemplateInsertLineViewModel, RerouteModel } from "./reroute-modal.interface";
import { ModalManagerService } from "../../../services/modal-manager.service";
import { ModalType } from "../../../models/component-source";
import { HttpClientService } from "../../../services/httpclient";
import { MsalService } from "../../../../msal/msal.service";
import { select } from "@angular-redux/store";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";
import { Observable, Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";



@Component({
  selector: "reroute-modal",
  templateUrl: "reroute-modal.component.html",
})

export class RerouteModalComponent implements OnInit {
  public actionTemplateAddresses: ActionTemplateAddress[] = [];
  public actionTemplateAddressesListItems: any[] = [];
  public actionTemplates: string[];
  public isLoadingRerouteAddress: boolean = true;
  public modalType = ModalType;

  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
  public enableAutocomplete: boolean = false;

  @Input() public planningBlocks: PlanningBlock[];
  @Input() public actionTemplateScreen: ActionTemplateScreen;
  @Output() public onRerouteSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();

  public rerouteModel: RerouteModel = {
    action: "",
    addressNo: {
      id: "",
      value: "",
    },
  };

  destroy$ = new Subject();

  constructor(
    private readonly sharedService: SharedService,
    private readonly sharedActions: SharedActions,
    private readonly depotInActions: DepotInActions,
    private readonly depotOutActions: DepotOutActions,
    private readonly depotOutService: DepotOutService,
    private readonly depotInService: DepotInService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly modalManager: ModalManagerService,
    private readonly authService: MsalService,
    public readonly httpClientService: HttpClientService
  ) {
  }

  public ngOnInit(): void {
    this.httpClientService.getToken().subscribe((token) => {
      this.getActionTemplatesForRerouteAutocomplete();
      this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });
    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public getActionTemplatesForRerouteAutocomplete(): void {
    this.sharedService.getActionTemplates(this.actionTemplateScreen).subscribe((actionTemplates: string[]) => {
      if (actionTemplates && actionTemplates.length > 0) {
        this.actionTemplates = actionTemplates;
      }
    });
  }

  public onActionTemplateSelected(): void {
    this.isLoadingRerouteAddress = true;
    if (this.rerouteModel.action) {
      this.sharedService.getActionTemplateAddresses(this.rerouteModel.action)
        .subscribe((actionTemplateAddresses: ActionTemplateAddress[]) => {
          if (actionTemplateAddresses && actionTemplateAddresses.length > 0) {
            this.actionTemplateAddresses = actionTemplateAddresses;
            this.actionTemplateAddressesListItems = actionTemplateAddresses.map((x) => {
              return {
                id: x.no,
                value: x.name,
              };
            }).filter((v, i, a) => a.indexOf(v) === i);
            this.isLoadingRerouteAddress = false;
          }
        });
    }
  }

  public onConfirmClicked(): void {
       const account = this.authService.getAccount(); 
     
      if (account.username) {
        const planningBlockIds: number[] = this.planningBlocks.map((x) => x.id);
        const actionTemplateInsertLineViewModel: ActionTemplateInsertLineViewModel = {
          actionTemplateCode: this.rerouteModel.action,
          addressNo: this.rerouteModel.addressNo.id,
          planningBlockIds,
          userId: account.username
        };
    
        this.sharedService.insertActionTemplateLines(actionTemplateInsertLineViewModel)
          .subscribe((actionTemplateInsertLineResultViewModel: ActionTemplateInsertLineResultViewModel) => {
            this.onRerouteSucceeded.emit(true);
            this.sharedActions
              .updatePlanningBlocksDisplayCpl([...actionTemplateInsertLineResultViewModel.updatedPlanningBlocks]);
            this.sharedActions
              .addPlanningBlocksDisplay(actionTemplateInsertLineResultViewModel.newPlanningBlocksViewModel);
            this.translate.get("TOASTR_MESSAGES.SUCCESS.REROUTING_COMPLETED_SUCCESFULLY").subscribe((res: string) => {
              this.toastr.success(res, "", {
                closeButton: false
            });
            });
    
            this.depotInActions.setDepotInSelection([]);
            this.depotOutActions.setDepotOutSelection([]);
          });
    
        this.resetUi();
      }
  }

  public onCancelClicked(): void {
    this.resetUi();
  }

  public resetUi(): void {
    this.sharedActions.setUiState(true);
    this.modalManager.closeModal(this.modalType.reroute);
    this.rerouteModel = {
      action: "",
      addressNo: {
        id: "",
        value: "",
      },
    };
  }
}
