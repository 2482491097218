import { select } from "@angular-redux/store";
import { Component, Input, OnInit } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { MsalService } from "../../../msal/msal.service";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { ModalType, ComponentSource } from "../../models/component-source";
import { PlanningBlock } from "../../models/planning-block/planning-block.interface";
import { ModalManagerService } from "../../services/modal-manager.service";
import { SharedService } from "../../services/shared.service";
import { SharedActions } from "../../shared.actions";

@Component({
    selector: "update-initials-modal",
    templateUrl: "./update-initials-modal.component.html",
})


export class UpdateInitialsModalComponent implements OnInit {
    @Input() public source: string;

    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;

    public modalType = ModalType;
    public selectedPlanningBlocks: PlanningBlock[] = [];
    public componentSource = ComponentSource;
    public loadingSpinner: boolean = false;

    public initialFromValue: string = "";
    public initialUntilValue: string = "";


    constructor(private readonly sharedService: SharedService,
        private readonly sharedActions: SharedActions,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService) {

    }

  ngOnInit(): void {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })

    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });

        this.depotInSelection$.subscribe((depotInSelection: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotIn) {
                this.selectedPlanningBlocks = depotInSelection;
                this.setFormValues();
            }
        });
        this.depotOutSelection$.subscribe((depotOutSelection: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotOut) {
                this.selectedPlanningBlocks = depotOutSelection;

                this.setFormValues();
            }
        });
    }

    public setFormValues() {
        this.initialUntilValue = this.selectedPlanningBlocks.length === 1 ?
        this.setDateString(this.selectedPlanningBlocks[0].bookedDatetimeFrom) : "";
        this.initialUntilValue = this.selectedPlanningBlocks.length === 1 ?
        this.setDateString(this.selectedPlanningBlocks[0].bookedDatetimeUntil) : "";

    }
    public updateAction() {
        this.loadingSpinner = true ; 
        const account = this.authService.getAccount(); 
        let formBody = {
            pbids : this.selectedPlanningBlocks.map((pb) => pb.id), 
            initialDateTimeUntil : this.initialUntilValue , 
            initialDateTimeFrom : this.initialFromValue , 
            userId : account.username
        }

        this.sharedService.updateInitials(formBody).subscribe((data) => {
            
            this.translate.get("TOASTR_MESSAGES.SUCCESS.INITIALS_UPDATE_SUCCEEDED").subscribe((res: string) => {
                this.toastr.success(res, "", {
                    closeButton: false
                });
            });

            this.sharedActions.updatePlanningBlocksDisplayCpl(data); 
            this.loadingSpinner = false ; 
            this.closeModal();

        }, (error) => {
            this.loadingSpinner = false ;
            this.closeModal();
        })
    }

    public closeModal(): void {
        this.initialFromValue = ""; 
        this.initialUntilValue = "";
        this.modalManager.closeModal(this.modalType.updateInitials);
        this.sharedActions.setUiState(true);
    }

    public setDateString(date: Date): string {
        let stringDate = "";
        if (date) {
            const allParts = date.toString().split(" ");
            const dateParts = allParts[0].split("/");
            stringDate += dateParts.reverse().join("-") + "T" + allParts[1];
        }
        return stringDate;
    }

    public enableSave() {
        return this.initialFromValue != "" || this.initialUntilValue != "" ; 
    }

    private formatDateForApi(dateString) {
        const dateArray = dateString.split("-");
        const apiFormattedDate = dateArray[1] + "-" + dateArray[2] + "-" + dateArray[0];
        return apiFormattedDate;
    }


}
