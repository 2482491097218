import { Component} from '@angular/core';
import { NgRedux, select } from '@angular-redux/store';
import { IAppState } from '../../../store/IAppState'
import { Observable } from 'rxjs/Observable';


@Component({
  selector: 'overlay',
  templateUrl: './overlay.component.html'
  })
export class OverlayComponent {

  @select('uiState') uiState$: Observable<boolean[]>;

  constructor(private ngRedux: NgRedux<IAppState>) { }

}