
import {throwError as observableThrowError,  Observable ,  Subject } from 'rxjs';




import { Injectable } from '@angular/core';
import { HttpClientService } from '../../shared/services/httpclient';
import { ApplicationUser, UserSettings, Scope, Column } from '../models/application.user.interface';
import { defaultUserSettings } from '../static/user/defaultUserSettings';
import { mockUser } from '../../mocks/userMock';
import { environment } from '../../../environments/environment';
import { Response } from '@angular/http';
import { map, catchError } from 'rxjs/operators';
import { element } from 'protractor';
import { settings } from 'cluster';


@Injectable()
export class ApplicationUserService {
    private subject = new Subject<any>();

    constructor(
        private readonly httpClientService: HttpClientService
    ) { }

    getDefaultApplicationUser(username: string, fullName: string): ApplicationUser {
        const user = {
            username: username,
            fullName: fullName,
        } as ApplicationUser;
        return user;
    }

   /* getApplicationUser(): Observable<any> {

      const id = localStorage.getItem('userId') ;

        this.getUserSettings(id).subscribe((userSettings : UserSettings) => {
            if(userSettings !== undefined) {
                console.log(userSettings) ;
              let applicationUser = {
                  id : id ,
                  settings : userSettings ,
                  planningBoard : JSON.parse(userSettings.value)
              } ;

              this.subject.next(applicationUser);
            }
            else {
                this.createSettings().subscribe((setting) => {
                    this.createNewSettingsForUser(setting , id).subscribe((res) => {
                        let applicationUser = {
                            id : id ,
                            settings : res ,
                            planningBoard : JSON.parse(res.value)
                        } as ApplicationUser ;

                        this.subject.next(applicationUser);
                    })

                })
            }
        })

      return this.subject.asObservable();



    }*/

    postApplicationUser(user: ApplicationUser): Observable<any> {
        const postData =  {

        };
        return this.httpClientService
            .post(environment.tmsUserApiEndPointAddress + 'usersettings', postData).pipe(
                map((response: any) => {
                    return response;
                }) ,
                catchError((error) => {
                    return observableThrowError(error);
                })
            );
    }

    putApplicationUser(user: ApplicationUser) {
        return this.httpClientService
            .put(environment.tmsUserApiEndPointAddress + 'usersettings/' + user.id , user.settings).pipe(
                map((response: any) => {
                    return response;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            );
    }

    updateApplicationUser(user: ApplicationUser): Observable<any> {
        // const planningBoard = user.planningBoard ;
        const putData = {
        };
        return this.httpClientService.put(environment.tmsUserApiEndPointAddress + 'usersettings' , putData).pipe(
            map(
                (response: any) => {
                    return response;
                }
            ), catchError((error) => {
                return observableThrowError(error);
            })
        );
    }

    getUserSettings(userId): Observable<any> {
        return this.httpClientService
            .get(environment.tmsUserApiEndPointAddress + 'usersettings?scope=module&scopeValue=planning&userId=' + userId)
            .pipe(
                map((response: any) => {
                    return response.data[0] ;
                }),
                catchError((error) => {
                    return observableThrowError(error);
                })
            );
    }

    createSettings(): Observable<any>{
        const postBody = {
            name : 'Preferences',
            settingType: 0
        };
        return this.httpClientService.post(environment.tmsUserApiEndPointAddress + 'settings' , postBody).pipe(
            map((res: any) => {
                return res.data;
            }) ,
            catchError((error) => {
                return observableThrowError(error);
            })
        );
    }

    createNewSettingsForUser(setting, userId): Observable<any> {
        const postBody = {
            setting : setting ,
            value : JSON.stringify({
                selectedToColumns: [] ,
                OrderLineColumnSelection : [],
                selectedColumns: [] ,
                savedAdvancedFilters: [],
                quickViews: [],
                selectedQuickFilters: {}
            }),
            scope : Scope.Global ,
            scopeValue : 'global',
            userId : userId
        };
        return this.httpClientService.post(environment.tmsUserApiEndPointAddress + 'usersettings' , postBody).pipe(
            map((response: any) => {
                return response.data;
            }) ,
            catchError((error) => {
                return observableThrowError(error);
            })
        );
    }

    GetAzureUser() {
       return  this.httpClientService.get(environment.tmsApiEndpointAddress + 'users').pipe(map(
            (res) => {
                return res;
            }),
            catchError((error) => {
                return observableThrowError(error);
            })
        );
    }






}
