export enum PlanningConflictType {
    WARNING = "1",
    ERROR = "0"
}

export enum HasConflicts {
  NoConflicts,
  OnlyWarnings,
  Errors
}

export function hasConflicts(planningConflicts): HasConflicts {
  let result = HasConflicts.NoConflicts;
  if(planningConflicts && planningConflicts.length > 0)
  {
    let errors = planningConflicts.filter(x => x.conflictType === "0");
    if(errors.length > 0) {
      result = HasConflicts.Errors;
    } else {
      result = HasConflicts.OnlyWarnings;
    }
  }
  return result;
}
