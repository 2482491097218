
import { OnInit, Component, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subject } from "rxjs";
import { Column, ApplicationUser } from "../../../../../pb-screen/models/application.user.interface";
import { DefaultOrderLineColumnDefiniton, OptionalOrderLineColumnDefiniton } from "../../../../../pb-screen/static/columns/OrderLineColumnDefinition";
import { SelectableItemDefinition, TranslatedSelectableItemDefinition, OnItemSelectedEvent, OnItemDeselectedEvent } from "../../../../../pb-screen/containers/filters/selectable-item/selectable-item.interface";
import { ToActions } from "../../../../to.actions";
import { ApplicationUserActions } from "../../../../../pb-screen/application.user.actions";
import { TranslateService } from "@ngx-translate/core";
import { takeUntil } from "rxjs/operators";
@Component({
    moduleId : module.id , 
    selector: 'orderline-column-selection',
    templateUrl: './to-details-column-selection.component.html',
})
export class OrderLineColumnSelection implements OnInit, OnDestroy {
    
    @select('sessionOrderLineColumnSelection') public sessionOrderLineColumnSelection$: Observable<Column[]>
    readonly defaultColumns: Column[] = DefaultOrderLineColumnDefiniton; 
    readonly optionalColumns: Column[] = OptionalOrderLineColumnDefiniton;
    selectableColumnsSet1: SelectableItemDefinition[];
    selectableColumnsSet2: SelectableItemDefinition[];
    selectableColumnsSet3: SelectableItemDefinition[];
    selectableColumnsSet4: SelectableItemDefinition[];
    selectableColumnsSet5: SelectableItemDefinition[];
    defaultColumnDefinitions: SelectableItemDefinition[];
    private translatedOptionalColumns: TranslatedSelectableItemDefinition[];
    private applicationUser: ApplicationUser;
    private currentSelectedColumns: Column[];
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    destroy$ = new Subject();
    

    constructor(
        private actions: ToActions,
        private userActions: ApplicationUserActions,
        private translate: TranslateService) {
    
      }

    ngOnInit(): void {
      this.sessionOrderLineColumnSelection$.pipe(takeUntil(this.destroy$)).subscribe((selectedColumns: Column[]) => this.currentSelectedColumns =
              selectedColumns);
       
        const d = this.defaultColumns;
        Promise.all(d.map(async (column):
        Promise<TranslatedSelectableItemDefinition> => {
         const t = await this.translate.get(column.name).toPromise();
            return { enabled: false, checked: new Observable(o => o.next(true)), name: column.name, translated: t } as TranslatedSelectableItemDefinition;
                })).then((columnDefinitions) => {
         this.defaultColumnDefinitions = columnDefinitions;
         this.defaultColumnDefinitions.sort(OrderLineColumnSelection.sortTranslatedSelectableItemDefinition);
         });

        const o = this.optionalColumns;
        Promise.all(o.map(async (column): Promise<TranslatedSelectableItemDefinition> => {
         const t = await this.translate.get(column.name).toPromise();
         const definition = { name: column.name, translated: t, enabled: true } as TranslatedSelectableItemDefinition;
         this.sessionOrderLineColumnSelection$.pipe(takeUntil(this.destroy$)).subscribe(a => {
         definition.checked = new Observable(o => o.next(a.findIndex(e => e.name === column.name) >= 0));
            });
            return definition;
         })).then((columnDefinitions) => {
                this.translatedOptionalColumns = columnDefinitions;

      
                 const maxSetElements = Math.ceil(this.optionalColumns.length / 5);
                 const set2Ceiling = maxSetElements * 2;
                 const set3Ceiling = maxSetElements * 3;
                 const set4Ceiling = maxSetElements * 4;
                 const set5Ceiling = maxSetElements * 5 ;

                 this.translatedOptionalColumns.sort(OrderLineColumnSelection.sortTranslatedSelectableItemDefinition);

                 this.selectableColumnsSet1 = this.translatedOptionalColumns.slice(0, maxSetElements);
                 this.selectableColumnsSet2 = this.translatedOptionalColumns.slice(maxSetElements, set2Ceiling);
                 this.selectableColumnsSet3 = this.translatedOptionalColumns.slice(set2Ceiling, set3Ceiling);
                 this.selectableColumnsSet4 = this.translatedOptionalColumns.slice(set3Ceiling, set4Ceiling);
                 this.selectableColumnsSet5 = this.translatedOptionalColumns.slice(set4Ceiling, set5Ceiling);
                });
        
                this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
                  if(applicationUser) {
                    this.applicationUser = applicationUser ;

                    let selectedColumn = applicationUser.orderlinePreferences.OrderLineColumnSelection;
                    if(selectedColumn.length > 0 ) {
                      this.checkIfColumnNotExistInDb(selectedColumn);
                      //this.userActions.updateApplicationUser(this.applicationUser,'defaultOrderLineUserSettings');
                    }
                  }
                });
        
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
  }

    checkIfColumnNotExistInDb(selection) {
      selection.forEach(Column => {
        let length =  OptionalOrderLineColumnDefiniton.filter(c => c.name === Column.name).length;
          if(length === 0){
        // Delete Column From List of user selected Columns 
        let ind = selection.indexOf(Column);
        if (ind !== -1) {
          this.applicationUser.orderlinePreferences.OrderLineColumnSelection = [...[
            ...selection.slice(0, ind),
            ...selection.slice(ind + 1)
              ]];
            }
        }
      });

}
    userSelectedColumn(event: OnItemSelectedEvent) {
      let column: Column = {
        name: event.name,
        width: 200,
      };
  
      this.actions.updateOrderLineSessionColumns([...this.currentSelectedColumns, column]);
      
    }
  
    userDeselectedColumn(event: OnItemDeselectedEvent) {
      const index = this.currentSelectedColumns.indexOf(this.currentSelectedColumns.filter((x) => x.name === event.name)[0]);
  
      if (index !== -1) {
        this.actions.updateOrderLineSessionColumns([
          ...this.currentSelectedColumns.slice(0, index),
          ...this.currentSelectedColumns.slice(index + 1)
        ]);
      }
    }

    private static sortTranslatedSelectableItemDefinition(item1: TranslatedSelectableItemDefinition,
        item2: TranslatedSelectableItemDefinition): number {
        if (item1.translated > item2.translated) return 1;
        if (item2.translated > item1.translated) return -1;
        return 0;
      }

      saveProfile() {
        this.applicationUser.orderlinePreferences.OrderLineColumnSelection = [...this.currentSelectedColumns];
        this.userActions.updateApplicationUser(this.applicationUser,'defaultOrderLineUserSettings',true);
      }

}