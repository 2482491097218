import { select } from "@angular-redux/store";
import { Inject, Injectable, OnInit } from "@angular/core";
import { Http, RequestOptions, Response, ResponseContentType } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";
import { Subject } from "rxjs/Subject";
import { environment } from "../../../../environments/environment";
import { ContainerAssignmentData } from "../../../pool/models/ContainerAssignmentData";
import { DamageAreas } from "../../../pool/models/damageareas-enum";
import { DamageList } from "../../../pool/models/damageList.interface";
import { PoolBooking } from "../../../pool/models/pool-booking.interface";
import { PoolContainerInspectionItem } from "../../../pool/models/pool-container-inspection-item.interface";
import { PoolContainer } from "../../../pool/models/pool-containers.interface";
import { PoolHaulier } from "../../../pool/models/pool-haulier.interface";
import { PoolMove } from "../../../pool/models/pool-move.interface";
import { PoolTruck } from "../../../pool/models/pool-truck.interface";
import { EIRdata } from "../../models/EIR-data.interface";
import { SharedService } from "../shared.service";

@Injectable()
export class EIRService {

  private baseUrl: string = environment.ContainerPlanningApiUri + "print";
  private selectedPoolContainer: PoolContainer;
  private data: ContainerAssignmentData;
  private damages: PoolContainerInspectionItem[];
  private poolbooking: PoolBooking;
  private haulier: PoolHaulier;
  private truck: PoolTruck;
  private move: PoolMove;
  private customer: string;
  private damagesFromCall: DamageList[];
  private printfromMove: boolean = false;
  private direction: string;



    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
        private readonly sharedService: SharedService,
    ) { }



    public printEIR(newMove: PoolMove, selectedChecklistItems: PoolContainerInspectionItem[],
                    customer: string, direction: string): void {

      this.damages = selectedChecklistItems;
      this.move = newMove;
      this.customer = customer;
      this.printfromMove = false;
      this.direction = direction;
      this.prepareObject();
    }

    public printEIRFromMove(newMove: PoolMove, selectedChecklistItems: DamageList[], 
                            customer: string, direction: string): void {

      this.damagesFromCall = selectedChecklistItems;
      this.move = newMove;
      this.customer = customer;
      this.printfromMove = true;
      this.direction = direction;
      this.prepareObject();
    }

  public prepareObject() {




      const  formattedData: EIRdata = {
        customer:  " ",
        date:  " ",
        containerType:  " ",
        booking: " ",
        containerNo: " ",
        reference:  " ",
        containerMove: " ",
        delivered:  " ",
        licensePlate:  " ",
        sealNo:  " ",
        remarks:  " ",
        damageCodes: " ",
        damageDescriptions: " ",
        damageSections: " ",
        direction: " ",
        time: " ",
        type: {
          iD: 1,
        },
      };

      if (this.move) {
        if (this.move.containerNo) {
          formattedData.containerNo = this.move.containerNo;
        }

        if (this.move.containerType) {
          formattedData.containerType = this.move.containerType;
        }

        if (this.move.no) {
          formattedData.containerMove = this.move.no;
        }

        if (this.move.truckRegistrationNo) {
          formattedData.licensePlate = this.move.truckRegistrationNo;
        }

        if (this.move.haulierName) {
          formattedData.delivered = this.move.haulierName;
        }

        if (this.move.referenceCode) {
          formattedData.reference = this.move.referenceCode;
        }

        if (this.move.dateTime) {
          formattedData.date = this.move.dateTime.split(" ")[0];
          formattedData.time = this.move.dateTime.split(" ")[1];
          if (!formattedData.time || formattedData.time.length < 3) {
            formattedData.time = this.move.dateTime.split("  ")[1];
          }
          if (!formattedData.time || formattedData.time.length < 3) {
            formattedData.time = " ";
          }
        }

        if (this.move.referenceCode) {
          formattedData.booking = this.move.referenceCode;
        }

        if (this.move.comment) {
          formattedData.remarks = this.move.comment;
        }
      }

      if (this.customer) {
        formattedData.customer = this.customer;
      }

      if (this.direction) {
        if (this.direction === "Incoming" ) {
          formattedData.direction = "IN";
        }
        if (this.direction === "Outgoing" ) {
          formattedData.direction = "OUT";
        }      }

      if (!this.printfromMove) {
        if (this.damages && this.damages.length > 0) {
          let damagecodes: string = "";
          let damagedescriptions: string = "";
          let damagesections: string = "";

          for (const damages of this.damages) {
            if (damages.checked && damages.checked === true) {

              const area = DamageAreas[damages.checklistArea];

              damagecodes += damages.code +  " \n";
              damagesections += area + " \n";
              damagedescriptions += damages.description + " \n";
            }
        }
          formattedData.damageCodes = damagecodes;
          formattedData.damageDescriptions = damagedescriptions;
          formattedData.damageSections = damagesections;

        }
      } else {
          if (this.damagesFromCall && this.damagesFromCall.length > 0) {
            let damagecodes: string = "";
            let damagedescriptions: string = "";
            let damagesections: string = "";

            for (const damages of this.damagesFromCall) {

                const area = DamageAreas[damages.checklistArea];

                damagecodes += damages.inspectionCode +  " \n";
                damagesections += area + " \n";
                damagedescriptions += damages.description + " \n";
              }
            formattedData.damageCodes = damagecodes;
            formattedData.damageDescriptions = damagedescriptions;
            formattedData.damageSections = damagesections;
          }
      }

      formattedData.type.iD = 1;
      this.getPDF(formattedData).subscribe();
  }

      public getPDF(formattedData: EIRdata){
        return this.http.post(this.baseUrl + "/eir" , formattedData, { responseType:ResponseContentType.Blob } )
        .map((res:any) => 
            window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
        .catch((err) => {
          return Observable.throw(err);
        });

      }

      public convertDate(dateString) {
        const date = new Date(dateString);
        return date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear();
      }

}