import { NgRedux, select } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApplicationUser } from '../models/application.user.interface';
import { IAppState } from '../../store/IAppState';
import { ChannelEventFactory, ChannelEvent } from '../services/channel.event.factory';
import { ChannelService } from '../services/channel.service';


@Injectable()
export abstract class SignalrBroadcaster {
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;

    private signalrUserChannel: string;

    protected constructor(
        protected readonly ngRedux: NgRedux<IAppState>,
        private readonly channelEventFactory: ChannelEventFactory,
        private readonly channelService: ChannelService) {
        this.channelService.starting$.subscribe(
            () => {
                this.applicationUser$.subscribe((data: ApplicationUser) => {
                    if (data) {
                        this.signalrUserChannel = data.id;

                        this.channelService.sub(data.id).subscribe(
                            (e: ChannelEvent) => {
                            },
                            (error: any) => {
                                // tslint:disable-next-line:no-console
                                console.warn('SignalR: Attempt to join channel failed', error);
                            },
                        );
                    }
                });
            },
            () => {
                // tslint:disable-next-line:no-console
                console.warn('SignalR: Service failed to start');
            },
        );
    }

    public dispatchAndTransmitAction(action) {
        this.ngRedux.dispatch(action);
        this.channelService.publish(this.channelEventFactory.createTransmitActionEvent(this.signalrUserChannel, action));
    }

    public TransmitAction(action) {

        this.channelService.publish(this.channelEventFactory.createTransmitActionEvent(this.signalrUserChannel, action));
    }

    public dispatchAction(action) {
        this.ngRedux.dispatch(action);
    }

    public dispatchAndTransmitActionToGeneralChannel(action) {
        this.ngRedux.dispatch(action);
        this.channelService.publish(this.channelEventFactory.createTransmitActionEvent('general', action));
    }
}
