import * as moment from "moment";

export function setVisibleWithingRange(to, startDate, endDate) {
    var navisionNullDate = moment("1760-01-01");
    startDate = moment(startDate).hour(0).minute(0).seconds(0);
    endDate = moment(endDate).hour(23).minute(59).seconds(59);

    for (var i in to.orderlines) {
        var ol = to.orderlines[i];

        ol.isWithinVisibleRange = false;
        // SEE IF IT IS A ZERO LENGTH PB (ORNOT)
        if (moment(ol.plannedDateTimeFrom).isSame(moment(ol.plannedDateTimeUntil))) {
            // WE HAVE A ZERO LENGTH
            if (moment(ol.plannedDateTimeFrom).isSame(startDate) || moment(ol.plannedDateTimeFrom).isAfter(startDate)) {
                ol.isWithinVisibleRange = true;
            }

        } else {
            // assuming pb.startFrom is ALWAYS before pb.endUntil
            if (moment(ol.plannedDateTimeFrom).isAfter(startDate) && moment(ol.plannedDateTimeFrom).isBefore(endDate) && moment(ol.plannedDateTimeUntil).isAfter(startDate)) {
                ol.isWithinVisibleRange = true;
            }
        }

        if (!to.hasPastPBs && (moment(ol.plannedDateTimeUntil).isBefore(startDate) || moment(ol.plannedDateTimeFrom).isBefore(startDate))) {
            to.hasPastPBs = true;
        }

        if (!to.hasFuturePBs && (moment(ol.plannedDateTimeFrom).isAfter(endDate) || moment(ol.plannedDateTimeUntil).isAfter(endDate))) {
            to.hasFuturePBs = true;
        }

    }
    to.visibleOrderlines = to.orderlines.filter(ol => ol.isWithinVisibleRange == true);
}

export function formatDateForApi(date) {
    const dateArray = date.split('/');
    const formattedDate = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0] ;
    return new Date(formattedDate).toISOString();
  }

export function setVisibleWithingRangePBs(to,startDate,endDate)
    {
        var navisionNullDate = moment("1760-01-01") ;
        startDate = moment(startDate).set({hour:0, minute:0});
        endDate =  moment({ y: startDate.year(), M: startDate.month(), d: startDate.date(), h: 23, m: 59 });

        for (var i in to.planningBlocks) {
            var pb = to.planningBlocks[i];
            if(pb.planningOrderlines){
                pb.isWithinVisibleRange = false;

                if ((pb.info.indexOf("Start") == 0 || pb.info.indexOf("Stop") == 0)) { // Start PB
                    // SEE IF IT IS A ZERO LENGTH PB (ORNOT)
                    if(moment(pb.firstPlanningTimeFromPlanning).isSame(moment(pb.lastPlanningTimeUntilPlanning))){
                        // WE HAVE A ZERO LENGTH
                        if (moment(pb.firstPlanningTimeFromPlanning).isSame(startDate) || moment(pb.firstPlanningTimeFromPlanning).isAfter(startDate)) {
                            pb.isWithinVisibleRange = true;
                        }
                    } else {
                        // OLD CASE FOR NON ZERO INTERVALS
                        if (moment(pb.firstPlanningTimeFromPlanning).isBefore(endDate) && moment(pb.lastPlanningTimeUntilPlanning).isAfter(startDate)) {
                            pb.isWithinVisibleRange = true;
                        }

                    }
                }

                // SEE IF IT IS A ZERO LENGTH PB (ORNOT)
                if(moment(pb.startFrom).isSame(moment(pb.endUntil))){
                    // WE HAVE A ZERO LENGTH
                    if (moment(pb.startFrom).isSame(startDate) || moment(pb.startFrom).isAfter(startDate)) {
                        pb.isWithinVisibleRange = true;
                    }

                } else {
                    // assuming pb.startFrom is ALWAYS before pb.endUntil
                    if (moment(pb.startFrom).isBefore(endDate) && moment(pb.endUntil).isAfter(startDate)) {
                        pb.isWithinVisibleRange = true;
                    }
                }

                //ignore nav null date year: 1753
                if ((moment(pb.endUntil).year() != 1753 && moment(pb.endUntil).isBefore(startDate)) 
                    || (moment(pb.startFrom).year() != 1753 && moment(pb.startFrom).isBefore(startDate))) {
                    to.hasPastPBs = true;
                }

                if (moment(pb.startFrom).isAfter(endDate) || moment(pb.endUntil).isAfter(endDate)) {
                    to.hasFuturePBs = true;
                }
            }
        }
        
        to.planningBlocks = to.planningBlocks.filter(pb => pb.isWithinVisibleRange == true);
    }