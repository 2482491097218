export interface PlanningConflict {
    transportOrderId : string;
    conflictType : string;
    conflictMessage : string;
    conflictReason : string;
    pbId : string;
    entryNumber : string;
    orderNumber: string;
    orderLineNumber : string;
    creationDateTime : string;
    ressourceType : string;
    ressourceNo : string;
}

export enum HasConflicts {
    HasErrors, 
    HasWarnings, 
    HasOnlyWarnings, 
    HasOnlyErrors,
    None
}