
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { HttpClientService } from "./httpclient";
import { clientSettings } from "../../pb-screen/static/client/clientSettings";
import { Sms } from '../models/sms/sms.interface';

@Injectable()
export class SmsService {
    constructor(
        private readonly http: HttpClientService,
      ) { }

    public send(transportOrderNumber: string, pbScreenPlanningBlockIds: number[],
      pbScreenTextMessage: string, userId: string) {

        return this.http.post(clientSettings.containerPlanningApiUri +
          "sms/send/", {
            transportOrderNumber,
            pbScreenPlanningBlockIds,
            pbScreenTextMessage,
            userId,
          }).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
      }

      public getTextMessage(transportOrderNumber: string, pbScreenPlanningBlockIds: number[]): Observable<Sms> {
        return this.http.post(clientSettings.containerPlanningApiUri +
            "sms/text-message/", {
              pbScreenPlanningBlockIds,
              transportOrderNumber,
            }).pipe(
            map((response: any) => {
              return response.data;
            }),
            catchError((err) => {
              return observableThrowError(err);
            }),);
      }
}
