import { NgRedux, select } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

import { SharedActions } from "../../../shared/shared.actions";
import { IAppState } from "../../../store/IAppState";
import { CapacitySlot } from "../../models/capacity-slot.interface";
import { HttpClientService } from "../../services/httpclient";

@Component({
  selector: "depot-capacity-selector",
  templateUrl: "./capacity-selector.component.html",
})
export class DepotCapacitySelectorComponent implements OnInit {

  @Output() public capacitySelection: EventEmitter<CapacitySlot> = new EventEmitter();
  @Output() public planDateSelection: EventEmitter<string> = new EventEmitter();
  @select("capacitySlots") public capacitySlots$: Observable<CapacitySlot[]>;
  @Input() public selectedCapacity: CapacitySlot = undefined;
  @Input() public hasError: boolean;

  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
  public enableAutocomplete: boolean = false;
  @select("planningGroupString") planningGroupString$ : Observable<string>

  public pgString : string = "";
  public capacitySlots: CapacitySlot[];
  public planDate: string;
  public apiFormattedDate: string;

  public disabledCapacityInput: boolean = false;
  public disabledDateInput: boolean = false;
  public emptyCapacity: CapacitySlot;
  public user: ApplicationUser;

  constructor(
    private readonly sharedActions: SharedActions,
    public readonly httpClientService: HttpClientService,
    private readonly ngRedux: NgRedux<IAppState>) {
  }

  public ngOnInit() {
    this.setInitialPlanDate();
    this.formatDateForApi();
    this.planDateSelection.emit(this.apiFormattedDate);

    this.planningGroupString$.subscribe((data) => {
      this.pgString = data;
    })
    this.applicationUser$.subscribe((data) => {
      if (data) {
        this.user = data;
        this.enableAutocomplete = data.generalSetting.enableAutocomplete;
      }
    })

    this.capacitySlots$.subscribe((capacitySlots: CapacitySlot[]) => {
      this.capacitySlots = capacitySlots;
    });

    this.updatePlanDate() ; 
  }

  public updatePlanDate() {
    // the current selection needs to be reset when a new list of capacities is fetched
    // had to force undefined,to fix the null value bug
    this.selectedCapacity = undefined;
    this.capacitySlots = [];
    this.updateCapacitySlots();
    this.planDateSelection.emit(this.apiFormattedDate);
    this.capacitySelection.emit(this.selectedCapacity);
  }

  public updateCapacitySelection(data: CapacitySlot) {
    if (data) {
      this.capacitySelection.emit(this.selectedCapacity);
    }
  }

  public updateCapacitySlots() {
    this.formatDateForApi();
    this.httpClientService.getToken().subscribe((token) => {
      if (this.pgString !== "" && this.pgString !== null) {
  
        this.sharedActions.getCapacitySlotsCpl(this.apiFormattedDate, this.pgString);
      } else {
        this.sharedActions.getCapacitySlotsCpl(this.apiFormattedDate, "ALL");
  
      }
    });
  }

  public formatDateForApi() {
    const dateArray = this.planDate.split("-");
    this.apiFormattedDate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];
  }

  public setSelectionFromParent(capacity: string): CapacitySlot {
    const foundCapacity = this.capacitySlots.find((x) => x.truck.name === capacity);
    if (foundCapacity) {
      this.selectedCapacity = foundCapacity;
    } else {
      this.selectedCapacity = this.emptyCapacity;
    }

    return this.selectedCapacity;
  }

  public autocompleListFormatter(data: any): string {
    if (data.driver.name != null) {
      return `${data.truck.name} - ${data.driver.name}`;
    } else {
      return `${data.truck.name} `;
    }
  }

  public valueListFormatter(data: any): string {
    if (data.driver.name != null) {
      return `${data.truck.name} - ${data.driver.name}`;
    } else {
      return `${data.truck.name} `;
    }
  }

  public enableCapacityInput() {
    this.disabledCapacityInput = false;
  }

  public disableCapacityInput() {
    this.disabledCapacityInput = true;
  }

  public enableDateInput() {
    this.disabledDateInput = false;
  }

  public disableDateInput() {
    this.disabledDateInput = true;
  }

  public setInitialPlanDate() {
    const currentDate = new Date();
    const currentDateHours = currentDate.getHours();

    if (this.isHourBetweenMidnightAndFiveAm(currentDateHours)) {
      this.setPlanDateToPreviousDay(currentDate);
    } else {
      this.planDate = currentDate.toISOString().slice(0, 10);
    }
  }

  public setPlanDateToPreviousDay(currentDate) {
    const subtractXDays = 1;
    const newDate = new Date();

    newDate.setDate(currentDate.getDate() - subtractXDays);
    this.planDate = newDate.toLocaleString().slice(0, 9);
    //this.planDate = newDate.toLocaleDateString();

    var tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
    this.planDate = (new Date(newDate as any - tzoffset)).toISOString().slice(0, 10);

  }

  public isHourBetweenMidnightAndFiveAm(currentDateHours) {
    return currentDateHours >= 0 && currentDateHours < 5;
  }
}
