export interface PoolContainer {
    timeStamp: string;
    containerNo: string;
    carrierCode: string;
    containerType: string;
    containerSubType: string;
    dimension: string;
    height: string;
    width: string;
    volume: string;
    tareWeight: string;
    build: string;
    sequence: string;
    status: PoolContainerStatus;
    inDate: string;
    stockUnit: string;
    location: string;
    locationGroup: string;
    carrierName: string;
    comment: string;
    poolAddressNo: string;
    poolAddressName: string;
    poolAddressSearchName: string;
    poolAddressCity: string;
    prevstatus: string;
    currMove: string;
    lastChargeStatus: string;
    inhoud: string;
    dedicated: string;
    fromAddressNo: string;
    createdBy: string;
    lastModifiedBy: string;
    lastModificationDateTime: string;
    toLocation : string;
    haulierNo : string;
    driverNo : string;
    driverName : string;
    haulierName : string;
    truckNo : string;
    truckRegistrationNo : string;
    announceComment : string;

}


export enum PoolContainerStatus {
    AVAILABLE = 0,
    NOT_AVAILABLE = 1,
    RESERVED = 2,
    NOT_ON_TERRAIN = 3,
    ALL = 4, 
    ANNOUNCED = 10
}
export function setContainerstatus(code: string) {
    let stringName = "";
    switch (parseInt(code)) {
        case PoolContainerStatus.AVAILABLE:
            stringName = "Available";
            break;
        case PoolContainerStatus.NOT_AVAILABLE:
            stringName = "Not Available";
            break;
        case PoolContainerStatus.RESERVED:
            stringName = "Reserved";
            break;
        case PoolContainerStatus.NOT_ON_TERRAIN:
            stringName = "Not on terrain";
            break;
        case PoolContainerStatus.ALL : 
            stringName = "All"; 
            break;
        case PoolContainerStatus.ANNOUNCED : 
            stringName = "Announced" ; 
            break;
        default:
            stringName = "Unknown";
    }
    return stringName;
}