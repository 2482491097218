import { DatePipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'orderDate'
})
export class OrderDatePipe extends DatePipe implements PipeTransform {
    transform(value: string, pattern?: string): string {
        let date = '';
        if (value.indexOf('0001-01-01T00:00:00Z') !== -1  || value.indexOf('1753-01-01T00:00:00Z') !== -1 || value === '')  {
            return date;
        }
        else {
            value = value.replace(" undefined" , '') ; 
            date = super.transform(value, pattern);
            let date2 = new Date(value);
            let monthT = date2.getUTCMonth() + 1;
            let monthS = "";
            let minutes = "";
            let hours = "";
            let day = "";

            if (date2.getUTCMinutes() === 0) {
                minutes = "00"
            } else if (date2.getUTCMinutes().toString().length === 1) {
                minutes = "0" + date2.getUTCMinutes().toString();
            } else {
                minutes = date2.getUTCMinutes().toString();
            }

            if (monthT.toString().length === 1) {
                monthS = "0" + monthT
            } else {
                monthS = monthT.toString();
            }

            if (date2.getUTCHours().toString().length === 1) {
                hours = "0" + date2.getUTCHours().toString();
            } else {
                hours = date2.getUTCHours().toString();
            }

            if (date2.getUTCDate().toString().length === 1) {
                day = "0" + date2.getUTCDate().toString();
            } else {
                day = date2.getUTCDate().toString();
            }

            date = monthS + "/" + day + "/" + date2.getUTCFullYear() + " " + hours + ":" + minutes + ":00Z";

            const navigatorCast = window.navigator as any;
            const browserLocale = navigatorCast.languages ? navigatorCast.languages[0] : (navigatorCast.language || navigatorCast.userLanguage)
            //const formattedDate = new Date(date).toLocaleString(browserLocale, { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone }) ; 
            const formattedDate =  super.transform(date2 , 'dd/MM/yyyy' ) ; 
            return formattedDate;
        }

    }

}