import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RedirectGuard } from './shared/guards/redirect.guard';
import { environment } from '../environments/environment';
import { MainPageComponent } from './mainPage/main-page.component';
import { MsalGuard } from './msal/msal.guard';

const appRoutes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: MainPageComponent },
  {
    path: 'pt4Home',
    canActivate: [RedirectGuard], component: RedirectGuard, data: {
      externalUrl: environment.pt4ClientEndPointAddress + '/home'
    }
  },
  { path : 'week-planning', loadChildren: () => import('./planningBoard/planningBoard.module').then(m => m.PlanningBoardModule), canLoad: [MsalGuard]},
  { path : 'planning', loadChildren: () => import('./planningBoard/planningBoard.module').then(m => m.PlanningBoardModule), canLoad: [MsalGuard]},
  { path : 'pb-screen', loadChildren: () => import('./pb-screen/pb-screen.module').then(m => m.PbScreenModule), canLoad: [MsalGuard]},
  { path : 'to-screen', loadChildren: () => import('./to-screen/to-screen.module').then(m => m.ToScreenModule), canLoad: [MsalGuard]},
  { path : 'driveragenda' , loadChildren: () => import('./driverAgenda/driverAgenda.module').then(m => m.DriverAgendaModule) , canLoad: [MsalGuard]},
  { path : 'truckagenda' , loadChildren: () => import('./truckAgenda/truckAgenda.module').then(m => m.TruckAgendaModule) , canLoad: [MsalGuard]},
  //{ path: 'entry/:login/:destination', component: HomeComponent, canActivate: [InitialGuard] },
  { path : 'depot-in' , loadChildren: () => import('./depot-in/depot-in.module').then(m => m.DepotInModule) , canLoad: [MsalGuard]},
  { path : 'depot-out' , loadChildren: () => import('./depot-out/depot-out.module').then(m => m.DepotOutModule) , canLoad: [MsalGuard]},
  { path : 'pool' , loadChildren: () => import('./pool/pool.module').then(m => m.PoolModule) , canLoad: [MsalGuard]},
  { path : 'announcement' , loadChildren: () => import('./announcement/annoucement.module').then(m => m.AnnoucementModule) , canLoad: [MsalGuard]},
  { path : 'stacker', loadChildren: () => import('./stacker-screen/stacker.module').then(m => m.StackerModule) , canLoad: [MsalGuard]},
  { path : 'waiting-room', loadChildren: () => import('./truckswaiting/truckswaiting.module').then(m => m.TrucksWaitingModule) , canLoad: [MsalGuard]}
];

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);
