import { ActionKeys } from "../pb-screen/action.keys";
import { FilterStackerPlanningBlocks } from "../stacker-screen/filters/stacker.quickFilter";
import { getFilterStackerPbsByTxt } from "../stacker-screen/filters/stacker.txt.filter";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';

export function stackerReducer(state: IAppState = InitialState.initialState, action) {
    switch (action.type) {
        case ActionKeys.STORE_PLANNING_BLOCKS_FOR_STACKER:
            return storePbsForStacker(state, action);
        case ActionKeys.UPDATE_STACKER_COLUMN_SELECTION: 
            return updateStackerColumnSelection(state,action);
        case ActionKeys.QUICK_FILTER_STACKER_PLANNING_BLOCKS:
            return quickFilterStackerPlanningBlocks(state,action);
        case ActionKeys.SET_FILTER_STACKER_VALUE: 
            return setQuickFilterValue(state,action);
        case ActionKeys.SET_SELECTED_STACKER_PBS: 
            return setSelectedStackerPbs(state,action);
        case ActionKeys.STORE_STACKER_LOCATIONS: 
            return storeStackerLocations(state,action);
        case ActionKeys.TXT_FILTER_STACKER_PBS: 
            return textStackerPbs(state,action);
        case ActionKeys.TXT_FILTER_VALUE : 
            return setTxtFilterValue(state,action);
        case ActionKeys.UPDATE_STACKER_DISPLAY: 
            return updateStackerDisplay(state,action);
        case ActionKeys.REGISTER_STACKER_ACTIONS_REDO:
            return updateStackerActionsRedo(state,action);
        case ActionKeys.REGISTER_STACKER_ACTIONS_UNDO:
            return updateStackerActionsUndo(state,action);
        case ActionKeys.STORE_STIES:
            return storeSites(state,action);
        default:
            return state;
    }
}

function storePbsForStacker(state : IAppState,action) {
    return Object.assign({}, state, {
        filteredStackerPlanningBlocks: FilterStackerPlanningBlocks(action.stackerPlanningBlocks,
            state.stackerQuickFilter),
        stackerPlanningBlocks : action.stackerPlanningBlocks
    });
}

function updateStackerColumnSelection(state: IAppState, action): IAppState {
    return Object.assign({}, state, { sessionStackerColumnSelection: action.columns });
}

function setSelectedStackerPbs(state: IAppState , action) : IAppState {
    return Object.assign({}, state, { selectedStackerPlanningBlocks: action.selectedStackerPlanningBlocks });
}

function quickFilterStackerPlanningBlocks(state : IAppState , action) : IAppState   
{
    let matches: any[] = []; 
    let pbs: any[] = [] ; 

    if(state.stackerPlanningBlocks) {
        pbs = state.stackerPlanningBlocks ;
    }
    const depotInSearchTextTrimmed = state.stackerTxtSearch.trim();
    if (depotInSearchTextTrimmed.length >= 1) {
        matches =
        getFilterStackerPbsByTxt(pbs, 
            depotInSearchTextTrimmed , state.sessionStackerColumnSelection 
        );
        return Object.assign({},
          state,
          {
            filteredStackerPlanningBlocks: [...matches],
            stackerQuickFilter: { ...action.stackerQuickFilter },
            stackerPlanningBlocks : state.stackerPlanningBlocks,
            stackerTxtSearch: action.stackerTxtSearch,
          });
      }

    matches = FilterStackerPlanningBlocks(pbs,
    action.stackerQuickFilter);
  
      return Object.assign({},
        state,
        {
          filteredStackerPlanningBlocks: [...matches],
          stackerQuickFilter: { ...action.stackerQuickFilter }
        });
}

function setQuickFilterValue(state : IAppState , action) : IAppState {
    return Object.assign({},
        state,
        {
            stackerQuickFilter: action.stackerQuickFilter
        });
}

function storeStackerLocations(state : IAppState , action) : IAppState {
    return Object.assign({},
        state,
        {
            stackerAddressLocations: action.stackerAddressLocations
        });
}


function textStackerPbs(state: IAppState, action): IAppState {
    if (action.stackerTxtSearch.trim().length >= 1) {
        const containersToFilter = state.filteredStackerPlanningBlocks
        const filtered = getFilterStackerPbsByTxt(containersToFilter, 
            action.stackerTxtSearch.trim() , state.sessionStackerColumnSelection 
        );

        return Object.assign({}, state, {
            stackerPlanningBlocks : state.stackerPlanningBlocks,
            filteredStackerPlanningBlocks: filtered,
            stackerTxtSearch: action.stackerTxtSearch,
        });
    }


    return Object.assign({}, state, {
        stackerPlanningBlocks : state.stackerPlanningBlocks,
        filteredStackerPlanningBlocks: state.filteredStackerPlanningBlocks,
        stackerTxtSearch: action.stackerTxtSearch,
    });

}


function setTxtFilterValue(state: IAppState, action): IAppState {
    return Object.assign({}, state, {
        stackerTxtSearch: action.stackerTxtSearch,
    });
}


function updateStackerDisplay(state: IAppState , action) : IAppState {
    let updatedPb = action.updatdItem; 
    let pbs = state.filteredStackerPlanningBlocks;
    let index = state.filteredStackerPlanningBlocks.findIndex((x) => {
        return x.containerNo == updatedPb.containerNo && x.containerPlanningType == updatedPb.containerPlanningType
    }); 

    if(index !== -1)
    {
        pbs[index] = updatedPb;
    } else {
        pbs.push(updatedPb);
    }

    if(state.stackerQuickFilter || state.stackerTxtSearch != "")
    {
        pbs = FilterStackerPlanningBlocks(pbs,
            state.stackerQuickFilter);
       
    }

    return Object.assign({} , state ,
        {
            filteredStackerPlanningBlocks: pbs,
        }
    )
}

function updateStackerActionsUndo(state: IAppState , action) : IAppState {
    return Object.assign({}, state, { stackerActionsUndo: action.undoActions });
}

function updateStackerActionsRedo(state: IAppState , action) : IAppState {
    return Object.assign({}, state, { stackerActionsRedo: action.redoActions });
}

function storeSites(state: IAppState , action) : IAppState {
    return Object.assign({} , state , {sites : action.sites});
}