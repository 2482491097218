import { Component, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'modal-dialog',
  templateUrl: './dialog.component.html'
})
export class DialogComponent {
  @Input() message: string;
  @Input() title: string;
  @Input() id: string;
  @Input() name: string;

  @Output() confirmClicked = new EventEmitter<void>();
  @Output() cancelClicked = new EventEmitter<void>();

  onConfirmClicked(): void {
    this.confirmClicked.emit();
  }

  onCancelClicked(): void {
    this.cancelClicked.emit();
  }
}