import { select } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Observable } from "rxjs/Rx";
import { environment } from "../../../../environments/environment";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { IconsFilter, QuickFilter } from "../../../depot-out/models/depot-out.interface";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { ComponentSource } from "../../models/component-source";


@Component({
    selector: 'depot-action-icons-filter',
    templateUrl: './action-icons-filter.component.html',
})

export class ActionIconsFilterComponent implements OnInit {
    @Output() public filterChanged = new EventEmitter<IconsFilter>();
    @Input() source: string;
    @select("applicationUser") public applicationUser$ : Observable<ApplicationUser>; 
    @select('depotInQuickFilter') public quickFilter$: Observable<QuickFilter>;

    public user : ApplicationUser;
    public containerAvailableIconStyle: string = "";
    public weighIconStyle: string = "";
    public preAnnouncement: any = {tooltip: "Pre-Announcement Not Required", style: "action-icon--inactive"};
    public truckAppointment: any = {tooltip: "Truck Appointment  Not Required", style: "action-icon--inactive"};
    public selectedCheck: any = {
      showScanning: true,
      showCustom: true,
      showWeighing: true,
      showghingDone: true,
      showTemperature:false,
      showHighValue:false,
      showADR: true,
      showT1: true,
      showTarTasCode: true,
      showContainerAvailable: environment.containerPoolEnabled,
      showPreAnnouncementStatus: environment.tarTasEnabled,
      showTruckAppointmentStatus: environment.tarTasEnabled,
      showContainerReleaseStatus: true
    };
    public containerReleaseStatusStyle: string = "action-icon--inactive";

      
    public quickFilterModel: IconsFilter ={
        important: false, hasADRAction: false, hasContainerAvailable: -1, hasCustomsAction: false, hasScanningAction: false, hasT1Action: false,
        hasTarTasCode: false, hasWeighingAction: false, weighingDone: 0, truckAppointmentStatus: 0, 
        preAnnouncementStatus: 0, temperature: 0, highValue: false, containerReleaseStatus: 0
    }
    constructor(private readonly depotInAction: DepotInActions, private readonly depotOutActions: DepotOutActions) {

    }
    ngOnInit(): void {
        this.applicationUser$.subscribe((data) => {
            if(data) {
              this.user = data ; 
              if(this.source == ComponentSource.depotIn)
              {
                data.depotInGridIcons.forEach((el) => {
                  if(!el)
                    return;
                  if(el.id == 1) {
                    this.selectedCheck.showScanning = el.isVisible;
                  } else  if(el.id == 2) {
                    this.selectedCheck.showCustom = el.isVisible;
                  } else  if(el.id == 3) {
                    this.selectedCheck.showWeighing = el.isVisible;
                  } else  if(el.id == 4) {
                    this.selectedCheck.showADR = el.isVisible;
                  } else  if(el.id == 5) {
                    this.selectedCheck.showT1 = el.isVisible;
                  } else  if(el.id == 6) {
                    this.selectedCheck.showContainerAvailable = el.isVisible && environment.containerPoolEnabled;
                  } else  if(el.id == 7) {
                    this.selectedCheck.showTarTasCode = el.isVisible;
                  }else  if(el.id == 8) {
                    this.selectedCheck.showPreAnnouncementStatus = el.isVisible && environment.tarTasEnabled;
                  } else  if(el.id == 9) {
                    this.selectedCheck.showTruckAppointmentStatus = el.isVisible && environment.tarTasEnabled;
                  } else  if(el.id == 10) {
                    this.selectedCheck.showTemperature = el.isVisible
                  } else  if(el.id == 11) {
                    this.selectedCheck.showHighValue = el.isVisible;
                  } else  if(el.id == 12) {
                    this.selectedCheck.showContainerReleaseStatus = el.isVisible && environment.containerReleaseEnabled;
                  }
                })
              }
      
              else if (this.source == ComponentSource.depotOut)
              {
                data.depotOutGridIcons.forEach((el) => {
                  if(!el)
                    return;
                  if(el.id == 1) {
                    this.selectedCheck.showScanning = el.isVisible;
                  } else  if(el.id == 2) {
                    this.selectedCheck.showCustom = el.isVisible;
                  } else  if(el.id == 3) {
                    this.selectedCheck.showWeighing = el.isVisible;
                  } else  if(el.id == 4) {
                    this.selectedCheck.showADR = el.isVisible;
                  } else  if(el.id == 5) {
                    this.selectedCheck.showT1 = el.isVisible;
                  } else  if(el.id == 6) {
                    this.selectedCheck.showContainerAvailable = el.isVisible && environment.containerPoolEnabled;
                  } else  if(el.id == 7) {
                    this.selectedCheck.showTarTasCode = el.isVisible;
                  }else  if(el.id == 8) {
                    this.selectedCheck.showPreAnnouncementStatus = el.isVisible && environment.tarTasEnabled;
                  } else  if(el.id == 9) {
                    this.selectedCheck.showTruckAppointmentStatus = el.isVisible && environment.tarTasEnabled;
                  } else  if(el.id == 10) {
                    this.selectedCheck.showTemperature = el.isVisible
                  } else  if(el.id == 11) {
                    this.selectedCheck.showHighValue = el.isVisible;
                  } else  if(el.id == 12) {
                    this.selectedCheck.showContainerReleaseStatus = el.isVisible && environment.containerReleaseEnabled;
                  }
                })
              }
              
            }
          })

        this.quickFilter$.subscribe((quickFilter: QuickFilter) => {
          if(quickFilter && quickFilter.iconsFilter)
          {
            var oldFilter = this.quickFilterModel;
            this.quickFilterModel = quickFilter.iconsFilter;
            this.resetFilter(oldFilter);
          }
        })
    }

    private resetFilter(oldFilter: IconsFilter) {
      if(oldFilter.truckAppointmentStatus != this.quickFilterModel.truckAppointmentStatus)
        this.truckAppointment = this.getStatusStyleAndTooltip(this.quickFilterModel.truckAppointmentStatus, "Truck Appointment");
      if(oldFilter.preAnnouncementStatus != this.quickFilterModel.preAnnouncementStatus)
        this.preAnnouncement = this.getStatusStyleAndTooltip(this.quickFilterModel.preAnnouncementStatus, "Pre-Announcement");
      if(oldFilter.hasContainerAvailable != this.quickFilterModel.hasContainerAvailable)
        this.setContainerStyle();
      if(oldFilter.hasWeighingAction != this.quickFilterModel.hasWeighingAction)
        this.setWeighingStyle();
    }

    public filterScanAction() {
        this.quickFilterModel.hasScanningAction = !this.quickFilterModel.hasScanningAction;
        this.applyFilter(this.quickFilterModel); 
    }
    public filterCustomAction() {
        this.quickFilterModel.hasCustomsAction = !this.quickFilterModel.hasCustomsAction;
        this.applyFilter(this.quickFilterModel); 
    }
    public filterWeighing() {
        this.quickFilterModel.hasWeighingAction = !this.quickFilterModel.hasWeighingAction;
        this.setWeighingStyle();
        this.applyFilter(this.quickFilterModel); 
    }

  setWeighingStyle() {
    this.weighIconStyle = this.quickFilterModel.hasWeighingAction
            ? "action-icon--danger"
            : "action-icon--inactive";
  }
  public importantStateChanged(){
    this.quickFilterModel.important = !this.quickFilterModel.important;
    this.applyFilter(this.quickFilterModel); 
  }
    public filterADRAction() {
        this.quickFilterModel.hasADRAction = !this.quickFilterModel.hasADRAction;
        this.applyFilter(this.quickFilterModel); 
    }
    public filterT1Action() {
        this.quickFilterModel.hasT1Action = !this.quickFilterModel.hasT1Action;
        this.applyFilter(this.quickFilterModel); 
    }
    public filterTarTasCode() {
        this.quickFilterModel.hasTarTasCode = !this.quickFilterModel.hasTarTasCode;
        this.applyFilter(this.quickFilterModel); 
    }
    public filterContainerAvailable() {
        if (this.quickFilterModel.hasContainerAvailable == -1) {
            this.quickFilterModel.hasContainerAvailable = 0
        } else if (this.quickFilterModel.hasContainerAvailable == 0) {
            this.quickFilterModel.hasContainerAvailable = 3
        } else {
            this.quickFilterModel.hasContainerAvailable = -1
        }

        this.setContainerStyle();

        this.applyFilter(this.quickFilterModel); 
    }

  setContainerStyle() {
    this.containerAvailableIconStyle = this.quickFilterModel.hasContainerAvailable === 0
            ? "action-icon--active"
            : (this.quickFilterModel.hasContainerAvailable === 3
                ? "action-icon--danger"
                : "action-icon--inactive");
  }

    public applyFilter(filter: IconsFilter) {
        if (this.source == ComponentSource.depotIn) {
            this.filterChanged.emit(filter);
        } else if (this.source == ComponentSource.depotOut) {
            this.filterChanged.emit(filter);
        }
    }
    
    filterPreAnnouncement(){
      this.quickFilterModel.preAnnouncementStatus = (this.quickFilterModel.preAnnouncementStatus + 1) % 6;
      this.preAnnouncement = this.getStatusStyleAndTooltip(this.quickFilterModel.preAnnouncementStatus, "Pre-Announcement");
      this.applyFilter(this.quickFilterModel); 
    }
  
    getStatusStyleAndTooltip(status: number, statusType: string): any {
      switch(status){
        case 1: 
          return {style: "action-icon--warning", tooltip: statusType + " Not Sent"};
        case 5:
          return {style: "action-icon--warning", tooltip: statusType + " Cancelled"};
        case 2:
          return {style: "action-icon--sent", tooltip: statusType + " Sent"};
        case 4:
          return {style: "action-icon--danger", tooltip: statusType + " Error"};
        case 3:
          return {style: "action-icon--success", tooltip: statusType + " OK"};
        case 0: 
        default:
          return {style: "action-icon--inactive", tooltip: statusType + " Not Required"};
      }
    }

    filterTruckAppointment() {
      this.quickFilterModel.truckAppointmentStatus = (this.quickFilterModel.truckAppointmentStatus + 1) % 6;
      this.truckAppointment = this.getStatusStyleAndTooltip(this.quickFilterModel.truckAppointmentStatus, "Truck Appointment");
      this.applyFilter(this.quickFilterModel);
    }

    filterTemperature(){
      if(!this.quickFilterModel.temperature)
        this.quickFilterModel.temperature = 0;
      this.quickFilterModel.temperature=(this.quickFilterModel.temperature+1)%2;
      this.applyFilter(this.quickFilterModel);
    }

    filterHighValue(){
      this.quickFilterModel.highValue=!this.quickFilterModel.highValue;
      this.applyFilter(this.quickFilterModel);
    }

    filterContainerReleaseStatus(){
      if(!this.quickFilterModel.containerReleaseStatus)
        this.quickFilterModel.containerReleaseStatus = 0;
      this.quickFilterModel.containerReleaseStatus = (this.quickFilterModel.containerReleaseStatus + 1) % 4;
      this.setContainerReleaseStatusStyle();
      this.applyFilter(this.quickFilterModel);
    }
  
    setContainerReleaseStatusStyle() {
      if(this.quickFilterModel.containerReleaseStatus == 0)
        this.containerReleaseStatusStyle = "action-icon--inactive";
      else if(this.quickFilterModel.containerReleaseStatus == 1)
        this.containerReleaseStatusStyle = "action-icon--warning";
      else if(this.quickFilterModel.containerReleaseStatus == 2)
        this.containerReleaseStatusStyle = "action-icon--danger";
      else if(this.quickFilterModel.containerReleaseStatus == 3)
        this.containerReleaseStatusStyle = "action-icon--active";
    }


}