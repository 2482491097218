import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";

@Injectable()
export class TokenInterceptor implements HttpInterceptor{

    constructor() {}

    intercept(request : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
        let basicToken = localStorage.getItem('basicToken');
              let UserId = localStorage.getItem('userId'); 
              let UserName = localStorage.getItem('userName'); 
           
        if(basicToken){
            let token = 'Basic ' + basicToken ;  
            const headers = new HttpHeaders({
                'Authorization': token,
                "X-UserId" : UserId?? "" , 
               "X-UserName" : UserName?? "" 
              });
            
            request = request.clone({
               headers
            });
        }

        return next.handle(request);
    }
}