
import {of as observableOf,  Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { Http } from '@angular/http';


export class AuthorizedUser {
    public fullName: string;
    public username: string;
}

// tslint:disable-next-line:max-classes-per-file
@Injectable()
export class HomeService {
    constructor(
        private readonly http: Http
    ) { }

    public getAuthorizedUser(): Observable<AuthorizedUser> {

        const mockUser: AuthorizedUser = {
            fullName: 'test',
            username: 'ùmock'
        };
        return observableOf(mockUser);

        // DEBUG: disabled for now
        // return this.http
        //   .get(this.window.location.origin + '/user')
        //   .map((response:any) => res);
    }
}
