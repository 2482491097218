import { OnInit, Component, Input, Output, ViewChild, TemplateRef} from "@angular/core";
import { ComponentSource, ModalType } from "../../models/component-source";
import { ModalManagerService } from "../../services/modal-manager.service";
import { SharedActions } from "../../shared.actions";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { PlanningBlock } from "../../../shared/models/planning-block/planning-block.interface";
import { PoolDriver } from "../../../pool/models/pool-driver.interface";
import { PoolTruck } from "../../../pool/models/pool-truck.interface";
import { PoolHaulier } from "../../../pool/models/pool-haulier.interface";
import { PoolActions } from "../../../pool/pool.actions";
import { SharedService } from "../../services/shared.service";
import { PoolService } from "../../../pool/pool.service";

import { TranslateService } from "@ngx-translate/core";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

@Component({
    selector: "planningblock-reservation-modal",
    templateUrl: "./planningblock-reservation-modal.component.html",
})

export class PlanningBlockReservationComponent implements OnInit {
    @ViewChild("dateTemplate") public dateTemplate: TemplateRef<any>;
    @Input()
    public source: string;
    public selectedFileNumber: string = null;
    public pbsInFile: PlanningBlock[] = [];
    
    @select("depotInSelection") private depotInSelection$: Observable<PlanningBlock[]>;
    @select("uiState") public uiState$: Observable<boolean[]>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    
    public selectedDepotInPropertyNames$: Observable<string[]>;
    public columns = [];
    public uiState: boolean[];
    public modalType = ModalType;
    public selectedPlanningBlocks: PlanningBlock[] = [];
    public componentSource = ComponentSource;
    public planningBlocks: PlanningBlock[] = [];
    public planningBlocksCount: number = 0;

    ngOnInit(): void {

        this.uiState$.subscribe((uiState: boolean[]) => {
            this.uiState = uiState;
        });
                
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
            this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
        });

        this.depotInSelection$.subscribe((depotInSelection: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotIn) {
                this.selectedPlanningBlocks = depotInSelection;
            }

            if (this.selectedPlanningBlocks.length > 0) {
                console.log("DEPOT IN SELECTION 1 : " + this.selectedPlanningBlocks[0].fileId);
                this.selectedFileNumber = this.selectedPlanningBlocks[0].fileId;
                this.sharedService.getPlanningBlocksByFileNumber(this.selectedFileNumber.toString()).subscribe(
                    (result: PlanningBlock[]) => {
                        this.planningBlocks = result;
                        console.log(this.planningBlocks);
                        this.planningBlocksCount = this.planningBlocks.length;
                    }, (error) => {
                        console.log(error);
                    });
                
            }
            
        });
        this.columns = ["Truck", "Driver", "Haulier", "Container Type", "Pb Status"];
    }

    constructor(private readonly modalManager: ModalManagerService,
        private readonly sharedActions: SharedActions,
        private readonly poolActions: PoolActions,
        private readonly sharedService: SharedService,
        private readonly poolService: PoolService,
        private readonly translate: TranslateService) {}

    public closeModal(): void {
        this.modalManager.closeModal(this.modalType.reservation);
        this.sharedActions.setUiState(true);
    }
}