import { DatePipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";
@Pipe({
    name: 'customDate'
})
export class CustomDatePipe extends DatePipe implements PipeTransform {
    transform(value: string, pattern?: string, separator: string = ', '): string {
        let date = '';
        let DateTimeFormat = localStorage.getItem('defaultDateFormat').replace('"' , "" ).replace('"' , "" ) ; 
        if (!value || value.indexOf('0001-01-01T00:00:00Z') !== -1  || value.indexOf('1753-01-01T00:00:00Z') !== -1)  {
            return date;
        }
        else {
            let dateToBeValidated = Date.parse(value);
            if (!isNaN(dateToBeValidated))
            {
                return super.transform(new Date(dateToBeValidated), DateTimeFormat +" "+ "H:mm").replace("24:" ,"00:");
            } else {
                value = value.replace(" undefined" , '') ;
                let formattedDates = [];
                let array = value.split(separator);
                array.forEach(date => {
                    let date2 = new Date(date);
                    DateTimeFormat = DateTimeFormat.replace('"' , "" ) ;
                    formattedDates.push(super.transform(date2 , DateTimeFormat +" "+ "H:mm").replace("24:" ,"00:"));
                });
                
                return formattedDates.join(separator);
            }
        }
    }

}
