import { Injectable } from "@angular/core";

@Injectable()
export class ModalManagerService {
    public openModal(modalId: string){
        const body: HTMLElement = document.getElementById("appBody") as HTMLElement ; 
        body.classList.add("modal-open"); 

        const modal: HTMLElement = document.getElementById(modalId) as HTMLElement; 
        modal.classList.remove("fadeOut"); 
        modal.classList.add("fadeInDown"); 
        modal.style.display = "block"; 
    }

    public closeModal(modalId: string){
        const body: HTMLElement = document.getElementById("appBody") as HTMLElement ; 
        body.classList.remove("modal-open"); 
        const modal: HTMLElement = document.getElementById(modalId) as HTMLElement; 
        modal.classList.remove("fadeInDown"); 
        modal.classList.add("fadeOut"); 
        setTimeout(() => { modal.style.display = "none" ; }, 500); 
    }

    public openReuseModalAfterPlan(modalId: string){
        const reuseModalLabel: HTMLElement = document.getElementById("reuseModalLabel") as HTMLElement ; 
        reuseModalLabel.style.display = "inherit" ; 
        this.openModal(modalId);
    }
}