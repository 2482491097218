import { filterPlanningBlocks } from "../depot-in/filters/depot-in.quickfilter";
import { ActionKeys } from "../pb-screen/action.keys";
import { advancedFilterPlanningBlocks } from "../pb-screen/filters/advanced.filter.comparison";
import { ContainerStatusId } from "../pb-screen/models/planning-block/planning-block-container-status.interface";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { IdNameAsStringObject } from "../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { getFilteredPlanningBlocksBySearchText } from "../shared/filter.by.text";
import { TO_BE_PLANNED } from "../shared/models/status-type.interface";
import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';

/* NOTE: When adding a case be sure you add it to the fallthrough in the reducer.ts file */
export function depotInReducer(state: IAppState = InitialState.initialState, action) {
  switch (action.type) {
    case ActionKeys.UPDATE_DEPOT_IN_PLANNED_PLANNING_BLOCKS:
      return updateDepotInPlannedPlanningBlocks(state, action);
    case ActionKeys.FILTER_DEPOT_IN_PLANNINGBLOCKS:
      return filterDepotInPlanningBlocks(state, action);
    case ActionKeys.REQUEST_DEPOT_IN_PLANNINGBLOCKS_SUCCESS:
      return storeDepotInPlanningBlocks(state, action);
    case ActionKeys.QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS:
      return quickFilterDepotInPlanningBlocks(state, action);
    case ActionKeys.RESET_QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS:
      return resetQuickFilterDepotInPlanningBlocks(state, action);
    case ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS:
      return advancedFilterDepotInPlanningBlocks(state, action);
    case ActionKeys.RESET_ADVANCED_FILTER_DEPOT_IN_PLANNINGBLOCKS:
      return resetAdvancedFilterDepotInPlanningBlocks(state, action);
    case ActionKeys.UPDATE_DEPOT_IN_COLUMN_SELECTION:
      return updateDepotInColumnSelection(state, action);
    case ActionKeys.SET_DEPOT_IN_SELECTION:
      return setDepotInSelection(state, action);
    case ActionKeys.SET_DEPOT_IN_CANDIDATES:
      return setDepotInCandidates(state, action);
    case ActionKeys.RESET_DEPOT_IN_CANDIDATES:
      return resetDepotInCandidates(state, action);
    case ActionKeys.SET_DEPOT_IN_OVERBOOKING_CANDIDATES:
      return setDepotInOverbookingCandidates(state, action)
    case ActionKeys.RESET_DEPOT_IN_PLANNINGBLOCKS:
      return resetDepotInPlanningBlocks(state, action);
    case ActionKeys.SET_DEPOT_IN_PROCESSED_DISPLAY_STATE:
      return setDepotInProcessedDisplayState(state, action);
    case ActionKeys.SET_DEPOT_IN_IMPORTANT_ONLY_DISPLAY_STATE:
      return setDepotInImportantOnlyDisplayState(state, action);
    default:
      return state;
  }
}

function setDepotInSelection(state: IAppState, action): IAppState {
  if(!action.depotInSelection)
    action.depotInSelection = [];
  if(!state.depotOutSelection)
    state.depotOutSelection = [];
  let toPlan = action.depotInSelection.concat(state.depotOutSelection).filter(pb => pb.status.statusType == 12);
  return Object.assign({},
    state,
    {
      selectedPbsToPlan: toPlan,
      depotInSelection: action.depotInSelection,
      selectedPbsFromPlanBoard: [],
      selectionType: "planningBlock"
    });
}

// tslint:disable-next-line:typedef
function advancedFilterDepotInPlanningBlocks(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let noText: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotInFilteredOnCandidates) {
    planningBlocks = state.depotInCandidates;
  } else {
    planningBlocks = state.depotInPlanningBlocks;
  }

  matches = advancedFilterPlanningBlocks(planningBlocks, action.filterItems);

  const depotInSearchTextTrimmed = state.depotInSearchText.trim();
  if (depotInSearchTextTrimmed.length > 0) {
    noText = matches;
    matches = getFilteredPlanningBlocksBySearchText(matches,
      depotInSearchTextTrimmed,
      state.sessionDepotInColumnSelection);
  } else {
    noText = matches;
  }

  return Object.assign({},
    state,
    {
      depotInQuickFilteredPlanningBlocksNoText: noText,
      sessionDepotInAdvancedFilters: action.filterItems,
      depotInFilteredPlanningBlocks: matches,
      depotInQuickFilteredPlanningBlocks: matches,
      depotInQuickFilter: null
    });
}

function resetAdvancedFilterDepotInPlanningBlocks(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotInFilteredOnCandidates) {
    planningBlocks = state.depotInCandidates;
  } else {
    planningBlocks = filterProcessedPlanningBlocks(state.depotInPlanningBlocks);
  }

  const depotInSearchTextTrimmed = state.depotInSearchText.trim();

  if (depotInSearchTextTrimmed.length > 0) {
    matches = getFilteredPlanningBlocksBySearchText(planningBlocks,
      depotInSearchTextTrimmed,
      state.sessionDepotInColumnSelection);
  } else {
    matches = planningBlocks;
  }

  return Object.assign({},
    state,
    {
      sessionDepotInAdvancedFilters: null,
      depotInFilteredPlanningBlocks: matches,
      depotInQuickFilteredPlanningBlocks: matches,
      depotInQuickFilteredPlanningBlocksNoText: planningBlocks,
      depotInQuickFilter: null
    });
}

function updateDepotInPlannedPlanningBlocks(state: IAppState, action): IAppState {
  const planningBlocks: PlanningBlock[] = state.depotInPlanningBlocks;
  const returnedPlanningBlocks: PlanningBlock[] = action.planningBlocks;
  const depotInSelection = state.depotInSelection;

  returnedPlanningBlocks.map((returnedPlanningBlock: PlanningBlock) => {
    let index = planningBlocks.findIndex((d) => d.id === returnedPlanningBlock.id);
    if(index != -1)
      planningBlocks[index] = returnedPlanningBlock;
    index = depotInSelection.findIndex((d) => d.id === returnedPlanningBlock.id);
    if(index != -1)
      depotInSelection[index] = returnedPlanningBlock;
  });

  let filteredPlanningBlocks = filterPlanningBlocks(planningBlocks,
    state.depotInQuickFilter,
    "",
    state.sessionDepotInColumnSelection);

  return Object.assign({},
    state,
    {
      depotInFilteredPlanningBlocks: [...filteredPlanningBlocks],
      depotInPlanningBlocks: [...planningBlocks],
      depotInSelection: [...depotInSelection]
    });
}

function updateDepotInColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionDepotInColumnSelection: action.columns });
}

function filterDepotInPlanningBlocks(state: IAppState, action): IAppState {
  if (action.searchText.trim().length >= 1) {
    const pbsToFilter = state.depotInQuickFilteredPlanningBlocksNoText.length > 0 ?
      state.depotInQuickFilteredPlanningBlocksNoText : state.depotInFilteredPlanningBlocks;
    const filtered = getFilteredPlanningBlocksBySearchText(pbsToFilter,
      action.searchText.trim(),
      state.sessionDepotInColumnSelection,
    );

    return Object.assign({}, state, {
      depotInQuickFilteredPlanningBlocksNoText: pbsToFilter,
      depotInFilteredPlanningBlocks: filtered,
      depotInSearchText: action.searchText,
    });
  }
  
  const pbs = state.depotInQuickFilteredPlanningBlocksNoText.length > 0 ?
    state.depotInQuickFilteredPlanningBlocksNoText : state.depotInFilteredPlanningBlocks;
  return Object.assign({}, state, {
    depotInFilteredPlanningBlocks: pbs,
    depotInSearchText: action.searchText,
  });
}

function storeDepotInPlanningBlocks(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      depotInPlanningBlocks: action.planningBlocks,
      depotInQuickFilteredPlanningBlocks: action.planningBlocks,
      depotInFilteredPlanningBlocks: depotInDefaultQuickFilter(state,action.planningBlocks),
    });
}

function depotInDefaultQuickFilter(state : IAppState , planningBlocks: PlanningBlock[]) : PlanningBlock[] 
{
  return filterPlanningBlocks(planningBlocks,state.depotInQuickFilter,"",state.sessionDepotInColumnSelection) ; 
}

function filterProcessedPlanningBlocks(planningBlocks: PlanningBlock[]): PlanningBlock[] {
  return planningBlocks;
  //return planningBlocks.filter((planningBlock: PlanningBlock) => !planningBlock.processed);
}

function quickFilterDepotInPlanningBlocks(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotInFilteredOnCandidates) {
    planningBlocks = state.depotInCandidates;
  } else {
    planningBlocks = state.depotInPlanningBlocks;
  }

  if (!action.quickFilter.customer &&
    !action.quickFilter.firstAction &&
    !action.quickFilter.lastAction &&
    !action.quickFilter.loadingPlace &&
    !action.quickFilter.unLoadingPlace &&
    !action.quickFilter.closing &&
    !action.quickFilter.pbFirstCollectionFrom &&
    !action.quickFilter.containerType &&
    !action.quickFilter.latestLocationCode &&
    !action.quickFilter.carrier &&
    !action.quickFilter.planningState.toBePlanned &&
    !action.quickFilter.planningState.reused &&
    !action.quickFilter.planningState.inProgress &&
    !action.quickFilter.planningState.planned &&
    !action.quickFilter.planningState.prePlanned &&
    !action.quickFilter.planningState.onHold &&
    !action.quickFilter.containerStatus.leegCrea &&
    !action.quickFilter.containerStatus.leegAf &&
    !action.quickFilter.containerStatus.geladen &&
    !action.quickFilter.containerStatus.volKlaar &&
    !action.quickFilter.containerStatus.volIn &&
    !action.quickFilter.containerStatus.volCrea &&
    !action.quickFilter.containerStatus.volAf &&
    !action.quickFilter.containerStatus.gelost &&
    !action.quickFilter.containerStatus.leegKlaar &&
    !action.quickFilter.containerStatus.leegIn &&
    !action.quickFilter.iconsFilter &&
    action.quickFilter.planningGroups.length === 0) {

    const depotInSearchTextTrimmed = state.depotInSearchText.trim();


    if (depotInSearchTextTrimmed.length >= 1) {
      matches =
        getFilteredPlanningBlocksBySearchText(planningBlocks,
          depotInSearchTextTrimmed,
          state.sessionDepotInColumnSelection);
      return Object.assign({},
        state,
        {
          depotInQuickFilteredPlanningBlocksNoText: planningBlocks,
          depotInFilteredPlanningBlocks: matches,
          depotInQuickFilteredPlanningBlocks: matches,
          depotInQuickFilter: null,
          sessionDepotInAdvancedFilters: null,
        });
    }

    return Object.assign({},
      state,
      {
        depotInQuickFilteredPlanningBlocksNoText: planningBlocks,
        depotInFilteredPlanningBlocks: planningBlocks,
        depotInQuickFilteredPlanningBlocks: planningBlocks,
        sessionDepotInAdvancedFilters: null,
      });
  }

  matches = filterPlanningBlocks(planningBlocks,
    action.quickFilter,
    "",
    state.sessionDepotInColumnSelection);

  const depotInSearchTextTrimmed = state.depotInSearchText.trim();

  if (depotInSearchTextTrimmed.length >= 1) {
    const noTextMatches: PlanningBlock[] = matches;
    matches = getFilteredPlanningBlocksBySearchText(matches,
      depotInSearchTextTrimmed,
      state.sessionDepotInColumnSelection);
    return Object.assign({},
      state,
      {
        depotInQuickFilteredPlanningBlocksNoText: noTextMatches,
        depotInFilteredPlanningBlocks: matches,
        depotInQuickFilteredPlanningBlocks: matches,
        depotInQuickFilter: { ...action.quickFilter },
        sessionDepotInAdvancedFilters: null,
      });
  }

  return Object.assign({},
    state,
    {
      depotInQuickFilteredPlanningBlocksNoText: matches,
      depotInFilteredPlanningBlocks: matches,
      depotInQuickFilteredPlanningBlocks: matches,
      depotInQuickFilter: { ...action.quickFilter },
      sessionDepotInAdvancedFilters: null,
    });
}

function resetQuickFilterDepotInPlanningBlocks(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      depotInQuickFilter: null,
      sessionDepotInAdvancedFilters: null,
    });
}

function setDepotInCandidates(state: IAppState, action): IAppState {
  const matches: PlanningBlock[] = [];
  const depotOutAddress: string = state.depotOutSelection[0].firstPlanAddressSearchName;
  // tslint:disable-next-line:prefer-const
  for (let planningBlock of state.depotInPlanningBlocks) {
    if (planningBlock.lastPlanAddressSearchName === depotOutAddress && planningBlock.status.statusType === TO_BE_PLANNED) {
      matches.push(planningBlock);
    }
  }
  return Object.assign({},
    state,
    {
      sessionDepotInAdvancedFilters: null,
      depotInQuickFilteredPlanningBlocks: matches,
      depotInQuickFilteredPlanningBlocksNoText: matches,
      depotInCandidates: matches,
      depotInFilteredPlanningBlocks: matches,
      depotInFilteredOnCandidates: true,
      depotInQuickFilter: null,
    });
}

function resetDepotInCandidates(state: IAppState, action): IAppState {
  const planningBlocks = filterProcessedPlanningBlocks(state.depotInPlanningBlocks);

  return Object.assign({},
    state,
    {
      sessionDepotInAdvancedFilters: null,
      depotInQuickFilteredPlanningBlocks: planningBlocks,
      depotInQuickFilteredPlanningBlocksNoText: planningBlocks,
      depotInFilteredPlanningBlocks: planningBlocks,
      depotInFilteredOnCandidates: false,
      depotInQuickFilter: null,
      depotInSearchText: "",
    });
}

function setDepotInOverbookingCandidates(state: IAppState, action): IAppState {
  const depotOutPlanningBlock = state.depotOutSelection[0];
  const matches: PlanningBlock[] = getDepotInCandidates(depotOutPlanningBlock, state.depotInPlanningBlocks, state.containerStatuses);

  return Object.assign({}, state, {
    depotInFilteredOnCandidates: true,
    depotInCandidates: matches,
    depotInFilteredPlanningBlocks: matches,
    depotInQuickFilter: null,
    depotInQuickFilteredPlanningBlocks: matches,
    depotInQuickFilteredPlanningBlocksNoText: matches,
    sessionDepotInAdvancedFilters: null,
  });
}

export function getDepotInCandidates(depotOutPlanningBlock: PlanningBlock, depotInPlanningBlocks: PlanningBlock[], containerStatuses: IdNameAsStringObject[]):PlanningBlock[]{
  const matches: PlanningBlock[] = [];
  // tslint:disable-next-line:prefer-const
  for (let planningBlock of depotInPlanningBlocks) {
    let checkContainerTrailer = (depotOutPlanningBlock.containerOrTrailerType && depotOutPlanningBlock.containerOrTrailerType === planningBlock.containerOrTrailerType)
    || (depotOutPlanningBlock.containerType && depotOutPlanningBlock.containerType === planningBlock.containerType)
    || (depotOutPlanningBlock.trailerType && depotOutPlanningBlock.trailerType === planningBlock.trailerType);
    if (
      checkContainerTrailer &&
      depotOutPlanningBlock.orderNumber !== planningBlock.orderNumber &&
      depotOutPlanningBlock.carrierId === planningBlock.carrierId &&
      isContainerStatusValidForDepotOutAndDepotInContainerReuseSelection(
        depotOutPlanningBlock,
        planningBlock,
        containerStatuses
      )
      && planningBlock.status.statusType === TO_BE_PLANNED
    ) {
      matches.push(planningBlock);
    }
  }
  return matches;
}

function isContainerStatusValidForDepotOutAndDepotInContainerReuseSelection(
  depotOutPB: PlanningBlock,
  depotInPB: PlanningBlock,
  containerStatuses: IdNameAsStringObject[]
): boolean {
  let depotOutPBStatusId = containerStatuses.find(el => el.name == depotOutPB.containerStatus)?.id;
  let depotInPBStatusId = containerStatuses.find(el => el.name == depotInPB.containerStatus)?.id;
  
  return (
    ((depotOutPBStatusId === ContainerStatusId.volCrea ||
      depotOutPBStatusId === ContainerStatusId.volAf ||
      depotOutPBStatusId === ContainerStatusId.leegKlaar) &&
      depotInPBStatusId === ContainerStatusId.leegCrea) ||
    ((depotInPBStatusId === ContainerStatusId.volCrea ||
      depotInPBStatusId === ContainerStatusId.volAf ||
      depotInPBStatusId === ContainerStatusId.leegKlaar) &&
      depotOutPBStatusId === ContainerStatusId.leegCrea)
  );
}

function resetDepotInPlanningBlocks(state: IAppState, action): IAppState {
  let planningBlocks: PlanningBlock[] = [];

  if (state.depotInFilteredOnCandidates) {
    planningBlocks = state.depotInCandidates;
  } else {
    planningBlocks = state.depotInPlanningBlocks;
  }

  if (state.depotInQuickFilteredPlanningBlocksNoText && state.depotInQuickFilteredPlanningBlocksNoText.length > 0) {
    return Object.assign({}, state, {
      depotInSearchText: "",
      depotInFilteredPlanningBlocks: state.depotInQuickFilteredPlanningBlocksNoText,
      depotInQuickFilteredPlanningBlocks: state.depotInQuickFilteredPlanningBlocksNoText,
    });
  }
  return Object.assign({}, state, {
    depotInSearchText: "",
    depotInFilteredPlanningBlocks: planningBlocks,
    depotInQuickFilteredPlanningBlocks: planningBlocks,
  });
}

function setDepotInProcessedDisplayState(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    depotInProcessedDisplayState: action.depotInProcessedDisplayState
  });
}

function setDepotInImportantOnlyDisplayState(state: IAppState, action): IAppState{
  return Object.assign({}, state, {
    depotInImportantOnlyDisplayState: action.depotInImportantOnlyDisplayState
  });
}
