import { NgRedux, select } from "@angular-redux/store";
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, OnDestroy } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { CapacitySlot } from "../../../models/capacity-slot.interface";
import { SharedActions } from "../../../../shared/shared.actions";
import { IAppState } from "../../../../store/IAppState";
import { HttpClientService } from "../../../../shared/services/httpclient";
import { TransportOrder } from "../../../models/transport-order/transport-order.interface";
import { Haulier } from "../../../models/haulier.interface";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { IdNameAsStringObject } from "../../../models/quick-view/id-name-as-string-object.interface";
import { takeUntil } from "rxjs/operators";
import { ApplicationUser } from "../../../models/application.user.interface";
import { environment } from "../../../../../environments/environment";

@Component({
  moduleId: module.id,
  selector: "capacity-selector",
  templateUrl: "./capacity-selector.component.html",
})
export class CapacitySelectorComponent implements OnInit, OnChanges, OnDestroy {

  @Output() public capacitySelection: EventEmitter<CapacitySlot> = new EventEmitter();
  @Output() public haulierSelection: EventEmitter<Haulier> = new EventEmitter();
  @Output() public planDateSelection: EventEmitter<string> = new EventEmitter();
  @Output() public finalizeCombiSelection: EventEmitter<boolean> = new EventEmitter();
  @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
  @select("capacitySlots") public capacitySlots$: Observable<CapacitySlot[]>;
  @select("hauliers") public hauliers$: Observable<Haulier[]>;
  @select("planningGroups") public planningGroups$: Observable<IdNameAsStringObject[]>;
  @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
  @select('planningZone') public planningZone$: Observable<any[]>;
  @select("selectedCapacityFromPlanBoard") public selectedCapacityFromPlanBoard$ : Observable<CapacitySlot>;
  @Input() public selectedCapacity: CapacitySlot;
  @Input() public selectedHaulier: Haulier;
  @Input() public selectedTransportOrder: TransportOrder = undefined;
  @Input() public hasError: boolean;
  @Input() public selectedFinalizeCombi: boolean;
  @Input() public filterOnZone: boolean = false;
  destroy$ = new Subject();

  public capacitySlots: CapacitySlot[] = [];
  public filteredCapacitySlots: CapacitySlot[] = [];
  public planningHauliers: Haulier[] = [];
  public filteredPlanningHauliers: Haulier[] = [];
  public planningGroups : IdNameAsStringObject[] = [];
  public planningZones: string[] = [];
  public selectedPlanningZone: string;
  public planDate: Date;
  public apiFormattedDate: string;
  public emptyHaulier: Haulier;
  public disabledCapacityInput: boolean = false;
  public disabledHaulierInput: boolean = false;
  public disabledDateInput: boolean = false;
  public emptyCapacity: CapacitySlot;
  public hasMultipleZones: boolean = false;
  public enableAutocomplete: boolean = false;
  public selectedPlanningBlocks: PlanningBlock[] = [];
  public selectedTransportOrderNo: string = "";

  constructor(
    public readonly sharedActions: SharedActions,
    public readonly ngRedux: NgRedux<IAppState>,
    public readonly httpClientSerive: HttpClientService) {
  }

  public ngOnInit() {

    this.setInitialPlanDate();
    this.planDateSelection.emit(this.planDate.toDateString());

    this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      this.selectedPlanningBlocks = selectedPlanningBlocks;
      this.selectedPlanningZone = this.selectedPlanningBlocks[0].firstAddressPlanningZone ?? this.selectedPlanningBlocks[0].firstPlanningAddressPlanningZone;
      this.filterHauliersOnSelectedPlanningGroup();
      this.filterCapacitySlotsOnSelectedPlanningGroupAndZone();
    });

    this.httpClientSerive.getToken().subscribe(()=> {
      if(this.validateAndFormatDateForApi()){
        this.sharedActions.getCapacitySlotsLight(this.apiFormattedDate);
        this.filterCapaciySlotsOnHaulier();
      }

      this.sharedActions.getHauliers();

      this.capacitySlots$.pipe(takeUntil(this.destroy$)).subscribe((capacitySlots: CapacitySlot[]) => {
        this.capacitySlots = capacitySlots;
        this.filteredCapacitySlots = capacitySlots;
        this.filterCapacitySlotsOnSelectedPlanningGroupAndZone();
      });

      this.hauliers$.pipe(takeUntil(this.destroy$)).subscribe((hauliers: Haulier[]) => {
        this.planningHauliers = hauliers;
        this.filterHauliersOnSelectedPlanningGroup();
      });

      this.planningGroups$.pipe(takeUntil(this.destroy$)).subscribe((planningGroups: IdNameAsStringObject[]) => {
        this.planningGroups = planningGroups;
        this.filterHauliersOnSelectedPlanningGroup();
        this.filterCapacitySlotsOnSelectedPlanningGroupAndZone();
      });

      this.planningZone$.pipe(takeUntil(this.destroy$)).subscribe((planningZones: any) => {
          if (planningZones) {
              this.planningZones = planningZones.map(el => el.id);
              this.hasMultipleZones = this.planningZones.length > 1;
          }
      });

      this.applicationUser$.subscribe((user) => {
        if (user !== null) {
            this.enableAutocomplete = user.generalSetting.enableAutocomplete;
            //let zone = user?.defaultPlanZone ?? environment.defaultPlanningZone;
            //this.selectedPlanningZone = zone;
        }
      })

      this.selectedCapacityFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if(data) {
          this.selectedCapacity = data ; 
          this.updateCapacitySelection(data);
        }
      })
    })
  }

  public ngOnChanges(simpleChange: SimpleChanges): void {
    if (simpleChange && simpleChange.selectedTransportOrder) {
      this.selectedTransportOrder = simpleChange.selectedTransportOrder.currentValue;
      if (this.selectedTransportOrder) this.selectedTransportOrderNo = this.selectedTransportOrder.id;
      else this.selectedTransportOrderNo = null;
    }

    if(simpleChange && simpleChange.selectedCapacity) {
      this.setSelectedHaulierFromSelectedCapacity();
    }

    if(simpleChange && simpleChange.selectedHaulier){
      this.selectedHaulier = simpleChange.selectedHaulier.currentValue;
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public updatePlanDate() {
    // the current selection needs to be reset when a new list of capacities is fetched
    // had to force undefined,to fix the null value bug
    this.selectedCapacity = undefined;
    this.enableCapacityInput();
    this.capacitySlots = [];
    this.updateCapacitySlots();
    this.planDateSelection.emit(this.planDate.toDateString());
    this.capacitySelection.emit(this.selectedCapacity);
  }

  public updatePlanZone()
  {
    this.filterCapacitySlotsOnSelectedPlanningGroupAndZone();
    this.filterHauliersOnSelectedPlanningGroup();
  }

  public updateCapacitySelection(data: CapacitySlot) {
    if (data) {
      this.disableHaulierInput();
      this.capacitySelection.emit(this.selectedCapacity);
    }
    else{
      this.capacitySelection.emit(null);
      this.filterCapaciySlotsOnHaulier();
      this.enableCapacityInput();
    }
  }

  public setSelectedFinalizeCombi(data : boolean) 
  {
    if(data) {
      this.finalizeCombiSelection.emit(this.selectedFinalizeCombi);
    } else {
      this.finalizeCombiSelection.emit(false);
    }
  }

  public updateHaulierSelection(data: Haulier) {
 
      if (data) {
        this.haulierSelection.emit(this.selectedHaulier);
      }
      else {
        this.resetHaulierInput(false);
        this.haulierSelection.emit({id: "", name: ""});
      }
     this.filterCapaciySlotsOnHaulier();
     this.enableCapacityInput();
  }

  filterCapaciySlotsOnHaulier() {
    this.selectedCapacity = this.emptyCapacity;
    this.filterCapacitySlotsOnSelectedPlanningGroupAndZone();
    if(this.selectedHaulier && this.selectedHaulier.id){
      this.filteredCapacitySlots = this.capacitySlots.filter((capacity) => {
        let haulierNoForCapacity = null;
        let capacityTruckHaulierNo = capacity.truck.haulierNo;
        if(capacityTruckHaulierNo && capacityTruckHaulierNo != "") haulierNoForCapacity = capacityTruckHaulierNo;
        return haulierNoForCapacity === this.selectedHaulier.id || !haulierNoForCapacity;
      })
    }
  }

  setSelectedHaulierFromSelectedCapacity(){
    if(this.selectedCapacity){
      let haulierNoForCapacity = null;
      let capacityDriverHaulierNo = this.selectedCapacity.driver.haulierNo;
      let capacityTruckHaulierNo = this.selectedCapacity.truck.haulierNo;
      if(capacityTruckHaulierNo && capacityTruckHaulierNo != "") haulierNoForCapacity = capacityTruckHaulierNo;
      else if(capacityDriverHaulierNo && capacityDriverHaulierNo != "") haulierNoForCapacity = capacityDriverHaulierNo;
      let selectedHaulier = this.planningHauliers.filter((haulier: Haulier) => {
        return haulier.id === haulierNoForCapacity;
      })[0];

      if(selectedHaulier) this.selectedHaulier = selectedHaulier;
      else this.selectedHaulier = {id : "" , name : ""};
    }
  }


  disableHaulierInput() {
    let selectedHaulierFromCapacity : Haulier = {id : "" ,name :""};
    let haulierNoForSeletedCapacity = null;
    if (this.selectedCapacity && this.selectedCapacity.driver || this.selectedCapacity.truck) {
      let selectedCapacityDriverHaulierNo = this.selectedCapacity.driver.haulierNo;
      let selectedCapacityTruckHaulierNo = this.selectedCapacity.truck.haulierNo;
      if (selectedCapacityTruckHaulierNo && selectedCapacityTruckHaulierNo != "") haulierNoForSeletedCapacity = selectedCapacityTruckHaulierNo;
      else if (selectedCapacityDriverHaulierNo && selectedCapacityDriverHaulierNo != "") haulierNoForSeletedCapacity = selectedCapacityDriverHaulierNo;
      let haulierForSelectedCapacityArray = this.planningHauliers.filter((haulier) => haulier.id === haulierNoForSeletedCapacity);
      if (haulierForSelectedCapacityArray.length > 0) {
        selectedHaulierFromCapacity = haulierForSelectedCapacityArray[0];
        this.selectedHaulier = selectedHaulierFromCapacity;
        // this.updateHaulierSelection(selectedHaulierFromCapacity);
        this.disabledHaulierInput = true;
      }
      else {
        this.resetHaulierInput(true);
      }
    }
    else {
      this.resetHaulierInput(false);
    }
  }

  public resetHaulierInput(disable: boolean) {
    if (disable) this.disabledHaulierInput = true;
    else this.disabledHaulierInput = false;
  }

  public updateCapacitySlots() {
    if(this.validateAndFormatDateForApi()){
      this.sharedActions.getCapacitySlotsLight(this.apiFormattedDate);
    }
  }

  public validateAndFormatDateForApi() : boolean {
    var t0 = Date.parse("1/1/1753 12:00:00 AM");
    var tf = Date.parse("12/31/9999 11:59:59 PM");
    var dateParsed = this.planDate.getTime() ; 
    //var dateStringIsValid = ;
    var dateIsValid = dateParsed >= t0 && dateParsed <= tf;
    if(dateIsValid){
      var date = new Date(dateParsed);
      this.apiFormattedDate = date.toISOString();
      return true;
    }
    else return false;
  }

  public setSelectionFromParent(capacity: string): CapacitySlot {
    const foundCapacity = this.capacitySlots.find((x) => x.truck.name === capacity);
    if (foundCapacity) {
      this.selectedCapacity = foundCapacity;
    } else {
      this.selectedCapacity = this.emptyCapacity;
    }

    return this.selectedCapacity;
  }


  public autocompleListFormatter(data: CapacitySlot): string {
    if (data.driver?.name) {
      return `${data.truck?.name} - ${data.driver.name}`;
    } else {
      return `${data.truck?.name}`;
    }
  }

  public valueListFormatter(data: CapacitySlot): string {
    if (data.driver?.name) {
      return `${data.truck?.name} - ${data.driver.name}`;
    } else {
      return `${data.truck?.name}`;
    }
  }

  public filterHauliersOnPlanningGroupSelection(): Haulier[] {
    let planningGroups: string[] = [];
    let filteredPlanningHauliers: Haulier[] = [];
    this.selectedPlanningBlocks.forEach((pb) => {
      if (planningGroups.indexOf(pb.planningGroup) <= -1) {
        planningGroups.push(pb.planningGroup);
      }
    });
    this.planningHauliers.forEach((haulier) => {
      if (haulier.planningGroup === '' || haulier.planningGroup === "ALL") filteredPlanningHauliers.push(haulier);
      if (planningGroups.indexOf(haulier.planningGroup) > -1 && this.filteredPlanningHauliers.indexOf(haulier) <= -1) filteredPlanningHauliers.push(haulier);
    });
    return filteredPlanningHauliers;
  }

  public filterHauliersOnSelectedPlanningGroup() {
    if(this.planningGroups && this.planningGroups.length > 0 
      && this.planningHauliers && this.planningHauliers.length > 0 
      && this.selectedPlanningBlocks && this.selectedPlanningBlocks.length > 0){

      let selectedPlanningGroupIds = this.selectedPlanningBlocks.map(pb => pb.planningGroup);
      selectedPlanningGroupIds = this.planningGroups.filter(pg => selectedPlanningGroupIds.indexOf(pg.id) > -1).map(pg => pg.id);

      if(selectedPlanningGroupIds.length > 0 && selectedPlanningGroupIds.indexOf('ALL') > -1) this.filteredPlanningHauliers = this.planningHauliers;
      else if(selectedPlanningGroupIds.length > 0 && selectedPlanningGroupIds.indexOf('ALL') === -1){
        this.filteredPlanningHauliers = this.planningHauliers.filter(h => selectedPlanningGroupIds.indexOf(h.planningGroup) > -1 || h.planningGroup === 'ALL');
      }
      else {
        this.filteredPlanningHauliers = this.planningHauliers;
      }
    }
    else this.filteredPlanningHauliers = this.planningHauliers;

    if(this.planningZones && this.planningZones.length > 0
      && this.planningHauliers && this.planningHauliers.length > 0 && this.hasMultipleZones && this.filterOnZone
      && this.selectedPlanningZone.toLowerCase() != 'all')
    {
      this.filteredPlanningHauliers = this.filteredPlanningHauliers.filter(el => el.planningZoneNo == this.selectedPlanningZone);
    }
  }

  public filterCapacitySlotsOnSelectedPlanningGroupAndZone() {
    if(this.planningGroups && this.planningGroups.length > 0
      && this.capacitySlots && this.capacitySlots.length > 0
      && this.selectedPlanningBlocks && this.selectedPlanningBlocks.length > 0){

        let selectedPlanningGroupIds = this.selectedPlanningBlocks.map(pb => pb.planningGroup);
        selectedPlanningGroupIds = this.planningGroups.filter(pg => selectedPlanningGroupIds.indexOf(pg.id) > -1).map(pg => pg.id);

        if(selectedPlanningGroupIds.length > 0 && selectedPlanningGroupIds.indexOf('ALL') > -1) this.filteredCapacitySlots = this.capacitySlots;
        else if(selectedPlanningGroupIds.length > 0 && selectedPlanningGroupIds.indexOf('ALL') === -1){
          this.filteredCapacitySlots = this.capacitySlots.filter(cs => selectedPlanningGroupIds.indexOf(cs.truck.planningGroupID) > -1 || cs.truck.planningGroupID === 'ALL');
        }
        else {
          this.filteredCapacitySlots = this.capacitySlots;
        }
    }
    else {
      this.filteredCapacitySlots = this.capacitySlots;
    }

    if(this.planningZones && this.planningZones.length > 0
      && this.capacitySlots && this.capacitySlots.length > 0 && this.hasMultipleZones && this.filterOnZone
      && this.selectedPlanningZone.toLowerCase() != 'all')
    {
      this.filteredCapacitySlots = this.filteredCapacitySlots.filter(el => el.truck.planningZoneNo == this.selectedPlanningZone);
    }
  }

  public autocompleListFormatterForHaulier(data: Haulier): string {
    return `${data.name}`;
  }

  public valueListFormatterForHaulier(data: Haulier): string {
    if(data != null)
    {
      return `${data.name}`;
    }
    else {
      return '';
    }
  }

  public enableCapacityInput() {
    this.disabledCapacityInput = false;
  }

  public disableCapacityInput() {
    this.disabledCapacityInput = true;
  }

  public enableDateInput() {
    this.disabledDateInput = false;
  }

  public disableDateInput() {
    this.disabledDateInput = true;
  }

  public setInitialPlanDate() {
    const currentDate = new Date();
    const currentDateHours = currentDate.getHours();

    if (this.isHourBetweenMidnightAndFiveAm(currentDateHours)) {
      this.setPlanDateToPreviousDay(currentDate);
    } else {
      this.planDate = currentDate;
    }
  }

  public setPlanDateToPreviousDay(currentDate) {
    const subtractXDays = 1;
    const newDate = new Date();

    newDate.setDate(currentDate.getDate() - subtractXDays);
    this.planDate = newDate;
  }

  public isHourBetweenMidnightAndFiveAm(currentDateHours) {
    return currentDateHours >= 0 && currentDateHours < 5;
  }
}
