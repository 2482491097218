import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { AnnouncementQuickFilter } from "../depot-out/models/depot-out.interface";
import { ActionKeys } from "../pb-screen/action.keys";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { DamageList } from "../pool/models/damageList.interface";
import { PoolContainer } from "../pool/models/pool-containers.interface";
import { PoolMove } from "../pool/models/pool-move.interface";
import { IAppState } from "../store/IAppState";

@Injectable()
export class AnnouncementActions extends SignalrBroadcaster {
    
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    public setAllPoolContainers(allPoolContainers: PoolContainer[]) {
        super.dispatchAction({
            type: ActionKeys.SET_ALL_POOL_CONTAINERS,
            allPoolContainers: allPoolContainers
        });
    }

    public filterContainers(searchText: string) {
        super.dispatchAction({
          type: ActionKeys.FILTER_CONTAINER_POOL_ANNOUNCEMENT,
          searchText
        });
    }

    public quickfilterAnnouncedContainers(announcementQuickFilter: AnnouncementQuickFilter) {
      super.dispatchAction({
        type: ActionKeys.QUICK_FILTER_ANNOUNCED_CONTAINERS,
        announcementQuickFilter,
      });
    }


    public quickFilterPoolContainers(announcementQuickFilter: AnnouncementQuickFilter) {
        super.dispatchAction({
          type: ActionKeys.QUICK_FILTER_CONTAINER_POOL_ANNOUNCEMENT,
          announcementQuickFilter,
        });
    }

    public quickFilterPreAnnouncedPoolMoves(announcementQuickFilter: AnnouncementQuickFilter) 
    {
      super.dispatchAction({
        type : ActionKeys.QUICK_FILTER_PREANNOUNCED_CONTAINER_MOVES , 
        announcementQuickFilter
      })
    }

    public restContainers() {
        super.dispatchAction({
          type: ActionKeys.RESET_POOL_CONTAINERS   
        });
    }

    public restPreAnnouncedContainers() {
      super.dispatchAction({
        type: ActionKeys.RESET_PREANNOUNCED_CONTAINER_MOVES,
      });
    }

    public resetAnnouncedContainers() {
        super.dispatchAction({
            type: ActionKeys.RESET_ANNOUNCED,
          });
    }

    public setSelectedPoolContainersForAnnouncement(poolContainersForAnnouncement: PoolContainer[]) {
        super.dispatchAction({
          type: ActionKeys.SET_POOL_CONTAINER_FOR_ANNOUNCEMENT, 
          poolContainersForAnnouncement
        })
    }

    public setAnnouncedPoolContainers(announcedPoolContainers : PoolContainer[]) {
        super.dispatchAction({
            type : ActionKeys.SET_ANNOUNCED_POOL_CONTAINERS,
            announcedPoolContainers
        })
    }

    public setSelectedAnnouncedContainers(selectedAnnouncedContainers: PoolContainer[]) {
        super.dispatchAction({
            type: ActionKeys.SET_SELECTED_ANNOUNCED_CONTAINER, 
            selectedAnnouncedContainers
          })
    }

    public filterAnnouncedContainers(searchText: string) {
        super.dispatchAction({
          type: ActionKeys.FILTER_ANNOUNCED,
          searchText
        });
    }

    updateAnnouncementDisplay(updatedPoolContainer: PoolContainer[])
    {
      super.dispatchAndTransmitActionToGeneralChannel({
        type: ActionKeys.MONITOR_ANNOUNCE_CONTAINER,
        updatedPoolContainer: updatedPoolContainer
      });
    }

    public setContainerDamageList(announcedContainerInspections: DamageList[]) {
      super.dispatchAction({
        type: ActionKeys.SET_ANNOUNCED_CONTAINER_DAMAGE,
        announcedContainerInspections
      });
    }

    public setPreAnnouncedContainerMoves(preAnnouncedContainerMoves : PoolMove[]) 
    {
      super.dispatchAction({
          type : ActionKeys.SET_PREANNOUNCED_CONTAINER_Moves, 
          preAnnouncedContainerMoves
      })
    }

    public setSelectedPreAnnouncedPoolMove(selectedPreAnnouncedPoolMove : PoolMove) {
      super.dispatchAndTransmitAction({
        type : ActionKeys.SET_SELECTED_PREANNOUNCED_POOL_MOVE , selectedPreAnnouncedPoolMove
      })
    }

    public resetAnnouncementFilter() {
      super.dispatchAction({
        type : ActionKeys.RESET_ANNOUNCEMENT_FILTER , 
        announcementQuickFilter : null
      })
    }
}