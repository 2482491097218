import { select } from "@angular-redux/store";
import { Component, Input, SimpleChanges } from "@angular/core";
import { isUniversal } from "@progress/kendo-angular-grid/dist/es2015/utils";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { ContainerReleaseStatus } from "../../../pb-screen/models/pb-screen.interface";
import { ComponentSource } from "../../models/component-source";
import { HasConflicts } from "../../models/planning-conflict";

@Component({
  selector: "depot-action-icons",
  templateUrl: "action-icons.component.html",
})

export class ActionIconsComponent {
  @Input() public hasScanningAction: boolean;
  @Input() public hasCustomsAction: boolean;
  @Input() public hasWeighingAction: boolean;
  @Input() public weighingDone: number;
  @Input() public hasADRAction: boolean;
  @Input() public hasT1Action: boolean;
  @Input() public highValue: string;
  @Input() public temperature: string;
  @Input() public containerReleaseStatus: ContainerReleaseStatus;
  @Input() public hasTarTasCode: boolean;
  @Input() public hasContainerAvailable: number;
  @Input() public preAnnouncementStatus: number;
  @Input() public truckAppointmentStatus: number;
  @Input() public preAnnouncementError: string;
  @Input() public truckAppointmentError: string;
  @Input() public source : string;
  @Input() public hasConflicts : HasConflicts = HasConflicts.None;
  
  @select("applicationUser") public applicationUser$ : Observable<ApplicationUser>; 

  public user : ApplicationUser;
  public type = HasConflicts ; 
  public weighed: boolean;
  public weighIconStyle: string;
  public containerAvailableIconStyle: string;
  public containerReleaseStatusStyle: string = "action-icon--inactive";
  public preAnnouncement: any = {tooltip: "Pre-Announcement Not Required", style: "action-icon--inactive"};
  public truckAppointment: any = {tooltip: "Truck Appointment  Not Required", style: "action-icon--inactive"};
  public conflictColor: string = "";
  public selectedCheck: any = {
    showScanning: true,
    showCustom: true,
    showWeighing: true,
    showghingDone: true,
    showADR: true,
    showT1: true,
    showTemperature:false,
    showHighValue:false,
    showTarTasCode: true,
    showContainerAvailable: environment.containerPoolEnabled,
    showPreAnnouncementStatus: environment.tarTasEnabled,
    showTruckAppointmentStatus: environment.tarTasEnabled,
    showContainerReleaseStatus: true
  };

  public ngOnInit(): void {

    this.applicationUser$.subscribe((data) => {
      if(data) {
        this.user = data ; 
        if(this.source == ComponentSource.depotIn)
        {
          data.depotInGridIcons.forEach((el) => {
            if(!el)
            return;
            if(el.id == 1) {
              this.selectedCheck.showScanning = el.isVisible;
            } else  if(el.id == 2) {
              this.selectedCheck.showCustom = el.isVisible;
            } else  if(el.id == 3) {
              this.selectedCheck.showWeighing = el.isVisible;
            } else  if(el.id == 4) {
              this.selectedCheck.showADR = el.isVisible;
            } else  if(el.id == 5) {
              this.selectedCheck.showT1 = el.isVisible;
            } else  if(el.id == 6) {
              this.selectedCheck.showContainerAvailable = el.isVisible && environment.containerPoolEnabled;
            } else  if(el.id == 7) {
              this.selectedCheck.showTarTasCode = el.isVisible;
            } else  if(el.id == 8) {
              this.selectedCheck.showPreAnnouncementStatus = el.isVisible && environment.tarTasEnabled;
            } else  if(el.id == 9) {
              this.selectedCheck.showTruckAppointmentStatus = el.isVisible && environment.tarTasEnabled;
            } else  if(el.id == 10) {
              this.selectedCheck.showTemperature = el.isVisible
            } else  if(el.id == 11) {
              this.selectedCheck.showHighValue = el.isVisible;
            } else  if(el.id == 12) {
              this.selectedCheck.showContainerReleaseStatus = el.isVisible && environment.containerReleaseEnabled;
            }
          })
        }

        else if (this.source == ComponentSource.depotOut)
        {
          data.depotOutGridIcons.forEach((el) => {
            if(!el)
            return;
            if(el.id == 1) {
              this.selectedCheck.showScanning = el.isVisible;
            } else  if(el.id == 2) {
              this.selectedCheck.showCustom = el.isVisible;
            } else  if(el.id == 3) {
              this.selectedCheck.showWeighing = el.isVisible;
            } else  if(el.id == 4) {
              this.selectedCheck.showADR = el.isVisible;
            } else  if(el.id == 5) {
              this.selectedCheck.showT1 = el.isVisible;
            } else  if(el.id == 6) {
              this.selectedCheck.showContainerAvailable = el.isVisible && environment.containerPoolEnabled;
            } else  if(el.id == 7) {
              this.selectedCheck.showTarTasCode = el.isVisible;
            } else  if(el.id == 8) {
              this.selectedCheck.showPreAnnouncementStatus = el.isVisible && environment.tarTasEnabled;
            } else  if(el.id == 9) {
              this.selectedCheck.showTruckAppointmentStatus = el.isVisible && environment.tarTasEnabled;
            } else  if(el.id == 10) {
              this.selectedCheck.showTemperature = el.isVisible
            } else  if(el.id == 11) {
              this.selectedCheck.showHighValue = el.isVisible;
            } else  if(el.id == 12) {
              this.selectedCheck.showContainerReleaseStatus = el.isVisible && environment.containerReleaseEnabled;
            }
          })
        }
        
      }
    })

    this.setWeighingStyle();

    this.setContainerAvailableStyle();
      this.setConflictIconColor();
      this.setPreAnnouncementStyle();
      this.setTruckAppointmentStyle();
      this.setContainerReleaseStatusStyle();
  }
  setContainerAvailableStyle() {
    this.containerAvailableIconStyle = this.hasContainerAvailable === 0
                                        ? "action-icon--active"
                                        : (this.hasContainerAvailable === 3
                                            ? "action-icon--danger"
                                            : "action-icon--inactive");
  }

  setPreAnnouncementStyle() {
    this.preAnnouncement = this.getStatusStyleAndTooltip(this.preAnnouncementStatus, "Pre-Announcement", this.preAnnouncementError);
  }

  getStatusStyleAndTooltip(status: number, statusType: string, error: string): any {
    switch(status){
      case 1: 
        return {style: "action-icon--warning", tooltip: statusType + " Not Sent"};
      case 5:
        return {style: "action-icon--warning", tooltip: statusType + " Cancelled"};
      case 2:
        return {style: "action-icon--sent", tooltip: statusType + " Sent"};
      case 4:
        return {style: "action-icon--danger", tooltip: statusType + " Error: " + error};
      case 3:
        return {style: "action-icon--success", tooltip: statusType + " OK"};
      case 0: 
      default:
        return {style: "action-icon--inactive", tooltip: statusType + " Not Required"};
    }
  }
  setTruckAppointmentStyle() {
    this.truckAppointment = this.getStatusStyleAndTooltip(this.truckAppointmentStatus, "Truck Appointment", this.truckAppointmentError);
  }

  setWeighingStyle() {
    this.weighed = this.weighingDone === 1 ?  true :  false;
    this.weighIconStyle = this.hasWeighingAction
                            ? (this.weighed ? "action-icon--active" : "action-icon--danger")
                            : "action-icon--inactive";
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.hasWeighingAction){
      this.setWeighingStyle();
    }
    if(changes.hasContainerAvailable){
      this.setContainerAvailableStyle();
    }
    if(changes.preAnnouncementStatus){
      this.setPreAnnouncementStyle();
    }
    if(changes.truckAppointmentStatus){
      this.setTruckAppointmentStyle();
    }
    if(changes.containerReleaseStatus){
      this.setContainerReleaseStatusStyle();
    }
  }


  setConflictIconColor() {
    if( this.hasConflicts == HasConflicts.None)
      return "action-icon--inactive";
    if(this.hasConflicts == HasConflicts.HasOnlyWarnings)
      this.conflictColor = "action-icon--warning";
    else if(this.hasConflicts == HasConflicts.HasErrors)
      this.conflictColor = "action-icon--danger";
    else this.conflictColor = "action-icon--inactive";
  }
  
  setContainerReleaseStatusStyle() {
    if(this.containerReleaseStatus == ContainerReleaseStatus.NotRequired)
      this.containerReleaseStatusStyle = "action-icon--inactive";
    else if(this.containerReleaseStatus == ContainerReleaseStatus.Required || this.containerReleaseStatus == ContainerReleaseStatus.Received)
      this.containerReleaseStatusStyle = "action-icon--warning";
    else if(this.containerReleaseStatus == ContainerReleaseStatus.Deleted || this.containerReleaseStatus == ContainerReleaseStatus.Revoked || this.containerReleaseStatus == ContainerReleaseStatus.Blocked)
      this.containerReleaseStatusStyle = "action-icon--danger";
    else if(this.containerReleaseStatus == ContainerReleaseStatus.Discharged)
      this.containerReleaseStatusStyle = "action-icon--active";
  }

}
