import { Injectable, Inject } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { Response, RequestOptions, ResponseContentType, Http } from '@angular/http';
import { ToastrService } from "ngx-toastr";

import * as  ClientSettings from '../static/clientSettings';

import { ShuttleListData } from '../shared/models/shuttle-list-data.interface';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';

@Injectable()
export class ShuttleListService {

  public baseUrl: string = ClientSettings.clientSettings.containerPlanningApiUri + "print";


    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }


    public printShuttleList(pbs: PlanningBlock[], name: string): any{
        const data: ShuttleListData = {
           planningBlocks: [],
           name: "",
            type: {
              iD: 1,
            },
          };

        data.planningBlocks =
            pbs.sort((a, b) => (a.fileId > b.fileId) ? 1 : ((b.fileId > a.fileId) ? -1 : 0));
        data.name = name;

        return this.http.post(this.baseUrl + "/shuttlelist" , data, { responseType:ResponseContentType.Blob } )
        .map((res:any) =>
         window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
        .catch((err) => {
          return Observable.throw(err);
        });
    }

}
