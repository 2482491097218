import { PipeTransform, Pipe } from "@angular/core";
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED, ON_HOLD, CANCELED, RE_USED, CLOSED, CREATED } from "../models/status-type.interface";

@Pipe({
    name: 'status'
  })
export class StatusPipe implements PipeTransform {
    transform(value: any, ...args: any[]): string {
        let stringStatus = '' ;
        if(value === PLANNED) {
            stringStatus = 'planned';
        }

        if(value === PRE_PLANNED) {
            stringStatus = 'pre planned';
        }

        return stringStatus;
    }
    
}