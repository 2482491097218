import { select } from "@angular-redux/store";
import { Component, Input, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { QuickView } from "../../../../models/quick-view/quick-view.interface";
import { ApplicationUser, UserSettings, Setting } from "../../../../models/application.user.interface";
import { QuickFilter } from "../../../../models/pb-screen.interface";
import { ApplicationUserActions } from "../../../../application.user.actions";
import { PbActions } from "../../../../pb.actions";
import { ApplicationUserService } from "../../../../services/application.user.service";
import { HttpClient } from "selenium-webdriver/http";
import { HttpClientService } from "../../../../../shared/services/httpclient";
import { ComponentSource } from "../../../../../shared/models/component-source";
import {  QuickFilter as depotOutQuickFilter } from "../../../../../depot-out/models/depot-out.interface";
import { QuickFilter as depotInQuickFilter } from "../../../../../depot-in/models/depot-in.interface";
import { AdvancedFilterGroup, AdvancedFilterItem } from "../../../../../shared/components/filter-line/filter-line.interface";
import { DepotOutActions } from "../../../../../depot-out/depot-out.actions";
import { DepotInActions } from "../../../../../depot-in/depot-in.actions";
import { DepotInAdvancedFilterActions } from "../../../../../depot-in/containers/depot-in-advanced-filter/depot-in-advanced-filter.action";
import { DepotOutAdvancedFilterActions } from "../../../../../depot-out/containers/depot-out-advanced-filter/depot-out-advanced-filter.actions";


@Component({
    moduleId: module.id,
    selector: "quick-view",
    templateUrl: "quick-view.component.html",
})

export class QuickViewComponent implements OnInit {
    public quickViews: QuickView[];
    public quickViewName: string = "";

    @Input() source: string;

    @select("applicationUser") public applicationUser$: Observable<ApplicationUser>;
    @select("sessionAdvancedFilters") public sessionAdvancedFilters$: Observable<AdvancedFilterGroup>;
    @select("quickFilter") public quickFilter$: Observable<QuickFilter>;
    @select("searchText") public searchText$: Observable<string>;
    @select("depotInQuickFilter") private depotInQuickFilter$: Observable<depotInQuickFilter>;
    @select("sessionDepotInAdvancedFilters") private sessionDepotInAdvancedFilters$: Observable<AdvancedFilterGroup>;
    @select("depotInSearchText") private depotInSearchText$: Observable<string>;
    @select("depotOutQuickFilter") private depotOutQuickFilter$: Observable<depotOutQuickFilter>;
    @select("sessionDepotOutAdvancedFilters") private sessionDepotOutAdvancedFilters$: Observable<AdvancedFilterGroup>;
    @select("depotOutSearchText") private depotOutSearchText$: Observable<string>;

    public applicationUser: ApplicationUser;
    public selectedQuickFilter: QuickFilter;
    public selectedAdvancedFilter: AdvancedFilterGroup;
    public selectedSearchText: string;
    public selectedDepotOutQuickFilter: depotOutQuickFilter;
    public selectedDepotOutAdvancedFilter: AdvancedFilterGroup;
    public selectedDepotOutSearchText: string;
    public selectedDepotInQuickFilter: depotInQuickFilter;
    public selectedDepotInAdvancedFilter: AdvancedFilterGroup;
    public selectedDepotInSearchText: string;
    public enableAutocomplete: boolean = false;

    constructor(
        public readonly userActions: ApplicationUserActions,
        public readonly pbActions: PbActions,
        public readonly applicationUserService: ApplicationUserService,
        private readonly depotOutActions: DepotOutActions,
        private readonly depotInActions: DepotInActions,
        private readonly depotInAdvancedFilterActions: DepotInAdvancedFilterActions,
        private readonly depotOutAdvancedFilterActions: DepotOutAdvancedFilterActions,
        public readonly httpClientService: HttpClientService) { }

    public ngOnInit(): void {
        this.subscribeToAllPbFilters();
        this.subscribeToAllDepotFilters();
        this.subscribeToApplicationUser();

    }

    public subscribeToApplicationUser() {
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.applicationUser = applicationUser;
                this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                if(this.source == ComponentSource.depotOut || this.source == ComponentSource.depotIn)
                    this.quickViews = this.applicationUser.depotQuickViews;
                else
                    this.quickViews = this.applicationUser.pbPreferences.quickViews;
                
            }
        });

    }

    public subscribeToAllPbFilters() {
        this.quickFilter$.subscribe((filter: QuickFilter) => {
            this.selectedQuickFilter = filter;
        });

        this.sessionAdvancedFilters$.subscribe((filterItems: AdvancedFilterGroup) => {
            this.selectedAdvancedFilter = filterItems;
        });

        this.searchText$.subscribe((searchText: string) => {
            this.selectedSearchText = searchText;
        });
    }

    public subscribeToAllDepotFilters() {
        this.depotOutQuickFilter$.subscribe((filter: depotOutQuickFilter) => {
            this.selectedDepotOutQuickFilter = filter;
        });

        this.sessionDepotOutAdvancedFilters$.subscribe((filterItems: AdvancedFilterGroup) => {
            this.selectedDepotOutAdvancedFilter = filterItems;
        });

        this.depotInQuickFilter$.subscribe((filter: depotInQuickFilter) => {
            this.selectedDepotInQuickFilter = filter;
        });

        this.sessionDepotInAdvancedFilters$.subscribe((filterItems: AdvancedFilterGroup) => {
            this.selectedDepotInAdvancedFilter = filterItems;
        });

        this.depotOutSearchText$.subscribe((searchText: string) => {
            this.selectedDepotOutSearchText = searchText;
        });

        this.depotInSearchText$.subscribe((searchText: string) => {
            this.selectedDepotInSearchText = searchText;
        });
    }

    public createNewQuickView(): void {
        const createdName = this.createQuickViewName();
        const quickView: QuickView = {
            pbSnapshot: {
                advancedFilter: this.selectedAdvancedFilter,
                quickFilter: this.selectedQuickFilter,
                textFilter: this.selectedSearchText,
            },
            name: createdName,
            depotInSnapshot: {
                advancedFilter: this.selectedDepotInAdvancedFilter,
                quickFilter: this.selectedDepotInQuickFilter,
                textFilter: this.selectedDepotInSearchText,
            },
            depotOutSnapshot: {
                advancedFilter: this.selectedDepotOutAdvancedFilter,
                quickFilter: this.selectedDepotOutQuickFilter,
                textFilter: this.selectedDepotOutSearchText,
            },
        };

        this.createInitialQuickViewsIfItDoesntExist();
        this.removeOldestQuickViewWhenOverTreshold();
        this.saveQuickViewsToApplicationUserSettings(quickView);
        this.quickViewName = "";
    }

    public createInitialQuickViewsIfItDoesntExist(): void {
        if (!this.applicationUser.pbPreferences.quickViews) {
            this.applicationUser.pbPreferences.quickViews = [];
        }
        if (!this.applicationUser.depotQuickViews) {
            this.applicationUser.depotQuickViews = [];
        }
    }

    public removeOldestQuickViewWhenOverTreshold(): void {
        const maximumNumberOfQuickViews: number = 10;
        if (this.applicationUser.pbPreferences.quickViews.length === maximumNumberOfQuickViews) {
            this.applicationUser.pbPreferences.quickViews.shift();
        }
        if (this.applicationUser.depotQuickViews.length === maximumNumberOfQuickViews) {
            this.applicationUser.depotQuickViews.shift();
        }
    }

    public saveQuickViewsToApplicationUserSettings(quickView: QuickView): void {
        if(this.source == ComponentSource.depotIn || this.source == ComponentSource.depotOut)
        {
            this.applicationUser.depotQuickViews = [...this.applicationUser.depotQuickViews, quickView];
            this.userActions.updateApplicationUser(this.applicationUser,'depotQuickViews',true);
        }
        else
        {
            this.applicationUser.pbPreferences.quickViews = [...this.applicationUser.pbPreferences.quickViews, quickView];
            this.userActions.updateApplicationUser(this.applicationUser,'defaultPbUserSettings',true);

        }
        
    }

    public createQuickViewName(): string {
        const dateTimeNow = new Date().toLocaleString();

        if (this.quickViewName) {
            return dateTimeNow + " - " + this.quickViewName;
        }

        return dateTimeNow + " - " + (this.selectedSearchText ? this.selectedSearchText : "")
        + (this.selectedDepotInSearchText ? this.selectedDepotInSearchText : "") + " "
        + (this.selectedDepotOutSearchText ? this.selectedDepotOutSearchText : "");
    }

    public openQuickView(index: number): void {
        //this.resetCurrentFilters();
        if (this.quickViews.length >= index + 1) {
            const quickView = this.quickViews[index];
            this.applyQuickViewFilterActions(quickView);
        }
    }

    public resetCurrentFilters(): void {
        this.pbActions.resetQuickFilter();
        this.pbActions.resetAdvancedFilter(null);

        this.depotOutActions.resetQuickFilterPlanningBlocks();
        this.depotOutAdvancedFilterActions.resetAdvancedFilter(null);

        this.depotInActions.resetQuickFilterPlanningBlocks();
        this.depotInAdvancedFilterActions.resetAdvancedFilter(null);
    }

    public applyQuickViewFilterActions(quickView: QuickView): void {
        if(this.source == ComponentSource.depotOut || this.source == ComponentSource.depotIn)
        {
            this.depotOutActions.filterPlanningBlocks(quickView.depotOutSnapshot.textFilter);
            this.depotInActions.filterPlanningBlocks(quickView.depotInSnapshot.textFilter);

            if (quickView.depotOutSnapshot.quickFilter) {
                this.depotOutActions.quickFilterPlanningBlocks(quickView.depotOutSnapshot.quickFilter);
            }

            if (quickView.depotInSnapshot.quickFilter) {
                this.depotInActions.quickFilterPlanningBlocks(quickView.depotInSnapshot.quickFilter);
            }

            if (quickView.depotInSnapshot.advancedFilter && 
                (quickView.depotInSnapshot.advancedFilter.filterGroups.length > 0 || quickView.depotInSnapshot.advancedFilter.filterItems.length > 0)) {
                this.depotInAdvancedFilterActions.executeAdvancedFilter(quickView.depotInSnapshot.advancedFilter);
            }

            if (quickView.depotOutSnapshot.advancedFilter &&
                (quickView.depotOutSnapshot.advancedFilter.filterGroups.length > 0 || quickView.depotOutSnapshot.advancedFilter.filterItems.length > 0)) {
                this.depotOutAdvancedFilterActions.executeAdvancedFilter(quickView.depotOutSnapshot.advancedFilter);
            }
        }
        else
        {
            this.pbActions.applyGlobalFilter(quickView.pbSnapshot.textFilter);
    
            if (quickView.pbSnapshot.quickFilter) {
                this.pbActions.applyQuickFilter(quickView.pbSnapshot.quickFilter);
            }
            if (quickView.pbSnapshot.advancedFilter && 
                (quickView.pbSnapshot.advancedFilter.filterGroups.length > 0 || quickView.pbSnapshot.advancedFilter.filterItems.length > 0)) {
                    this.pbActions.applyAdvancedFilter(quickView.pbSnapshot.advancedFilter);
            }

        }
    }

    private removeQuickView(selectedQuickView: QuickView) {
        if(this.source == ComponentSource.depotOut || this.source == ComponentSource.depotIn)
        {
            const newQuickViews = this.applicationUser.depotQuickViews
                .filter((savedQuickView: QuickView) => savedQuickView !== selectedQuickView);
    
            this.applicationUser.depotQuickViews = newQuickViews;
            this.userActions.updateApplicationUser(this.applicationUser, 'depotQuickViews', true);

        }
        else
        {
            const newQuickViews = this.applicationUser.pbPreferences.quickViews
                .filter((savedQuickView: QuickView) => savedQuickView !== selectedQuickView);
    
            this.applicationUser.pbPreferences.quickViews = newQuickViews;
            this.userActions.updateApplicationUser(this.applicationUser,'defaultPbUserSettings',true);

        }
    }
}
