import { Injectable } from "@angular/core";
import { ToActions } from "../../to-screen/to.actions";
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from "../models/status-type.interface";
import { element } from "protractor";

@Injectable()
export class ToActionBarService {
    constructor(private toActions: ToActions) { }
    public setActionBar(selectedOrderlines, selectedPlanningBlocks) {
        let toactionBarStates;

        const toPlanBtnState = selectedPlanningBlocks.every((element, index, array) => {
                return  ((element.status.statusType === PRE_PLANNED && (element.transportOrderNumber === array[0].transportOrderNumber)) 
                        || (element.status.statusType === TO_BE_PLANNED && element.transportOrderNumber === array[0].transportOrderNumber));
        });

        const cancelPbBtnState = selectedPlanningBlocks.every((element, index, array) => {
            return element.status.statusType === PLANNED && selectedOrderlines.length > 0;
        })

        const unplanPbBtnState = selectedPlanningBlocks.every((element, index, array) => {

            return element.status.statusType === PRE_PLANNED && selectedOrderlines.length > 0;
        });

        const sendPbBtnState = selectedPlanningBlocks.every((element, index, array) => {
            return element.status.statusType === PRE_PLANNED && selectedOrderlines.length > 0;
        })
        
        const pbDetailsFromTo = selectedOrderlines.every((element , index , array) => {
            return element.planningBlockId && element.planningBlockId == array[0].planningBlockId; 
        })

        

        toactionBarStates = {
            toPlanBtnState : toPlanBtnState,
            cancelPbBtnState: cancelPbBtnState,
            unplanPbBtnState: unplanPbBtnState,
            sendPbBtnState: sendPbBtnState,
            pbDetailsFromTo : pbDetailsFromTo
        };

        this.toActions.setActionBarStates(toactionBarStates);
    }
    public resetActionBar(){
        let toactionBarStates = {
            toPlanBtnState : false,
            cancelPbBtnState: false,
            unplanPbBtnState: false,
            sendPbBtnState: false,
            pbDetailsFromTo: false
        };
        this.toActions.setActionBarStates(toactionBarStates);
    }
}

