import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter, ChangeDetectorRef } from "@angular/core";
import { SimpleModalService } from "ngx-simple-modal";
import { TarTasService } from "../../services/tartas.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { TruckAppointment, TruckAppointmentBookingTabs, TruckAppointmentLine } from "../../models/tar-tas/tar-tas.interface";
import { Actions } from "../../../services/printticket.service";
import { ComponentSource } from "../../models/component-source";
import { PlanningBlock } from "../../models/planning-block/planning-block.interface";
import { SharedService } from "../../services/shared.service";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { PbActions } from "../../../pb-screen/pb.actions";
import { PbScreenService } from "../../../pb-screen/services/pb-screen.service";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";

@Component({
    selector: "swap-truck-appointment-modal",
    templateUrl: "./swap-truck-appointment-modal.component.html",
    styleUrls: ["./swap-truck-appointment-modal.component.css"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SwapTruckAppointmentModalComponent implements OnInit {

    @Input() depotInLines: TruckAppointmentLine[];
    @Input() depotOutLines: TruckAppointmentLine[];
    @Input() selectedTab: TruckAppointmentBookingTabs;
    TruckAppointmentBookingTabs = TruckAppointmentBookingTabs;
    @Output() goToMain: EventEmitter<boolean> = new EventEmitter<boolean>();
    public truckAppointments: TruckAppointment[];
    checkedAppointments: TruckAppointment[] = [];
    public componentSource = ComponentSource;
    @select('loading') public loading$: Observable<boolean>;

    public depotInTruckAppointmentLines: TruckAppointmentLine[] = [];
    public depotOutTruckAppointmentLines: TruckAppointmentLine[] = [];
    public checkedDepotInLines: TruckAppointmentLine[] = [];
    public checkedDepotOutLines: TruckAppointmentLine[] = [];
    public selectedAppointmentNo: string = '';
    public loading: boolean = false;

    constructor(
        private simpleModalService: SimpleModalService,
        private readonly tarTasService: TarTasService,
        private readonly toastr: ToastrService,
        private readonly changeDetector: ChangeDetectorRef,
        private readonly sharedService: SharedService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        public pbActions: PbActions,
        private readonly pbService: PbScreenService
    ) {
    }

  public ngOnInit() {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loading = data;
    })
        var lines = this.depotInLines.concat(this.depotOutLines);
        if(lines.length){
            var line = lines[0];
            this.loading = true;
            this.tarTasService.getTruckAppointmentsForSwap(line.truckAppointmentNo, 
                line.truckAppointmentAddressId, line.truckAppointmentSystem)
                .subscribe(data => {
                    this.truckAppointments = data;
                    this.loading = false;
                    this.changeDetector.detectChanges();
                    if(!this.truckAppointments.length)
                        this.toastr.error("No available truck appointments.");
                });
        }
    }

    getTruckAppointmentLines(truckAppointmentNo: string) {
        this.loading = true;
        this.tarTasService.getTruckAppointmentLinesForAppointmentNo(truckAppointmentNo)
            .subscribe(
                result => {
                    this.loading = false;
                    if(!result.length)
                    {
                        this.toastr.error("No lines for Truck appointment no.: " + truckAppointmentNo + ". The appointment will be automatically deleted after 15 minutes.");
                        this.changeDetector.detectChanges();
                        return;
                    }
                    var partiotioned = _.partition(result, (line: TruckAppointmentLine) => line.actionType == Actions.DEPOT_IN);
                    this.depotInTruckAppointmentLines = partiotioned[0];
                    this.depotOutTruckAppointmentLines = partiotioned[1];
                    this.changeDetector.detectChanges();
                });
    }

    goBackToMain(){
        this.goToMain.emit(false);
    }

    public closeModal() {
        this.simpleModalService.removeAll();
    }

    check(appointment: TruckAppointment){
        if(this.selectedAppointmentNo != appointment.no)
        {
            this.selectedAppointmentNo = appointment.no;
            if(this.selectedTab == TruckAppointmentBookingTabs.Swap)
                this.getTruckAppointmentLines(appointment.no);
        }
        else
        {
            this.selectedAppointmentNo = '';
        }
    }

    swapAppointment(){
        let toLines = this.checkedDepotInLines.concat(this.checkedDepotOutLines);
        let fromLines = this.depotInLines.concat(this.depotOutLines);
        if(!toLines.length || !fromLines.length)
        {
            this.toastr.error("Truck Appointment Lines not selected")
            return;
        }
        let orderlineIdsTo = toLines.map(el => el.orderlineId);
        let orderlineIdsFrom = fromLines.map(el => el.orderlineId);
        this.tarTasService.swapContainers(orderlineIdsFrom, fromLines[0].truckAppointmentNo, orderlineIdsTo, toLines[0].truckAppointmentNo)
            .subscribe(
                result => {
                    let pbIds = toLines.concat(fromLines).map(el => el.pbEntryNo);
                    this.sharedService.getPlanningBlocksByIds(pbIds)
                        .subscribe((planningBlocks) => {
                            this.depotInActions.updatePlanningBlocks(planningBlocks);
                            this.depotOutActions.updatePlanningBlocks(planningBlocks);
                        });

                    this.pbService.getPlanningBlocksByIds(pbIds)
                        .subscribe((pbs)=> {
                            this.pbActions.updatePlanningBlocksDisplay(pbs);
                        });
                    this.toastr.success("Containers swaped.");
                    this.closeModal();
                });
    }

    connect(actionName: string){
        let lines = this.depotInLines.concat(this.depotOutLines);
        if(!lines.length)
        {
            this.toastr.error("Truck Appointment Lines not selected")
            return;
        }
        let orderlineIds = lines.map(el => el.orderlineId);
        this.tarTasService.connectContainers(orderlineIds, lines[0].truckAppointmentNo, this.selectedAppointmentNo)
            .subscribe(
                result => {
                    var pbIds = lines.map(el => el.pbEntryNo);
                    this.sharedService.getPlanningBlocksByIds(pbIds)
                        .subscribe((planningBlocks) => {
                            this.depotInActions.updatePlanningBlocks(planningBlocks);
                            this.depotOutActions.updatePlanningBlocks(planningBlocks);
                        });
                    this.pbService.getPlanningBlocksByIds(pbIds)
                        .subscribe((pbs)=> {
                            this.pbActions.updatePlanningBlocksDisplay(pbs);
                        })
                    if(lines[0].truckAppointmentNo)
                        this.getTruckAppointmentLines(lines[0].truckAppointmentNo);
                    this.closeModal();
                    this.toastr.success("Containers " + actionName);
                });
    }

    move(){
        this.connect("moved");
    }

    link(){
        this.connect("linked");
    }
}
