import { OnInit, Component, ViewChild, ElementRef, Input, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { ToOperationalStatusType, TransportOrder } from "../../../../pb-screen/models/transport-order/transport-order.interface";
import { Observable, Subject, Subscription } from "rxjs";
import { OrderLine } from "../../../../pb-screen/models/order-line/orderLine.interface";
import { ToScreenService } from "../../../../pb-screen/services/to-screen.service";
import { GridDataResult } from "@progress/kendo-angular-grid";
import { ActionWizardService } from "../../../../shared/services/action-wizard.service";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";
import { WizardDefinition } from "../../../../shared/models/wizard-definition";
import { InsertActionTemplateType } from "../../../../shared/models/insert-action-template-type";
import { HttpClientService } from "../../../../shared/services/httpclient";
import { SharedActions } from "../../../../shared/shared.actions";
import { ToastrService } from "ngx-toastr";
import { ToActions } from "../../../to.actions";
import { ToActionBarService } from "../../../../pb-screen/services/to-action-bar.service";
import { PbScreenService } from "../../../../pb-screen/services/pb-screen.service";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { takeUntil } from "rxjs/operators";
import { SimpleModalComponent, SimpleModalService } from "ngx-simple-modal";
import { ToInsertActionWizardComponent } from "../../../modals/to-insert-action-wizard-modal/to-insert-action-wizard-modal.component";

@Component({
    moduleId: module.id,
    selector: 'to-details-modal',
    templateUrl: './to-details-modal.component.html'
})

export class ToDetailsComponent extends SimpleModalComponent<null, null> implements OnInit,OnDestroy{
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select("toWizardDefinitions") toWizardDefinitions$: Observable<WizardDefinition[]>;
    @select("selectedPbsFromPlanBoard") selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>
    @select("selectionType") public selectionType$: Observable<string>;
    @select("selectedPbsToPlan") public selectedPbsToPlan$ : Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;

    public selectedTransportOrders: TransportOrder[] = [] ; 
    public orderLines: OrderLine[] = [];
    public finalizeCombi : boolean = false ;
    public actionWizardsList: WizardDefinition[] = [];
    public selectedActionWizard: WizardDefinition = null;
    public selectedTo : TransportOrder = null;
    public filter: string = InsertActionTemplateType.toCard;
    public isProccessingWizard: boolean = false;
    public manuallySelectOrderlines: boolean = false;
    public selectionType: string = "";
    public user: ApplicationUser;
    public defaultPlanningZone: string;
    public isOpenTO: boolean = false;

    public loadingSpinner = false;
 
    public wizard = {
        code: "",
        manuallySelectOrderline: false,
        wizardDefinition: null,
        targetType: 1,
        orderlines: []
    }
    public openingScreen = 'TO-Screen-Details';
    public tableData: GridDataResult;
    destroy$ = new Subject();
    constructor(private readonly toScreenService: ToScreenService,
        private readonly actionWizardService: ActionWizardService,
        private readonly httpClientService: HttpClientService,
        private readonly sharedActions: SharedActions,
        private readonly toastr: ToastrService,
        private readonly toActions: ToActions,
        private readonly simpleModalService: SimpleModalService,
        private readonly toActionBarService: ToActionBarService,
        private readonly pbScreenService : PbScreenService) {
    
        super();
      }
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
  ngOnInit(): void {
    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.selectionType$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            if (data) {
              this.selectionType = data;
            }
          })

        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders : TransportOrder[]) => {
            this.loadingSpinner = true;
            this.isOpenTO = false;
            if(transportOrders) {
                this.selectedTransportOrders = transportOrders;
                this.selectedTo = transportOrders[0] ;
                this.loadingSpinner = false;
            } 
            if(this.selectedTo) 
            {
                this.finalizeCombi = this.selectedTo.finalizedCombi === 1 ? true : false;
                this.loadingSpinner = false;

                if(this.selectedTo.toOperationalStatusType == ToOperationalStatusType.Created && !this.selectedTo.truckNo && !this.selectedTo.haulierNo)
                    this.isOpenTO = true;
                else
                    this.isOpenTO = false;
            }
        })

       this.selectedPbsFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.setPbs(data);
        })

        this.selectedPbsToPlan$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.setPbs(data);
          })

        this.toWizardDefinitions$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            this.actionWizardsList = data;
        })

        
    } 
    setPbs(data: PlanningBlock[]) {
        if(data && data.length > 0) {
            this.loadingSpinner = true;
            this.toScreenService.getTransportOrdersByIds(data[0].transportOrderNumber).subscribe((data) => {
                if(data) {
                    this.selectedTo = data[0] ; 
                    this.finalizeCombi = data[0].finalizedCombi === 1 ? true : false ;
                    this.toActions.selectToForDetails(data[0]);
                    this.loadingSpinner = false;
                    this.sharedActions.setOrderlinesForToR(data[0].planningOrderlines);
                }
            })
        }
    }

    startWizard() {
        if (!this.selectedActionWizard) {
            this.toastr.warning('Please Select a wizard');
        } 

        if (this.selectedActionWizard.insertLines.indexOf('Action Code') > -1) {
                this.executeWizardWithoutModal();
        }
        else {
            this.simpleModalService.addModal(ToInsertActionWizardComponent).subscribe(() => { });
        }
        
    }

    selectChange(event) {
        this.selectedActionWizard = event;
        this.wizard.code = this.selectedActionWizard.code;
        this.wizard.wizardDefinition = this.selectedActionWizard;
        this.sharedActions.setSelectedActionWizard(this.selectedActionWizard);
    }

    automaticallySelectOrderlineForWizard(wizard) {}

    executeWizardWithoutModal() {
      /*this.loadingSpinner = true;
        if (this.wizard.wizardDefinition.insertLines === "After Last Action Code" || this.wizard.wizardDefinition.insertLines === "Before First Action Code") {
            this.automaticallySelectOrderlineForWizard(this.wizard);
        }*/
        let insertLineContainsActionCode = this.wizard.wizardDefinition.insertLines.indexOf("Action Code");
        if (insertLineContainsActionCode > -1 || this.wizard.code === "START TO" || this.wizard.code === "STOP TO") {
            this.actionWizardService.getOrderlineForTransportOrder(this.selectedTransportOrders[0].id, this.wizard.code).subscribe((data: OrderLine) => {
                this.wizard.orderlines.push(data);
                this.wizard.orderlines.forEach((element) => {
                    this.actionWizardService.getWizardLines(this.wizard.code, element.orderLineId).subscribe((data) => {
                        data.forEach((wizardLine,index) => {
                            if (data.length - 1 === index) {
                                this.actionWizardService.executeWizard({
                                    "code": this.wizard.code,
                                    "orderLineId": element.orderLineId,
                                    "calledFrom": this.wizard.targetType,
                                    "addressList": data[0].addressNo
                                }).subscribe((data) => {
                                    this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                                })
                            }
                        });
                    })
                })
            })
        }

        

    }

    public onChange($event) {
        this.loadingSpinner = true;
        this.finalizeCombi = $event; 
        let toNumbers = [] ; 
        toNumbers.push(this.selectedTo.id)
        this.pbScreenService.updateFinalizedCombi(toNumbers,this.finalizeCombi).subscribe((data) => {
            this.sharedActions.updateTransportOrdersDisplay(data)
            this.toastr.success('Finalized Combi Update succeeded')
            this.loadingSpinner = false;
        }); 
    }
    
    private closeModal(): void {
        this.close();
    }
   
    private setOrderlines(to){
        this.toScreenService.getTransportOrderDetails(to
        ).subscribe((orderlines: any[]) => {
            this.sharedActions.getOrderlines(orderlines);
          })
    }
}
