import { Component } from '@angular/core';
import { SimpleModalComponent } from 'ngx-simple-modal';
 
export interface AlertModel {
  title: string;
  message: string;
}
 
@Component({
  selector: 'version-modal',
  templateUrl: './version-modal.component.html'
})
export class VersionComponent extends SimpleModalComponent<AlertModel, null> implements AlertModel {
  title: string;
  message: string;
  constructor() {
    super();
  }
}