import { select } from "@angular-redux/store";
import {
  Component, ElementRef, EventEmitter, Input, OnChanges, OnInit,
  Output, SimpleChange, ViewChild,
} from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";

import { ModalManagerService } from "./../../../services/modal-manager.service";

import { ComponentSource, ModalType } from "../../../models/component-source";
import { PLANNED, PRE_PLANNED } from "../../../models/status-type.interface";
import { TransportOrderPlanUnit } from "../../../models/transport-order/transport-order-plan-unit.interface";
import { SharedService } from "../../../services/shared.service";
import { SharedActions } from "../../../shared.actions";
import { ApplicationUser } from "../../../../pb-screen/models/application.user.interface";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { OrderLine } from "../../../../pb-screen/models/order-line/orderLine.interface";
import { PrintTicketService } from "../../../services/print/printticket.service";
import { ContainerListService } from "../../../services/print/containerlist.print.service";
import { ShuttleListService } from "../../../../services/shuttle.print.service";
import { StackerListService } from "../../../services/print/stacker.print.service";
import { WareHouseListService } from "../../../services/print/warehouse-list.service";
import { StuffingStaalListService } from "../../../services/print/stuffing-staal-list.service";
import { CMRService } from "../../../services/print/print-CMR.service";
import { WeekPlanningListService } from "../../../services/print/week-planning-list.service";

@Component({
  selector: "depot-print-list-modal",
  templateUrl: "print-list-modal.component.html",
})

export class PrintListComponent implements OnInit {

  @ViewChild("modal")
  public modal: ElementRef;

  @select("applicationUser") public applicationUser$: Observable<ApplicationUser>;
  @select("depotInSelection") private depotInSelection$: Observable<PlanningBlock[]>;
  @select("depotOutSelection") private depotOutSelection$: Observable<PlanningBlock[]>;
  @select("depotInProcessedDisplayState") public depotInProcessedDisplayState$: Observable<boolean>;
  @select("depotOutProcessedDisplayState") public depotOutProcessedDisplayState$: Observable<boolean>;

  @Input() public depotOutPlanningBlocks: PlanningBlock[];
  @Input() public depotInPlanningBlocks: PlanningBlock[];
  @Input() public source: string;

  public enableAutocomplete: boolean = false;
  public CMR: string = "CMR";
  public TICKET: string = "Print Ticket";
  public WEEK_PLANNING: string = "Week Planning Report";

  public lists: string[] = [this.CMR, "Checklist Container Stuffing – Staal", "Container Planning List Warehouse",
    "Container Planning List", "Shuttle List", "Stacker List", this.TICKET, this.WEEK_PLANNING];

  public selectedList: string = "Container Planning List";
  public sizeOfSelectionToBig: boolean = false;
  public overMaximum: boolean = false;
  public applicationUser: string = "";
  public depotInProcessedDisplayState: boolean;
  public depotOutProcessedDisplayState: boolean;
  public processedDepotOutPlanningBlocks: PlanningBlock[];
  public processedDepotInPlanningBlocks: PlanningBlock[];

  public componentSource = ComponentSource;
  public modalType = ModalType;

  public weekPlanStartDate: string;
  public weekPlanEndDate: string;

  public selectedDepotInPbs: PlanningBlock[] = [];
  public selectedDepotOutPbs: PlanningBlock[] = [];
  public printButtonRestriction: boolean = false;

  constructor(
    private readonly sharedActions: SharedActions,
    private readonly sharedService: SharedService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly shuttleService: ShuttleListService,
    private readonly containerService: ContainerListService,
    private readonly stackerService: StackerListService,
    private readonly wareHouseService: WareHouseListService,
    private readonly stuffingtaalService: StuffingStaalListService,
    private readonly cmrService: CMRService,
    private readonly printTicketService: PrintTicketService,
    private readonly weekPlanningListService: WeekPlanningListService,
    private readonly modalManager: ModalManagerService) { }

  public ngOnInit(): void {
    this.depotInPlanningBlocks = this.depotInPlanningBlocks ? this.depotInPlanningBlocks : [];
    this.depotOutPlanningBlocks = this.depotOutPlanningBlocks ? this.depotOutPlanningBlocks : [];

    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.applicationUser = applicationUser.fullName;
        this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
      }
    });

    this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      if (selectedPlanningBlocks) {
        this.selectedDepotInPbs = selectedPlanningBlocks;
      }
    });
    this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      if (selectedPlanningBlocks) {
        this.selectedDepotOutPbs = selectedPlanningBlocks;
      }
    });

    this.depotInProcessedDisplayState$.subscribe((depotInProcessedDisplayState: boolean) => {
      this.depotInProcessedDisplayState = depotInProcessedDisplayState;
    });
    this.depotOutProcessedDisplayState$.subscribe((depotOutProcessedDisplayState: boolean) => {
      this.depotOutProcessedDisplayState = depotOutProcessedDisplayState;
    });
  }

  public onCancelClicked(): void {
    this.resetUi();
  }

  public resetUi(): void {
    this.selectedList = "Container Planning List";
    this.sizeOfSelectionToBig = false;
    this.overMaximum = false;
    this.printButtonRestriction = false;
    this.sharedActions.setUiState(true);
  }

  public print() {
    if (this.selectedList === "Print Ticket") {
      const planningBlocks = [...this.selectedDepotInPbs, ...this.selectedDepotOutPbs];
      const planUnit: TransportOrderPlanUnit = {
        depotOutPlanningBlocks: this.selectedDepotOutPbs,
        depotInPlanningBlocks: this.selectedDepotInPbs,
      };

      const pbIds = planningBlocks.reduce((planningBlockIds: number[], pb: PlanningBlock) => {
        planningBlockIds.push(pb.id); return planningBlockIds;
      }, []).join(",");

      const toNumberCheck: boolean = planningBlocks.every((planningBlock: PlanningBlock) =>
        planningBlock.transportOrderNumber === planningBlocks[0].transportOrderNumber);
        const pbsTypesCheck: boolean = planningBlocks.every((planningBlock: PlanningBlock) =>
            planningBlock.statusType === PLANNED || planningBlock.statusType === PRE_PLANNED);

      if (!toNumberCheck) {
        this.toastr.error("Planning Blocks have different transport order numbers");
      } else if (!pbsTypesCheck) {
        this.toastr.error("Planning Blocks need to be planned or pre-planned");
      } else {
        this.sharedService.getOrderLinesCpl(pbIds).subscribe(
          (orderlines: OrderLine[]) => {
            this.printTicketService.printTicket(planUnit, planningBlocks[0].orderNumber, orderlines, this.applicationUser)
              .subscribe((result: any) => { this.closeModal(); });
          });
      }
    }

    if (this.selectedList === "Shuttle List") {
      if (this.source === this.componentSource.depotIn) {
        this.shuttleService.printShuttleList(this.processedDepotInPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
      if (this.source === this.componentSource.depotOut) {
        this.shuttleService.printShuttleList(this.processedDepotOutPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
    }
    if (this.selectedList === "Container Planning List") {
      if (this.source === this.componentSource.depotIn) {
        this.containerService.printContainerList(this.processedDepotInPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
      if (this.source === this.componentSource.depotOut) {
        this.containerService.printContainerList(this.processedDepotOutPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
    }
    if (this.selectedList === "Stacker List") {
      if (this.source === this.componentSource.depotIn) {
        this.stackerService.printStackerList(this.processedDepotInPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
      if (this.source === this.componentSource.depotOut) {
        this.stackerService.printStackerList(this.processedDepotOutPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
    }
    if (this.selectedList === "Container Planning List Warehouse") {
      if (this.source === this.componentSource.depotIn) {
        this.wareHouseService.printWareHouseList(this.processedDepotInPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
      if (this.source === this.componentSource.depotOut) {
        this.wareHouseService.printWareHouseList(this.processedDepotOutPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
    }
    if (this.selectedList === "Checklist Container Stuffing – Staal") {
      if (this.source === this.componentSource.depotIn) {
        this.stuffingtaalService.printStuffingStaalList(this.processedDepotInPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
      if (this.source === this.componentSource.depotOut) {
        this.stuffingtaalService.printStuffingStaalList(this.processedDepotOutPlanningBlocks, this.applicationUser)
          .subscribe((result: any) => { this.closeModal(); });
      }
    }
    if (this.selectedList === this.WEEK_PLANNING) {
      const type = this.source === this.componentSource.depotOut ? 1 : 2;

      this.weekPlanningListService.printWeekPlan(this.applicationUser, this.weekPlanStartDate, this.weekPlanEndDate, type)
        .subscribe((result: any) => { this.closeModal(); });
    }
  }

  public closeModal(): void {
    this.selectedList = "Container Planning List";
    this.sizeOfSelectionToBig = false;
    this.overMaximum = false;
    this.printButtonRestriction = false;
    this.sharedActions.setUiState(true);
    this.modalManager.closeModal(this.modalType.print);

  }

  public selectionChanged() {
    this.sizeOfSelectionToBig = false;
    this.overMaximum = false;
    this.printButtonRestriction = false;
    if (this.selectedList === this.CMR) {
      this.printButtonRestriction = !([...this.selectedDepotInPbs, ...this.selectedDepotOutPbs].length > 0);
    } else if (this.selectedList === this.TICKET) {
      this.printButtonRestriction = !([...this.selectedDepotInPbs, ...this.selectedDepotOutPbs].length > 0);
    }
  }

  public excludeProcessed() {
    if (!this.depotInProcessedDisplayState) {
      this.processedDepotInPlanningBlocks = this.depotInPlanningBlocks.filter((pb) => pb.processed === false);
    } else {
      this.processedDepotInPlanningBlocks = this.depotInPlanningBlocks;
    }
    if (!this.depotOutProcessedDisplayState) {
      this.processedDepotOutPlanningBlocks = this.depotOutPlanningBlocks.filter((pb) => pb.processed === false);
    } else {
      this.processedDepotOutPlanningBlocks = this.depotOutPlanningBlocks;
    }
  }

  public checkSize() {
    this.excludeProcessed();

    if (this.selectedList === this.CMR) {
      this.cmrService.printCMR([...this.selectedDepotInPbs, ...this.selectedDepotOutPbs]);
    } else if (this.selectedList === this.TICKET) {
      this.print();
    } else if (this.selectedList === this.WEEK_PLANNING) {
      this.print();
    } else {
      this.overMaximum = this.source === this.componentSource.depotIn
        ? this.processedDepotInPlanningBlocks.length > 750
        : this.processedDepotOutPlanningBlocks.length > 750;
      this.sizeOfSelectionToBig = (this.source === this.componentSource.depotIn
        ? this.processedDepotInPlanningBlocks.length > 250
        : this.processedDepotOutPlanningBlocks.length > 250) && !this.overMaximum;

      this.printButtonRestriction = this.sizeOfSelectionToBig || this.overMaximum;
      if (this.sizeOfSelectionToBig === false && this.overMaximum === false) {
        this.print();
      }
    }
  }
}
