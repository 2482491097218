export enum TarTasStatus {
  NotRequired = 0,
  Required = 1,
  Sent = 2,
  OK = 3,
  Error = 4,
  Cancelled = 5
}

export class TruckAppointmentLine{
  truckAppointmentNo: string;
  orderNo: string;
  containerType: string;
  containerNo: string;
  actionType: number;
  bookedDateTimeFrom: string;
  bookedDateTimeUntil: string;
  preAnnouncemenentNo: string;
  orderlineId: number;
  truckAppointmentAddressId: string;
  truckAppointmentSystem: string;
  pbEntryNo: number;
}


export interface TruckAppointment{
  no: string;
  addressSearchName: string;
  bookedDateTimeFrom: string;
  bookedDateTimeUntil: string;
  externalTruckAppointmentNo: string;
  linkedContainers: string;
  truckDescription: string;
  driverFullName: string;
}

export enum TruckAppointmentBookingTabs{
  Main,
  Swap,
  Move,
  Link
}