export const DefaultOrderLineColumnDefiniton : any [] = [
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName2',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeUntil',
].map((x) => {
    return {
      name: x ,
      width: 200,
    };
  });

export const OptionalOrderLineColumnDefiniton: any [] = [
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.planningBlockId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.lineNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sequenceNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.transportOrderId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.groupingId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.lineStatus',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actionNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressNo',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressStreet',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressPostalCode',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressCity',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressCountryCode',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineRef1',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineRef2',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.initialDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.initialDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.bookedDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.bookedDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeSource',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeSource',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.requestedDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.requestedDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.closingDate',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName2',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressSearchName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressZoneName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerType',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerSubType',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.trailerNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.chassisNumber',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.driverId',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.trailerDescription',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.chassisDescription',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.driverName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.coDriverName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.equipmentTraction',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDistanceTO',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDrivingTimeTO',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDistance',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDrivingTime',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.timeFrom',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.timeUntil',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.typeofTime',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sortingKey',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.insertActionCode',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sentToBcDateTime',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sendStatusBC' ,
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.isEmptyDistance',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.planningRemark' ,
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.totalCargoGrossWeight',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customTxtField1',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customTxtField2',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customDropDownText1',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customDropDownText2',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.reqPalletPlacesAfterOL',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.reqLoadingMetersAfterOL',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customerName',
    'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.shortcutReference1Code'

].map((x) => {
    return {
      name: x ,
      width: 200,
    };
  });


export const OrderLineDockColumnDefiniton: any [] = [
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.lineStatus',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.driverName',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.coDriverName',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actionNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressSearchName',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressPostalCode',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressCity',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.groupingId',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineRef1',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineRef2',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerType',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.containerSubType',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.trailerDescription',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.chassisNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.initialDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.initialDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.arrivalDateTime',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.requestedDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.requestedDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.bookedDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.bookedDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.plannedDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.closingDate',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDistanceTO',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDrivingTimeTO',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.truckPreviousCargo',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.reqPalletPlacesAfterOL',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.reqLoadingMetersAfterOL',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customTxtField1',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customDropDownText1',

  /*'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.lineNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sequenceNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.orderLineId',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.transportOrderId',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressNo',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressStreet',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressNumber',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressCountryCode',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.actualDateTimeSource',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.etaDateTimeSource',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.adressName2',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.addressZoneName',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.driverId',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.chassisDescription',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.equipmentTraction',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDistance',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.calculatedDrivingTime',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.timeFrom',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.timeUntil',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.typeofTime',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sortingKey',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.insertActionCode',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sentToBcDateTime',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.sendStatusBC' ,
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.isEmptyDistance',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.planningRemark' ,
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.totalCargoGrossWeight',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customTxtField2',
  'ORDERLINE_COLUMN_SELECTION.COLUMN_NAMES.customDropDownText2',*/

].map((x) => {
  return {
    name: x ,
    width: 200,
  };
});

export const ConflictsDockColumnDefiniton: any [] = [
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.conflictType',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.conflictReason',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.transportOrderId',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.orderNumber',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.orderLineNumber',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.pbId',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.creationDateTime',
  'CONFLICTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.conflictMessage'
].map((x) => {
  return {
    name: x ,
    width: 200,
  };
});

export const CargoDockColumnDefiniton: any [] = [
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.description',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.description2',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.chassis',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.quantity',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.unitOfMeasureCode',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.grossWeight',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.netWeight',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.dangerousGoods',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.adrType',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.imdgType',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.limitedQuantity',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.temperature',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.setTemperature',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.minTemperature',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.maxTemperature',
  'CARGO_DOCK_COLUMN_SELECTION.COLUMN_NAMES.highValue'
].map((x) => {
  return {
    name: x ,
    width: 200,
  };
});

export const EquipmentDockColumnDefiniton: any [] = [
  'EQUIPMENT_DOCK_COLUMN_SELECTION.COLUMN_NAMES.materialType',
  'EQUIPMENT_DOCK_COLUMN_SELECTION.COLUMN_NAMES.equipmentTypeNo',
  'EQUIPMENT_DOCK_COLUMN_SELECTION.COLUMN_NAMES.equipmentSubTypeNo',
  'EQUIPMENT_DOCK_COLUMN_SELECTION.COLUMN_NAMES.cleaningInstruction'
].map((x) => {
  return {
    name: x ,
    width: 200,
  };
});

export const DocumentsDockColumnDefiniton: any [] = [
  'DOCUMENTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.documentType',
  'DOCUMENTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.description',
  'DOCUMENTS_DOCK_COLUMN_SELECTION.COLUMN_NAMES.created'
].map((x) => {
  return {
    name: x ,
    width: 200,
  };
});
