import { Injectable } from "@angular/core";
import { Http, Response, ResponseContentType } from "@angular/http";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { environment } from "../../../../environments/environment";

@Injectable()
export class WeekPlanningListService {

    public baseUrl: string = environment.ContainerPlanningApiUri + "print";

    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }

    public printWeekPlan(name: string, startDate: string, endDate: string, type: number): any {
        const callData = {
            filterDateFrom: startDate,
            filterDateUntil: endDate,
            userName: name,
            type: {
                ID: type,
            },
        };
        return this.http.post(this.baseUrl + "/week-planning-list", callData, { responseType:ResponseContentType.Blob })
            .map((res:any) =>
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
            .catch((err) => {
                return Observable.throw(err);
            });

    }
}
