import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { ActionKeys } from '../pb-screen/action.keys';
import { SignalrBroadcaster } from '../pb-screen/helpers/signalr.broadcaster';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { ChannelEventFactory } from '../pb-screen/services/channel.event.factory';
import { ChannelService } from '../pb-screen/services/channel.service';




import { IAppState } from '../store/IAppState';
import { DepotInService } from './depot-in.service';
import { QuickFilter } from './models/depot-in.interface';

@Injectable()
export class DepotInActions extends SignalrBroadcaster {
  constructor(
    ngRedux: NgRedux<IAppState>,
    channelEventFactory: ChannelEventFactory,
    channelService: ChannelService,
  ) {
    super(ngRedux, channelEventFactory, channelService);
  }

  setDepotInSelection(depotInSelection: PlanningBlock[]) {
    super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_IN_SELECTION,
      depotInSelection
    });
  }

  public filterPlanningBlocks(searchText: string) {
    super.dispatchAction({
      type: ActionKeys.FILTER_DEPOT_IN_PLANNINGBLOCKS,
      searchText
    });
  }

  public updatePlanningBlocks(planningBlocks: PlanningBlock[]) {
    super.dispatchAndTransmitAction({
      type: ActionKeys.UPDATE_DEPOT_IN_PLANNED_PLANNING_BLOCKS,
      planningBlocks
    });
  }

  public getPlanningBlocks(planningBlocks: PlanningBlock[]) {
    super.dispatchAction({
      type: ActionKeys.REQUEST_DEPOT_IN_PLANNINGBLOCKS_SUCCESS,
      planningBlocks
    });
  }

  public quickFilterPlanningBlocks(quickFilter: QuickFilter) {
    super.dispatchAction({
      type: ActionKeys.QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS,
      quickFilter
    });
  }

  public resetQuickFilterPlanningBlocks(quickFilter?: QuickFilter) {
    super.dispatchAction({
      type: ActionKeys.RESET_QUICK_FILTER_DEPOT_IN_PLANNINGBLOCKS,
      quickFilter
    });
  }

    setDepotInCandidates() {
        super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_IN_CANDIDATES,
    });
  }

  public resetDepotInCandidates() {
      super.dispatchAndTransmitAction({
      type: ActionKeys.RESET_DEPOT_IN_CANDIDATES,
    });
  }

  public setDepotInOverbookingCandidates() {
    super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_IN_OVERBOOKING_CANDIDATES,
    });
  }

  public resetPlanningBlocks() {
    super.dispatchAction({
      type: ActionKeys.RESET_DEPOT_IN_PLANNINGBLOCKS,
    });
  }

  public setDepotInProcessedDisplayState(depotInProcessedDisplayState: boolean) {
    super.dispatchAction({
      type: ActionKeys.SET_DEPOT_IN_PROCESSED_DISPLAY_STATE,
      depotInProcessedDisplayState
    });
  }
  public setDepotInImportantOnlyDisplayState(depotInImportantOnlyDisplayState: boolean) {
    super.dispatchAction({
      type: ActionKeys.SET_DEPOT_IN_IMPORTANT_ONLY_DISPLAY_STATE ,
      depotInImportantOnlyDisplayState
    });
  }
}
