import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'sendStatusBc'
})
export class SendStatusBcPipe implements PipeTransform {

    transform(value: any, ...args: any[]) {
        let statusValue = '';
        if (value === 0) {
            statusValue = '';
        }
        if (value === 1) {
            statusValue = 'Sent';
        }
        if (value === 2) {
            statusValue = 'Received';
        }
        if (value === 3) {
            statusValue = 'Cancelled';
        }
        if (value === 4) {
            statusValue = 'Error';
        }
        if (value === 5) {
            statusValue = 'In Queue';
        }
        if (value === 6) {
            statusValue = 'Planning Started';
        }
        if (value === 7) {
            statusValue = 'Waiting';
        }
        if (value === 8) {
            statusValue = 'Finished';
        }
        if (value === 9) {
            statusValue = 'Manual Correction';
        }
        return statusValue;
    }

}