export enum ContainerStatusId {
    leegCrea = 'Exp_Ctr_Created',
    leegAf = 'Exp_Ctr_DepotOut',
    geladen = 'Exp_Ctr_Loaded',
    volKlaar = 'Exp_Ctr_Ready4Tpt',
    volIn = 'Exp_Ctr_DepotIn',
    volCrea = 'Imp_Ctr_Created',
    volAf = 'Imp_Ctr_DepotOut',
    gelost = 'Imp_Ctr_Unloaded',
    leegKlaar = 'Imp_Ctr_Ready4Tpt',
    leegIn = 'Imp_Ctr_DepotIn,ExpEmpty_Ctr_DepotIn',
    empty = '',
}
