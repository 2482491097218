import { Injectable } from "@angular/core";
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse, HttpResponse } from "@angular/common/http";
import { from, Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ToastrService } from "ngx-toastr";
import { ErrorHttpParams } from "./errror.httpparams";
import { PlanningBoardActions } from "../../planningBoard/planningBoard.action";
import { SharedActions } from "../shared.actions";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor{

    constructor(
        public readonly sharedActions: SharedActions,
        private readonly toastrService: ToastrService) {}

    intercept(request : HttpRequest<any>, next : HttpHandler) : Observable<HttpEvent<any>> {
        this.sharedActions.httpRequestPending(true);
        return next.handle(request)
            .pipe(catchError(error => from(this.handleError(error, request))))
            .pipe(map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
                if (evt instanceof HttpResponse) {
                  this.sharedActions.httpRequestPending(false);
                }
                return evt;
              })) as Observable<HttpEvent<any>>;
    }

    async handleError(error: HttpErrorResponse, request: HttpRequest<any>){
        this.sharedActions.setLoading(true);
        this.sharedActions.setLoading(false);
        this.sharedActions.httpRequestPending(false);

        if(this._shouldIgnoreError(request))
            return throwError(error).toPromise();

        var message = "";
        if(error.error?.feedback)
        {
            message = error.error.feedback[0].description;
        }
        else if(error.error?.exceptionMessage)
        {
            message = error.error.message + " " + error.error.exceptionMessage;
        }
        else if(error.error instanceof Blob){
            var text = await error.error.text();
            var json = JSON.parse(text);
            message = json.message + " " + json.exceptionMessage;
        }
        else if(error.error && typeof(error.error) === 'string') {
            message = error.error;
        }
        else {
            message = error.message;
        }
        if(message.length > 200)
            message = message.substring(0, 200) + "...";
        this.toastrService.error(message);
        return throwError(error).toPromise();
    }

    _shouldIgnoreError(request: HttpRequest<any>) {
        if (request.params instanceof ErrorHttpParams && request.params.ignoreException) {
          return true;
        }
        return false;
      }
}