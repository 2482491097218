import { PlanningBlock } from "../models/planning-block/planning-block.interface";
import { PlanningState, ToFinalizeCombi } from "../models/pb-screen.interface";
import { TO_BE_PLANNED, IN_PROGRESS, PLANNED, PRE_PLANNED, ON_HOLD, CREATED, CLOSED, RE_USED } from "../models/status-type.interface";

export function isOfSelectedPlanningState(planningBlock: PlanningBlock, planningState: PlanningState): boolean {
        if (!planningState || (!planningState.toBePlanned && !planningState.inProgress &&
            !planningState.planned && !planningState.prePlanned && !planningState.onHold &&
            !planningState.created && !planningState.closed && !planningState.reused)) {
          return true;
        }

        return planningState.toBePlanned && planningBlock.status.statusType === TO_BE_PLANNED ||
               planningState.inProgress && planningBlock.status.statusType === IN_PROGRESS ||
               planningState.planned && planningBlock.status.statusType === PLANNED ||
          planningState.prePlanned && planningBlock.status.statusType === PRE_PLANNED ||
          planningState.onHold && planningBlock.status.statusType === ON_HOLD ||
          planningState.reused && planningBlock.status.statusType == RE_USED||
          planningState.created && planningBlock.status.statusType === CREATED ||
          planningState.closed && planningBlock.status.statusType === CLOSED;
    }

export function isOfSelectedToFinalizeCombi(planningBlock: PlanningBlock , finalizeCombi: ToFinalizeCombi): boolean {
    if(!finalizeCombi || (!finalizeCombi.yes && !finalizeCombi.all && !finalizeCombi.no)) {
      return true;
    }

    return finalizeCombi.yes && planningBlock.toFinalizedCombi === true ||
           finalizeCombi.no && planningBlock.toFinalizedCombi === false ||
           finalizeCombi.all && (planningBlock.toFinalizedCombi === true || planningBlock.toFinalizedCombi === false ) ;
}
