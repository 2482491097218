import { IAppState } from "./IAppState";
import * as InitialState from './initial.state';
import { ActionKeys } from "../pb-screen/action.keys";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { DeleteToFromCapacity, TransportOrder } from "../pb-screen/models/transport-order/transport-order.interface";
import { CapacitySlot } from "../pb-screen/models/capacity-slot.interface";
import { PlanningConflict } from "../pb-screen/models/planning-conflict.interface";
import * as moment from "moment";
import { formatDateForApi, setVisibleWithingRangePBs } from "../shared/functions/orderlines.functions";

export function PlanningBoardReducer(state: IAppState = InitialState.initialState, action) {
    switch (action.type) {
      case ActionKeys.STORE_PLANNING_FOR_DATE_SUCCESS:
        return storePlanningData(state, action);
      case ActionKeys.PLANNING_CONFLICTS_FOR_DOCK:
        return storeConflictsForDock(state, action);
      case ActionKeys.STORE_UNPLANNED_PBS_SUCCESS:
        return storeUnplannedPbs(state,action)
      case ActionKeys.STORE_SELECTION_TYPE:
        return setSelectionType(state,action)
      case ActionKeys.STORE_SELECTED_QUOTES:
        return setSelectedQuotes(state, action);
      case ActionKeys.UPDATE_UNPLANNED_DISPLAY:
        return updatedUnplannedDisplay(state,action);
      case ActionKeys.UPDATE_ORDERLINES_DISPLAY: 
        return updateOrderlinesDisplay(state,action);
      case ActionKeys.SET_SELECTED_PBS_UNPLANNED : 
        return setSelectedPbsUnplanned(state,action);
      case ActionKeys.STORE_WEEK_PLANNING_DATA_SUCCESS: 
        return storeDataForWeekPlanning(state,action);
      case ActionKeys.STORE_WEEK_UNPLANNED_PBS_SUCCESS: 
        return storeWeekUnplannedPbs(state,action);
      case ActionKeys.SET_SELECTED_ORDERLINES_FROM_PLANBOARD: 
        return setSelectedOrderlinesFromPlanBoard(state,action);
        case ActionKeys.SET_SELECTED_QUOTELINES_FROM_BOARD: 
          return setSelectedQuotelinesFromPlanBoard(state,action);
      case ActionKeys.SELECT_PBS_FROM_PLAN_BOARD : 
        return setSelectedPbsFromPlanBoard(state,action);
      case ActionKeys.SET_SELECTED_CAPACITY_FROM_PLANBOARD: 
        return setSelectedCapacityFromPlanBoard(state,action);
      case ActionKeys.STORE_OPEN_TOS_FO_DATE_SUCCESS : 
        return storeOpenTosForDate(state , action);
      case ActionKeys.UPDATE_PLANNING_DISPLAY:
        return updatePlanningDisplay(state , action); 
      case ActionKeys.SET_LOADING: 
        return setLoading(state,action);
      case ActionKeys.SET_ORDERLINES_DOCK_LOADING: 
        return setOrderlinesDockLoading(state,action);
      case ActionKeys.REMOVE_TOS_FROM_CAPACITY:
        return removeToFromCapacity(state, action);
      default:
        return state;
    }

    function storePlanningData(state: IAppState, action): IAppState {
        return Object.assign({},
          state,
          {
            planningData: action.planningData,
            loading : false,
          });
    }

    function storeUnplannedPbs(state : IAppState , action) : IAppState {
      return Object.assign({} ,
        state ,
        {
          unplannedPbs : action.unplannedPbs
        })
    }

    function storeOpenTosForDate(state : IAppState , action) : IAppState {
      return Object.assign({}, state , { openTosForDate : action.openTosForDate}) ; 
    }
    
    function setLoading(state : IAppState , action) : IAppState {
      return Object.assign({} , state , { loading : action.loading} );
    }

    function setOrderlinesDockLoading(state : IAppState , action) : IAppState {
      return Object.assign({} , state , { orderlinesDockLoading: action.loading} );
    }

    function setSelectionType(state: IAppState, action): IAppState {
      return Object.assign({},
        state,
        {
          selectionType: action.selectionType
        })
    }

    function setSelectedQuotes(state: IAppState, action): IAppState {
      return Object.assign({},
        state,
        {
          selectedQuotes: action.quotes
        })
    }
    
    function setSelectedPbsFromPlanBoard(state: IAppState , action): IAppState {
      return Object.assign({}, 
        state , 
        {
          selectedPbsFromPlanBoard : action.selectedPbsFromPlanBoard
        })
    }

    function updatedUnplannedDisplay(state: IAppState , action) : IAppState {
      const usedPlanningBlocks = action.usedPlanningBlocks ; 
      const unplannedPbs = state.unplannedPbs; 
      
      const userPbsIds = usedPlanningBlocks.map(pb => pb.id);
      let newUnplannedList = unplannedPbs.filter(x =>  !userPbsIds.includes(x.id) );
      return Object.assign({},state , {
        unplannedPbs :  [...newUnplannedList]
      })
    }

    function updateOrderlinesDisplay(state: IAppState , action) : IAppState {
      const updateInput = action.updatedOrderlines.map(ol => ol.orderLineId); 
      const oldOrderlines = state.orderLines ; 
      let updatedOrderlines = [...action.updatedOrderlines , oldOrderlines.filter( x => !updateInput.includes(x.orderLineId))]
      return Object.assign({} , state , {
        orderlines : [...updatedOrderlines]
      })
    }

    function setSelectedPbsUnplanned(state: IAppState , action) : IAppState {
      return Object.assign({},
        state,
        {
          selectedPbsToPlan: [...action.selectedPbsToPlan],
        });
    }

    
    
    /* Week Planning */
    function storeDataForWeekPlanning(state: IAppState,action) : IAppState {
      return Object.assign({} , state , {weekPlanningData : action.weekPlanningData , loading : false});
    }
    function storeWeekUnplannedPbs(state : IAppState , action) : IAppState {
      return Object.assign({}, state , {unplannedPbsForWeekPlanning : action.unplannedPbsForWeekPlanning})
    }
    function setSelectedOrderlinesFromPlanBoard(state : IAppState , action) : IAppState
    {
      return Object.assign({},state , {selectedOrderlinesFromBoard : action.selectedOrderlinesFromBoard})
    }
    function setSelectedQuotelinesFromPlanBoard(state : IAppState , action) : IAppState
    {
      return Object.assign({},state , {selectedQuotelinesFromBoard : action.selectedQuotelinesFromBoard})
    }

    function updateMultiDaysPlanningDisplay(state : IAppState , action) : IAppState {
      var updatedTos = action.updatedTransportOrders ; 
      var updatedToIds = updatedTos.map(to => to.id) ; 
      var csForTos = [] ; 

      updatedTos.forEach((to) => {
        state.weekPlanningData.forEach((cs) => {
          if(cs.transportOrders) {
            if(cs.transportOrders.indexOf(to) !== -1) csForTos.push(cs); 
          }
        })
      })

      /* Deleted Transport Order */
      if(action.code = "unplan")


      return ; 
    }

    function setSelectedCapacityFromPlanBoard(state: IAppState ,action) : IAppState {
      return Object.assign({}, 
        state , 
        {
          selectedCapacityFromPlanBoard : action.selectedCapacityFromPlanBoard
        }) 
    }


    function updatePlanningDisplay(state: IAppState , action) : IAppState {
      
      let planningData = state.planningData ; 
      let weekPlanningData = state.weekPlanningData; 
      let updatedItems: TransportOrder[] = action.updatedItems; 
      let conflicts: PlanningConflict[] = [];

      if(planningData && planningData.length > 0) {
        updatedItems.forEach(updatedItem => {
          let updateToSelection = action.updateToSelection;
          if(!updatedItem)
            return;
          let capacitySlotToUpdate = planningData.filter((x) => {
            return x.truck.name == updatedItem.truck.name ; 
          })[0];

          if(capacitySlotToUpdate) {
          let toIndex = capacitySlotToUpdate.transportOrders.findIndex((x) => {
            return x.id == updatedItem.id;
          }) 

          if(toIndex == -1){
            if(moment(updatedItem.date).isSame(moment(state.applicationUser.defaultPlanDate, "DD/MM/YYYY"), 'day'))
              capacitySlotToUpdate.transportOrders.push(updatedItem);
            else{
              updatedItem = null;
            }
          }
          else
          {
            capacitySlotToUpdate.transportOrders[toIndex] = updatedItem;
            //if has pbs check if within range -> if only future or past dont show, if both show
            if(updatedItem.planningBlocks && updatedItem.planningBlocks.length)
            {
              var pbs = updatedItem.planningBlocks;
              setVisibleWithingRangePBs(updatedItem,
                formatDateForApi(state.applicationUser.defaultPlanDate),
                formatDateForApi(state.applicationUser.defaultPlanDate))

                if(updatedItem.planningBlocks && updatedItem.planningBlocks.length == 0 && (!updatedItem.hasFuturePBs || ! updatedItem.hasPastPBs))
                {
                  capacitySlotToUpdate.transportOrders.splice(toIndex, 1);
                  updateToSelection = false;
                }
                else
                  updatedItem.planningBlocks = pbs;
            }
          }
          
          if(updatedItem && updateToSelection)
            state.selectedTransportOrders = [updatedItem]; 
          }
        });

        conflicts = getUpdatedConflictsListForDock(planningData);
      }

      if(weekPlanningData && weekPlanningData.length > 0) {
        updatedItems.forEach(updatedItem => {
          //updatedItem.planningOrderlines = [] ; 
          if(!updatedItem)
            return;
          let capacitySlotToUpdate = weekPlanningData.filter((x) => {
            return x.truck.name == updatedItem.truck.name ; 
          })[0];
          
          if(capacitySlotToUpdate) {
            let toIndex = capacitySlotToUpdate.transportOrders.findIndex((x) => {
              return x.id == updatedItem.id;
            }) 
            
            if(toIndex == -1)
              capacitySlotToUpdate.transportOrders.push(updatedItem);
            else
              capacitySlotToUpdate.transportOrders[toIndex] = updatedItem;
              
            if(updatedItem && action.updateToSelection)
              state.selectedTransportOrders = [updatedItem]; 
          }

        });

        conflicts = getUpdatedConflictsListForDock(weekPlanningData);
      }

      return Object.assign({} , state , {
          planningData : planningData,
          weekPlanningData  : [...weekPlanningData],
          planningConflictsForDock: conflicts
      }); 
    }

    function removeToFromCapacity(state: IAppState , action) : IAppState {
      
      let planningData = state.planningData ; 
      let weekPlanningData = state.weekPlanningData; 
      let tosToBeDeleted: DeleteToFromCapacity[] = action.tosToBeDeleted; 
      let conflicts: PlanningConflict[] = [];

      if(planningData && planningData.length > 0) {
        tosToBeDeleted.forEach(toBeDeleted => {
          let capacitySlotToUpdate = planningData.filter((x) => {
            return x.truck.name == toBeDeleted.truckName; 
          })[0];

          if(capacitySlotToUpdate) {
            let toIndex = capacitySlotToUpdate.transportOrders.findIndex((x) => {
              return x.id == toBeDeleted.tranportOrderNo;
            }) 

            if(toIndex != -1)
              capacitySlotToUpdate.transportOrders.splice(toIndex, 1);

            state.selectedTransportOrders = []; 
          }
        });

        conflicts = getUpdatedConflictsListForDock(planningData);
      }

      if(weekPlanningData && weekPlanningData.length > 0) {
        tosToBeDeleted.forEach(toBeDeleted => {
          //updatedItem.planningOrderlines = [] ; 
          let capacitySlotToUpdate = weekPlanningData.filter((x) => {
            return x.truck.name == toBeDeleted.truckName ; 
          })[0];
          
          if(capacitySlotToUpdate) {
            let toIndex = capacitySlotToUpdate.transportOrders.findIndex((x) => {
              return x.id == toBeDeleted.tranportOrderNo;
            }) 
            
            if(toIndex != -1)
              capacitySlotToUpdate.transportOrders.splice(toIndex, 1);
           // state.selectedTransportOrders = [updatedItem]; 
          }

        });

        conflicts = getUpdatedConflictsListForDock(planningData);
      }
      return Object.assign({} , state , {
          planningData : planningData,
          weekPlanningData  : [...weekPlanningData],
          planningConflictsForDock: conflicts
      }); 
    }

    

    function storeConflictsForDock(state: IAppState ,action) : IAppState {
      return Object.assign({}, 
        state , 
        {
          planningConflictsForDock: action.conflicts
        }) 
    }


    
}

function getUpdatedConflictsListForDock(planningData: CapacitySlot[]) {
  let conflicts = [];
  planningData.forEach(cp => {
    if(cp && cp.transportOrders && cp.transportOrders.length){
      cp.transportOrders.forEach(to => {
        if (to.planningConflicts && to.planningConflicts.length > 0) {
              conflicts.push(...to.planningConflicts);
        }
      });
    }
  });
  return conflicts;
}

