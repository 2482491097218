import { NgRedux } from "@angular-redux/store";
import { Injectable } from "@angular/core";
import { ActionKeys } from "../pb-screen/action.keys";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { IAppState } from "../store/IAppState";
import { DepotOutService } from "./depot-out.service";
import { QuickFilter } from "./models/depot-out.interface";

@Injectable()
export class DepotOutActions extends SignalrBroadcaster {
  constructor(
    ngRedux: NgRedux<IAppState>,
    channelEventFactory: ChannelEventFactory,
    channelService: ChannelService
  ) {
    super(ngRedux, channelEventFactory, channelService);
  }

  public filterPlanningBlocks(searchText: string) {
    super.dispatchAction({
      type: ActionKeys.FILTER_DEPOT_OUT_PLANNINGBLOCKS,
      searchText,
    });
  }

  public quickFilterPlanningBlocks(quickFilter: QuickFilter) {
    super.dispatchAction({
      type: ActionKeys.QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS,
      quickFilter,
    });
  }

  public resetQuickFilterPlanningBlocks(quickFilter?: QuickFilter) {
    super.dispatchAction({
      type: ActionKeys.RESET_QUICK_FILTER_DEPOT_OUT_PLANNINGBLOCKS,
      quickFilter,
    });
  }

  public getPlanningBlocks(planningBlocks: PlanningBlock[]) {
    super.dispatchAction({
      type: ActionKeys.REQUEST_DEPOT_OUT_PLANNINGBLOCKS_SUCCESS,
      planningBlocks,
    });
  }

  setDepotOutSelection(depotOutSelection: PlanningBlock[]) {
    super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_OUT_SELECTION,
      depotOutSelection,
    });
  }

  public setDepotOutCandidates() {
      super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_OUT_CANDIDATES,
    });
  }

  public resetDepotOutCandidates() {
      super.dispatchAndTransmitAction({
      type: ActionKeys.RESET_DEPOT_OUT_CANDIDATES,
    });
  }

  public setDepotOutOverbookingCandidates() {
      super.dispatchAndTransmitAction({
      type: ActionKeys.SET_DEPOT_OUT_OVERBOOKING_CANDIDATES,
    });
  }

  public resetPlanningBlocks() {
    super.dispatchAction({
      type: ActionKeys.RESET_DEPOT_OUT_PLANNINGBLOCKS,
    });
  }

  public setDepotOutProcessedDisplayState(depotOutProcessedDisplayState: boolean) {
    super.dispatchAction({
      type: ActionKeys.SET_DEPOT_OUT_PROCESSED_DISPLAY_STATE,
      depotOutProcessedDisplayState
    });
  }
  public setDepotOutImportantOnlyDisplayState(depotOutImportantOnlyDisplayState: boolean) {
    super.dispatchAction({
      type: ActionKeys.SET_DEPOT_OUT_IMPORTANT_ONLY_DISPLAY_STATE ,
      depotOutImportantOnlyDisplayState
    });
  }
  public updatePlanningBlocks(planningBlocks: PlanningBlock[]) {
    super.dispatchAndTransmitAction({
      type: ActionKeys.UPDATE_DEPOT_OUT_PLANNED_PLANNING_BLOCKS,
      planningBlocks
    });
  }
}
