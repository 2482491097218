import { OnInit, Component, Input, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subject } from "rxjs";
import { TransportOrder } from "../../../../../pb-screen/models/transport-order/transport-order.interface";
import { ToScreenService } from "../../../../../pb-screen/services/to-screen.service";
import { takeUntil } from "rxjs/operators";
import { ApplicationUser } from "../../../../../pb-screen/models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'to-genral-details',
    templateUrl: './to-general-details.component.html',
})

export class ToGeneralDetailsComponent implements OnInit, OnDestroy {
    @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select('toSelectionForDetails') public toSelectionForDetails$ : Observable<TransportOrder>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;

    @Input() public transportorder: TransportOrder;
    public selectedTransportOrder: TransportOrder ; 
    destroy$ = new Subject();
    
    constructor(private readonly toService : ToScreenService) {}
    
    ngOnInit(): void {

        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });
          
        this.toSelectionForDetails$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
            if(data) {
               
                    this.selectedTransportOrder = data ; 
              
            }
        })
        this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders: TransportOrder[]) => {
            this.selectedTransportOrder = transportOrders[0];
            if(this.selectedTransportOrder) {
                this.toService.getPlanningBlocksForTO(this.selectedTransportOrder.id).subscribe((data) => {
                    this.selectedTransportOrder = data ; 
                })
            }
        })
    }

    
    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

}