import { settingAction } from './setting-modal/setting.action';
import { SharedActions } from './shared/shared.actions';
import { Component, OnInit, Inject , OnDestroy} from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';
import { VersionSettings } from './pb-screen/static/client/versionSettings';
import { DOCUMENT } from '@angular/common';
import { DynamicCaptionService, dynamicCaptions } from './shared/services/dynamic-caption.service';
import { Caption } from './static/models/caption.interface';
import { ApplicationUserActions } from './pb-screen/application.user.actions';
import { HttpClientService } from './shared/services/httpclient';
import { SharedService } from './shared/services/shared.service';
import { PlanningConflictsService } from './shared/services/planning-conflicts.service';
import { ApplicationUser, View } from './pb-screen/models/application.user.interface';
import { select } from '@angular-redux/store';
import { PbScreenService } from './pb-screen/services/pb-screen.service';
import { PlanningBoardService } from './planningBoard/services/planningBoard.service';
import { PlanningBoardActions } from './planningBoard/planningBoard.action';
import { UserSettingsService } from './pb-screen/services/usersettings.service';
import { ActivatedRoute, NavigationStart, Route, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SimpleModalService } from 'ngx-simple-modal';
import { VersionComponent } from './shared/components/modals/version-modal/version-modal.component';
import { MSAL_INSTANCE, MsalService , MsalBroadcastService } from './msal';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser'; 
import { MsalGuardConfiguration } from './msal/msal.guard.config';
import { EventMessage, EventType, InteractionType, AuthenticationResult } from '@azure/msal-browser';
import { Subject } from "rxjs";
import { MSAL_GUARD_CONFIG } from "./msal/constants";

function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId, 
      authority: environment.authority,
      redirectUri:  environment.redirectUrl 
      
    }
  });
}

import { filter, skip, takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  moduleId: module.id,
})

export class AppComponent implements OnInit , OnDestroy {
  public connectionState$: Observable<string>;
  public versionNumber: string;
  public isProduction: boolean;
  public environment = environment.envName;
  public userId: string = "";
  public thisYear = new Date().getFullYear();
  public destination: string;
  public showSettingsIcon: boolean = false;
  public showDepotInIcon: boolean = true;
  public showDepotOutIcon: boolean = true;
  public showWaitingRoom: boolean = false ; 
  public showDeliveryIcon: boolean = true;
  public showCollectionIcon: boolean = true;
  public buisnessCentralUrl: string = environment.buisnessCentralUrl;
  private readonly _destroying$ = new Subject<void>();
  public user: ApplicationUser;
  public views: View[];
  public iconType: string;
  public isIframe = false;
  public loggedIn = false;
  public requestPending: boolean = false;
  @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
  @select('azureGroups') azureGroups$: Observable<any[]>;
  @select('httpRequestPending') httpRequestPending$: Observable<boolean>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private readonly dynamicCaptionService: DynamicCaptionService,
    private readonly httpClientService: HttpClientService,
    private readonly sharedService: SharedService,
    private readonly applicationUserActions: ApplicationUserActions,
    private readonly settingActions: settingAction,
    private readonly shardActions: SharedActions,
    private readonly planningConflictsService: PlanningConflictsService,
    private pbScreenService: PbScreenService,
    private userSettingsService: UserSettingsService,
    private planningBoardService: PlanningBoardService,
    private planningBoardAction: PlanningBoardActions,
    private translate: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService , 
    
    private simpleModalService: SimpleModalService) {
    translate.setDefaultLang('en');
    translate.use('en');
  }

  public ngOnInit() {


    this.httpRequestPending$.subscribe(loading => this.requestPending = loading);

    this.isIframe = window !== window.parent && !window.opener;

    this.checkAccount();


   
      this.msalBroadcastService.msalSubject$
      .pipe(
          filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
          takeUntil(this._destroying$)
      ).subscribe((result) => {
          this.checkAccount();
          if(result.eventType == "msal:loginSuccess" ) {
          this.SetApplicationDefaults() ; 
          }
      });

 this.login();


    
  }

  SetApplicationDefaults() {
    const account = this.authService.instance.getAllAccounts()[0];

    if (!this.authService.instance.getActiveAccount()) {
        this.authService.instance.setActiveAccount(account);
    }


    
    this.iconType = environment.iconType;
    if (this.loggedIn)
      this.getUserData();
    this.azureGroups$.subscribe((groups) => {
      if (groups) {
        this.showSettingsIcon = groups.map(x => x.name.toLowerCase()).indexOf(environment.adminGroup.toLowerCase()) !== -1;
      }
    })
    this.subscribeToViewParam();
    this.subscribeToReroute();
    this.versionNumber = VersionSettings.versionNumber;
    this.isProduction = VersionSettings.isProduction;
    this.showDepotInIcon = environment.depotInIcon != null ? environment.depotInIcon : true;
    this.showDepotOutIcon = environment.depotOutIcon != null ? environment.depotOutIcon : true;
    this.showDeliveryIcon = environment.deliveryIcon != null ? environment.deliveryIcon : true;
    this.showWaitingRoom = environment.showWaitingRoom != null ? environment.showWaitingRoom : true;
    this.showCollectionIcon = environment.collectionIcon != null ? environment.collectionIcon : true;

}

  subscribeToReroute() {
    this.router.events.pipe(filter(event => event instanceof NavigationStart))
      .subscribe(el => {
        this.shardActions.resetState();
      });
  }

  showVersionModal() {
    this.simpleModalService.addModal(VersionComponent, {
      title: 'VERSION_MODAL.TITLE',
      message: 'VERSION_MODAL.MESSAGE'
    }, { closeOnEscape: false, closeOnClickOutside: false }).subscribe(() => { });
  }

  getUserData() {
    const account = this.authService.instance.getAllAccounts()[0];
    
    this.httpClientService.getToken().subscribe((token) => {
      if (account && account.localAccountId) {
        let userId = account.localAccountId;
        
        localStorage.setItem('userId', userId);
        localStorage.setItem("userName" ,account.name + "(" +  account.username + ")" ); 


        this.shardActions.storeUserAzureGroups(userId);
        this.applicationUserActions.getAppUserAndDefaultPreferences(["defaultPbUserSettings", "defaultToUserSettings",
          "defaultOrderLineUserSettings", "defaultGridIcon", "defaultDateFormat", "defaultPlanDate",
          "defaultPlanGroups", "defaultTruckDepartments", "defaultPlanZone", "defaultPlanDateRange", "defaultPlanSorting",
          "defaultAgendaDateRange", "defaultTruckAgendaDateRange", "defaultAgendaPlanningGroup", "defaultAgendaPlanningZone"
        ,"depotOutSelectedColumns","depotInSelectedColumns","stackerSelectedColumns","depotOutSavedAdvancedFilters"
      ,"depotInSavedAdvancedFilters","quickViews","planningBlocksPriority","planningGroups","siteNumber","depotInPreferences",
      "depotOutPreferences" ,"depotOutGridIcons","depotInGridIcons",
      "depotQuickViews"], userId).subscribe(
              (user) => {
                this.dynamicCaptionService.getDynamicCaptions().subscribe();
                if(user.generalSetting.useContainerStatuses)
                {
                  this.shardActions.getContainerStatuses();
                }
              });
        this.settingActions.getDefaultGlobalPreference(["globalPrintSetting", 
          "timeline",'changeSequence',"collapsedPbs"]).subscribe(userSettings => {
            let result = userSettings.find(el => el.setting.name == "timeline");
            if(result.value)
            {
              localStorage.setItem("timeLine" , result.value);
            } else {
              localStorage.setItem("timeLine" , "");
            }

            result = userSettings.find(el => el.setting.name == "collapsedPbs");
            if(result.value)
            {
              localStorage.setItem("collapsedPbs" , result.value);
            } else {
              localStorage.setItem("collapsedPbs" , "");
            }

          });
        //this.shardActions.setDefaultPlanningZone(userId);
        this.shardActions.setActivePlanningConflicts();
        this.shardActions.setTMSPlanningSetup();
        this.shardActions.storeIgnoreConflictReasons();
        this.applicationUserActions.setAzureUsers();
        this.shardActions.storePlanningZone();
        this.shardActions.getPlanningGroups();

      }
      this.userSettingsService.getViews().subscribe(views => this.views = views);
      
    })
  }

  private subscribeToViewParam() {
    const account = this.authService.getAccount();

    this.route.queryParamMap.subscribe(queryParams => {
      //refresh user only for pb-screen -> view parameter might have changed
      if (account && account.localAccountId && this.router.url.includes("/pb-screen"))
        this.applicationUserActions.setApplicationUser(account.localAccountId).subscribe();
    });
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
     
    this.checkAccount() ; 
  if (!this.loggedIn) {
    
    this.authService.loginPopup().subscribe ((() => { 
      this.checkAccount();
      if (this.loggedIn) {
          this.SetApplicationDefaults();      }
      } )) ;

      this.SetApplicationDefaults() ; 
  }

else {
    this.SetApplicationDefaults();
    }

  }

  logOut() {
    this.authService.logout();
  }

  test() {
    this.sharedService.getTMSPlanningSetup().subscribe((data) => {
      alert('ok')
    })
  }


  accessRoute(route) {
    this.router.navigate([route]);
  }

  accessPt4(page: string, isRightClick: boolean) {
    let url = environment.pt4ClientEndPointAddress + page;
    if (isRightClick)
      window.open(url, "_blank");
    else
      window.location.href = url;
  }

  openCopySettings() {
    this.applicationUserActions.setAzureUsers();
  }

  toogleMenu(ev, open) {
    let element = ev.target as Element;
    if (open)
      element.classList.add('open');
    else
      element.classList.remove('open');
  }

  onRightClick(page: string, queryParams: string = '') {
    window.open(page + queryParams);
    
    return false;
  }

  onClick(e, page: string, queryParams: string = ''){
    e.preventDefault();
    if(e.which==2) {
      window.open(page + queryParams);
    }
  }

  checkAccount() {
    this.loggedIn = this.authService.instance.getAllAccounts().length > 0;
  }

  changeRoute() {
    this.planningBoardAction.setLoading(true);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
