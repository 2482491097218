import { PlanningBlock } from "../models/planning-block/planning-block.interface";
import { Column } from "../models/application.user.interface";
import { transformColumnNamesToProperties } from "../static/columns/columName.to.property.transformation";
import { isTruthyComparison } from "../filters/advanced.filter.comparison";
import { TransportOrder } from "../models/transport-order/transport-order.interface";
import { AdvancedFilterColumnType, AdvancedFilterOperatorType } from "../../shared/components/filter-line/filter-line.interface";


export function getFilteredPlanningBlocksBySearchText(planningBlocks: PlanningBlock[],
                                                      searchText: string,
                                                      depotOutColumnSelection: Column[]): PlanningBlock[] {
    const matches: PlanningBlock[] = [];
    const columNames: string[] = transformColumnNamesToProperties(depotOutColumnSelection.map((x) => x.name));
    const splitSearchText: string[] = searchText.split(' ');
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);

    for (let planningBlock of planningBlocks) {
      let matchedAllStrings: boolean = true;
      for (let text of filteredSplitSearchText) {
        let isValidFilterOperation: boolean = false;
        for (let column of columNames) {
          if (isTruthyComparison(planningBlock[column],
            AdvancedFilterOperatorType.Contains,
            text,
            AdvancedFilterColumnType.Text)) {
            isValidFilterOperation = true;
          }
        }
        if (!isValidFilterOperation) {
          matchedAllStrings = false;
          break;
        }
      }

      if (matchedAllStrings) {
        matches.push(planningBlock);
      }
  }
    return matches;
  }

export function getFilteredTransportOrdersBySearchText(transportOrders: TransportOrder[] , searchText: string , sessionToColumnSelection: Column[]) : TransportOrder[] {
  const matches: TransportOrder[] = [];

    const splitSearchText: string[] = searchText.split(' ');
    const filteredSplitSearchText: string[] = splitSearchText.filter(t => t.length > 0);
    const columNames: string[] = transformColumnNamesToProperties(sessionToColumnSelection.map((x) => x.name));


    for (let transportOrder of transportOrders) {
      let matchedAllStrings: boolean = true;
      for (let text of filteredSplitSearchText) {
        let isValidFilterOperation: boolean = false;
        for (let column of columNames) {
          if (isTruthyComparison(transportOrder[column],
            AdvancedFilterOperatorType.Contains,
            text,
            AdvancedFilterColumnType.Text)) {
            isValidFilterOperation = true;
          }
        }
        if (!isValidFilterOperation) {
          matchedAllStrings = false;
          break;
        }
      }

      if (matchedAllStrings) {
        matches.push(transportOrder);
      }
    }
    return matches;
  }
