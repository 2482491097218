export const defaultStackerPlanningBlockColumnDefinition: any[] =
    [
      "COLUMN_SELECTION.STACKER_COLUMNS.containerPlanningType",
        
      "COLUMN_SELECTION.STACKER_COLUMNS.containerOrTrailerType",
        "COLUMN_SELECTION.STACKER_COLUMNS.containerNo",
        "COLUMN_SELECTION.STACKER_COLUMNS.containerStatus",
        "COLUMN_SELECTION.STACKER_COLUMNS.latestLocationCode",
        "COLUMN_SELECTION.STACKER_COLUMNS.fileId",
        "COLUMN_SELECTION.STACKER_COLUMNS.truckId",
        "COLUMN_SELECTION.STACKER_COLUMNS.quay",
        "COLUMN_SELECTION.STACKER_COLUMNS.closingDate",
        "COLUMN_SELECTION.STACKER_COLUMNS.depotInOutRef1",
        "COLUMN_SELECTION.STACKER_COLUMNS.orderNumber",
        "COLUMN_SELECTION.STACKER_COLUMNS.status"
       
  ].map((x) => {
  return {
    name: x,
    width: 200,
  };
});

export const optionalStackerPlanningBlockColumnDefinition: any[] =
  [
    "COLUMN_SELECTION.STACKER_COLUMNS.id",
    "COLUMN_SELECTION.STACKER_COLUMNS.info",
    //"COLUMN_SELECTION.STACKER_COLUMNS.containerPlanningType",
    "COLUMN_SELECTION.STACKER_COLUMNS.planningGroup",
    //"COLUMN_SELECTION.STACKER_COLUMNS.fileId",
    //"COLUMN_SELECTION.STACKER_COLUMNS.truckId",
    "COLUMN_SELECTION.STACKER_COLUMNS.truck",
    //"COLUMN_SELECTION.STACKER_COLUMNS.containerOrTrailerType",
    "COLUMN_SELECTION.STACKER_COLUMNS.containerOrTrailerSubType",
    "COLUMN_SELECTION.STACKER_COLUMNS.firstPlanAddressSearchName",
    "COLUMN_SELECTION.STACKER_COLUMNS.lastPlanAddressSearchName",
    //"COLUMN_SELECTION.STACKER_COLUMNS.quay",
    //"COLUMN_SELECTION.STACKER_COLUMNS.closingDate",
    "COLUMN_SELECTION.STACKER_COLUMNS.transportOrderDate",
    "COLUMN_SELECTION.STACKER_COLUMNS.closingDatePreferredFormat",
    "COLUMN_SELECTION.STACKER_COLUMNS.transportOrderDatePreferredFormat",
    "COLUMN_SELECTION.STACKER_COLUMNS.operationalStatus",
    //"COLUMN_SELECTION.STACKER_COLUMNS.containerStatus",
    //"COLUMN_SELECTION.STACKER_COLUMNS.latestLocationCode",
    "COLUMN_SELECTION.STACKER_COLUMNS.locationAddressNo",
    "COLUMN_SELECTION.STACKER_COLUMNS.customerReference",
    "COLUMN_SELECTION.STACKER_COLUMNS.bookingReference",
    "COLUMN_SELECTION.STACKER_COLUMNS.warehouseRemarks",
    "COLUMN_SELECTION.STACKER_COLUMNS.remark",
    "COLUMN_SELECTION.STACKER_COLUMNS.customerNo",
    "COLUMN_SELECTION.STACKER_COLUMNS.customerName",
    "COLUMN_SELECTION.STACKER_COLUMNS.chassisNo",
    "COLUMN_SELECTION.STACKER_COLUMNS.chassisDescription",
    "COLUMN_SELECTION.STACKER_COLUMNS.actualWeight",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotOutRef1",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotOutRef2",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotInRef1",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotInRef2",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotInAddressSearchName",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotOutAddressSearchName",
   // "COLUMN_SELECTION.STACKER_COLUMNS.depotInOutRef1",
    "COLUMN_SELECTION.STACKER_COLUMNS.depotInOutRef2",
  ].map((x) => {
  return {
    name: x,
    width: 200,
  };
});

export const stackerPlanningBlockColumnDefinition: any[] =
  [...defaultStackerPlanningBlockColumnDefinition, ...optionalStackerPlanningBlockColumnDefinition];
