import { Component, Input } from '@angular/core';
import { TransportOrder } from '../../../../pb-screen/models/transport-order/transport-order.interface';
import { ToActions } from '../../../to.actions';

@Component({
    moduleId: module.id,
    selector: 'to-selection-display',
    templateUrl: './to-selection-display.component.html'
})
export class ToSelectionDisplayComponent {
    @Input() selectedTransportOrders: TransportOrder[];

    constructor(public readonly toActions: ToActions) { }

    private popSelectedTo(transportOrder: TransportOrder) {
        const filteredArray = this.selectedTransportOrders.filter((to: TransportOrder) => to.id != transportOrder.id);
        this.toActions.setSelectedTransportOrders([...filteredArray]);
    }
}