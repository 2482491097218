export const REAR = "Rear";
export const LEFT_SIDE = "Left side";
export const RIGHT_SIDE = "Right side";
export const FLOOR = "Floor";
export const ROOF = "Roof";
export const INSIDE = "Inside";
export const TANK_BULK = "Tank Bulk";
export const REEFER = "Reefer";
export const GENERAL = "General";
export const FRONT = "Front";

export enum DamageAreas {
  "Front        ",
  "Left Side   " ,
  "Rear         ",
  "Right Side ",
  "Floor         ",
  "Roof         ",
  "Inside        ",
  "Tank Bulk ",
  "Cooling     ",
  "General    ",
}
