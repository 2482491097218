import { Injectable } from "@angular/core";
import { IAppState } from "../store/IAppState";
import { NgRedux } from "@angular-redux/store";
import { ActionKeys } from "../pb-screen/action.keys";
import { TransportOrder } from "../pb-screen/models/transport-order/transport-order.interface";
import { Column } from "../pb-screen/models/application.user.interface";
import { OrderLine } from "../pb-screen/models/order-line/orderLine.interface";
import { ToQuickFilter } from "./models/to-screen.interface";
import { ToScreenService } from "../pb-screen/services/to-screen.service";
import { Driver } from "../static/models/driver.interface";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ToastrService } from "ngx-toastr";
import { ChannelEventFactory } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { PlanningBoardService } from "../planningBoard/services/planningBoard.service";
import { PlanningBoardSignalRListener } from "../planningBoard/services/PlanningBoardSignalRListener";
import { SharedService } from "../shared/services/shared.service";


@Injectable()
export class ToActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
        private readonly sharedService: SharedService,
        private readonly planningBoardService: PlanningBoardService, 
        private readonly boardMonitoringService:PlanningBoardSignalRListener , 
        private readonly toastr: ToastrService ,
        private readonly toScreenService: ToScreenService
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    public getTransportOrders(transportOrders: TransportOrder[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.REQUEST_TRANSPORTORDERS_SUCCESS,
            transportOrders
        })
    }

    public getAllOpenTransportOrders() {
        this.toScreenService.getAllOpenTransportOrders().subscribe((transportOrders : TransportOrder[]) => {
            this.ngRedux.dispatch({
                type: ActionKeys.REQUEST_OPEN_TRANSPORTORDERS_SUCCESS,
                openTransportOrders : transportOrders
            })
        });
    }


    public resetTransportOrders() {
        this.ngRedux.dispatch({
            type: ActionKeys.RESET_TRANSPORTORDERS,
        });
    }

    public setSelectedTransportOrders(selectedTransportOrders: TransportOrder[]) {
        this.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_TRANSPORT_ORDERS,
            selectedTransportOrders
        })
    }

    //do not share selection 
    public setSelectedTransportOrdersNoShare(selectedTransportOrders: TransportOrder[]) {
        this.dispatchAction({
            type: ActionKeys.SET_SELECTED_TRANSPORT_ORDERS,
            selectedTransportOrders
        })
    }

    public setSelectedOrderlines(selectedOrderlines: OrderLine[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_SELECTED_ORDER_LINES,
            selectedOrderlines
        })
    }

    public applyGlobalFilter(searchText: string) {
        this.ngRedux.dispatch({
            type: ActionKeys.APPLY_TO_GLOBAL_FILTER,
            searchText
        });
    }

    public filterOpenTransportOrders(filterValue: string, columnFields: string[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.FILTER_OPEN_TRANSPORT_ORDERS,
            filterValue: filterValue,
            columnFields : columnFields
        });
    }

    public resetOpenTransportOrdersFilter(){
        this.ngRedux.dispatch({
            type : ActionKeys.RESET_OPEN_TRANSPORTORDERS_FILTER
        });
    }

    public updateSessionColumns(columns: Column[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.UPDATE_TO_SESSION_COLUMNS,
            columns: columns
        });
    }

    public updateOrderLineSessionColumns(columns: Column[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.UPDATE_ORDERLINE_SESSION_COLUMNS,
            columns: columns
        })
    }
    public applyQuickFilter(ToQuickFilter: ToQuickFilter) {
        this.ngRedux.dispatch({
            type: ActionKeys.APPLY_TO_QUICK_FILTER,
            ToQuickFilter
        });
    }

    public resetQuickFilter(ToQuickFilter?: ToQuickFilter) {
        this.ngRedux.dispatch({
            type: ActionKeys.RESET_TO_QUICK_FILTER,
            ToQuickFilter
        });
    }

    public setProcessedDisplayState(toProcessedDisplayState: boolean) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_TO_PROCESSED_DISPLAY_STATE,
            toProcessedDisplayState
        });
    }

    public updateTransportOrdersDisplay(transportOrders: TransportOrder[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.UPDATE_TOS_DISPLAY,
            transportOrders: transportOrders
        })
    }

    public setActionBarStates(toactionBarStates) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_TO_ACTION_BAR_STATE,
            toactionBarStates: toactionBarStates,
        });
    }

    public resetActionBarStates() {
        this.ngRedux.dispatch({
            type: ActionKeys.RESET_TO_ACTION_BAR_STATE
        });
    }

    public setFinalizedCombiState(finalizedCombiState: boolean) {
        this.ngRedux.dispatch({
          type: ActionKeys.SET_FINALIZED_COMBI_STATE,
          finalizedCombiState
        });
    }

    public selectToForDetails(toSelectionForDetails : TransportOrder) {
        this.ngRedux.dispatch({
            type: ActionKeys.SELECTED_TO_FOR_DETAILS,
            toSelectionForDetails
        })
    }
}
