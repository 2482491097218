
import {first, takeUntil} from 'rxjs/operators';
import { OnInit, Component, Input, OnDestroy } from "@angular/core";
import { Notification } from "../../../models/notification/notification.interface";
import { NotificationService } from "../../../services/notification.service";
import { SharedActions } from "../../../shared.actions";
import { ToastrService } from 'ngx-toastr';
import { ToActions } from "../../../../to-screen/to.actions";
import { SelectionEnum } from "../../../models/selection.enum";
import { TranslateService } from "@ngx-translate/core";
import { select } from '@angular-redux/store';
import { ApplicationUser } from '../../../../pb-screen/models/application.user.interface';
import { Observable, Subject } from 'rxjs-compat';


@Component({
    selector: 'notification-viewer',
    templateUrl : "./notification-viewer.component.html"
})
export class NotificationViewerComponent implements OnInit, OnDestroy {

    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    private _notifications = [];

    get Notifications(): Notification[] { 
      return this._notifications;
    }

    @Input()
    set Notifications(val: Notification[]) {
      this._notifications = val;
      this.checkedNotifications = this.checkedNotifications.filter(el => val.findIndex(value => value == el) != -1);
    }

    @Input() SelectedNotification : Notification;
    @Input() selectionType : SelectionEnum;

    private index : Number = 0;
    private selectedNotification : Notification;
    private checkedNotifications: Notification[] = [];
    destroy$ = new Subject();

    constructor(
        private readonly notificationService: NotificationService, 
        public readonly sharedActions: SharedActions,
        public readonly toActions: ToActions,
        public readonly translate: TranslateService,
        private readonly toastr: ToastrService) {
    }

    ngOnInit(): void {
        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
                          if (applicationUser) {
                            this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                          }
                        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
      }

    public SelectNotification(id)
    {
        this.SelectedNotification = this.Notifications.filter(x => x.id == id)[0];
    }

    notificationSelected(notification, event) {
        var index = this.checkedNotifications.findIndex(el => el.id == notification.id);
        if(index == -1)
            this.checkedNotifications.push(notification);
        else
            this.checkedNotifications.splice(index, 1);
    }
    
    processNotifications() {
        var ids = this.checkedNotifications.map(el => el.id).join(',');
        if(this.selectionType === SelectionEnum.PlanningBlock) {
            
            this.notificationService.processNotificationsForPb(ids, this.checkedNotifications[0].planningBlockId).subscribe(result =>
                {
                    this.sharedActions.updatePlanningBlocksDisplay([result]);
                    this.setNotificationsAsProcessed();
                },
                err => {
                    this.translateString('TOASTR_MESSAGES.ERROR.NOTIFICATION_PROCESSED_ERROR').then((result) => {
                        this.toastr.error(result);
                    });
                }
            );
        }
        else if(this.selectionType === SelectionEnum.TransportOrder) {
            
            this.notificationService.processNotificationsForTo(ids, this.checkedNotifications[0].transportOrderNumber).subscribe(result =>
                {
                    this.toActions.setSelectedTransportOrders([result]);
                    this.toActions.updateTransportOrdersDisplay([result]);
                    this.setNotificationsAsProcessed();
                },
                err => {
                    this.translateString('TOASTR_MESSAGES.ERROR.NOTIFICATION_PROCESSED_ERROR').then((result) => {
                        this.toastr.error(result);
                    });
                }
            );
        }
    }
    setNotificationsAsProcessed() {
        this.checkedNotifications.forEach(el => el.processed = true);
        this.translateString('TOASTR_MESSAGES.SUCCESS.NOTIFICATION_PROCESSED_SUCCESS').then((result) => {
            this.toastr.success(result);
        });
    }
    
    translateString(text: string) {
        return this.translate.get(text).pipe(first()).toPromise();
    }
}