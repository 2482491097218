import { TMSSelectedCMRActions } from './../TMSSelectCMRActions.interface';
import { IdNameObject } from '../id-name-object.interface';
import { PlanningBlock } from '../planning-block/planning-block.interface';
import { Remark } from '../remark.interface';
import { PlannedItem } from './plannedItem.interface';

export class TransportOrder extends PlannedItem {
    id: string;
    startAddress: string;
    endAddress: string;
    truck: IdNameObject;
    truckNo: string;
    haulier: IdNameObject;
    date: Date;
    driver: string;
    operationalStatus: string;
    toOperationalStatusType: number;
    financialStatus: string;
    planningGroup: string;
    remarks: string;
    toPlanningRemark: string;
    toHaulierRemark:string;
    firstPlanningTimeFrom: string;
    lastPlanningTimeUntil: string;
    revenue: number;
    cost: number;
    margin: number;
    marginPercentage: number;
    planningBlocks: PlanningBlock[];
    haulierNo: string;
    haulierName: string;
    seriesNo: string;
    info: string;
    firstTruckDescription: string;
    firstCoDriver: string;
    firstCoDriverFullName: string;
    firstDriver: string;
    firstDriverFullName: string;
    emptyDistance:number;
    lastOlActionColor: string;
    linkedChassis: string;
    linkedOrders: string;
    linkedTrailerContainers: string;
    selectedTransportOrder: string;
    toSentDateTime: Date;
    customerRef: string;
    bookingRef: string;
    tripType: string;
    firstPlanningZone: string;
    lastPlanningZone: string;
    errorOnBc: number;
    orderlineToSend: string;
    haulierRemarks: Remark[];
    planningRemarks: Remark[];
    highValue:string;
    dangerousGoods: boolean;
    timestamp: Date;
    hasConflicts: number;
    finalizedCombi: number;
    ordersTripTypes:string;
    truckDepartmentCode:string;
    truckDepartmentDescription:string;
    totalCalculatedDistanceTO: number ;
    totalCalculatedDrivingTimeTO: number;
    hasPBRemarks: boolean;
    hasPbsTemperatureControlled: boolean;
    hasNotifications: number;
    sentToBc: number;
    etaFrom: Date;
    etaUntil: Date;
    etaLocation: string;
    etaDate: Date;
    planningConflicts: any;
    toSentToHaulierDate: Date;
    orderlines: any;
    visibleOrderlines: any[];
    selectionSequence: number;
    planningOrderlines: any ;
    plannedItemType: number;
}

export class DeleteToFromCapacity{
    tranportOrderNo: string;
    truckName: string;
}

export enum ToOperationalStatusType {
    Created = 20,
    Closed = 23,
    InProgress = 21
}