import { Injectable, Inject } from '@angular/core';
import { Subject } from "rxjs/Subject";
import { Observable } from "rxjs/Observable";
import { Response, RequestOptions, ResponseContentType, Http } from '@angular/http';
import { ToastrService } from "ngx-toastr";
import { environment } from '../../../../environments/environment';
import { ContainerListData } from '../../models/container-list-data.interface';
import { PlanningBlock } from '../../../pb-screen/models/planning-block/planning-block.interface';


@Injectable()
export class ContainerListService {

  public baseUrl: string = environment.ContainerPlanningApiUri + "print";


    constructor(
        private readonly http: Http,
        private readonly toastr: ToastrService,
    ) { }


    public printContainerList(pbs: PlanningBlock[], name: string): any{
        const data: ContainerListData = {
           planningBlocks: [],
           name: "",
            type: {
              iD: 1,
            },
          };


        data.planningBlocks = pbs.sort((a, b) => {
          if ( a.closingDate.toString().split(" ")[0].split("/").reverse().join() >
          b.closingDate.toString().split(" ")[0].split("/").reverse().join()) { return  1; }
          if ( a.closingDate.toString().split(" ")[0].split("/").reverse().join() <
          b.closingDate.toString().split(" ")[0].split("/").reverse().join()) { return -1; }

          if (a.fileId > b.fileId) { return -1; }
          if (a.fileId < b.fileId) { return  1; }
          });

        data.name = name;

        return this.http.post(this.baseUrl + "/containerlist" , data, { responseType:ResponseContentType.Blob } )
        .map((res:any) =>
         window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" }))))
        .catch((err) => {
          return Observable.throw(err);
        });
    }

}
