import { Injectable } from "@angular/core";

import { ActionIcons } from "../action.icons.enum";
import { ComponentSource } from "../models/component-source";
import { ContainerReuseInfo } from "../models/container-reuse/container.reuse.interface";
import { IN_PROGRESS, ON_HOLD, PLANNED, RE_USED, TO_BE_PLANNED } from "../models/status-type.interface";
import { SharedActions } from "../shared.actions";
import { SharedService } from "./shared.service";
import { PoolContainerStatus } from "../../pool/models/pool-containers.interface";
import { PlanningBlock } from "../../pb-screen/models/planning-block/planning-block.interface";
import { ToastrService } from "ngx-toastr";
import { environment } from "../../../environments/environment";
import { PlanningBoardActions } from "../../planningBoard/planningBoard.action";
import { select } from "@angular-redux/store";
import { IdNameAsStringObject } from "../../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { Observable } from "rxjs";
import { ContainerStatusId } from "../../pb-screen/models/planning-block/planning-block-container-status.interface";
import { ApplicationUser } from "../../pb-screen/models/application.user.interface";
import { ContainerPlanningTypeEnum } from "../models/planning-groups-enum.interface";


@Injectable()
export class ActionBarService {
    constructor(private sharedActions: SharedActions,
        public readonly planningBoardActions: PlanningBoardActions,
        public readonly toastr: ToastrService,
        private sharedService: SharedService) { 
            this.containerStatuses$.subscribe((statuses: IdNameAsStringObject[]) => {
                this.containerStatuses = statuses;
            });
            this.applicationUser$.subscribe((data) => {
                if(data) {
                    this.user = data;
                }
            })
        }
        
    @select("applicationUser") applicationUser$ : Observable<ApplicationUser>;
    @select("containerStatuses") public containerStatuses$: Observable<IdNameAsStringObject[]>;
    
    private containerStatuses: IdNameAsStringObject[] = [];
    private user: ApplicationUser = null ; 
    private depotInSelection: PlanningBlock[];
    private depotOutSelection: PlanningBlock[];
    private componentSource = ComponentSource;
    private source: string;

    public planChecker = (element, index, array) => {

        return element.status.statusType !== TO_BE_PLANNED || element.containerPoolStatus === PoolContainerStatus.RESERVED || element.transportOrderNumber;
    }

    public updateInitialChecker = (element , index , array) => {
        return element.status.statusType !== TO_BE_PLANNED ; 
    }
    public reservationChecker = (element, index, array) => {
        const firstPb: PlanningBlock = array[0];
        const statusTypeCheck: boolean = element.status.statusType === TO_BE_PLANNED;
        return !statusTypeCheck || element.fileId !== firstPb.fileId;
    }
    public unPlanChecker = (element, index, array) => {
        return element.transportOrderNumber;
    }
    public statusChecker = (element, index, array) => {
        const firstPb: PlanningBlock = array[0];
        return element.status.statusType === firstPb.status.statusType
            && element.containerStatus === firstPb.containerStatus;
    }

    public rerouteChecker = (element, index, array) => {
        const firstPb: PlanningBlock = array[0];
        let status = this.containerStatuses.find(el => el.name == element.containerStatus);
        return (status?.id && (status?.id === ContainerStatusId.volIn || ContainerStatusId.leegIn.includes(status?.id)))
            || element.firstPlanAddressSearchName !== firstPb.firstPlanAddressSearchName
            || element.lastPlanAddressSearchName !== firstPb.lastPlanAddressSearchName;
    }

    public candidatesChecker = (element, index, array) => {
        const firstPb: PlanningBlock = array[0];
        const statusTypeBool: boolean = element.status.statusType === TO_BE_PLANNED ||
            element.status.statusType === PLANNED;
        return !statusTypeBool
            || element.firstPlanAddressSearchName !== firstPb.firstPlanAddressSearchName
            || element.lastPlanAddressSearchName !== firstPb.lastPlanAddressSearchName;
    }

    public reuseCandidatesCheckerDepotIn = (element, index, array) => {
        let status = this.containerStatuses.find(el => el.name == element.containerStatus);
        return (status?.id === ContainerStatusId.volCrea
            || status?.id === ContainerStatusId.volAf
            || status?.id === ContainerStatusId.leegKlaar
            || status?.id === ContainerStatusId.gelost)
            && element.containerReuseNo === ""
            && element.carrierId
            && element.status.statusType === TO_BE_PLANNED;
    }

    public reuseCandidatesCheckerDepotOut = (element, index, array) => {
        let status = this.containerStatuses.find(el => el.name == element.containerStatus);
        return status?.id === ContainerStatusId.leegCrea
            && element.containerReuseNo === ""
            && element.carrierId
            && element.status.statusType === TO_BE_PLANNED;
    }

    public containerPoolRequestStatusChecker = (element, index, array) => {
        return (element.status.statusType === TO_BE_PLANNED
            || element.status.statusType === PLANNED
            || element.status.statusType === IN_PROGRESS);
    }

    public containerPoolRequestOnHoldChecker = (element, index, array) => {
        return (element.status.statusType === ON_HOLD && element.containerPoolRequestNo)
            && element.containerPoolRequestNo;
    }

    public weighingActionChecker = (pb: PlanningBlock) => {
        const containerActions = pb.specificContainerActions.split("|");
        return containerActions.indexOf(ActionIcons.WEIGHING) !== -1;
    }
    public resetActionBar() {
        this.sharedActions.resetActionBarStates(false);
    }
    public setActionBar(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[], source: string): void {
        this.depotInSelection = depotInSelection;
        this.depotOutSelection = depotOutSelection;
        this.source = source;
        const mergedPbList: PlanningBlock[] = [...this.depotInSelection, ...this.depotOutSelection];
        let actionBarStates;

        actionBarStates = {
            depotInRailState: !this.depotInSelection.some(this.planChecker) && this.depotInSelection.length > 0,
            depotOutRailState: !this.depotOutSelection.some(this.planChecker) && this.depotOutSelection.length > 0,
            cmrState: this.depotInSelection.length > 0
                && mergedPbList.length !== 0,
            depotInCandidateState: this.depotInSelection.length === 1
                && !this.depotInSelection.some(this.candidatesChecker)
                && this.depotOutSelection.length === 0 && mergedPbList.length !== 0,
            depotInRerouteState: !this.depotInSelection.some(this.rerouteChecker)
                && this.depotOutSelection.length === 0
                && mergedPbList.length !== 0,
            depotInReuseCandidatesState: this.getDepotInReuseCandidatesState(this.depotInSelection, this.depotOutSelection),
            depotOutReuseCandidatesState: this.getDepotOutReuseCandidatesState(this.depotInSelection, this.depotOutSelection),
            depotInStatusState: mergedPbList.length !== 0,
            depotInUnplanState: this.depotInSelection.every(this.unPlanChecker) && this.depotInSelection.length !== 0,
            depotOutCandidateState: this.depotOutSelection.length === 1
                && !this.depotOutSelection.some(this.candidatesChecker)
                && this.depotInSelection.length === 0
                && mergedPbList.length !== 0,
            // Tom : Selection.length limited to 1 for now, has functionality for more blocks with
            // same adress, but isnt needed at the moment
            depotOutRerouteState: !this.depotOutSelection.some(this.rerouteChecker)
                && this.depotInSelection.length === 0
                && mergedPbList.length !== 0,
            depotOutStatusState: mergedPbList.length !== 0,
            depotOutUnplanState: this.depotOutSelection.every(this.unPlanChecker) && this.depotOutSelection.length !== 0,
            planState: !this.depotOutSelection.some(this.planChecker)
                && !this.depotInSelection.some(this.planChecker)
                && mergedPbList.length !== 0,
            containerInPoolDepotOutState: this.depotOutSelection.length >= 1
                && (this.depotOutSelection.every(this.containerPoolRequestStatusChecker)
                    || this.depotOutSelection.every(this.containerPoolRequestOnHoldChecker))
                && this.depotInSelection.length === 0,
            containerInPoolDepotInState: this.depotInSelection.length >= 1
                && (this.depotInSelection.every(this.containerPoolRequestStatusChecker)
                    || this.depotInSelection.every(this.containerPoolRequestOnHoldChecker))
                && this.depotOutSelection.length === 0,
            depotInWeighState: this.depotInSelection.length === 1
                && this.depotOutSelection.length === 0
                && this.weighingActionChecker(this.depotInSelection[0]),
            depotOutWeighState: this.depotOutSelection.length === 1
                && this.depotInSelection.length === 0
                && this.weighingActionChecker(this.depotOutSelection[0]),
            dossierDepotInState: this.depotInSelection.length === 1 && this.depotInSelection[0].fileId,
            dossierDepotOutState: this.depotOutSelection.length === 1 && this.depotOutSelection[0].fileId,
            depotInReservationState: !this.depotInSelection.some(this.reservationChecker),
            groupUpdateState: mergedPbList.length !== 0,
            updateInitialsState: !this.depotOutSelection.some(this.updateInitialChecker)
            && !this.depotInSelection.some(this.updateInitialChecker)
            && mergedPbList.length !== 0
        };

        this.setContainerReuseState(this.depotInSelection, this.depotOutSelection, this.source)
            .then((containerReuseState: boolean) => {
                if ([...this.depotInSelection, ...this.depotOutSelection].length !== 0) {
                    actionBarStates.containerReuseState = containerReuseState;
                    this.sharedActions.setActionBarStates(actionBarStates);
                } else {
                    this.resetActionBar();
                }
            });
    }

    public async isContainerReusable(pbs: PlanningBlock[], reuseNotified: boolean): Promise<boolean>{
        if(this.user.generalSetting.containerReuseEnabled)
        {
            this.planningBoardActions.setLoading(true);
            let pbsDepotIn = pbs.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.IN);
            let pbsDepotOut = pbs.filter(pb => pb.containerPlanningType == ContainerPlanningTypeEnum.AF);
            const isReusable = await this.isReuseable(pbsDepotIn, pbsDepotOut, ComponentSource.depotIn);
            if(isReusable && !reuseNotified)
            {
                this.notifyReuse(pbs);
                this.planningBoardActions.setLoading(false);
                return true;
            }
            this.planningBoardActions.setLoading(false);
        }
        return false;
    }

    public setContainerReuseState(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[], source: string): Promise<boolean> {
        const currentWindowPbs: PlanningBlock[] = source === ComponentSource.depotIn ? depotInSelection : depotOutSelection;
        const otherWindowPbs: PlanningBlock[] = source === ComponentSource.depotIn ? depotOutSelection : depotInSelection;
        const allPlanningBlocks: PlanningBlock[] = [...currentWindowPbs, ...otherWindowPbs];

        const promise: Promise<boolean> = new Promise<boolean>(async (resolve) => {
            if (allPlanningBlocks.length === 0) {
                resolve(false);
            } else if (allPlanningBlocks.length === 1) {
                resolve(await this.singlePlanningBlockReuseCheck(depotInSelection, depotOutSelection));
            } else if (allPlanningBlocks.length > 1 && currentWindowPbs.length !== otherWindowPbs.length) {
                if(this.source == ComponentSource.depotOut)
                {
                    resolve(await this.multiplePlanningBlocksReuseCheck([],depotOutSelection));
                } else if(this.source == ComponentSource.depotIn) {
                    resolve(await this.multiplePlanningBlocksReuseCheck(depotInSelection , []));
                } else {
                    resolve(await this.multiplePlanningBlocksReuseCheck(depotInSelection, depotOutSelection));
                }
            } else {
                resolve(await this.multiplePlanningBlocksReuseCheck(depotInSelection, depotOutSelection));
            }
        });
        return promise;
    }

    public async isReuseable(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[], source: string, selectedStatus: IdNameAsStringObject = null, statusEnabled: boolean = false) {
        let pbs = [...depotInSelection, ...depotOutSelection];
        if(!pbs.length)
            return false;
        const initialStatus = pbs[0].containerStatus;
        const planningStatusCheck = pbs
            .every((pb: PlanningBlock) => 
            {
                let pbStatus = this.containerStatuses.find(el => el.name == pb.containerStatus);
                return pbStatus?.id === ContainerStatusId.volCrea || pbStatus?.id === ContainerStatusId.leegCrea
                 || pbStatus?.id == ContainerStatusId.gelost || pbStatus?.id == ContainerStatusId.leegKlaar
                 || pbStatus?.id == ContainerStatusId.volAf;
            });
        const initialStatusId = this.containerStatuses.find(el => el.name == initialStatus)?.id;
        const statusUpdateCheck = selectedStatus?.id &&
        ((initialStatusId === ContainerStatusId.volCrea && selectedStatus?.id === ContainerStatusId.volAf)
            || (initialStatusId === ContainerStatusId.volAf && selectedStatus?.id === ContainerStatusId.leegKlaar)
            || (initialStatusId === ContainerStatusId.leegKlaar && ContainerStatusId.leegIn.includes(selectedStatus?.id)));
        const isReusable = await this.setContainerReuseState(depotInSelection, depotOutSelection, source);

        return ((statusEnabled && initialStatus !== selectedStatus?.name && statusUpdateCheck) || planningStatusCheck) ? isReusable : false;
    }
    

    public notifyReuse(selection: PlanningBlock[]) {
        if (selection.length === 1) {
            const response = `A reuse operation is possible on selected PB: <br/>
                        Order No:  ${selection[0].orderNumber}  
                        <br/> File No: ${selection[0].fileId} 
                        <br/> Container No:  ${selection[0].containerNo} 
                        <br/> Container Type: ${selection[0].containerType}
                        <br/> Carrier: ${selection[0].carrierId}`;
            this.toastr.warning(response, "", {
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false
            });
        }

        if (selection.length > 1) {
            selection.forEach((depotIn, index) => {
                const response = `A reuse operation is possible on selected PBs: <br/>
                             Order Nos: ${depotIn.orderNumber}<br/>
                             File Nos: ${depotIn.fileId}<br/>
                             Container Nos: ${depotIn.containerNo}
                             <br/> Container Types: ${depotIn.containerType}
                             <br/> Carriers: ${depotIn.carrierId}`;

                this.toastr.warning(response, "", {
                    closeButton: true,
                    enableHtml: true,
                    tapToDismiss: false
                });
            });
        }
    }

    private async singlePlanningBlockReuseCheck(depotInSelection, depotOutSelection): Promise<boolean> {
        const promise: Promise<boolean> = new Promise<boolean>((resolve) => {
            this.sharedService.getContainerReuseInfo([...depotInSelection, ...depotOutSelection][0].containerReuseNo)
                .subscribe((containerReuseInfo: ContainerReuseInfo) => {
                    if (containerReuseInfo && containerReuseInfo.requestStatus === 0) {
                        resolve(true);
                    }
                    if (containerReuseInfo && [...depotInSelection, ...depotOutSelection][0].statusType === RE_USED) {
                        resolve(true);
                    }
                    if (this.statusCheck(depotInSelection, depotOutSelection)) {
                        resolve(true);
                    } else {
                        resolve(false);
                    }
                });
        });
        return promise;
    }

    private async multiplePlanningBlocksReuseCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): Promise<boolean> {
        const promise: Promise<boolean> = new Promise<boolean>((resolve) => {
            const reuseNos: string[] = [];
            let pbs = [...depotInSelection, ...depotOutSelection];
            pbs.map((planningBlock: PlanningBlock) => {
                reuseNos.push(planningBlock.containerReuseNo);
            });

            this.sharedService.getMultipleContainersReuseInfo(reuseNos).subscribe((containerReuseInfos: ContainerReuseInfo[]) => {
                if (this.requestStatusCheck(containerReuseInfos, pbs)) {
                    resolve(true);
                }
                if (this.checkReuseRestrictions(depotInSelection, depotOutSelection)) {
                    resolve(true);
                } else {
                    resolve(false);
                }
            });
        });
        return promise;
    }

    private checkReuseRestrictions(depotInSelection, depotOutSelection) {
        return this.statusCheck(depotInSelection, depotOutSelection)
            && this.carrierCheck(depotInSelection, depotOutSelection)
            && this.typeCheck(depotInSelection, depotOutSelection)
            && this.orderNumberCheck(depotInSelection, depotOutSelection);
    }

    private requestStatusCheck(containerReuseInfos: ContainerReuseInfo[], pbs: PlanningBlock[]): boolean {
        return containerReuseInfos.every((containerReuseInfo: ContainerReuseInfo, index: number) => containerReuseInfo && (containerReuseInfo.requestStatus === 0 || pbs[index].statusType === RE_USED));
    }

    private reuseNumberCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotInSelection.every((element, index) => element.containerReuseNo === depotOutSelection[index]?.containerReuseNo);
    }

    private statusCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotOutSelection.every((planningBlock: PlanningBlock) => {
            let status = this.containerStatuses.find(el => el.name == planningBlock.containerStatus);
            return status?.id === ContainerStatusId.leegCrea && planningBlock.carrierId;
        })
            && depotInSelection.every((planningBlock: PlanningBlock) => {
                let statusId = this.containerStatuses.find(el => el.name == planningBlock.containerStatus)?.id;
                return (statusId === ContainerStatusId.volCrea ||
                statusId === ContainerStatusId.volAf 
                || statusId === ContainerStatusId.leegKlaar
                || statusId === ContainerStatusId.gelost) && planningBlock.carrierId;}
                );
    }

    private carrierCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotInSelection.every((element, index) => element.carrierId === depotOutSelection[index]?.carrierId);
    }

    private typeCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotInSelection.every((element, index) => element.containerOrTrailerType === depotOutSelection[index]?.containerOrTrailerType);
    }

    private orderNumberCheck(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotInSelection.every((element, index) => element.orderNumber !== depotOutSelection[index]?.orderNumber);
    }

    public getDepotInReuseCandidatesState(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotInSelection.every(this.reuseCandidatesCheckerDepotIn)
            && depotInSelection.length === 1
            && depotOutSelection.length === 0
            && [...depotInSelection, ...depotOutSelection].length !== 0;
    }

    public getDepotOutReuseCandidatesState(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
        return depotOutSelection.every(this.reuseCandidatesCheckerDepotOut)
            && depotInSelection.length === 0
            && depotOutSelection.length === 1
            && [...depotInSelection, ...depotOutSelection].length !== 0;
    }

}
