export enum ComponentSource {
    depotIn = "DEPOT_IN",
    depotOut = "DEPOT_OUT",
    stacker = "stacker",
    pool = "pool", 
    announcement = "announcement"
}

export enum ModalType {
    rail = "allocate-to-train-modal",
    unplan = "unplan-modal-cpl",
    dossier = "dossier-modal",
    planning = "planning-modal",
    status = "status-modal",
    wizard = "wizard-modal",
    reroute = "reroute-modal",
    overbooking = "overbooking-modal",
    containerReuse = "container-reuse-modal",
    moveToPool = "move-to-pool-modal",
    weigh = "weigh-modal",
    print = "print-modal",
    reservation = "planningBlock-reservation-modal",
    pbreservation = "pb-reservation-modal",
    updatePlanningGroups = "update-planning-groups",
    updateInitials = "update-initials-modal",
    editContainerInspection = "editContainerInspection",
    unAnnounceModal = "undoAnnounceModal",
    editContainerInfo = "editContainerInfo",
    stackerColumnSelection = "stackerColumnSelection",
    shiftContainerModal = "shiftContainerModal",
    updateContainerModal = "updateContainerModal"

}
