import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { IAppState } from '../store/IAppState';
import { ActionKeys } from './action.keys';
import { QuickFilter } from './models/pb-screen.interface';
import { PlanningBlock } from './models/planning-block/planning-block.interface';
import { Column } from './models/application.user.interface';
import { OrderLine } from './models/order-line/orderLine.interface';

import { ChannelEventFactory, ChannelEvent } from "../pb-screen/services/channel.event.factory";
import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { ChannelService } from "../pb-screen/services/channel.service";
import { PbScreenService } from './services/pb-screen.service';
import { AdvancedFilterGroup } from '../shared/components/filter-line/filter-line.interface';

@Injectable()
export class PbActions extends SignalrBroadcaster {


  constructor(
    ngRedux: NgRedux<IAppState>,
    channelEventFactory: ChannelEventFactory,
    channelService: ChannelService,
    private readonly pbService: PbScreenService

) {
    super(ngRedux, channelEventFactory, channelService);
}

  setReuseCandidates(candidates: PlanningBlock[]){
    super.dispatchAction({
      type: ActionKeys.SET_REUSE_CANDIDATES,
      candidates
    });
  }
  
  setSelectedPlanningBlocks(selectedPlanningBlocks: PlanningBlock[]) {

    super.dispatchAction({
      type: ActionKeys.SET_SELECTED_PLANNING_BLOCKS,
      selectedPlanningBlocks , 
      selectedTransportOrders : []
    });


   var sharedSelectedPlanningBlocks =  this.ngRedux.getState().sharedSelectedPlanningBlocks;
   var localSelecteddPlanningBlocsk: PlanningBlock[] ;
   if(sharedSelectedPlanningBlocks.length > 0)
      localSelecteddPlanningBlocsk =  selectedPlanningBlocks.filter( pb => sharedSelectedPlanningBlocks.findIndex(sPb=> sPb.id == pb.id) < 0 ) ;
    else
      localSelecteddPlanningBlocsk = selectedPlanningBlocks ;
    super.TransmitAction({
      type: ActionKeys.SET_Shared_SELECTED_PLANNING_BLOCKS,
       selectedPlanningBlocks: localSelecteddPlanningBlocsk,
       selectedTransportOrders : []
    }) ;
  }

  setSharedSelectedPlanningBlocks(sharedSelectedPlanningBlock: PlanningBlock[]) {

  var appState =  this.ngRedux.getState();

   var selectedPlanningBlocks = appState.selectedPlanningBlocks;
   var sharedSelectedPlanningBlocksPrev = appState.sharedSelectedPlanningBlocks;

   var localSelecteddPlanningBlocsk: PlanningBlock[] ;
   if(sharedSelectedPlanningBlocksPrev.length > 0)
      localSelecteddPlanningBlocsk =  selectedPlanningBlocks.filter( pb => sharedSelectedPlanningBlocksPrev.findIndex(sPb=> sPb.id == pb.id) < 0 ) ;
    else
      localSelecteddPlanningBlocsk = selectedPlanningBlocks ;


      super.dispatchAction({
        type: ActionKeys.SET_SELECTED_PLANNING_BLOCKS,
        selectedPlanningBlocks: localSelecteddPlanningBlocsk,
        
       selectedTransportOrders : []
      });



    super.dispatchAction({
      type: ActionKeys.SET_Shared_SELECTED_PLANNING_BLOCKS,
       selectedPlanningBlocks: sharedSelectedPlanningBlock,
       selectedTransportOrders : []
    }) ;

    super.dispatchAction({
      type: ActionKeys.SET_SELECTED_TRANSPORT_ORDERS,
      selectedTransportOrders : []
    });

 }


  public getPlanningBlocks(planningBlocks: PlanningBlock[]) {
    this.ngRedux.dispatch({
      type: ActionKeys.REQUEST_PLANNINGBLOCKS_SUCCESS,
      planningBlocks
    });
  }

  public applyQuickFilter(quickFilter: QuickFilter) {
    this.ngRedux.dispatch({
      type: ActionKeys.APPLY_QUICK_FILTER,
      quickFilter
    });
  }

  public resetQuickFilter(quickFilter?: QuickFilter) {
    this.ngRedux.dispatch({
      type: ActionKeys.RESET_QUICK_FILTER,
      quickFilter
    });
  }

  public resetPlanningBlocks() {
    this.ngRedux.dispatch({
      type: ActionKeys.RESET_PLANNINGBLOCKS,
    });
  }

  public setProcessedDisplayState(processedDisplayState: boolean) {
    this.ngRedux.dispatch({
      type: ActionKeys.SET_PROCESSED_DISPLAY_STATE,
      processedDisplayState
    });
  }
  public setImportantDisplayState(importantDisplayState: boolean) {
    this.ngRedux.dispatch({
      type: ActionKeys.SET_IMPORTANT_DISPLAY_STATE,
      importantDisplayState
    });
  }

  public setSelectedDisplayState(selectedDisplayState: boolean) {
    this.ngRedux.dispatch({
      type : ActionKeys.SET_SELECTED_DISPLAY_STATE ,
      selectedDisplayState
    })
  }
  public applyGlobalFilter(searchText: string) {
    this.ngRedux.dispatch({
      type: ActionKeys.APPLY_GLOBAL_FILTER,
      searchText
    });
  }

  public applyAdvancedFilter(filterItems: AdvancedFilterGroup): void {
    this.ngRedux.dispatch({
      type: ActionKeys.APPLY_ADVANCED_FILTER,
      filterItems,
    });
  }

  public resetAdvancedFilter(filterItems: AdvancedFilterGroup) {
    this.ngRedux.dispatch({
      type: ActionKeys.RESET_ADVANCED_FILTER,
      filterItems,
    });
  }

  public updateSessionColumns(columns: Column[]) {
    this.ngRedux.dispatch({
      type: ActionKeys.UPDATE_SESSION_COLUMNS,
      columns: columns
    });
  }


  public setActionBarStates(actionBarStates) {
    this.ngRedux.dispatch({
      type: ActionKeys.SET_ACTION_BAR_STATES,
      actionBarStates: actionBarStates,
    });
  }

  public resetActionBarStates() {
    this.ngRedux.dispatch({
      type: ActionKeys.RESET_ACTION_BAR_STATES,
    });
  }

  public setDateInterval(startDate: number, endDate: number) {
    this.ngRedux.dispatch({
      type: ActionKeys.SET_DATE_INTERVAL,
      startDate: startDate,
      endDate: endDate,
    });
  }

  public updatePlanningBlocksDisplay(updatedPlanningBlocks: PlanningBlock[]) {
    this.ngRedux.dispatch({
      type: ActionKeys.UPDATE_PLANNINGBLOCKS_DISPLAY,
      planningBlocks: updatedPlanningBlocks
    });
  }

  public setOrderlinesForPlanningBlock(pbOrderlines: OrderLine[]) {
    this.ngRedux.dispatch({
      type: ActionKeys.SET_ORDERLINES_FOR_PLANNINGBLOCK,
      pbOrderlines
    })
  }

  public storeOrderlinesForPb(pbs: PlanningBlock[]) {
    this.pbService.getPlanningBlockDetailsByIDs(pbs.map(pb => pb.id).join(',')).subscribe((data) => {
        this.setOrderlinesForPlanningBlock(data);
    })
}

  public updatePbOrderLineSessionColumns(columns: Column[]) {
    this.ngRedux.dispatch({
        type: ActionKeys.UPDATE_PBORDERLINE_SESSION_COLUMNS,
        columns: columns
    })
  }



  public storedDataForShippingRail(lineNumbers) {

    this.pbService.getShippingRailDiaryByLineNumbers(lineNumbers).subscribe((data) => {

      this.ngRedux.dispatch({
        type: ActionKeys.SET_SHIPPING_RAIL_DATA,
        shippingRailDiary : data 
      });
    })
  }
}

