
import {map,  catchError } from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClientService } from "./httpclient";
import { Observable } from "rxjs";
import { WizardDefinition } from "../models/wizard-definition";
import { environment } from "../../../environments/environment";
import { Address } from "cluster";
import { ActionWizardAddress } from "../models/action-wizard-address";
import { OrderLine } from "../../pb-screen/models/order-line/orderLine.interface";
import { PlanningBlock } from "../../pb-screen/models/planning-block/planning-block.interface";
import { ApplicationUser } from '../../pb-screen/models/application.user.interface';
import { InsertActionTemplateType } from '../models/insert-action-template-type';
import { SharedActions } from '../shared.actions';

@Injectable()
export class ActionWizardService {
    constructor(private readonly http: HttpClientService,
        public readonly sharedActions: SharedActions) {
    }

    public getPbAndToActionWizardDefinitions(currentUser: ApplicationUser, prevUser: ApplicationUser){
        if(!prevUser || !currentUser || prevUser.defaultPlanZone != currentUser.defaultPlanZone)
        {
            this.getActionWizardDefinitons(InsertActionTemplateType.toCard, currentUser.defaultPlanZone)
                .subscribe((data) => {
                    this.sharedActions.storeTOWizardActions(data);
                })

            this.getActionWizardDefinitons(InsertActionTemplateType.pbCard, currentUser.defaultPlanZone)
                .subscribe((data) => {
                    this.sharedActions.storePBWizardActions(data);
                })
        }
    }

    public getActionWizardDefinitons(filter, planninzone): Observable<WizardDefinition[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions?filter=' + filter + '&planningZone=' + planninzone).pipe(map((response: any) => {
            return response.data;
        }));
    }

    public getActionWizardAddreses(code): Observable<ActionWizardAddress[]> {
        return this.http.get(environment.tmsApiEndpointAddress +
            'wizarddefinitions/addresses'+ "?code=" + code).pipe(
            map((response: any) => {
                return response.data;
            }))
    }

    public inserActionWizard(actionWizardModel): Observable<PlanningBlock[]> {
        return this.http.post(environment.tmsApiEndpointAddress + 'wizarddefinitions', actionWizardModel).pipe(map((res: any) => {
            return res.data;
        }))
    }

    public insertActionWizardForTo(actiontionWizardModel): Observable<any> {
        return this.http.post(environment.tmsApiEndpointAddress + 'wizarddefinitions/transportorder', actiontionWizardModel).pipe(map((res : any) => {
            return res.data;
        }))
    }


    public getWizardLines(code, orderlineId): Observable<any> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions/steps?orderlineId=' + orderlineId + "&code=" + code).pipe(map((res: any) => {
            return res.data;
        }))
    }

    public getAddresses(code, lineNumber, searchFilter): Observable<ActionWizardAddress[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions/steps/' + lineNumber + '/addresses?searchFilter=' + searchFilter+ "&code=" + code).pipe(
            map((res: any) => {
                return res.data;
            }))
    }

    public executeWizard(wizardData): Observable<any> {
        return this.http.post(environment.tmsApiEndpointAddress + 'wizards', wizardData).pipe(map((res: any) => {
            return res.data;
        }))
    }

    public getOrderlineForPlanningBlock(planningBlockId, wizardCode): Observable<OrderLine> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizards/' + wizardCode + '/orderlines?planningBlockId=' + planningBlockId).pipe(map(
            (res: any) => {
                return res.data;
            }
        ))
    }

    public getOrderlineForTransportOrder(transportOrderId, wizardCode): Observable<OrderLine> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizards/' + wizardCode + '/orderlines?transportOrderId=' + transportOrderId).pipe(map(
            (res: any) => {
                return res.data;
            }
        ))
    }

    public getNewSplittedPBsCreatedByIAW(orderlineId,actionCode) : Observable<PlanningBlock[]>
    {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions/newSplittedPbs?orderlineId=' + orderlineId + '&actionCode=' + actionCode).pipe(
                map((res : any) => {
                    return res.data;
                }))
    }

    public getNewSplittedPBAndOLCreatedByIAW(orderlineId,actionCode) : Observable<PlanningBlock[]>
    {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions/newSplittedPbandOl?orderlineId=' + orderlineId + '&actionCode=' + actionCode).pipe(
                map((res : any) => {
                    return res.data;
                }))
    }

    public getFilteredShippingLineList(actionTemplate: string): Observable<ShippingLine[]> {
        return this.http.get(environment.tmsApiEndpointAddress + 'wizarddefinitions/filtered-shipping-lines?actionTemplate=' + actionTemplate)
            .pipe(map((response: any) => {
                return response.data;
            }));
    }
}
