import { PrintService } from './../../../../shared/services/print/print.service';
import { OnInit, Component, ViewChild, ElementRef, Input, OnDestroy } from '@angular/core';
import { select } from '@angular-redux/store';
import { Observable, Subject } from "rxjs";
import { ApplicationUser } from "../../../models/application.user.interface";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { ModalType } from "../../../../shared/models/source";
import { SharedActions } from "../../../../shared/shared.actions";
import { SharedService } from "../../../../shared/services/shared.service";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { ModalManagerService } from "../../../../shared/services/modal-manager.service";
import { CMRService } from "../../../services/print-CMR.service";
import { PLANNED, PRE_PLANNED } from "../../../models/status-type.interface";

import { PrintTicketService } from "../../../services/printticket.service";
import { OrderLine } from "../../../models/order-line/orderLine.interface";
import { GetCmrActionsService } from "../../../services/getCmrActions.service";
import { xmlTMSSelectCMRActionsXML, TMSSelectedCMRActions } from "../../../models/TMSSelectCMRActions.interface";
import { SimpleModalComponent } from 'ngx-simple-modal';
import { takeUntil } from 'rxjs/operators';

@Component({
    moduleId: module.id,
    selector: "print-list-modal",
    templateUrl: "print-list-modal.component.html"
})
export class PrintListComponent extends SimpleModalComponent<void, void> implements OnInit, OnDestroy {

    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;

    @select("applicationUser") public applicationUser$: Observable<ApplicationUser>;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select("processedDisplayState") public processedDisplayState$: Observable<boolean>
    @select("filteredPlanningBlocks") public filteredPlanningBlocks$: Observable<PlanningBlock[]>;

    @Input() public selectedPlanningBlocks: PlanningBlock[];
    @Input() public planningBlocks: PlanningBlock[];
    @Input() public source: string;

    public enableAutocomplete: boolean = false;
    public CMR: string = "Cmr";
    public TICKET: string = "Print Ticket";
    public WEEK_PLANNING: string = "Week Planning Report";
    public TRANSPORTORDER : string = "Transport Order Document"

    // public lists: string[] = ["Container Planning List Warehouse",
    //   "Container Planning List", this.TICKET, this.WEEK_PLANNING, this.CMR];
    public lists: string[] = []
    public selectedList: string = "";
    public overMaximum: boolean = false;
    public sizeOfSelectionToBig: boolean = false;
    public applicationUser;
    public modalType = ModalType;
    public weekPlanStartDate: string;
    public weekPlanEndDate: string;
    public selectedCmrActions: TMSSelectedCMRActions[];
    public processedPlanningBlocks: PlanningBlock[];
    public filteredPlanningBlocks: PlanningBlock[];
    public processedDisplayState: boolean;
    public pbId: number

    public printButtonRestriction: boolean = false;
    destroy$ = new Subject();

    constructor(
        public readonly sharedActions: SharedActions,
        public readonly sharedService: SharedService,
        public readonly translate: TranslateService,
        public readonly toastr: ToastrService,
        public readonly modalManager: ModalManagerService,
        public readonly cmrService: CMRService,
        public readonly printTicketService: PrintTicketService,
        public readonly getCmrActionsService: GetCmrActionsService,
        public readonly printService : PrintService
    ) {
        super();
     }

    ngOnInit(): void {
        this.planningBlocks = this.planningBlocks ? this.planningBlocks : [];
        this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.applicationUser = applicationUser;
                this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
        });
        this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.selectedPlanningBlocks = selectedPlanningBlocks;
        });

        this.processedDisplayState$.pipe(takeUntil(this.destroy$)).subscribe(function (processedDisplayState) {
            this.processedDisplayState = processedDisplayState;
        })
        this.filteredPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((filteredPlanningBlocks: PlanningBlock[]) => {
            this.filteredPlanningBlocks = filteredPlanningBlocks;
        })

        if(this.selectedList == '') {
            this.printButtonRestriction = true ;
        }

    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
      }

    documentList() {
        if (this.applicationUser) {
            this.lists = []
            if (this.sharedService.ScreenName === 'PB-Screen') {
                for (var i = 0; i < this.applicationUser.Screens.pbscreen.length; i++) {
                    this.lists.push(this.applicationUser.Screens.pbscreen[i].name);
                }

            } else {
                for (var i = 0; i < this.applicationUser.Screens.pbdetails.length; i++) {
                    this.lists.push(this.applicationUser.Screens.pbdetails[i].name);
                }
            }
            return this.lists
        }
    }

    public onCancelClicked(): void {
        this.resetUi();
    }

    public resetUi(): void {
        this.sharedActions.setUiState(true);
    }

    private closeModal(): void {
        this.resetUi();
        this.close();
    }

    public selectionChanged() {
        this.sizeOfSelectionToBig = false;
        this.overMaximum = false;
        this.printButtonRestriction = false;
        if (this.selectedList === this.CMR) {
            this.sharedActions.getCmrActionsFromBp(this.selectedPlanningBlocks[0].id);
        } else if (this.selectedList === this.TICKET) {
            this.printButtonRestriction = !([...this.selectedPlanningBlocks].length > 0);
        }
    }

    public excludeProcessed() {
        if (!this.processedDisplayState) {
            this.processedPlanningBlocks = this.planningBlocks.filter((pb) => pb.processed === false);
        } else {
            this.processedPlanningBlocks = this.planningBlocks;
        }
    }

    public checkSize() {
        this.excludeProcessed();

        if (this.selectedList === this.CMR) {
            // this.cmrService.printCMR([...this.selectedPlanningBlocks]); Oud Line
            this.print(); // my Line Koiba
        } else if (this.selectedList === this.TICKET) {
            this.print();
        } else if (this.selectedList === this.WEEK_PLANNING) {
            this.print();
        } else if(this.selectedList == this.TRANSPORTORDER) {
            this.print();
        } else {
            this.overMaximum = true
                ? this.processedPlanningBlocks.length > 750
                : this.processedPlanningBlocks.length > 750;
            this.sizeOfSelectionToBig = (true
                ? this.processedPlanningBlocks.length > 250
                : this.processedPlanningBlocks.length > 250) && !this.overMaximum;

            this.printButtonRestriction = this.sizeOfSelectionToBig || this.overMaximum;
            if (this.sizeOfSelectionToBig === false && this.overMaximum === false) {
                this.print();
            }
        }
    }

    public print() {

        if (this.selectedList === "Print Ticket") {
            const planningBlocks = [...this.selectedPlanningBlocks];
            const printData = {
                planningBlocks
            }

            const pbIds = planningBlocks.reduce((planningBlocksIds: number[], pb: PlanningBlock) => {
                planningBlocksIds.push(pb.id); return planningBlocksIds;
            }, []).join(",");

            const toNumberCheck: boolean = planningBlocks.every((planningBlock: PlanningBlock) =>
                planningBlock.transportOrderNumber === planningBlocks[0].transportOrderNumber);
            const pbsTypeCheck: boolean = planningBlocks.every((planningBlock: PlanningBlock) =>
                planningBlock.status.statusType === PLANNED || planningBlock.status.statusType === PRE_PLANNED);

            if (!toNumberCheck) {
                this.toastr.error("Planning Blocks have different transport order number");
            } else if (!pbsTypeCheck) {
                this.toastr.error("Planning Blocks need to be planned or pre-planned");
            } else {
                this.sharedService.getOrderLines(pbIds).subscribe(
                    (orderlines: OrderLine[]) => {
                        this.printTicketService.printTicket(printData, planningBlocks[0].orderNumber, orderlines, this.applicationUser)
                            .subscribe((result: any) => {
                                this.closeModal();
                            })
                    }
                )
            }

        }

        if (this.selectedList === "Shuttle List") {
            this.printService.printShuttleList(this.filteredPlanningBlocks, this.applicationUser).subscribe(
                (result: any) => {
                    this.closeModal();
                }
            )
        }

        if (this.selectedList === "Container Planning List") {

            this.printService.printContainerList(this.filteredPlanningBlocks, this.applicationUser).subscribe(
                (result: any) => {

                }
            )
        }

        if (this.selectedList === "Stacker List") {
            this.printService.printStackerList(this.filteredPlanningBlocks, this.applicationUser).subscribe(
                (result: any) => {
                    this.closeModal();
                }
            )
        }

        if (this.selectedList === "Container Planning List Warehouse") {
            this.printService.printWareHouseList(this.filteredPlanningBlocks, this.applicationUser).subscribe(
                (result: any) => {
                    this.closeModal();
                }
            )
        }

        if (this.selectedList === "Checklist Container Stuffing – Staal") {
            this.printService.printStuffingStaalList(this.filteredPlanningBlocks, this.applicationUser).subscribe(
                (result: any) => {
                    this.closeModal();
                }
            )
        }

        if (this.selectedList === this.WEEK_PLANNING) {
            const type = 1;
            this.printService.printWeekPlan(this.applicationUser, this.weekPlanStartDate, this.weekPlanEndDate, type).subscribe(
                (result: any) => {
                    this.closeModal();
                }
            )
        }
        if (this.selectedList === this.CMR) {
            if (this.selectedPlanningBlocks[0].transportOrderNumber !== '') {
                this.sharedService.myUpdatedObject.tmsSelectCMRActions.forEach(CmrAction => {
                    if (CmrAction.select[0] !== '0') {
                        let xmlTMSCmrObject: xmlTMSSelectCMRActionsXML = {
                            tmsSelectCMRActions: [CmrAction],
                            Text: ''
                        };
                        CmrAction.select[0] = "true";
                        this.getCmrActionsService.printCmrDocument(xmlTMSCmrObject,
                            this.selectedPlanningBlocks[0].id, this.selectedPlanningBlocks[0].transportOrderNumber);
                    }
                });
            } else {
                this.toastr.error("This planningBlock doesn't contains Transport Order Id");
            }
        }

        if(this.selectedList == this.TRANSPORTORDER) {

            this.selectedPlanningBlocks.forEach(pb => {
                this.printService.printtransportorder(pb.transportOrderNumber);
                });

        }

    }

    /*public printTestNewApi() {
        this.sharedService.printTestNewApi().subscribe(
            (result : any) => {
                this.toastr.success(" Printed ")
            }, 
            (error) => {
                this.toastr.error(error)
            }

        )
    }*/



}