import { Injectable } from '@angular/core';
import { HttpClientService } from '../../shared/services/httpclient';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';
import { map, catchError } from 'rxjs/operators';
import * as moment from "moment";
import { PlanningBlock } from '../../pb-screen/models/planning-block/planning-block.interface';
import { IdNameAsStringObject } from '../../pb-screen/models/quick-view/id-name-as-string-object.interface';
import { TransportOrder } from '../../pb-screen/models/transport-order/transport-order.interface';
import { DriverRemark } from '../../static/models/driverRemark.interface';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class PlanningBoardService {
  public planDateRange = {
    startDate : "",
    endDate : ""
  }
    constructor(private http: HttpClientService,
      private readonly translate: TranslateService,
      public readonly toastr: ToastrService) { }

  public getPlanningForDate(endDate: string, startDate: string, truckDepartment: string, zone: string , groups: string): Observable<any[]> {

    this.setPlannigDate(startDate);
    let params = '';
    params = '?group='+groups+'&truckDepartment='+truckDepartment+
     '&zone='+zone+'&startDate='+this.planDateRange.startDate+
     '&endDate='+ this.planDateRange.endDate + '&withOrderlines=' + false + 
     '&withQuotes=' + false; 

    return this.http.get(environment.tmsApiEndpointAddress + 'planning' + params).pipe(
      map((response: any) => response.data));

  }

  public getUnplannedPlanningBlocks(zones, groups, startDate, endDate, transportTypes) : Observable<PlanningBlock[]> {
    this.setPlannigDate(startDate)
    let params = '';

    params += '?startDate=' + this.planDateRange.startDate;
    params += '&endDate=' + this.planDateRange.endDate;
    params += '&zone=' + zones;
    
    
    if(transportTypes !== '') params += '&transportTypes=' + transportTypes;
    if(groups !== '') params += '&group=' + groups;
    

   return this.http.get(environment.tmsApiEndpointAddress + "planningblocks/unplanned" + params).pipe(map((response : any) => response.data))
  }


    public getOrderlinesForPlanningblock(pb): Observable<any> {
      if(!pb || !pb.id)
      {
        this.translate.get("TOASTR_MESSAGES.ERROR.PB_NOT_SELECTED").subscribe((translated) => {
          this.toastr.error(translated);
        });
        return;
      }
      let params = '';
      params += '?foobar=' + new Date().getTime();
      return this.http.get(environment.tmsApiEndpointAddress +  'planningblocks/' + pb.id + '/orderlines' + params).pipe(
        map((response: any) => response.data));
    }
    public getCargoForPlanningblock(planningblockId): Observable<any> {

      return this.http.get(environment.tmsApiEndpointAddress +  'planningblocks/' + planningblockId + '/cargo').pipe(
      map((response: any) => response.data));
    }
    public getEquipements(item: PlanningBlock[]): Observable<any> {
      return this.http.get(environment.tmsApiEndpointAddress + 'planningblocks/' + item.map(el => el.id).join(",") + '/equipment').pipe(
      map((response: any) => response.data));
    }

    private setDate(date) {
      const dateArray = date.split('/');
      const formattedDate = dateArray[2] + '-' + dateArray[1] + '-' + dateArray[0] ;
      return new Date(formattedDate);
    }

    private setPlannigDate(date) {
       this.planDateRange.startDate =  moment(this.setDate(date), "DD/MM/YYYY").hour(0).minute(0).seconds(0).toISOString() ;
       this.planDateRange.endDate = moment(this.setDate(date), "DD/MM/YYYY").hour(23).minute(59).seconds(59).toISOString() ;
    }

    /*Edit Order lines Helpers */

    public getDriversForHaulierNumber(haulierNo) :Observable<any>
    {
      return this.http.get(environment.tmsApiEndpointAddress + "/planningblocks/" + haulierNo + "/drivers").pipe(
      map((response: any) => response.data));
      
    }

    /* Edit Remarks Pb & TO */

    
    public updatePlanningBlockRemar(planningBlockIds , remarks) : Observable<any>
    {
      return  this.http.post(environment.tmsApiEndpointAddress + "planningblocks/remarks?planningBlockIds=" + planningBlockIds, remarks).pipe(
        map((response : any) => response.data));
    }

    public updateTransportOrderRemark(remark) : Observable<any>
    {
      return this.http.put(environment.tmsApiEndpointAddress + "transportorders/id/remarks/" + remark.lineNumber + "?toId=" + remark.transportOrderId , remark).pipe(
        map((res : any) => res.data));
    }

    public saveNewToRemark(remark) : Observable<any> 
    {
      return this.http.post(environment.tmsApiEndpointAddress + "transportorders/id/remarks?toId=" + remark.transportOrderId, remark).pipe(
        map((res : any) => res.data))
    }
    
    public getDocumentsForOrderNo(orderNo) : Observable<any> 
    {
      return this.http.get(environment.tmsApiEndpointAddress + "planning/documents?orderNo=" + orderNo).pipe(
        map((res : any) => res.data));
    }

    public downloadDocument(url)  {
       this.http.getWithConfig(environment.tmsApiEndpointAddress + "planning/download-doc?url=" + url , { responseType: "blob" })
      .subscribe((response : any) => {
        window.open(window.URL.createObjectURL(response)) ;
      });
    }

    public downloadPlanningExcel(endDate: string, startDate: string, truckDepartment: string, zone: string , groups: string)  {
      let endDateApi = moment(this.setDate(endDate), "DD/MM/YYYY").hour(23).minute(59).seconds(59).toISOString() ;
    let startDateApi = moment(this.setDate(startDate), "DD/MM/YYYY").hour(0).minute(0).seconds(0).toISOString();

    let params = '';
    params = '?group='+groups+'&truckDepartments='+truckDepartment+
     '&zone='+zone+'&startDate='+startDateApi+
     '&endDate='+ endDateApi + '&withOrderlines=' + true + '&withQuotes=' + environment.withQuotes; 

      this.http.getWithConfig(environment.tmsApiEndpointAddress + "planning/get-planning-excel" + params , { responseType: "blob" })
      .subscribe((response : any) => {
        var a = <any>document.createElement("a");
        document.body.appendChild(a);
        a.style = "display: none";
        var url = window.URL.createObjectURL(response);
        a.href = url;
        a.download = 'plan.xlsx';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
      });
    }

   /* Week - Planning */
   public getPlanningForWeekPlanning(endDate: string, startDate: string, truckDepartment: string, zone: string , groups: string): Observable<any[]> {

    let endDateApi = moment(this.setDate(endDate), "DD/MM/YYYY").hour(23).minute(59).seconds(59).toISOString() ;
    let startDateApi = moment(this.setDate(startDate), "DD/MM/YYYY").hour(0).minute(0).seconds(0).toISOString();

    let params = '';
    params = '?group='+groups+'&truckDepartment='+truckDepartment+
     '&zone='+zone+'&startDate='+startDateApi+
     '&endDate='+ endDateApi + '&withOrderlines=' + true + '&withQuotes=' + environment.withQuotes; 

    return this.http.get(environment.tmsApiEndpointAddress + 'planning' + params).pipe(
      map((response: any) => response.data));

  }

  public getUnplannedPlanningBlocksForWeekPlanning(zones, groups, startDate, endDate, transportTypes) : Observable<PlanningBlock[]> {
    let endDateApi = moment(this.setDate(endDate), "DD/MM/YYYY").hour(23).minute(59).seconds(59).toISOString() ; 
    let startDateApi = moment(this.setDate(startDate), "DD/MM/YYYY").hour(0).minute(0).seconds(0).toISOString();
    let params = '';

    params += '?startDate=' + startDateApi;
    params += '&endDate=' + endDateApi;
    params += '&zone=' + zones;
    
    
    if(transportTypes !== '') params += '&transportTypes=' + transportTypes;
    if(groups !== '') params += '&group=' + groups;
    

   return this.http.get(environment.tmsApiEndpointAddress + "planningblocks/unplanned" + params).pipe(map((response : any) => response.data))
  }

  public updateDriverRemarks(remarkInfo) : Observable<any> {
    return this.http.post(environment.tmsApiEndpointAddress + "remarks?type=0" , remarkInfo).pipe(
      map((res : any) => {res.data}))
  }

  public deleteDriverRemark(remarkInfo: DriverRemark) : Observable<any> {
    return this.http.post(environment.tmsApiEndpointAddress + "remarks/deleteDriverRemark" , remarkInfo).pipe(
      map((res : any) => {res.data}))
  }

  public getOpenTosForDate(planDateFrom , planDateUntil, planningGroup) : Observable<TransportOrder[]> {
    return this.http.get(environment.tmsApiEndpointAddress + "transportorders/open-for-date?PlanDateFrom=" + planDateFrom + "&PlanDateUntil=" + planDateUntil + "&planningGroup=" + planningGroup).pipe(map((respones : any) => respones.data)) ; 
  }

  public moveTo(transportOrderToMove , capacitySlotToMoveTo) : Observable<any> {

    return this.http.patch(environment.tmsApiEndpointAddress + "transportorders/movements" , {
      'TransportOrder': transportOrderToMove,
      'CapacitySlot': capacitySlotToMoveTo  
    }).pipe(map((res : any) => res.data)) ; 
  }

}
