export class PoolEquipmentType {
    code: string;
    description: string;

    constructor() {
        this.code = "";
        this.description = "";
    }
}

