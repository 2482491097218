import { Observable } from "rxjs";
import { PoolContainer, setContainerstatus } from "../pool/models/pool-containers.interface";
import { HttpClientService } from "../shared/services/httpclient";
import { clientSettings } from "../static/clientSettings";
import { map, catchError } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { DamageList } from "../pool/models/damageList.interface";
import { PoolMove } from "../pool/models/pool-move.interface";
import { environment } from "../../environments/environment";

@Injectable()

export class AnnouncementService {
    constructor(
        private http: HttpClientService) { 
            
        }

    public getAllPoolContainers() : Observable<PoolContainer[]> {

        return this.http.get(environment.ContainerPlanningApiUri + "announcement/get-pool-containers").pipe(
            map((res : any) => { return this.formatPoolContainers(res.data)} ), catchError((err) => { return Observable.throw(err)})
        )
    }

    public getAnnouncedPoolContainers() : Observable<PoolContainer[]> {
        
        return this.http.get(environment.ContainerPlanningApiUri + "announcement/get-announced-containers").pipe(
            map((res : any) => { return this.formatPoolContainers(res.data)} ), catchError((err) => { return Observable.throw(err)})
        )
    }

    public getAnnouncedContainerInspection(containerNo): Observable<DamageList[]> {
        return this.http.get(environment.ContainerPlanningApiUri + "announcement/get-announced-container-inspection?containerNo=" + containerNo).pipe(
            map((res : any) => { return res.data} ), catchError((err) => { return Observable.throw(err)})
        ) 
    }

    public announceContainer(data : any) : Observable<PoolContainer[]> {
        return this.http.post(environment.ContainerPlanningApiUri + "announcement/announce-container" , data)
                .pipe(map((res : any) => { return this.formatPoolContainers([res.data])} ), catchError((err) => { return Observable.throw(err)}))
    }

    public unAnnounceContainer(containerNo): Observable<PoolContainer[]> {
        return this.http.post(environment.ContainerPlanningApiUri + "announcement/undo-announce-container" , containerNo)
                .pipe(map((res : any) => { return this.formatPoolContainers(res.data)} ), catchError((err) => { return Observable.throw(err)}))
    }

    public editAnnnouncementInspections(data : any) : Observable<PoolContainer[]> {
        return this.http.post(environment.ContainerPlanningApiUri + "announcement/edit-announced-container-inspection" , data)
                .pipe(map((res : any) => { return this.formatPoolContainers([res.data])} ), catchError((err) => { return Observable.throw(err)}))
    }

    public getPreAnnouncedContainerMoves() : Observable<PoolMove[]>
    {
        return this.http.get(environment.ContainerPlanningApiUri + "announcement/get-pre-announced-container-moves")
                .pipe(map((res : any) => { return res.data}), catchError((err) => { return Observable.throw(err)}))
    }

    public updateAnnouncedContainerMove(data) : Observable<any> 
    {
        return this.http.post(environment.ContainerPlanningApiUri + "announcement/update-announced-contmove",data)
                .pipe(map((res : any) => { return res.data}) , catchError((err) => { return Observable.throw(err)}))
    }

    private formatPoolContainers(poolContainers): PoolContainer[] {
        poolContainers.forEach(element => {
            element.status = setContainerstatus(element.status)
        });
        return poolContainers;
    }
    
    public UpdateAnnouncedContainer(data) : Observable<PoolContainer[]> 
    {
        return this.http.post(environment.ContainerPlanningApiUri + "announcement/update-announced-container" , data) 
            .pipe(map((res : any) => {return this.formatPoolContainers([res.data]) }) , catchError((err) => { return Observable.throw(err)})) 
    }
}