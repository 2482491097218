import { SignalrBroadcaster } from '../pb-screen/helpers/signalr.broadcaster';
import { Injectable } from '@angular/core';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../store/IAppState';
import { ChannelEventFactory } from '../pb-screen/services/channel.event.factory';
import { ChannelService } from '../pb-screen/services/channel.service';
import { SharedService } from '../shared/services/shared.service';
import { PlanningBoardService } from './services/planningBoard.service';
import { ActionKeys } from '../pb-screen/action.keys';
import { ToastrService } from 'ngx-toastr';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { OrderLine } from '../pb-screen/models/order-line/orderLine.interface';
import { PlanningBoardSignalRListener } from './services/PlanningBoardSignalRListener';
import { Quote } from '../static/models/quote.interface';
import { DeleteToFromCapacity, TransportOrder } from '../pb-screen/models/transport-order/transport-order.interface';
import { PlanningConflict } from '../pb-screen/models/planning-conflict.interface';


@Injectable()
export class PlanningBoardActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
        private readonly sharedService: SharedService,
        private readonly planningBoardService: PlanningBoardService, 
        private readonly boardMonitoringService:PlanningBoardSignalRListener , 
        private readonly toastr: ToastrService ,
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    public storeDataForPlanningGrid(endDate: string, startDate: string, truckDepartment: string, zone: string,groups: string) {
        this.planningBoardService.getPlanningForDate(endDate,startDate,truckDepartment,zone,groups).subscribe((planningData) => {

            this.ngRedux.dispatch({
                type: ActionKeys.STORE_PLANNING_FOR_DATE_SUCCESS,
                planningData
              });
              
        })
      
    }


    public storeUnplannedPlanningBlocks(zones, groups, startDate, endDate, transportTypes, createMonitor = true)
    {
        this.planningBoardService.getUnplannedPlanningBlocks(zones, groups, startDate, endDate, transportTypes).subscribe((unplannedPbs) => {
            this.ngRedux.dispatch({
                type : ActionKeys.STORE_UNPLANNED_PBS_SUCCESS,
                unplannedPbs
            })
            if(createMonitor)
                this.boardMonitoringService.createUnassignedPlanningblocksMonitor(startDate ,endDate , zones , groups) ; 
            
        })
       
    }

    public storeOpenTosForDate(planningDateFrom , planningDateUntil, planningGroups) {
        this.planningBoardService.getOpenTosForDate(planningDateFrom , planningDateUntil, planningGroups).subscribe((openTosForDate) => {
           this.ngRedux.dispatch({
                type : ActionKeys.STORE_OPEN_TOS_FO_DATE_SUCCESS,
                openTosForDate
            })
        })
    }

    public updatedUnplannedDisplay(usedPlanningBlocks) 
    {
        this.ngRedux.dispatch({
            type : ActionKeys.UPDATE_UNPLANNED_DISPLAY,
            usedPlanningBlocks : usedPlanningBlocks     
        })
    }

    public updateOrderlinesDisplay(updatedOrderlines) {
    
        this.ngRedux.dispatch({
            type : ActionKeys.UPDATE_ORDERLINES_DISPLAY,
            updatedOrderlines : updatedOrderlines
        })
    }

    public setSelectedPbsUnplanned(planningBlocks : PlanningBlock[])
    {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_PBS_UNPLANNED,
            selectedPbsToPlan : planningBlocks
        })
    }

    public setSelectedPbsFromPlanBoard(selectedPbsFromPlanBoard)
    {
        super.dispatchAndTransmitAction({
            type : ActionKeys.SELECT_PBS_FROM_PLAN_BOARD , 
            selectedPbsFromPlanBoard
        })
    }

    //


    public setSelectionType (selectionType)
    {
        this.ngRedux.dispatch({
            type : ActionKeys.STORE_SELECTION_TYPE,
            selectionType
        });
    }

    public setSelectedQuotes (quotes: Quote[])
    {
        this.ngRedux.dispatch({
            type : ActionKeys.STORE_SELECTED_QUOTES,
            quotes
        });
    }

    /* Week Planning */
    public storeDataForWeekPlanning(endDate: string, startDate: string, truckDepartment: string, zone: string,groups: string) 
    {
        this.planningBoardService.getPlanningForWeekPlanning(endDate,startDate,truckDepartment,zone,groups).subscribe((weekPlanningData) => {
            this.ngRedux.dispatch({
                type : ActionKeys.STORE_WEEK_PLANNING_DATA_SUCCESS, 
                weekPlanningData
              });
        })
       
    }

    public storeUnplannedPlanningBlocksForWeekPlanning(zones, groups, startDate, endDate, transportTypes)
    {
        this.planningBoardService.getUnplannedPlanningBlocksForWeekPlanning(zones, groups, startDate, endDate, transportTypes).subscribe((unplannedPbsForWeekPlanning) => {
            this.ngRedux.dispatch({
                type : ActionKeys.STORE_WEEK_UNPLANNED_PBS_SUCCESS,
                unplannedPbsForWeekPlanning
            })
        })
       
    }

    public setSelectedOrderlinesFromPlanBoard(selectedOrderlinesFromBoard: any[]) 
    {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_ORDERLINES_FROM_PLANBOARD, 
            selectedOrderlinesFromBoard
        })
    }

    public updateMultidaysPlanningDisplay(updatedTransportOrders: any[]) {
        this.ngRedux.dispatch({
            type : ActionKeys.UPDATE_MULTIDAYS_PLANNING_DISPLAY,
            updatedTransportOrders
        })
    }

    public setSelectedCapacityFromPlanBoard(selectedCapacityFromPlanBoard : any) 
    {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_CAPACITY_FROM_PLANBOARD, 
            selectedCapacityFromPlanBoard
        })
    }

    /* Planning Board Monitor Actions */

    public updateUnplannedPbsSelection(source,data : PlanningBlock[])
    {
       
        var appState =  this.ngRedux.getState();  

        var oldSelection = appState.selectedPbsToPlan ; 

        if(oldSelection.length > 0) {
            if(source == "update")
            data.forEach((item) => {
                let oldPb = oldSelection.filter((pb) => {return pb.id == item.id})[0]; 
                if(oldPb) {
                    let index = oldSelection.indexOf(oldPb); 
                    oldSelection[index] = item ; 
                }
            }) 
            if(source == "delete")
            {
                /*data.forEach((item) => {
                    oldSelection = oldSelection.filter((pb) => {return pb.id == item.id}); 
                    
                }) */

                oldSelection = oldSelection.filter((pb) => {return  data.filter((item) => {return item.id == pb.id}).length !== 0 })
            }
            
            
        }

        
        

        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_PBS_UNPLANNED , 
            selectedPbsToPlan : oldSelection
        })
    }

    public updatePlanningBoardDisplay(action , data) {
        if(action == ActionKeys.STORE_PLANNING_FOR_DATE_SUCCESS)
        {
            this.ngRedux.dispatch({
                type: ActionKeys.STORE_PLANNING_FOR_DATE_SUCCESS,
                planningData : data
              });
        }

        if(action == ActionKeys.STORE_UNPLANNED_PBS_SUCCESS) {

            this.ngRedux.dispatch({
                type : ActionKeys.STORE_UNPLANNED_PBS_SUCCESS,
                unplannedPbs : data
            })
            
        }
    }

    public UpdatePlanningDisplay(updatedItems: TransportOrder[], updateToSelection: boolean = true) {
        this.dispatchAndTransmitAction({
            type : ActionKeys.UPDATE_PLANNING_DISPLAY,
            updatedItems : updatedItems,
            updateToSelection
        })
    }

    public removeTosFromCapacity(tosToBeDeleted: DeleteToFromCapacity[]) {
        this.dispatchAndTransmitAction({
            type : ActionKeys.REMOVE_TOS_FROM_CAPACITY,
            tosToBeDeleted
        })
    }

    public setLoading(loading) {
        this.dispatchAction({
            type : ActionKeys.SET_LOADING,
            loading : loading
        })
    }

    public setOrderlinesDockLoading(loading: boolean) {
        this.dispatchAction({
            type : ActionKeys.SET_ORDERLINES_DOCK_LOADING,
            loading: loading
        })
    }

    public setSelectedQuotelinesFromBoard(selectedQuotelinesFromBoard: any[]) 
    {
        this.ngRedux.dispatch({
            type : ActionKeys.SET_SELECTED_QUOTELINES_FROM_BOARD, 
            selectedQuotelinesFromBoard
        })
    }

    public setPlanningConflictsForDock(conflicts: PlanningConflict[]) 
    {
        this.ngRedux.dispatch({
            type : ActionKeys.PLANNING_CONFLICTS_FOR_DOCK, 
            conflicts
        })
    }


 }
