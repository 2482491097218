import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs/Subject';

@Injectable()
export class TableStateService {
  private resetTableSelectionSource = new Subject<boolean>();

  resetTableSelectionSource$ = this.resetTableSelectionSource.asObservable();

  resetTableSelection(reset: boolean){
    this.resetTableSelectionSource.next(reset);
  }
}