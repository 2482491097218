import { Component, Input } from "@angular/core";

import { ComponentSource, ModalType } from "../../models/component-source";
import { PlanningBlock } from "../../models/planning-block/planning-block.interface";

@Component({
    selector: "selection-display-status",
    templateUrl: "./selection-display-change-status.component.html",
})

export class SelectionDisplayComponentStatus {

    @Input() public selectedPlanningBlocks: PlanningBlock[];
    @Input() public source: string;
    @Input() public modalType: string;

    public componentSource = ComponentSource;
    public parentModalType = ModalType;
}
