import { OnInit, Component, Input, TemplateRef, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { PlanningBlock } from "../../../../models/planning-block/planning-block.interface";
import { PbScreenService } from "../../../../services/pb-screen.service";
import { Observable, Subject } from "rxjs";
import {
  ColumnReorderEvent,
  ColumnResizeArgs,
  DataStateChangeEvent,
  GridDataResult,
  SelectableSettings,
} from '@progress/kendo-angular-grid';

import { ApplicationUser, Column } from "../../../../models/application.user.interface";
import { TransportOrder } from "../../../../models/transport-order/transport-order.interface";
import { TranslateService } from "@ngx-translate/core";
import { DefaultOrderLineColumnDefiniton } from "../../../../static/columns/OrderLineColumnDefinition";
import { orderBy, SortDescriptor, State } from '@progress/kendo-data-query';
import { ToActions } from "../../../../../to-screen/to.actions";
import { TableStateService } from "../../../../services/table.state.service";
import { ApplicationUserActions } from "../../../../application.user.actions";
import { ActionIcons, hasActionIcon } from "../../../../models/action.icons.enum";
import { SharedActions } from "../../../../../shared/shared.actions";
import { OrderLine } from "../../../../models/order-line/orderLine.interface";
import { ToastrService } from "ngx-toastr";
import { PbActions } from "../../../../pb.actions";
import { takeUntil } from "rxjs/operators";


@Component({
  moduleId: module.id,
  selector: 'pb-orderlines-table',
  templateUrl: './pb-orderlines-table.component.html',
})
export class PbOrderlinesComponent implements OnInit, OnDestroy {
  @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
  @select('sessionPbOrderLineColumnSelection') public sessionPbOrderLineColumnSelection$: Observable<Column[]>;
  @select('selectedOrderlines') public selectedOrderlines$: Observable<OrderLine[]>;
  @select('selectedPbsFromPlanBoard') selectedPbsFromPlanBoard$: Observable<PlanningBlock[]>
  @select('pbOrderlines') public pbOrderlines$: Observable<OrderLine[]>;
  @select("selectedPbsToPlan") public selectedPbsToPlan$ : Observable<PlanningBlock[]>;
  @select('loading') public loading$: Observable<boolean>;
  destroy$ = new Subject();

  public loadingSpinner: boolean = false;
  public selectedTransportOrder: TransportOrder;
  public selectedPb: PlanningBlock;
  public selectedOrderlines: OrderLine[] = [];
  public orderLines: OrderLine[] = [];
  public tableData: GridDataResult;
  public windowWidth: number;
  public tableWidth: number;
  public windowHeight: number;
  public tableHeight: number;
  public tooltipOffset = { left: 0, top: 0 };
  public tooltipVisible = false;
  public tooltipValue = '';
  public GRID_RATIO = 0.833;
  public GRID_FILLER_SPACE = 82.8;
  public processedDisplayState: boolean;
  public gridUser: any;
  // ngx-datatable
  public rows = [];
  public columns = [];
  public state: State = {
    skip: 0,
    take: 50,
  };
  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'multiple',
  };
  public user: ApplicationUser;
  // tslint:disable-next-line:variable-name - backing field should have an underscore
  public _resetSelectedRows: boolean;
  public mySelection: OrderLine[] = [];
  public mySelectionKeys: number[] = [];

  get resetSelectedRows(): boolean {
    return this._resetSelectedRows;
  }

  @Input('resetSelectedRows')
  set resetSelectedRows(value: boolean) {
    this._resetSelectedRows = value;
  }
  private fallbackLanguage: string = 'en';
  private languageToUse: string = 'en';

  public pageSize = 10;
  public skip = 0;

  @ViewChild('dateTemplate') public dateTemplate: TemplateRef<any>;
  @ViewChild('tableParent') tableParent: ElementRef;

  constructor(private readonly pbScreenService: PbScreenService,
    private readonly translate: TranslateService,
    private readonly toAction: ToActions,
    public readonly tableStateService: TableStateService,
    public readonly userActions: ApplicationUserActions,
    private readonly sharedAction: SharedActions,
    private readonly pbActions: PbActions,
    private readonly toastr: ToastrService) { }

  ngOnInit(): void {
    this.tableData = {
      data: [],
      total: 0
    };

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
    this.sessionPbOrderLineColumnSelection$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.columns = this.createDataTablesColumns(data);

    });
    this.selectedPlanningBlocks$.pipe(takeUntil(this.destroy$)).subscribe((planningBlocks: PlanningBlock[]) => {
      this.selectedPb = planningBlocks[0];
    })

    this.selectedPbsFromPlanBoard$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.selectedPb = data[0];
      }

    })

    this.selectedPbsToPlan$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if(data && data.length)
      {
        this.selectedPb = data[0];
        this.loadingSpinner = true;
        this.pbScreenService.getPlanningBlockDetailsByID(data[0].id).subscribe((data) => {
          this.pbActions.setOrderlinesForPlanningBlock(data);
        })
      }
  })

    this.pbOrderlines$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (data) {
        this.orderLines = data;
        this.setGridContent();
        this.loadingSpinner = false;
      }
    })

    this.selectedOrderlines$.pipe(takeUntil(this.destroy$)).subscribe((orderlines: OrderLine[]) => {

      if (orderlines) {
        this.selectedOrderlines = orderlines;

      }
      if (this.selectedOrderlines.length === 0) {
        this.mySelection = [];
        this.mySelectionKeys = [];

      }

    })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public setGridContent(): void {
    this.tableData = {
      data: [...this.orderLines.slice(this.state.skip, this.state.skip + this.state.take)],
      total: this.orderLines.length,
    };
  }

  public createDataTablesColumns(selectedColumns: Column[]): string[] {
    const result = [];
    if (selectedColumns && selectedColumns.length > 0) {
      for (const column of selectedColumns) {

        this.translate.get(column.name).subscribe((translated) => {

          result.push({
            cellTemplate: this.dateTemplate,
            name: translated,
            prop: column.name.substring(column.name.lastIndexOf('.') + 1),
            width: column.width,
          });
        });
      }
    }
    return result;
  }
  public refreshTableWidth(): void {
    this.tableWidth = this.tableParent.nativeElement.offsetWidth;
  }

  public refreshTableDisplay(): void {
    this.state.skip = 0;
    this.setGridContent();
  }


  public changeDates(tos: OrderLine[], prop: string): OrderLine[] {
    tos.forEach((to: OrderLine) => {
      if (to[prop].length) {
        to[prop] = to[prop].toString().split(' ')[0].split('/')
          .reverse().join(',') + ' ' + to[prop].toString().split(' ')[1];
      }
    });
    return tos;
  }

  public changeDatesBack(tos: OrderLine[], prop: string): OrderLine[] {
    tos.forEach((to: OrderLine) => {
      if (to[prop].length) {
        to[prop] = to[prop].toString().split(' ')[0].split(',')
          .reverse().join('/') + ' ' + to[prop].toString().split(' ')[1];
      }
    });
    return tos;
  }




  public moveElementInArray(array, reorderedColumnIndex, differencePos) {
    const value = array[reorderedColumnIndex];
    const arrayClone = array.slice();
    const newpos = reorderedColumnIndex + -(differencePos);
    arrayClone.splice(reorderedColumnIndex, 1);
    arrayClone.splice(newpos, 0, value);

    return arrayClone;
  }

  // documentation does not specify a type for event


  public setGridDimensions(): void {
    this.windowHeight = window.screen.height;
    //this.tableHeight = this.windowHeight >= 1080 ? this.windowHeight * this.GRID_RATIO : 480;
    const rowHeight = (this.tableHeight - this.GRID_FILLER_SPACE) / this.state.take;
    document.querySelector('body').style.cssText = '--row-height: ' + rowHeight + 'px';
    this.refreshTableWidth();
  }

  public showTooltip(event: any) {
    this.tooltipOffset = { left: event.pageX + 4, top: event.pageY + 4 };
    this.tooltipValue = event.srcElement.innerText;
    if (this.tooltipValue.length > 0 &&
      (event.srcElement.localName === 'td' || event.srcElement.localName === 'a')
      && (event.srcElement.offsetWidth < event.srcElement.scrollWidth)) {
      this.tooltipVisible = true;
    }
  }

  public hideTooltip() {
    this.tooltipVisible = false;
  }

  public pageChange(event: DataStateChangeEvent): void {
    this.state.skip = event.skip;

    this.setGridContent();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    const prop = this.state.sort[0].field;
    let tos: OrderLine[] = this.orderLines;

    this.orderLines = orderBy([...this.orderLines], sort);

    this.setGridContent();
  }
  public reorderGridUser(event: ColumnReorderEvent) {
    const column: any = event.column;
    const reorderedColumnIndex = this.user.orderlinePreferences.OrderLineColumnSelection
      .indexOf(this.user.orderlinePreferences.OrderLineColumnSelection
        .filter((x) => x && x.name.substring(x.name.lastIndexOf('.') + 1) === column.field)[0]);

    if (reorderedColumnIndex !== -1) {
      const differencePos = event.oldIndex - event.newIndex;
      if (differencePos) {
        const updatedArray =
          this.moveElementInArray(this.user.orderlinePreferences.OrderLineColumnSelection, reorderedColumnIndex, differencePos);
        this.user.orderlinePreferences.OrderLineColumnSelection = updatedArray;
        this.userActions.updateApplicationUser(this.user, 'defaultOrderLineUserSettings', true);
      }
    } else {
      this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_NOT_FOUND_REORDER_FAILED').subscribe((translated) => {
        this.toastr.warning(translated);
      });
    }
  }

  public resizeGrid(event: ColumnResizeArgs[]) {
    if (this.isTheResizedColumnValid(event)) {
      const column: any = event[0].column;
      const resizedColumnIndex = this.user.orderlinePreferences.OrderLineColumnSelection
        .indexOf(this.user.orderlinePreferences.OrderLineColumnSelection
          .filter((x) => x && x.name.substring(x.name.lastIndexOf('.') + 1) === column.field)[0]);

      if (resizedColumnIndex !== -1) {
        if (this.isNewWidthValid(event[0].newWidth)) {
          this.user.orderlinePreferences.OrderLineColumnSelection[resizedColumnIndex].width = event[0].newWidth;
          //this.userActions.updateApplicationUser(this.user);
        } else {
          // this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_INVALID_WIDTH_RESIZE_FAILED').subscribe((translated) => {
          //   this.toastr.warning(translated);
          // });
        }
      } else {
        // this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_NOT_FOUND_RESIZE_FAILED').subscribe((translated) => {
        //   this.toastr.warning(translated);
        // });
      }
    }
  }

  private isTheResizedColumnValid(event: ColumnResizeArgs[]): boolean {
    return event.length === 1 && event[0].hasOwnProperty('column');
  }

  private isNewWidthValid(newWidth: number): boolean {
    return newWidth > 0;
  }

  public rowClicked(gridUser, selection): void {

    if (selection.selectedRows && selection.selectedRows.length > 0) {
      this.mySelection = [...this.mySelection.concat(selection.selectedRows.map((x) => x.dataItem))];
    }

    if (selection.deselectedRows && selection.deselectedRows.length > 0) {
      const deselectedOrderlines = selection.deselectedRows.map((x) => x.dataItem);
      this.mySelection = [...this.mySelection.filter((x) => deselectedOrderlines.indexOf(x) === -1)];
    }

    this.selectedOrderlines = this.mySelection;
    this.toAction.setSelectedOrderlines(this.selectedOrderlines)

  }
  public hasScanningAction(specificContainerActions: string): boolean {
    return hasActionIcon(specificContainerActions, ActionIcons.SCANNING);
  }

  public hasCustomsAction(specificContainerActions: string): boolean {
    return hasActionIcon(specificContainerActions, ActionIcons.CUSTOMS);
  }

  public hasWeighingAction(specificContainerActions: string): boolean {
    return hasActionIcon(specificContainerActions, ActionIcons.WEIGHING);
  }

  public hasADRAction(dangerousGoods: boolean): boolean {
    return dangerousGoods;
  }

  public hasT1Action(t1Document: boolean): boolean {
    return t1Document;
  }
}
