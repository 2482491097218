export interface AdvancedFilterItem {
  columnName: string;
  operator: AdvancedFilterOperatorType;
  value: string;
  type: AdvancedFilterColumnType;
}

export interface AdvancedFilterColumn {
  id: number;
  name: string;
  resourceName: string;
  advancedFilterTypeOperator: AdvancedFilterTypeOperator;
}

// tslint:disable-next-line:interface-name
export interface AdvancedFilterTypeOperator {
  operators: AdvancedFilterOperatorType[];
  type: AdvancedFilterColumnType;
}

export enum AdvancedFilterOperatorType{
 Contains = "ADVANCED_FILTER.OPERATORS.CONTAINS",
 NotEqual = "ADVANCED_FILTER.OPERATORS.NOT_EQUAL",
 Empty = "ADVANCED_FILTER.OPERATORS.EMPTY",
 GreaterThan = "ADVANCED_FILTER.OPERATORS.GREATER_THAN",
 Equal = "ADVANCED_FILTER.OPERATORS.EQUAL",
 GreaterThanOrEqual = "ADVANCED_FILTER.OPERATORS.GREATER_THAN_OR_EQUAL",
 LessThan = "ADVANCED_FILTER.OPERATORS.LESS_THAN",
 LessThanOrEqual = "ADVANCED_FILTER.OPERATORS.LESS_THAN_OR_EQUAL",
 In = "ADVANCED_FILTER.OPERATORS.IN",
 NotContains = 'ADVANCED_FILTER.OPERATORS.NOT_CONTAINS' ,
 NotIn = 'ADVANCED_FILTER.OPERATORS.NOT_IN'
}

export enum AdvancedFilterColumnType {
  Text = "Text",
  Select = "Select",
  Number ="Number" ,
  DateTime = "DateTime",
  Boolean = "Boolean",
  All = "All"
}

export interface AdvancedFilter {
  name: string;
  advancedFilter: AdvancedFilterGroup;
}

export class AdvancedFilterGroup {
  filterItems: AdvancedFilterItem[];
  filterGroups: AdvancedFilterGroup[];
  operator: AdvancedFilterGroupType;
}

export enum AdvancedFilterGroupType {
  And = 'And',
  Or = 'Or'
}