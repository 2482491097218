import { UnavailabilityPipe } from './pipes/unavailability.pipe';
import { NgModule } from "@angular/core";
import { OrderLineColumnSelection } from "../to-screen/containers/display/to-details/to-details-column-selection/to-details-column-selection.component";
import { SelectableItemComponent } from "../pb-screen/containers/filters/selectable-item/selectable-item.component";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { HttpClient } from '@angular/common/http';
import { UserSettingsService } from "../pb-screen/services/usersettings.service";
import { OrderlineService } from "./services/orderline.service";
import { CustomDatePipe } from "../pb-screen/pipes/custom.date.pipe";
import { CapacitySelectorComponent } from "../pb-screen/containers/display/capacity-selector/capacity-selector.component";
import { SelectionDisplayComponent } from "../pb-screen/containers/display/selection-display/selection-display.component";
import { StatusPipe } from "../pb-screen/pipes/status.pipe";
import { CustomToDatePipe } from "../pb-screen/pipes/to-custom.date.pipe";
import { ToDetailsComponent } from "../to-screen/containers/display/to-details/to-details-modal.component";
import { ToGeneralDetailsComponent } from "../to-screen/containers/display/to-details/to-general-details/to-general-details.component";
import { ToOrderLinesComponent } from "../to-screen/containers/display/to-details/to-orderlines/to-orderlines.component";
import { ToActionBarComponent } from "../to-screen/containers/to-action-bar/to-action-bar.component";
import {PlanningConflictsComponent } from "../to-screen/modals/conflicts-modal/conflicts-list-modal.component"
import { GridModule } from "@progress/kendo-angular-grid";
import { SendStatusBcPipe } from "../pb-screen/pipes/sendStatusBC.pipe";
import { DynamicCaptionPipe } from "./pipes/dynamic-caption.pipe";
import { ToActionBarService } from "../pb-screen/services/to-action-bar.service";
import { OrderlineFilterPipe } from "../pb-screen/pipes/orderlines-filter.pipe";
import { SharedService } from "./services/shared.service";
import { ToInsertActionWizardComponent } from "../to-screen/modals/to-insert-action-wizard-modal/to-insert-action-wizard-modal.component";
import { TemperaturePipe } from "./pipes/temperature.pipe";

import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogComponent } from "./components/modals/dialog/dialog.component";
import { TimeFormatterPipe } from "./pipes/time-formatter.pipe";
import { NotificationModalComponent } from "./components/modals/notification/notification-modal.component";
import { NotificationService } from "./services/notification.service";
import { PrintService } from "./services/print/print.service";
import { ShowPlanningConflictsModal } from "./components/modals/show-conflicts-modal/show-planning-onflicts-modal.component";
import { ToSelectionDisplayComponent } from "../to-screen/containers/display/to-selection-display/to-selection-display.component";
import { PlanningConflictsService } from "./services/planning-conflicts.service";
import { ActivePlanningConflictsComponent } from "./components/modals/active-planning-conflicts/active-planning-conflicts.component";
import { LoadingModule } from "ngx-loading";
import { AutomaticUpdatesComonent } from "./components/modals/automatic-updates/automatic-updates.component";
import { OrderDatePipe } from "../pb-screen/pipes/orderDate.pipe";
import { FinalizedCombTansform } from "../pb-screen/pipes/finalizedCombi.transform.pipe";
import { FinalizedCombiBoolean } from "../pb-screen/pipes/boolean.pipe";
import { InsertActionWizardComponent } from "../pb-screen/containers/modals/insert-action-wizard-modal/insert-action-wizard-modal.component";
import { PbCmrActionComponent } from "../pb-screen/containers/display/pb-CmrActions-table/pb-CmrActions-table";
import { PbInsertActionWizardComponent } from "../pb-screen/containers/modals/pb-insert-action-wizard-modal/pb-insert-action-wizard-modal.component";
import { StatusBadgeComponent } from "../pb-screen/containers/display/status-badge/status-badge.component";
import { PbDetailsComponent } from "../pb-screen/containers/display/pb-details/pb-details.component";
import { PbGeneralDetails } from "../pb-screen/containers/display/pb-details/pb-general-details/pb-general-details.component";
import { PbOrderlinesComponent } from "../pb-screen/containers/display/pb-details/pb-orderlines/pb-orderlines-table.component";
import { UpdatePBModalComponent } from "../pb-screen/containers/modals/update-pb-modal/update-pb-modal.component";
import { PlanningModalComponent } from "../pb-screen/containers/modals/planning-modal/planning-modal.component";
import { TransportOrderMasterDetailComponent } from "../pb-screen/containers/display/transport-order-master-detail/transport-order-master-detail.component";
import { TODetailsComponent } from "../pb-screen/containers/display/transport-order-master-detail/to-details/to-details.component";
import { TransportOrdersMasterDetailFilterComponent } from "../pb-screen/containers/display/transport-orders-master-detail-filter/transport-orders-master-detail-filter.component";
import { PbOrderLineColumnSelection } from "../pb-screen/containers/display/pb-details/pb-details-column-selection/pb-details-column-selection.component";
import { NotificationDisplayComponent } from "./components/notification/notification-display.component";
import { NotificationViewerComponent } from "./components/notification/notification-viewer/notification-viewer.component";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogService } from "./components/confirm-dialog/confirm-dialog.service";
import { DropDownListModule } from "@progress/kendo-angular-dropdowns";
import { FontComponent } from './components/font/font.component';
import { DrivingTimeFormatter } from './pipes/driving-time-pipe';
import { ReplacePipe } from './pipes/replace-pipe';
import { NguiAutoCompleteModule } from '@ngui/auto-complete';
import { PreviousTransportInfoComponent } from '../pb-screen/containers/display/pb-details/previous-transport-information/previous-transport-info.component';
import { NextTransportInfoComponent } from '../pb-screen/containers/display/pb-details/next-transport-information/next-transport-info.component';
import { ShippingRailway } from './pipes/shipping-railway.pipe';
import { OverlayComponent } from './components/overlay/overlay.component';
import { UndoPreplanComponent } from './components/undo-preplan/undo-preplan.component';
import { RailPlanningModalComponent } from './containers/rail-planning-modal/rail-planning-modal.component';
import { UnplanModalComponent } from '../pb-screen/containers/modals/unplan-modal/unplan-modal.component';
import { UnplanModalComponentCPL } from './containers/unplan-modal/unplan-modal-CPL.component';

import { ActionBarComponent } from './components/action-bar/action-bar.component';
import { SelectionDisplayComponentStatus } from './components/selection-display-change-status/selection-display-change-status.component';
import { OkDialogComponent } from './components/modals/dialog/okDialog.component';
import { RerouteModalComponent } from './components/modals/reroute-modal/reroute-modal.component';
import { ContainerReuseComponent } from './components/modals/container-reuse-modal/container-reuse-modal.component';
import { MoveToPoolComponent } from './components/modals/move-to-pool-modal/move-to-pool-modal.component';
import { CMRPrintComponent } from './components/modals/cmr-print-modal/cmr-print-modal.component';
import { ActionIconsComponent } from './components/action-icons/action-icons.component';
import { PlanningBlockDetailComponent } from './containers/planning-block-detail/planning-block-detail.component';
import { PrintListComponent } from './components/modals/print-list-modal/print-list-modal.component';
import { WeighingModalComponent } from './components/modals/weighing-modal/weighing-modal.component';
import { DossierModalComponent } from './containers/dossier-modal/dossier-modal.component';
import { QuickViewComponent } from '../pb-screen/containers/filters/quick-view/component/quick-view.component';
import { PlanningBlockReservationComponent } from './components/planningBlock-reservation-modal/planningBlock-reservation-modal.component';
import { PlanningGroupModalComponent } from './components/modals/planning-groups-update/planning-groups-update.component';
import { UpdateInitialsModalComponent } from './containers/update-initial-datetime/update-initials-modal.component';
import { AnnouncedContainersTable } from '../announcement/components/annouced-container/announced-containers-table/announced-containers-table.component';
import { EditContainerInspectionsModal } from '../announcement/modals/edit-container-inspections/edit-container-inspections.component';
import { UndoAnnounceContainer } from '../announcement/modals/undo-announce-container/undo-announce-container-modal.component';
import { AnnouncedContainersComponent } from '../announcement/components/annouced-container/announced-containers.component';
import { EditContainerInfoModal } from '../announcement/modals/edit-container-info/edit-container-info.component';
import { ActionBarService } from './services/action-bar.service';
import { SharedActions } from './shared.actions';
import { PrintTicketService } from './services/print/printticket.service';
import { SmsService } from './services/sms.service';
import { CMRService } from './services/print/print-CMR.service';
import { EIRService } from './services/print/print-EIR.service';
import { ContainerListService } from './services/print/containerlist.print.service';
import { StackerListService } from './services/print/stacker.print.service';
import { WareHouseListService } from './services/print/warehouse-list.service';
import { StuffingStaalListService } from './services/print/stuffing-staal-list.service';
import { HttpClientService } from './services/httpclient';
import { WeekPlanningListService } from './services/print/week-planning-list.service';
import { ActionIconsFilterComponent } from './components/action-icons-filter/action-icons-filter.component';
import { LocationSelectorComponent } from './components/location-selector/location-selector.component';
import { TruckAppointmentModalComponent } from './containers/truck-appointment-modal/truck-appointment-modal.component';
import { DepotSelectionDisplayComponent } from './components/selection-display/selection-display.component';
import { DepotCapacitySelectorComponent } from './containers/capacity-selector/capacity-selector.component';
import { DepotPlanningModalComponent } from './containers/planning-modal/planning-modal.component';
import { TimeSlotSelectorComponent } from './containers/time-slot-selector/time-slot-selector.component';
import { TarTasService } from './services/tartas.service';
import { TruckAppointmentBookingModalComponent } from './containers/truck-appointment-booking-modal/truck-appointment-booking-modal.component';
import { TruckAppointmentLineDisplayComponent } from './components/truck-appointment-line-display/truck-appointment-line-display.component';
import { SwapTruckAppointmentModalComponent } from './containers/swap-truck-appointment-modal/swap-truck-appointment-modal.component';
import { TarTasManager } from './services/tartas-manager.service';
import { UtcDatePipe } from '../pb-screen/pipes/utc-date.pipe';
import { FilterGroupComponent } from '../pb-screen/containers/filters/filter-group/filter-group.component';
import { FilterLineComponent } from '../pb-screen/containers/filters/filter-line/filter-line.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { GetCmrActionsService } from '../pb-screen/services/getCmrActions.service';
import { PbScreenService } from '../pb-screen/services/pb-screen.service';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './resources/', '.json');
}
@NgModule({
  declarations: [
    OrderLineColumnSelection,
    SelectableItemComponent,
    CustomDatePipe,
    UtcDatePipe,
    ReplacePipe,
    CapacitySelectorComponent,
    SelectionDisplayComponent,
    TruckAppointmentLineDisplayComponent,
    PlanningConflictsComponent,
    NotificationModalComponent,
    StatusPipe,
    CustomToDatePipe,
    ToDetailsComponent,
    ToGeneralDetailsComponent,
    ToActionBarComponent,
    ToOrderLinesComponent,
    DynamicCaptionPipe,
    TemperaturePipe,
    TimeFormatterPipe,
    UnavailabilityPipe,
    SendStatusBcPipe,
    OrderlineFilterPipe,
    ToInsertActionWizardComponent,
    DialogComponent,
    ShowPlanningConflictsModal,
    ToSelectionDisplayComponent,
    ActivePlanningConflictsComponent,
    AutomaticUpdatesComonent,
    OrderDatePipe,
    FinalizedCombTansform,
    FinalizedCombiBoolean,
    InsertActionWizardComponent,
    PbCmrActionComponent,
    PbInsertActionWizardComponent,
    StatusBadgeComponent,
    FilterGroupComponent,
    PbDetailsComponent,
    PbGeneralDetails,
    PbOrderlinesComponent,
    UpdatePBModalComponent,
    LocationSelectorComponent,
    PlanningModalComponent,
    DepotPlanningModalComponent,
    TruckAppointmentModalComponent,
    TruckAppointmentBookingModalComponent,
    SwapTruckAppointmentModalComponent,
    TransportOrderMasterDetailComponent,
    TODetailsComponent,
    TransportOrdersMasterDetailFilterComponent,
    PbOrderLineColumnSelection,
    NotificationDisplayComponent,
    NotificationViewerComponent,
    ConfirmDialogComponent,
    FontComponent,
    DrivingTimeFormatter,
    NextTransportInfoComponent,
    PreviousTransportInfoComponent,
    ShippingRailway,
    SelectableItemComponent,
    DepotCapacitySelectorComponent,
    TimeSlotSelectorComponent,
    OverlayComponent,
    StatusBadgeComponent,
    UndoPreplanComponent,
    RailPlanningModalComponent,
    UnplanModalComponent,
    UnplanModalComponentCPL ,
    ActionBarComponent,
    DepotSelectionDisplayComponent,
    SelectionDisplayComponentStatus,
    FilterLineComponent,
    CustomDatePipe,
    DialogComponent,
    OkDialogComponent,
    RerouteModalComponent,
    ContainerReuseComponent,
    MoveToPoolComponent,
    CMRPrintComponent,
    ActionIconsComponent,
    ActionIconsFilterComponent,
    PlanningBlockDetailComponent,
    PrintListComponent,
    WeighingModalComponent,
    DossierModalComponent,
    QuickViewComponent,
    PlanningBlockReservationComponent,
    PlanningGroupModalComponent,
    
    UpdateInitialsModalComponent,
    AnnouncedContainersTable,
    EditContainerInspectionsModal,
    UndoAnnounceContainer,
    AnnouncedContainersComponent,
        EditContainerInfoModal
  ],
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule.forRoot({
      loader: {
        deps: [HttpClient],
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
      },
    }),
    GridModule , DateInputsModule,
    LoadingModule,
    DropDownListModule,
    NguiAutoCompleteModule

  ],
  // tslint:disable-next-line:object-literal-sort-keys
  exports: [
    FilterGroupComponent,
    DynamicCaptionPipe,
    TemperaturePipe,
    TimeFormatterPipe,
    UnavailabilityPipe,
    OrderLineColumnSelection,
    SelectableItemComponent,
    CustomDatePipe,
    UtcDatePipe,
    ReplacePipe,
    CapacitySelectorComponent,
    SelectionDisplayComponent,
    TruckAppointmentLineDisplayComponent,
    NotificationModalComponent,
    StatusPipe,
    CustomToDatePipe,
    ToDetailsComponent,
    ToGeneralDetailsComponent,
    ToActionBarComponent,
    SendStatusBcPipe,
    PlanningConflictsComponent,
    OrderlineFilterPipe,
    ToInsertActionWizardComponent,
    PbInsertActionWizardComponent,
    DialogComponent,
    ShowPlanningConflictsModal,
    ToSelectionDisplayComponent,
    ActivePlanningConflictsComponent,
    AutomaticUpdatesComonent,
    OrderDatePipe,
    FinalizedCombTansform,
    FinalizedCombiBoolean,
    StatusBadgeComponent,
    PbDetailsComponent,
    PbGeneralDetails,
    PbOrderlinesComponent,
    InsertActionWizardComponent,
    PbCmrActionComponent,
    UpdatePBModalComponent,
    LocationSelectorComponent,
    PlanningModalComponent,
    DepotPlanningModalComponent,
    TruckAppointmentModalComponent,
    TruckAppointmentBookingModalComponent,
    SwapTruckAppointmentModalComponent,
    TransportOrderMasterDetailComponent,
    TODetailsComponent,
    PbOrderLineColumnSelection,
    NotificationDisplayComponent,
    NotificationViewerComponent,
    PbOrderLineColumnSelection,
    ConfirmDialogComponent,
    FontComponent, 
    DrivingTimeFormatter,
    NextTransportInfoComponent,
    PreviousTransportInfoComponent,
    ShippingRailway,
    SelectableItemComponent,
    DepotCapacitySelectorComponent,
    TimeSlotSelectorComponent,
    OverlayComponent,
    UnplanModalComponent,
    UnplanModalComponentCPL,
    StatusBadgeComponent,
    UndoPreplanComponent,
    RailPlanningModalComponent,
    ActionBarComponent,
    DepotSelectionDisplayComponent,
    SelectionDisplayComponentStatus,
    FilterLineComponent,
    CustomDatePipe,
    DialogComponent,
    OkDialogComponent,
    RerouteModalComponent,
    ContainerReuseComponent,
    MoveToPoolComponent,
    CMRPrintComponent,
    ActionIconsComponent,
    ActionIconsFilterComponent,
    PlanningBlockDetailComponent,
    PrintListComponent,
    WeighingModalComponent,
    DossierModalComponent,
    QuickViewComponent,
    PlanningBlockReservationComponent,
    PlanningGroupModalComponent,
    
    UpdateInitialsModalComponent,
    AnnouncedContainersComponent,
    AnnouncedContainersTable,
    EditContainerInspectionsModal,
    UndoAnnounceContainer,
    EditContainerInfoModal
  ],
  providers: [
    GetCmrActionsService,
    UserSettingsService,
    OrderlineService,
    NotificationService,
    ToActionBarService,
    SharedService,
    PrintService,
    PlanningConflictsService,
    ConfirmDialogService,
    SharedService,
     ActionBarService,
     SharedActions,
     PrintTicketService,
     CMRService,
     EIRService,
     SmsService,
     ContainerListService,
     StackerListService,
     WareHouseListService,
     StuffingStaalListService,
     HttpClientService,
     WeekPlanningListService,
     TarTasService,
     TarTasManager,
     PbScreenService
  ],
})

export class SharedModules { }
