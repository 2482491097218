import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';

import { IAppState } from '../../../store/IAppState'
import { ChannelEventFactory } from '../../../pb-screen/services/channel.event.factory';
import { SignalrBroadcaster } from '../../../pb-screen/helpers/signalr.broadcaster';
import { ChannelService } from '../../../pb-screen/services/channel.service';
import { ActionKeys } from '../../../pb-screen/action.keys';
import { AdvancedFilterGroup, AdvancedFilterItem } from '../../../shared/components/filter-line/filter-line.interface';


@Injectable()
export class DepotOutAdvancedFilterActions extends SignalrBroadcaster {
    constructor(ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    executeAdvancedFilter(filterItems: AdvancedFilterGroup) {
        super.dispatchAction({
            type: ActionKeys.EXECUTE_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS,
            filterItems: filterItems
        });
    }

    resetAdvancedFilter(filterItems: AdvancedFilterGroup) {
        super.dispatchAction({
            type: ActionKeys.RESET_ADVANCED_FILTER_DEPOT_OUT_PLANNINGBLOCKS,
            filterItems: filterItems
        });
    }
}