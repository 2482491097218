import { ActionKeys } from '../pb-screen/action.keys';
import { advancedFilterPlanningBlocks } from '../pb-screen/filters/advanced.filter.comparison';
import { filterPlanningBlocks } from '../pb-screen/filters/pb.quickfilter';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { PRE_PLANNED, TO_BE_PLANNED } from '../pb-screen/models/status-type.interface';
import { IAppState } from './IAppState';
import * as _ from "lodash";

import * as InitialState from './initial.state';
import { getFilteredPlanningBlocksBySearchText } from '../pb-screen/helpers/filter.by.text';
import { stat } from 'fs';
import { NgRedux } from '@angular-redux/store';
import * as moment from 'moment';

/* NOTE: When adding a case be sure you add it to the fallthrough in the reducer.ts file */
export function PbReducer(state: IAppState = InitialState.initialState, action) {
  switch (action.type) {
    case ActionKeys.APPLY_GLOBAL_FILTER:
      return applyGlobalFilter(state, action);
    case ActionKeys.REQUEST_PLANNINGBLOCKS_SUCCESS:
      return storePlanningBlocks(state, action);
    case ActionKeys.APPLY_QUICK_FILTER:
      return applyQuickFilter(state, action);
    case ActionKeys.RESET_QUICK_FILTER:
      return resetQuickFilter(state, action);
    case ActionKeys.APPLY_ADVANCED_FILTER:
      return applyAdvancedFilter(state, action);
    case ActionKeys.RESET_ADVANCED_FILTER:
      return resetAdvancedFilter(state, action);
    case ActionKeys.UPDATE_SESSION_COLUMNS:
      return updateSessionColumnSelection(state, action);
    case ActionKeys.RESET_PLANNINGBLOCKS:
      return resetPlanningBlocks(state, action);
    case ActionKeys.SET_PROCESSED_DISPLAY_STATE:
      return seProcessedDisplayStateilter(state, action);
    case ActionKeys.SET_SELECTED_PLANNING_BLOCKS:
      return setSelectedPlanningBlocks(state, action);
    case ActionKeys.SET_REUSE_CANDIDATES:
      return setReuseCandidates(state, action);
    case ActionKeys.SET_Shared_SELECTED_PLANNING_BLOCKS:
      return setSharedSelectedPlanningBlocks(state, action);
    case ActionKeys.RESET_ACTION_BAR_STATES:
      return resetActionBarStates(state, action);
    case ActionKeys.SET_ACTION_BAR_STATES:
      return setActionBarStates(state, action);
    case ActionKeys.SET_DATE_INTERVAL:
      return setDateInterval(state, action);
    case ActionKeys.SET_PROCESSED_DISPLAY_STATE:
      return setProcessedDisplayState(state, action);
    case ActionKeys.SET_IMPORTANT_DISPLAY_STATE:
      return setImportantDisplayState(state, action);
    case ActionKeys.UPDATE_PLANNINGBLOCKS_DISPLAY:
      return updatePlanningBlocksDisplay(state, action);
    case ActionKeys.SET_ORDERLINES_FOR_PLANNINGBLOCK:
      return setOrderlinesForPlanningBlock(state, action);
    case ActionKeys.SET_SELECTED_DISPLAY_STATE:
      return setSelectedDisplayState(state, action);
    case ActionKeys.UPDATE_PBORDERLINE_SESSION_COLUMNS:
      return updateSessionPbOrderlineColumnSelection(state,action);
    case ActionKeys.SET_SHIPPING_RAIL_DATA:
      return setShippingRailDiary(state,action);
    default:
      return state;
  }
}


function setSelectedPlanningBlocks(state: IAppState, action): IAppState {

  if (state.sharedSelectedPlanningBlocks.length > 0) {
    state.sharedSelectedPlanningBlocks.forEach(sPB => {

      if (action.selectedPlanningBlocks.findIndex(pb => pb.id == sPB.id) == -1) {

        action.selectedPlanningBlocks.push(sPB);
      }

    });
  }
  return Object.assign({},
    state,
    {
      selectedPlanningBlocks: [...action.selectedPlanningBlocks],
    });
}

function setSharedSelectedPlanningBlocks(state: IAppState, action): IAppState {

  if (action.selectedPlanningBlocks.length > 0) {
    action.selectedPlanningBlocks.forEach(pb => {
      if (state.selectedPlanningBlocks.findIndex(sPB => sPB.id == pb.id) == -1)
        state.selectedPlanningBlocks.push(pb);
    });
  }

  if (state.sharedSelectedPlanningBlocks.length > 0) {
    state.sharedSelectedPlanningBlocks.forEach(sPB => {
      if (action.selectedPlanningBlocks.findIndex(x => x.id == sPB.id) == -1) {
        var index = state.selectedPlanningBlocks.findIndex(pb => pb.id == sPB.id);
        if (index > -1)
          state.selectedPlanningBlocks.splice(index, 1);

        var pbBoardInd = state.selectedPbsToPlan.findIndex(pb => pb.id == sPB.id);
        if (index > -1)
          state.selectedPbsToPlan.splice(index, 1);

      }
    });

  }

  //  Object.assign({},
  //   state,
  //   {
  //     selectedPlanningBlocks: [...state.selectedPlanningBlocks],
  //   });

  var planningBoardSelection = getSharedSelectedPbsUnplanned(state, action);
  let selectionType = state.selectionType;
  if (planningBoardSelection.length > 0) {
    if (selectionType !== 'orderline') {
      selectionType = "planningBlock"
    }
  }
  return Object.assign({},
    state,
    {
      selectedPbsFromPlanBoard: [],
      sharedSelectedPlanningBlocks: [...action.selectedPlanningBlocks],
      selectedPbsToPlan: [...planningBoardSelection],
      selectionType: selectionType,
      selectedTransportOrders: []
    }

  );
}

function getSharedSelectedPbsUnplanned(state: IAppState, action): PlanningBlock[] {

  var planningBlocksToPlan = state.selectedPbsToPlan;
  var result: PlanningBlock[] = [];


  action.selectedPlanningBlocks.forEach(pb => {
    var selectedPlanningBlock = planningBlocksToPlan.filter(unPlan => unPlan.id == pb.id);
    if (selectedPlanningBlock.length == 0) {
      if (pb.status.statusType == 12) // committed by Hussam : it cause sharing PB issues.  || pb.status.statusType == PRE_PLANNED)
        planningBlocksToPlan.push(pb);
    }

  });



  return planningBlocksToPlan;
}


// tslint:disable-next-line:typedef
function applyAdvancedFilter(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let noText: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = state.planningBlocks;

  planningBlocks = getPBsFilteredByFixedQuickFilter(state);

  matches = advancedFilterPlanningBlocks(planningBlocks, action.filterItems);

  const depotInSearchTextTrimmed = state.searchText.trim();
  if (depotInSearchTextTrimmed.length > 0) {
    noText = matches;
    matches = getFilteredPlanningBlocksBySearchText(matches,
      depotInSearchTextTrimmed,
      state.sessionColumnSelection);
  } else {
    noText = matches;
  }

  return Object.assign({},
    state,
    {
      quickFilteredPlanningBlocksNoText: noText,
      sessionAdvancedFilters: action.filterItems,
      filteredPlanningBlocks: matches,
      quickFilteredPlanningBlocks: matches,
      quickFilter: null
    });
}

function resetAdvancedFilter(state: IAppState, action): IAppState {
  let matches: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = [];

  planningBlocks = filterProcessedPlanningBlocks(state.planningBlocks);
  planningBlocks = getPBsFilteredByFixedQuickFilter(state);

  const depotInSearchTextTrimmed = state.searchText.trim();

  if (depotInSearchTextTrimmed.length > 0) {
    matches = getFilteredPlanningBlocksBySearchText(planningBlocks,
      depotInSearchTextTrimmed,
      state.sessionColumnSelection);
  } else {
    matches = planningBlocks;
  }

  return Object.assign({},
    state,
    {
      sessionAdvancedFilters: null,
      filteredPlanningBlocks: matches,
      quickFilteredPlanningBlocks: matches,
      quickFilteredPlanningBlocksNoText: planningBlocks,
      quickFilter: null
    });
}

function getPBsFilteredByFixedQuickFilter(state: IAppState): PlanningBlock[] {
  let fixedQuickFilter = null;
  if (state.applicationUser && state.applicationUser.pbFixedPreferences && state.applicationUser.pbFixedPreferences.fixedQuickFilter)
    fixedQuickFilter = state.applicationUser.pbFixedPreferences.fixedQuickFilter;
  if (fixedQuickFilter)
    return filterPlanningBlocks(state.planningBlocks, fixedQuickFilter, '',
      state.sessionColumnSelection, state.selectedPlanningBlocks);
  return state.planningBlocks;
}

function updatePlanningBlocksDisplay(state: IAppState, action): IAppState {
  const planningBlocks: PlanningBlock[] = state.planningBlocks;
  const returnedPlanningBlocks: PlanningBlock[] = action.planningBlocks;
  let selectedPlanningBlocks: PlanningBlock[] = state.selectedPlanningBlocks;

  //Update collections with the returned pbs
  returnedPlanningBlocks.map((returnedPlanningBlock: PlanningBlock) => {
    let index = planningBlocks.findIndex((d) => d.id === returnedPlanningBlock.id);
    planningBlocks[index] = returnedPlanningBlock;
    index = selectedPlanningBlocks.findIndex((d) => d.id === returnedPlanningBlock.id);
    if(index != -1)
      selectedPlanningBlocks[index] = returnedPlanningBlock;
  });

  // Re apply the filters on the updated pbs
  let filteredPlanningBlocksNoText = filterPlanningBlocks(planningBlocks, state.quickFilter, '', state.sessionColumnSelection, state.selectedPlanningBlocks);
  let filteredPlanningBlocks = getFilteredPlanningBlocksBySearchText(planningBlocks, state.searchText, state.sessionColumnSelection);

  return Object.assign({},
    state,
    {
      planningBlocks: [...planningBlocks],
      quickFilteredPlanningBlocks: [...filteredPlanningBlocks],
      quickFilteredPlanningBlocksNoText: [...filteredPlanningBlocksNoText],
      filteredPlanningBlocks: [...filteredPlanningBlocks],
      selectedPlanningBlocks: [...selectedPlanningBlocks]
    });
}

function updateSessionColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionColumnSelection: action.columns });
}

function updateSessionPbOrderlineColumnSelection(state: IAppState, action): IAppState {
  return Object.assign({}, state, { sessionPbOrderLineColumnSelection: action.columns });
}

function applyGlobalFilter(state: IAppState, action): IAppState {
  if (action.searchText.trim().length >= 1) {
    const pbsToFilter = state.quickFilteredPlanningBlocksNoText;
    const filtered = getFilteredPlanningBlocksBySearchText(pbsToFilter, action.searchText.trim(), state.sessionColumnSelection);

    return Object.assign({}, state, {
      quickFilteredPlanningBlocksNoText: pbsToFilter,
      filteredPlanningBlocks: filtered,
      searchText: action.searchText,
    });
  }

  const pbs = state.quickFilteredPlanningBlocksNoText;
  return Object.assign({}, state, {
    filteredPlanningBlocks: pbs,
    searchText: action.searchText,
  });
}

function storePlanningBlocks(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      planningBlocks: action.planningBlocks,
      quickFilteredPlanningBlocks: action.planningBlocks,
      filteredPlanningBlocks: filterProcessedPlanningBlocks(action.planningBlocks),
    });
}

function setOrderlinesForPlanningBlock(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      pbOrderlines: action.pbOrderlines,
    }
  )
}

function filterProcessedPlanningBlocks(planningBlocks: PlanningBlock[]): PlanningBlock[] {
  return planningBlocks;
  // return planningBlocks.filter((planningBlock: PlanningBlock) => !planningBlock.processed);
}

function applyQuickFilter(state: IAppState, action): IAppState {

  let matches: PlanningBlock[] = [];
  let planningBlocks: PlanningBlock[] = getPBsFilteredByFixedQuickFilter(state);
  if (action.quickFilter) {
    if (action.quickFilter.collectionDate) {
      planningBlocks = planningBlocks.filter(pb => pb.firstCollectionFrom.toString() !== "0001-01-01T00:00:00Z"
        && pb.firstCollectionFrom.toString() !== "1753-01-01T00:00:00Z");
    } else if (action.quickFilter.deliveryDate) {
      planningBlocks = planningBlocks.filter(pb => pb.firstDeliveryFrom.toString() !== "0001-01-01T00:00:00Z"
        && pb.firstDeliveryFrom.toString() !== "1753-01-01T00:00:00Z");
    } else if (action.quickFilter.etaFrom) {
      planningBlocks = planningBlocks.filter(pb => pb.etaFrom.toString() !== "0001-01-01T00:00:00Z"
        && pb.etaFrom.toString() !== "1753-01-01T00:00:00Z"
        && moment(pb.etaFrom).format("YYYY Do MMM") === moment(action.quickFilter.etaFrom).format("YYYY Do MMM"));
    } else {
      planningBlocks = planningBlocks;
    }

    if (!action.quickFilter.customer &&
      !action.quickFilter.actionIconFilter &&
      !action.quickFilter.planningGroup &&
      !action.quickFilter.planningZone &&
      !action.quickFilter.firstAction &&
      !action.quickFilter.lastAction &&
      !action.quickFilter.loadingPlace &&
      !action.quickFilter.unLoadingPlace &&
      !action.quickFilter.closing &&
      !action.quickFilter.vgmClosing &&
      !action.quickFilter.startDate &&
      !action.quickFilter.endDate &&
      !action.quickFilter.containerType &&
      !action.quickFilter.latestLocationCode &&
      !action.quickFilter.carrier &&
      !action.quickFilter.planningState.toBePlanned &&
      !action.quickFilter.planningState.inProgress &&
      !action.quickFilter.planningState.planned &&
      !action.quickFilter.planningState.prePlanned &&
      !action.quickFilter.planningState.onHold &&
      !action.quickFilter.containerStatus.leegCrea &&
      !action.quickFilter.containerStatus.leegAf &&
      !action.quickFilter.containerStatus.geladen &&
      !action.quickFilter.containerStatus.volKlaar &&
      !action.quickFilter.containerStatus.volIn &&
      !action.quickFilter.containerStatus.volCrea &&
      !action.quickFilter.containerStatus.volAf &&
      !action.quickFilter.containerStatus.gelost &&
      !action.quickFilter.containerStatus.leegKlaar &&
      !action.quickFilter.containerStatus.leegIn &&
      !action.quickFilter.deliveryRegion &&
      !action.quickFilter.transportType &&
      !action.quickFilter.tripType &&
      !action.quickFilter.colDelPlanning &&
      !action.quickFilter.collectionRegion) {

      const depotInSearchTextTrimmed = state.searchText.trim();


      if (depotInSearchTextTrimmed.length >= 1) {
        matches =
          getFilteredPlanningBlocksBySearchText(planningBlocks,
            depotInSearchTextTrimmed,
            state.sessionColumnSelection);
        return Object.assign({},
          state,
          {
            quickFilteredPlanningBlocksNoText: planningBlocks,
            filteredPlanningBlocks: matches,
            quickFilteredPlanningBlocks: matches,
            quickFilter: null,
            sessionAdvancedFilters: null,
          });
      }

      return Object.assign({},
        state,
        {
          quickFilteredPlanningBlocksNoText: planningBlocks,
          filteredPlanningBlocks: planningBlocks,
          quickFilteredPlanningBlocks: planningBlocks,
          quickFilter: null,
          sessionAdvancedFilters: null,
        });
    }
  }

  matches = filterPlanningBlocks(planningBlocks,
    action.quickFilter,
    '',
    state.sessionColumnSelection, state.selectedPlanningBlocks);

  const depotInSearchTextTrimmed = state.searchText.trim();

  if (depotInSearchTextTrimmed.length >= 1) {
    const noTextMatches: PlanningBlock[] = matches;
    matches = getFilteredPlanningBlocksBySearchText(matches,
      depotInSearchTextTrimmed,
      state.sessionColumnSelection);
    return Object.assign({},
      state,
      {
        quickFilteredPlanningBlocksNoText: noTextMatches,
        filteredPlanningBlocks: matches,
        quickFilteredPlanningBlocks: matches,
        quickFilter: { ...action.quickFilter },
        sessionAdvancedFilters: null,
      });
  }

  return Object.assign({},
    state,
    {
      quickFilteredPlanningBlocksNoText: matches,
      filteredPlanningBlocks: matches,
      quickFilteredPlanningBlocks: matches,
      quickFilter: { ...action.quickFilter },
      sessionAdvancedFilters: null,
    });
}

function resetQuickFilter(state: IAppState, action): IAppState {
  return Object.assign({},
    state,
    {
      quickFilter: null,
      sessionAdvancedFilters: null,
    });
}


function resetPlanningBlocks(state: IAppState, action): IAppState {
  let planningBlocks: PlanningBlock[] = [];

  planningBlocks = state.planningBlocks;

  if (state.quickFilteredPlanningBlocksNoText && state.quickFilteredPlanningBlocksNoText.length > 0) {
    return Object.assign({}, state, {
      searchText: '',
      filteredPlanningBlocks: state.quickFilteredPlanningBlocksNoText,
      quickFilteredPlanningBlocks: state.quickFilteredPlanningBlocksNoText,
    });
  }
  return Object.assign({}, state, {
    searchText: '',
    filteredPlanningBlocks: planningBlocks,
    quickFilteredPlanningBlocks: planningBlocks,
  });
}

function seProcessedDisplayStateilter(state: IAppState, action): IAppState {
  return Object.assign({}, state, {
    depotInProcessedDisplayState: action.depotInProcessedDisplayState
  });
}

function setActionBarStates(state, action): IAppState {
  return Object.assign({}, state, {
    planState: action.actionBarStates.planState,
    unplanState: action.actionBarStates.unplanState,
    updateState: action.actionBarStates.updateState,
    pbDetailsState: action.actionBarStates.pbDetailsState,
    toDetailsState: action.actionBarStates.toDetailsState,
    groupUpdateState: action.actionBarStates.groupUpdateState,
    checkConflictsForPbState: action.actionBarStates.checkConflictsForPbState
  });
}

function resetActionBarStates(state, action): IAppState {
  return Object.assign({}, state, {
    planState: false,
    unplanState: false,
    updateState: false,
    pbDetailsState: false,
    toDetailsState: false,
    groupUpdateState: false,
    checkConflictsForPbState: false
  });
}

function setDateInterval(state, action): IAppState {
  return Object.assign({}, state, {
    startDate: action.startDate,
    endDate: action.endDate
  });
}

function setProcessedDisplayState(state, action): IAppState {
  return Object.assign({}, state, {
    processedDispalyState: action.processedDispalyState
  })
}

function setImportantDisplayState(state, action): IAppState {
  return Object.assign({}, state, {
    importantDisplayState: action.importantDisplayState
  })
}

function setSelectedDisplayState(state, action): IAppState {
  return Object.assign({}, state, {
    selectedDisplayState: action.selectedDisplayState
  })
}

function setShippingRailDiary(state,action) : IAppState {
  return Object.assign({},state , {
    shippingRailDiary : action.shippingRailDiary
  })
}

function setReuseCandidates(state: IAppState, action: any) {
  return Object.assign({},state , {
    reuseCandidates: action.candidates
  })
}

