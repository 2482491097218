
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClientService } from "./httpclient";
import { environment } from '../../../environments/environment';
import { TarTasStatus, TruckAppointmentLine } from '../models/tar-tas/tar-tas.interface';
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from '../models/status-type.interface';
import { PlanningBlock } from '../../pb-screen/models/planning-block/planning-block.interface';
import { TruckAppointmentModalComponent } from '../containers/truck-appointment-modal/truck-appointment-modal.component';
import { TruckAppointmentBookingModalComponent } from '../containers/truck-appointment-booking-modal/truck-appointment-booking-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';

@Injectable()
export class TarTasService {

  private tarTasPath = "tartas/"
    constructor(
        private readonly http: HttpClientService,
        private readonly simpleModalService: SimpleModalService
      ) { }

    public createTruckAppointment(orderlineIds: number[], bookedDateTimeFrom: string, bookedDateTimeUntil: string, isGroupedAppointment: boolean) {

        return this.http.post(environment.ContainerPlanningApiUri + this.tarTasPath +
          "create-truck-appointment?orderlineIds="+orderlineIds.join("|") 
          + '&from='+ bookedDateTimeFrom
          +'&until='+ bookedDateTimeUntil
          +'&grouped='+ isGroupedAppointment, {}).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
      }
      
    cancelTruckAppointment(orderlineId: number) {
      return this.http.post(environment.ContainerPlanningApiUri + this.tarTasPath +
        "cancel-truck-appointment?orderlineId="+orderlineId, {}).pipe(
        map((response: any) => {
          return response.data;
        }),
        catchError((err) => {
          return observableThrowError(err);
        }),);
    }
    
      getTruckAppointmentLines(dOrderLineID: number): Observable<TruckAppointmentLine[]> {
        return this.http.get(environment.ContainerPlanningApiUri + this.tarTasPath +
          "get-truck-appointment-lines?orderlineId="+dOrderLineID).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
      }

      getTruckAppointmentNo(dOrderLineID: number): Observable<string> {
        return this.http.get(environment.ContainerPlanningApiUri + this.tarTasPath +
          "get-truck-appointment-no?orderlineId="+dOrderLineID).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
      }

      getTruckAppointmentLinesForAppointmentNo(truckAppointmentNo: string): Observable<TruckAppointmentLine[]> {
        return this.http.get(environment.ContainerPlanningApiUri + this.tarTasPath +
          "get-truck-appointment-lines-for-no?truckAppointmentNo="+truckAppointmentNo).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
      }

      
    getTruckAppointmentsForSwap(truckAppointmentNo: string, truckAppointmentAddressId: string, truckAppointmentSystem: string) {
      return this.http.get(environment.ContainerPlanningApiUri + this.tarTasPath +
        "get-truck-appointments-for-swap?truckAppointmentNo=" + truckAppointmentNo
        + "&truckAppointmentAddressId=" + truckAppointmentAddressId
        + "&truckAppointmentSystem=" + truckAppointmentSystem).pipe(
        map((response: any) => {
          return response.data;
        }),
        catchError((err) => {
          return observableThrowError(err);
        }),);
    }

    swapContainers(orderlineIdsFrom: number[], appointmentNoFrom: any, orderlineIdsTo: number[], appointmentNoTo: any) {
      return this.http.post(environment.ContainerPlanningApiUri + this.tarTasPath +
        "swap-containers?orderlineIdsFrom="+orderlineIdsFrom.join(",")
        +"&appointmentNoFrom="+appointmentNoFrom
        +"&orderlineIdsTo="+orderlineIdsTo.join(",")
        +"&appointmentNoTo="+appointmentNoTo, {}).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
    }
    
    disconnectContainers(orderlineIds: number[], truckAppointmentNo: string) {
      return this.http.post(environment.ContainerPlanningApiUri + this.tarTasPath +
        "disconnect-containers?orderlineIds="+orderlineIds.join(",")
        +"&appointmentNo="+truckAppointmentNo, {}).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
    }

    connectContainers(orderlineIds: number[], fromTruckAppointmentNo: string, toTruckAppointmentNo: string) {
      return this.http.post(environment.ContainerPlanningApiUri + this.tarTasPath +
        "connect-containers?orderlineIds="+orderlineIds.join(",")
        +"&appointmentNoFrom="+fromTruckAppointmentNo
        +"&appointmentNoTo="+toTruckAppointmentNo, {}).pipe(
          map((response: any) => {
            return response.data;
          }),
          catchError((err) => {
            return observableThrowError(err);
          }),);
    }
}
