import { HomeService } from './home.service';
import { ActionKeys } from '../action.keys';

export const TRANSMIT_ACTION = ActionKeys.TRANSMIT_ACTION;

export class ChannelEvent {
  public name: string;
  public channelName: string;
  public timestamp: Date;
  public data: any;
  public json: string;

  constructor() {
    this.timestamp = new Date();
  }
}

// tslint:disable-next-line:max-classes-per-file
export class ChannelEventFactory {
  public createChannelEvent(channelName: string, eventName: string): ChannelEvent {
    const channelEvent: ChannelEvent = new ChannelEvent();
    channelEvent.channelName = channelName;
    channelEvent.name = eventName;

    return channelEvent;
  }

  public createChannelEventWithData(channelName: string, eventName: string, data: any): ChannelEvent {
    const channelEvent = this.createChannelEvent(channelName, eventName);
    channelEvent.data = data;

    return channelEvent;
  }

  public createTransmitActionEvent(channelName: string, data): ChannelEvent {
    const channelEvent = this.createChannelEvent(channelName, TRANSMIT_ACTION);
    channelEvent.data = { data, uniqueTabId: this.getUniqueTabId() };
    return channelEvent;
  }

  private getUniqueTabId(): number {
    return sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
  }
}
