import { OnInit, Component, Output, Input, EventEmitter, SimpleChanges, OnDestroy } from "@angular/core";
import { select, NgRedux } from "@angular-redux/store";
import { Haulier } from "../../../pb-screen/models/haulier.interface";
import { CapacitySlot } from "../../../pb-screen/models/capacity-slot.interface";
import { Observable, Subject } from "rxjs";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { TransportOrder } from "../../../pb-screen/models/transport-order/transport-order.interface";
import { SharedActions } from "../../../shared/shared.actions";
import { IAppState } from "../../../store/IAppState";
import { HttpClientService } from "../../../shared/services/httpclient";
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { IdNameAsStringObject } from '../../../pb-screen/models/quick-view/id-name-as-string-object.interface';
import { Chassis } from '../../../shared/models/chassis.interface';
import { Trailer } from '../../../shared/models/trailer.interface';
import { SharedService } from '../../../shared/services/shared.service';
import { ToScreenService } from '../../../pb-screen/services/to-screen.service';
import { Driver } from "../../../static/models/driver.interface";
import { takeUntil } from "rxjs/operators";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: "to-capacity-selector",
    templateUrl: "./to-capacity-selector.component.html",
})
export class ToCapacitySelectorComponent implements OnInit, OnDestroy {

    @Output() public capacitySelection: EventEmitter<CapacitySlot> = new EventEmitter();
    @Output() public haulierSelection: EventEmitter<Haulier> = new EventEmitter();
    @Output() public planningGroupSelection: EventEmitter<IdNameAsStringObject> = new EventEmitter();
    @Output() public driverSelection: EventEmitter<Driver> = new EventEmitter();
    @Output() public coDriverSelection: EventEmitter<Driver> = new EventEmitter();
    @Output() public transportOrder: TransportOrder;
    @select("capacitySlots") public capacitySlots$: Observable<CapacitySlot[]>;
    @select("drivers") public drivers$: Observable<Driver[]>;
    @select("hauliers") public hauliers$: Observable<Haulier[]>;
    @select("planningGroups") public planningGroups$: Observable<IdNameAsStringObject[]>;
    @select("selectedPlanningBlocks") public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select("selectedTransportOrders") public selectedTransportOrders$: Observable<TransportOrder[]>;
    @select("chassis") public chassis$ : Observable<Chassis[]>;
    @select("trailers") public trailers$ : Observable<Trailer[]>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    @Input() public selectedCapacity: CapacitySlot = undefined;
    @Input() public selectedHaulier: Haulier = undefined;
    @Input() public selectedPlanningGroup : IdNameAsStringObject = undefined;
    @Input() public selectedDriver: Driver;
    @Input() public selectedCoDriver: Driver;
    @Input() public selectedTransportOrder: TransportOrder = undefined;
    @Input() public hasError: boolean;

    public capacitySlots: CapacitySlot[] = [];
    public filteredCapacitySlots: CapacitySlot[] = [];
    public planningHauliers: Haulier[] = [];
    public filteredPlanningHauliers: Haulier[] = [];
    public drivers: Driver[] = [];
    public planningGroups: IdNameAsStringObject[] = [];
    public initialFilteredDrivers: Driver[] = [];
    public filteredDrivers: Driver[] = [];
    public filteredCoDrivers: Driver[] = [];
    public planDate: Date;
    public apiFormattedDate: string;
    public tranpsorOrderDate: Date;

    public disabledCapacityInput: boolean = false;
    public disabledHaulierInput: boolean = false;
    public disabledDateInput: boolean = false;
    public disabledPlanningGroupInput : boolean = false;
    public selectedDriverIsAbsent: boolean = false;
    public selectedCoDriverIsAbsent: boolean = false;
    public emptyCapacity: CapacitySlot;

    public selectedPlanningBlocks: PlanningBlock[] = [];
    public selectedTransportOrderNo: string = "";

    public trailers : Trailer[] = [] ;
    public chassis : Chassis[] = [] ;

    public selectedChassis: Chassis;
    public selectedTrailer: Trailer;
    destroy$ = new Subject();

    constructor(public readonly sharedActions: SharedActions,
                public readonly ngRedux: NgRedux<IAppState>,
                public readonly toastr: ToastrService,
                public readonly httpClientSerive: HttpClientService,
                public readonly sharedService: SharedService, 
                public readonly toScreenService : ToScreenService) {}

    public ngOnInit() {
        if (this.selectedTransportOrder) {
            this.tranpsorOrderDate = this.selectedTransportOrder.date;
        }

        this.setInitialPlanDate();
        this.formatDateForApi();

        this.httpClientSerive.getToken().subscribe(() => {
            this.sharedActions.getCapacitySlotsLight(this.apiFormattedDate);
            this.sharedActions.getHauliers();

            this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
                if (applicationUser) {
                  this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                }
              });

            this.capacitySlots$.pipe(takeUntil(this.destroy$)).subscribe((capacitySlots: CapacitySlot[]) => {
                this.capacitySlots = capacitySlots;
            });

            this.planningGroups$.pipe(takeUntil(this.destroy$)).subscribe((planningGroups: IdNameAsStringObject[]) => {
                this.planningGroups = planningGroups;
            });

            this.hauliers$.pipe(takeUntil(this.destroy$)).subscribe((hauliers: Haulier[]) => {
                this.planningHauliers = hauliers;
                this.filteredPlanningHauliers = hauliers;
            })

            this.drivers$.pipe(takeUntil(this.destroy$)).subscribe((drivers: Driver[]) => {
                this.drivers = drivers;
                this.filteredDrivers = this.drivers;
                this.filteredCoDrivers = this.drivers;
                if(drivers && drivers.length > 0) {
                    this.recalculateRelevantDriverAbsences();
                    if(this.selectedHaulier) {
                        this.filterCapaciySlotsOnHaulier();
                    }
                }
            })

            this.chassis$.pipe(takeUntil(this.destroy$)).subscribe((res : Chassis[]) => {
                this.chassis = res ;
            })

            this.trailers$.pipe(takeUntil(this.destroy$)).subscribe((res : Trailer[]) => {
                this.trailers = res ;
            })

            this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders: TransportOrder[]) => {
                
                if(this.planningHauliers.length > 0){
                    let haulierList = this.planningHauliers.filter(h => this.selectedTransportOrder.haulierNo === h.id);
                    if (haulierList.length > 0)
                        this.selectedHaulier = haulierList[0];
                    this.haulierSelection.emit(this.selectedHaulier);
                }

                if(this.planningGroups && this.planningGroups.length > 0){
                    let planningGroupList = this.planningGroups.filter(pg => this.selectedTransportOrder.planningGroup === pg.id);
                    if(planningGroupList && planningGroupList.length > 0){
                        this.selectedPlanningGroup = planningGroupList[0];
                        this.planningGroupSelection.emit(this.selectedPlanningGroup);
                    }
                }

                if(transportOrders && transportOrders.length == 1){
                    let assignedTruck = this.selectedTransportOrder.truckNo;
    
                    let selectedCapacity = this.capacitySlots.filter((capacitySlot : CapacitySlot) => {
                        return capacitySlot.truck.id === assignedTruck;
                    })[0];

                    if(selectedCapacity) {
                        this.selectedCapacity = selectedCapacity;
                    }
                    else{
                        this.selectedCapacity = null;
                    }
                }

                if(this.drivers){
                    let toDriverList = this.drivers.filter(d => d.id === this.selectedTransportOrder.firstDriver);
                    if (toDriverList.length > 0)
                        this.selectedDriver = toDriverList[0];
                    this.driverSelection.emit(this.selectedDriver);
    
                    let toCoDriverList = this.drivers.filter(d => d.id === this.selectedTransportOrder.firstCoDriver);
                    if (toCoDriverList.length > 0)
                        this.selectedCoDriver = toCoDriverList[0];
                    this.coDriverSelection.emit(this.selectedCoDriver);

                    this.filterHauliersOnPlanningGroup();
                    this.filterCapaciySlotsOnHaulier();
                    this.filterDriversOnHaulier();
                    this.filterCoDriverOnDriver();
                    this.filterDriverOnCoDriver();
                }
            })
        });
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    filterHauliersOnPlanningGroup(){
        if(this.selectedPlanningGroup && this.planningHauliers && this.planningHauliers.length > 0) {
            if(this.selectedPlanningGroup.id === 'ALL'){
                this.filteredPlanningHauliers = this.planningHauliers;
            }
            else{
                let filteredPlanningHauliers = this.planningHauliers.filter(h => h.planningGroup === this.selectedPlanningGroup.id);
                this.filteredPlanningHauliers = filteredPlanningHauliers;
            }
        }
        else {
            this.filteredPlanningHauliers = this.planningHauliers;
        }
    }

    filterCapacitySlotsOnPlanningGroup(){
        if(this.selectedPlanningGroup && !this.selectedHaulier && this.capacitySlots && this.capacitySlots.length > 0){
            if(this.selectedPlanningGroup.id === 'ALL'){
                this.filteredCapacitySlots = this.capacitySlots;
            }
            else{
                let filteredCapacitySlots = this.capacitySlots.filter(c => c.truck.planningGroupID === this.selectedPlanningGroup.id);
                this.filteredCapacitySlots = filteredCapacitySlots;
            }
        }
        else if(this.selectedHaulier) this.filterCapaciySlotsOnHaulier();
        else{
            this.filteredCapacitySlots = this.capacitySlots;
        }
    }

    filterDriversOnHaulier() {
        if (this.selectedHaulier && this.drivers && this.drivers.length > 0) {
            this.filteredDrivers = this.drivers.filter((driver: Driver) => driver.haulierNo === this.selectedHaulier.id);
            this.filteredCoDrivers = this.filteredDrivers;
            this.initialFilteredDrivers = this.filteredDrivers;
        }
        else {
            this.filteredDrivers = this.drivers;
            this.filteredCoDrivers = this.filteredDrivers;
            this.initialFilteredDrivers = this.filteredDrivers;
        }
    }

    filterCoDriverOnDriver() {
        if (this.selectedDriver && this.initialFilteredDrivers && this.initialFilteredDrivers.length > 0) {
            let filteredCoDrivers = this.initialFilteredDrivers.filter((driver: Driver) => driver.id != this.selectedDriver.id);
            this.filteredCoDrivers = filteredCoDrivers;
        }
        else {
            this.filteredCoDrivers = this.initialFilteredDrivers;
        }

        if(this.selectedCoDriver && this.filteredCoDrivers.map(driver => driver.id).indexOf(this.selectedCoDriver.id) < 0){
            this.updateCoDriverSelection(null);
        }
    }

    filterDriverOnCoDriver() {
        if (this.selectedCoDriver && this.initialFilteredDrivers && this.initialFilteredDrivers.length > 0) {
            this.filteredDrivers = this.initialFilteredDrivers.filter((driver: Driver) => driver.id != this.selectedCoDriver.id);
        }
        else {
            this.filteredDrivers = this.initialFilteredDrivers;
        }

        if(this.selectedDriver && this.filteredDrivers.map(driver => driver.id).indexOf(this.selectedDriver.id) < 0){
            this.updateDriverSelection(null);
        }
    }

    public ngOnChanges(simpleChange: SimpleChanges): void {
        if (simpleChange && simpleChange.selectedTransportOrder && !simpleChange.selectedTransportOrder.firstChange) {
            this.selectedTransportOrder = simpleChange.selectedTransportOrder.currentValue;
            if (this.selectedTransportOrder) this.selectedTransportOrderNo = this.selectedTransportOrder.id;
            else this.selectedTransportOrderNo = null;
            
            if (this.selectedTransportOrder) {
                this.tranpsorOrderDate = this.selectedTransportOrder.date;
            }

            this.setInitialPlanDate();
            this.formatDateForApi();
            this.sharedActions.getCapacitySlotsLight(this.apiFormattedDate);
        }

        if (simpleChange && simpleChange.selectedCapacity) {
            this.selectedCapacity = simpleChange.selectedCapacity.currentValue;
            this.setSelectedHaulierFromSelectedCapacity();
            this.filterDriversOnHaulier();
        }
    }

    setSelectedHaulierFromSelectedCapacity() {
        if (this.selectedCapacity) {
            let haulierNoForCapacity = null;
            let capacityTruckHaulierNo = this.selectedCapacity.truck.haulierNo;
            if (capacityTruckHaulierNo && capacityTruckHaulierNo != "") haulierNoForCapacity = capacityTruckHaulierNo;
            let selectedHaulier = this.planningHauliers.filter((haulier: Haulier) => {
                return haulier.id === haulierNoForCapacity;
            })[0];

            if (selectedHaulier) this.selectedHaulier = selectedHaulier;
        }
    }

    public updatePlanDate() {
        // the current selection needs to be reset when a new list of capacities is fetched
        // had to force undefined,to fix the null value bug
        this.selectedCapacity = undefined;
        this.enableCapacityInput();
        this.capacitySlots = [];
        this.updateCapacitySlots();
        this.capacitySelection.emit(this.selectedCapacity);
        this.recalculateRelevantDriverAbsences();
    }

    public updateCapacitySelection(data: CapacitySlot) {
        if (data) {
            this.disableHaulierInput();
            this.setSelectedPlanningGroupFromCapacity();
            this.setSelectedHaulierFromCapacity();
            this.setSelectedDriverFromCapacity();
            this.capacitySelection.emit(this.selectedCapacity);
        }
        else {
            this.capacitySelection.emit(null);
            this.filterCapaciySlotsOnHaulier();
            this.enableCapacityInput();
            this.enableHaulierInput();
            this.enablePlanningGroupInput();
        }
    }

    public setSelectedDriverFromCapacity() {
        if(this.selectedCapacity && this.selectedCapacity.driver)  {
            let selectedCapacityDriver = this.drivers.filter(driver => driver.id === this.selectedCapacity.driver.id)[0];
            this.updateDriverSelection(selectedCapacityDriver);
        }
    }

    public setSelectedPlanningGroupFromCapacity(){
        if(this.selectedCapacity && this.selectedCapacity.truck && this.selectedCapacity.truck.planningGroupID){
            let selectedPlanningGroup = this.planningGroups.filter(pg => pg.id === this.selectedCapacity.truck.planningGroupID)[0];
            this.selectedPlanningGroup = selectedPlanningGroup;
            this.disabledPlanningGroupInput = true;
            this.planningGroupSelection.emit(this.selectedPlanningGroup);
        }
    }

    public setSelectedHaulierFromCapacity(){
        if(this.planningHauliers && this.planningHauliers.length > 0 
            && this.selectedCapacity && this.selectedCapacity.truck 
            && this.selectedCapacity.truck.planningGroupID){
            let selectedHaulier = this.planningHauliers.filter(h => h.id === this.selectedCapacity.truck.haulierNo)[0];
            this.updateHaulierSelection(selectedHaulier);
        }
    }

    public updateHaulierSelection(data: Haulier) {
        this.haulierSelection.emit(data);
        this.filterCapaciySlotsOnHaulier();
        this.filterDriversOnHaulier();
        this.filterCoDriverOnDriver();
        this.enableCapacityInput();
    }

    public updatePlanningGroupSelection(data: IdNameAsStringObject) {
        this.selectedHaulier = null;
        this.haulierSelection.emit(null);
        this.selectedCapacity = null;
        this.capacitySelection.emit(null);
        this.selectedDriver = null;
        this.driverSelection.emit(null);
        this.selectedCoDriver = null;
        this.coDriverSelection.emit(null);

        this.selectedPlanningGroup = data;
        this.enableHaulierInput();
        this.filterHauliersOnPlanningGroup();
        this.filterCapacitySlotsOnPlanningGroup();
        
        this.planningGroupSelection.emit(this.selectedPlanningGroup);
    }

    public updateDriverSelection(data: Driver) {
        this.selectedDriver = data;
        this.driverSelection.emit(data);
        
        if (data && data.releventAbsence) {
            this.selectedDriverIsAbsent = true;
        } else {
            this.selectedDriverIsAbsent = false;
        }

        this.filterCoDriverOnDriver();
    }

    public updateCoDriverSelection(data: Driver) {
        this.selectedCoDriver = data;
        this.coDriverSelection.emit(data);
        
        if(data && data.releventAbsence){
            this.selectedCoDriverIsAbsent = true;
        }
        else{
            this.selectedCoDriverIsAbsent = false;
        }

        this.filterDriverOnCoDriver();
    }


    filterCapaciySlotsOnHaulier() {
        this.filteredCapacitySlots = this.capacitySlots;
        if(this.selectedHaulier && this.capacitySlots.length > 0){
            this.filteredCapacitySlots = this.capacitySlots.filter((capacity) => {
                let haulierNoForCapacity = null;
                let capacityTruckHaulierNo = capacity.truck.haulierNo;
                if(capacityTruckHaulierNo && capacityTruckHaulierNo != "") haulierNoForCapacity = capacityTruckHaulierNo;
                return haulierNoForCapacity === this.selectedHaulier.id || !haulierNoForCapacity;
            })
        }
    }

    disableHaulierInput() {
        let selectedHaulierFromCapacity: Haulier = null;
        let haulierNoForSeletedCapacity = null;
        if (this.selectedCapacity && this.selectedCapacity.driver || this.selectedCapacity.truck) {
            let selectedCapacityDriverHaulierNo = this.selectedCapacity.driver.haulierNo;
            let selectedCapacityTruckHaulierNo = this.selectedCapacity.truck.haulierNo;
            if (selectedCapacityTruckHaulierNo && selectedCapacityTruckHaulierNo != "") haulierNoForSeletedCapacity = selectedCapacityTruckHaulierNo;
            else if (selectedCapacityDriverHaulierNo && selectedCapacityDriverHaulierNo != "") haulierNoForSeletedCapacity = selectedCapacityDriverHaulierNo;
            let haulierForSelectedCapacityArray = this.planningHauliers.filter((haulier) => haulier.id === haulierNoForSeletedCapacity);
            if (haulierForSelectedCapacityArray.length > 0) {
                selectedHaulierFromCapacity = haulierForSelectedCapacityArray[0];
                this.selectedHaulier = selectedHaulierFromCapacity;
                this.haulierSelection.emit(this.selectedHaulier);
                // this.updateHaulierSelection(selectedHaulierFromCapacity);
                this.disabledHaulierInput = true;
            }
            else {
                this.resetHaulierInput(true);
            }
        }
        else {
            this.resetHaulierInput(false);
        }
    }

    enableHaulierInput(){
        this.disabledHaulierInput = false;
    }

    enablePlanningGroupInput(){
        this.disabledPlanningGroupInput = false;
    }

    public resetHaulierInput(disable: boolean) {
        this.selectedHaulier = undefined;
        if (disable) this.disabledHaulierInput = true;
        else this.disabledHaulierInput = false;
    }

    public updateCapacitySlots() {
        this.formatDateForApi();
        this.sharedActions.getCapacitySlotsLight(this.apiFormattedDate);
    }

    public formatDateForApi() {
        // const dateArray = this.planDate.split("-");
        // this.apiFormattedDate = dateArray[1] + "/" + dateArray[2] + "/" + dateArray[0];

        this.apiFormattedDate = this.planDate.toISOString();
    }

    public setSelectionFromParent(capacity: string): CapacitySlot {
        const foundCapacity = this.capacitySlots.find((x) => x.truck.name === capacity);
        if (foundCapacity) {
            this.selectedCapacity = foundCapacity;
        } else {
            this.selectedCapacity = this.emptyCapacity;
        }
        return this.selectedCapacity;
    }

    public autocompleListFormatter(data: any): string {
        if (data.driver.name != null) {
            return `${data.truck.name} - ${data.driver.name}`;
        } else {
            return `${data.truck.name} `;
        }
    }

    public valueListFormatter(data: any): string {
        if (data.driver.name != null) {
            return `${data.truck.name} - ${data.driver.name}`;
        } else {
            return `${data.truck.name} `;
        }
    }

    public autocompleListFormatterForHaulier(data: Haulier): string {
        return `${data.name}` ;
    }

    public valueListFormatterForHaulier(data: Haulier): string {
        return `${data.name}`;
    }

    public autocompleteListFormatterForPlanningGroup(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public valueListFormatterForPlanningGroup(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public autocompleListFormatterForTrailer(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public valueListFormatterForTrailer(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public autocompleListFormatterForChassis(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }

    public valueListFormatterForChassis(data: IdNameAsStringObject): string {
        return `${data.name}`;
    }


    public autocompleListFormatterForDriver(data: Driver): string {
        const hasReleventAbsence = data.releventAbsence != null && data.releventAbsence != undefined;
        const content = hasReleventAbsence
            ? `<i class="fa fa-user absent-driver-icon"></i> `
            : ``
        return `${content}${data.name}`;
    }

    public valueListFormatterForDriver(data: Driver): string {
        if(!data) return "";
        return `${data.name}`;
    }

    public enableCapacityInput() {
        this.disabledCapacityInput = false;
    }

    public disableCapacityInput() {
        this.disabledCapacityInput = true;
    }

    public enableDateInput() {
        this.disabledDateInput = false;
    }

    public disableDateInput() {
        this.disabledDateInput = true;
    }

    public setInitialPlanDate() {
        if (this.tranpsorOrderDate) {
            const currentDate = new Date(this.tranpsorOrderDate);
            const currentDateHours = currentDate.getHours();

            // this.planDate = currentDate.toISOString().slice(0, 10);

            this.planDate = currentDate;

            this.recalculateRelevantDriverAbsences();
        }
    }

    public setPlanDateToPreviousDay(currentDate) {
        const subtractXDays = 1;
        const newDate = new Date(this.tranpsorOrderDate);

        newDate.setDate(currentDate.getDate() - subtractXDays);
        // this.planDate = newDate.toISOString().slice(0, 10);
        this.planDate = newDate;
    }

    public isHourBetweenMidnightAndFiveAm(currentDateHours) {
        return currentDateHours >= 0 && currentDateHours < 5;
    }

    public recalculateRelevantDriverAbsences(){
        if(this.drivers && this.drivers.length > 0){
            this.drivers.forEach(driver => {
                if(driver.absences && driver.absences.length > 0) {
                    let releventAbsence = null;
                    driver.absences.forEach(absence => {
                        let absenceFrom = moment(moment(absence.fromDate).toISOString().split("T")[0] + "T" + moment(absence.fromTime).toISOString().split("T")[1]);
                        let absenceTo = moment(moment(absence.toDate).toISOString().split("T")[0] + "T" + moment(absence.toTime).toISOString().split("T")[1]);
    
                        if(absenceFrom.isSameOrBefore(moment.utc(this.planDate).hours(23).minutes(59).seconds(59)) 
                        && absenceTo.isSameOrAfter(moment.utc(this.planDate).hours(0).minutes(0).seconds(0))) releventAbsence = absence;
                    })
                    if(releventAbsence) driver.releventAbsence = releventAbsence;
                    else driver.releventAbsence = null;
                }
            })
        }
    }

    public prettifyDateForAbsence(date : string, time: string) : string {
        let dateMoment = moment.utc(date.split("T")[0] + "T" + time.split("T")[1]);
        if(dateMoment.isValid()) return dateMoment.format("L LT");
        else return "invalid date";
    }

    public confirmAbsentDriverSelection(){
        this.selectedDriverIsAbsent = false;
        this.filterCoDriverOnDriver();
    }

    public cancelAbsentDriverSelection(){
        this.selectedDriverIsAbsent = false;
        this.updateDriverSelection(null);
        this.filterCoDriverOnDriver();
    }

    public confirmAbsentCoDriverSelection(){
        this.selectedCoDriverIsAbsent = false;
        this.filterDriverOnCoDriver();
    }

    public cancelAbsentCoDriverSelection(){
        this.selectedCoDriverIsAbsent = false;
        this.updateCoDriverSelection(null);
        this.filterDriverOnCoDriver();
    }

}