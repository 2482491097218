export class PlannedItem{
    orderlines: PlannedItemOrderline[];
    visibleOrderlines: any[];
    plannedItemType: number;
    leftMargin: number;
    upperPoint: Point;
    lowerPoint: Point;
    isHidden: boolean;
    futureIndicatorLeftMargin: number;
    hasPastPBs: boolean;
    hasFuturePBs: boolean;
}

export interface PlannedItemOrderline{
    plannedDateTimeFrom: string;
    plannedDateTimeUntil: string;
}

export interface Point{
    x: number;
    y: number;
}