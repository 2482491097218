
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClientService } from '../../shared/services/httpclient';
import { environment } from '../../../environments/environment';

@Injectable()
export class DriverAgendaService {

  constructor(private http: HttpClientService) { }

  addTruckAssignment(capacity) {
    return this.http.post(environment.tmsApiEndpointAddress + 'driveragenda/capacity', capacity).pipe(
      map((response: any) => response.data));
  }

  getAvailableTrucksForDriver(group: string, zone: string, haulierNo: any) {
    return this.http.get(environment.tmsApiEndpointAddress + 'driveragenda/trucks?planningGroup=' + group + '&planningZone=' + zone +
      '&haulierNo=' + haulierNo).pipe(map((response: any) => response.data));
  }

  getDriverAgenda(startDate: any, endDate: any, group: string, zone: string) {
    return this.http.get(environment.tmsApiEndpointAddress + 'driveragenda' + '?timeFrom=' + startDate.toISOString()
        + "&timeUntil=" + endDate.toISOString() + '&group=' + group + '&zone=' + zone).pipe(
      map((response: any) => response.data));
  }

  getCausesOfAbsence() {
    return this.http.get(environment.tmsApiEndpointAddress + 'driveragenda/absence/causes').pipe(
      map((response: any) => response.data));
  }

  calculateAbsenceDays(toDate: Date, fromDate: Date) {
    return this.http.get(environment.tmsApiEndpointAddress + 'driveragenda/absence/days?timeFrom=' + fromDate.toISOString()
        + '&timeUntil=' + toDate.toISOString()).pipe(
      map((response: any) => response.data));
  }

  registerAbsence(absence: any) {
    return this.http.post(environment.tmsApiEndpointAddress + 'driveragenda/absence', absence).pipe(
      map((response: any) => response.data));
  }

  updateTruckAssignment(id: any, capacity: any) {
    return this.http.put(environment.tmsApiEndpointAddress + 'driveragenda/capacity/'+ id, capacity).pipe(
      map((response: any) => response.data));
  }

  deleteTruckAssignment(id: any) {
    return this.http.delete(environment.tmsApiEndpointAddress + 'driveragenda/capacity/' + id).pipe(
      map((response: any) => response.data));
  }

  updateDriverAbsence(id: any, absence: any) {
    return this.http.put(environment.tmsApiEndpointAddress + 'driveragenda/absence/'+ id, absence).pipe(
      map((response: any) => response.data));
  }

  deleteDriverAbsence(id: any) {
    return this.http.delete(environment.tmsApiEndpointAddress + 'driveragenda/absence/' + id).pipe(
      map((response: any) => response.data));
  }

}
