import { OnInit, Component, Input, ViewChild, ElementRef, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subscription } from "rxjs";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { OrderLine } from "../../../models/order-line/orderLine.interface";
import { WizardDefinition } from "../../../../shared/models/wizard-definition";
import { ActionWizardService } from "../../../../shared/services/action-wizard.service";
import { PbScreenService } from "../../../services/pb-screen.service";
import { GridComponent, GridDataResult, PageChangeEvent, SelectableSettings } from "@progress/kendo-angular-grid";
import { State } from "@progress/kendo-data-query";
import { ToastrService } from "ngx-toastr";
import { PbActions } from "../../../pb.actions";
import { OrderlineService } from "../../../../shared/services/orderline.service";
import { SharedActions } from "../../../../shared/shared.actions";
import { ToScreenService } from "../../../services/to-screen.service";
import { PlanningBoardActions } from "../../../../planningBoard/planningBoard.action";
import { SimpleModalComponent } from "ngx-simple-modal";
import { environment } from "../../../../../environments/environment";
import { ToActions } from "../../../../to-screen/to.actions";
import { ApplicationUser } from "../../../models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'pb-insert-action-wizard-modal',
    templateUrl: './pb-insert-action-wizard-modal.component.html'
})

export class PbInsertActionWizardComponent extends SimpleModalComponent<void, boolean>  implements OnInit, OnDestroy {

    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    @select('selectedPlanningBlocks') selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('selectedOrderlines') selectedOrderlines$: Observable<OrderLine[]>;
    @select('selectedActionWizard') selectedActionWizard$: Observable<WizardDefinition>;


    @select("selectedPbsFromPlanBoard") selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>
    @select("selectedPbsToPlan") public selectedPbsToPlan$ : Observable<PlanningBlock[]>

    @select("selectedOrderlinesFromBoard") selectedOrderlinesFromBoard$ : Observable<any[]>;
    @select('pbOrderlines') public pbOrderlines$: Observable<OrderLine[]>;
    @select('loading') public loading$: Observable<boolean>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    public enableAutocomplete: boolean = false;
    public selectedOrderlineId: number;
    public selectedPb: PlanningBlock;
    public selectedPbs: PlanningBlock[];
    public orderlines: OrderLine[] = [];
    public selectedOrderlines: OrderLine[] = [];

    public selectedActionWizard: WizardDefinition;
    private currentStep: number;
    private navigationEnabled: boolean = true;
    public gridData: GridDataResult;
    public wizardLines: any;
    public searchFilter: string = '';
    public selectedWizardLineAddresses = [];

    public shouldAskAdress = false;
    public addressAsked = false;
    public wizardManuallySelectOrderline = true;
    public wizardSelectDateTime = false;

    public wizardResult: any;

    public selectedRow: number;
    public shipOrRailState: State = {
        skip: 0,
        take: 25,
    };
    public shipOrRailGridData: GridDataResult;

    private _shipRailGrid: GridComponent;
    @ViewChild('shipRailGrid') set shipRailGrid(shipRailGrid: GridComponent)
    {
        if(shipRailGrid)
            this._shipRailGrid = shipRailGrid;
    }

    public requestedDateTimeUntil: Date;
    public requestedDateTimeFrom: Date;
    public bookedDateTimeFrom: Date;
    public bookedDateTimeUntil: Date;

    public shipOrRailSelection: ShippingLine[] = [];
    public showShippingOrRailwayLines: boolean = false;
    shipOrRailLines: ShippingLine[] = [];
    selectedShipOrRailLineNo: string;
    railwayEnabled: boolean = environment.railway;
    shippingEnabled: boolean = environment.shipping;


    public selectedWizard = {
        index: 0,
        addressList: []
    }


    public state: State = {
        skip: 0,
        take: 25,
    };
    public selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: 'single',
    };
    public mySelection: OrderLine[] = [];

    public loadingSpinner: boolean = false;

    private subscriptions: Subscription = new Subscription();

    constructor(private readonly actionWizardService: ActionWizardService,
        private readonly pbScreenService: PbScreenService,
        private readonly toastr: ToastrService,
        private readonly pbActions: PbActions,
        public readonly toActions: ToActions,
        private readonly sharedActions: SharedActions,
        private readonly orderlineService: OrderlineService,
        private readonly sharedAction: SharedActions,
        private readonly toScreenService: ToScreenService,
        private readonly planningBoarActions: PlanningBoardActions) {
            super();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    ngOnInit(): void {
        let subscription;
      this.currentStep = 1;

      subscription = this.loading$.subscribe((data) => {
        if (!data)
          this.loadingSpinner = data;
      })
      this.subscriptions.add(subscription);

      subscription = this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });
      this.subscriptions.add(subscription);

        subscription = this.selectedPlanningBlocks$.subscribe((planningBlocks) => {
            if(planningBlocks && planningBlocks.length > 0)
            {
                let prevId = this.selectedPb ? this.selectedPb.id : null;
                this.selectedPbs = planningBlocks;
                this.selectedPb = planningBlocks[0];

            }
        })
        this.subscriptions.add(subscription);

        subscription = this.selectedPbsToPlan$.subscribe((planningBlocks) => {
            if(planningBlocks && planningBlocks.length > 0)
            {
                let prevId = this.selectedPb ? this.selectedPb.id : null;
                this.selectedPbs = planningBlocks;
                this.selectedPb = planningBlocks[0];

                if (this.selectedPb && this.selectedPb.id != prevId) {
                    this.pbScreenService.getPlanningBlockDetails(this.selectedPb).subscribe((data: OrderLine[]) => {
                        this.orderlines = data;
                        this.setGridContent();

                    })
                }
            }
        })
        this.subscriptions.add(subscription);

        subscription = this.selectedPbsFromPlanBoard$.subscribe((planningBlocks) => {
            if(planningBlocks && planningBlocks.length > 0)
            {
                let prevId = this.selectedPb ? this.selectedPb.id : null;
                this.selectedPbs = planningBlocks;
                this.selectedPb = planningBlocks[0];
            }
        })
        this.subscriptions.add(subscription);

        this.pbOrderlines$.subscribe((data) => {
            this.orderlines = data;
            this.setGridContent();
        })

        subscription = this.selectedOrderlinesFromBoard$.subscribe((ol) => {
            if(ol && ol.length > 0) {
                this.pbScreenService.getPlanningBlocksByIds(ol.map( x => x.planningBlockId)).subscribe((pbs) => {
                    let prevId = this.selectedPb ? this.selectedPb.id : null;
                    this.selectedPb = pbs[0];
                    if (this.selectedPb && this.selectedPb.id != prevId) {
                        this.pbScreenService.getPlanningBlockDetails(this.selectedPb).subscribe((data: OrderLine[]) => {
                            this.orderlines = data;
                            this.setGridContent();

                        })
                    }
                })
            }
        })
        this.subscriptions.add(subscription);

        subscription = this.selectedActionWizard$.subscribe((actionWizard) => {
            this.selectedActionWizard = actionWizard;
        })
        this.subscriptions.add(subscription);
    }

    getWizardLines() {
        if (this.selectedOrderlineId && this.selectedActionWizard) {
            this.actionWizardService.getWizardLines(this.selectedActionWizard.code, this.selectedOrderlineId).subscribe((res) => {
                this.wizardLines = res;
            })
        }
    }

    getAddressesForWizard(wizardLine) {
        if (this.searchFilter.length >= 3) {
            this.actionWizardService.getAddresses(wizardLine.wizardCode, wizardLine.lineNo, this.searchFilter).subscribe((data) => {
                this.selectedWizard.addressList = data;
            })
        } else {
            this.toastr.warning('Please type at least 3 characters to search for an address.');
        }

    }

    executeWizard(wizardData) {
        this.loadingSpinner = true;
        this.actionWizardService.executeWizard(wizardData).subscribe((data) => {
            //Test if inserting dateTime for pbs is required
            let timerequiredLine = this.wizardLines.find((x) => x.timeRequired === true);
            if (this.wizardLines.length > 0 && timerequiredLine) {
                this.wizardManuallySelectOrderline = false;
                this.shouldAskAdress = false;
                this.wizardSelectDateTime = true;
                this.currentStep++;

                this.actionWizardService.getNewSplittedPBAndOLCreatedByIAW(wizardData.orderLineId , wizardData.code).subscribe((wizardPbs) => {
                  this.wizardResult = wizardPbs;
                  if(!wizardPbs || !wizardPbs.length)
                    this.wizardResult = data.result;
                }, error => this.wizardResult = data.result);

                this.actionWizardService.getNewSplittedPBsCreatedByIAW(wizardData.orderLineId , wizardData.code).subscribe((data) => {
                    this.sharedAction.updatePlanningBlocksDisplay(data);
                    this.pbActions.setSelectedPlanningBlocks([...this.selectedPbs,...data]);
                });
                this.toScreenService.getTransportOrdersByIds(this.selectedPb.transportOrderNumber).subscribe((data) => {
                    var orderlines = data[0].planningOrderlines;
                    this.sharedAction.getOrderlines(orderlines);
                    this.planningBoarActions.setSelectionType('transportOrder');
                    this.toActions.setSelectedTransportOrders(data);
                    this.planningBoarActions.UpdatePlanningDisplay(data);
                })
            } else {
                this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                this.selectedPbs = data.result;
                var prevselectedPB = this.selectedPb;
                this.selectedPb = this.selectedPbs[0];
                this.actionWizardService.getNewSplittedPBsCreatedByIAW(wizardData.orderLineId , wizardData.code).subscribe((data) => {
                    this.sharedAction.updatePlanningBlocksDisplay(data);
                    this.pbActions.setSelectedPlanningBlocks([...this.selectedPbs,...data]);
                });

                this.toScreenService.getTransportOrdersByIds(this.selectedPb.transportOrderNumber).subscribe((data) => {
                    var orderlines = data[0].planningOrderlines;
                    this.sharedAction.getOrderlines(orderlines);
                    this.planningBoarActions.setSelectionType('transportOrder');
                    this.toActions.setSelectedTransportOrders(data);
                    this.planningBoarActions.UpdatePlanningDisplay(data);
                })

                this.sharedActions.deleteSharedPlanningBlocksDisplay([prevselectedPB]);
                this.sharedActions.updatePlanningBlocksDisplay(data.result);

                this.closeModal(true);
            }
            this.loadingSpinner = false;
        }, (err) => {
            this.loadingSpinner = false;
            this.closeModal(false);
        })
    }

    // To Do : Update orderlines in result pb (add dateTime)
    saveDateTimeForPbs() {
        this.loadingSpinner = true;
        let olsInProgress = {};
        let hasErrorOnSave = false;
        for (const key in this.wizardResult) {
            let tempPb = this.wizardResult[key];

            for (const p in tempPb.planningOrderlines) {
                let tempOl = tempPb.planningOrderlines[p];
                olsInProgress[tempOl.orderLineId] = true;
            }
        }

        this.wizardResult.forEach(element => {
            let newCreatedPbs = [];
            element.planningOrderlines.forEach(ol => {
                ol.bookedDateTimeFrom = new Date(this.bookedDateTimeFrom);
                ol.bookedDateTimeUntil = new Date(this.bookedDateTimeUntil);
                ol.requestedDateTimeFrom = new Date(this.requestedDateTimeFrom);
                ol.requestedDateTimeUntil = new Date(this.requestedDateTimeUntil);
                this.orderlineService.updateOrderline(ol.planningBlockId, ol.orderLineId, ol, false).subscribe((res) => {
                    newCreatedPbs.push(res);
                    this.pbActions.updatePlanningBlocksDisplay(newCreatedPbs);
                    this.pbActions.resetPlanningBlocks();
                    this.toastr.success("Wizard Saved", 'The wizard got successfully executed and saved.');
                    this.loadingSpinner = false;
                    this.closeModal(true);
                }, (error) => {
                    this.closeModal(false);
                })
            });
        });

    }


    private previous(): void {
        this.currentStep--;
        this.showShippingOrRailwayLines = false;
    }

    private next(): void {
        if (!this.selectedOrderlineId && this.wizardManuallySelectOrderline) {
            this.toastr.error('Please select an orderline before proceeding');
        } else {
            if (this.wizardLines.length > 0) {
                if(this.selectedActionWizard.useShippingLines || this.selectedActionWizard.useRailWayLines)
                {
                    this.shouldAskAdress = false;
                    this.actionWizardService.getFilteredShippingLineList(this.selectedActionWizard.code).subscribe(data => {
                        this.showShippingOrRailwayLines = true;
                        this.shipOrRailLines = data;
                        this.setShipOrRailGridContent();
                    }, err => {
                        this.currentStep--;
                    });
                }
                else if (this.selectedActionWizard.useSameAddress) {
                    if (this.wizardLines[0].addressNo) {
                        this.selectedWizardLineAddresses.push({
                            lineNo: this.wizardLines[0].lineNo,
                            addressNo: this.wizardLines[0].addressNo
                        })
                        this.shouldAskAdress = false;
                        //this.currentStep--;
                        let dataToSave = {
                            addressList: this.selectedWizardLineAddresses.map((x) => x.addressNo).join(";"),
                            calledFrom: 0,
                            code: this.selectedActionWizard.code,
                            orderLineId: this.selectedOrderlineId
                        }
                        this.executeWizard(dataToSave);

                    } else {
                        this.shouldAskAdress = true;
                    }
                } else {
                    this.wizardLines.forEach(element => {
                        if (element.addressNo) {
                            var alreadyPushedAdrs = this.selectedWizardLineAddresses.map((x) => x.lineNo === element.lineNo);
                            if (alreadyPushedAdrs.length === 0) {
                                this.selectedWizardLineAddresses.push({
                                    lineNo: element.lineNo,
                                    addressNo: element.addressNo
                                });
                                this.shouldAskAdress = false;
                            }
                        } else {
                            this.shouldAskAdress = true;
                        }
                    });
                }
            }
            this.currentStep++;
        }

    }

    public pageChange(event: PageChangeEvent): void {
        this.shipOrRailState.skip = event.skip;
        this.setShipOrRailGridContent();
      }

    private saveData(): void {
        if(this.showShippingOrRailwayLines)
        {
            this.saveShipOrRailData();
            return;
        }
        if (this.shouldAskAdress && this.selectedWizardLineAddresses.length === 0) {
            this.toastr.error('Please select an address before proceeding');
            return;
        }
        if (!this.selectedActionWizard.useSameAddress && this.shouldAskAdress && this.selectedWizardLineAddresses.length !== this.wizardLines.length) {
            this.toastr.error('Please select an address for each wizard line before proceeding');
            return;
        }
        else if (this.shouldAskAdress && !this.addressAsked) {
            if (this.selectedOrderlineId && this.wizardManuallySelectOrderline) {
                this.wizardManuallySelectOrderline = false;
            }
        }

        let dataToSave = {
            addressList: this.selectedWizardLineAddresses.map((x) => x.addressNo).join(";"),
            calledFrom: 0,
            code: this.selectedActionWizard.code,
            orderLineId: this.selectedOrderlineId
        }

        this.executeWizard(dataToSave);

    }

    private setStep(step): void {
        if (this.navigationEnabled) {
            this.currentStep = step;
        }
    }

    public setGridContent(): void {
        this.gridData = {
            data: [...this.orderlines.slice(this.state.skip, this.state.skip + this.state.take)],
            total: this.orderlines.length,
        };
    }

    public rowClicked(gridUser, selection): void {
        this.selectedOrderlineId = selection[0];
        this.mySelection = [...selection];
        this.getWizardLines();
    }

    public selectAddress(lineNo, address, index) {
        let selectedAddress = this.selectedWizardLineAddresses.find((x) => x.lineNo === lineNo);
        if (selectedAddress) {
            selectedAddress.addressNo = address.no;
        } else {
            this.selectedWizardLineAddresses.push({
                lineNo: lineNo,
                addressNo: address.no
            })
        }
        this.selectedRow = index;
        this.shouldAskAdress = true;
    }

    public setAddressContent() {
        this.selectedWizard.addressList = [];
        this.searchFilter = '';

        this.bookedDateTimeFrom = null;
        this.bookedDateTimeUntil = null;
        this.requestedDateTimeFrom = null;
        this.requestedDateTimeUntil = null;
    }

    public closeModal(result = false): void {
        this.result = result;
        this.close();
    }

    saveShipOrRailData() {
        if(!this.selectedShipOrRailLineNo)
        {
            this.toastr.error('Please select a line before proceeding');
            return;
        }

        this.executeShipOrRailWizard();
    }

    executeShipOrRailWizard() {
        let dataToSave = {
            shippingLineNo: this.selectedShipOrRailLineNo,
            calledFrom: 1,
            code: this.selectedActionWizard.code,
            orderLineId: this.selectedOrderlineId
        };
        this.executeWizard(dataToSave);
    }

    public shipOrRailRowClicked(gridUser, selection): void {
        this.selectedShipOrRailLineNo = selection[0];
        this.shipOrRailSelection = [...selection];
    }

    public setShipOrRailGridContent(): void {
        this.shipOrRailGridData = {
            data: [...this.shipOrRailLines.slice(this.shipOrRailState.skip, this.shipOrRailState.skip + this.shipOrRailState.take)],
            total: this.shipOrRailLines.length,
        };
        setTimeout(() => {
            if(this._shipRailGrid)
                this._shipRailGrid.autoFitColumns();
        });
    }

}
