import { TransportOrder } from './../../../models/transport-order/transport-order.interface';
import { PlanningBlock } from './../../../models/planning-block/planning-block.interface';
import { TMSSelectedCMRActions, xmlTMSSelectCMRActionsXML } from './../../../../pb-screen/models/TMSSelectCMRActions.interface';
import { SharedService } from './../../../../shared/services/shared.service';
import { OnInit, Component, ViewChild, ElementRef, Input } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable } from "rxjs";
import { WizardDefinition } from "../../../../shared/models/wizard-definition";
import { OrderLine } from "../../../../pb-screen/models/order-line/orderLine.interface";
import { GridDataResult, SelectableSettings, ColumnResizeArgs } from "@progress/kendo-angular-grid";
import { State, SortDescriptor, orderBy } from '@progress/kendo-data-query';
import { ActionWizardService } from "../../../../shared/services/action-wizard.service";
import { ToScreenService } from "../../../../pb-screen/services/to-screen.service";
import { ToastrService } from "ngx-toastr";

import { OrderlineService } from "../../../../shared/services/orderline.service";
import { PbActions } from "../../../../pb-screen/pb.actions";
import { SharedActions } from "../../../../shared/shared.actions";
import { ApplicationUser } from '../../../../pb-screen/models/application.user.interface';
import { ApplicationUserActions } from '../../../../pb-screen/application.user.actions';
import { GetCmrActionsService } from '../../../../pb-screen/services/getCmrActions.service';
import { ToActions } from '../../../../to-screen/to.actions';
import { TranslateService } from '@ngx-translate/core';


@Component({
    moduleId: module.id,
    selector: 'pb-CmrActions-table',
    templateUrl: './pb-CmrActions-table.html'
})

export class PbCmrActionComponent implements OnInit {

    @ViewChild("modal", { read: ElementRef }) public modal: ElementRef;
    @select('selectedActionWizard') selectedActionWizard$: Observable<WizardDefinition>;
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    @select('cmrActions') tmsSelectCMRActionsXML$: Observable<xmlTMSSelectCMRActionsXML>;
    public selectedActionWizard: WizardDefinition;
    public selectedTransportOrder: TransportOrder;
    public selectedpbID: number;
    private currentStep: number;
    public gridData: GridDataResult;
    public wizardLines: any;
    public searchFilter: string = '';
    public selectedWizardLineAddresses = [];
    public orderlines: OrderLine[] = [];
    public shouldAskAdress = false;
    private mySelection: any[] = [];
    private mySelectionKeys: number[] = [];
    public addressAsked = false;
    public wizardManuallySelectOrderline = false;
    public wizardSelectDateTime = false;
    public selectedCmrActions: TMSSelectedCMRActions[] = [];
    public xmlTMSSelectCMRActionsXML: xmlTMSSelectCMRActionsXML;
    public wizardResult: any;
    public selectedtoId: string;
    public planningblock: PlanningBlock[];
    public selectedPlanningBlocks: PlanningBlock[];
    public selectedRow: number;
    public user: ApplicationUser;
    public requestedDateTimeUntil: Date;
    public requestedDateTimeFrom: Date;
    public bookedDateTimeFrom: Date;
    public bookedDateTimeUntil: Date;

    public orderNumber: number;
    public planningBlockId: number;

    public tooltipOffset = { left: 0, top: 0 };
    public tooltipVisible = false;
    public tooltipValue = '';
    public selectedWizard = {
        index: 0,
        addressList: []
    }
    public displayplanningblockstate: boolean = false;
    public fallbackLanguage: string = 'en';
    public languageToUse: string = 'en';
    public state: State = {
        skip: 0,
        take: 25,
    };
    public selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: 'multiple',
    };
    //public mySelection: PlanningBlock[] = [];

    public loadingSpinner: boolean = false;

    constructor(private readonly actionWizardService: ActionWizardService,
        private readonly toScreenService: ToScreenService,
        private readonly toastr: ToastrService,
        public readonly sharedActions: SharedActions,
        public readonly translate: TranslateService,
        private readonly toActions: ToActions,
        private readonly orderlineService: OrderlineService,
        public readonly userActions: ApplicationUserActions,
        private readonly pbActions: PbActions,
        private readonly sharedService: SharedService,
        private readonly getCmrActionsService: GetCmrActionsService) {

    }

    ngOnInit(): void {
        // this.currentStep = 1;


        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            this.user = applicationUser;
        });

        this.gridData = { data: [], total: 0 };

        this.selectedPlanningBlocks$.subscribe((planningBlock: PlanningBlock[]) => {
            this.selectedPlanningBlocks = planningBlock;
        });
        this.gridData = { data: [], total: 0 };
        this.tmsSelectCMRActionsXML$.subscribe((xmlTMSSelectCMRActionsXML: xmlTMSSelectCMRActionsXML) => {
            this.xmlTMSSelectCMRActionsXML = xmlTMSSelectCMRActionsXML;
            if (this.xmlTMSSelectCMRActionsXML.tmsSelectCMRActions && this.xmlTMSSelectCMRActionsXML.tmsSelectCMRActions.length > 0) {
                this.setGridContent();
            }
        });
    }

    public setGridContent(): void {
        this.gridData = {
            data: [...this.xmlTMSSelectCMRActionsXML.tmsSelectCMRActions.slice(this.state.skip, this.state.skip + this.state.take)],
            total: this.xmlTMSSelectCMRActionsXML.tmsSelectCMRActions.length,
        };
    }
    public rowClicked(gridUser, selection): void {
        if (selection.selectedRows && selection.selectedRows.length > 0) {

            const selectedcmrAction = selection.selectedRows.map((x) => x.dataItem);
            selectedcmrAction[0].select[0] = true;
            console.log(this.xmlTMSSelectCMRActionsXML)
        }

        if (selection.deselectedRows && selection.deselectedRows.length > 0) {

            const deselectedcmrAction = selection.deselectedRows.map((x) => x.dataItem);
            deselectedcmrAction[0].select[0] = '0';
            console.log(this.xmlTMSSelectCMRActionsXML)

        }
        this.sharedService.myUpdatedObject = this.xmlTMSSelectCMRActionsXML;
    }
    public closeModal(): void {
        this.currentStep = 1;
        this.wizardSelectDateTime = false;
        this.toActions.resetActionBarStates();
        this.toActions.updateTransportOrdersDisplay([]);
        this.modal.nativeElement.click();
        this.selectedCmrActions = []
    }
    public resizeGrid(event: ColumnResizeArgs[]) {
        if (this.isTheResizedColumnValid(event)) {
            const column: any = event[0].column;
            const resizedColumnIndex = this.user.toPreferences.selectedToColumns
                .indexOf(this.user.toPreferences.selectedToColumns
                    .filter((x) => x && x.name.substring(x.name.lastIndexOf('.') + 1) === column.field)[0]);

            if (resizedColumnIndex !== -1) {
                if (this.isNewWidthValid(event[0].newWidth)) {
                    this.user.toPreferences.selectedToColumns[resizedColumnIndex].width = event[0].newWidth;
                    this.userActions.updateApplicationUser(this.user, 'defaultToUserSettings',true);
                }
            }
        }
    }
    private isNewWidthValid(newWidth: number): boolean {
        return newWidth > 0;
    }
    private isTheResizedColumnValid(event: ColumnResizeArgs[]): boolean {
        return event.length === 1 && event[0].hasOwnProperty('column');
    }

}