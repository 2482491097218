import { Component, OnInit, ViewChild, TemplateRef, Output, EventEmitter } from "@angular/core";
import { select } from "@angular-redux/store";
import { GridComponent, GridDataResult, DataStateChangeEvent, SelectableSettings, SelectableMode } from '@progress/kendo-angular-grid';
import { SortDescriptor } from '@progress/kendo-data-query';
import { Observable } from "rxjs";
import { TransportOrder } from "../../../models/transport-order/transport-order.interface";
import { TranslateService } from "@ngx-translate/core";
import { Column } from "../../../models/application.user.interface";
import { PlanningBlock } from "../../../models/planning-block/planning-block.interface";
import { ToActions } from "../../../../to-screen/to.actions";


const columnDefinition = [
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.id', field : 'id', width : 100 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.date', field : 'date', width : 90 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.startAddress', field : 'startAddress', width : 100 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.endAddress', field : 'endAddress', width : 100 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.haulierName', field : 'haulierName', width : 100 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.firstTruckDescription', field : 'firstTruckDescription', width : 130 },
  { name : 'TO_COLUMN_SELECTION.COLUMN_NAMES.driver', field : 'driver', width : 100 }
];

@Component({
    moduleId: module.id,
    selector: 'transport-order-master-detail',
    templateUrl: './transport-order-master-detail.component.html'
})
export class TransportOrderMasterDetailComponent implements OnInit {
    
    @select("openTransportOrders") public openTransportOrders$: Observable<TransportOrder[]>;
    @select("filteredOpenTransportOrders") public filteredOpenTransportOrders$: Observable<TransportOrder[]>;
    @select('sessionToColumnSelection') public sessionToColumnSelection$: Observable<Column[]>
    
    @Output() public transportOrderSelection: EventEmitter<TransportOrder> = new EventEmitter();
    @Output() public planningBlockSelection: EventEmitter<PlanningBlock> = new EventEmitter();

    @ViewChild('dateTemplate') public dateTemplate: TemplateRef<any>;
    @ViewChild('toDetailsChildComponent') public toDetailsChildComponent;
    @ViewChild(GridComponent) grid: GridComponent;

    public transportOrders : TransportOrder[];
    public filteredTransportOrders : TransportOrder[];
    public selectedTransportOrder : TransportOrder;
    public selectedPlanningBlock : PlanningBlock;
    public columns = [];
    public columnFields = [];
    public mySelection: number[] = [];

    public checkboxOnly = false;
    public mode : SelectableMode = "single";
    public selectableSettings: SelectableSettings;
    
    constructor(
        private readonly translate: TranslateService,
        private readonly toActions: ToActions) {
        this.setSelectableSettings();
    }

    ngOnInit(): void {
      this.toActions.getAllOpenTransportOrders();
        this.openTransportOrders$.subscribe((transportOrders : TransportOrder[]) => {
            this.transportOrders = transportOrders;
        });

        this.filteredOpenTransportOrders$.subscribe((filteredTransportOrders : TransportOrder[]) => {
            this.filteredTransportOrders = filteredTransportOrders;
        })

        this.columnFields = columnDefinition.map(cd => cd.field);
        this.columns = this.createDataTablesColumns(columnDefinition);
    }

    public updatePlanningBlockSelection(planningBlock : PlanningBlock) {
      if(planningBlock){
        this.selectedPlanningBlock = planningBlock;
        this.planningBlockSelection.emit(planningBlock);
        this.clearSelection();
      }
    }

    public createDataTablesColumns(selectedColumns: Column[]): string[] {
      const result = [];
      for (const column of selectedColumns) {
  
        this.translate.get(column.name).subscribe((translated) => {
  
          result.push({
            cellTemplate: this.dateTemplate,
            name: translated,
            prop: column.name.substring(column.name.lastIndexOf('.') + 1),
            width: column.width,
          });
        });
      }
      return result;
    }

    public rowDetailExpandChange(dataItem : any) {
      this.collapseAll(this.grid);
      this.grid.expandRow(dataItem.index);
    }

    public collapseAll(topGrid) {
      this.transportOrders.forEach((item, idx) => {
        topGrid.collapseRow(idx);
      })
    }
    
    public setSelectableSettings(): void {
      this.selectableSettings = {
          checkboxOnly: this.checkboxOnly,
          mode: this.mode
      };
    }
    
    public rowClicked(gridUser, selection): void {
      this.selectedTransportOrder = selection.selectedRows[0].dataItem;
      this.transportOrderSelection.emit(this.selectedTransportOrder);
      this.selectedPlanningBlock = null;
      if(this.selectedTransportOrder && this.toDetailsChildComponent  && this.toDetailsChildComponent !== undefined) this.toDetailsChildComponent.clearSelection();
    }

    public clearSelection(){
      this.selectedTransportOrder = null;
      this.transportOrderSelection.emit(null);
      this.mySelection = [];
    }
}