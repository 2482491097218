import { NgRedux, select } from '@angular-redux/store';
import { Observable } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { IAppState } from '../../store/IAppState';
import { PBoardChannelService } from './PBoardchannel.service';
import { NGXLogger } from 'ngx-logger';
import { SharedActions } from '../../shared/shared.actions';
import { ApplicationUser } from '../../pb-screen/models/application.user.interface';
import { ConnectionState } from '../../pb-screen/services/channel.service';

@Injectable()
export class PlanningBoardSignalRListener { 
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;

    private signalrUserChannel: string;
    public signalRConnectionAvailable = false;
    public  monitorCallbackArray = [];
    public  signalrConnectionCallbackArray = [];
     public signalRDataChangesSuspended = false;
    public  suspendedSignalRChanges = [];
     public processingSuspendedChanges = false;


    constructor(
        protected readonly ngRedux: NgRedux<IAppState>,
        protected readonly sharedActions: SharedActions,
        protected readonly channelService: PBoardChannelService,
        protected readonly logger: NGXLogger,
    ) {
        this.channelService.starting$.subscribe(
            () => {
                this.applicationUser$.subscribe((data: ApplicationUser) => {
                    if (data) {
                        this.signalrUserChannel = data.id;
                     
                    }
                });
            },
            () => {
                // tslint:disable-next-line:max-line-length
                this.logger.error('SignalR: Service failed to start', this.signalrUserChannel, 'starting', 'SIGNALR', '');
            },
        );
    }

    public initialize(): Promise<any> {

        return new Promise((resolve, reject) => { 
            this.channelService.start().then( () => { 
                this.signalRConnectionAvailable = true;
                

               let allConnectedCallBacks =  this.signalrConnectionCallbackArray.filter((callback:any) => 
                callback.type == 'connected' ) ; 
                
                allConnectedCallBacks.forEach(function (signalrCallback) {
                    signalrCallback.callback();
                });
                resolve() ; 
            } )
            .catch( (error) => 
            { reject() ;} )           
            
        } ) ; 
    }

   

    private getUniqueTabId(): number {
        return sessionStorage.tabID ? sessionStorage.tabID : sessionStorage.tabID = Math.random();
    }

    private messageReceiverIsDifferentFromSender(uniqueTabId: number): boolean {
        return uniqueTabId !== this.getUniqueTabId();
    }

    public addMonitorCallback(monitorCallback) {
        this.monitorCallbackArray.push(monitorCallback);
    }

    public addSignalRCallback(signalRCallback) {
        this.signalrConnectionCallbackArray.push(signalRCallback);
    }

    public processChanges(monitorType, monitorItems) {
        console.log('SignalR Changes : processing ' + monitorType);
        this.monitorCallbackArray.filter( (callBack) => callBack.monitorType == monitorType ).forEach(function (monitorCallback) {

            setTimeout( () => {
                monitorCallback.callback(monitorItems);
                // And it just works!
            });
        });
    }

    public processSuspendedChanges() {
        console.log('Start processing suspended changes');
        if (!this.processingSuspendedChanges) {
            this.processingSuspendedChanges = true;
            this.suspendedSignalRChanges.forEach((suspendedChange) => {
                this.processChanges(suspendedChange.monitorType, suspendedChange.monitorItems);
            })
        }
        this.processingSuspendedChanges = false;
    }

    public suspendSignalRChanges() {
        console.log('SignalR Changes suspended');
        this.signalRDataChangesSuspended = true;
    }

    public unsuspendSignalRChanges() {
        this.signalRDataChangesSuspended = false;
        console.log('SignalR Changes unsuspended');
        this.processSuspendedChanges();
    }


    public DataChangeDetected (monitorType:any , monitorItems: any) 
    { 

        if (!this.signalRDataChangesSuspended) {
            console.log('SignalR Changes Recevied a change NOT suspended');
            this.processChanges(monitorType, monitorItems);
        } else {
            console.log('SignalR Changes Recevied ADDING to suspended changes');
            this.suspendedSignalRChanges.push({ 'monitorType': monitorType, 'monitorItems': monitorItems });
        }
    }

    public initiateMonitors(servicesStarted) { 
        let that = this; 
        this.channelService.hubProxy.on('dataChangeDetected', (monitorType: any, monitorItems: any) => { 
            
           that.DataChangeDetected(monitorType , monitorItems) ; 

        });
        // var monitorHub = this.channelService.window.$.connection.dataMonitorHub;

        // this.channelService.window.$.connection.hub.error(function (error) {
        //     console.log('SignalR error: ' + error);
        // });

        // Create a public that the hub can call to broadcast messages. 
     
        // this.channelService.window.$.connection.hub.logging = true;

        // Start the connection.
        this.channelService.start().then (( ) => {
            this.signalRConnectionAvailable = true;
        }) ;
        
        this.channelService.connectionState$.subscribe( (connectionState: ConnectionState) =>
        { 

            if(connectionState ==  ConnectionState.Disconnected) {
         
                this.signalRConnectionAvailable = false;
    
                this.signalrConnectionCallbackArray.filter( (callBack) => { callBack.type == 'disconnected' }).forEach(function (signalrCallback) {
                    this.signalrCallback.callback();
                });
    
                setTimeout(function () {
                    that.channelService.start().then(function () {
                        that.signalRConnectionAvailable = true;
                        that.signalrConnectionCallbackArray.filter( (callBack) => callBack.type ==  'connected' ).forEach(function (signalrCallback) {
                            signalrCallback.callback();
                        });
                    });
                }, 10000);
            
            }
            else {

            }
         }
             ) ; 
            
       
    }

    

    public isHubAvailable() {
        return this.signalRConnectionAvailable;
    }

    // the monitorSource is the source of the connection. e.g.: "Detached view", "Condensed view"
    
   

    public  createUnassignedPlanningblocksMonitor(startDate , endDate , selectedZone ,selectedGroups)  {
        if (selectedZone != '') {
            this.channelService.startPlanningblocksMonitor("Condensed view", 
                                                      selectedZone, 
                                                      selectedGroups, 
                                                      '', 
                                                       startDate, 
                                                       endDate, 
                                                      '12', // 12 = unassigned status, all = all transportTypes
                                                      'ALL'); 
        }
    }

    public  createWeekPlanningPbsMonitor(startDate , endDate , selectedZone ,selectedGroups)  {
        if (selectedZone != '') {
            this.channelService.startWeekPlanningPbsMonitor("Condensed view", 
                                                      selectedZone, 
                                                      selectedGroups, 
                                                      '', 
                                                       startDate, 
                                                       endDate, 
                                                      '12', // 12 = unassigned status, all = all transportTypes
                                                      'ALL'); 
        }
    }

    public  createWeekPlanningMonitor (startDate , endDate , selectedZone ,selectedTruckDepartments , selectedGroups, withQuotes) {
        if (selectedZone) {
            this.channelService.startWeekPlanningMonitor(selectedGroups, 
                                                selectedTruckDepartments,
                                                selectedZone, 
                                                 startDate, 
                                                endDate, withQuotes);
        }
    }

}


   

    