import { select } from "@angular-redux/store";
import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs";
import { ApplicationUser } from "../../../pb-screen/models/application.user.interface";
import { DamageList } from "../../../pool/models/damageList.interface";
import { PoolCarrier } from "../../../pool/models/pool-carrier.interface";
import { PoolContainerInspectionItem } from "../../../pool/models/pool-container-inspection-item.interface";
import { PoolContainer, PoolContainerStatus } from "../../../pool/models/pool-containers.interface";
import { PoolEquipmentType } from "../../../pool/models/pool-equipment-type.interface";
import { PoolActions } from "../../../pool/pool.actions";
import { PoolService } from "../../../pool/pool.service";
import { ModalType } from "../../../shared/models/component-source";
import { ModalManagerService } from "../../../shared/services/modal-manager.service";
import { SharedActions } from "../../../shared/shared.actions";
import { AnnouncementService } from "../../annoucement.service";
import { AnnouncementActions } from "../../announcement.actions";


@Component({
    selector: "edit-container-info",
    templateUrl: "./edit-container-info.component.html",
})

export class EditContainerInfoModal implements OnInit {

    @select("selectedAnnouncedContainers") public selectedAnnouncedContainers$: Observable<PoolContainer[]> ; 
    @select("carriers") public carriers$: Observable<PoolCarrier[]>;
    @select("equipmentTypes") public equipmentTypes$: Observable<PoolEquipmentType[]>;
    @select('loading') public loading$: Observable<boolean>; 
    @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
    
    public modalType = ModalType;
    @ViewChild("editContainerInfo",{static: true}) public editContainerInfo: ElementRef;

    public loading: boolean = false ;

    public selectedContainer : PoolContainer ; 
    public carriers : PoolCarrier[] = []; 
    public equipmentTypes : PoolEquipmentType[] = [];
    public enableAutocomplete: boolean = false;
    
    public equipmentType: PoolEquipmentType = new PoolEquipmentType();
    public carrier: PoolCarrier = new PoolCarrier();

    constructor(
        private readonly poolService: PoolService,
        private readonly poolActions: PoolActions,
        private readonly modalManager: ModalManagerService,
        private readonly announcementService: AnnouncementService,
        private readonly toastr : ToastrService,
        private readonly sharedActions : SharedActions,
        private readonly announcementActions: AnnouncementActions
      ) { }


  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loading = data;
    })
    
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
        if (applicationUser) {
          this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
        }
      });

        this.selectedAnnouncedContainers$.subscribe((data) => {
            if(data && data.length > 0) {
                this.selectedContainer = data[0];
            } else {
                this.selectedContainer = null;
            }
            this.renderInputs();
        })

        this.carriers$.subscribe((data) => {
            if(data) {
                this.carriers = data ; 
                console.log(this.carriers)
            }
        })

        this.equipmentTypes$.subscribe((data) => {
            if(data) {
                this.equipmentTypes = data ; 
            }
        })
    }

    renderInputs() {

        this.equipmentType = new PoolEquipmentType();
        this.carrier = new PoolCarrier();

        if(this.selectedContainer) {
            if (this.selectedContainer.containerType) {
                this.equipmentType.code = this.selectedContainer.containerType;
                this.equipmentType = this.equipmentTypes.filter((x) => { return x.code == this.equipmentType.code })[0]; 
            } else {
                this.equipmentType.code = ""; 
                this.equipmentType = null;
            }
            
            if(this.selectedContainer.carrierCode) {
                this.carrier.no = this.selectedContainer.carrierCode ; 
                this.carrier = this.carriers.filter((x) => {return x.no == this.carrier.no})[0];
            } else {
                this.carrier.no = ""; 
                this.carrier = null;
            }
        } else {
            this.equipmentType.code = "";
            this.equipmentType = null;
            this.carrier.no = "";
            this.carrier = null;
        }
    }
    apply() {
        
        let updateDate = {
            containerNumber : this.selectedContainer.containerNo , 
            carrierCode : this.carrier.no, 
            containerType : this.equipmentType.code
        }

        if(updateDate.carrierCode !== this.selectedContainer.carrierCode 
            || updateDate.containerType !== this.selectedContainer.containerType)
        {
            this.announcementService.UpdateAnnouncedContainer(updateDate).subscribe((data) => {
                this.announcementActions.updateAnnouncementDisplay(data);
                this.toastr.success("Container Updated Successfully");
                this.closeModal();
            })
        } 
        else {
            this.toastr.warning("Nothing has been changed")
            this.closeModal(); 
        }
    }

    public listCarrierFormatter(data: PoolCarrier): string {
        return `${data.no}`;
    };

    public listEquipmentFormatter(data: PoolEquipmentType): string {
        return `${data.code}`;
    };

    public closeModal(): void {
        this.modalManager.closeModal(this.modalType.editContainerInfo)
    }
}
