import { select } from "@angular-redux/store";
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs/Observable";
import { SharedActions } from "../../../shared/shared.actions";

import { ToastrService } from "ngx-toastr";
import { DepotInActions } from "../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../depot-out/depot-out.actions";
import { ComponentSource, ModalType } from "../../models/component-source";
import { CreateTransportOrder } from "../../models/transport-order/create-transport-order.interface";
import { TransportOrderPlanUnit } from "../../models/transport-order/transport-order-plan-unit.interface";
import { TransportOrderWithPlanUnit } from "../../models/transport-order/transport-order-with-plan-unit.interface";
import { SharedService } from "../../services/shared.service";

import { PoolService } from "../../../pool/pool.service";
import { ActionTemplateScreen } from "../../action.template.screen.enum";
import { LocationSelectorComponent } from "../../components/location-selector/location-selector.component";
import { AddressLocation } from "../../models/address-location";
import { Sms } from "../../models/sms/sms.interface";

import { ActionBarService } from "../../services/action-bar.service";
import { ModalManagerService } from "../../services/modal-manager.service";

import { resolve } from 'url';
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { ApplicationUser, DocumentToBePrinted } from "../../../pb-screen/models/application.user.interface";
import { CapacitySlot } from "../../models/capacity-slot.interface";
import { OrderLine } from "../../../pb-screen/models/order-line/orderLine.interface";
import { SmsService } from "../../services/sms.service";
import { PrintTicketService } from "../../services/print/printticket.service";
import { AuthorizedUser, HomeService } from "../../../pb-screen/services/home.service";
import { TarTasStatus } from "../../models/tar-tas/tar-tas.interface";
import { TarTasService } from "../../services/tartas.service";
import { TarTasManager } from "../../services/tartas-manager.service";
import { MsalService } from "../../../msal/msal.service";
import { IdNameAsStringObject } from "../../../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { ContainerStatusId } from "../../../pb-screen/models/planning-block/planning-block-container-status.interface";

@Component({
    selector: "depot-planning-modal",
    templateUrl: "./planning-modal.component.html",
})
export class DepotPlanningModalComponent implements OnInit {
    public depotOutSelection: PlanningBlock[] = [];
    public depotInSelection: PlanningBlock[] = [];
    public selectedPlanningBlocks: PlanningBlock[];
    public filteredPlanningBlocks: PlanningBlock[];

    public selectedCapacity: CapacitySlot;
    public planDate: string;
    public signalRUserChannel: string;
    public uiState: boolean[];
    public loading = false;
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public sendDepotInSms: boolean = false;
    public sendDepotOutSms: boolean = false;
    public depotInSmsMessage: string = "";
    public depotOutSmsMessage: string = "";

    public statusEnabled = false;
    public selectedPbsStatus: string;
    public selectedStatus: IdNameAsStringObject;
    public statusOptions: IdNameAsStringObject[] = [];
    public containerStatuses: IdNameAsStringObject[] = [];
    public statusPlanningBlock: PlanningBlock;
    public containerNumberRequired: boolean = false;
    public buttonState: boolean = true;
    public locationFilled: boolean = true;
    public containerNumberFilled: boolean = false;
    public containerNumber: string;
    public depotInFrom: string;
    public depotInFromRequired: boolean = false;
    public containerStatusId = ContainerStatusId;
    public selectedLocation: AddressLocation;
    public containerNumberIsValid: boolean = true;
    public containerNumberErrorMessage: string;
    public newLocationSelection: boolean = false;
    public planAndSendSucceeded: boolean = false;
    public transportOrderNumber: string = "";
    public depotInPlanningBlockIdsForTextMessage: number[] = [];
    public depotOutPlanningBlockIdsForTextMessage: number[] = [];
    public fallbackLanguage: string = "en";
    public languageToUse: string = "en";
    private userName: string = "";
    private userId: string = "";
    private CONTAINER_NUMBER_LENGTH: number = 11;
    private reuseNotified: boolean = false;
    public linkToAppointmentEnabled: boolean = false;
    public showWarning: boolean = false;
    public warningMessage: string = '';
    private user: ApplicationUser;

    @Output() public onPlanningSucceeded: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Input() public source: string;

    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select("applicationUser") public applicationUser$: Observable<ApplicationUser>;
    @select("filteredPlanningblocks") public filteredPlanningblocks$: Observable<PlanningBlock[]>;
    @select("uiState") public uiState$: Observable<boolean[]>;
    @select("containerStatuses") public containerStatuses$: Observable<IdNameAsStringObject[]>;
    @select('loading') public loading$: Observable<boolean>;

    @ViewChild(LocationSelectorComponent) private locationSelector: LocationSelectorComponent;

    public enableAutocomplete: boolean = false;
    private containerIsAvailable: boolean = false;
    public locationRequired: boolean;

    constructor(
        private readonly elementRef: ElementRef,
        private readonly sharedService: SharedService,
        private readonly translate: TranslateService,
        private readonly sharedActions: SharedActions,
        private readonly toastr: ToastrService,
        private readonly printTicketService: PrintTicketService,
        private readonly depotInActions: DepotInActions,
        private readonly depotOutActions: DepotOutActions,
        private readonly smsService: SmsService,
        private readonly homeService: HomeService,
        private readonly poolService: PoolService,
        private readonly actionBarService: ActionBarService,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService,
        private readonly tarTasService: TarTasService,
        private readonly tarTasManager: TarTasManager
    ) {
        translate.setDefaultLang(this.fallbackLanguage);
        translate.use(this.languageToUse);
    }

  public ngOnInit() {
    this.loading$.subscribe((data) => {
      if (!data)
        this.loading = data;
    })
        this.userId = this.authService.getAccount().username;
        this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotOutSelection = selectedPlanningBlocks;
            if (this.source === this.componentSource.depotOut) {
                this.selectedPlanningBlocks = selectedPlanningBlocks;
            }
            if (this.depotInSelection.length + this.depotOutSelection.length > 0) {
                this.initializeStatusOptions();
            }
            this.isLinkToAppointmentEnabled();
        });
        this.containerStatuses$.subscribe((statuses: IdNameAsStringObject[]) => {
            this.statusOptions = statuses;
            this.containerStatuses = statuses;
        });
        this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            this.depotInSelection = selectedPlanningBlocks;
            if (this.source === this.componentSource.depotIn) {
                this.selectedPlanningBlocks = selectedPlanningBlocks;
            }
            if (this.depotInSelection.length + this.depotOutSelection.length > 0) {
                this.initializeStatusOptions();
            }
            this.isLinkToAppointmentEnabled();
        });
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.user = applicationUser;
                this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                this.userName = applicationUser.fullName;
                this.userId = applicationUser.username;
                this.locationRequired = applicationUser.generalSetting.locationRequired;
            }
        });
        this.uiState$.subscribe((uiState: boolean[]) => {
            this.uiState = uiState;
        });
    }

    public initializeStatusOptions() {
        if(!this.depotInSelection.length && !this.depotOutSelection.length)
            return;
        this.statusPlanningBlock = this.depotInSelection.length > 0 ?
            this.depotInSelection[0] : this.depotOutSelection[0];
        this.selectedPbsStatus = this.statusPlanningBlock.containerStatus;
        this.selectedStatus = this.containerStatuses.find(el => el.name == this.selectedPbsStatus);
        this.setSelection();
        if (this.locationSelector) {
            this.locationSelector.setSelectionFromParent(this.statusPlanningBlock.latestLocationCode);
        }
        if (this.selectedStatus) {
            this.getValidStatuses(this.statusPlanningBlock.orderNumber);
        }
        this.statusEnabled =
            this.depotInSelection.every((x) => x.containerStatus === this.selectedStatus?.name) &&
            this.depotOutSelection.every((y) => y.containerStatus === this.selectedStatus?.name);
        this.depotInFrom = new Date().toISOString().slice(0, 10);

    }

    public closeModal() {
        this.modalManager.closeModal(this.modalType.planning);
        this.clearFields();
    }

    public clearFields() {
        this.reuseNotified = false;
        this.depotInFromRequired = false;
        this.selectedCapacity = null;
        this.containerNumberIsValid = true;
        this.buttonState = true;
        this.depotInFrom = new Date().toISOString().slice(0, 10);
        this.initializeStatusOptions();
        this.containerNumber = this.statusPlanningBlock.containerNo;
        this.containerNumberFilled = this.containerNumber !== "" ? true : false;
        this.locationFilled = true;
        this.selectedStatus = this.containerStatuses.find(el => el.name == this.selectedPbsStatus);
        this.planAndSendSucceeded = false;
        this.transportOrderNumber = "";
        this.depotInPlanningBlockIdsForTextMessage = [];
        this.depotOutPlanningBlockIdsForTextMessage = [];
        this.selectedLocation = null;
        this.sharedActions.resetModalDomains();
        this.sharedActions.setUiState(true);
    }

    public getSelectedCapacity(capacity) {
        this.selectedCapacity = capacity;
    }

    public getSelectedPlanDate(planDate) {
        this.planDate = planDate;
    }

    public onPlanAndSendAction(printAndSendSms: boolean) {
        let letPlanning : boolean = false ;
        const planUnit: TransportOrderPlanUnit = {
            depotInPlanningBlocks: this.depotInSelection,
            depotOutPlanningBlocks: this.depotOutSelection,
        } as TransportOrderPlanUnit;

        const dataLoad: CreateTransportOrder = {
            capacitySlot: this.selectedCapacity,
            planDate: this.planDate,
            planUnit,
        } as CreateTransportOrder;

        if(planUnit.depotOutPlanningBlocks && planUnit.depotOutPlanningBlocks.length > 0)
        {
            letPlanning = planUnit.depotOutPlanningBlocks.every(this.comparePlanDateToDepotOutFrom) ? false : true ;
        } 

        if(planUnit.depotInPlanningBlocks && planUnit.depotInPlanningBlocks.length > 0)
        {
            letPlanning = planUnit.depotInPlanningBlocks.every(this.comparePlanDateToDepotInFrom) ? false : true ;; 
        }
        
        if(letPlanning)
        {
            this.loading = true;
            this.sharedService.prePlanAction(dataLoad.capacitySlot, dataLoad.planUnit, dataLoad.planDate, this.userId).subscribe(
                (prePlannedData: TransportOrderWithPlanUnit) => {

                  //  this.sharedActions.updatePlanningBlocksDisplayCpl(unplannedPbs);

                    this.sharedActions.updatePlanningBlocksDisplayCpl(prePlannedData.planningBlocks.depotInPlanningBlocks);
                    this.sharedActions.updatePlanningBlocksDisplayCpl(prePlannedData.planningBlocks.depotOutPlanningBlocks);


                    if(printAndSendSms)
                        this.groupAndSendPlanningBlocks(prePlannedData, dataLoad,
                            prePlannedData.transportOrder.id);
                    else {
                        this.updateScreenAndShowMessage(prePlannedData.planningBlocks, prePlannedData.transportOrder?.id);
                        this.closeModal();
                        this.depotInActions.setDepotInSelection([]);
                        this.depotOutActions.setDepotOutSelection([]);
                    }
                },
                (error) => {
                    this.onErrorRerollPreplan(dataLoad, error);
                });
        } 
        else 
        {
            if(planUnit.depotOutPlanningBlocks && planUnit.depotOutPlanningBlocks.length > 0) {
                this.toastr.error("Selected plan date : '" + this.planDate + "'    is before  depot out from date");
                this.loading = false;
            } 
            if (planUnit.depotInPlanningBlocks && planUnit.depotInPlanningBlocks.length > 0) {
                this.toastr.error("Selected plan date : '" + this.planDate + "'    is  before depot In from date");
                this.loading = false;
            }
        }

        
    }

    public groupAndSendPlanningBlocks(prePlannedData: TransportOrderWithPlanUnit, dataLoad: CreateTransportOrder,
        transportOrderNumber: string) {
        this.sharedService.group(prePlannedData.planningBlocks, this.userId).subscribe(
            (groupedData: TransportOrderPlanUnit) => {
                this.sendPlanningBlocks(groupedData, dataLoad, transportOrderNumber);
            },
            (error) => {
                this.onErrorRerollPreplan(dataLoad, error);
            });
    }

    public sendPlanningBlocks(groupedData: TransportOrderPlanUnit, dataLoad: CreateTransportOrder,
        transportOrderNumber: string) {
        this.sharedService.send(groupedData, this.userId).subscribe(
            (planUnit: TransportOrderPlanUnit) => {
                this.depotInActions.setDepotInSelection([]);
                this.depotOutActions.setDepotOutSelection([]);
                this.updateScreenAndShowMessage(planUnit, transportOrderNumber);
                this.closeModal();
            },
            (error) => {
                this.onErrorRerollPreplan(dataLoad, error);
            });
    }

    updateScreenAndShowMessage(planUnit: TransportOrderPlanUnit, transportOrderNumber: string) {
        if(!transportOrderNumber)
        {
            transportOrderNumber = planUnit.depotInPlanningBlocks.concat(planUnit.depotOutPlanningBlocks).find(el => el.transportOrderNumber)?.transportOrderNumber ?? '';
        }
        this.sharedActions.updatePlanningBlocksDisplayCpl([...planUnit.depotInPlanningBlocks,
                ...planUnit.depotOutPlanningBlocks]);
                this.loading = false;

                this.translate.get("TOASTR_MESSAGES.SUCCESS.PLAN_SUCCEEDED").subscribe((res) => {
                    this.toastr.success(res, "", {
                        closeButton: false
                    });
                });
                this.onPlanningSucceeded.emit(true);
                this.locationSelector.locationchanged = false;
                let docToBePrinted: DocumentToBePrinted[] = this.user.Screens.depotIn;
                if(this.source == ComponentSource.depotOut)
                    docToBePrinted = this.user.Screens.depotOut;
                if(docToBePrinted.find(el => el.name == "Print Ticket"))
                    this.printTicket(planUnit, transportOrderNumber);
    }

    public printTicketTest(planUnit: TransportOrderPlanUnit, transportOrderNumber: string) {
        const pbIds = [1066236];
        const pbIdsIn = [1066236];
        const pbIdsOut = [];
        let pbsIn = [];
        let pbsOut = [];
        this.sharedService.getPlanningBlocksByIds(pbIdsIn).subscribe((pbsin: PlanningBlock[]) => {
            pbsIn = pbsin;
            this.sharedService.getPlanningBlocksByIds(pbIdsOut).subscribe((pbsout: PlanningBlock[]) => {
                pbsOut = pbsout;
                this.sharedService.getOrderLines(pbIds.join(",")).subscribe(
                    (orderlines: OrderLine[]) => {
                        const newUnit: TransportOrderPlanUnit = {
                            depotInPlanningBlocks: pbsIn,
                            depotOutPlanningBlocks: pbsOut,
                        } as TransportOrderPlanUnit;

                        this.printTicketService.printTicket(newUnit, "TO17-048915", orderlines, this.userName).subscribe();
                    });
            });
        });

    }

    public printTicket(planUnit: TransportOrderPlanUnit, transportOrderNumber: string) {

        const pbIds = [...planUnit.depotInPlanningBlocks, ...planUnit.depotOutPlanningBlocks]
            .reduce((planningblockIds, pb) => {
                planningblockIds.push(pb.id);
                return planningblockIds;
            }, [])
            .join(",");

        this.sharedService.getOrderLines(pbIds).subscribe(
            (orderlines: OrderLine[]) => {
                this.printTicketService.printTicket(planUnit, transportOrderNumber, orderlines, this.userName)
                    // tslint:disable-next-line:no-empty
                    .subscribe((result: any) => { });
            });

        // this.printTicketService.printTicketv1(planUnit).subscribe();
    }

    public onErrorRerollPreplan(dataLoad: CreateTransportOrder, error) {
        this.sharedService.undoPreplan(dataLoad.planUnit, this.userId);
        this.loading = false;
        this.translateString("TOASTR_MESSAGES.ERROR.PLAN_FAILED").then((res) => {
            this.toastr.error(res);
        });
    }

    public onErrorUndoSend(dataLoad: CreateTransportOrder, error) {
        this.sharedService.undoSend(dataLoad.planUnit, this.userId).subscribe((undoPlanData: TransportOrderPlanUnit) => {
            this.sharedService.undoPreplan(undoPlanData, this.userId);
            this.loading = false;
            this.translateString("TOASTR_MESSAGES.ERROR.PLAN_FAILED").then((res) => {
                this.toastr.error(res);
            });
        });
    }
    public translateString(text: string) {
        return this.translate.get(text).first().toPromise();
    }

    public getValidStatuses(orderNo: string) {
        this.sharedService.getValidContainerStatuses(orderNo, this.selectedPbsStatus)
            .subscribe((data: string[]) => {
                if (this.statusPlanningBlock) {
                    data.push(this.statusPlanningBlock.containerStatus);
                    let array = this.containerStatuses.filter(status => data.find(el => el == status.name));
                    this.statusOptions = array;
                }
            });
    }
    public selectionChanged() {
        this.setSelection();
        if (this.selectedStatus?.name === this.selectedPbsStatus) {
            this.locationFilled = true;
            this.containerNumberFilled = true;
            this.locationSelector.clearSelection();
            this.setButtonState();
        } else {
            if (this.locationSelector.selectedLocation
                && this.locationSelector.selectedLocation.searchName.length > 0) {
                this.locationFilled = true;
                this.setButtonState();
            } else {
                this.locationFilled = false;
                this.setButtonState();
            }
        }
        this.reuseNotified = false;
    }

    public setSelection() {
        switch (this.selectedStatus?.id) {
            case ContainerStatusId.leegCrea:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.leegAf:
                this.containerNumberRequired = true
                    && (this.depotInSelection.length + this.depotOutSelection.length === 1);
                this.depotInFromRequired = false;
                if (this.depotInSelection.length + this.depotOutSelection.length === 1) {
                    this.containerNumber = this.statusPlanningBlock.containerNo;
                    this.onContainerInput(this.containerNumber);
                }
                break;
            case ContainerStatusId.geladen:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.volKlaar:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.volCrea:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.volAf:
                this.containerNumberRequired = false;
                this.depotInFromRequired = true;
                break;
            case ContainerStatusId.leegKlaar:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.volIn:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            case ContainerStatusId.gelost:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
                break;
            default:
                this.containerNumberRequired = false;
                this.depotInFromRequired = false;
        }

        this.setButtonState();
    }

    public setButtonState() {
        if (!this.containerNumberRequired) {
            this.containerNumberFilled = true;
        }
        if (!this.containerNumberFilled || (!this.locationFilled && this.locationRequired)) {
            this.buttonState = false;
        } else {
            this.buttonState = true;
        }
    }

    public async updateAndPlan(printAndSendSms: boolean) {
        const isReusable = await this.actionBarService.isReuseable(this.depotInSelection, this.depotOutSelection, 
            this.source, this.selectedStatus, this.statusEnabled);

        if ((isReusable && this.reuseNotified) || !isReusable) {
            if (this.containerNumberRequired) {
                this.checkContainerNumber(printAndSendSms);
            } else {
                this.updateData(false, printAndSendSms);
            }
        } 
        else if (isReusable && !this.reuseNotified) {
            this.notifyReuse();
        }
    }

    public sendSms() {
        const account = this.authService.getAccount(); 
        this.userId =  account.username;
            if (account.username) {
                this.smsService.send(this.transportOrderNumber,
                    this.depotInPlanningBlockIdsForTextMessage,
                    this.depotInSmsMessage,
                    account.username,
                ).subscribe(() => {
                    this.closeModal();
                    this.translateString("TOASTR_MESSAGES.SUCCESS.REQUEST_SMS_SUCCESS").then((res) => {
                        this.toastr.success(res, "", {
                            closeButton: false,
                        });
                    });
                }, (error) => {
                    this.closeModal();
                });
            } else {
                this.closeModal();
            }
    }

    private onContainerInput(input: string) {
        if (this.selectedPlanningBlocks.length !== 0 && this.selectedPlanningBlocks[0].containerNo !== input
            && input.length === this.CONTAINER_NUMBER_LENGTH) {
            this.sharedService.checkContainerNumbersInFile(this.selectedPlanningBlocks[0].fileId, input).subscribe((res: boolean) => {
                if (!res) {
                    this.toastr.warning("The file " + this.selectedPlanningBlocks[0].fileId +
                        " already contains a planning block having container number : " + input, "", {
                        });
                }
            });
        }
        if (this.containerNumber && this.containerNumber.length > 0 && (/\S/.test(input))) {
            this.containerNumberFilled = true;
            this.setButtonState();
        } else {
            this.containerNumberFilled = false;
            this.setButtonState();
        }
    }

    private checkContainerNumber(printAndSendSms: boolean) {
        this.sharedService.checkContainerNumberCpl(this.containerNumber, this.userId).subscribe((res) => {
            if (res.statusCode === "OK") {
                this.containerNumberIsValid = true;
                const statusPlanningBlock = this.depotInSelection.length > 0 ?
                    this.depotInSelection[0] : this.depotOutSelection[0];
                if (statusPlanningBlock.containerNo !== this.containerNumber) {
                    this.checkContainerAvailability(statusPlanningBlock.id, this.containerNumber, printAndSendSms);
                } else {
                    this.updateData(false, printAndSendSms);
                }
            }
        },
            (err) => {
                const description = err.feedback[0].description;
                this.containerNumberIsValid = false;
                this.buttonState = false;
                this.containerIsAvailable = false;
                this.translate.get("STATUS_MODAL.ERROR_PANEL.ERROR_TEXT").subscribe((res: string) => {
                    this.containerNumberErrorMessage = description + " " + res;
                });
            });
    }

    private checkContainerAvailability(PbId: number, containerNumber: string, printAndSendSms: boolean) {
        this.containerNumberIsValid = true;
        const statusPlanningBlock = this.depotInSelection.length > 0 ?
            this.depotInSelection[0] : this.depotOutSelection[0];

        this.poolService.checkContainerAvailability(statusPlanningBlock.id, this.containerNumber, this.userId)
            .subscribe((available: boolean) => {
                if (available) {
                    this.containerIsAvailable = true;
                } else {
                    // false: the user can carry on the normal function (update or plan)
                    this.buttonState = true;
                    this.updateData(false, printAndSendSms);
                }
            }, (err) => {
                // display error , the user will have to type another c number
                this.buttonState = false;
                this.containerIsAvailable = false;
            });
    }

    private moveContainerOutOfPool(printAndSendSms: boolean) {
        const statusPlanningBlock = this.depotInSelection.length > 0 ?
            this.depotInSelection[0] : this.depotOutSelection[0];

        this.poolService.registerApprovePoolContReqChecks([statusPlanningBlock.id], this.userId).subscribe(() => {
            this.updateData(true, printAndSendSms);
        }, (err) => {
            // display error , the user will have to type another c number
            this.buttonState = true;
            this.containerIsAvailable = false;
        });
    }

    private getSelectedLocation(location: AddressLocation) {
        if (location) {
            this.selectedLocation = location;
            if (this.locationSelector.selectedLocation
                && this.locationSelector.selectedLocation.searchName.length > 0) {
                this.locationFilled = true;
                this.setButtonState();
            } else {
                this.locationFilled = false;
                this.setButtonState();
            }
        } else {
            this.locationSelector.setSelectionFromParent("empty");
        }
    }

    private resetContainerNumber() {
        this.containerNumber = null;
        this.containerNumberIsValid = true;
        this.containerNumberFilled = false;
        this.containerIsAvailable = false;
        this.buttonState = false;
    }

    private async updateData(isMoveContainerOutOfPool: boolean, printAndSendSms: boolean) {
        const promises = [];
        this.loading = true;
        const account = this.authService.getAccount(); 
        this.userId =  account.username;

            this.depotInSelection.forEach((planningBlock: PlanningBlock) => {
                promises.push(this.updateFields(planningBlock, isMoveContainerOutOfPool, account.username));
            });

            this.depotOutSelection.forEach((planningBlock: PlanningBlock) => {
                promises.push(this.updateFields(planningBlock, isMoveContainerOutOfPool, account.username));
            });

            Promise.all(promises).then(() => {
                if (this.loading === true) {
                    this.onPlanAndSendAction(printAndSendSms);
                }
            });
    }

    private async updateFields(planningBlock: PlanningBlock, isMoveContainerOutOfPool: boolean,
        userId: string): Promise<void> {
        const date = await this.updateDepotInDate(planningBlock, userId);
        const cont = await this.updateContainerNumber(planningBlock, isMoveContainerOutOfPool);
        const pb = await this.updatePlanningBlock(planningBlock, userId);

        return;
    }

    private updateDepotInDate(planningBlock: PlanningBlock, userId: string): Promise<boolean> {
        const scope = this;
        return new Promise<boolean>((resolve) => {
            if (!scope.depotInFromRequired || planningBlock.depotInFrom === scope.formatDateForApi()) {
                return resolve(false);
            } else {
                scope.sharedService.updateDepotInDate(planningBlock.id, scope.formatDateForApi(), userId)
                    .subscribe(() => {
                        planningBlock.depotInFrom = scope.formatDateForApi();
                        return resolve(true);
                    }, (err) => {
                        scope.translate.get("TOASTR_MESSAGES.ERROR.DEPOT_IN_DATE_ERROR")
                            .subscribe((res: string) => {
                                scope.toastr.error(res);
                                scope.loading = false;
                            });

                        return resolve(false);
                    });
            }
        });
    }


    private updateContainerNumber(planningBlock: PlanningBlock, isMoveContainerOutOfPool: boolean): Promise<boolean> {
        const scope = this;
        return new Promise<boolean>((resolve) => {
            if (!scope.containerNumberRequired || planningBlock.containerNo === scope.containerNumber) {
                return resolve(false);
            } else {
                scope.sharedService.updateContainerNumber(planningBlock.id, scope.containerNumber, this.userId)
                    .subscribe(() => {
                        planningBlock.containerNo = scope.containerNumber;
                        if (isMoveContainerOutOfPool) {
                            return this.takeContainerFromPool();
                        } else {
                            this.containerIsAvailable = false;

                            return resolve(true);
                        }
                    }, (err) => {
                        scope.translate.get("TOASTR_MESSAGES.ERROR.CONTAINER_NUMBER_ERROR").subscribe((res: string) => {
                            scope.toastr.error(res);
                        });

                        scope.loading = false;
                        this.containerIsAvailable = false;

                        return resolve(false);
                    });
            }
        });
    }

    private takeContainerFromPool(): Promise<boolean> {
        const scope = this;
        return new Promise<boolean>((resolve) => {
            scope.homeService.getAuthorizedUser().subscribe((authorizedUser: AuthorizedUser) => {
                const statusPlanningBlock = scope.depotInSelection.length > 0 ?
                    scope.depotInSelection[0] : scope.depotOutSelection[0];

                scope.poolService
                    .takeContainerFromPool([statusPlanningBlock.id],
                        authorizedUser.username, scope.containerNumber)
                    .subscribe(() => {
                        scope.buttonState = true;
                        scope.containerIsAvailable = false;

                        return resolve(true);
                    },
                        (err) => {
                            scope.containerIsAvailable = false;
                            scope.resetContainerNumber();

                            return resolve(false);
                        });
            });
        });
    }

    private updatePlanningBlock(planningBlock: PlanningBlock, userId: string): Promise<boolean> {
        const scope = this;
        return new Promise<boolean>((resolve) => {
            let status: any;

            if (!scope.statusEnabled && !scope.locationSelector.locationchanged) {
                return resolve(false);
            }

            if (scope.statusEnabled) {
                status = scope.selectedStatus?.name;
            } else {
                status = planningBlock.containerStatus;
            }

            if (scope.selectedStatus?.name !== planningBlock.containerStatus ||
                scope.locationSelector.locationchanged) {
                scope.sharedService.updatePlanningBlockStatus(planningBlock, status,
                    scope.selectedLocation, userId)
                    .subscribe((returnedPb) => {
                        scope.translate.get("TOASTR_MESSAGES.SUCCESS.STATUS_UPDATE_SUCCEEDED")
                            .subscribe((res: string) => {
                                this.toastr.success(res, "", {
                                    closeButton: false
                                });

                                return resolve(true);
                            });
                    }, (err) => {
                        scope.loading = false;
                        scope.translate.get("TOASTR_MESSAGES.ERROR.STATUS_LOCATION_ERROR")
                        .subscribe((res: string) => {
                            scope.toastr.error(res);

                            return resolve(false);
                        });
                    });
            } else { return resolve(false); }
        });
    }

    private formatDateForApi() {
        const dateArray = this.depotInFrom.split("-");
        const apiFormattedDate = dateArray[1] + "-" + dateArray[2] + "-" + dateArray[0];
        return apiFormattedDate;
    }

    public notifyReuse() {
        const selection: PlanningBlock[] = [...this.depotInSelection, ...this.depotOutSelection];
        if (selection.length === 1) {
            const response = `A reuse operation is possible on selected PB: <br/>
                        Order No:  ${selection[0].orderNumber}  
                        <br/> File No: ${selection[0].fileId} 
                        <br/> Container No:  ${selection[0].containerNo} 
                        <br/> Container Type: ${selection[0].containerOrTrailerType}
                        <br/> Carrier: ${selection[0].carrierId}`;
            this.toastr.warning(response, "", {
                closeButton: true,
                enableHtml: true,
                tapToDismiss: false
            });
        }

        if ([...this.depotInSelection, ...this.depotOutSelection].length > 1) {
            this.depotInSelection.forEach((depotIn, index) => {
                const response = `A reuse operation is possible on selected PB Pair: <br/>
                             Order Nos: ${depotIn.orderNumber} - ${this.depotOutSelection[index].orderNumber} <br/>
                             File Nos: ${depotIn.fileId} - ${this.depotOutSelection[index].fileId} <br/>
                             Container Nos: ${depotIn.containerNo} - ${this.depotOutSelection[index].containerNo} <br/>
                             Container Types: ${depotIn.containerOrTrailerType} - ${this.depotOutSelection[index].containerOrTrailerType} <br/>
                             Carriers: ${depotIn.carrierId} - ${this.depotOutSelection[index].carrierId} `;

                this.toastr.warning(response, "", {
                    closeButton: true,
                    enableHtml: true,
                    tapToDismiss: false
                });
            });
        }
        this.reuseNotified = true;
    }


    public comparePlanDateToDepotOutFrom = (element , index , array ) => {
        let date = element.depotOutFrom.split(' ',1);
        const dateArray = date[0].split("/");
        const formattedDate = dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2];
        return new Date(this.planDate) < new Date(formattedDate) ; 
    }

    public comparePlanDateToDepotInFrom = (element,index,array) => {
        let date = element.depotInFrom.split(' ',1);
        const dateArray = date[0].split("/");
        const formattedDate = dateArray[1] + "/" + dateArray[0] + "/" + dateArray[2];
        return new Date(this.planDate) < new Date(formattedDate);
    }

    isLinkToAppointmentEnabled() {
        if(!this.selectedPlanningBlocks)
            this.selectedPlanningBlocks = [];
        var result = this.tarTasManager.isLinkToAppointmentEnabled(this.selectedPlanningBlocks.filter(pb => pb.dInOrderLineID),
                                    this.selectedPlanningBlocks.filter(pb => pb.dOutOrderLineID));
        this.linkToAppointmentEnabled = result.linkToAppointmentEnabled;
        this.warningMessage = result.warningMessage;
        this.showWarning = result.showWarning;
    }

    link()
    {
        this.linkToAppointmentEnabled = false;
        this.tarTasManager.linkDepotOutToDepotIn(this.selectedPlanningBlocks.filter(pb => pb.dInOrderLineID),
                                    this.selectedPlanningBlocks.filter(pb => pb.dOutOrderLineID));
    }
}
