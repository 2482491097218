import { OrderLine } from './../pb-screen/models/order-line/orderLine.interface';
import { ApplicationUser, Column, DocumentToBePrinted } from '../pb-screen/models/application.user.interface';
import { QuickFilter } from '../pb-screen/models/pb-screen.interface';
import { PlanningBlock } from '../pb-screen/models/planning-block/planning-block.interface';
import { CapacitySlot } from './../pb-screen/models/capacity-slot.interface';
import { IAppState } from './IAppState';
import { TransportOrder } from '../pb-screen/models/transport-order/transport-order.interface';
import { ToQuickFilter } from '../to-screen/models/to-screen.interface';
import { Haulier } from '../pb-screen/models/haulier.interface';
import { TMSPlanningSetup } from '../shared/models/tms-planning-setup';
import { WizardDefinition } from '../shared/models/wizard-definition';
import { Driver } from '../static/models/driver.interface';
import { CommunicationPartner } from '../shared/models/notification/communication-partner.interface';
import { StandardNotification } from '../shared/models/notification/standard-notification.interface';
import { xmlTMSSelectCMRActionsXML } from '../pb-screen/models/TMSSelectCMRActions.interface';
import { PlanningConflict } from '../pb-screen/models/planning-conflict.interface';
import { WindowsService } from '../shared/models/windows-service';
import { ChannelEvent } from '../pb-screen/services/channel.event.factory';
import { IdNameAsStringObject } from '../pb-screen/models/quick-view/id-name-as-string-object.interface';
import { IgnoreConflictReason } from '../pb-screen/models/ingore-conflict-reason.interface';
import { Trailer } from '../shared/models/trailer.interface';
import { Chassis } from '../shared/models/chassis.interface';
import { StackerAction } from '../shared/models/stacker-actions';
import { AnnouncementQuickFilter, StackerQuickFilter } from '../depot-out/models/depot-out.interface';
import { PoolMove } from '../pool/models/pool-move.interface';
import { DamageList } from '../pool/models/damageList.interface';
import { PoolContainer } from '../pool/models/pool-containers.interface';
import { PlanningGroups } from '../shared/models/planning-groups-enum.interface';
import { PoolContainerInspectionItem } from '../pool/models/pool-container-inspection-item.interface';
import { PoolTruck } from '../pool/models/pool-truck.interface';
import { PoolDriver } from '../pool/models/pool-driver.interface';
import { PoolHaulier } from '../pool/models/pool-haulier.interface';
import { PoolCarrier } from '../pool/models/pool-carrier.interface';
import { PoolEquipmentType } from '../pool/models/pool-equipment-type.interface';
import { PoolBooking } from '../pool/models/pool-booking.interface';
import { PoolBookingFilter } from '../pool/models/pool-booking-filters.interface';
import { BookingModes } from '../pool/models/booking-enums.interface';
import { Quote, QuoteLine } from '../static/models/quote.interface';
import { WaitingRoomDetail } from '../shared/models/waiting-room-detail.interface';

import {  QuickFilter as depotOutQuickFilter } from "../depot-out/models/depot-out.interface";
import { QuickFilter as depotInQuickFilter } from "../depot-in/models/depot-in.interface";
import { AdvancedFilterGroup, AdvancedFilterGroupType, AdvancedFilterItem } from '../shared/components/filter-line/filter-line.interface';
import { AddressLocation } from '../shared/models/address-location';

const quickFilteredPlanningBlocks: PlanningBlock[] = [];
const quickFilteredPlanningBlocksNoText: PlanningBlock[] = [];
const filteredPlanningBlocks: PlanningBlock[] = [];
const updatedPlanningBlocks: PlanningBlock[] = [];

const filteredTransportOrders: TransportOrder[] = [];
const openTransportOrders: TransportOrder[] = [];
const filteredOpenTransportOrders: TransportOrder[] = [];
const searchTextForOpenTOs : string = '';
const selectedTransportOrders: TransportOrder[] = [];
const selectedQuotes: Quote[] = [];
const transportOrders: TransportOrder[] = [] ;
const quickFilteredTransportOrdersNoText: TransportOrder[] = []
const quickFilteredTransportOrders: TransportOrder[] = [];
const selectedOrderLines: OrderLine[] = [] ;
const capacitySlots: CapacitySlot[] = [];
const hauliers: Haulier[] = [];
const drivers: Driver[] = [];
const trailers : Trailer [] = [] ;
const chassis : Chassis [] = [];
const communicationPartners: CommunicationPartner[] = [];
const standardNotifications: StandardNotification[] = [];
const applicationUser: ApplicationUser = null;

const sessionAdvancedFilters: AdvancedFilterGroup = {filterGroups: [], filterItems: [], operator: AdvancedFilterGroupType.And};
const searchText: string = '';
const quickFilter: QuickFilter = null;
const toQuickFilter: ToQuickFilter = null;

const planningBlocks: PlanningBlock[] = [];
const selectedPlanningBlocks: PlanningBlock[] = [];
const sharedSelectedPlanningBlocks: PlanningBlock[] = [] ;
const sessionColumnSelection: Column[] = [];
const sessionToColumnSelection: Column[] = [];
const planState: boolean = false;
const updateState: boolean = false;
const unplanState: boolean = false;
const printState: boolean = false ;
const pbDetailsState: boolean = false;
const sessionOrderLineColumnSelection: Column[] = [];
const toDetailsState: boolean = false;
let formattedEndDate: Date = new Date();
const endDate: number = formattedEndDate.valueOf();
let formattedStartDate: Date = new Date(formattedEndDate.setMonth(formattedEndDate.getMonth() - 6));
const startDate: number = formattedStartDate.valueOf();
const processedDisplayState: boolean = false;
const importantDisplayState: boolean = false;
const selectedDisplayState: boolean = false;
const toProcessedDispalyState: boolean = false;
const cancelPbBtnState: boolean = false ;
const toPlanBtnState: boolean = false;
const unplanPbBtnState: boolean = false ;
const sendPbBtnState : boolean = false ;
const tmsPlanningSetup:TMSPlanningSetup = null;
const groupUpdateState:boolean = false;
const transportOrderWithPbs: TransportOrder = null;
const planningBlocksForTO: PlanningBlock[] = [];
const selectedActionWizard: WizardDefinition = null;
const pbOrderlines : OrderLine[] = [];
const defaultPlanningZone: string = '';
const sessionDocumentSelection: DocumentToBePrinted[] = [];
const activePlanningConflicts: PlanningConflict[] = [];
const planningConflicts: PlanningConflict[] = [];
const planningConflictsForDock: PlanningConflict[] = [];
const checkConflictsForPbState: boolean= false;
const monitorService : WindowsService = null;
const channelEvents : ChannelEvent[] = [];
const notifications : Notification[] = [];
const planningBlockNotifications : Notification[] = [];
const transportOrderNotifications : Notification[] = [];

const pbDetailsFromTo: boolean = false ;
const orderLines:OrderLine[] = [];
const cmrActions: xmlTMSSelectCMRActionsXML = {
  tmsSelectCMRActions: [],
  Text: null
};
const planningGroups : IdNameAsStringObject[] = [];
const truckDepartments : IdNameAsStringObject[] = [];
const sessionPbOrderLineColumnSelection : Column[] = [];
const azureUsers : any[] = [];
const azureGroups : any[] = [];
const planningZone : IdNameAsStringObject[] = [];
const ignoreConflictReasons: IgnoreConflictReason[] = [];
const selectedTOForInterWindowPlanning : TransportOrder = null;
const planningData : any[] = [];
const unplannedPbs : PlanningBlock[] = [];
const selectionType : string = '';
const selectedPbsToPlan : PlanningBlock[] = [];
const weekPlanningData : any[] = [];
const unplannedPbsForWeekPlanning: PlanningBlock[] = [];
const selectedOrderlinesFromBoard : any[] = [];
const selectedPbsFromPlanBoard : PlanningBlock[] = [];
const selectedCapacityFromPlanBoard : any = null;
const openTosForDate : TransportOrder[] = [] ;
const planBoardMonitor : WindowsService = null ;

const selectedDriverFromAgenda: any = null ; 
const selectedTruckAssignement: any= null ; 
const selectedDriverAbsence: any= null ; 
const driverAgendaData : any[] = [] ; 
const selectedTruckFromAgenda : any = null ; 
const selectedUnavailability : any = null ; 
const selectedAssignement: any = null;
const truckAgendaData : any [] = []; 
const toSelectionForDetails: TransportOrder = null; 
const orderlinesForTO: any[] = [];
const quoteLines: QuoteLine[] = [];
const waitingRoomDetails: WaitingRoomDetail[]  = []; 

const loading : boolean = true ; 
const shippingRailDiary : any[] = [];

//Container Planning 
const depotOutPlanningBlocks: PlanningBlock[] = [];
const depotOutQuickFilteredPlanningBlocks: PlanningBlock[] = [];
const depotOutFilteredPlanningBlocks: PlanningBlock[] = [];
const depotOutQuickFilteredPlanningBlocksNoText: PlanningBlock[] = [];
const depotInPlanningBlocks: PlanningBlock[] = [];
const depotInQuickFilteredPlanningBlocks: PlanningBlock[] = [];
const depotInQuickFilteredPlanningBlocksNoText: PlanningBlock[] = [];
const depotInFilteredPlanningBlocks: PlanningBlock[] = [];
const depotInCandidates: PlanningBlock[] = [];
const depotOutCandidates: PlanningBlock[] = [];
const updatedPlanningBlockscpl: PlanningBlock[] = [];

const capacitySlotscpl: CapacitySlot[] = [];
const depotOutSelection: PlanningBlock[] = [];
const depotInSelection: PlanningBlock[] = [];
const uiState: boolean = true;

const planStatecpl: boolean = false;
const depotOutStatusState: boolean = false;
const depotInStatusState: boolean = false;
const depotOutRerouteState: boolean = false;
const depotInRerouteState: boolean = false;
const depotInUnplanState: boolean = false;
const depotOutUnplanState: boolean = false;
const depotOutCandidateState: boolean = false;
const depotInRailState: boolean = false;
const depotOutRailState: boolean = false;
const printListDepotOutState: boolean = false;
const printListDepotInState: boolean = false;
const depotInWeighState: boolean = false;
const depotOutWeighState: boolean = false;
const cmrState: boolean = false;
const containerReuseState: boolean = false;
const depotInReuseCandidatState: boolean = false;
const depotOutReuseCandidatState: boolean = false;
const depotOutFilteredOnCandidates: boolean = false;
const depotInFilteredOnCandidates: boolean = false;
const depotInCandidateState: boolean = false;
const depotInReservationState: boolean = false;
const groupUpdateStatecpl:boolean = false;
const sessionDepotOutColumnSelection: Column[] = [];
const sessionDepotInColumnSelection: Column[] = [];

const sessionDepotOutAdvancedFilters: AdvancedFilterGroup = {filterGroups: [], filterItems: [], operator: AdvancedFilterGroupType.And};
const sessionDepotInAdvancedFilters: AdvancedFilterGroup = {filterGroups: [], filterItems: [], operator: AdvancedFilterGroupType.And};
const depotInSearchText: string = "";
const depotOutSearchText: string = "";
const depotInQuickFilter: depotInQuickFilter = null;
const depotOutQuickFilter: depotOutQuickFilter = null;

const poolAssignmentDirection: BookingModes = BookingModes.Incoming;

const poolBookings: PoolBooking[] = [];
const filteredPoolBookings: PoolBooking[] = [];
const poolBookingsFilter: PoolBookingFilter = null;
const selectedPoolBooking: PoolBooking = null;

const selectedPoolMove: PoolMove = null;
const poolMoves: PoolMove[] = [];
const filteredPoolMoves: PoolMove[] = [];

const selectedActivePoolMove: PoolMove = null;
const activePoolMoves: PoolMove[] = [];
const filteredActivePoolMoves: PoolMove[] = [];

const poolContainers: PoolContainer[] = [];
const filteredPoolContainers: PoolContainer[] = [];
const selectedPoolContainer: PoolContainer = null;

const equipmentTypes: PoolEquipmentType[] = [];
const carriers: PoolCarrier[] = [];

const haulierscpl: PoolHaulier[] = [];
const selectedHaulier: PoolHaulier = null;
const driverscpl: PoolDriver[] = [];
const selectedDriver: PoolDriver = null;
const trucks: PoolTruck[] = [];
const selectedTruck: PoolTruck = null;
const selectedToLocation: AddressLocation = null;
const wizardComment: string = "";

const depotInProcessedDisplayState: boolean = false;
const depotOutProcessedDisplayState: boolean = false;

const depotInInmportantOnlyDisplayState: boolean = false;
const depotOutInmportantOnlyDisplayState: boolean = false;

const containerRemarks: string[] = [];
const checklistItems: PoolContainerInspectionItem[] = [];
const selectedChecklistItems: PoolContainerInspectionItem[] = [];

const lastActionDate : Date = null;

const updateInitialsState : boolean = false; 
const allPoolContainers: PoolContainer[] = [];
const announcementSearchText: string = "";

const announcementQuickFilter: AnnouncementQuickFilter = null;
const filteredContainers: PoolContainer[] = [];
const poolContainersForAnnouncement : PoolContainer[] = [];
const announcedPoolContainers: PoolContainer[] = [];
const filteredAnnouncedPoolContainers : PoolContainer[] = [];

const selectedAnnouncedContainers: PoolContainer[] = [];
const announcedSearchText: string = "";
const announcedContainerInspections : DamageList[] = [];

const preAnnouncedContainerMoves : PoolMove[] = [];
const filteredPreAnnouncedContainerMoves : PoolMove[] = [] ;
const selectedPreAnnouncedPoolMove : PoolMove = null ;
const stackerPlanningBlocks : any[] = []; 
const sessionStackerColumnSelection: Column[] = [];
const filteredStackerPlanningBlocks: any[] = []; 
const stackerQuickFilter: StackerQuickFilter = {
  containerNo : '' , orderNo : '' , fileNumber : '' , truck : '' , selectedStatuses : [] , selectedPlanningTypes : [] , selectedKaai : ''
}; 
const selectedStackerPlanningBlocks : any[] = [];
const stackerAddressLocations: AddressLocation[] = [];
const stackerTxtSearch : string = ""; 
const stackerActionsUndo : StackerAction[] = []; 
const stackerActionsRedo : StackerAction[] = [];
const sites: any[] = [];
const planningGroupString: string = "";
const selectedConflict : PlanningConflict = null;

export const initialState: IAppState = {
  globalSettings: [],
  httpRequestPending: false,
  orderlinesDockLoading: false,
  containerStatuses: [],
  quoteLines,
  quickFilteredPlanningBlocks,
  filteredPlanningBlocks,
  applicationUser,
  capacitySlots,
  hauliers,
  drivers,
  planningGroups,
  communicationPartners,
  standardNotifications,
  sessionAdvancedFilters,
  searchText,
  quickFilteredPlanningBlocksNoText,
  updatedPlanningBlocks,
  quickFilter,
  planningBlocks,
  selectedPlanningBlocks,
  sharedSelectedPlanningBlocks,
  sessionColumnSelection,
  planState,
  unplanState,
  updateState,
  startDate,
  endDate,
  printState,
  processedDisplayState,
  importantDisplayState,
  selectedTransportOrders,
  selectedQuotes,
  transportOrders,
  quickFilteredTransportOrdersNoText,
  sessionToColumnSelection,
  sessionOrderLineColumnSelection,
  pbDetailsState,
  selectedOrderLines,
  quickFilteredTransportOrders,
  planningConflictsForDock,
  toQuickFilter,
  toProcessedDispalyState,
  filteredTransportOrders,
  openTransportOrders,
  filteredOpenTransportOrders,
  searchTextForOpenTOs,
  toDetailsState,
  cancelPbBtnState,
  toPlanBtnState,
  unplanPbBtnState,
  sendPbBtnState,
  tmsPlanningSetup,
  groupUpdateState,
  transportOrderWithPbs,
  planningBlocksForTO,
  selectedActionWizard,
  pbOrderlines,
  defaultPlanningZone,
  sessionDocumentSelection,
  activePlanningConflicts,
  planningConflicts,
  checkConflictsForPbState,
  orderLines,
  cmrActions,
  monitorService,
  channelEvents,
  selectedDisplayState,
  pbDetailsFromTo,
  truckDepartments,
  sessionPbOrderLineColumnSelection,
  notifications,
  planningBlockNotifications,
  transportOrderNotifications,
  azureUsers,
  azureGroups,
  ignoreConflictReasons,
  planningZone,
  chassis,
  trailers,
  selectedTOForInterWindowPlanning,
  planningData,
  unplannedPbs,
  selectionType,
  selectedPbsToPlan,
  selectedPbsFromPlanBoard,
  weekPlanningData,
  unplannedPbsForWeekPlanning,
  selectedOrderlinesFromBoard,
  selectedCapacityFromPlanBoard,
  openTosForDate,
  planBoardMonitor,
  selectedDriverFromAgenda,
  selectedTruckAssignement,
  selectedDriverAbsence,
  driverAgendaData ,
  selectedTruckFromAgenda ,
  selectedUnavailability ,
  selectedAssignement,
  truckAgendaData ,
  toSelectionForDetails,
  orderlinesForTO,
  loading,
  shippingRailDiary,

  //Container Planning 
  depotOutPlanningBlocks,
  depotOutQuickFilteredPlanningBlocks,
  depotOutFilteredPlanningBlocks,
  depotInPlanningBlocks,
  depotInQuickFilteredPlanningBlocks,
  depotInFilteredPlanningBlocks,
  capacitySlotscpl,
  depotOutSelection,
  depotInSelection,
  sessionDepotOutColumnSelection,
  sessionDepotInColumnSelection,
  sessionStackerColumnSelection,
  sessionDepotOutAdvancedFilters,
  sessionDepotInAdvancedFilters,
  uiState,
  planStatecpl,
  depotOutStatusState,
  depotInStatusState,
  cmrState,
  containerReuseState,
  depotInReuseCandidatState,
  depotOutReuseCandidatState,
  depotInRerouteState,
  depotOutRerouteState,
  depotInUnplanState,
  depotOutUnplanState,
  depotInCandidateState,
  depotOutCandidateState,
  depotInRailState,
  depotOutRailState,
  depotOutSearchText,
  depotInSearchText,
  reuseCandidates: [],
  depotOutQuickFilteredPlanningBlocksNoText,
  depotInQuickFilteredPlanningBlocksNoText,
  updatedPlanningBlockscpl,
  depotInQuickFilter,
  depotOutQuickFilter,
  depotOutFilteredOnCandidates,
  depotInFilteredOnCandidates,
  depotInCandidates,
  depotOutCandidates,
  poolAssignmentDirection,
  poolBookings,
  filteredPoolBookings,
  poolBookingsFilter,
  selectedPoolBooking,
  selectedPoolMove,
  poolMoves,
  filteredPoolMoves,
  selectedActivePoolMove,
  activePoolMoves,
  filteredActivePoolMoves,
  poolContainers,
  filteredPoolContainers,
  equipmentTypes,
  carriers,
  haulierscpl,
  selectedHaulier,
  driverscpl,
  selectedDriver,
  trucks,
  selectedTruck,
  selectedPoolContainer,
  selectedToLocation,
  wizardComment,
  containerRemarks,
  checklistItems,
  selectedChecklistItems,
  printListDepotOutState,
  printListDepotInState,
  depotInWeighState,
  depotOutWeighState,
  depotInProcessedDisplayState,
  depotOutProcessedDisplayState,
  depotInInmportantOnlyDisplayState,
  depotOutInmportantOnlyDisplayState,
  depotInReservationState,
  groupUpdateStatecpl,
  lastActionDate,
  updateInitialsState,
  allPoolContainers,
  announcementSearchText,
  announcementQuickFilter,
  filteredContainers,
  poolContainersForAnnouncement,
  announcedPoolContainers,
  filteredAnnouncedPoolContainers, 
  selectedAnnouncedContainers,
  announcedSearchText,
  announcedContainerInspections,
  preAnnouncedContainerMoves,
  filteredPreAnnouncedContainerMoves,
  selectedPreAnnouncedPoolMove,
  stackerPlanningBlocks,
  filteredStackerPlanningBlocks,
  stackerQuickFilter,
  selectedStackerPlanningBlocks,
  stackerAddressLocations , 
  stackerTxtSearch,
  stackerActionsUndo ,
  stackerActionsRedo ,
  sites,
  planningGroupString,
    selectedConflict,
    waitingRoomDetails,
    toWizardDefinitions: [],
    pbWizardDefinitions: []
};
