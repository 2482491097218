
import {throwError as observableThrowError,  Observable } from 'rxjs';
import { Injectable } from "@angular/core";
import { HttpClientService } from "./httpclient";
import { CommunicationPartner } from "../models/notification/communication-partner.interface";
import { environment } from "../../../environments/environment";
import { catchError, map } from "rxjs/operators";
import { StandardNotification } from "../models/notification/standard-notification.interface";
import { WaitingRoomDetail } from '../models/waiting-room-detail.interface';

@Injectable()
export class NotificationService{

    constructor(
        private readonly http: HttpClientService) {   
    }

    public getAllNotifications() : Observable<Notification[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "notification/all").pipe(
            map((response : any) => response.data as Notification[]),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }
    
    public getAllPlanningBlockNotifications() : Observable<Notification[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "notification/planningblocks/all").pipe(
            map((response : any) => response.data as Notification[]),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    public getAllTransportOrderNotifications() : Observable<Notification[]> {
        return this.http.get(environment.tmsApiEndpointAddress + "notification/transportorders/all").pipe(
            map((response : any) => response.data as Notification[]),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }
    
    public getCommunicationPartners() : Observable<CommunicationPartner[]>{
        return this.http
        .get(environment.tmsApiEndpointAddress + "notification/communication-partners").pipe(
            map((response : any) => response.data as CommunicationPartner[]),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    
    public getStandardNotifications() {
        return this.http
        .get(environment.tmsApiEndpointAddress + "notification/standard").pipe(
            map((response : any) => response.data as StandardNotification[]),
            catchError((err) => {
                return observableThrowError(err);
            })
        )
    }

    public createNotification(selectionType : number, itemId: string, orderId: string, body: string, subject: string, recipientCode: string, standardNotificationCode: string) : Observable<any> {
        return this.http.post(environment.tmsApiEndpointAddress + `notification?selectionType=${selectionType}&itemId=${itemId}&orderId=${orderId}`,
            {
                body : body,
                subject : subject,
                recipientCode : recipientCode,
                standardNotificationCode : standardNotificationCode
            }).pipe(
                map((response : any) => response.data),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public processNotificationsForPb(ids: string, pbId: number) : Observable<any> {
        return this.http.post(environment.tmsApiEndpointAddress + `notification/process?entryIDs=${ids}&pbId=${pbId}`, null).pipe(
                map((response : any) => response.data),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public processNotificationsForTo(ids: string, tranportOrderNumber: string) : Observable<any> {
        return this.http.post(environment.tmsApiEndpointAddress + `notification/process?entryIDs=${ids}&toId=${tranportOrderNumber}`, null).pipe(
                map((response : any) => response.data),
                catchError((err) => {
                    return observableThrowError(err);
                })
            )
    }

    public GetTMSWaitingRoomdetails() : Observable<WaitingRoomDetail[]> {
        return this.http.get(environment.tmsApiEndpointAddress + '/notification/waitingroom/details')
            .pipe(map((res : any) => res.data) , 
            catchError((err) => observableThrowError(err))
            ); 
    }

}