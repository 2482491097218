import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name : 'drivingTime' })
export class DrivingTimeFormatter implements PipeTransform {

    constructor() {
    }

    transform(value: string, drivingHours: number, drivingMinutes: number, separator: string = ', ') {
        if(!value)
            return '';
        let results = [];
        let array = value.split(separator);
        array.forEach(el => {
            let formatted = '';
            var time = el.split("T")[1].slice(0, -1).split(":");
            let hours = parseInt(time[0]);
            let minutes = parseInt(time[1]);
            if( hours > drivingHours || (hours == drivingHours && minutes >= drivingMinutes))
                formatted = time[0] + "H:" + time[1] + "M"; 
            results.push(formatted);
        });
        return results.join(separator);
    }
}
