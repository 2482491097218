import { Component, Input, OnInit } from "@angular/core";
import { SharedService } from "../../services/shared.service";

@Component({
  selector: "planning-block-detail",
  templateUrl: "planning-block-detail.component.html",
})

export class PlanningBlockDetailComponent implements OnInit {
  ngOnInit(): void {
    throw new Error("Method not implemented.");
  }
  @Input() public planningBlockId: number;

  constructor(
    private readonly sharedService: SharedService,
  ) {
  }
}
