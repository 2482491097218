import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";
import { map, catchError } from "rxjs/operators";
import { HttpClientService } from "../shared/services/httpclient";
import * as ClientSettings from "../static/clientSettings";
import { environment } from '../../environments/environment';
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";


@Injectable()
export class DepotOutService {
  constructor(
    private readonly http: HttpClientService
  ) { }

  public getPlanningBlocks(zone: string): Observable<PlanningBlock[]> {
    return this.http
      .get(environment.ContainerPlanningApiUri + "planningBlocks/depot-out?zone="+zone)
      .pipe(
        map((res : any) => res.data),
        catchError((error) =>  Observable.throw(error.json())),
    );
  }
}
