import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from "../../models/status-type.interface";
import { SharedService } from "../../services/shared.service";
import { TransportOrderPlanUnit } from "../../models/transport-order/transport-order-plan-unit.interface";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { MsalService } from "../../../msal/msal.service";


@Component({
    selector: "undo-preplan",
    templateUrl: "./undo-preplan.component.html",
})
export class UndoPreplanComponent {
    @Input() public planningBlock: PlanningBlock;
    @Output() public unplanPlanningBlock = new EventEmitter();

    public PLANNED: number = PLANNED;
    public PRE_PLANNED: number = PRE_PLANNED;

    constructor(
        private sharedService: SharedService,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly authService: MsalService
    ) { }

    public undoPreplan(): void {
        const account = this.authService.getAccount(); 

            if (account.username) {
                let userId = account.username;

                this.sharedService.undoSend({
                    depotInPlanningBlocks: [this.planningBlock],
                    depotOutPlanningBlocks: []
                }, userId).subscribe((transportOrderPlanUnit: TransportOrderPlanUnit) => {
                    this.translate.get("TOASTR_MESSAGES.SUCCESS.UNPLAN_SUCCEEDED").subscribe((res: string) => {
                        this.toastr.success(res, "", {
                            closeButton: false
                        });
                    });
                    const planningBlock: PlanningBlock = transportOrderPlanUnit.depotInPlanningBlocks.length > 0
                        ? transportOrderPlanUnit.depotInPlanningBlocks[0]
                        : transportOrderPlanUnit.depotOutPlanningBlocks[0];

                    this.unplanPlanningBlock.emit(planningBlock);
                });
            }
        
    }
}
