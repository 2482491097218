import { select } from "@angular-redux/store";
import { Component, OnInit } from "@angular/core";
import { GridDataResult, PageChangeEvent, SelectableSettings } from "@progress/kendo-angular-grid";
import { orderBy, SortDescriptor, State } from "@progress/kendo-data-query";
import { Observable } from "rxjs";
import { PoolContainer } from "../../../../pool/models/pool-containers.interface";
import { AnnouncementActions } from "../../../announcement.actions";

@Component({
    selector: "announced-containers-table",
    templateUrl: "./announced-containers-table.component.html",
})

export class AnnouncedContainersTable implements OnInit {

    @select("filteredAnnouncedPoolContainers") public filteredAnnouncedPoolContainers$: Observable<PoolContainer[]>;
    @select("selectedAnnouncedContainers") public selectedAnnouncedContainers$: Observable<PoolContainer[]>; 

    public announcedContainers: PoolContainer[] = [];
    public announcedGridResult: GridDataResult;

    public mySelection: PoolContainer[] = [];
    public mySelectionKeys: number[] = [];

    public state: State = {
        skip: 0,
        take: 10,
    };
    public selectableSettings: SelectableSettings = {
        checkboxOnly: false,
        mode: "multiple",
    };

    public gridUser: any;


    public columnNames = [
        { title: "Container number", field: "containerNo" }, { title: "Container Type", field: "containerType" },
        { title: "Carrier Name", field: "carrierName" }, { title: "Status", field: "status" },
        { title: "Location", field: "location" }, { title: "Pool adress Name", field: "poolAddressSearchName" },
        { title: "Comment", field: "comment" }
    ];


    constructor(private readonly announcementActions : AnnouncementActions) {

    }

    ngOnInit(): void {

        this.selectedAnnouncedContainers$.subscribe((data) => {
            if(data && data.length > 0) {
                this.mySelection  = data;
            } else {
                this.mySelection = [];
                this.mySelectionKeys = [];
            }
        })
        this.filteredAnnouncedPoolContainers$.subscribe((data) => {
            if (data) {
                this.announcedContainers = data;
                this.setGridContent();
            }
        })
    }


    public setGridContent() {
        this.announcedGridResult = {
            data: [...this.announcedContainers.slice(this.state.skip, this.state.skip + this.state.take)],
            total: this.announcedContainers.length,
        }
    }

    public loadItems(): void {
        this.announcedGridResult = {
            data: this.announcedContainers.slice(this.state.skip, this.state.skip + this.state.take),
            total: this.announcedContainers.length,
        };
    }

    public pageChange(event: PageChangeEvent): void {
        this.state.skip = event.skip;
        this.loadItems();
    }


    public sortChange(sort: SortDescriptor[]): void {
        this.state.sort = sort;
        this.announcedContainers = orderBy([...this.announcedContainers], sort);

        this.announcedGridResult = {
            data: this.announcedContainers.slice(this.state.skip, this.state.skip + this.state.take),
            total: this.announcedContainers.length,
        };
    }

    public rowClicked(gridUser, selection): void {
       
        if (selection.selectedRows && selection.selectedRows.length > 0) {
            this.mySelection = [...this.mySelection.concat(selection.selectedRows.map((x) => x.dataItem))];
          }
      
          if (selection.deselectedRows && selection.deselectedRows.length > 0) {
            const deslectectedContainers = selection.deselectedRows.map((x) => x.dataItem);
            this.mySelection = [...this.mySelection.filter((x) => deslectectedContainers.indexOf(x) === -1)];
          }
       
          this.announcementActions.setSelectedAnnouncedContainers(this.mySelection);
    }

    public refreshTableDisplay(): void {
        this.state.skip = 0;
        this.setGridContent();
    }

}