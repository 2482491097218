export enum ActionIcons {
  SCANNING = '35',
  CUSTOMS = '36',
  WEIGHING = '37',
}

export function hasActionIcon(specificContainerActions: string, actionIcon: ActionIcons): boolean {
  const containerActions = specificContainerActions.split('|');
  return containerActions.indexOf(actionIcon) !== -1;
}

export enum BeforeOrAfter {
  Before = 0,
  After = 1
}
