import { OnInit, Component, ViewChild, TemplateRef, ElementRef, Input, OnDestroy } from "@angular/core";
import { select } from "@angular-redux/store";
import { Observable, Subject, Subscription } from "rxjs";
import { TransportOrder } from "../../../../../pb-screen/models/transport-order/transport-order.interface";
import { ToScreenService } from "../../../../../pb-screen/services/to-screen.service";
import { SelectableSettings, GridDataResult, DataStateChangeEvent, ColumnReorderEvent, ColumnResizeArgs } from "@progress/kendo-angular-grid";
import { State, SortDescriptor, orderBy } from "@progress/kendo-data-query";
import { ApplicationUser, Column } from "../../../../../pb-screen/models/application.user.interface";
import { TranslateService } from "@ngx-translate/core";
import { DefaultOrderLineColumnDefiniton } from "../../../../../pb-screen/static/columns/OrderLineColumnDefinition";
import { ToActions } from "../../../../to.actions";
import { SharedActions } from "../../../../../shared/shared.actions";
import { OrderLine } from "../../../../../pb-screen/models/order-line/orderLine.interface";
import { ApplicationUserActions } from "../../../../../pb-screen/application.user.actions";
import { ToastrService } from "ngx-toastr";
import { ToActionBarService } from "../../../../../pb-screen/services/to-action-bar.service";
import { PlanningBlock } from "../../../../../pb-screen/models/planning-block/planning-block.interface";
import { PbActions } from "../../../../../pb-screen/pb.actions";
import { takeUntil } from "rxjs/operators";

@Component({
  moduleId: module.id,
  selector: 'to-orderlines-table',
  templateUrl: './to-orderlines.component.html',
})

export class ToOrderLinesComponent implements OnInit, OnDestroy {
  @select('selectedTransportOrders') public selectedTransportOrders$: Observable<TransportOrder[]>;
  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
  @select('sessionOrderLineColumnSelection') public sessionOrderLineColumnSelection$: Observable<Column[]>;
  @select('selectedOrderlines') public selectedOrderlines$: Observable<OrderLine[]>;
  @select('orderLines') public orderLines$: Observable<OrderLine[]>;
  @select('planningBlocksForTO') public planningBlocksForTO$: Observable<PlanningBlock[]>;
  @select('toSelectionForDetails') public toSelectionForDetails$ : Observable<TransportOrder>
  @select('orderlinesForTO') public orderlinesForTO$: Observable<any[]>;
  @select('loading') public loading$: Observable<boolean>;

  public selectedTransportOrder: TransportOrder;
  public selectedOrderlines: OrderLine[] = [];
  public orderLines: OrderLine[] = [];
  public tableData: GridDataResult;
  public rows = [];
  public columns = DefaultOrderLineColumnDefiniton;
  public state: State = {
    skip: 0,
    take: 50,
  };
  public selectableSettings: SelectableSettings = {
    checkboxOnly: false,
    mode: 'multiple',
  };
  private windowWidth: number;
  private tableWidth: number;
  private windowHeight: number;
  private tableHeight: number;
  private tooltipOffset = { left: 0, top: 0 };
  private tooltipVisible = false;
  private tooltipValue = '';
  private GRID_RATIO = 0.5;
  private GRID_FILLER_SPACE = 82.8;
  private processedDisplayState: boolean;
  public user: ApplicationUser;
  private _resetSelectedRows: boolean;
  private mySelection: OrderLine[] = [];
  private mySelectionKeys: number[] = [];
  public planningBlocksForTo: PlanningBlock[] = [];

  get resetSelectedRows(): boolean {
    return this._resetSelectedRows;
  }

  @Input('resetSelectedRows')
  set resetSelectedRows(value: boolean) {
    this._resetSelectedRows = value;
  }
  private fallbackLanguage: string = 'en';
  private languageToUse: string = 'en';

  public pageSize = 10;
  public skip = 0;

  public loadingSpinner = false ; 

  destroy$ = new Subject();

  @ViewChild('dateTemplate') public dateTemplate: TemplateRef<any>;
  @ViewChild('tableParent') tableParentOrderLines: ElementRef;
  @Input() public transportorder: TransportOrder;

  constructor(private readonly toScreenService: ToScreenService,
    private readonly translate: TranslateService,
    private readonly toActions: ToActions,
    private readonly sharedAction: SharedActions,
    private readonly userActions: ApplicationUserActions,
    private readonly toastr: ToastrService,
    private readonly toActionBarService: ToActionBarService,
    private readonly pbActions: PbActions) { }
  ngOnInit(): void {

    this.loading$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
    this.applicationUser$.pipe(takeUntil(this.destroy$)).subscribe((applicationUser: ApplicationUser) => {
      this.user = applicationUser;
    });

    this.sessionOrderLineColumnSelection$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      this.columns = this.createDataTablesColumns(data);
    });

    this.toSelectionForDetails$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
      if(data) {
        this.selectedTransportOrder = data ; 
        if (this.selectedTransportOrder) {
          this.planningBlocksForTO$.subscribe((data) => {
            this.planningBlocksForTo = data;
          })
        }
      }
    });
    
    this.selectedTransportOrders$.pipe(takeUntil(this.destroy$)).subscribe((transportOrders: TransportOrder[]) => {
      this.selectedTransportOrder = transportOrders[0];

      if (this.selectedTransportOrder) {
       
        this.planningBlocksForTO$.subscribe((data) => {
          this.planningBlocksForTo = data;
        })
      }
      
    });

    this.orderLines$.pipe(takeUntil(this.destroy$)).subscribe((orderlines: OrderLine[]) => {
      
      if (orderlines && orderlines.length > 0) {
        this.orderLines = orderlines;
        this.tableHeight = (this.orderLines.length + 2) * 100
        this.setGridContent();
      } 
    }) 

    this.orderlinesForTO$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        if(data && data.length > 0) {
          this.orderLines = data ;
          this.tableHeight = (this.orderLines.length + 2) * 100
          this.setGridContent();
        }
    })

    this.selectedOrderlines$.pipe(takeUntil(this.destroy$)).subscribe((orderlines: OrderLine[]) => {
      let selectedPlanningBlocks : PlanningBlock[] = [];
      if (orderlines) {
        this.selectedOrderlines = orderlines;
        this.planningBlocksForTO$.subscribe((res) => {
          this.planningBlocksForTo = res;
          this.planningBlocksForTo.forEach((element) => {
            this.selectedOrderlines.forEach((orderline) => {
              if (element.id === orderline.planningBlockId && !selectedPlanningBlocks.map(pb => pb.id).includes(element.id)) {
                selectedPlanningBlocks.push(element);
              }
            })
          })
        })

        this.pbActions.setSelectedPlanningBlocks([...selectedPlanningBlocks]);
        this.toActionBarService.setActionBar(this.selectedOrderlines, selectedPlanningBlocks);

      }
      if (this.selectedOrderlines.length === 0) {
        this.mySelection = [];
        this.mySelectionKeys = [];

      }

    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
}

  public setGridContent(): void {
    this.tableData = {
      data: [...this.orderLines.slice(this.state.skip, this.state.skip + this.state.take)],
      total: this.orderLines.length,
    };
   
  }

  public createDataTablesColumns(selectedColumns: Column[]): string[] {
    const result = [];
    for (const column of selectedColumns) {

      this.translate.get(column.name).subscribe((translated) => {

        result.push({
          cellTemplate: this.dateTemplate,
          name: translated,
          prop: column.name.substring(column.name.lastIndexOf('.') + 1),
          width: column.width,
        });
      });
    }
    return result;
  }
  public refreshTableWidth(): void {
    this.tableWidth = this.tableParentOrderLines.nativeElement.offsetWidth;
  }

  public refreshTableDisplay(): void {
    this.state.skip = 0;
    this.setGridContent();
  }


  public changeDates(tos: TransportOrder[], prop: string): TransportOrder[] {
    tos.forEach((to: TransportOrder) => {
      if (to[prop].length) {
        to[prop] = to[prop].toString().split(' ')[0].split('/')
          .reverse().join(',') + ' ' + to[prop].toString().split(' ')[1];
      }
    });
    return tos;
  }

  public changeDatesBack(tos: TransportOrder[], prop: string): TransportOrder[] {
    tos.forEach((to: TransportOrder) => {
      if (to[prop].length) {
        to[prop] = to[prop].toString().split(' ')[0].split(',')
          .reverse().join('/') + ' ' + to[prop].toString().split(' ')[1];
      }
    });
    return tos;
  }

  public moveElementInArray(array, reorderedColumnIndex, differencePos) {
    const value = array[reorderedColumnIndex];
    const arrayClone = array.slice();
    const newpos = reorderedColumnIndex + -(differencePos);
    arrayClone.splice(reorderedColumnIndex, 1);
    arrayClone.splice(newpos, 0, value);

    return arrayClone;
  }

  public setGridDimensions(): void {
    this.windowHeight = window.screen.height;
    this.tableHeight = 50 * this.tableData.total;
    const rowHeight = (this.tableHeight - this.GRID_FILLER_SPACE) / this.state.take;
    document.querySelector('body').style.cssText = '--row-height: ' + rowHeight + 'px';
    this.refreshTableWidth();
  }

  public showTooltip(event: any) {
    this.tooltipOffset = { left: event.pageX + 4, top: event.pageY + 4 };
    this.tooltipValue = event.srcElement.innerText;
    if (this.tooltipValue.length > 0 &&
      (event.srcElement.localName === 'td' || event.srcElement.localName === 'a')
      && (event.srcElement.offsetWidth < event.srcElement.scrollWidth)) {
      this.tooltipVisible = true;
    }
  }

  public hideTooltip() {
    this.tooltipVisible = false;
  }

  public pageChange(event: DataStateChangeEvent): void {
    this.state.skip = event.skip;

    this.setGridContent();
  }

  public sortChange(sort: SortDescriptor[]): void {
    this.state.sort = sort;
    const prop = this.state.sort[0].field;
    let tos: OrderLine[] = this.orderLines;

    this.orderLines = orderBy([...this.orderLines], sort);

    this.setGridContent();
  }
  public reorderGridUser(event: ColumnReorderEvent) {
    const column: any = event.column;
    const reorderedColumnIndex = this.user.orderlinePreferences.OrderLineColumnSelection
      .indexOf(this.user.orderlinePreferences.OrderLineColumnSelection
        .filter((x) => x && x.name.substring(x.name.lastIndexOf('.') + 1) === column.field)[0]);

    if (reorderedColumnIndex !== -1) {
      const differencePos = event.oldIndex - event.newIndex;
      if (differencePos) {
        const updatedArray =
          this.moveElementInArray(this.user.orderlinePreferences.OrderLineColumnSelection, reorderedColumnIndex, differencePos);
        this.user.orderlinePreferences.OrderLineColumnSelection = updatedArray;
        this.userActions.updateApplicationUser(this.user, 'defaultOrderLineUserSettings',true);
      }
    } else {
      this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_NOT_FOUND_REORDER_FAILED').subscribe((translated) => {
        this.toastr.warning(translated);
      });
    }
  }

  public resizeGrid(event: ColumnResizeArgs[]) {
    if (this.isTheResizedColumnValid(event)) {
      const column: any = event[0].column;
      const resizedColumnIndex = this.user.orderlinePreferences.OrderLineColumnSelection
        .indexOf(this.user.orderlinePreferences.OrderLineColumnSelection
          .filter((x) => x && x.name.substring(x.name.lastIndexOf('.') + 1) === column.field)[0]);

      if (resizedColumnIndex !== -1) {
        if (this.isNewWidthValid(event[0].newWidth)) {
          this.user.orderlinePreferences.OrderLineColumnSelection[resizedColumnIndex].width = event[0].newWidth;
          this.userActions.updateApplicationUser(this.user, 'defaultOrderLineUserSettings',true);
        } else {
          this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_INVALID_WIDTH_RESIZE_FAILED').subscribe((translated) => {
            this.toastr.warning(translated);
          });
        }
      } else {
        this.translate.get('TOASTR_MESSAGES.WARNING.COLUMN_NOT_FOUND_RESIZE_FAILED').subscribe((translated) => {
          this.toastr.warning(translated);
        });
      }
    }
  }

  private isTheResizedColumnValid(event: ColumnResizeArgs[]): boolean {
    return event.length === 1 && event[0].hasOwnProperty('column');
  }

  private isNewWidthValid(newWidth: number): boolean {
    return newWidth > 0;
  }

  public rowClicked(gridUser, selection): void {
    if (selection.selectedRows && selection.selectedRows.length > 0) {
      this.mySelection = [...this.mySelection.concat(selection.selectedRows.map((x) => x.dataItem))];
    }

    if (selection.deselectedRows && selection.deselectedRows.length > 0) {
      const deselectedOrderlines = selection.deselectedRows.map((x) => x.dataItem);
      this.mySelection = [...this.mySelection.filter((x) => deselectedOrderlines.indexOf(x) === -1)];
    }

    this.selectedOrderlines = this.mySelection;
    this.toActions.setSelectedOrderlines(this.mySelection);
  }


  /**
   * changeOrderlineSequence
$event , sequence    */
  public changeOrderlineSequence($event, targetSequenceNo): void {

    if (this.mySelection.length > 0) {
      var orderLineToMove = this.mySelection[0];

      this.toScreenService.changePlanningSequence(this.selectedTransportOrder.id, orderLineToMove.squenceNo < targetSequenceNo,
        targetSequenceNo, orderLineToMove).subscribe((data) => {

          this.toastr.info("Operation Success");

          if (this.selectedTransportOrder) {
            this.toScreenService.getTransportOrderDetails(this.selectedTransportOrder.id).subscribe((orderlines: any[]) => {
              this.sharedAction.getOrderlines(orderlines);
            })
            this.planningBlocksForTO$.subscribe((data) => {
              this.planningBlocksForTo = data;
              this.pbActions.updatePlanningBlocksDisplay(data);
            })

            this.resetSelection();
          }
        });
    }

  }

  public CanMoveRow(orderLine: OrderLine) {

    if (this.mySelection.length > 0) {
      if (this.mySelection[0].orderLineId == orderLine.orderLineId)
        return false;
      else
        return true;
    }
    else
      return false;

  }

  resetSelection() {
    this.toActions.setSelectedOrderlines([]);
    this.toActionBarService.resetActionBar();
  }


}
