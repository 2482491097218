
import {of as observableOf,  Observable, pipe } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { NgRedux } from '@angular-redux/store';
import { Injectable } from '@angular/core';
import { IAppState } from '../store/IAppState';
import { ToastrService } from 'ngx-toastr';
import { settings } from 'cluster';
import { HttpClientService } from '../shared/services/httpclient';
import { SharedActions } from '../shared/shared.actions';
import { ApplicationUserService } from '../pb-screen/services/application.user.service';
import { UserSettingsService } from '../pb-screen/services/usersettings.service';
import { UserSettings, ApplicationUser, DocumentToBePrinted } from '../pb-screen/models/application.user.interface';
import { ActionKeys } from '../pb-screen/action.keys';
import { GlobalSettingDefinition, DefaultGlobalSettingDefinition } from '../pb-screen/static/columns/GlobalSettingDefinition';

@Injectable()
export class settingAction {
    constructor(
        private readonly ngRedux: NgRedux<IAppState>,
        private readonly applicationUserService: ApplicationUserService,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly httpClientService: HttpClientService,
        private readonly userSettingService: UserSettingsService,
        private readonly sharedActions: SharedActions
    ) { }

    getDefaultPreferenceValues(setting: string) {
        
        var documentArray : DocumentToBePrinted[] = [];
        switch (setting) {
            case 'globalPrintSetting':
                return JSON.stringify({
                    depotIn: [],
                    depotOut: [],
                    pbscreen: [],
                    pbdetails: [],
                    toscreen: [],
                    todetails: [],
                    planningBoard: []
                });
            default:
                return null;
            }
        }


    getDefaultGlobalPreference(settingNames: string[]): Observable<UserSettings[]> {
        
        return this.userSettingService.getGolbalSettings().map(pipe(userSettings => {

            this.ngRedux.dispatch({
                type: ActionKeys.SET_GLOBAL_SETTINGS,
                settings: userSettings
            });

            settingNames.forEach(settingName => {
            
                let result = userSettings.find((item: UserSettings) => {
                    return item.setting.name === settingName;
                });
                if (!result) {

                    this.userSettingService.getSettingByName(settingName).subscribe((setting) => {
                        let defaultValue = '';
                        if (settingName === 'globalPrintSetting') {
                            defaultValue = this.getDefaultPreferenceValues('globalPrintSetting');
                        }
                        this.userSettingService.createGlobalSettings(setting, defaultValue, 'module', 'planning').subscribe((userSetting) => {
                        return observableOf(userSetting);
    
                        })
                    })
                } else {
    
                    return observableOf(userSettings[0]);
                }
            });
            return userSettings;
        }));
    }

    updateSettings(applicationUser: ApplicationUser, settingName) {

        this.userSettingService.getGolbalSettingByName(settingName).subscribe((userSetting: UserSettings[]) => {
            if (settingName === 'globalPrintSetting') {
                userSetting[0].value = JSON.stringify(applicationUser.Screens);
                /*this.translate.get('TOASTR_MESSAGES.SUCCESS.COLUMN_SELECTION').subscribe((res: string) => {
                    this.toastr.success(res)});*/
            }
            this.userSettingService.updateUserSettings(userSetting[0]).subscribe(
                () => {
                    this.ngRedux.dispatch({
                        type: ActionKeys.UPDATE_APPLICATION_USER,
                        applicationUser: applicationUser
                    });
                    this.translate.get('TOASTR_MESSAGES.SUCCESS.COLUMN_SELECTION').subscribe((res: string) => {
                        this.toastr.success(res);
                    });
                });
        })
    }
    }
