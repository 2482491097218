import { Component, EventEmitter, Input, Output } from '@angular/core';

import { ComponentSource, ModalType } from '../../models/component-source';
import { PlanningBlock } from '../../models/planning-block/planning-block.interface';
import { TruckAppointmentLine } from '../../models/tar-tas/tar-tas.interface';

@Component({
    selector: 'truck-appointment-line-display',
    templateUrl: './truck-appointment-line-display.component.html'
})

export class TruckAppointmentLineDisplayComponent {

    @Input() selectedPlanningBlocks: TruckAppointmentLine[];
    @Input() source: string;
    @Output() checkedPbs: EventEmitter<TruckAppointmentLine[]> = new EventEmitter<TruckAppointmentLine[]>();

    public componentSource = ComponentSource;
    public modalType = ModalType;

    checkPb(planningBlock){
        this.checkedPbs.emit(this.selectedPlanningBlocks.filter(el => (<any>el).isSelected));
    }
}