
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClientService } from "./httpclient";
import { environment } from '../../../environments/environment';
import { TarTasStatus, TruckAppointmentLine } from '../models/tar-tas/tar-tas.interface';
import { PLANNED, PRE_PLANNED, TO_BE_PLANNED } from '../models/status-type.interface';
import { PlanningBlock } from '../../pb-screen/models/planning-block/planning-block.interface';
import { TruckAppointmentModalComponent } from '../containers/truck-appointment-modal/truck-appointment-modal.component';
import { TruckAppointmentBookingModalComponent } from '../containers/truck-appointment-booking-modal/truck-appointment-booking-modal.component';
import { SimpleModalService } from 'ngx-simple-modal';
import { TarTasService } from './tartas.service';
import { SharedService } from './shared.service';
import { DepotOutActions } from '../../depot-out/depot-out.actions';
import { ToastrService } from 'ngx-toastr';
import { PbActions } from '../../pb-screen/pb.actions';
import { PbScreenService } from '../../pb-screen/services/pb-screen.service';

@Injectable()
export class TarTasManager {

  constructor(
    private readonly simpleModalService: SimpleModalService,
    private readonly tarTasService: TarTasService,
    private readonly sharedService: SharedService,
    private readonly depotOutActions: DepotOutActions,
    private readonly toastr: ToastrService,
    private readonly pbActions: PbActions,
    private readonly pbService: PbScreenService
  ) { }
  
    checkIfTruckSelectionEnabled(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): boolean {
      let truckAppointmentEnabled = true;
      var prevTruckAppointmentSystem: string;
      var prevTruckStatus: number;
      var prevTruckAppointmentNo: string;
      if(depotInSelection.length)
      {
        prevTruckAppointmentSystem = depotInSelection[0].dInTruckAppointmentSystem;
        prevTruckStatus = depotInSelection[0].dInTruckAppointmentStatus;
        prevTruckAppointmentNo = depotInSelection[0].dInTruckAppointmentNo;
      }
      else if(depotOutSelection.length)
      {
        prevTruckAppointmentSystem = depotOutSelection[0].dOutTruckAppointmentSystem;
        prevTruckStatus = depotOutSelection[0].dOutTruckAppointmentStatus;
        prevTruckAppointmentNo = depotOutSelection[0].dOutTruckAppointmentNo;
      }
      else 
        return false;
      for(let i = 0; i<depotInSelection.length; i++)
      {
        //check if status required, ok or error
        if(depotInSelection[i].dInTruckAppointmentStatus != TarTasStatus.Required
          && depotInSelection[i].dInTruckAppointmentStatus != TarTasStatus.OK
          && depotInSelection[i].dInTruckAppointmentStatus != TarTasStatus.Error)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        // check if all required
        if(depotInSelection[i].dInTruckAppointmentStatus == TarTasStatus.Required
          && prevTruckStatus != TarTasStatus.Required)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        //check if all ok or error
        if((depotInSelection[i].dInTruckAppointmentStatus == TarTasStatus.OK
          || depotInSelection[i].dInTruckAppointmentStatus == TarTasStatus.Error)
          && (prevTruckStatus != TarTasStatus.OK && prevTruckStatus != TarTasStatus.Error))
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        //check if pb type to be planned, pre planned, planned
        if(depotInSelection[i].statusType != TO_BE_PLANNED 
          && depotInSelection[i].statusType != PRE_PLANNED
          && depotInSelection[i].statusType != PLANNED)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        // check if all appointment systems same
        if(prevTruckAppointmentSystem != depotInSelection[i].dInTruckAppointmentSystem)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(depotInSelection[i].dInPreAnnouncementStatus != TarTasStatus.OK)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(depotInSelection[i].dInTruckAppointmentNo != prevTruckAppointmentNo)
        {
          truckAppointmentEnabled = false;
          break;
        }
      }
  
      for(let i = 0; i<depotOutSelection.length; i++)
      {
        if(depotOutSelection[i].dOutTruckAppointmentStatus != TarTasStatus.Required
          && depotOutSelection[i].dOutTruckAppointmentStatus != TarTasStatus.OK
          && depotOutSelection[i].dOutTruckAppointmentStatus != TarTasStatus.Error)
        {
          truckAppointmentEnabled = false;
          break;
        }
        
        // check if all required
        if(depotOutSelection[i].dOutTruckAppointmentStatus == TarTasStatus.Required
          && prevTruckStatus != TarTasStatus.Required)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        //check if all ok or error
        if((depotOutSelection[i].dOutTruckAppointmentStatus == TarTasStatus.OK
          || depotOutSelection[i].dOutTruckAppointmentStatus == TarTasStatus.Error)
          && (prevTruckStatus != TarTasStatus.OK && prevTruckStatus != TarTasStatus.Error))
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(depotOutSelection[i].statusType != TO_BE_PLANNED 
          && depotOutSelection[i].statusType != PRE_PLANNED
          && depotOutSelection[i].statusType != PLANNED)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(prevTruckAppointmentSystem != depotOutSelection[i].dOutTruckAppointmentSystem)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(depotOutSelection[i].dOutPreAnnouncementStatus != TarTasStatus.OK)
        {
          truckAppointmentEnabled = false;
          break;
        }
  
        if(depotOutSelection[i].dOutTruckAppointmentNo != prevTruckAppointmentNo)
        {
          truckAppointmentEnabled = false;
          break;
        }
      }
      return truckAppointmentEnabled;
    }

    truckAppointmentAction(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]){
      if((depotInSelection.length && 
            depotInSelection[0].dInTruckAppointmentStatus == TarTasStatus.Required)
        || (depotOutSelection.length && 
              depotOutSelection[0].dOutTruckAppointmentStatus == TarTasStatus.Required))
      {
        this.simpleModalService.addModal(TruckAppointmentModalComponent)
        .subscribe(()=>{
        });
      }
      else{
        {
          this.simpleModalService.addModal(TruckAppointmentBookingModalComponent)
          .subscribe(()=>{
          });
        }
      }
  
    }

    linkDepotOutToDepotIn(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[])
    {
        if(depotOutSelection.length && depotInSelection.length)
        {
            let orderlineIds = depotOutSelection.map(el => el.dOutOrderLineID);
            let dInOrderLineID = depotInSelection[0].dInOrderLineID;
            this.tarTasService.getTruckAppointmentNo(dInOrderLineID)
                .subscribe(truckAppointmentNo => {
                    this.tarTasService.connectContainers(orderlineIds, '', truckAppointmentNo)
                        .subscribe(el => {
                          let pbIds = depotOutSelection.map(el => el.id);
                            this.sharedService.getPlanningBlocksByIds(pbIds)
                                .subscribe((planningBlocks) => {
                                    this.depotOutActions.updatePlanningBlocks(planningBlocks);
                                });
                              this.pbService.getPlanningBlocksByIds(pbIds)
                                .subscribe((pbs)=> {
                                    this.pbActions.updatePlanningBlocksDisplay(pbs);
                                });
                            this.toastr.success('Linked to appointment.');
                        });
                });
        }
    }

    isLinkToAppointmentEnabled(depotInSelection: PlanningBlock[], depotOutSelection: PlanningBlock[]): any {
      let linkToAppointmentEnabled = false;
      let showWarning = false;
      let warningMessage = '';
      let prevTruckAppointment = '';
      if(!depotOutSelection.length || !depotInSelection.length)
          return { linkToAppointmentEnabled, showWarning, warningMessage };
      if(depotInSelection.length)
      {
          prevTruckAppointment = depotInSelection[0].dInTruckAppointmentNo;
          for(let i=0; i<depotInSelection.length; i++)
          {
              if(TarTasStatus.NotRequired === depotInSelection[i].dInTruckAppointmentStatus)
                  continue;

              if(prevTruckAppointment !== depotInSelection[i].dInTruckAppointmentNo)
              {
                  showWarning = true;
                  warningMessage = 'Depot In PBs have different Truck Appointment No.';
                  break;
              }

              if(TarTasStatus.OK !== depotInSelection[i].dInTruckAppointmentStatus 
                  || !depotInSelection[i].dInTruckAppointmentNo)
              {
                  showWarning = true;
                  warningMessage = 'Depot In PBs don`t have a truck appointment.';
                  break;
              }
              linkToAppointmentEnabled = true;
          }
      }
      if(linkToAppointmentEnabled && depotOutSelection.length)
      {
          linkToAppointmentEnabled = false;
          for(let i=0; i<depotOutSelection.length; i++)
          {
              if(TarTasStatus.NotRequired === depotOutSelection[i].dOutTruckAppointmentStatus)
                  continue;

              if(TarTasStatus.OK === depotOutSelection[i].dOutTruckAppointmentStatus)
              {
                  if(prevTruckAppointment !== depotOutSelection[i].dOutTruckAppointmentNo)
                  {
                      showWarning = true;
                      warningMessage = 'Depot Out PBs have different Truck Appointment No. than Depot In PBs';
                      break;
                  }
                  continue;
              }
                  
              if(depotOutSelection[i].dOutPreAnnouncementStatus != TarTasStatus.OK 
                  || !depotOutSelection[i].dOutPreAnnouncementNo)
              {
                  showWarning = true;
                  warningMessage = 'Depot Out PBs aren`t preannounced.';
                  break;
              }

              linkToAppointmentEnabled = true;
          }
      }

      if(showWarning)
          linkToAppointmentEnabled = false;
          
      return {linkToAppointmentEnabled, showWarning, warningMessage};
  }
}
