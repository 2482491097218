import { Component, EventEmitter, Input, OnChanges, Output, SimpleChange, OnInit, ViewChild, ElementRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { DepotInActions } from "../../../../depot-in/depot-in.actions";
import { DepotOutActions } from "../../../../depot-out/depot-out.actions";
import { SharedActions } from "../../../shared.actions";
import { SharedService } from "../../../services/shared.service";

import { select } from "@angular-redux/store";
import { Observable } from "rxjs/Observable";
import { CMRService } from "../../../../services/print-CMR.service";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";

@Component({
  selector: "cmr-print-modal",
  templateUrl: "cmr-print-modal.component.html",
})

export class CMRPrintComponent implements OnInit {


  @select("depotInSelection") depotInSelection$: Observable<PlanningBlock[]>;

  @ViewChild('modal')
  modal: ElementRef;

public depotInSelection: PlanningBlock[];

  constructor(
    private readonly sharedActions: SharedActions,
    private readonly sharedService: SharedService,
    private readonly translate: TranslateService,
    private readonly toastr: ToastrService,
    private readonly depotInActions: DepotInActions,
    private readonly depotOutActions: DepotOutActions,
    private readonly cmrService: CMRService
  ) { }

  public ngOnInit(): void {
    this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
      this.depotInSelection = selectedPlanningBlocks;
    });
  }

  public onCancelClicked(): void {
    this.resetUi();
  }

  public resetUi(): void {
    this.sharedActions.setUiState(true);
  }

  public print(){
    this.cmrService.printCMR(this.depotInSelection);
    this.modal.nativeElement.click();
    this.resetUi();
  }


}
