import { select } from "@angular-redux/store";
import { Component, ElementRef, Input, OnInit, ViewChild } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { ToastrService } from "ngx-toastr";
import { Observable } from "rxjs/Observable";

import { ComponentSource, ModalType } from "../../../shared/models/component-source";
import { RailwayDeparture } from "../../../shared/models/RailwayDeparture";
import { RailwayLine } from "../../../shared/models/RailwayLine";
import { SharedActions } from "../../../shared/shared.actions";
import { SharedService } from "../../services/shared.service";
import { ModalManagerService } from "../../services/modal-manager.service";
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { MsalService } from "../../../msal/msal.service";



@Component({
    selector: "rail-planning-modal",
    templateUrl: "./rail-planning-modal.component.html",
})

export class RailPlanningModalComponent implements OnInit {
    public selectedPbs: PlanningBlock[];
    public componentSource = ComponentSource;
    public modalType = ModalType;
    public dateInput: string;
    public lineList: RailwayLine[];
    public departureList: RailwayDeparture[];
    public selectedLine: RailwayLine = null;
    public selectedDeparture: RailwayDeparture;
    public loadingSpinner: boolean = false;
    public rowIndex: number;
    public userId: string;

    @Input() public source: string;
    @select("depotInSelection") public depotInSelection$: Observable<PlanningBlock[]>;
    @select("depotOutSelection") public depotOutSelection$: Observable<PlanningBlock[]>;
    @select('loading') public loading$: Observable<boolean>;

    constructor(private readonly sharedActions: SharedActions,
        private readonly sharedService: SharedService,
        private readonly translate: TranslateService,
        private readonly toastr: ToastrService,
        private readonly modalManager: ModalManagerService,
        private readonly authService: MsalService) { }

  public ngOnInit() {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })
        this.depotInSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotIn) {
                this.selectedPbs = selectedPlanningBlocks;
            }
        });
        this.depotOutSelection$.subscribe((selectedPlanningBlocks: PlanningBlock[]) => {
            if (this.source === this.componentSource.depotOut) {
                this.selectedPbs = selectedPlanningBlocks;
            }
        });

       /* this.homeService.getAuthorizedUser().subscribe((data: AuthorizedUser) => {
            if (data) {
                this.userId = data.username;
                this.sharedService.getAvailableRailwayLines().subscribe((res: RailwayLine[]) => {
                    const filteredLines = res.filter((railwayLine: RailwayLine) =>
                        railwayLine.directionOfTrain[0] === (this.source === ComponentSource.depotOut ? '1' : '2'));
                    this.lineList = filteredLines;
                });
            }
        },
            (error) => {
                this.toastr.error("ERROR : " + error.json().feedback[0].description);
                this.loadingSpinner = false;
            });
        */ 
       const account = this.authService.getAccount(); 
       this.userId =  account.username;
        this.dateInput = new Date().toISOString().slice(0, 10);
    }

    public dateInputChanged() {
        this.formatDateForApi();
        if (this.selectedLine) {
            this.sharedService.getRailwayDepartures(this.selectedLine.no, this.formatDateForApi(), this.userId)
                .subscribe((res: RailwayDeparture[]) => {
                    this.departureList = res;
                    this.selectedDeparture = null;
                });
        }
    }

    public lineSelectionChanged() {
        this.sharedService.getRailwayDepartures(this.selectedLine.no, this.formatDateForApi(), this.userId)
            .subscribe((res: RailwayDeparture[]) => {
                this.departureList = res;
                this.selectedDeparture = null;
            });
    }

    public planAction() {
        const account = this.authService.getAccount(); 
        this.userId =  account.username;
        
        if (!this.selectedLine) {
            this.translate.get("TOASTR_MESSAGES.ERROR.MISSING_TRAIN_LINE").subscribe((res: string) => {
                this.toastr.error(res);
            });
        } else if (!this.selectedDeparture) {
            this.translate.get("TOASTR_MESSAGES.ERROR.MISSING_TRAIN_DEPARTURE").subscribe((res: string) => {
                this.toastr.error(res);
            });
        } else {
            this.loadingSpinner = true;
            
                if (this.userId) {
                    this.planPbsToTrain(this.userId);
                } else {
                    this.toastr.error("Error :" + "User Not Found");
                }
            
        }
    }

    public planPbsToTrain(username: string): void {
        this.sharedService.planPbsToTrain(
            this.selectedPbs,
            this.selectedLine.no,
            this.selectedDeparture.entryNo,
            username,
        )
            .subscribe((plannedPbs: PlanningBlock[]) => {
                this.translate.get("TOASTR_MESSAGES.SUCCESS.ALLOCATE_TO_TRAIN").subscribe((res: string) => {
                    this.toastr.success(res, "", {
                        closeButton: false
                    });
                });
                this.closeModal();
                this.sharedActions.updatePlanningBlocksDisplayCpl(plannedPbs);
                this.loadingSpinner = false;
            }
                , (err) => {
                    this.loadingSpinner = false;
                });
    }

    public closeModal() {
        this.sharedActions.resetModalDomains();
        this.enableUi();
        this.clearFields();
    }

    public clearFields() {
        this.departureList = null;
        this.selectedLine = null;
        this.rowIndex = -1;
    }

    public departureSelected(selectedDeparture, rowIndex) {
        this.rowIndex = rowIndex;
        this.selectedDeparture = selectedDeparture;
    }

    public enableUi() {
        this.sharedActions.setUiState(true);
        this.modalManager.closeModal(this.modalType.rail);
    }

    public formatDateForApi() {
        const dateArray = this.dateInput.split("-");
        const apiFormattedDate = dateArray[1] + "-" + dateArray[2] + "-" + dateArray[0];
        return apiFormattedDate;
    }

}
