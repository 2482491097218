
import {throwError as observableThrowError,  Observable } from 'rxjs';

import {catchError, map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClientService } from "../httpclient";
import { Http, Response, Headers, RequestOptions, ResponseContentType } from "@angular/http"
import { PlanningBlock } from "../../../pb-screen/models/planning-block/planning-block.interface";
import { ContainerListData } from "../../../pb-screen/models/container-list-data.interface";
import { environment } from "../../../../environments/environment";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class PrintService {
    
    constructor(
        private readonly http : HttpClientService, 
        private readonly http1 : Http ,
        private readonly toastr: ToastrService
    ) {

    }

    //Print ShuttleList 
    public printShuttleList(pbs: PlanningBlock[], name: string): any {
        pbs.forEach((element) => {
            if (element.specificContainerActions === undefined) {
                element.specificContainerActions = "null"
            }
            delete element["idString"];
            delete element["haulier"];
            delete element["trailer"];
            delete element["chassis"];
            delete element["haulierRemarks"];
            delete element["planningRemarks"];
        })

        const data: ContainerListData = {
            "PlanningBlocks": pbs,
            "Name": name,
            "Type": {
                "ID": 1
            }
        }

        data.PlanningBlocks = pbs.sort((a, b) => (a.fileId, b.fileId) ? 1 : ((b.fileId > a.fileId) ? -1 : 0));
        data.Name = name;

        return this.http1.post(environment.tmsApiEndpointAddress + "print/shuttlelist", data, { responseType:ResponseContentType.Blob }).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                
                return observableThrowError(err);
            }),);
    }

    //Print Container List
    public printContainerList(pbs: PlanningBlock[], name: string): any {

        pbs.forEach((element) => {
            delete element["idString"];
            delete element["haulier"];
            delete element["trailer"];
            delete element["chassis"];
            delete element["haulierRemarks"];
            delete element["planningRemarks"];
        })

        const data: ContainerListData = {
            "Name": "Mohamed",
            "PlanningBlocks": pbs,
            "Type": {
                "ID": 1
            }
        }

        data.PlanningBlocks = pbs.sort((a, b) => {
            if (a.closingDate.toString().split(" ")[0].split("/").reverse().join() >
                b.closingDate.toString().split(" ")[0].split("/").reverse().join()) { return 1; }
            if (a.closingDate.toString().split(" ")[0].split("/").reverse().join() <
                b.closingDate.toString().split(" ")[0].split("/").reverse().join()) { return -1; }

            if (a.fileId > b.fileId) { return -1; }
            if (a.fileId < b.fileId) { return 1; }
        });

        data.Name = "Mohamed";
        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        let options = new RequestOptions({
            headers : headers ,
            responseType :ResponseContentType.Blob
        })
        return this.http1.post(environment.tmsApiEndpointAddress + "print/containerlist", data, options).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                
                return observableThrowError(err);
            }),);
    }

    //Print Stacker List
    public printStackerList(pbs: PlanningBlock[], name: string): any {

        pbs.forEach((element) => {
            delete element["idString"];
            delete element["haulier"];
            delete element["trailer"];
            delete element["chassis"];
            delete element["haulierRemarks"];
            delete element["planningRemarks"];
        })

        const data: ContainerListData = {
            "PlanningBlocks": pbs,
            "Name": name,
            "Type": {
                "ID": 1
            }
        }

        data.PlanningBlocks = pbs.sort((a, b) => (a.fileId, b.fileId) ? 1 : ((b.fileId > a.fileId) ? -1 : 0));
        data.Name = name;
        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        headers.append('responseType' , 'blob');
        let options = new RequestOptions({
            headers : headers ,
            responseType :ResponseContentType.Blob
        })
        return this.http1.post(environment.tmsApiEndpointAddress + "print/stackerlist", data, options).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                
                return observableThrowError(err);
            }),);

    }

    //Print Warehouse List
    public printWareHouseList(pbs: PlanningBlock[], name: string): any {
        pbs.forEach((element) => {
            delete element["idString"];
            delete element["haulier"];
            delete element["trailer"];
            delete element["chassis"];
            delete element["haulierRemarks"];
            delete element["planningRemarks"];
        })

        const data: ContainerListData = {
            "PlanningBlocks": pbs,
            "Name": name,
            "Type": {
                "ID": 1
            }
        }


        data.PlanningBlocks = pbs.sort((a, b) => (a.fileId > b.fileId) ? 1 : ((b.fileId > a.fileId) ? -1 : 0));
        data.Name = name;

        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        headers.append('responseType' , 'blob');
        let options = new RequestOptions({
            headers : headers ,
            responseType :ResponseContentType.Blob
        })

        return this.http1.post(environment.tmsApiEndpointAddress + "print/warehouselist", data, options).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                
                return observableThrowError(err);
            }),);
    }

    //Print StuffingStaal List
    public printStuffingStaalList(pbs: PlanningBlock[], name: string): any {
        pbs.forEach((element) => {
            delete element["idString"];
            delete element["haulier"];
            delete element["trailer"];
            delete element["chassis"];
            delete element["haulierRemarks"];
            delete element["planningRemarks"];
        })

        const data: ContainerListData = {
            "PlanningBlocks": pbs,
            "Name": name,
            "Type": {
                "ID": 1
            }
        }

        data.PlanningBlocks = pbs.sort((a, b) => (a.vgmClosing.toString().split(" ")[0].split("/").reverse().join() <
            b.vgmClosing.toString().split(" ")[0].split("/").reverse().join()) ? -1 : 1);
        data.Name = name;

        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        headers.append('responseType' , 'blob');
        let options = new RequestOptions({
            headers : headers ,
            responseType :ResponseContentType.Blob
        })

        return this.http1.post(environment.tmsApiEndpointAddress + "print/stuffingstaallist", data, options).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                
                return observableThrowError(err);
            }),);


    }

    //Print Week Plan
    public printWeekPlan(name: string, startDate: string, endDate: string, type: number): any {
        const printData = {
            filterDateFrom: startDate,
            filterDateUntil: endDate,
            userName: name,
            type: {
                ID: 1
            }
        };

        let headers = new Headers(); 
        headers.append('Authorization' , 'Basic ' + localStorage.getItem('basicToken')); 
        headers.append('responseType' , 'blob');
        let options = new RequestOptions({
            headers : headers ,
            responseType :ResponseContentType.Blob
        })

        return this.http1.post(environment.tmsApiEndpointAddress + "print/week-planning-list", printData, options).pipe(
            map((res:any) => {
                window.open(window.URL.createObjectURL(new Blob([(res as any)._body], { type: "application/pdf" })))
            }
            ),
            catchError((err) => {
                return observableThrowError(err);
            }),);
    }

    //Print Transport Order
    public printtransportorder(Id: string) {
        this.http.getWithConfig(environment.tmsApiEndpointAddress  + "print/printTO?id=" + Id, { responseType: "blob" } ).subscribe((response : any) => {
            window.open(window.URL.createObjectURL(response)) ;
        });
    }

}