import { OnInit, Component } from "@angular/core";
import { select } from "@angular-redux/store";
import { PlanningBlock } from "../../../../models/planning-block/planning-block.interface";
import { Observable } from "rxjs";
import { PbScreenService } from "../../../../services/pb-screen.service";
import { ApplicationUser } from "../../../../models/application.user.interface";

@Component({
    moduleId: module.id,
    selector: 'pb-general-details',
    templateUrl: './pb-general-details.component.html',
})
export class PbGeneralDetails implements OnInit {
    
    @select('selectedPlanningBlocks') public selectedPlanningBlocks$: Observable<PlanningBlock[]>;
    @select('selectedPbsFromPlanBoard') selectedPbsFromPlanBoard$ : Observable<PlanningBlock[]>;
    @select("selectedPbsToPlan") public selectedPbsToPlan$ : Observable<PlanningBlock[]>;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    public selectedPb: any = null; 
    applicationUser: ApplicationUser;
    public enableAutocomplete: boolean = false;

    constructor(private readonly pbScreenService: PbScreenService){

    }

    ngOnInit(): void {
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
              this.applicationUser = applicationUser;
              this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
            }
          });

        this.selectedPlanningBlocks$.subscribe((planningBlocks : PlanningBlock[]) => {
            if(planningBlocks && planningBlocks.length > 0 )
            {
                this.selectedPb = planningBlocks[0];
            }
           
        })

        this.selectedPbsFromPlanBoard$.subscribe((data) => {
            if(data && data.length > 0) {
                this.selectedPb = data[0];
            }
           
        })

        this.selectedPbsToPlan$.subscribe((data) => {
            if(data && data.length) {
                this.selectedPb = data[0];
            }
          })
    }

}