import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PlanningStatus } from '../../../models/status-type.interface';
import { ColDelPlanning } from '../../../models/pb-screen.interface';
import { select } from '@angular-redux/store';
import { IdNameAsStringObject } from '../../../models/quick-view/id-name-as-string-object.interface';
import { Observable } from 'rxjs';
import { AdvancedFilterColumn, AdvancedFilterColumnType, AdvancedFilterItem, AdvancedFilterOperatorType } from '../../../../shared/components/filter-line/filter-line.interface';
import * as moment from 'moment';
import { ApplicationUser } from '../../../models/application.user.interface';

@Component({
  moduleId: module.id,
  selector: 'filter-line',
  templateUrl: './filter-line.component.html',
  styleUrls: ['./filter-line.component.css']
})

export class FilterLineComponent implements OnInit {
  @Input() public showOperator: boolean;
  @Input() public advancedFilterColumns: AdvancedFilterColumn[];
  private _filterItem;
  @Input()
  set filterItem (value: AdvancedFilterItem) {
    if(value.type == AdvancedFilterColumnType.DateTime)
      value.value = <any>moment(value.value).toDate();
    this._filterItem = value;
  }
  get filterItem(): AdvancedFilterItem{
    return this._filterItem;
  }
  @Output() public removeFilterItem: EventEmitter<AdvancedFilterItem> = new EventEmitter<AdvancedFilterItem>();
  @Input() public filterItems: AdvancedFilterItem[] ;

  public enableAutocomplete: boolean = false;
  @select('applicationUser') public applicationUser$: Observable<ApplicationUser>;
  @select("containerStatuses") public containerStatuses$: Observable<IdNameAsStringObject[]>;
  public containerStatuses: IdNameAsStringObject[] = [];

  public selectedColumn: AdvancedFilterColumn;
  public selectedOperator: string;
  public selectedSelectOption: string;

  public advancedFilterColumnType = AdvancedFilterColumnType;
  public showAddButton: boolean;
  public showRemoveButton: boolean;
  public isColumnSelected: boolean;
  public operators: string[];
  public selectOptions: string[];
  public planningStatus = PlanningStatus;
  public colDelPlanning = ColDelPlanning;
  public selectOption: string;

  public ngOnInit(): void {
    this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
      if (applicationUser) {
        this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
      }
    });
    
    this.containerStatuses$.subscribe((statuses: IdNameAsStringObject[]) => {
        this.containerStatuses = statuses;
    });
    this.showAddButton = false;
    this.showRemoveButton = true;
    this.isColumnSelected = false;
    this.selectedColumn = this.advancedFilterColumns.find((x) => x.name === this.filterItem.columnName);

    if (this.selectedColumn) {

      this.operators = this.selectedColumn.advancedFilterTypeOperator.operators;
      this.selectedOperator =
      this.selectedColumn.advancedFilterTypeOperator.operators.find((x) => x === this.filterItem.operator);
      this.selectOptions = this.setSelectionOptionsFromLoadedFilter(this.selectedColumn);
      this.isColumnSelected = true;
      this.showRemoveButton = true;
    }
    this.checkAddNew();
  }

  public checkAddNew() {
    if(this.filterItems.indexOf(this.filterItem) === this.filterItems.length - 1 || this.filterItems.indexOf(this.filterItem) === this.filterItems.length - 2 )
      {
        this.showAddButton = true;
      }
      else {
        this.showAddButton  = false;
      }
  }

  public onRemoveFilterItem(): void {
    this.showRemoveButton = false ;
    this.removeFilterItem.emit(this.filterItem);
  }

  public onColumnSelectionChange($event): void {
    if (this.selectedColumn) {
      this.isColumnSelected = true;
      this.filterItem.columnName = this.selectedColumn.name;
      this.operators = this.selectedColumn.advancedFilterTypeOperator.operators;
      this.selectOptions = this.setSelectionOptionsFromSelectedColumn(this.selectedColumn);

      if (this.operators.length > 0) {
        this.selectedOperator = this.selectedColumn.advancedFilterTypeOperator.operators.find((x) => x === this.operators[0]);
        this.filterItem.operator = this.selectedOperator as AdvancedFilterOperatorType;
      }

      this.filterItem.type = this.selectedColumn.advancedFilterTypeOperator.type;
    }
  }

  public onOperatorSelectionChange($event): void {
    this.filterItem.operator = $event.target.value;
  }

  public onSelectOptionChange($event): void {
    if (this.selectedColumn.name === 'containerStatus') {
      this.filterItem.value = $event.target.value;
    } else if (this.selectedColumn.name === 'status' || this.selectedColumn.name === "statusType") {
      this.filterItem.value = this.planningStatus[$event.target.value];
    } else if (this.selectedColumn.name === 'colDelPlanning') {
      this.filterItem.value = this.colDelPlanning[$event.target.value];
    }
  }

  public setSelectionOptionsFromSelectedColumn(selectedColumn: AdvancedFilterColumn): string[] {
    let selectionOptions: string[] = [];
    if (selectedColumn.name === 'containerStatus') {
      selectionOptions = this.containerStatuses.map(el => el.name);
    } else if (selectedColumn.name === 'status' || this.selectedColumn.name === "statusType") {
      selectionOptions = Object.keys(this.planningStatus).filter((f) => f);
    } else if (selectedColumn.name === 'colDelPlanning') {
      selectionOptions = Object.keys(this.colDelPlanning).filter((f) => f).filter((f) => isNaN(Number(f)));;
    }

    return selectionOptions;
  }

  public setSelectionOptionsFromLoadedFilter(selectedColumn: AdvancedFilterColumn): string[] {
    let selectionOptions: string[] = [];
    if (selectedColumn.name === 'containerStatus') {
      selectionOptions = this.containerStatuses.map(el => el.name);
      this.selectedSelectOption = this.filterItem.value;
    } else if (selectedColumn.name === 'status' || this.selectedColumn.name === "statusType") {
      selectionOptions = Object.keys(this.planningStatus).filter((f) => f);
      for (let key of selectionOptions) {
        if (this.planningStatus[key] === this.filterItem.value) {
          this.selectedSelectOption = key;
        }
      }
    } else if (selectedColumn.name === 'colDelPlanning') {
      selectionOptions = Object.keys(this.colDelPlanning).filter((f) => isNaN(Number(f)));
      for (let key of selectionOptions) {
        if (this.colDelPlanning[key] === this.filterItem.value) {
          this.selectedSelectOption = key;
        }
      }
    }


    return selectionOptions;
  }
}
