import { PbScreenService } from './../services/pb-screen.service';
import { Component, OnInit, ViewChild, ElementRef, Output } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ApplicationUser } from "../models/application.user.interface";
import { select } from "@angular-redux/store";
import { forkJoin, merge, Observable } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { settingAction } from "../../setting-modal/setting.action";
import { SharedActions } from "../../shared/shared.actions";
import { IdNameAsStringObject } from '../models/quick-view/id-name-as-string-object.interface';
import { ApplicationUserActions } from '../application.user.actions';
import { WeekPlanningSetting } from '../../setting-modal/setting-modal.interface';
import { UserSetup } from './userSetup-modal.interface';

@Component({
    moduleId: module.id,
    selector: 'userSetup-modal',
    templateUrl: 'userSetup-modal.component.html'
})
export class userSetupComponent implements OnInit {
    @ViewChild('modal') public modal: ElementRef
    @Output('companyName') companyName = environment.envName;
    private applicationUser: ApplicationUser;
    @select('planningGroups') public planningGroups$: Observable<IdNameAsStringObject[]>;
    @select('applicationUser') applicationUser$: Observable<ApplicationUser>;
    @select('planningZone') public planningZone$: Observable<any[]>;
    public enableAutocomplete: boolean = false;
    public planningGroups: IdNameAsStringObject[] = [];
    public planningZones: any[];
    public dateFormats : any[];
    public selectedPlanningZone: any;
    public selectedDateFormat: any;
    public selectedPlanningGroups: any;
    public defaultDateRange: number;
    public weekPlanningSetting: WeekPlanningSetting = new WeekPlanningSetting();
    public userSetup: UserSetup = new UserSetup();

    constructor(private applicationUserActions : ApplicationUserActions) { }

    ngOnInit() {
        this.applicationUser$.subscribe((applicationUser: ApplicationUser) => {
            if (applicationUser) {
                this.applicationUser = applicationUser;
                this.enableAutocomplete = applicationUser.generalSetting.enableAutocomplete;
                this.selectedDateFormat = this.applicationUser.defaultDateFormat;
                this.selectedPlanningZone = this.applicationUser.defaultPlanZone;
                this.weekPlanningSetting = this.applicationUser.weekPlanningSetting ? this.applicationUser.weekPlanningSetting : this.weekPlanningSetting;
                
                if(this.applicationUser.userSetup){
                    this.userSetup = this.applicationUser.userSetup;
                    this.defaultDateRange = this.userSetup.weekplanningDefaultDateRange;
                }
                this.selectedPlanningGroups = this.planningGroups.filter((x) => {
                    return this.applicationUser.defaultPlanGroups.indexOf(x.id) !== -1;
                  });
            }
        });
        this.planningGroups$.subscribe(
            (planningGroups: IdNameAsStringObject[]) => {
              this.planningGroups = planningGroups;
              if(this.applicationUser && this.applicationUser.defaultPlanGroups)
                this.selectedPlanningGroups = this.planningGroups.filter((x) => {
                    return this.applicationUser.defaultPlanGroups.indexOf(x.id) !== -1;
                });
            }
          );
        this.planningZone$.subscribe((planningZones: any) => {
            if (planningZones) {
                this.planningZones = planningZones;
                this.planningZones = this.planningZones.map(x => x.id);
            }
        });

        this.dateFormats = ['dd/MM/yyyy' , 'MM/dd/yyyy']
    }

    public saveSetting(): void {

        let merged = [];
        if(this.selectedDateFormat)
        {
            this.applicationUser.defaultDateFormat = this.selectedDateFormat;
            merged.push(this.applicationUserActions.updateApplicationUserObservable(this.applicationUser , "defaultDateFormat",true));
        }

        if(this.selectedPlanningZone)
        {
            this.applicationUser.defaultPlanZone = this.selectedPlanningZone; 
            merged.push(this.applicationUserActions.updateApplicationUserObservable(this.applicationUser , "defaultPlanZone",true));
        }

        if(this.selectedPlanningGroups)
        {
            this.applicationUser.defaultPlanGroups = this.selectedPlanningGroups.map(x => x.id);
            merged.push(this.applicationUserActions.updateApplicationUserObservable(this.applicationUser, "defaultPlanGroups", true));
        }

        if(this.defaultDateRange)
        {
            this.userSetup.weekplanningDefaultDateRange = this.defaultDateRange;
            this.applicationUser.userSetup = this.userSetup; 
            merged.push(this.applicationUserActions.updateApplicationUserObservable(this.applicationUser , "userSetup", true));
        }

        forkJoin(merged).subscribe(() => {
            if(confirm("The page must be reloaded. Please confirm or restart yourself."))
                window.location.reload();
        });

        this.closeModal();
    }

    dateRangeChange($event){
        this.defaultDateRange = undefined;
        if($event == undefined)
        return;
        
        if($event < 0) {
            setTimeout(() => {this.defaultDateRange = 0;});
        }
        else if($event > this.weekPlanningSetting.defaultDateRange){
            setTimeout(() => {this.defaultDateRange = this.weekPlanningSetting.defaultDateRange;});
        } else {
            this.defaultDateRange = $event;
        }
    }

    private closeModal(): void {
        this.modal.nativeElement.click();
    }
    
}