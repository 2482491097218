import { SignalrBroadcaster } from "../pb-screen/helpers/signalr.broadcaster";
import { Injectable } from "@angular/core";
import { NgRedux } from "@angular-redux/store";
import { IAppState } from "../store/IAppState";
import { ChannelEventFactory, ChannelEvent } from "../pb-screen/services/channel.event.factory";
import { ChannelService } from "../pb-screen/services/channel.service";
import { PlanningBlock } from "../pb-screen/models/planning-block/planning-block.interface";
import { ActionKeys } from "../pb-screen/action.keys";
import { SharedService } from "./services/shared.service";
import { CapacitySlot } from "../pb-screen/models/capacity-slot.interface";
import { OrderLine } from "../pb-screen/models/order-line/orderLine.interface";
import { Haulier } from "../pb-screen/models/haulier.interface";
import { TMSPlanningSetup } from "./models/tms-planning-setup";
import { ApplicationUserService } from "../pb-screen/services/application.user.service";
import { UserSettingsService } from "../pb-screen/services/usersettings.service";
import { UserSettings, DocumentToBePrinted, ApplicationUser } from "../pb-screen/models/application.user.interface";
import { Driver } from "../static/models/driver.interface";
import { NotificationService } from "./services/notification.service";
import { CommunicationPartner } from "./models/notification/communication-partner.interface";
import { StandardNotification } from "./models/notification/standard-notification.interface";
import { PlanningConflict } from "../pb-screen/models/planning-conflict.interface";
import { PlanningConflictsService } from "./services/planning-conflicts.service";
import { GetCmrActionsService } from "../pb-screen/services/getCmrActions.service";
import { xmlTMSSelectCMRActionsXML } from '../pb-screen/models/TMSSelectCMRActions.interface';
import { WindowsService } from "./models/windows-service";
import { TransportOrder } from "../pb-screen/models/transport-order/transport-order.interface";
import * as moment from 'moment';
import { IdNameAsStringObject } from "../pb-screen/models/quick-view/id-name-as-string-object.interface";
import { PbScreenService } from "../pb-screen/services/pb-screen.service";
import { NewPlanningBlocks } from "./components/modals/reroute-modal/reroute-modal.interface";
import { QuoteLine } from "../static/models/quote.interface";
import { WaitingRoomDetail } from "./models/waiting-room-detail.interface";
import { WizardDefinition } from "./models/wizard-definition";

@Injectable()
export class SharedActions extends SignalrBroadcaster {
    constructor(
        ngRedux: NgRedux<IAppState>,
        channelEventFactory: ChannelEventFactory,
        channelService: ChannelService,
        private readonly sharedService: SharedService,
        private readonly notificationService: NotificationService,
        private readonly userSettingsService: UserSettingsService,
        private readonly getCmrActionsService: GetCmrActionsService,
        private readonly planningConflictsService: PlanningConflictsService,
        private pbScreenService: PbScreenService
    ) {
        super(ngRedux, channelEventFactory, channelService);
    }

    resetModalDomains() {
        super.dispatchAndTransmitAction({
            type: ActionKeys.RESET_MODAL_DOMAINS,
        })
    }

    setApplicationUser(applicationUser) {
        this.ngRedux.dispatch({
            type: ActionKeys.GET_APPLICATION_USER_SUCCESS,
            applicationUser: applicationUser
        });
    }

    resetState() {
        this.ngRedux.dispatch({
            type: ActionKeys.RESET_STATE
        });
    }

    getCapacitySlots(planDate: String) {
        this.sharedService.getCapacitySlots(planDate).subscribe((capacitySlots: CapacitySlot[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS,
                capacitySlots: capacitySlots
            })
        })
    }

    getCapacitySlotsLight(planDate: String) {
        let planDateMoment = moment(planDate.toString());
        if (planDateMoment.isValid() && planDateMoment.isAfter(moment("01-01-2000"))) {
            this.sharedService.getCapacitySlotsLight(planDate).subscribe((capacitySlots: CapacitySlot[]) => {
                super.dispatchAndTransmitAction({
                    type: ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS,
                    capacitySlots: capacitySlots
                })
            })
        }
    }

    getCapacitySlotsAll() {
        this.sharedService.getCapacitySlotsAll().subscribe((capacitySlots: CapacitySlot[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS,
                capacitySlots: capacitySlots
            })
        })
    }

    getHauliers() {
        this.sharedService.getPlanningHauliers().subscribe((hauliers: Haulier[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_HAULIERS_SUCCESS,
                hauliers: hauliers
            })
        })
    }

    getDrivers() {
        this.sharedService.getDrivers().subscribe((drivers: Driver[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_DRIVERS_SUCCESS,
                drivers: drivers
            })
        })
    }

    getPlanningGroups() {
        this.sharedService.getPlanningGroups().subscribe((planningGroups: IdNameAsStringObject[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_PLANNINGGROUPS_SUCCESS,
                planningGroups: planningGroups
            })
        })
        
    }

    getContainerStatuses() {
      this.sharedService.getContainerStatuses().subscribe((statuses) => {
        super.dispatchAction({
            type: ActionKeys.SET_CONTAINER_STAUSES,
            containerStatuses: statuses
        })
      });
    }

    public setPlanningGroups(planningGroups) {
        super.dispatchAction({
            type: ActionKeys.REQUEST_PLANNINGGROUPS_SUCCESS,
            planningGroups: planningGroups
        })
    }

    getAllNotifications() {
        this.notificationService.getAllNotifications().subscribe((notifications: Notification[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_ALL_NOTIFICATIONS_SUCCESS,
                notifications: notifications
            })
        })
    }

    getTrucksWaitingRoomDetails() {
        this.notificationService.GetTMSWaitingRoomdetails().subscribe((waitingRoomDetails: WaitingRoomDetail[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_Waiting_Room_Details_SUCCESS,
                waitingRoomDetails: waitingRoomDetails
            })
        }) 
    }

    getAllPlanningBlockNotifications() {
        this.notificationService.getAllPlanningBlockNotifications().subscribe((notifications: Notification[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_ALL_PLANNING_BLOCK_NOTIFICATIONS_SUCCESS,
                planningBlockNotifications: notifications
            })
        })
    }

    getAllTransportOrderNotifications() {
        this.notificationService.getAllTransportOrderNotifications().subscribe((notifications: Notification[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_ALL_TRANSPORT_ORDER_NOTIFICATIONS_SUCCESS,
                transportOrderNotifications: notifications
            })
        })
    }

    getTruckDepartments() {
        this.sharedService.getTruckDepartments().subscribe((truckDepartments: IdNameAsStringObject[]) => {
            super.dispatchAction({
                type: ActionKeys.REQUEST_TRUCKDEPARTMENTS_SUCCESS,
                truckDepartments: truckDepartments
            })
        })
    }

    getCommunicationPartners() {
        this.notificationService.getCommunicationPartners().subscribe((communicationPartners: CommunicationPartner[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_COMMUNICATION_PARTNERS_SUCCESS,
                communicationPartners: communicationPartners
            })
        })
    }


    getStandardNotifications() {
        this.notificationService.getStandardNotifications().subscribe((standardNotifications: StandardNotification[]) => {
            super.dispatchAndTransmitAction({
                type: ActionKeys.REQUEST_STANDARD_NOTIFICATIONS_SUCCESS,
                standardNotifications: standardNotifications
            })
        })
    }

    setUiState(uiState: boolean) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_UI_STATE,
            uiState: uiState
        })
    }

    getOrderlines(orderLines: OrderLine[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.REQUEST_ORDERLINES_SUCCESS,
            orderLines
        });
    }

    setQuotelines(quoteLines: QuoteLine[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_QUOTELINES,
            quoteLines
        });
    }

    getPbsForTO(planningBlocksForTO: PlanningBlock[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_PLANNINGBLOCKS_FOR_TO,
            planningBlocksForTO
        })
    }

    setTMSPlanningSetup() {
        this.sharedService.getTMSPlanningSetup().subscribe((tmsPlanningSetup: TMSPlanningSetup) => {
            this.ngRedux.dispatch({
                type: ActionKeys.SET_TMS_PLANNING_SETUP,
                tmsPlanningSetup
            })
        });

    }

    setSelectedActionWizard(selectedActionWizard) {
        this.ngRedux.dispatch({
            type: ActionKeys.SET_SELECTED_ACTION_WIZARD,
            selectedActionWizard
        })
    }

    public setSelectedTransportOrderForInterWindowPlanning(selectedTransportOrder: TransportOrder) {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_TRANSPORT_ORDERS_FOR_INTERWINDOW_PLANNING,
            selectedTOForInterWindowPlanning: selectedTransportOrder
        })
    }

    public resetSharedSelectedPlanningBlocks()
    {
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_Shared_SELECTED_PLANNING_BLOCKS,
            selectedPlanningBlocks: []
        })   
        super.dispatchAndTransmitAction({
            type: ActionKeys.SET_SELECTED_PLANNING_BLOCKS,
            selectedPlanningBlocks: []
        });
        
    }

    public setLoading(loading) {
        this.dispatchAction({
            type : ActionKeys.SET_LOADING,
            loading : loading
        })
    }

    public httpRequestPending(pending) {
        this.dispatchAction({
            type : ActionKeys.HTTP_REQUEST_PENDING,
            pending
        })
    }

    setDefaultPlanningZone(userId) {
        this.userSettingsService.getUserSettingByName('defaultPlanZone', userId).subscribe((userSettings: UserSettings[]) => {

            let defaultPlanningZone = userSettings[0].value;
            this.ngRedux.dispatch({
                type: ActionKeys.SET_DEFAULT_PLANNINGZONE,
                defaultPlanningZone: defaultPlanningZone
            })
        })

    }
    public updateSessionDocumentNameSelection(Documents: DocumentToBePrinted[]) {
        this.ngRedux.dispatch({
            type: ActionKeys.UPDATE_SESSION_Document,
            sessionDocumentSelection: Documents
        });
    }
    public getCmrActions(id: string) {
        this.getCmrActionsService.getCmrActionsForTo(id).subscribe((xmlTMSSelectCMRActionsXML: xmlTMSSelectCMRActionsXML) => {
            super.dispatchAction({
                type: ActionKeys.GET_CMR_ACTIONS,
                cmrActions: xmlTMSSelectCMRActionsXML
            });
        });
    }
    public getCmrActionsFromBp(id: number) {
        this.getCmrActionsService.getCmrActionsForPb(id).subscribe((xmlTMSSelectCMRActionsXML: xmlTMSSelectCMRActionsXML) => {
            super.dispatchAction({
                type: ActionKeys.GET_CMR_ACTIONS,
                cmrActions: xmlTMSSelectCMRActionsXML
            });
        });
    }

    setActivePlanningConflicts() {
        this.planningConflictsService.getActivePlanningConflicts().subscribe((activePlanningConflicts: PlanningConflict[]) => {
            this.ngRedux.dispatch({
                type: ActionKeys.GET_ACTIVE_PLANNING_CONFLICTS,
                activePlanningConflicts: activePlanningConflicts
            })
        })
    }

    setPlanningConflictsPbs(selectedPbs: PlanningBlock[]) {
        const pbIds = selectedPbs.reduce((planningBlocksIds: number[], pb: PlanningBlock) => {
            planningBlocksIds.push(pb.id); return planningBlocksIds;
        }, []).join(",");
        this.planningConflictsService.getPlanningConflictsForPbIds(pbIds).subscribe((planningConflicts: PlanningConflict[]) => {
            if (planningConflicts) {
                this.ngRedux.dispatch({
                    type: ActionKeys.SET_PLANNING_CONFLICTS,
                    planningConflicts: planningConflicts
                })
            }
        })
    }

    setPlanningConflictsForTos(selectedTos: TransportOrder[]) {
        let toIds = selectedTos.map(x => x.id);
        const toNumbers = toIds.join(",");
        this.planningConflictsService.getPlanningConflictsForToNumbers(toNumbers).subscribe((planningConflicts: PlanningConflict[]) => {
            if (planningConflicts) {
                this.ngRedux.dispatch({
                    type: ActionKeys.SET_PLANNING_CONFLICTS,
                    planningConflicts: planningConflicts
                })
            }
        })
    }

    updatePlanningBlocksDisplay(updatedPlanningBlocks: PlanningBlock[]) {
        let action = {
            type: ActionKeys.SHARED_UPDATE_PLANNINGBLOCKS_DISPLAY,
            deletedPlanningBlocks: [],
            updatedPlanningBlocks: [...updatedPlanningBlocks]
        };
        super.dispatchAndTransmitAction(action);
    }

    deleteSharedPlanningBlocksDisplay(deletedPlanningBlocks: PlanningBlock[]) {
        let action = {
            type: ActionKeys.SHARED_UPDATE_PLANNINGBLOCKS_DISPLAY,
            deletedPlanningBlocks: [...deletedPlanningBlocks],
            updatedPlanningBlocks: []
        };
        super.dispatchAndTransmitAction(action);
    }

    updateTransportOrdersDisplay(updatedTransportOrders: TransportOrder[]) {
        let action = {
            type: ActionKeys.SHARED_UPDATE_TRANSPORTORDERS_DISPLAY,
            addedTransportOrders: [],
            deletedTransportOrders: [],
            updatedTransportOrders: [...updatedTransportOrders]
        };

        super.dispatchAndTransmitAction(action);
    }

    public getMonitorServiceStatus() {
        this.sharedService.getMonitoringServiceStatus().subscribe((monitorService: WindowsService) => {
            this.ngRedux.dispatch({
                type: ActionKeys.GET_MONITOR_SERVICE_STATUS,
                monitorService: monitorService
            })
        })
    }

    public addChannelEventToInventory(event: ChannelEvent) {
        this.ngRedux.dispatch({
            type: ActionKeys.Add_CHANNEL_EVENT_TO_INVENTORY,
            channelEvent: event
        })
    }

    public storeUserAzureGroups(userId) {
        this.sharedService.getUserAzureGroups(userId).subscribe((res) => {

            this.ngRedux.dispatch({
                type: ActionKeys.STORE_USER_AZURE_GROUPS,
                azureGroups: res
            })
        })
    }
    public storePlanningZone() {
        this.pbScreenService.getPlanningZones().subscribe((res) => {

            this.ngRedux.dispatch({
                type: ActionKeys.STORE_PLANNING_ZONE,
                planningZone: res
            })
        })
    }

    public storeIgnoreConflictReasons() {
        this.planningConflictsService.getIgnoreConflictReasons().subscribe((res) => {
            this.ngRedux.dispatch({
                type: ActionKeys.STORE_IGNORE_CONFLICT_REASONS,
                ignoreConflictReasons: res
            })
        })
    }

    public storeTrailerForPb(pbId) {
        this.sharedService.getTrailersForPbId(pbId).subscribe((res) => {
            this.ngRedux.dispatch({
                type: ActionKeys.STORE_TRAILERS_FOR_PB,
                trailers: res
            })
        })
    }

    public storeChassisForPb(pbId) {
        this.sharedService.getChassisForPbId(pbId).subscribe((res) => {
            this.ngRedux.dispatch({
                type: ActionKeys.STORE_CHASSIS_FOR_PB,
                chassis: res
            })
        })
    }

    public PlanBoardFilterResult(applicationUser: ApplicationUser, newData) {
        const dateFormatter = moment(newData.planDate).format('DD/MM/YYYY');
        applicationUser.defaultPlanDate = dateFormatter;
        applicationUser.defaultPlanGroups = newData.planningGroup.map(x => x.name).join(),
            applicationUser.defaultPlanningZone = newData.truckDepartment.map(x => x.name).join();
        if (newData.planningZone !== '') {
            applicationUser.defaultPlanZone = newData.planningZone;
        }
        // searchText: '',
        console.log(applicationUser);
        this.ngRedux.dispatch({
            type: ActionKeys.STORE_PLANNING_FILTER_SAVE,
            applicationUser: applicationUser
        });
    }

    public GetPlanBoardMonitor() {
        this.sharedService.getMonitorServiceStatusPlanBoard().subscribe((data) => 
        {
            this.ngRedux.dispatch({
                type : ActionKeys.PLAN_BOARD_MONITOR_STATUS , 
                planBoardMonitor : data 
            })
        })
    }

    public storeTOWizardActions(wizardDefinitions: WizardDefinition[]){
        this.ngRedux.dispatch({
            type: ActionKeys.STORE_TO_WIZARD_DEFINITIONS,
            wizardDefinitions
        })
    }

    public storePBWizardActions(wizardDefinitions: WizardDefinition[]){
        this.ngRedux.dispatch({
            type: ActionKeys.STORE_PB_WIZARD_DEFINITIONS,
            wizardDefinitions
        })
    }

    public setOrderlinesForToR(orderlinesForTO) {
        this.ngRedux.dispatch({
            type : ActionKeys.REQUEST_ORDERLINES_SUCCESS2 , 
            orderlinesForTO : orderlinesForTO
        })
    } 


    //Container Planning 
    
      updatePlanningBlocksDisplayCpl(updatedPlanningBlocks: PlanningBlock[]) {
        super.dispatchAndTransmitActionToGeneralChannel({
          type: ActionKeys.UPDATE_CPL_PLANNINGBLOCKS_DISPLAY,
          updatedPlanningBlocks: updatedPlanningBlocks
        });
      }
    
    
    
      addPlanningBlocksDisplay(newPlanningBlocks: NewPlanningBlocks) {
        super.dispatchAndTransmitActionToGeneralChannel({
          newPlanningBlocks: {
            depotInPlanningBlocks: [...newPlanningBlocks.depotInPlanningBlocks],
            depotOutPlanningBlocks: [...newPlanningBlocks.depotOutPlanningBlocks],
          },
          type: ActionKeys.ADD_PLANNINGBLOCKS_DISPLAY,
          updatedPlanningBlocks: [],
        });
      }
    
      removePlanningBlocksDisplay(deletedPlanningBlocks: PlanningBlock[]) {
        super.dispatchAndTransmitActionToGeneralChannel({
          type: ActionKeys.DELETE_PLANNINGBLOCKS_DISPLAY,
          deletedPlanningBlocks: deletedPlanningBlocks,
          updatedPlanningBlocks: []
        })
      }
    
      getCapacitySlotsCpl(planDate: string , planningGroups) {
        this.sharedService.getCapacitySlotsCpl(planDate , planningGroups).subscribe((capacitySlots: CapacitySlot[]) => {
          super.dispatchAction({
            type: ActionKeys.REQUEST_CAPACITY_SLOTS_SUCCESS,
            capacitySlots: capacitySlots
          })
        });
      }
    
    
    
      resetActionBarStates(state: boolean) {
        super.dispatchAction({
          type: ActionKeys.RESET_CPL_ACTION_BAR_STATES,
          state: state
        });
      }
    
      setActionBarStates(actionBarStates) {
        super.dispatchAction({
          type: ActionKeys.SET_CPL_ACTION_BAR_STATES,
          actionBarStates: actionBarStates
        });
      }
    
      setSelectedPlanningGroups(selectedPlanningGroups) {
        super.dispatchAction({
          type: ActionKeys.SET_PLANNING_GROUPS,
          selectedPlanningGroups
        });
      }
    
 
     
    
      public setPlanningGroupString(planningGroupString: string)
      {
        this.ngRedux.dispatch({
          type : ActionKeys.SET_GROUP_STRING,
          planningGroupString : planningGroupString
        })
      }
    
      public storePlanningConflicts()
      {
        this.sharedService.getActivePlanningConflicts().subscribe((data) => {
          if(data) {
            this.ngRedux.dispatch({
              type : ActionKeys.STORE_PLANNING_CONFLICTS, 
              activePlanningConflicts : data 
            })
          }
        })
      }
    
      public selectConflict(conflict : PlanningConflict)
      {
        this.ngRedux.dispatch({
          type : ActionKeys.SELECT_CONFLICT, 
          selectedConflict : conflict
        })
      }

}
