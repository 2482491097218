import { Injectable } from "@angular/core";
import { Response } from "@angular/http";
import { Observable } from "rxjs/Observable";

import { clientSettings } from "../static/clientSettings";
import { BookingModesStrings, BookingStatesStrings } from "./models/booking-enums.interface";
import { PoolCarrier } from "./models/pool-carrier.interface";
import { PoolContainerInspectionItem } from "./models/pool-container-inspection-item.interface";
import { PoolContainer, setContainerstatus } from "./models/pool-containers.interface";
import { PoolEquipmentType } from "./models/pool-equipment-type.interface";
import { PoolDriver } from "./models/pool-driver.interface";
import { PoolHaulier } from "./models/pool-haulier.interface";
import { PoolTruck } from "./models/pool-truck.interface";
import { ContainerAssignmentData } from "./models/ContainerAssignmentData";
import { PoolMove, setMoveToFrom } from "./models/pool-move.interface";
import { PoolBooking } from "./models/pool-booking.interface";
import { HttpClientService } from "../shared/services/httpclient";
import { map, catchError } from "rxjs/operators";
import { SharedActions } from '../shared/shared.actions';
import { environment } from "../../environments/environment";

@Injectable()
export class PoolService {
    constructor(private readonly http: HttpClientService) { }

    public getEquipmentTypes(): Observable<PoolEquipmentType[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-equipments-types")
            .pipe(map((response:any) => {
                const data = response.data;
                return data;
            }));
    }

    public getCarriers(): Observable<PoolCarrier[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/carriers")
            .pipe(map((response:any) => {
                const data = response.data;
                return data;
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolContainers(mode: number): Observable<PoolContainer[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-containers/" + mode)
            .pipe(map((response:any) => {
                return this.formatPoolContainers(response.data);
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolBookings(mode: number): Observable<PoolBooking[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-bookings/" + mode)
            .pipe(map((response:any) => {
                return response.data;
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolBookingByBookingNo(bookingNo: string): Observable<PoolBooking> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-booking-by-bookingNo/" + bookingNo)
            .pipe(map((response:any) => {
                return response.data
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getAllActiveMoves(): Observable<PoolMove[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-all-active-moves")
            .pipe(map((response:any) => {
                const data = this.formatPoolMoves(response.data);
                return data;
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolMovesByBookingNo(poolMoveNo: string): Observable<PoolMove[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-moves-by-bookingNo/" + poolMoveNo)
            .pipe(map((response:any) => {
                const data = this.formatPoolMoves(response.data);
                return data;
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolMovesByAnnouncedNo(announcedNo: string): Observable<PoolMove[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-moves-by-announcedNo/" + announcedNo)
            .pipe(map((response:any) => {
                const data = this.formatPoolMoves(response.data);
                return data;
            }), catchError((err) => {
                return Observable.throw(err.error);
            }));
    }

    public getPoolInspectionChecklist(): Observable<PoolContainerInspectionItem[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-container-inspection-checklist")
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getDrivers(haulierNumber: string): Observable<PoolDriver[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-drivers/" + haulierNumber)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getHauliers(): Observable<PoolHaulier[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-hauliers")
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getTrucks(haulierNumber: string): Observable<PoolTruck[]> {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-trucks/" + haulierNumber)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public assignContainerToBookMove(data: ContainerAssignmentData) {
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/assign-container-to-book-move", data)
            .pipe(map((response:any) => response)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getPoolContainerByContainerNumber(containerNumber: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-container-by-containerNo/" + containerNumber)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getPoolMoveByBookingNumber(bookingNo: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-move-by-bookingNo/" + bookingNo)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getPoolMoveByCmNumber(cmNo: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-pool-move-by-cmNo/" + cmNo)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public getDamageList(cmNo: string) {
        return this.http
            .get(environment.ContainerPlanningApiUri + "pool/get-container-inspection-checklist-by-containerMoveNo/" + cmNo)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    public checkContainerAvailability(pbId: number, containerNumber: string, userId: string): Observable<boolean> {
        const apiData = {
            "planningBlockId": pbId,
            "containerNumber": containerNumber,
            "userId": userId
        }
        
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/check-container-availability/", apiData)
            .pipe(map((response:any) => response.data)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    };

    public takeContainerFromPool(pbIds: number[], userId: string, containerNo: string) {
        const apiData = {
            pbIds: pbIds,
            userId: userId,
            containerNo: containerNo,
        }
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/take-container-from-pool/", apiData)
            .pipe(map((response:any) => response)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    };

    public registerApprovePoolContReqChecks(pbIds: number[], userId: string) {
        const apiData = {
            pbIds: pbIds,
            userId: userId
        }
        return this.http
            .post(environment.ContainerPlanningApiUri + "pool/register-approve-pool-checks/", apiData)
            .pipe(map((response:any) => response)
                , catchError((err) => {
                    return Observable.throw(err.error);
                }));
    }

    private formatPoolMoves(poolMoves): PoolMove[] {
        poolMoves.forEach((element: PoolMove) => {
            element.from = setMoveToFrom(element.from);
            element.towards = setMoveToFrom(element.towards)
        });
        return poolMoves;
    }

    private formatPoolContainers(poolContainers): PoolContainer[] {
        poolContainers.forEach(element => {
            element.status = setContainerstatus(element.status)
        });
        return poolContainers;
    }
}
