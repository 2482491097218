import { DatePipe } from "@angular/common";
import { PipeTransform, Pipe } from "@angular/core";

@Pipe({
    name: 'customToDate'
})
export class CustomToDatePipe extends DatePipe implements PipeTransform {
    transform(value: string, pattern?: string): string {
        let date = '';
        if (value === '0001-01-01T00:00:00Z' || value === '1753-01-01T00:00:00Z') {
            return date;
        }
        else {
            date = super.transform(value, pattern);
            let date2 = new Date(value);
            let monthT = date2.getUTCMonth() + 1;
            let monthS = "";
            let day = "";

            if (monthT.toString().length === 1) {
                monthS = "0" + monthT
            } else {
                monthS = monthT.toString();
            }

            if (date2.getUTCDate().toString().length === 1) {
                day = "0" + date2.getUTCDate().toString();
            } else {
                day = date2.getUTCDate().toString();
            }

            date = day + "/" + monthS + "/" + date2.getUTCFullYear();
            return date;
        }

    }

}