import { OnInit, Component, ViewChild, ElementRef, HostListener, Input } from "@angular/core";
import { SharedService } from "../../../services/shared.service";
import { Observable } from "rxjs";
import { TransportOrder } from "../../../../pb-screen/models/transport-order/transport-order.interface";
import { select } from "@angular-redux/store";
import { PlanningConflict } from "../../../../pb-screen/models/planning-conflict.interface";
import { PlanningBlock } from "../../../../pb-screen/models/planning-block/planning-block.interface";
import { PlanningConflictsService } from "../../../services/planning-conflicts.service";
import { PbActions } from "../../../../pb-screen/pb.actions";
import { ToActions } from "../../../../to-screen/to.actions";
import { SharedActions } from "../../../shared.actions";

@Component({
    selector: "show-planning-conflicts-modal",
    templateUrl: './show-planning-conflicts-modal.component.html'
})
export class ShowPlanningConflictsModal implements OnInit {

    @select("selectedTransportOrders") $selectedTransportOrders: Observable<TransportOrder[]>
    @select("selectedPlanningBlocks") $selectedPlanningBlocks: Observable<PlanningBlock[]>
    @select("planningConflicts") $planningConflicts: Observable<PlanningConflict[]>
    @select('loading') public loading$: Observable<boolean>;
    @ViewChild("modal") public modal: ElementRef;

    @Input() public source : string;
    public planningConflicts: PlanningConflict[] = [];
    public selectedLine: PlanningConflict;
    public nbClicks = 1;
    public selectedTransportOrder: TransportOrder;
    public selectedTransportOrders: TransportOrder[];
    public selectedPlanningBlocks: PlanningBlock[];
    public selectedPlanningBlock: PlanningBlock;
    public loadingSpinner: boolean = false;
    constructor(
        public readonly sharedService: SharedService,
        public readonly planningConflictsService: PlanningConflictsService,
        public readonly pbActions: PbActions,
        public readonly toActions: ToActions, 
        public readonly sharedActions: SharedActions) {

    }

  ngOnInit(): void {

    this.loading$.subscribe((data) => {
      if (!data)
        this.loadingSpinner = data;
    })

        this.$planningConflicts.subscribe((conflicts : PlanningConflict[]) => {
            if(conflicts) {
                this.planningConflicts = conflicts;
            }
        })

        if(this.source === "to-screen") {
            this.$selectedTransportOrders.subscribe((transportOrders: TransportOrder[]) => {
                if (transportOrders.length !== 0) {
                    this.selectedTransportOrders = transportOrders;
                    this.selectedTransportOrder = transportOrders[0];
                    this.sharedActions.setPlanningConflictsForTos(this.selectedTransportOrders);
                }
            })
            
           
        } 
        else if (this.source === "pb-screen") {
            this.$selectedPlanningBlocks.subscribe((planningBlocks : PlanningBlock[]) => {
                if(planningBlocks.length !== 0) {
                    this.selectedPlanningBlock = planningBlocks[0]; 
                    this.selectedPlanningBlocks = planningBlocks;
                    this.sharedActions.setPlanningConflictsPbs(this.selectedPlanningBlocks);
                }
            })
        }
    }


    public recalculatePlanningConflictsFoTo()
    {        
            this.loadingSpinner = true;
            setTimeout(() => {
                this.sharedService.calculatePlanningBlockConflicts(this.selectedTransportOrder.id).subscribe((data) => {
                    this.planningConflicts = data ; 
                    this.sharedActions.setActivePlanningConflicts();
                    this.loadingSpinner = false;
                })
            },3000)
        
    }
    public recalculatePlanningConflictsFoPB()
    {
        this.loadingSpinner = true;
        setTimeout(() => {
            this.sharedService.calculatePlanningBlockConflicts(this.selectedPlanningBlock.transportOrderNumber).subscribe((data) => {
                this.planningConflicts = data.filter(x => x.pbId === this.selectedPlanningBlock.id.toString()) ; 
                this.sharedActions.setActivePlanningConflicts();
                this.loadingSpinner = false;
            })
        },3000)
    
    }


    public closeModal(): void {
        this.modal.nativeElement.click();
    }

    public onHideConflict(conflict: PlanningConflict): void {
        var transportOrderId: string, selectedPb: PlanningBlock, pbId: number;
        if(this.source === "to-screen") {
            transportOrderId = this.selectedTransportOrder.id;
            this.sharedService.hideConflictForTo(conflict.entryNumber, transportOrderId).subscribe((data) => {
                this.selectedTransportOrder = data;
                this.toActions.setSelectedTransportOrders([this.selectedTransportOrder]);
                this.toActions.updateTransportOrdersDisplay([this.selectedTransportOrder]);
                this.sharedActions.setActivePlanningConflicts();
            });
        } else if (this.source === "pb-screen") {
            selectedPb = this.selectedPlanningBlocks.find(el => el.id.toString() === conflict.pbId);
            transportOrderId = selectedPb.transportOrderNumber;
            pbId = selectedPb.id;
            this.sharedService.hideConflictForPb(conflict.entryNumber, pbId).subscribe((data) => {
                selectedPb = data;
                this.sharedActions.updatePlanningBlocksDisplay([selectedPb]);
                this.sharedActions.setActivePlanningConflicts();
            });
        }
    }
 
}
