import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name : 'timeFormatter' })
export class TimeFormatterPipe implements PipeTransform {

    constructor() {
    }

    transform(value: string) {
        return value.split("T")[1].slice(0, -1);
    }
}
